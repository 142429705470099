/* eslint-disable angular/document-service */
if (APP_SETTINGS.MATOMO_DSN) {
    const _paq = (window._paq = window._paq || []); // eslint-disable-line angular/window-service
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    (function () {
        const u = APP_SETTINGS.MATOMO_DSN;
        _paq.push(['setTrackerUrl', `${u}matomo.php`]);
        _paq.push(['setSiteId', '24']);

        const g = document.createElement('script');
        g.type = 'text/javascript';
        g.async = true;
        g.src = `${u}matomo.js`;

        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(g, s);
    })();
}
