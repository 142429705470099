angular.module('dotic').factory('$authorizationService', authorizationService);

function authorizationService($auth) {
    return {
        isAllowed,
        checkPermissions,
    };

    function isAllowed(roles) {
        if ($auth.isAuthenticated() === false) {
            return false;
        }

        if (roles.length === 0) {
            return true;
        }

        const payload = $auth.getPayload();

        return roles.includes(payload.userRole);
    }

    /**
     * Safely compare permissions object
     * This method remove possible mongoose field from the two object before compare
     * Fix the _id, id fields returned by mongoose lean
     * @param {object} permission
     * @param {object} required
     */
    function checkPermissions(permission = {}, required = {}) {
        const requiredObject = { ...required };
        const permissionObject = { ...permission };

        delete requiredObject.id;
        delete requiredObject._id;

        delete permissionObject.id;
        delete permissionObject._id;

        return angular.equals(permissionObject, requiredObject);
    }
}
