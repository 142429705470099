/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.22.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* FMEServers service.
* @module api/FMEServersApi
* @version 1.22.0
*/
export default class FMEServersApi {

    /**
    * Constructs a new FMEServersApi. 
    * @alias module:api/FMEServersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create a FME server
     * @param {Object.<String, Object>} body Server to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    createWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling create");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fmeServers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a FME server
     * @param {Object.<String, Object>} body Server to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    create(body) {
      return this.createWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a FME job logs
     * @param {String} fmeJobId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getJobLogsWithHttpInfo(fmeJobId) {
      let postBody = null;
      // verify the required parameter 'fmeJobId' is set
      if (fmeJobId === undefined || fmeJobId === null) {
        throw new Error("Missing the required parameter 'fmeJobId' when calling getJobLogs");
      }

      let pathParams = {
        'fmeJobId': fmeJobId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fmeServers/transformations/jobs/{fmeJobId}/logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a FME job logs
     * @param {String} fmeJobId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getJobLogs(fmeJobId) {
      return this.getJobLogsWithHttpInfo(fmeJobId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all servers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fmeServers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all servers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    list() {
      return this.listWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a FME server
     * @param {String} serverId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    removeWithHttpInfo(serverId) {
      let postBody = null;
      // verify the required parameter 'serverId' is set
      if (serverId === undefined || serverId === null) {
        throw new Error("Missing the required parameter 'serverId' when calling remove");
      }

      let pathParams = {
        'serverId': serverId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fmeServers/{serverId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a FME server
     * @param {String} serverId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    remove(serverId) {
      return this.removeWithHttpInfo(serverId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove a FME server
     * @param {String} serverId 
     * @param {Object.<String, Object>} body Server data to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    updateWithHttpInfo(serverId, body) {
      let postBody = body;
      // verify the required parameter 'serverId' is set
      if (serverId === undefined || serverId === null) {
        throw new Error("Missing the required parameter 'serverId' when calling update");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling update");
      }

      let pathParams = {
        'serverId': serverId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fmeServers/{serverId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove a FME server
     * @param {String} serverId 
     * @param {Object.<String, Object>} body Server data to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    update(serverId, body) {
      return this.updateWithHttpInfo(serverId, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
