import template from './fmeServersList.html';

class FmeServersListPage {
    constructor($filter, $timeout, $state, $scope, $toasterService, $uibModal, fmeServerProvider, $modalService) {
        this._translate = $filter('translate');
        this._$timeout = $timeout;
        this._$state = $state;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._fmeServerProvider = fmeServerProvider;
        this._$modalService = $modalService;

        this.loading = true;
        this.serversList = [];

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('fmeServersList.title'),
                    href: $state.href('app.fmeServersList'),
                },
            ],
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        try {
            this.serversList = await this._fmeServerProvider.list();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    createServer() {
        const resolve = {
            isEditMode: () => false,
            name: () => '',
            description: () => '',
            host: () => '',
            token: () => '',
            repositoryName: () => '',
        };

        const modal = this._$uibModal.open({
            component: 'fmeFormModal',
            size: 'md',
            backdrop: false,

            resolve,
        });

        modal.result.then(
            (result) => {
                this._fmeServerProvider
                    .create(result)
                    .then(() => {
                        this._$toasterService.success(this._translate('fmeServersList.serverCreated'));
                        this._$timeout(async () => {
                            await this.initData();
                        }, 500);
                    })
                    .catch(this._$toasterService.error);
            },
            () => {},
        );
    }

    editServer(server) {
        const resolve = {
            isEditMode: () => true,
            name: () => server.name,
            description: () => server.description,
            host: () => server.host,
            token: () => server.token,
            repositoryName: () => server.repositoryName,
        };

        const modal = this._$uibModal.open({
            component: 'fmeFormModal',
            size: 'md',
            backdrop: false,
            resolve,
        });

        modal.result.then(
            (result) => {
                result.id = server._id;
                this._fmeServerProvider.update(result).then(() => {
                    this._$toasterService.success(this._translate('fmeServersList.serverEdited'));
                    this._$timeout(async () => {
                        await this.initData();
                    }, 500);
                });
            },
            () => {},
        );
    }

    async removeServer(fmeServer) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.server', {
                NAME: fmeServer.name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._fmeServerProvider.remove(fmeServer.id);
            this._$toasterService.info({
                body: this._translate('removeModal.success'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }
}

angular.module('dotic').component('fmeServersListPage', {
    controller: FmeServersListPage,
    templateUrl: template,
});
