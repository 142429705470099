import { unique as radashUnique } from 'radash';

import { HelpersService } from '../../../services/helpers.service';
import template from './dataModelEdit.html';

class DataModelEditPage {
    calculationVariables = [];
    calculationVariablesString = '';
    dataModel = {
        name: '',
        values: [],
        settings: {
            hasSynopticEnabled: false,
            hasQGisEnabled: false,
            hasGeoPackageExportEnabled: false,
        },
    };
    dataModelForm = null;
    dataModelValueNames = [];
    defaultCalculation = [];
    filteredValues = [];
    isEditMode = false;

    constructor(
        $dataModelService,
        $excelService,
        $filter,
        $log,
        $modalService,
        $scope,
        $searchService,
        $state,
        $timeout,
        $toasterService,
        $uibModal,
        dataModelProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._translate = $filter('translate');
        this._$log = $log;
        this._$modalService = $modalService;
        this._$searchService = $searchService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._dataModelProvider = dataModelProvider;

        this.filter = {
            name: $state.params.name || '',
            search: $state.params.search || '',
        };
        this.dataModelId = $state.params.dataModelId;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('shared.dataModel'),
                    href: this._$state.href('app.dataModelList'),
                },
                {
                    title: `${
                        this.dataModelId ? this._translate('shared.edit') : this._translate('shared.add')
                    } ${this._translate('dataModelEdit.dataModel')}`,
                },
            ],
        });

        $scope.$watch('$ctrl.dataModel.name', () => {
            if (this.dataModelForm?.slug && !this.dataModelForm.slug.$touched) {
                this.dataModel.slug = this.generateSlug(this.dataModel.name);
            }
        });
        $scope.$watchGroup(['$ctrl.dataModel.values', '$ctrl.filter.name', '$ctrl.filter.search'], () =>
            this.filterValues(),
        );
    }

    async $onInit() {
        this.loading = true;
        if (!this.dataModelId) {
            this.isEditMode = false;
            this.loading = false;

            return Promise.resolve();
        }

        this.isEditMode = true;

        try {
            const dataModel = await this._dataModelProvider.get(this.dataModelId);
            this.prepareData(dataModel);
        } catch {
            this._translate('toaster.error');
        }
        this.loading = false;
    }

    async createDataModel(dataModel) {
        try {
            await this._dataModelProvider.create(dataModel);

            this._$toasterService.success({
                body: this._translate('dataModelEdit.created'),
            });
            this._$state.go('app.dataModelList');
        } catch (error) {
            let message = this._translate('shared.errorCreation');
            if (error.data?.errors) {
                message = `${message}, ${error.data.errors.join('\n')}`;
            }

            this._$toasterService.error({ body: message });
        }
    }

    filterValues() {
        this._$state.go('.', {
            name: this.filter.name,
            search: this.filter.search,
        });

        this.filteredValues = this.dataModel.values
            .filter((value) => this.filter.name === '' || value.name === this.filter.name)
            .filter((filteredValue) =>
                this._$searchService.match(this.filter.search)([
                    filteredValue.name,
                    filteredValue.label,
                    filteredValue.description,
                ]),
            );
    }

    prepareData(dataModel) {
        this.dataModelValueNames = HelpersService.toKeyValue(
            radashUnique(dataModel.values, (value) => value.name),
            { keyField: 'name', valueField: 'name' },
        );

        this.dataModel = { ...this.dataModel, ...dataModel };

        if (dataModel.settings?.calculationPlugsChoice) {
            this.defaultCalculation = dataModel.settings.calculationPlugsDefault;
            this.calculationVariablesString = dataModel.settings.calculationPlugsChoice.join(', ');
            this.calculationVariables = dataModel.settings.calculationPlugsChoice.map((plugChoice) => ({
                key: plugChoice,
                value: plugChoice,
            }));
        }
    }

    generateCalculation() {
        const calculationVariables = this.calculationVariablesString.split(',');

        this._$timeout(() => {
            this.calculationVariables = calculationVariables.map((variable) => ({
                key: variable.trim(),
                value: variable.trim(),
            }));
        });
    }

    generateSlug(string = '') {
        return string
            .toLowerCase()
            .replace(/[^a-z0-9]/gi, '-')
            .replace(/-+/gi, '-');
    }

    async removeValue(dataModelValue) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.value', {
                NAME: dataModelValue.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const index = this.dataModel.values.findIndex((value) => value._id === dataModelValue._id);
        if (index < 0) {
            this._$toasterService.error({
                body: this._translate('removeModal.failure', {
                    COUNT: 1,
                }),
            });

            return;
        }

        this.dataModel.values.splice(index, 1);
        this.filterValues();
        this._$toasterService.success({
            body: this._translate('removeModal.success'),
        });
    }

    onSubmit() {
        if (!this.dataModel.name || !this.dataModel.slug) {
            return;
        }

        const data = {
            ...this.dataModel,
            settings: {
                ...this.dataModel.settings,
                calculationPlugsChoice: this.calculationVariablesString.split(',').map((variable) => variable.trim()),
                calculationPlugsDefault: this.defaultCalculation,
            },
            values: this.dataModel.values.map((value) => {
                if (value.new) {
                    return { ...value, _id: undefined };
                }

                return value;
            }),
        };

        if (this.dataModelId) {
            return this.updateDataModel(data, this.dataModelId);
        }

        return this.createDataModel(data);
    }

    openAddValuePopup() {
        const modal = this._$uibModal.open({
            size: 'mg',
            component: 'ccDataModelValue',
            resolve: {
                value: () => ({
                    _id: Date.now(),
                    name: '',
                    code: '',
                    label: '',
                    description: '',
                }),
            },
        });

        return modal.result.then(
            (data) => {
                if (data) {
                    this.dataModel.values = [...this.dataModel.values, { ...data, new: true }];
                }

                this._$timeout(() => {
                    this.filterValues();
                });
            },
            () => {},
        );
    }

    openEditValuePopup(item) {
        const modal = this._$uibModal.open({
            size: 'mg',
            component: 'ccDataModelValue',
            resolve: {
                value: () => item,
            },
        });

        return modal.result.then(
            (data) => {
                const index = this.dataModel.values.findIndex((value) => value._id === data._id);
                if (index < 0) {
                    return;
                }
                this.dataModel.values[index] = data;

                this._$timeout(() => {
                    this.filterValues();
                });
            },
            () => {},
        );
    }

    async importFile(file) {
        if (!file) {
            return;
        }

        this.loading = true;
        const data = await this._$excelService.importXLSX(URL.createObjectURL(file));

        this.prepareData({ values: this._$dataModelService.excelToJson(data) });
        this.loading = false;
    }

    async updateDataModel(dataModel, dataModelId) {
        try {
            await this._dataModelProvider.update(dataModelId, dataModel);
            this._$toasterService.success({
                body: this._translate('dataModelEdit.saved'),
            });
            this._$state.go('app.dataModelList');
        } catch (error) {
            let message = this._translate('shared.errorUpdate');
            if (error.data?.errors) {
                message = `${message}, ${error.data.errors.join('\n')}`;
            }

            this._$toasterService.error({ body: message });
        }
    }
}

angular.module('dotic').component('dataModelEditPage', {
    controller: DataModelEditPage,
    templateUrl: template,
});
