class DeliveryZonesService {
    constructor(deliveryZoneProvider, $filter, $segmentsService) {
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._translate = $filter('translate');
        this._$segmentsService = $segmentsService;
    }

    excelToJson(objArray, projects, servers, companies) {
        const uniqueNames = {};

        return objArray.map((item, index) => {
            const newObject = {};

            // Rename an old deprecated field
            if (item['NOM']) {
                item['NOM_LIVRAISON'] = item['NOM'];
                delete item['NOM'];
            }

            // Required fields
            ['NOM_LIVRAISON', 'PROJET_CC', 'START_DATE', 'DESCRIPTION'].forEach((field) => {
                if (!item[field]) {
                    throw new Error(
                        this._translate('shared.requiredFieldExcel', {
                            field,
                            line: index + 2,
                        }),
                    );
                }
            });

            // Check uniqueness with the couple of project and zone name
            const uniqueName = [item.PROJET_CC, item.NOM_LIVRAISON].join('_');

            if (uniqueNames[uniqueName]) {
                throw new Error(
                    this._translate('shared.duplicatedFieldUniqExcel', {
                        field: 'NOM',
                        fieldGroup: 'PROJET_CC',
                        value: uniqueName,
                        line: index + 2,
                    }),
                );
            } else {
                uniqueNames[uniqueName] = true;
            }

            newObject.name = item.NOM_LIVRAISON;
            newObject.city = item.COMMUNE;

            if (angular.isDefined(item.SOUSTRAITANTS)) {
                const assignments = item.SOUSTRAITANTS.split(',').filter((value) => value);
                newObject.assignments = assignments.map((name) => {
                    const cleanName = name.trim();
                    const foundCompany = companies.find((c) => {
                        return (
                            c.name.localeCompare(cleanName, 'fr', {
                                sensitivity: 'base',
                            }) === 0
                        );
                    });
                    if (foundCompany) {
                        return foundCompany.id;
                    } else {
                        throw new Error(`L'entreprise sous traitante "${name}" n'est pas autorisé.`);
                    }
                });
            }

            if (item.PROJET_CC) {
                const foundProject = projects.find((project) => {
                    return (
                        project.name.localeCompare(item.PROJET_CC, 'fr', {
                            sensitivity: 'base',
                        }) === 0
                    );
                });

                if (foundProject) {
                    newObject.project = foundProject.id;
                } else {
                    throw new Error(`Le projet "${item.PROJET_CC}" n'existe pas dans notre base de données.`);
                }
            }

            if (angular.isDefined(item.BASE_STOCKAGE)) {
                if (item.BASE_STOCKAGE === '') {
                    newObject.server = undefined;
                } else {
                    const foundServer = servers.find((server) => {
                        return (
                            server.name.localeCompare(item.BASE_STOCKAGE, 'fr', {
                                sensitivity: 'base',
                            }) === 0
                        );
                    });

                    if (foundServer) {
                        newObject.server = foundServer.id;
                    } else {
                        throw new Error(
                            this._translate('deliveryZoneList.serverErrorNotExist', {
                                NAME: item.BASE_STOCKAGE,
                            }),
                        );
                    }
                }
            }

            // Parse the date, if the date is not a string, convert the number in Date
            let startDate = angular.isString(item.START_DATE) ? item.START_DATE : item.CONVERTED_DATE;
            const date = moment(startDate, 'DD/MM/YYYY');
            if (date.isValid()) {
                startDate = date.toISOString();
            } else {
                throw new Error(this._translate('deliveryZoneList.dateRequired'));
            }

            newObject.projectId = item.ID_PROJET;
            newObject.nature = item.NATURE;
            newObject.description = item.DESCRIPTION;
            newObject.department = item.DEPARTEMENT;
            newObject.INSEE = item.INSEE;
            newObject.numberOfPlugs = item.NB_PRISES;
            newObject.startDate = startDate;
            newObject.idSroRegulatory = item.ID_SRO_REGLEMENTAIRE;
            newObject.refTransportMoa = item.REF_TRANSPORT_MOA;
            newObject.trigramNroMoa = item.TRIGRAMME_NRO_MOA;

            return newObject;
        });
    }

    async getDeliveryZoneByName(projectName, deliveryZoneName) {
        const deliveryZone = await this._deliveryZoneProvider.resolve(projectName, deliveryZoneName);

        if (angular.isArray(deliveryZone?.project?.controlConfigurations)) {
            deliveryZone.project.controlConfigurations = deliveryZone.project.controlConfigurations.filter(
                (controlConfiguration) => controlConfiguration.isActive,
            );
        }

        return deliveryZone;
    }

    jsonToExcel(zone) {
        let startDate = '';
        if (zone.startDate && zone.startDate !== '') {
            const date = moment(zone.startDate);
            if (date.isValid()) {
                startDate = date.format('DD/MM/YYYY');
            }
        }

        let assignmentCompanyNames = '';
        if (zone.assignments && zone.assignments.length > 0) {
            assignmentCompanyNames = zone.assignments.map(({ name }) => name).join(',');
        }

        const excel = {};
        excel.NOM_LIVRAISON = zone.name;
        excel.COMMUNE = zone.city || '';

        excel.PROJET_CC = zone.project ? zone.project.name : '';
        excel.BASE_STOCKAGE = zone.server ? zone.server.name : '';
        excel.ID_PROJET = zone.projectId || '';
        excel.NATURE = zone.nature || '';
        excel.DESCRIPTION = zone.description || '';
        excel.DEPARTEMENT = zone.department || '';
        excel.INSEE = zone.INSEE || '';
        excel.NB_PRISES = zone.numberOfPlugs || '';

        excel.START_DATE = startDate;

        excel.SOUSTRAITANTS = assignmentCompanyNames;

        excel.ID_SRO_REGLEMENTAIRE = zone.idSroRegulatory;
        excel.REF_TRANSPORT_MOA = zone.refTransportMoa;
        excel.TRIGRAMME_NRO_MOA = zone.trigramNroMoa;

        return excel;
    }
}

angular
    .module('dotic')
    .factory(
        '$deliveryZoneService',
        (deliveryZoneProvider, $filter, $segmentsService) =>
            new DeliveryZonesService(deliveryZoneProvider, $filter, $segmentsService),
    );
