import template from './aggregation.html';

class AggregationHomePage {
    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $scope,
        $tableService,
        $timeout,
        $toasterService,
        $translate,
        $uibModal,
        aggregationProvider,
        serverProvider,
        userMetricsProvider,
    ) {
        this._$deleteService = $deleteService;
        this._translate = $filter('translate');
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._aggregationProvider = aggregationProvider;
        this._serverProvider = serverProvider;
        this._userMetricsProvider = userMetricsProvider;

        // Items
        this.aggregations = [];
        this.serversList = [];

        // init generate filters
        this.generateFilter = {
            from: null,
            to: null,
            businessCodes: [],
            projects: [],
            deliveryZones: [],
            phases: [],
            users: [],
            servers: [],
            version: 'last',
        };

        this.isDownloading = false;
        this.isAllowed = $authorizationService.isAllowed;
        this.loading = true;
        this.generationLoading = false;
        this.table = [];
        this.statistics = [];

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'aggregationHome',
                    title: this._translate('shared.aggregation'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    $onInit() {
        this.loading = true;
        this.tableDetail = this._$tableService.detail();

        this._userMetricsProvider.listAggregations();
        Promise.all([this.getAggregations(), this.getServers()]).then(() => {
            this._$timeout(() => {
                this.tableSelect = this._$tableService.select();
                this.loading = false;
            });
        });
    }

    selectAll() {
        this._$scope.$broadcast('getVisibleRows');
    }

    async removeAggregations() {
        const selectedAggregations = this.aggregations.filter(this.tableSelect.isActive);

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.aggregate', {
                COUNT: selectedAggregations.length,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedAggregationIds = selectedAggregations.map((selectedAggregation) => selectedAggregation._id);
        await this._$deleteService.deleteArrayOfIds(
            selectedAggregationIds,
            (aggregationId) => this._aggregationProvider.delete(aggregationId),
            'removeModal.successAggregation',
        );

        await this.getAggregations();
    }

    getServers() {
        return this._serverProvider.getAll().then((servers) => {
            this._$timeout(() => {
                this.servers = servers;
                this.serversList = servers.data.map(({ id, name }) => ({
                    key: id,
                    value: name,
                }));
            });
        });
    }

    generateMerge() {
        const modal = this._$uibModal.open({
            component: 'generateMergeModal',
            size: 'xl',
            backdrop: 'static',
            resolve: {
                serversList: () => this.serversList,
                generateFilter: () => ({ ...this.generateFilter }),
            },
        });

        modal.result.then(
            (data) => {
                if (!data) {
                    return;
                }

                const { params, filters } = data;
                // Keep selected filters in memory
                this.generateFilter = filters;

                if (params) {
                    this._userMetricsProvider.generateAggregation();

                    this._aggregationProvider
                        .generate(params)
                        .then(() => {
                            this._$toasterService.info({
                                title: this._translate('aggregationPage.generating'),
                                body: this._translate('aggregationPage.cronMessage'),
                            });
                        })
                        .then(() => this.getAggregations())
                        .catch((e) => {
                            this._$toasterService.error(e);
                        });
                }
            },
            () => {},
        );
    }

    async getAggregations() {
        try {
            const results = await this._aggregationProvider.getAll();

            this.aggregations = results.map((res) => {
                if (!res.deposits) {
                    return res;
                }

                const dataModelNames = res.deposits.map((deposit) => deposit.dataModel.name);

                return {
                    ...res,
                    dataModels: dataModelNames.reduce((acc, name) => {
                        if (acc.includes(name)) {
                            return acc;
                        }

                        return [...acc, name];
                    }, []),
                };
            });
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async download(aggregation) {
        this.isDownloading = true;

        try {
            await this._aggregationProvider.download(aggregation._id);
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this.isDownloading = false;
        }
    }

    showDetails(aggregationItem) {
        this.tableDetail.toggle(aggregationItem);
    }
}

angular.module('dotic').component('aggregationHomePage', {
    controller: AggregationHomePage,
    templateUrl: template,
});
