function getId(notification) {
    if (!notification?.data?.project_id) {
        return {};
    }

    return notification.data.project_id.id || notification.data.project_id;
}

function getProject(projects, id) {
    if (!projects[id]) {
        return {};
    }

    return projects[id];
}

function getControlConfiguration(controlConfiguration, id) {
    if (!controlConfiguration[id]) {
        return {};
    }

    return controlConfiguration[id];
}

function is(notification, category, operation) {
    return notification.category === category && notification.data.operation === operation;
}

function getProjectName(projectsObject, notification) {
    const name = getProject(projectsObject, getId(notification)).name || notification.data.project_name;

    if (name) {
        return `« ${name} »`;
    } else {
        return '';
    }
}

function getControlConfigurationName(controlConfigurationObject, notification) {
    const name = getControlConfiguration(controlConfigurationObject, notification.data.controlConfiguration_id).name;

    if (name) {
        return `« ${name} »`;
    } else {
        return '';
    }
}

/** @ngInject */
function notificationService() {
    return {
        format: format,
    };

    function format(notification, { projectList, controlConfigurationList }) {
        const byId = (acc, val) => ({ ...acc, [val.id]: val });

        const projectsObject = projectList.reduce(byId, {});
        const controlConfigurationObject = controlConfigurationList.reduce(byId, {});

        const when = moment(notification.createdAt).fromNow(true);
        const date = `${moment(notification.createdAt).fromNow()} à ${moment(notification.createdAt).format('LTS')}`;

        const projectName = getProjectName(projectsObject, notification);
        const controlConfigurationName = getControlConfigurationName(controlConfigurationObject, notification);

        const data = {
            when,
            who: notification.createdBy ? notification.createdBy.fullname : 'Utilisateur supprimé',
            date,
            value: notification.data.value,
        };

        const deleted = "<i class='notification-list__deleted'>Supprimé</i>";

        if (is(notification, 'CONTROL_CONFIGURATION', 'ADD')) {
            return {
                ...data,
                text: `Configuration de contrôle ajoutée au projet ${projectName || deleted}`,
                link: projectName ? `app.projectDetail({ projectId: "${getId(notification)}" })` : null,
            };
        } else if (is(notification, 'CONTROL_CONFIGURATION', 'REMOVE')) {
            return {
                ...data,
                text: `Configuration de contrôle supprimée du projet ${projectName || deleted}`,
                link: projectName ? `app.projectDetail({ projectId: "${getId(notification)}" })` : null,
            };
        } else if (is(notification, 'CONTROL_CONFIGURATION', 'EXPIRE')) {
            return {
                ...data,
                text: `La configuration de contrôle ${controlConfigurationName || '-Supprimée-'} va expirer dans ${
                    data.value
                } jour(s)`,
                link: controlConfigurationName
                    ? `app.controlConfigurationList({ controlConfigurationId: "${getId(notification)}" })`
                    : null,
            };
        } else if (is(notification, 'PROJECT', 'ADD')) {
            if (notification.to.id === notification.data.user_id) {
                return {
                    ...data,
                    text: `Vous avez été ajouté au projet ${projectName || deleted}`,
                    link: projectName ? `app.projectDetail({ projectId: "${getId(notification)}" })` : null,
                };
            } else {
                return {
                    ...data,
                    text: `Un nouvel utilisateur a été ajouté au projet ${projectName || deleted}`,
                    link: projectName ? `app.projectDetail({ projectId: "${getId(notification)}" })` : null,
                };
            }
        } else if (is(notification, 'PROJECT', 'REMOVE')) {
            return {
                ...data,
                text: `Le projet ${projectName} à été supprimé`,
                link: `app.projectList()`,
            };
        }

        return data;
    }
}

angular.module('dotic').factory('$notificationService', notificationService);
