angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('change-password', {
        url: '/change-password',
        component: 'changeUserPasswordPage',
        data: {
            authenticationRequired: false,
            roleRequired: [],
        },
    });
});
