import template from './opticalCables.html';
const capacityCable = [
    {
        color: '#5069a9',
        title: 'CX',
        type: 'CX',
    },
    {
        color: '#00b4b4',
        title: 'CO',
        type: 'CO',
    },
    {
        color: '#bbd2e1',
        title: 'CT',
        type: 'CT',
    },
    {
        color: '#006768',
        title: 'CD',
        type: 'CD',
    },
    {
        color: '#e971b1',
        title: 'TD',
        type: 'TD',
    },
    {
        color: '#dd3073',
        title: 'TR',
        type: 'TR',
    },
    {
        color: '#0b99e0',
        title: 'DI',
        type: 'DI',
    },
    {
        color: '#edb349',
        title: 'RA',
        type: 'RA',
    },
    {
        color: '#f88e55',
        title: 'BM',
        type: 'BM',
    },
    {
        color: '#e971b1',
        title: 'LH',
        type: 'LH',
    },
    {
        color: '#bababa',
        title: 'NC',
        type: 'NC',
    },
];

class OpticalCablesController {
    data = null;
    isOld = false;
    loaded = false;
    newNbCables = false;
    sumNbCable = 0;
    types = {};

    constructor($indicatorBoxesService, $indicatorCapacityCableService, $timeout) {
        this._$indicatorBoxesService = $indicatorBoxesService;
        this._$indicatorCapacityCableService = $indicatorCapacityCableService;
        this._$timeout = $timeout;
    }

    $onInit() {
        this._$timeout(() => {
            this.isOld = true;

            if (angular.isArray(this.data)) {
                this.types = this._$indicatorCapacityCableService.sumCapacityCable(this.data);
                this.sumNbCable = this._$indicatorCapacityCableService.getSumNbCable(this.data, [], false);

                this.chartData = this._$indicatorBoxesService.computeBoxNumber(this.data, capacityCable);

                this.newNbCables = angular.isNumber(this.sumNbCable);

                this.isOld = false;
            }

            this.loaded = true;
        });
    }
}

angular.module('dotic').component('opticalCables', {
    controller: OpticalCablesController,
    templateUrl: template,
    bindings: {
        parentRef: '<',
        data: '<',
    },
});
