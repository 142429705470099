import userManualGestionProjetCreerProjetTemplate from './creerProjet.html';
import userManualGestionProjetGestionProjetTemplate from './gestionProjet.html';
import userManualGestionProjetImportProjetTemplate from './importProjet.html';
import userManualGestionProjetInformationProjetTemplate from './informationProjet.html';
import userManualGestionProjetParametrerProjetTemplate from './parametrerProjet.html';
import userManualGestionProjetSubContractorProjectTemplate from './subContractorProject.html';
import userManualGestionProjetSupprimerProjetTemplate from './supprimerProjet.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.gestionProjet', {
            url: '/gestion-projets/',
            templateUrl: userManualGestionProjetGestionProjetTemplate,
            data: {
                title: 'Projets',
                navigation: [{ title: 'Gestion des projets' }],
                previous: 'app.help.subContractorSee',
                next: 'app.help.informationProjet',
            },
        })
        .state('app.help.informationProjet', {
            url: '/gestion-projets/informations',
            templateUrl: userManualGestionProjetInformationProjetTemplate,
            data: {
                title: 'Informations projet',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.gestionProjet',
                next: 'app.help.creerProjet',
            },
        })
        .state('app.help.creerProjet', {
            url: '/gestion-projets/creer',
            templateUrl: userManualGestionProjetCreerProjetTemplate,
            data: {
                title: 'Créer un projet',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.informationProjet',
                next: 'app.help.soustraitantProjet',
            },
        })
        .state('app.help.soustraitantProjet', {
            url: '/gestion-projets/sous-traitant',
            templateUrl: userManualGestionProjetSubContractorProjectTemplate,
            data: {
                title: 'Sous-traitant',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.creerProjet',
                next: 'app.help.importProjet',
            },
        })
        .state('app.help.importProjet', {
            url: '/gestion-projets/import-export',
            templateUrl: userManualGestionProjetImportProjetTemplate,
            data: {
                title: 'Importer ou exporter un projet',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.soustraitantProjet',
                next: 'app.help.parametrerProjet',
            },
        })
        .state('app.help.parametrerProjet', {
            url: '/gestion-projets/parametrer',
            templateUrl: userManualGestionProjetParametrerProjetTemplate,
            data: {
                title: 'Paramétrer un projet',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.importProjet',
                next: 'app.help.supprimerProjet',
            },
        })
        .state('app.help.supprimerProjet', {
            url: '/gestion-projets/supprimer',
            templateUrl: userManualGestionProjetSupprimerProjetTemplate,
            data: {
                title: 'Supprimer un projet',
                navigation: [
                    {
                        title: 'Gestion des projets',
                        sref: 'app.help.gestionProjet',
                    },
                    { title: 'Projets', sref: 'app.help.gestionProjet' },
                ],
                previous: 'app.help.parametrerProjet',
                next: 'app.help.gestionConfigurations',
            },
        });
});
