angular.module('dotic').filter('humanizePhone', () => {
    return (value) => {
        if (!value) {
            return '-';
        }

        if (value.length === 10) {
            return value.replace(/(\d{2})/gi, '$1 ');
        } else if (value.indexOf('+33') === 0) {
            return value.replace('+33', '0').replace(/(\d{2})/gi, '$1 ');
        }

        return value;
    };
});
