angular.module('dotic').filter('humanizeOffer', () => {
    return (value) => {
        switch (value) {
            case 'basic':
                return 'Standard';
            case 'advanced':
                return 'Avancée';
            default:
                return value;
        }
    };
});
