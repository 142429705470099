import template from './change-user-password.component.html';
import { UsersApi } from '../../../../sdk/connect-control-api-v1/src';

class ChangeUserPasswordComponent {
    constructor($apiClientService, $filter, $location, $securityService, $state, $timeout, $toasterService, $window) {
        this._$securityService = $securityService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$window = $window;
        this._translate = $filter('translate');

        this.token = $location.search().token;

        this._usersApi = new UsersApi($apiClientService.client);
    }

    async $onInit() {
        if (!this._$securityService.isTokenValid(this.token)) {
            await this._$state.go('login', {
                redirectUrl: this._$window.location,
            });
        }
    }

    async updatePassword(newPassword) {
        try {
            await this._usersApi.changePasswordWithHttpInfo(
                `Bearer ${this.token}`,
                this._$securityService.decodeToken(this.token).userId,
                { newPassword: newPassword },
            );
            this._$toasterService.success(this._translate('shared.modifiedPassword'));

            this._$timeout(() => {
                this._$state.go('login');
            }, 500);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular.module('dotic').component('changeUserPasswordPage', {
    controller: ChangeUserPasswordComponent,
    templateUrl: template,
});
