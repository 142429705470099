import template from './depositList.html';

class StatisticsDetailsDepositList {
    constructor($filter, $log, $scope, $segmentsService, $tableService, statisticsProvider) {
        this._$log = $log;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$segmentsService = $segmentsService;
        this._statisticsProvider = statisticsProvider;
        this._translate = $filter('translate');

        this.statistic = null;

        this.loading = true;

        this.chart = null;
        this.severities = null;
        this.deposits = [];
        this.filteredDeposits = [];
        this.activeDepositId = null;
        this.activeSeverity = null;

        const filterDiffs = {
            gt: this._translate('statisticComparisonEvolution.filterImprovement'),
            eq: this._translate('statisticComparisonEvolution.filterEqual'),
            lt: this._translate('statisticComparisonEvolution.filterRegression'),
        };

        this.filterOptions = {
            diffs: Object.keys(filterDiffs).map((key) => ({
                key,
                value: filterDiffs[key],
            })),
        };

        this.filter = this.getFilters();

        // Chart
        this.chartSettings = {
            legends: {
                errors: {
                    show: true,
                    text: this._translate('shared.anomaly'),
                },
                controls: {
                    show: true,
                    text: this._translate('shared.control'),
                },
            },
            colors: {
                total: { main: '#1c84c6', light: '#539dc6' },
                major: { main: '#FF7761', light: '#ff8f87' },
                minor: { main: '#FECA0B', light: '#fee940' },
                blocking: { main: '#c72c48', light: '#c7455d' },
            },
        };

        $scope.$watchCollection('$ctrl.filter', (next, prev) => this.onFilterChange(next, prev));
    }

    $onInit() {
        this.getDepositList();
    }

    getDepositList() {
        if (!this.statistic) {
            this._$log.error('Missing statistic props');

            return;
        }

        const id = this.statistic.id || this.statistic._id;

        this._statisticsProvider
            .getGroupedByDeposits(id)
            .then((data) => {
                this.deposits = this.fetchDeposits(data);

                const depositsOrdered = this.deposits.map((deposit) => {
                    return {
                        ...deposit,
                        networkSegments: this._$segmentsService.sortSegments(deposit.networkSegments || []),
                    };
                });

                this.filteredDeposits = [...depositsOrdered];
            })
            .then(() => {
                this.tableDetail = this._$tableService.detail();
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }

    fetchDeposits(data) {
        return data.map((deposit) => ({
            ...deposit,
            networkSegments: this._$segmentsService.setNetworkSegments(deposit.networkSegments),
            diff: {
                minor: this.getDiff(deposit, 'minor'),
                major: this.getDiff(deposit, 'major'),
                blocking: this.getDiff(deposit, 'blocking'),
                total: this.getDiff(deposit),
            },
            total: {
                errors: this.sumControls(deposit, 'errors'),
                total: this.sumControls(deposit, 'total'),
            },
        }));
    }

    sumControls(deposit, type = 'errors') {
        const { controls } = deposit;

        return controls.reduce((acc, control) => {
            return acc + control.total[type];
        }, 0);
    }

    /**
     * @param deposit
     * @param {'errors'|'total'}type
     * @param severity
     * @return {*}
     */
    sumDepositControls(deposit, { type = 'errors', severity = null }) {
        let { controls } = deposit;
        if (severity) {
            controls = controls.filter((c) => c.severity === severity);
        }

        return controls.reduce((acc, control) => acc + control.total[type], 0);
    }

    getDiff(deposit, severity) {
        let diff = null;

        let controls;
        if (severity) {
            controls = deposit.controls.filter((c) => c.severity === severity);
        } else {
            ({ controls } = deposit);
        }

        // Flatten histories of each control point
        const groups = this.groupByHistory({ controls });
        const prevVersion = groups.shift();
        const lastVersionErrors = this.sumDepositControls(deposit, {
            type: 'errors',
            severity,
        });
        if (prevVersion) {
            diff = (prevVersion.total.errors || 0) - (lastVersionErrors || 0);
        }

        return diff;
    }

    spreadBySeverity(deposit) {
        return deposit.controls.reduce(
            (acc, item) => {
                if (item.total.errors === null) {
                    return acc;
                }

                if (!acc[item.severity]) {
                    acc[item.severity] = [];
                }

                acc[item.severity].push(item);

                return acc;
            },
            { blocking: [], major: [], minor: [] },
        );
    }

    /**
     * Calculate sums by deposit id
     * @param deposit
     * @return {unknown[]}
     */
    groupByHistory(deposit) {
        // Flatten histories of each control point
        const histories = deposit.controls
            .reduce((acc, c) => acc.concat(c.history), [])
            .sort((a, b) => a.deposit.version - b.deposit.version);

        const groups = histories.reduce((acc, item) => {
            if (!item.total || item.total.errors === null) {
                return acc;
            }

            if (!acc[item.deposit.id]) {
                acc[item.deposit.id] = {
                    ...item,
                };
            } else {
                acc[item.deposit.id].total = {
                    total: acc[item.deposit.id].total.total + item.total.total,
                    errors: acc[item.deposit.id].total.errors + item.total.errors,
                };
            }

            return acc;
        }, {});

        return Object.values(groups).sort((a, b) => a.deposit.version - b.deposit.version);
    }

    showChart(deposit, severity) {
        const { colors, legends } = this.chartSettings;

        const title = `Historique pour ${deposit.name.replace(/_cv./, '')}`;

        // Reduce main deposits with histories and sort by version
        const controls = deposit.controls.filter((c) => c.severity === severity);
        const groups = this.groupByHistory({ controls });
        const errors = groups.map(({ total }) => total.errors);
        const totals = groups.map(({ total }) => total.total);

        const onLegendClick = function (e, legendItem) {
            const defaultHandler = Chart.defaults.global.legend.onClick.bind(this);
            const key = Object.keys(legends).find((key) => legends[key].text === legendItem.text);
            if (key && legends[key]) {
                legends[key].show = !legends[key].show;
            }

            return defaultHandler(e, legendItem);
        };

        this.activeSeverity = severity;
        this.chart = {
            type: 'line',
            options: {
                legend: {
                    position: 'bottom',
                    onClick: onLegendClick,
                },
                title: {
                    text: title,
                    display: true,
                    position: 'top',
                },
                tooltips: { enabled: true, mode: 'label' },
                scales: {
                    y: { ticks: { beginAtZero: true, maxTicksLimit: 6 } },
                },
            },
            data: {
                labels: groups.map(({ deposit }) => {
                    const createdAt = moment(deposit.createdAt);
                    if (createdAt.isValid()) {
                        return `V${deposit.version} - ${createdAt.format('DD/MM/YYYY')}`;
                    }

                    return `Version ${deposit.version}`;
                }),
                datasets: [
                    {
                        pointHitRadius: 30,
                        label: legends.errors.text,
                        data: errors,
                        fill: true,
                        backgroundColor: colors[severity].light,
                        borderColor: colors[severity].main,
                        borderWidth: 2,
                        hidden: !legends.errors.show,
                        lineTension: 0,
                    },
                    {
                        pointHitRadius: 30,
                        label: legends.controls.text,
                        data: totals,
                        borderColor: colors.total.main,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 2,
                        hidden: !legends.controls.show,
                        lineTension: 0,
                    },
                ],
            },
        };
    }

    toggleDetails(deposit) {
        this.chart = null;
        this.severities = null;
        this.activeDepositId = deposit.id;

        this.tableDetail.toggle(deposit);
        if (deposit) {
            this.severities = this.spreadBySeverity(deposit);
            const defaultSeverity = Object.keys(this.severities).shift();
            this.showChart(deposit, defaultSeverity);
        }
    }

    handleSeverityClick(deposit, severity) {
        if (angular.isFunction(this.onSeverityClick)) {
            this.onSeverityClick(deposit.deposit, severity);
        }
    }

    onFilterChange(next) {
        this.filteredDeposits = this.deposits.filter(this.filterDiff(next.diff)).filter(this.filterSearch(next.search));
    }

    filterDiff(operator) {
        return ({ diff }) => {
            switch (operator) {
                case 'lt':
                    return diff.total < 0;
                case 'eq':
                    return diff.total === 0;
                case 'gt':
                    return diff.total > 0;
                default:
                    return true;
            }
        };
    }

    filterSearch(value) {
        const keyword = value.toLowerCase();

        return (deposit) => deposit.name.toLowerCase().indexOf(keyword) > -1;
    }

    getFilters() {
        return {
            diff: '',
            search: '',
        };
    }

    showCancelFilter() {
        return this.filter.diff || this.filter.search;
    }

    cancelFilters() {
        this.filter = this.getFilters();
        this._$scope.$broadcast('clearFilter');
    }
}

angular.module('dotic').component('statisticsDetailsDepositList', {
    controller: StatisticsDetailsDepositList,
    templateUrl: template,
    bindings: {
        statistic: '=',
        onSeverityClick: '<',
    },
});
