import { formTypeEnum } from '../../models/form.model';
import template from './serverList.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.serverList', {
            controller: 'ServerListController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: template,
            url: '/server/list?search&p',
        })
        .state('app.serverCreate', {
            component: 'serverFormComponent',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                formType: formTypeEnum.CREATION,
            },
            url: '/server/create',
        })
        .state('app.serverDetail', {
            component: 'serverDetailPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            url: '/server/:serverId',
        })
        .state('app.serverEdit', {
            component: 'serverFormComponent',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                formType: formTypeEnum.EDITION,
            },
            url: '/server/:serverId/edit',
        });
});
