import template from './file.html';

class CcFormFileController {
    constructor($element, $filter) {
        this._$element = $element;
        this._translate = $filter('translate');

        this.errors = [];
    }

    doChange() {
        this.updateModel();
        this.onChange();
    }

    updateModel() {
        const target = this._$element.find('.cc-form-file__input').get(0);

        const errors = [];

        if (!target.files[0]) {
            this.filled = false;
            this.fileName = '';

            return;
        }

        this.filled = true;
        if (target.files.length > 1) {
            const filenames = [];
            for (const file of target.files) {
                filenames.push(file.name);
                if (file.$error) {
                    errors.push(file);
                }
            }

            this.fileName = `${filenames.length} ${this._translate('shared.selectedFiles')}`;
            this.model = target.files;
        } else {
            this.fileName = target.files[0].name;
            this.model = target.files[0];

            if (target.files[0].$error) {
                errors.push(target.files[0]);
            }
        }

        this.errors = errors
            .map((error) => {
                if (error.$error === 'maxSize') {
                    return `${this._translate('shared.theFile')} ${error.name} ${this._translate(
                        'shared.maxVolume',
                    )}: ${error.$errorParam}`;
                }

                return null;
            })
            .filter((error) => error);
    }
}

angular.module('dotic').component('ccFormFile', {
    templateUrl: template,
    controller: CcFormFileController,
    bindings: {
        onChange: '&',
        model: '=',
        required: '@',
        accept: '@',
        multiple: '@',
        disabled: '=',
    },
});
