import { sum as radashSum } from 'radash';

import { definitions, thematicsDef } from './customerPDFReport.definitions';
import { IndicatorCableBtiService } from '../../../services/indicatorCableBti.service';
import template from './customerPDFReport.html';

class CustomerPDFReportController {
    company = {};
    controls = null;
    definitions = definitions;
    finished = false;
    formattedReport = null;
    glossary = [];
    hasIndicators = true;
    hasLinkBetweenGridsEnabled = false;
    indicators = null;
    isGraceV3 = false;
    loading = false;
    report = null;

    // thematics
    inoperativeControls = [];
    unrealisedControl = [];

    // Indicators
    barChartOptions = {
        responsive: true,
        tooltips: {
            enabled: false,
        },
        scales: null,
    };
    boxesMaxValue = 0;
    cableBti = IndicatorCableBtiService.cableBtiDefaultValues;
    controlPointBySeverity = null;
    natures = [];
    nbBoxChart = null;
    nbBoxesLabeled = [];
    newNbCables = 0;
    NROTree = null;
    opticalCableSupportTable = [];
    SROTree = null;
    sroLData = null;
    technicalPointTable = [];
    types = null;

    constructor(
        $chartService,
        $companySettings,
        $dataModelService,
        $filter,
        $indicatorBoxesService,
        $indicatorCableBtiService,
        $indicatorCapacityCableService,
        $indicatorMaxValueService,
        $indicatorStatusService,
        $location,
        $pdfReportService,
        $reportService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        reportProvider,
    ) {
        this._$companySettings = $companySettings;
        this._$dataModelService = $dataModelService;
        this._$indicatorBoxesService = $indicatorBoxesService;
        this._$indicatorCableBtiService = $indicatorCableBtiService;
        this._$indicatorCapacityCableService = $indicatorCapacityCableService;
        this._$indicatorMaxValueService = $indicatorMaxValueService;
        this._$indicatorStatusService = $indicatorStatusService;
        this._$location = $location;
        this._$pdfReportService = $pdfReportService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this._reportProvider = reportProvider;

        this.barChartOptions.scales = $chartService.getDefaultScaleConfiguration(true, {
            fontSize: 12,
            minRotation: 8,
        });

        this.forceOpen = {
            isOpen: () => true,
        };
    }

    async $onInit() {
        this.loading = true;
        this.finished = false;

        const initialReport = await this.getReportData();
        this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
            this.report.companyId,
        );
        this.hasIndicators =
            this.company.settings.feature.hasIndicatorsDefault ||
            this.company.settings.feature.hasIndicatorsANCT ||
            this.company.settings.feature.hasindicatorsGC;

        this.controlPointBySeverity = this._$pdfReportService.getControlPointChartData(
            initialReport.report,
            this.hasLinkBetweenGridsEnabled,
        );

        this._$pdfReportService.setScopeNavigation(initialReport.report, this._$scope);
        this._$timeout(() => (this.loading = false));

        this.formattedReport = this._$reportService.format(initialReport);
        this.loadPdfContent();

        this._$timeout(() => {
            this._$pdfReportService.definePageNumbers();
            this.finished = true;
        });
    }

    async getReportData() {
        try {
            const reportData = await this._reportProvider.get(this._$stateParams.reportId);
            this.controls = reportData.controls;
            this.report = reportData.report;
            this.indicators = reportData.indicators;
            this.company = reportData.report.company;

            this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(this.report.deposit);

            return reportData;
        } catch {
            this._$toasterService.error('toaster.error');
        }
    }

    initNbOfBoxes(boxes) {
        if (!boxes.length) {
            boxes.push([
                { value: 0, id: 'pbo' },
                { value: 0, id: 'bpe' },
                { value: 0, id: 'pto' },
            ]);
        }

        return boxes
            .map((box) => {
                return {
                    color: this._$indicatorBoxesService.getColor(box.id),
                    id: box.id,
                    type: this._$indicatorBoxesService.getName(box.id),
                    value: box.value,
                };
            })
            .sort((a, b) => a.type.localeCompare(b.type));
    }

    initBoxes() {
        this.nbBoxesLabeled = this.initNbOfBoxes(this.formattedReport.nbBoxes);
        this.nbBoxChart = this._$indicatorBoxesService.computeBoxNumber(
            this.indicators.nbBPE,
            Object.values(this.nbBoxesLabeled),
        );
        this.boxesMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(this.nbBoxChart);
    }

    initCables() {
        this.opticalCableSupportTable = this._$reportService.computeChunk(
            this.indicators.opticalCableSupport?.table || [],
            15,
        );

        if (!this.indicators.lgCable) {
            return;
        }
        this.types = this._$indicatorCapacityCableService.sumCapacityCable(this.indicators.lgCable);
        this.sumNbCable = this._$indicatorCapacityCableService.getSumNbCable(this.indicators.lgCable, [], false);

        this.opticalCableTable = this._$reportService.computeChunk(this.indicators.lgCable, 20);
    }

    initNROTree() {
        this.NROTree = {
            textIcon: 'NRO',
            color: '#e971b1',
            value: this.indicators.nroTypePhy?.[0]?.type
                ? radashSum(this.indicators.nroTypePhy, (nro) => nro.count)
                : 0,
        };
    }

    initSROTree() {
        const sroL = this.indicators.sroTypePhy.filter((sro) => sro.capacity === 'SROL');

        this.sroLData = {
            textIcon: 'SROL',
            color: '#008080',
            value: radashSum(sroL, (sro) => sro.count),
        };

        this.SROTree = {
            textIcon: 'SRO',
            color: '#00a889',
            value: this.indicators.sroTypePhy?.[0]?.type
                ? radashSum(this.indicators.sroTypePhy, (sro) => sro.count)
                : 0,
        };
    }

    initThematicDescription() {
        Object.keys(this.controls.thematics).forEach((key) => {
            const data = thematicsDef.filter((thematic) => thematic.key === key);
            this.controls.thematics[key].definition = data.find((el) => el.description) ?? {};
        });
    }

    loadPdfContent() {
        this.glossary = this._$reportService.computeChunk(this.formattedReport.glossary, 10);
        this.inoperativeControls = this._$reportService.computeChunk(this.controls.inoperative, 15);
        this.unrealisedControl = this._$reportService.computeChunk(this.controls.notMade, 15);

        this.initBoxes();

        this.controls.thematics = this._$pdfReportService.initThematics(this.controls.thematics);
        this.initThematicDescription();

        this.housingTree = this._$pdfReportService.initHousePlugTree(this.indicators.newPlug, 'house');
        this.plugTree = this._$pdfReportService.initHousePlugTree(this.indicators.newPlug, 'plug');

        this.initNROTree();
        this.initSROTree();

        this.cableBti = this._$indicatorCableBtiService.initCableBti(this.indicators.cableBti || []);

        if (this.indicators.nature_type_phy) {
            this.natures = this.sumByTypePhyAndStatus(this.indicators.nature_type_phy);
            this.technicalPointTable = this._$reportService.computeChunk(this.indicators.nature_type_phy.table, 10);
        }
        this.initCables();
    }

    sumByTypePhyAndStatus(natureTypePhy) {
        return Object.entries(natureTypePhy.nature_support).map(([natureName, data]) => {
            const datasets = natureTypePhy.status.map((state) => {
                const status = this._$indicatorStatusService.getData(state);

                return {
                    color: status?.color || '',
                    count: radashSum(Object.values(data), (value) => value[state] || 0),
                    icon: status?.icon || '',
                    label: status.title,
                    title: status?.title || '',
                };
            });

            return { datasets: datasets, name: natureName };
        });
    }
}

angular.module('dotic').component('customerPDFReportPage', {
    controller: CustomerPDFReportController,
    templateUrl: template,
});
