angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.superAdminStatistics', {
        url: '/superAdmin/statistics',
        component: 'superAdminStatisticsPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
});
