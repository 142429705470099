import template from './controlGridLinked.html';

class ControlGridLinkedController {
    filter = {
        isActivated: '',
        object: [],
        search: '',
        status: [],
        thematic: [],
        typeControlPoint: '',
    };

    constructor($filter, $filterService, $gridControlService, $scope, $state, $tableService, $uibModal) {
        this._$filterService = $filterService;
        this._$gridControlService = $gridControlService;
        this._$state = $state;
        this._$tableService = $tableService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._$uibModal = $uibModal;

        this.controlGridData = [];
        this.filteredControlGridList = [];

        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'status',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'linkBetweenGrids',
                value: 'isActivated',
                placeholder: 'shared.linkBetweenGrids',
                translateKeyLabel: 'shared.linkBetweenGrids',
            },
            {
                allowed: true,
                name: 'objectList',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematicList',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'typeControlPointList',
                value: 'typeControlPoint',
                placeholder: 'shared.type',
                translateKeyLabel: 'shared.type',
            },
        ];

        this.filterValues = {
            severityList: [
                {
                    key: 'minor',
                    value: this._humanizeSeverity('minor'),
                },
                {
                    key: 'major',
                    value: this._humanizeSeverity('major'),
                },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            typeControlPointList: [
                {
                    key: 'true',
                    value: this._translate('shared.genericControlPoint'),
                },
                {
                    key: 'false',
                    value: this._translate('shared.nonGenericControlPoint'),
                },
            ],
            objectList: [],
            linkBetweenGrids: [
                {
                    key: 'true',
                    value: this._translate('shared.complete'),
                },
                {
                    key: 'false',
                    value: this._translate('shared.incomplete'),
                },
            ],
            thematicList: [],
        };

        // *
        // Set filters from urls
        $scope.$on('$locationChangeSuccess', () => this.initFilterIntoUrl($state));

        // *
        // Watch view changes
        $scope.$watchGroup(
            [
                '$ctrl.filter.isActivated',
                '$ctrl.filter.object',
                '$ctrl.filter.search',
                '$ctrl.filter.status',
                '$ctrl.filter.thematic',
                '$ctrl.filter.typeControlPoint',
                '$ctrl.controlGridData',
            ],
            () => this.setScopeGridControlWithFilters(),
        );
    }

    static filterControlGridActive(isActivated) {
        if (isActivated === 'true') {
            return (control) => control.isActive;
        } else if (isActivated === 'false') {
            return (control) => !control.isActive;
        }

        return () => true;
    }

    static filterTypeControlPoint(typeControlPoint) {
        if (typeControlPoint === 'true') {
            return (control) => control.isGenericControlPoint;
        } else if (typeControlPoint === 'false') {
            return (control) => !control.isGenericControlPoint;
        }

        return () => true;
    }

    cancelFilters() {
        this.initFilterIntoUrl({}, false);
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    initFilterIntoUrl(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            isActivated: params.isActivated || '',
            object: this._$filterService.getFilterValue(params.object),
            search: params.search || '',
            status: this._$filterService.getFilterValue(params.status),
            thematic: this._$filterService.getFilterValue(params.thematic),
            typeControlPoint: params.typeControlPoint || '',
        };
    }

    $onChanges() {
        if (!this.controlGridData || !angular.isArray(this.controlGridData)) {
            return;
        }

        this.tableDetail = this._$tableService.detail();
        this.tableSelect = this._$tableService.select();

        this.enabledControlGridData = this.controlGridData.filter(
            (controlPoint) =>
                controlPoint.networkSegments.some((networkSegment) => networkSegment.isEnabled) &&
                controlPoint.phases.some((phaseData) => phaseData.isEnabled),
        );

        this.prepareControlGrid();
    }

    prepareControlGrid() {
        const thematicList = new Map();
        const objectList = new Map();

        this.enabledControlGridData.forEach(({ thematicName, object }) => {
            thematicList.set(thematicName, this._humanizeThematic(thematicName));
            if (angular.isString(object)) {
                objectList.set(object, object);
            }
        });

        const mapToObject = ([key, value]) => ({ key, value });
        this.filterValues.thematicList = Array.from(thematicList, mapToObject);
        this.filterValues.objectList = Array.from(objectList, mapToObject);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    setScopeGridControlWithFilters() {
        const { thematic, object, isActivated, typeControlPoint, search, status } = this.filter;

        this._$state.go('.', {
            isActivated,
            object: object.join(','),
            search,
            status: status.join(','),
            thematic: thematic.join(','),
            typeControlPoint,
        });

        this.filteredControlGridList = this.enabledControlGridData
            .filter(this._$gridControlService.filterSearch(search.toLowerCase()))
            .filter(ControlGridLinkedController.filterControlGridActive(isActivated))
            .filter(ControlGridLinkedController.filterTypeControlPoint(typeControlPoint))
            .filter(this._$filterService.genericMultiFilter(status, 'severity'))
            .filter(this._$filterService.genericMultiFilter(object, 'object'))
            .filter(this._$filterService.genericMultiFilter(thematic, 'thematicName'));
    }

    showAttributes(controlPoint) {
        this._$uibModal.open({
            size: 'sm',
            component: 'attributesListModal',
            resolve: {
                controlPoint: () => controlPoint,
            },
        });
    }
}

angular.module('dotic').component('controlGridLinked', {
    templateUrl: template,
    controller: ControlGridLinkedController,
    bindings: {
        controlGridData: '<',
    },
});
