import template from './filterSearch.html';

/**
 * @ngdoc component
 * @name ccFilterSearch
 *
 * @description
 * A component to show a filter of a list
 *
 * Example:
 *
 * <cc-filter-search value="" placeholder="My field"></cc-filter-search>
 *
 * @param {string} value       The selected key
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterSearch', {
    templateUrl: template,
    bindings: {
        values: '<',
        value: '=',
        placeholder: '@',
        options: '<',
        icon: '@',
        size: '@',
        type: '@',
    },
});
