export default class BoxIndicatorService {
    static defaultBoxes = {
        pbo: {
            type: 'PBO',
            name: 'PBO',
            color: '#5069a9',
            request: "SELECT COUNT(*) as nb FROM t_ebp WHERE bp_typelog = 'PBO'",
        },
        bpe: {
            type: 'BPE',
            name: 'BPE',
            color: '#e971b1',
            request: "SELECT COUNT(*) as nb FROM t_ebp WHERE bp_typelog = 'BPE'",
        },
        pto: {
            type: 'PTO',
            name: 'PTO',
            color: '#00a889',
            request:
                "SELECT COUNT(*) as counter, bp_typelog as type_ebp_pto FROM t_ebp WHERE bp_typelog = 'PTO' GROUP BY bp_typelog",
        },
    };

    constructor($filter) {
        this._translate = $filter('translate');
    }

    computeBoxNumber(array, boxes) {
        if (!angular.isArray(array)) {
            return { datasets: [], labels: [] };
        }

        const labels = this.getLabelsBar(array);
        const countPerType = this.getCountPerType(array);
        const datasets = this.computeDatasets(countPerType, labels, boxes);

        return { datasets: datasets, labels: labels };
    }

    computeDatasets(countPerType, labels, boxes) {
        return Object.keys(countPerType).map((type) => {
            const data = countPerType[type];

            const plotData = labels.map((capacity) => {
                const foundBox = data.find((box) => box.capacity === capacity);
                if (!foundBox) {
                    return 0;
                }

                return foundBox.count;
            });

            const status = boxes.find((boxValue) => boxValue.type === type);
            const backgroundColor = status?.color;

            return {
                label: type,
                backgroundColor: backgroundColor,
                data: plotData,
                count: plotData.reduce((acc, val) => acc + val),
                borderColor: '#fff',
                borderWidth: 1,
                categoryPercentage: 0.75,
                barPercentage: 0.75,
                maxBarThickness: 15,
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    rotation: 0,
                    padding: '0',
                    color: backgroundColor,
                    clamp: true,
                    font: {
                        weight: 'bold',
                    },
                },
            };
        });
    }

    getColor(id) {
        return BoxIndicatorService.defaultBoxes[id]?.color ?? '#000';
    }

    getCountPerType(array) {
        return array.reduce((acc, data) => {
            return {
                ...acc,
                [data.type]: [
                    ...(acc[data.type] ?? []),
                    {
                        capacity: data.capacity,
                        count: data.count,
                    },
                ],
            };
        }, {});
    }

    getLabelsBar(array) {
        return array
            .reduce((acc, box) => {
                if (acc.includes(box.capacity)) {
                    return acc;
                }

                return [...acc, box.capacity];
            }, [])
            .sort((a, b) => {
                return b - a;
            });
    }

    getName(id) {
        return BoxIndicatorService.defaultBoxes[id]?.name ?? id.toLocaleUpperCase();
    }

    getRequest(id) {
        return BoxIndicatorService.defaultBoxes[id]?.request ?? this._translate('shared.missingInDeliverable');
    }
}

angular.module('dotic').factory('$indicatorBoxesService', ($filter) => new BoxIndicatorService($filter));
