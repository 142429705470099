import template from './compareReportPDF.html';

class ReportComparePDFController {
    company = {};
    hasIndicators = true;

    constructor(
        $auth,
        $chartService,
        $companySettings,
        $dataModelService,
        $document,
        $filter,
        $indicatorDistanceRangeService,
        $reportService,
        $stateParams,
        $timeout,
        reportProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        // Used in html, verify usage before deleting
        this._$dataModelService = $dataModelService;
        this._$document = $document;
        this._translate = $filter('translate');
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;

        this._$indicatorDistanceRangeService = $indicatorDistanceRangeService;
        this._$reportService = $reportService;
        this._reportProvider = reportProvider;

        this.companyId = $auth.getPayload().company;

        this.loaded = false;
        this.finished = false;
        this.report = null;
        this.indicators = null;
        this.controls = null;

        this.barChartOptions = {
            responsive: true,
            tooltips: {
                enabled: false,
            },
            scales: $chartService.getDefaultScaleConfiguration(true, { fontSize: 12, minRotation: 8 }),
        };

        this.forceOpen = {
            isOpen: () => true,
        };
    }

    $onInit() {
        const deposit1 = this._$stateParams.deposit1;
        const deposit2 = this._$stateParams.deposit2;
        this.loaded = false;
        this.finished = false;
        this.oldDeposit = false;
        this.numberOfLinesPerTableSum = 0;

        this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(this.companyId).then((result) => {
            this.hasLinkBetweenGridsEnabled = result;
        });

        Promise.all([
            this._reportProvider.getFromDeposit(deposit1),
            this._reportProvider.getFromDeposit(deposit2),
        ]).then((reports) => {
            this._$timeout(() => {
                this.reports = reports;

                this.reports = this.reports.map((report) => {
                    return {
                        ...report,
                        ...this._$reportService.format(report),
                    };
                });
                this.reports[0].report.controls = this.reports[0].dataControls;
                this.reports[1].report.controls = this.reports[1].dataControls;
                this.controls = this._$reportService.getComparisonReports(
                    this.reports[0].report,
                    this.reports[1].report,
                );

                this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(this.reports[0].report.deposit);

                this.loaded = true;
            }).then(() => {
                this.company = this.reports[0].report.company;
                this.hasIndicators =
                    this.company.settings.feature.hasIndicatorsDefault ||
                    this.company.settings.feature.hasIndicatorsANCT ||
                    this.company.settings.feature.hasindicatorsGC;

                this._$timeout(() => {
                    const pages = this._$document.find('.page');
                    for (let i = 0; i < pages.length; i++) {
                        const pageNumbers = pages[i].getElementsByClassName('page__footer-number');
                        if (pageNumbers[0]) {
                            pageNumbers[0].innerHTML = `${i + 1 > 9 ? '' : '0'}${i + 1} / ${pages.length}`;
                        }
                    }

                    this.prepareControlPointChart();
                    this.prepareNbControlChart();

                    this.finished = true;
                });

                const reportOrigin = this.reports[0];
                const reportToCompare = this.reports[1];

                if (
                    !(reportOrigin.indicators.distanceBetweenNroPbo || []).find((data) => data.distanceKm) &&
                    !(reportToCompare.indicators.distanceBetweenNroPbo || []).find((data) => data.distanceKm)
                ) {
                    this.oldDistanceNroPbo = true;
                } else {
                    this.oldDistanceNroPbo = false;

                    if (!reportOrigin.hasSegmentTrDi) {
                        reportOrigin.indicators.distanceBetweenNroPbo = [];
                    }

                    if (!reportToCompare.hasSegmentTrDi) {
                        reportToCompare.indicators.distanceBetweenNroPbo = [];
                    }

                    this.distanceBetweenNroPboChart =
                        this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                            reportOrigin.indicators.distanceBetweenNroPbo ?? [],
                            reportToCompare.indicators.distanceBetweenNroPbo ?? [],
                        );
                }

                if (
                    !(reportOrigin.indicators.distanceBetweenNroSro || []).find((data) => data.distanceKm) &&
                    !(reportToCompare.indicators.distanceBetweenNroSro || []).find((data) => data.distanceKm)
                ) {
                    this.oldDistanceNroSro = true;
                } else {
                    this.oldDistanceNroSro = false;

                    if (!reportOrigin.hasSegmentTrDi) {
                        reportOrigin.indicators.distanceBetweenNroSro = [];
                    }

                    if (!reportToCompare.hasSegmentTrDi) {
                        reportToCompare.indicators.distanceBetweenNroSro = [];
                    }

                    this.distanceBetweenNroSroChart =
                        this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                            reportOrigin.indicators.distanceBetweenNroSro ?? [],
                            reportToCompare.indicators.distanceBetweenNroSro ?? [],
                        );
                }

                if (
                    !(reportOrigin.indicators.distanceBetweenSroPbo || []).find((data) => data.distanceKm) &&
                    !(reportToCompare.indicators.distanceBetweenSroPbo || []).find((data) => data.distanceKm)
                ) {
                    this.oldDistanceSroPbo = true;
                } else {
                    this.oldDistanceSroPbo = false;

                    if (!reportOrigin.hasSegmentDi) {
                        reportOrigin.indicators.distanceBetweenSroPbo = [];
                    }

                    if (!reportToCompare.hasSegmentDi) {
                        reportToCompare.indicators.distanceBetweenSroPbo = [];
                    }

                    this.distanceBetweenSroPboChart =
                        this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                            reportOrigin.indicators.distanceBetweenSroPbo ?? [],
                            reportToCompare.indicators.distanceBetweenSroPbo ?? [],
                        );
                }

                this.result = reports.map((report) => {
                    if (
                        !report.indicators.tables ||
                        !report.indicators.tables[0] ||
                        angular.isUndefined(report.indicators.tables[0]['nb_lignes_par_table'])
                    ) {
                        this._$timeout(() => {
                            this.isOld = true;
                        });
                    } else {
                        this.numberOfLinesPerTableSum = report.indicators.tables.reduce(
                            (acc, table) => acc + parseInt(table.nb_lignes_par_table, 10),
                            0,
                        );
                        this.sumTotalTable = report.indicators.tables.length;
                        this.activeTable = report.indicators.tables.filter((active) => active.activated);
                        this.sumActiveTable = this.activeTable.length;
                        this.activeTableWithRecords = report.indicators.tables.filter(
                            (active) => active.activated && active.nb_lignes_par_table > 0,
                        );
                        this.sumActiveTableWithRecords = this.activeTableWithRecords.length;
                        this.activeTableWithoutRecords = report.indicators.tables.filter(
                            (active) => active.activated && active.nb_lignes_par_table === 0,
                        );
                        this.sumActiveTableWithoutRecords = this.activeTableWithoutRecords.length;

                        this.activeTableArray = {
                            total: this.sumActiveTable,
                            activedRecords: this.sumActiveTableWithRecords,
                            activedNoRecords: this.sumActiveTableWithoutRecords,
                        };

                        this.emptyTable = report.indicators.tables.filter(
                            (active) => active.nb_lignes_par_table === 0 && !active.activated,
                        );
                        this.sumEmptyTable = this.emptyTable.length;

                        this.totalTableTree = {
                            parent: {
                                icon: 'table',
                                color: '#e971b1',
                                value: this.sumTotalTable,
                                text:
                                    this.sumTotalTable > 1
                                        ? `${this._translate('shared.tables')} ${this._translate('shared.totales')}`
                                        : `${this._translate('shared.table')} ${this._translate('shared.totale')}`,
                            },
                            children: [
                                {
                                    icon: 'file-spreadsheet',
                                    color: '#0b99e0',
                                    value: this.activeTableArray.total,
                                    text: this._translate('shared.activeTable', { COUNT: this.sumActiveTable }),
                                },
                                {
                                    icon: 'file-file',
                                    color: '#c8c8d1',
                                    value: this.sumEmptyTable,
                                    text: this._translate('shared.emptyTable', {
                                        COUNT: this.sumEmptyTable,
                                    }),
                                },
                            ],
                        };
                    }

                    const sumTablesName = report.report.abandonnedObjectIndicator?.sumTablesName ?? 0;

                    this.abandonedObjectTree = {
                        parent: {
                            icon: 'recycling-man',
                            color: '#607099',
                            value: sumTablesName,
                            text: this._translate('shared.abandonedObject', { COUNT: this.sumTablesName }),
                        },
                    };

                    const privateHousing = report.indicators.newPlug.sum_nbl_hab || 0;
                    const proHousing = report.indicators.newPlug.sum_nbl_pro || 0;

                    this.housingTree = {
                        parent: {
                            icon: 'ui-home',
                            color: '#5069a9',
                            text: this._translate('shared.house', { COUNT: privateHousing + proHousing }),
                            value: privateHousing + proHousing,
                        },
                        children: [
                            {
                                icon: 'home',
                                color: '#e971b1',
                                text: this._translate('shared.privateIndividual', { COUNT: privateHousing }),
                                value: privateHousing,
                            },
                            {
                                icon: 'industries-4',
                                color: '#0b99e0',
                                text:
                                    proHousing > 1
                                        ? this._translate('shared.professionals')
                                        : this._translate('shared.professional'),
                                value: proHousing,
                            },
                        ],
                    };

                    const privatePlug = report.indicators.newPlug.sum_nbpr_hab || 0;
                    const proPlug = report.indicators.newPlug.sum_nbpr_pro || 0;

                    this.plugTree = {
                        parent: {
                            icon: 'plugin',
                            color: '#5069a9',
                            text: this._translate('shared.potentialCatch', { COUNT: privatePlug + proPlug }),
                            value: privatePlug + proPlug,
                        },
                        children: [
                            {
                                icon: 'home',
                                color: '#e971b1',
                                text: this._translate('shared.privateIndividual', { COUNT: privatePlug }),
                                value: privatePlug,
                            },
                            {
                                icon: 'industries-4',
                                color: '#0b99e0',
                                text:
                                    proPlug > 1
                                        ? this._translate('shared.professionals')
                                        : this._translate('shared.professional'),
                                value: proPlug,
                            },
                        ],
                    };

                    if (!report.indicators.sroTypePhy?.[0]?.type) {
                        this.oldDepositSRO = true;
                    } else {
                        this.oldDepositSRO = false;

                        this.sumSRO = report.indicators.sroTypePhy.reduce((acc, cur) => acc + cur.count, 0);
                    }

                    if (!report.indicators.nroTypePhy?.[0]?.type) {
                        this.oldDepositNRO = true;
                    } else {
                        this.oldDepositNRO = false;

                        this.sumNRO = report.indicators.nroTypePhy.reduce((acc, cur) => acc + cur.count, 0);
                    }

                    if (!report.indicators.nbBti?.[0]?.code) {
                        this.oldNbBti = true;
                    } else {
                        this.oldNbBti = false;
                        this.sumTable = report.indicators.nbBti.length;
                    }

                    if (!report.indicators.cableBti?.[0]?.position) {
                        this.oldCableBti = true;
                    } else {
                        this.oldCableBti = false;
                        this.inPosition = report.indicators.cableBti.filter(
                            (item) => item.position === 'En entrée ',
                        ).length;
                        this.outPosition = report.indicators.cableBti.filter(
                            (item) => item.position === 'En sortie ',
                        ).length;
                    }

                    return {
                        totalTableTree: this.totalTableTree,
                        abandonedObjectTree: this.abandonedObjectTree,
                        sumAbandonnedObject: sumTablesName,
                        housingTree: this.housingTree,
                        plugTree: this.plugTree,
                        oldDepositSRO: this.oldDepositSRO,
                        sumSRO: this.sumSRO,
                        oldDepositNRO: this.oldDepositNRO,
                        sumNRO: this.sumNRO,
                        oldNbBti: this.oldNbBti,
                        oldCableBti: this.oldCableBti,
                        sumTable: this.sumTable,
                        inPosition: this.inPosition,
                        outPosition: this.outPosition,
                        oldDistanceNroPbo: this.oldDistanceNroPbo,
                        distanceBetweenNroPboChart: this.distanceBetweenNroPboChart,
                        oldDistanceNroSro: this.oldDistanceNroSro,
                        distanceBetweenNroSroChart: this.distanceBetweenNroSroChart,
                        oldDistanceSroPbo: this.oldDistanceSroPbo,
                        distanceBetweenSroPboChart: this.distanceBetweenSroPboChart,
                    };
                });
            });
        });
    }

    prepareControlPointChart() {
        const originData = this.reports[0].dataControls;
        const toCompareData = this.reports[1].dataControls;

        const controlActivatedOriginLength = originData.length;
        const controlActivatedToCompareLength = toCompareData.length;

        this.resultControlPointDataOrigin = this.fetchControlPointData(originData);
        this.resultControlPointDataToCompare = this.fetchControlPointData(toCompareData);

        const labelsBasic = [
            this._translate('shared.noAnomalyControl', {
                COUNT: this.resultControlPointDataOrigin.successControls,
            }),
            this._translate('shared.anomalyControlPoint', {
                COUNT: this.resultControlPointDataOrigin.anomalyControls,
            }),
            this._translate('shared.inoperativeControl', {
                COUNT: this.resultControlPointDataOrigin.errorControls,
            }),
        ];

        const dataSetsBasicDataOrigin = [
            this.resultControlPointDataOrigin.successControls,
            this.resultControlPointDataOrigin.anomalyControls,
            this.resultControlPointDataOrigin.errorControls,
        ];

        const dataSetsBasicDataToCompare = [
            this.resultControlPointDataToCompare.successControls,
            this.resultControlPointDataToCompare.anomalyControls,
            this.resultControlPointDataToCompare.errorControls,
        ];

        const dataSetBasicBackgroundColorOrigin = ['#00a889', '#dd3073', '#b2b2ba'];
        const dataSetBasicBackgroundColorToCompare = ['#7fffd4', '#f5bcda', '#e2e2e5'];

        const labels = this.hasLinkBetweenGridsEnabled
            ? [
                  ...labelsBasic,
                  this._translate('shared.notMetControlPoint', {
                      COUNT: this.resultControlPointDataOrigin.warningControls,
                  }),
              ]
            : labelsBasic;

        const datasets = [
            {
                label: 'Dataset 1',
                data: this.hasLinkBetweenGridsEnabled
                    ? [...dataSetsBasicDataOrigin, this.resultControlPointDataOrigin.warningControls]
                    : dataSetsBasicDataOrigin,
                backgroundColor: this.hasLinkBetweenGridsEnabled
                    ? [...dataSetBasicBackgroundColorOrigin, '#5069a9']
                    : dataSetBasicBackgroundColorOrigin,
            },
            {
                label: 'Dataset 2',
                data: this.hasLinkBetweenGridsEnabled
                    ? [...dataSetsBasicDataToCompare, this.resultControlPointDataToCompare.warningControls]
                    : dataSetsBasicDataToCompare,
                backgroundColor: this.hasLinkBetweenGridsEnabled
                    ? [...dataSetBasicBackgroundColorToCompare, '#adb9d8']
                    : dataSetBasicBackgroundColorToCompare,
            },
        ];

        this.controlPointData = {
            bigLegend: {
                figure: `${controlActivatedOriginLength} vs ${controlActivatedToCompareLength}`,
                name: this._translate('shared.controlPoint', { COUNT: controlActivatedOriginLength }),
            },
            labels,
            datasets,
        };
    }

    fetchControlPointData(data) {
        const successControls = 0;
        const anomalyControls = 0;
        const errorControls = 0;
        const warningControls = 0;

        this.controlPointData = {
            successControls,
            anomalyControls,
            errorControls,
            warningControls,
        };

        data.forEach((control) => {
            if (control.faults > 0) {
                this.controlPointData.anomalyControls += 1;
            } else if (control.faults < 0 && !control.warningControlPoint) {
                this.controlPointData.errorControls += 1;
            } else if (control.faults < 0 && control.warningControlPoint && this.hasLinkBetweenGridsEnabled) {
                this.controlPointData.warningControls += 1;
            } else {
                this.controlPointData.successControls += 1;
            }
        });

        return this.controlPointData;
    }

    fetchNbControlData(data) {
        const minorAnomalyNb = 0;
        const majorAnomalyNb = 0;
        const blockingAnomalyNb = 0;
        const minorSuccessNb = 0;
        const majorSuccessNb = 0;
        const blockingSuccessNb = 0;
        const numberTotalControl = 0;
        const anomalyNumberTotalCount = 0;
        const successNumberTotalCount = 0;

        this.nbControlData = {
            minorAnomalyNb,
            majorAnomalyNb,
            blockingAnomalyNb,
            minorSuccessNb,
            majorSuccessNb,
            blockingSuccessNb,
            numberTotalControl,
            anomalyNumberTotalCount,
            successNumberTotalCount,
        };

        data.forEach((control) => {
            this.nbControlData.numberTotalControl += control.total_count;
            this.nbControlData.anomalyNumberTotalCount += control.faults;
            this.nbControlData.successNumberTotalCount += control.valid_count;

            if (control.faults > 0) {
                if (control.severity === 'minor') {
                    this.nbControlData.minorAnomalyNb += control.faults;
                    this.nbControlData.minorSuccessNb += control.valid_count;
                } else if (control.severity === 'major') {
                    this.nbControlData.majorAnomalyNb += control.faults;
                    this.nbControlData.majorSuccessNb += control.valid_count;
                } else {
                    this.nbControlData.blockingAnomalyNb += control.faults;
                    this.nbControlData.blockingSuccessNb += control.valid_count;
                }
            } else if (control.faults === 0) {
                if (control.severity === 'minor') {
                    this.nbControlData.minorSuccessNb += control.valid_count;
                } else if (control.severity === 'major') {
                    this.nbControlData.majorSuccessNb += control.valid_count;
                } else {
                    this.nbControlData.blockingSuccessNb += control.valid_count;
                }
            }
        });

        return this.nbControlData;
    }

    prepareNbControlChart() {
        this.resultNbControlDataOrigin = this.fetchNbControlData(this.reports[0].report.controls);
        this.resultNbControlDataToCompare = this.fetchNbControlData(this.reports[1].report.controls);

        const successNbValuesOrigin = [
            this.resultNbControlDataOrigin.blockingSuccessNb,
            this.resultNbControlDataOrigin.majorSuccessNb,
            this.resultNbControlDataOrigin.minorSuccessNb,
        ];
        const anomalyNbValuesOrigin = [
            this.resultNbControlDataOrigin.blockingAnomalyNb,
            this.resultNbControlDataOrigin.majorAnomalyNb,
            this.resultNbControlDataOrigin.minorAnomalyNb,
        ];

        const successNbValuesToCompare = [
            this.resultNbControlDataToCompare.blockingSuccessNb,
            this.resultNbControlDataToCompare.majorSuccessNb,
            this.resultNbControlDataToCompare.minorSuccessNb,
        ];
        const anomalyNbValuesToCompare = [
            this.resultNbControlDataToCompare.blockingAnomalyNb,
            this.resultNbControlDataToCompare.majorAnomalyNb,
            this.resultNbControlDataToCompare.minorAnomalyNb,
        ];

        const labelsSeverity = [
            this._translate('severity.blocking', { COUNT: 1 }),
            this._translate('severity.major', { COUNT: 1 }),
            this._translate('severity.minor', { COUNT: 1 }),
        ];

        this.anomalySuccessNbBySeverity = {
            labels: labelsSeverity,
            datasets: [
                {
                    label: `${this._translate('shared.nbSuccessfulChecks')} V${
                        this.reports[0].report.deposit.version
                    } `,
                    backgroundColor: '#00a889',
                    hoverBackgroundColor: '#006b58',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: successNbValuesOrigin,
                    stack: 'Stack 0',
                    datalabels: {
                        align: 'top',
                        padding: '1',
                        anchor: 'center',
                        color: '#006b58',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    label: `${this._translate('shared.nbFaultyChecks')} V${this.reports[0].report.deposit.version} `,
                    backgroundColor: '#dd3073',
                    hoverBackgroundColor: ' #941848',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: anomalyNbValuesOrigin,
                    stack: 'Stack 0',
                    datalabels: {
                        align: 'top',
                        padding: '10',
                        anchor: 'center',
                        color: '#941848',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },

                {
                    label: `${this._translate('shared.nbSuccessfulChecks')} V${
                        this.reports[1].report.deposit.version
                    } `,
                    backgroundColor: '#8cf2d0',
                    hoverBackgroundColor: '#00d2ab',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: successNbValuesToCompare,
                    stack: 'Stack 1',
                    datalabels: {
                        align: 'bottom',
                        padding: '2',
                        anchor: 'center',
                        color: '#00d2ab',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    label: `${this._translate('shared.nbFaultyChecks')} V${this.reports[1].report.deposit.version} `,
                    backgroundColor: '#f5bcda',
                    hoverBackgroundColor: ' #e66a9a',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: anomalyNbValuesToCompare,
                    stack: 'Stack 1',
                    datalabels: {
                        align: 'bottom',
                        padding: '10',
                        anchor: 'center',
                        color: '#e66a9a',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };
    }

    severitiesToArray(severities) {
        return this._$reportService.severitiesToArray(severities);
    }
}

angular.module('dotic').component('reportComparePdfPage', {
    controller: ReportComparePDFController,
    templateUrl: template,
});
