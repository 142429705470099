angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.userMetrics', {
        url: '/usersMetrics?p',
        component: 'userMetricsPage',

        params: {
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
});
