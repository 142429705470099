import template from './selectCheckbox.html';

class CcSelectCheckboxController {
    constructor() {
        this.selection = 'none';
        this.all = false;
        this.few = false;
        this.visible = false;
        this.none = true;
    }

    $onChanges() {
        switch (this.selection) {
            case 'all':
                this.all = true;
                this.visible = false;
                this.few = false;
                this.none = false;
                break;
            case 'visible':
                this.all = false;
                this.visible = true;
                this.few = false;
                this.none = false;
                break;
            case 'few':
                this.all = false;
                this.visible = false;
                this.few = true;
                this.none = false;
                break;
            case 'none':
            default:
                this.all = false;
                this.visible = false;
                this.few = false;
                this.none = true;
                break;
        }
    }
}

angular.module('dotic').component('ccSelectCheckbox', {
    templateUrl: template,
    transclude: true,
    controller: CcSelectCheckboxController,
    bindings: {
        selection: '<',
    },
});
