angular.module('dotic').factory('fmeServerProvider', fmeServerProvider);

function fmeServerProvider(ApiProvider, URI_KEYS) {
    return {
        list,
        create,
        remove,
        update,
    };

    function list() {
        return ApiProvider.one(URI_KEYS.FME_SERVER).get();
    }

    function create(server) {
        return ApiProvider.one(URI_KEYS.FME_SERVER).customPOST(server);
    }

    function remove(serverId) {
        return ApiProvider.one(URI_KEYS.FME_SERVER, serverId).remove();
    }

    function update(server) {
        return ApiProvider.one(URI_KEYS.FME_SERVER, server.id).customPUT(server);
    }
}
