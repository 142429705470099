// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/projets/projet-info-1.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 2.1 / {{ 'shared.informations' | translate }} </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span> ! {{ 'helpProject.warningInformation' | translate }}. <br/> ! {{ 'helpProject.warningInformation2' | translate }}. <br/> ! {{ 'helpProject.warningInformation3' | translate }}. <br/> </span> </p> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpProject.projectSection' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpProject.projectSection' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionProjet/informationProjet.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;