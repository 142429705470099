import { omit as radashOmit } from 'radash';

import EnvironmentSettingsService from '../../../services/environment-settings.service';
import { HelpersService } from '../../../services/helpers.service';
import template from './fillGridComparePage.html';

class FillGridComparePage {
    constructor(
        $auth,
        $filter,
        $filterService,
        $location,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        fillGridProvider,
        userMetricsProvider,
    ) {
        this._$state = $state;
        this._$timeout = $timeout;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$toasterService = $toasterService;
        this._$location = $location;
        this._$tableService = $tableService;
        this._userMetricsProvider = userMetricsProvider;
        this.isOrange = EnvironmentSettingsService.isOrange();
        this._helpersService = HelpersService;
        this.totalDifferenceBetweenGrids = 0;
        this.fillGridProvider = fillGridProvider;

        this.loading = true;
        this.isTemplateCompared = false;
        this.isTemplateSelected = false;
        this.gridCompared = {};
        this.gridSelected = {};
        this.fillGrids = [];
        this.dataFillGrids = [];
        this.dataCompared = [];
        this.dataSelected = [];
        this.equalsDataFillGrid = [];
        this.userCompany = $auth.getPayload().company;

        this.notSameData = { gridSelected: [], gridCompared: [] };
        this.sameDataTempIdWithDifference = [];

        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                saveValue: true,
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseList',
                selected: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseActivatedList',
                value: 'phaseActivated',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.state',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'relationList',
                selected: 'relation',
                placeholder: 'filter.all.relation',
                hasSearch: true,
                translateKeyLabel: 'shared.relationship',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'requiredList',
                selected: 'required',
                placeholder: 'filter.all.status',
                saveValue: true,
                translateKeyLabel: 'shared.status',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableList',
                selected: 'table',
                placeholder: 'filter.all.table',
                hasSearch: true,
                translateKeyLabel: 'shared.table',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableTypes',
                value: 'tableType',
                placeholder: 'filter.all.tableType',
                translateKeyLabel: 'shared.tableType',
                saveValue: true,
            },
        ];

        this.filterValues = {
            phaseList: [],
            tableList: [],
            relationList: [],
            severityList: [
                { key: 'minor', value: this._humanizeSeverity('minor') },
                { key: 'major', value: this._humanizeSeverity('major') },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            tableTypes: [
                {
                    key: '^t_',
                    value: this._translate('fillGrid.linkedTable'),
                },
                {
                    key: '^l_',
                    value: this._translate('fillGrid.listedTable'),
                },
            ],
            phaseActivatedList: [
                {
                    key: 'activated',
                    value: this._translate('shared.activated', { GENDER: 'male' }),
                },
                {
                    key: 'disable',
                    value: this._translate('shared.disabled'),
                },
            ],
            requiredList: [
                {
                    key: 'required',
                    value: this._translate('status.required', { COUNT: 1 }),
                },
                {
                    key: 'optional',
                    value: this._translate('status.optional', { COUNT: 1 }),
                },
                {
                    key: 'noRequired',
                    value: this._translate('status.noRequired', { COUNT: 1 }),
                },
            ],
        };

        this.filteredFillGridList = [];
        this.defaultSort = 'nbElementDifferentAdmin';

        this.setDefaultFilter($state.params);

        this.comparisonFilter = {
            attributeDifferentList: $state.params.attributeDifferentList || '',
            phaseDifference: $state.params.phaseDifference || '',
            selectListData: $state.params.selectListData || '',
        };
        this.GRID_TYPE = {
            equal: 'equal',
            notEqual: 'notEqual',
            onlyCompared: 'onlyCompared',
            onlySelected: 'onlySelected',
        };
    }

    async $onInit() {
        this.initKeepPreviousNavigation();
        await this.getFillGrids();
        this.preparedCountData();
        this.preparedFillGrids();
        this.preparedListData();
        this.countTotalDifference();
        this.aggregateDataFillGrid();
        this.prepareFilters();
        this.initInputFilters();
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
        });
    }

    setDefaultFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filters) {
            params.search = this.filters.search;
        }

        this.filters = {
            phase: this._$filterService.getFilterValue(params.phase),
            table: this._$filterService.getFilterValue(params.table),
            search: params.search || '',
            required: this._$filterService.getFilterValue(params.required),
            relation: this._$filterService.getFilterValue(params.relation),
            tableType: params.tableType || '',
            severity: this._$filterService.getFilterValue(params.severity),
            phaseActivated: params.phaseActivated || '',
        };
    }

    initKeepPreviousNavigation() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'comparedFillGrids',
                    title: `${this._translate('shared.comparedGrids')}`,
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.fillGrid'),
                href: this._$state.href('app.gridFillAdminList'),
                key: 'fillGrids',
            },
            allowedPreviousKeys: ['fillGrids', 'fillGrid'],
        });
    }

    async getFillGrids() {
        const { idFillGridCompared, idGridSelected } = this._$state.params;
        this._userMetricsProvider.compareFillingGrids(idGridSelected, idFillGridCompared);

        await Promise.all([
            this.fillGridProvider.getFillGridsToCompare(
                this._$state.params.idFillGridCompared,
                this._$state.params.idGridSelected,
            ),
        ])
            .then((fillGrids) => {
                this.fillGrids = fillGrids[0];
                // First grid
                if (this.fillGrids[0].id === this._$state.params.idGridSelected) {
                    // if selected grid -> match with company user
                    this.fillGrids[0].company =
                        this.fillGrids[0].company.length > 1
                            ? this.fillGrids[0].company.filter(({ _id }) => _id === this.userCompany)
                            : this.fillGrids[0].company;
                } else {
                    // if compared grid -> match with company in params
                    this.fillGrids[0].company =
                        this.fillGrids[0].company.length > 1
                            ? this.fillGrids[0].company.filter(({ _id }) => _id === this._$state.params.company)
                            : this.fillGrids[0].company;
                }

                // Second grid
                if (this.fillGrids[1].id === this._$state.params.idGridSelected) {
                    // if selected grid -> match with company user
                    this.fillGrids[1].company =
                        this.fillGrids[1].company.length > 1
                            ? this.fillGrids[1].company.filter(({ _id }) => _id === this.userCompany)
                            : this.fillGrids[1].company;
                } else {
                    // if compared grid -> match with company in params
                    this.fillGrids[1].company =
                        this.fillGrids[1].company.length > 1
                            ? this.fillGrids[1].company.filter(({ _id }) => _id === this._$state.params.company)
                            : this.fillGrids[1].company;
                }

                this.tableDetail = this._$tableService.detail();
            })
            .then(() => {
                this._$timeout(() => {
                    this.loading = false;
                });
            })
            .catch((e) => {
                this._$toasterService.error(e);
            });
    }

    preparedCountData() {
        this.fillGrids.forEach((fillGrid) => {
            fillGrid.phasesEnabledListOf = HelpersService.getPhasesListWithCountEnabled(fillGrid.data);
        });
    }

    preparedFillGrids() {
        const { fillGrids, cleanDataOfRequest } = this;

        this.gridCompared = fillGrids[0];
        this.gridSelected = fillGrids[1];
        this.isTemplateCompared = fillGrids[0].isTemplate;
        this.isTemplateSelected = fillGrids[1].isTemplate;
        this.idGridCompared = fillGrids[0].id;
        this.idGridSelected = fillGrids[1].id;
        this.dataCompared = cleanDataOfRequest(this.gridCompared.data);
        this.dataSelected = cleanDataOfRequest(this.gridSelected.data);
    }

    cleanDataOfRequest(data) {
        return data.map(
            ({
                attributName,
                condition,
                severity,
                description,
                geometrie,
                phases,
                regex,
                relation,
                required,
                tableName,
                testableCondition,
                /* @orange */
                productionMode,
                objectSI,
                correspondenceSI,
                commentSI,
                /* @orange */
            }) => ({
                attributName,
                condition: condition || undefined,
                severity,
                description,
                geometrie,
                phases: phases.map(({ isEnabled, phaseName }) => ({
                    isEnabled,
                    phaseName,
                })),
                regex,
                relation,
                required,
                tableName,
                tempId: tableName + attributName,
                testableCondition: testableCondition || undefined,
                /* @orange */
                productionMode,
                commentSI,
                correspondenceSI,
                objectSI,
                /* @orange */
            }),
        );
    }

    emptyAttribute() {
        return {
            attributName: '',
            condition: '',
            severity: [],
            description: '',
            geometrie: '',
            phases: [],
            regex: '',
            relation: [],
            required: [],
            tableName: '',
            tempId: '',
            testableCondition: '',
            /* @orange */
            commentSI: '',
            correspondenceSI: '',
            objectSI: '',
            productionMode: '',
            /* @orange */
        };
    }

    preparedListData() {
        /* @orange */
        if (!this.isOrange) {
            this.dataCompared = this.dataCompared.map((element) =>
                radashOmit(element, ['commentSI', 'correspondenceSI', 'objectSI']),
            );

            this.dataSelected = this.dataSelected.map((element) =>
                radashOmit(element, ['commentSI', 'correspondenceSI', 'objectSI']),
            );
        }
        /* @orange */

        this.getSameData(this.dataSelected, this.dataCompared);
        this.getDataCompared(this.dataSelected, this.dataCompared);
        this.getDataOnlySelected();
        this.getDataDifference(this.dataSelected, this.dataCompared);

        this.sameDataTempIdWithDifference = this.preparedDifferentAttributes();
    }

    equals(a, b) {
        if (!angular.isObject(a) || !angular.isObject(b)) {
            return angular.equals(a, b);
        }

        const aKeys = Object.keys(a),
            bKeys = Object.keys(b);

        if (aKeys.length !== bKeys.length) {
            return false;
        }

        if (!aKeys.every((key) => bKeys.includes(key))) {
            return false;
        }

        return aKeys.every((key) => {
            if (key === 'phases') {
                return angular.equals(
                    a.phases.map(({ isEnabled, phaseName }) => ({
                        isEnabled,
                        phaseName,
                    })),
                    b.phases.map(({ isEnabled, phaseName }) => ({
                        isEnabled,
                        phaseName,
                    })),
                );
            }

            if (key === '$$hashKey') {
                return true;
            }

            if (key === 'productionMode') {
                // L'usage d'une éqalité faible ici est volontaire
                return a[key] == b[key];
            }

            return angular.equals(a[key], b[key]);
        });
    }

    getSameData(dataSelected, dataCompared) {
        const { preparedElement } = this;

        return dataCompared.filter((elementFiltered) => {
            if (dataSelected.some((elementFounded) => this.equals(elementFiltered, elementFounded))) {
                this.equalsDataFillGrid.push({
                    ...preparedElement(elementFiltered),
                    isSame: true,
                    hasEqualPhases: true,
                    isDifferentTemplate: false,
                    elementCompared: this.cleanAttributeAndTableKey(elementFiltered),
                    elementSelected: this.cleanAttributeAndTableKey(elementFiltered),
                });
            }
        });
    }

    getDataOnlySelected() {
        const { dataCompared, dataSelected, preparedElement } = this;

        dataSelected.forEach((singleData) => {
            if (dataCompared.some((elementFounded) => singleData.tempId === elementFounded.tempId)) {
                return;
            }

            this.notSameData.gridSelected.push({
                ...preparedElement(singleData),
                isSame: false,
                haveSelectedData: true,
                hasEqualPhases: false,
                isDifferentTemplate: true,
                elementSelected: this.cleanAttributeAndTableKey(singleData),
                elementCompared: this.emptyAttribute(),
            });
        });
    }

    getDataCompared(dataSelected, dataCompared) {
        const { preparedElement } = this;

        dataCompared.forEach((singleData) => {
            if (dataSelected.some((elementFounded) => singleData.tempId === elementFounded.tempId)) {
                return;
            }

            this.notSameData.gridCompared.push({
                ...preparedElement(singleData),
                isSame: false,
                haveComparedData: true,
                isDifferentTemplate: true,
                elementCompared: this.cleanAttributeAndTableKey(singleData),
                elementSelected: this.emptyAttribute(),
            });
        });
    }

    getDataDifference(dataSelected, dataCompared) {
        return dataCompared.filter((elementFiltered) => {
            dataSelected.some((elementFounded) => {
                if (!this.equals(elementFiltered, elementFounded) && elementFiltered.tempId === elementFounded.tempId) {
                    this.sameDataTempIdWithDifference.push(elementFiltered);
                }
            });
        });
    }

    preparedElement(element) {
        return {
            attributName: element.attributName,
            tableName: element.tableName,
            tempId: element.tempId,
        };
    }

    preparedDifferentAttributes() {
        const { dataSelected, isSameDataWithDifference } = this;

        return this.sameDataTempIdWithDifference.map((elementCompared) => {
            const elementSelected = dataSelected.find(isSameDataWithDifference(elementCompared));

            return this.preparedElementDataDifferent(elementCompared, elementSelected);
        });
    }

    preparedElementDataDifferent(elementCompared, elementSelected) {
        const keyAdmin = ['phases', 'severity', 'regex'];
        /* @orange */
        const keyTemplate = this.isOrange
            ? [
                  'description',
                  'geometrie',
                  'relation',
                  'required',
                  'productionMode',
                  'objectSI',
                  'correspondenceSI',
                  'commentSI',
                  'phases',
              ]
            : ['description', 'geometrie', 'relation', 'required', 'productionMode', 'phases'];

        /* @orange */
        return {
            attributName: elementCompared.attributName,
            tableName: elementCompared.tableName,
            tempId: elementCompared.tempId,
            isSame: false,
            haveComparedData: true,
            haveSelectedData: true,
            hasEqualPhases: HelpersService.arePhasesEqual(elementCompared.phases, elementSelected.phases),
            nbElementDifferentAdmin: this.countDataElementDifference(keyAdmin, elementCompared, elementSelected),
            isDifferentTemplate: this.countDataElementDifference(keyTemplate, elementCompared, elementSelected) > 0,
            elementCompared: this.cleanAttributeAndTableKey(elementCompared),
            elementSelected: this.cleanAttributeAndTableKey(elementSelected),
        };
    }

    countDataElementDifference(keyOfComparison, elementDataCompared, elementDataSelected) {
        let counter = 0;
        keyOfComparison.forEach((key) => {
            if (key === 'productionMode') {
                if (angular.isUndefined(elementDataCompared[key])) {
                    elementDataCompared[key] = null;
                }

                if (angular.isUndefined(elementDataSelected[key])) {
                    elementDataSelected[key] = null;
                }
            }

            if (
                key in elementDataCompared &&
                ((key === 'phases' &&
                    !HelpersService.arePhasesEqual(elementDataCompared.phases, elementDataSelected.phases)) ||
                    (key !== 'phases' && !angular.equals(elementDataSelected[key], elementDataCompared[key])))
            ) {
                counter++;
            }
        });

        return counter;
    }

    cleanAttributeAndTableKey(attribute) {
        if (!attribute) {
            return;
        }

        const {
            condition,
            severity,
            description,
            geometrie,
            phases,
            regex,
            relation,
            required,
            testableCondition,
            /* @orange */
            objectSI,
            correspondenceSI,
            commentSI,
            productionMode,
            /* @orange */
        } = attribute;

        return {
            condition,
            severity,
            description: description?.replace(/(\r\n|\n|\r)/gm, ''),
            geometrie,
            phases,
            regex,
            relation,
            required,
            testableCondition,
            /* @orange */
            objectSI,
            correspondenceSI,
            commentSI,
            productionMode,
            /* @orange */
        };
    }

    isSameDataWithDifference(elementCompared) {
        return (elementFound) =>
            elementCompared.tempId === elementFound.tempId && !angular.equals(elementCompared, elementFound);
    }

    countTotalDifference() {
        const {
            sameDataTempIdWithDifference,
            notSameData: { gridCompared, gridSelected },
        } = this;

        const { length: nbDataOfComparedNoSameData } = gridCompared;
        const { length: nbDataOfSelectedNoSameData } = gridSelected;
        const { length: nbDataDifferent } = sameDataTempIdWithDifference;
        this.totalDifferenceBetweenGrids = nbDataOfComparedNoSameData + nbDataOfSelectedNoSameData + nbDataDifferent;
        this.totalIdenticalBetweenGrids = this.equalsDataFillGrid.length;
    }

    aggregateDataFillGrid() {
        const {
            equalsDataFillGrid,
            sameDataTempIdWithDifference,
            notSameData: { gridCompared, gridSelected },
        } = this;
        this.dataFillGrids = [...sameDataTempIdWithDifference, ...gridCompared, ...gridSelected, ...equalsDataFillGrid];

        this.filteredFillGridList = angular.copy(this.dataFillGrids);
    }

    prepareFilters() {
        const { dataFillGrids } = this;
        const phaseList = new Map();
        const tableList = new Map();
        const relationList = new Map();
        if (dataFillGrids.length === 0) {
            return;
        }

        if (dataFillGrids[0].elementCompared) {
            this.foundPhasesFilter('elementCompared', dataFillGrids, phaseList);
        }

        if (dataFillGrids[0].elementSelected) {
            this.foundPhasesFilter('elementSelected', dataFillGrids, phaseList);
        }

        this.foundRelationAndTableFilter(dataFillGrids, tableList, relationList);
        this.filterValues.phaseList = Array.from(phaseList, this.mapToObject);
        this.filterValues.tableList = Array.from(tableList, this.mapToObject);

        const rawRelationList = Array.from(relationList, this.mapToObject);
        this.filterValues.relationList = rawRelationList.filter((r) => r.key.length !== 0 && r.value.length !== 0);
    }

    foundPhasesFilter(list, dataFillGrids, phaseList) {
        dataFillGrids[0][list].phases.forEach(({ phaseName }) => {
            phaseList.set(phaseName, phaseName);
        });
    }

    foundRelationAndTableFilter(dataFillGrids, tableList, relationList) {
        dataFillGrids.forEach(({ elementCompared, elementSelected, tableName }) => {
            if (elementCompared) {
                const { relation: relationCompared } = elementCompared;
                if (tableName && relationCompared) {
                    tableList.set(tableName, tableName);
                }

                if (relationCompared) {
                    relationList.set(relationCompared, relationCompared);
                }
            }

            if (elementSelected) {
                const { relation: relationSelected } = elementSelected;
                if (tableName && relationSelected) {
                    tableList.set(tableName, tableName);
                }

                if (relationSelected) {
                    relationList.set(relationSelected, relationSelected);
                }
            }
        });
    }

    mapToObject([key, value]) {
        return { key, value };
    }

    initInputFilters() {
        this.initListFilter();
        this.initWatchFilters();
        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
        this.showButtonComparisonFilterCancelFilter = HelpersService.isFilterEmpty(this.comparisonFilter);
    }

    initListFilter() {
        this.initInputDifferentBetweenKey();
        this.initFilterSelectListData();
    }

    initInputDifferentBetweenKey() {
        this.attributeDifferentList = [
            {
                key: 'condition',
                value: this._translate('shared.condition'),
            },
            {
                key: 'severity',
                value: this._translate('shared.severity'),
            },
            {
                key: 'description',
                value: this._translate('shared.description'),
            },
            {
                key: 'geometrie',
                value: this._translate('shared.geometry'),
            },
            { key: 'phases', value: this._translate('shared.step') },
            { key: 'regex', value: 'Regex' },
            {
                key: 'relation',
                value: this._translate('shared.relationship'),
            },
            { key: 'required', value: this._translate('shared.status') },
            {
                key: 'testableCondition',
                value: this._translate('shared.testableCondition'),
            },
            /* @orange */
            {
                key: 'objectSI',
                value: this._translate('fillGrid.objectSI'),
            },
            {
                key: 'correspondenceSI',
                value: this._translate('fillGrid.correspondenceSI'),
            },
            {
                key: 'commentSI',
                value: this._translate('fillGrid.commentSI'),
            },
            {
                key: 'productionMode',
                value: this._translate('shared.productionMode'),
            },
            /* @orange */
        ];
    }

    initFilterSelectListData() {
        const { equal, notEqual, onlyCompared, onlySelected } = this.GRID_TYPE;
        const {
            gridSelected: { fullname: fullNameSelected },
            gridCompared: { fullname: fullNameCompared },
        } = this;

        this.selectListData = [
            { key: equal, value: this._translate('shared.same') },
            {
                key: notEqual,
                value: this._translate('shared.different'),
            },
            {
                key: onlyCompared,
                value: this._translate('shared.onlyIn', {
                    NAME: fullNameCompared,
                }),
            },
            {
                key: onlySelected,
                value: this._translate('shared.onlyIn', {
                    NAME: fullNameSelected,
                }),
            },
        ];
    }

    initWatchFilters() {
        this._$scope.$watchGroup(
            [
                '$ctrl.filters.table',
                '$ctrl.filters.phase',
                '$ctrl.filters.search',
                '$ctrl.filters.required',
                '$ctrl.filters.relation',
                '$ctrl.filters.severity',
                '$ctrl.filters.tableType',
                '$ctrl.fillGrid.isAdvanced',
                '$ctrl.filters.phaseActivated',
                '$ctrl.comparisonFilter.selectListData',
                '$ctrl.comparisonFilter.phaseDifference',
                '$ctrl.comparisonFilter.attributeDifferentList',
            ],
            () => this.setScopeGridFillViewWithFilters(),
        );
    }

    setScopeGridFillViewWithFilters() {
        const { phase, table, search, relation, required, severity, tableType, phaseActivated } = this.filters;
        const { selectListData, phaseDifference, attributeDifferentList } = this.comparisonFilter;

        this._$state.go('.', {
            attributeDifferentList,
            severity: severity.join(','),
            phase: phase.join(','),
            phaseActivated,
            phaseDifference,
            relation: relation.join(','),
            required: required.join(','),
            search,
            selectListData,
            table: table.join(','),
            tableType,
        });

        let regexpTableType = null;
        if (tableType && tableType !== '') {
            regexpTableType = new RegExp(tableType, 'i');
        }

        this.filteredFillGridList = this.filtersDataAttributes(selectListData)
            .filter(this.filterSearch(search))
            .filter(this.filterPhases(phase))
            .filter(this.filterTables(table))
            .filter(this.genericMultiFilter(required, 'required'))
            .filter(this.genericMultiFilter(severity, 'severity'))
            .filter(this.genericMultiFilter(relation, 'relation'))
            .filter(this.filterByPhases(phaseActivated))
            .filter(this.filterTableTypes(regexpTableType))
            .filter(this.filterByPhaseDifference(phaseDifference))
            .filter(this.filterKeyDifference(attributeDifferentList));

        this.keyFilterAttributeSelected = this._$tableService.foundValueAttributeDifferent(
            this.attributeDifferentList,
            attributeDifferentList,
        );
        this.showButtonComparisonFilterCancelFilter = HelpersService.isFilterEmpty(this.comparisonFilter);
    }

    filtersDataAttributes(selectListData) {
        const { equal, notEqual, onlyCompared, onlySelected } = this.GRID_TYPE;
        const {
            dataFillGrids,
            equalsDataFillGrid,
            sameDataTempIdWithDifference,
            notSameData: { gridCompared, gridSelected },
        } = this;

        switch (selectListData) {
            case equal:
                return equalsDataFillGrid;
            case notEqual:
                return [...sameDataTempIdWithDifference];
            case onlyCompared:
                return gridCompared;
            case onlySelected:
                return gridSelected;

            default:
                return dataFillGrids;
        }
    }

    filterTables(table) {
        return (attribute) => table.length === 0 || table.includes(attribute.tableName);
    }

    filterByPhaseDifference(phase) {
        return ({ elementCompared, elementSelected }) => {
            const phaseCompared = elementCompared
                ? elementCompared.phases.find(({ phaseName }) => phaseName === phase)
                : false;
            const phaseSelected = elementSelected
                ? elementSelected.phases.find(({ phaseName }) => phaseName === phase)
                : false;

            return (
                phase === '' ||
                !elementCompared ||
                !elementSelected ||
                !phaseCompared ||
                !phaseSelected ||
                phaseCompared.isEnabled !== phaseSelected.isEnabled
            );
        };
    }

    filterKeyDifference(key) {
        return (attribute) => {
            return (
                key === '' ||
                !attribute.elementCompared ||
                !attribute.elementSelected ||
                (!angular.equals(attribute.elementCompared[key], attribute.elementSelected[key]) && key !== 'phases') ||
                (!attribute.hasEqualPhases && key === 'phases')
            );
        };
    }

    filterByPhases(phaseActivated) {
        if (phaseActivated === 'activated') {
            return this.isActive();
        }

        if (phaseActivated === 'disable') {
            return this.isDisable();
        }

        return () => true;
    }

    isActive() {
        return (attribute) =>
            (attribute.elementCompared ? attribute.elementCompared.phases.some((phase) => phase.isEnabled) : false) ||
            (attribute.elementSelected ? attribute.elementSelected.phases.some((phase) => phase.isEnabled) : false);
    }

    isDisable() {
        return (attribute) =>
            (attribute.elementCompared ? attribute.elementCompared.phases.every((phase) => !phase.isEnabled) : true) ||
            (attribute.elementSelected ? attribute.elementSelected.phases.every((phase) => !phase.isEnabled) : true);
    }

    filterSearch(search) {
        const wordSearch = search.toLowerCase();

        return (attribute) =>
            !search ||
            attribute.attributName.toLowerCase().indexOf(wordSearch) > -1 ||
            (attribute.elementCompared
                ? attribute.elementCompared.regex.toLowerCase().indexOf(wordSearch) > -1
                : false) ||
            (attribute.elementSelected
                ? attribute.elementSelected.regex.toLowerCase().indexOf(wordSearch) > -1
                : false);
    }

    filterPhases(phases) {
        return (attr) =>
            phases.length === 0 ||
            (attr.elementCompared
                ? phases.some((phase) =>
                      attr.elementCompared.phases.find((p) => p.phaseName === phase && p.isEnabled === true),
                  )
                : false) ||
            (attr.elementSelected
                ? phases.some((phase) =>
                      attr.elementSelected.phases.find((p) => p.phaseName === phase && p.isEnabled === true),
                  )
                : false);
    }

    filterTableTypes(regexpTableType) {
        return (attribute) =>
            regexpTableType === null ||
            (attribute.elementCompared ? regexpTableType.test(attribute.elementCompared.relation) : false) ||
            (attribute.elementSelected ? regexpTableType.test(attribute.elementSelected.relation) : false);
    }

    genericMultiFilter(values, fieldName) {
        return (attribute) =>
            values.length === 0 ||
            (attribute.elementCompared ? values.includes(attribute.elementCompared[fieldName]) : false) ||
            (attribute.elementSelected ? values.includes(attribute.elementSelected[fieldName]) : false);
    }

    download() {
        const {
            dataFillGrids,
            gridCompared,
            gridSelected,
            comparisonFilter: { phaseDifference },
        } = this;
        if (!phaseDifference) {
            return;
        }

        const gridNames = {
            nameGridCompared: gridCompared.name,
            nameGridSelected: gridSelected.name,
        };
        this.fillGridProvider.downloadComparison(dataFillGrids, gridNames, phaseDifference);
    }

    cancelFilters() {
        this.setDefaultFilter({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filters[filterName] = initialValue;
        this._$state.go('.', this.filters);
    }

    removeSearch() {
        this.filters.search = '';
        this._$state.go('.', this.filters);
    }

    showDiff() {
        this.comparisonFilter.selectListData = 'notEqual';
        this._$timeout(() => {
            const element = angular.element('#array-of-differences');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }
    showSame() {
        this.comparisonFilter.selectListData = 'equal';
        this._$timeout(() => {
            const element = angular.element('#array-of-differences');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }
}

angular.module('dotic').component('fillGridComparePage', {
    controller: FillGridComparePage,
    templateUrl: template,
});
