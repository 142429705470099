import template from './addKnownUserModal.html';

class AddKnownUserModalComponent {
    close;
    required = {
        selectedUser: false,
    };
    resolve = {
        companyId: null,
    };
    selectedUser = null;
    users = [];

    constructor($filter, $timeout, $toasterService, companyProvider, userProvider) {
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._userProvider = userProvider;
        this._translate = $filter('translate');
    }

    $onInit() {
        this._$timeout(async () => {
            this.users = await this.getAllUser();
        });
    }

    async addToCompany() {
        if (!this.selectedUser) {
            this.required.selectedUser = true;

            return;
        }

        try {
            await this._companyProvider.addUserById(this.resolve.companyId, this.selectedUser);
            this._$toasterService.success({
                body: this._translate('userFormModal.userAdded'),
            });

            return this.close({ $value: true });
        } catch (error) {
            this._$toasterService.error(error);

            return this.close({ $value: false });
        }
    }

    closePopup() {
        this.close({ $value: false });
    }

    async getAllUser() {
        try {
            const allUsers = await this._userProvider.getAllWithCompanies();
            const availableUsers = allUsers.filter((user) => {
                const notCurrentCompany = !user.companies
                    .map((company) => company._id)
                    .includes(this.resolve.companyId);

                return user.role !== 'superAdmin' && notCurrentCompany;
            });

            return availableUsers.map((user) => {
                return {
                    key: user._id,
                    value: `${user.fullname} - ${user.email} - ${user.role}`,
                };
            });
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }
}

angular.module('dotic').component('addKnownUserModal', {
    templateUrl: template,
    controller: AddKnownUserModalComponent,
    bindings: {
        close: '&',
        resolve: '<',
    },
});
