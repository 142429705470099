angular.module('dotic').filter('simplifyDate', () => {
    return (value) => {
        if (value) {
            const date = moment(value);
            if (date.isSame(moment(), 'day')) {
                return date.format('HH:mm');
            } else if (date.isSame(moment(), 'year')) {
                return date.format('DD MMM');
            }

            return date.format('DD/MM/YYYY');
        }

        return null;
    };
});
