const capacityCable = [
    {
        type: 'CX',
        title: 'CX',
        backgroundColor: '#5069a9',
    },
    {
        type: 'CO',
        title: 'CO',
        backgroundColor: '#00a889',
    },
    {
        type: 'CT',
        title: 'CT',
        backgroundColor: '#bbd2e1',
    },
    {
        type: 'CD',
        title: 'CD',
        backgroundColor: '#006768',
    },
    {
        type: 'TD',
        title: 'TD',
        backgroundColor: '#e971b1',
    },
    {
        type: 'TR',
        title: 'TR',
        backgroundColor: '#dd3073',
    },
    {
        type: 'DI',
        title: 'DI',
        backgroundColor: '#0b99e0',
    },
    {
        type: 'RA',
        title: 'RA',
        backgroundColor: '#edb349',
    },
    {
        type: 'BM',
        title: 'BM',
        backgroundColor: '#f88e55',
    },
    {
        type: 'LH',
        title: 'LH',
        backgroundColor: '#e971b1',
    },
    {
        type: 'NC',
        title: 'NC',
        backgroundColor: '#bababa',
    },
];

class CapacityCableService {
    getData(type) {
        return capacityCable.find((capacity) => capacity.type.toLowerCase() === type.toLowerCase());
    }

    sumCapacityCable(data) {
        if (!data) {
            return {};
        }

        return data.reduce((acc, entry) => {
            const { type, count, nbCables } = entry;

            if (!acc[type]) {
                const status = this.getData(type);
                const title = status?.title || '';
                const backgroundColor = status?.backgroundColor;

                acc[type] = {
                    type,
                    count: 0,
                    title,
                    nbCables: 0,
                    backgroundColor,
                };
            }

            acc[type].count += count;
            acc[type].nbCables += nbCables;

            return acc;
        }, {});
    }

    reduceType(data) {
        return Object.values(data).reduce((type, nbCable) => {
            for (const [key, value] of Object.entries(nbCable)) {
                if (!type[key]) {
                    type[key] = 0;
                }

                type[key] += value;
            }

            return type;
        }, {});
    }

    getSumNbCable(data1, data2, isComparison) {
        let sumDataOrigin = {};

        if (data1.length) {
            sumDataOrigin = this.reduceType(data1);
        }

        let result = sumDataOrigin.nbCables ?? 0;

        if (isComparison && data2.length) {
            const sumDataToCompare = this.reduceType(data2);

            result = {
                origin: sumDataOrigin.nbCables,
                toCompare: sumDataToCompare.nbCables ?? 0,
            };
        }

        return result;
    }
}

angular.module('dotic').factory('$indicatorCapacityCableService', () => new CapacityCableService());
