import template from './postProcessingAdminDetail.html';

class PostProcessingAdminDetailPage {
    constructor($scope, $state, $stateParams, $toasterService, postProcessingProvider) {
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._postProcessingProvider = postProcessingProvider;

        this.postProcessingId = $stateParams.postProcessingId;
        this.postProcessing = {};

        this.createdBy = {};

        this.loading = true;

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'postProcessingAdminDetail',
                    title: 'Détail du post-traitement',
                    href: this._$state.href('app.postProcessingAdminDetail'),
                },
            ],
            defaultPrevious: {
                title: 'Liste des post-traitements',
                href: this._$state.href('app.postProcessingAdminList'),
                key: 'postProcessingAdminList',
            },
            allowedPreviousKeys: ['postProcessingAdminList'],
        });
    }

    $onInit() {
        this.loading = true;

        this._postProcessingProvider
            .get(this.postProcessingId)
            .then((postProcessing) => {
                this.postProcessing = postProcessing;

                this.createdBy = this.postProcessing.createdBy;

                this.loading = false;
            })
            .catch(this._$toasterService.error);
    }
}

angular.module('dotic').component('postProcessingAdminDetailPage', {
    controller: PostProcessingAdminDetailPage,
    templateUrl: template,
});
