angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.statisticsFillHome', {
            url: '/fillingStatistics',
            component: 'fillingStatisticsPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.fillingStatisticDetail', {
            url: '/fillingStatistics/{fillingStatisticId:[A-Za-z0-9]+}/view?severity&maxPercentageAverage&minPercentageAverage&required&attribute&table&search',
            component: 'fillingStatisticDetailPage',
            params: {
                severity: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                table: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                attribute: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                required: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                minPercentageAverage: {
                    value: '0',
                    squash: true,
                    dynamic: true,
                },
                maxPercentageAverage: {
                    value: '100',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        });
    $stateProvider.state('app.fillingStatisticsCompare', {
        url: '/fillingStatistics/{idStatisticsOrigin:[A-Za-z0-9]+}/{idStatisticsSelected:[A-Za-z0-9]+}/compare?severity&controlsSynthesisDifferentList&differentType&maxPercentageAverage&minPercentageAverage&required&search&table',
        component: 'fillingStatisticsCompare',
        params: {
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            required: {
                value: '',
                squash: true,
                dynamic: true,
            },
            differentType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            controlsSynthesisDifferentList: {
                value: '',
                squash: true,
                dynamic: true,
            },
            minPercentageAverage: {
                value: '0',
                squash: true,
                dynamic: true,
            },
            maxPercentageAverage: {
                value: '100',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
