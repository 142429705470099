import EnvironmentSettingsService from '../../../services/environment-settings.service';
import template from './interventionGridView.html';

class InterventionGridViewPage {
    grid = { name: '', data: [], companies: [] };
    loading = true;

    constructor(
        $authorizationService,
        $excelService,
        $filter,
        $filterService,
        $interventionGridService,
        $location,
        $log,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        $uibModal,
        interventionGridProvider,
    ) {
        this._translate = $filter('translate');
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._$interventionGridService = $interventionGridService;
        this._$log = $log;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._interventionGridProvider = interventionGridProvider;
        this._$uibModal = $uibModal;
        this.isAllowed = $authorizationService.isAllowed;

        this.gridId = $stateParams.gridId;
        this.isSfr = EnvironmentSettingsService.isSfr();
    }

    $onInit() {
        this.loading = true;

        return this.loadGrid().then((grid) => {
            this.grid = grid;
            this.loading = false;
            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'interventionGridView',
                        title: `${this._translate('shared.interventionGrid')}  ${this.grid.name}`,
                        href: this._$state.href('app.interventionGridView'),
                    },
                ],
                defaultPrevious: {
                    key: 'interventionGridsList',
                    title: this._translate('shared.interventionGridList'),
                    href: this._$state.href('app.interventionGridsList'),
                },
                allowedPreviousKeys: ['interventionGridsList'],
            });
        });
    }

    loadGrid() {
        return this._interventionGridProvider.getGrid(this.gridId);
    }

    openCompanyModal(grid) {
        const companiesList = grid.companies;

        this._$uibModal.open({
            size: 'md',
            component: 'companiesListModal',
            resolve: {
                companies: () => companiesList,
            },
        });
    }

    download() {
        const data = this._$interventionGridService.jsonToExcel(this.grid.data);

        return this._$excelService.downloadXLSX({ headers: true }, data, this.grid.name, 'interventionGrid.download');
    }
}

angular.module('dotic').component('interventionGridViewPage', {
    templateUrl: template,
    controller: InterventionGridViewPage,
});
