import template from './depositList.html';

class FillingStatisticDepositList {
    constructor($timeout, fillingStatisticsProvider, $state, $toasterService) {
        this.$fillingStatisticsProvider = fillingStatisticsProvider;
        this.$state = $state;
        this._$toasterService = $toasterService;
        this._$timeout = $timeout;
        this.deposits = [];
        this.fillingStatId = undefined;
        this.loading = true;
        this.statistics = {};
    }
    $onInit() {
        if (!this.statistics) {
            return;
        }

        this.deposits = this.statistics.deposits;
        this.fillingStatId = this.statistics._id;
        this._$timeout(() => {
            this.loading = false;
        });
    }

    async download(idOfType) {
        this.isDownloaded = true;
        try {
            await this.$fillingStatisticsProvider.download(this.fillingStatId, 'report', idOfType);
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this.isDownloaded = false;
        }
    }
}

angular.module('dotic').component('fillingStatisticDepositList', {
    templateUrl: template,
    controller: FillingStatisticDepositList,
    bindings: {
        statistics: '<',
        id: '@',
    },
});
