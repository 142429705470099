import template from './deliveryZoneDetail.html';

class DeliveryZoneDetailPage {
    constructor(
        $auth,
        $authorizationService,
        $deliveryZoneService,
        $excelService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        deliveryZoneProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$excelService = $excelService;
        this._translate = $filter('translate');
        this._$toasterService = $toasterService;
        this._$scope = $scope;
        this._$deliveryZoneService = $deliveryZoneService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$scope = $scope;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._$modalService = $modalService;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;

        this.deliveryZone = {};
        this.deliveryZoneId = $stateParams.deliveryZoneId;
        this.subCompanyList = [];

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    title: 'Details de la zone de livraison',
                    key: 'deliveryZoneDetail',
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: 'Mes zones de livraison',
                href: this._$state.href('app.deliveryZoneList'),
                key: 'deliveryZones',
            },
            allowedPreviousKeys: [
                'deliveryZones',
                'statistics',
                'servers',
                'serverDetail',
                'projectDetail',
                'comparaison',
                'fillingStatistic',
                'fillingStatisticsCompare',
            ],
        });

        this.loading = true;
        userMetricsProvider.openDeliveryZone(this.deliveryZoneId);
    }

    $onInit() {
        this.loading = true;
        this.deliveryZone = {};

        this.getDeliveryZone(this.deliveryZoneId)
            .then((deliveryZone) => {
                const { project } = deliveryZone;
                this.deliveryZone = deliveryZone;

                // Get assigned subcontractors
                this.subCompanyList = deliveryZone.assignments || [];

                // Filter assignments
                if (project?.assignments) {
                    const assignedToProject = project.assignments
                        .filter((a) => a.type === 'subcontractor')
                        .map((a) => a.company);
                    this.subCompanyList = this.subCompanyList.filter((a) => assignedToProject.indexOf(a.id) > -1);
                }

                this.loading = false;
            })
            .catch(this._$toasterService.error);
    }

    getDeliveryZone(deliveryZoneId) {
        return this._deliveryZoneProvider.get(deliveryZoneId);
    }

    isInUse() {
        return this.deliveryZone.countDeposits > 0;
    }

    subCompanyListNames() {
        return this.subCompanyList.map((subCompany) => subCompany.name).join(', ');
    }

    async removeDeliveryZoneAction() {
        if (this.isInUse()) {
            return false;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.deliveryZone', {
                COUNT: 1,
                NAME: this.deliveryZone.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._deliveryZoneProvider.remove(this.deliveryZone.id);
            this._$toasterService.success({
                body: this._translate('removeModal.successDeliveryZone', { COUNT: 1 }),
            });
            await this._$state.go('app.deliveryZoneList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    exportDeliveryZonesToExcel(zone) {
        const data = this._$deliveryZoneService.jsonToExcel(zone);

        return this._$excelService.downloadXLSX(
            { headers: true },
            [data],
            'zones-de-livraison',
            'deliveryZoneList.exported',
        );
    }
}

angular.module('dotic').component('deliveryZoneDetailPage', {
    controller: DeliveryZoneDetailPage,
    templateUrl: template,
});
