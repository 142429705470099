import template from './evolution.html';

class StatisticsDetailEvolution {
    constructor($timeout) {
        this._$timeout = $timeout;
        this.diffAbs = null;
        this.diff = null;
    }

    $onInit() {
        this.diffAbs = Math.abs(this.diff);
    }
}

angular.module('dotic').component('statisticsDetailEvolution', {
    controller: StatisticsDetailEvolution,
    templateUrl: template,
    bindings: {
        diff: '<',
        showValue: '<',
    },
});
