import { ApiClient } from '../../sdk/connect-control-api-v1/dist';

angular.module('dotic').factory('$apiClientService', () => new ApiClientService());

class ApiClientService {
    client = new ApiClient(APP_SETTINGS.baseUrl);

    constructor() {
        this.client.authentications = {
            bearerAuth: {
                type: 'bearer',
                accessToken: () => localStorage.getItem('satellizer_token'),
            },
        };
    }
}
