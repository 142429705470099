// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/app/Logo_ConnectControl_white_1_line.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<toaster-container toaster-options=\"{\n                'closeButton': true,\n                'debug': false,\n                'progressBar': true,\n                'preventDuplicates': false,\n                'position-class': 'toast-bottom-right',\n                'onclick': null,\n                'showDuration': '400',\n                'hideDuration': '1000',\n                'timeOut': '7000',\n                'extendedTimeOut': '1000',\n                'showEasing': 'swing',\n                'hideEasing': 'linear',\n                'showMethod': 'fadeIn',\n                'hideMethod': 'fadeOut'\n            }\"></toaster-container> <div id=\"wrapper\"> <nav class=\"menu\" role=\"navigation\" ng-mouseenter=\"$ctrl.showMenu()\" ng-mouseleave=\"$ctrl.hideMenu()\"> <div class=\"menu__wrapper\"> <div class=\"menu__logo-wrapper\"> <i class=\"menu__logo-icon icofont icofont-navigation-menu\" ng-click=\"$ctrl.toggleMenu()\"></i> <img alt=\"logo\" class=\"menu__logo\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> <div ng-include=\"$ctrl.menuTemplate\" ng-if=\"!$ctrl.helpMenu\"></div> <div ng-include=\"$ctrl.menuHelpTemplate\" ng-if=\"$ctrl.helpMenu\"></div> </div> <div class=\"menu__copyright\"><cc-version></cc-version></div> </nav> <div id=\"page-wrapper\" ng-mousemove=\"$ctrl.forceHideMenu()\" class=\"gray-bg {{ this.stateCurrentName }}\"> <div ng-include=\"$ctrl.topnavbarTemplate\"></div> <div class=\"dotic-content\" ng-if=\"$ctrl.appLoaded\" ui-view></div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/components/common/content.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;