class NumberControlService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    increaseCountingNbControls(
        nbControlData,
        control,
        isStatisticData,
        minorKeyAnomaly,
        minorKeySuccess,
        majorKeyAnomaly,
        majorKeySuccess,
        blockingKeyAnomaly,
        blockingKeySuccess,
    ) {
        if (control.severity === 'minor' && isStatisticData) {
            nbControlData[minorKeyAnomaly] += control.faults;
            nbControlData[minorKeySuccess] += control.success;

            return;
        }

        if (control.severity === 'minor' && !isStatisticData) {
            nbControlData[minorKeyAnomaly] += control.invalid_count;
            nbControlData[minorKeySuccess] += control.valid_count;

            return;
        }

        if (control.severity === 'major' && isStatisticData) {
            nbControlData[majorKeyAnomaly] += control.faults;
            nbControlData[majorKeySuccess] += control.success;

            return;
        }

        if (control.severity === 'major' && !isStatisticData) {
            nbControlData[majorKeyAnomaly] += control.invalid_count;
            nbControlData[majorKeySuccess] += control.valid_count;

            return;
        }

        if (!isStatisticData) {
            nbControlData[blockingKeyAnomaly] += control.invalid_count;
            nbControlData[blockingKeySuccess] += control.valid_count;

            return;
        }

        nbControlData[blockingKeyAnomaly] += control.faults;
        nbControlData[blockingKeySuccess] += control.success;
    }

    fetchNbControlData(data, isStatisticData) {
        const nbControlData = {
            minorAnomalyNb: 0,
            majorAnomalyNb: 0,
            blockingAnomalyNb: 0,
            minorSuccessNb: 0,
            majorSuccessNb: 0,
            blockingSuccessNb: 0,
            numberTotalControl: 0,
            anomalyNumberTotalCount: 0,
            successNumberTotalCount: 0,
        };

        data.forEach((control) => {
            nbControlData.numberTotalControl += control.total;
            nbControlData.anomalyNumberTotalCount += control.faults;
            nbControlData.successNumberTotalCount += control.success;

            if (control.faults) {
                this.increaseCountingNbControls(
                    nbControlData,
                    control,
                    isStatisticData,
                    'minorAnomalyNb',
                    'minorSuccessNb',
                    'majorAnomalyNb',
                    'majorSuccessNb',
                    'blockingAnomalyNb',
                    'blockingSuccessNb',
                );

                return;
            }

            this.increaseCountingNbControls(
                nbControlData,
                control,
                isStatisticData,
                'minorAnomalyNb',
                'minorSuccessNb',
                'majorAnomalyNb',
                'majorSuccessNb',
                'blockingAnomalyNb',
                'blockingSuccessNb',
            );
        });

        return nbControlData;
    }

    initLabelsDoughnut(dataOrigin, dataToCompare, compare) {
        return [
            this._translate('shared.successfulControl', {
                COUNT: compare
                    ? dataOrigin.successNumberTotalCount || dataToCompare.successNumberTotalCount
                    : dataOrigin.successNumberTotalCount,
            }),
            this._translate('shared.anomalyControl', {
                COUNT: compare
                    ? dataOrigin.anomalyNumberTotalCount || dataToCompare.anomalyNumberTotalCount
                    : dataOrigin.anomalyNumberTotalCount,
            }),
        ];
    }

    initDatasetDoughnut(dataOrigin, dataToCompare, compare) {
        const basicDataset = [
            {
                data: [dataOrigin.successNumberTotalCount, dataOrigin.anomalyNumberTotalCount],
                label: 'Dataset 1',
                backgroundColor: ['#00a889', '#dd3073'],
            },
        ];

        if (!compare) {
            return basicDataset;
        }

        return [
            ...basicDataset,
            {
                data: [dataToCompare.successNumberTotalCount, dataToCompare.anomalyNumberTotalCount],
                label: 'Dataset 2',
                backgroundColor: ['#7fffd4', '#f5bcda'],
            },
        ];
    }

    loadAnomalyVsSuccessChart(dataOrigin, dataToCompare, compare) {
        return {
            bigLegend: {
                figure: compare
                    ? `${dataOrigin.numberTotalControl} vs ${dataToCompare.numberTotalControl}`
                    : dataOrigin.numberTotalControl,
                name: this._translate('shared.control', {
                    COUNT: compare
                        ? dataOrigin.numberTotalControl || dataToCompare.numberTotalControl
                        : dataOrigin.numberTotalControl,
                }),
            },
            labels: this.initLabelsDoughnut(dataOrigin, dataToCompare, compare),
            datasets: this.initDatasetDoughnut(dataOrigin, dataToCompare, compare),
        };
    }

    initAnomalySuccessBySeverityData(dataOrigin, dataToCompare, compare) {
        const initDataOrigin = {
            successNbValuesOrigin: [dataOrigin.blockingSuccessNb, dataOrigin.majorSuccessNb, dataOrigin.minorSuccessNb],
            anomalyNbValuesOrigin: [dataOrigin.blockingAnomalyNb, dataOrigin.majorAnomalyNb, dataOrigin.minorAnomalyNb],
        };

        if (!compare) {
            return initDataOrigin;
        }

        return {
            ...initDataOrigin,
            successNbValuesToCompare: [
                dataToCompare.blockingSuccessNb,
                dataToCompare.majorSuccessNb,
                dataToCompare.minorSuccessNb,
            ],
            anomalyNbValuesToCompare: [
                dataToCompare.blockingAnomalyNb,
                dataToCompare.majorAnomalyNb,
                dataToCompare.minorAnomalyNb,
            ],
        };
    }

    initDatasetConfiguration(translateKey, options, data, isOrigin, isComparison) {
        const statNumber = isOrigin ? 'stat 1' : 'stat 2';
        const suffix = isComparison ? statNumber : '';

        return {
            label: `${this._translate(translateKey)} ${suffix}`,
            backgroundColor: options.backgroundColor,
            hoverBackgroundColor: options.hoverBackgroundColor,
            borderColor: '#fff',
            borderWidth: 1,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            maxBarThickness: 15,
            data: data,
            stack: isOrigin ? 'Stack 1' : 'Stack 2',
            datalabels: {
                align: isOrigin ? 'top' : 'bottom',
                padding: options.padding,
                anchor: 'center',
                color: options.dataLabelColor,
                clamp: true,
                font: {
                    weight: 'bold',
                },
            },
        };
    }

    initDataSetBar(initDatas, compare) {
        const basicDataset = [
            this.initDatasetConfiguration(
                'shared.nbSuccessfulChecks',
                {
                    backgroundColor: '#00a889',
                    hoverBackgroundColor: '#006b58',
                    padding: '4',
                    dataLabelColor: '#006b58',
                },
                initDatas.successNbValuesOrigin,
                true,
                compare,
            ),
            this.initDatasetConfiguration(
                'shared.nbFaultyChecks',
                {
                    backgroundColor: '#dd3073',
                    hoverBackgroundColor: '#941848',
                    padding: '14',
                    dataLabelColor: '#941848',
                },
                initDatas.anomalyNbValuesOrigin,
                true,
                compare,
            ),
        ];

        if (!compare) {
            return basicDataset;
        }

        return [
            ...basicDataset,
            this.initDatasetConfiguration(
                'shared.nbSuccessfulChecks',
                {
                    backgroundColor: '#8cf2d0',
                    hoverBackgroundColor: '#00d2ab',
                    padding: '5',
                    dataLabelColor: '#00d2ab',
                },
                initDatas.successNbValuesToCompare,
                false,
                true,
            ),
            this.initDatasetConfiguration(
                'shared.nbFaultyChecks',
                {
                    backgroundColor: '#f5bcda',
                    hoverBackgroundColor: '#e66a9a',
                    padding: '14',
                    dataLabelColor: '#e66a9a',
                },
                initDatas.anomalyNbValuesToCompare,
                false,
                true,
            ),
        ];
    }

    loadAnomalySuccessBySeverityChart(dataOrigin, dataToCompare, compare) {
        const initDatas = this.initAnomalySuccessBySeverityData(dataOrigin, dataToCompare, compare);

        const labelsSeverity = [
            this._translate('severity.blocking', { COUNT: 1 }),
            this._translate('severity.major', { COUNT: 1 }),
            this._translate('severity.minor', { COUNT: 1 }),
        ];

        return {
            labels: labelsSeverity,
            datasets: this.initDataSetBar(initDatas, compare),
        };
    }
}

angular.module('dotic').factory('$indicatorNumberControlService', ($filter) => new NumberControlService($filter));
