export const ThematicEnum = {
    ANCT_VERIFICATIONS: 'verifications anct',
    COHERENCE: 'coherence',
    DATA_AND_MODEL: 'donnees et modele',
    GEOMETRY: 'geometrie',
    GEOMETRY_AND_TOPOLOGY: 'geometrie et topologie',
    JOB: 'metier',
    JOB_COHERENCE: 'coherence metier',
    REGULATORY: 'reglementaire',
};

function transform(thematic) {
    switch (thematic) {
        case ThematicEnum.ANCT_VERIFICATIONS:
            return 'thematic.anctVerifications';
        case ThematicEnum.COHERENCE:
            return 'thematic.coherence';
        case ThematicEnum.DATA_AND_MODEL:
            return 'thematic.dataAndModel';
        case ThematicEnum.GEOMETRY:
            return 'thematic.geometry';
        case ThematicEnum.GEOMETRY_AND_TOPOLOGY:
            return 'thematic.geometryAndTopology';
        case ThematicEnum.JOB:
            return 'thematic.job';
        case ThematicEnum.JOB_COHERENCE:
            return 'thematic.jobCoherence';
        case ThematicEnum.REGULATORY:
            return 'thematic.regulatory';
        default:
            return thematic;
    }
}

angular.module('dotic').filter('humanizeThematic', ($filter) => {
    return (value) => {
        const translate = $filter('translate');
        const res = translate(transform(value.toLowerCase()));
        const firstLetter = res[0].toUpperCase();

        return firstLetter + res.substring(1);
    };
});
