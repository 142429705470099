/**
 * Fix import date Microsoft excel type number into moment date js https://support.microsoft.com/en-us/office/datevalue-function-df8b07d4-7761-4a93-bc33-b7471bbff252
 * @param dateValue
 * @returns {undefined|*}
 * monkey patchalsql is in global scope
 * @example
 * SELECT * , datetime(LAST_MODIFICATION_DATE) as test FROM XLSX(?, {autoExt: false})
 *
 */
import * as Sentry from '@sentry/browser';
import * as xlsx from 'xlsx';
import alasql from 'alasql';

alasql.fn.datetime = function (dateValue) {
    if (dateValue) {
        if (angular.isNumber(dateValue)) {
            return moment('1899-12-30').add(dateValue, 'days');
        }

        if (angular.isString(dateValue)) {
            return moment(dateValue, 'DD/MM/YYYY');
        }
    }

    return undefined;
};
alasql.utils.isBrowserify = false;
alasql.utils.global.XLSX = xlsx;

Sentry.init({
    dsn: 'https://a995624f3e6c201006ff4ea9780e37aa@o131322.ingest.us.sentry.io/4507684354719744',
    enabled: APP_SETTINGS.isSentryEnable,
    environment: APP_SETTINGS.ENV,
    integrations: [
        Sentry.captureConsoleIntegration(),
        Sentry.contextLinesIntegration(),
        Sentry.httpClientIntegration(),
    ],
    release: APP_VERSION,
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    tracePropagationTargets: ['https://app.connect-control.com', 'https://app.staging.connect-control.com'],
});

function runBlock(
    $auth,
    $authorizationService,
    $rootScope,
    $transitions,
    $pendingRequestsProvider,
    $window,
    $location,
    $locale,
) {
    // Set locales
    moment.locale('fr');
    if (moment.locale() !== 'fr') {
        $window.console.debug('Locale moment not loaded correctly', moment.locale());
    }

    $locale.NUMBER_FORMATS.GROUP_SEP = ' ';
    $locale.NUMBER_FORMATS.DECIMAL_SEP = ',';

    if (APP_SETTINGS.ENV === 'prod') {
        $window.console = {
            log: () => {},
            debug: () => {},
            info: () => {},
        };
    }

    $transitions.onStart({}, (transition) => {
        const from = transition.from();
        const to = transition.to();

        if (from.url !== to.url) {
            $window.scroll(0, 0);
            $pendingRequestsProvider.cancelAll();
        }
    });

    /**
     * Redirect main /dashboard to the needed one
     */
    $transitions.onSuccess({ entering: 'app.depositJob-control' }, (transition) => {
        const stateService = transition.router.stateService;

        const authPayload = $auth.getPayload();
        if (!authPayload) {
            return stateService.go('login');
        }

        // TODO: search why there isn't any infinite loop
        const userRole = authPayload.userRole;
        switch (userRole) {
            case 'user':
            case 'admin':
            case 'extendedUser':
                return stateService.go('app.depositJob-control');
            case 'superAdmin':
                return stateService.go('app.dashboardSuperAdmin');
        }
    });

    $transitions.onBefore({}, (transition) => {
        const $state = transition.targetState().$state();
        const authenticationRequired = $state.data.authenticationRequired;
        const roleRequired = $state.data.roleRequired;
        // Handle auth by token
        const access_token = $location.search().access_token;
        if (access_token && access_token !== $auth.getToken()) {
            $auth.setToken(access_token);
            $location.search('access_token', null);
        }

        $rootScope.isAuthenticated = $auth.isAuthenticated();

        if (!authenticationRequired) {
            return;
        }

        const stateService = transition.router.stateService;
        if (!$auth.isAuthenticated()) {
            transition.abort();

            return stateService.go('login');
        } else if (!$authorizationService.isAllowed(roleRequired)) {
            transition.abort();

            return stateService.go('app.dashboard');
        }
    });

    $transitions.onStart({ from: 'app.bulk' }, () => {
        return confirm('Êtes-vous sûr de vouloir quitter la page ?');
    });
}

angular.module('dotic').run(runBlock);
