import template from './errorSiChart.html';

class ErrorSiController {
    constructor($timeout, $indicatorMaxValueService) {
        this._$timeout = $timeout;
        this._$indicatorMaxValueService = $indicatorMaxValueService;

        this.loading = false;
        this.data = null;
        this.sortArray = null;

        this.getHorizontalBarChartOptions = {
            responsive: true,
            scales: {},
            tooltips: {
                intersect: false,
            },
            legend: {
                display: true,
                labels: {
                    fontColor: '#1d5276',
                },
            },
            plugins: {
                datalabels: {
                    color: '#1d5276',
                    align: 'start',
                },
            },
        };
    }

    $onInit() {
        this._$timeout(() => {
            if (angular.isArray(this.data)) {
                this.sortArray = this.data.sort((a, b) => {
                    return b.anomalies.length - a.anomalies.length;
                });
                this.chartData = this.getBarData(this.sortArray);
            } else {
                return null;
            }

            this.loading = true;
        });
    }

    getBarData(array) {
        const dataset = [];
        let labels = [];

        if (angular.isArray(array)) {
            labels = this.getLabelsBar(array);

            dataset.push({
                label: "Nombre d'erreurs du SI ",
                backgroundColor: 'rgba(221, 48, 115, 0.4)',
                hoverBackgroundColor: '#f5005e',
                data: this.getDataBar(array),
                categoryPercentage: 0.7,
                barPercentage: 0.7,
                maxBarThickness: 15,
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    padding: '5',
                    rotation: -90,
                    color: '#1d5276',
                    clamp: true,
                    font: {
                        weight: 'bold',
                    },
                },
            });
        }

        return { datasets: dataset, labels };
    }

    getLabelsBar(array) {
        return array.reduce((acc, label) => {
            if (acc.includes(label.object)) {
                return acc;
            }

            return [...acc, label.object];
        }, []);
    }

    getDataBar(array) {
        return array.reduce((acc, data) => {
            if (acc.includes(data.anomalies.length)) {
                return acc;
            }

            return [...acc, data.anomalies.length];
        }, []);
    }

    onClickAction(customClickEvent) {
        this.onClick({ customClickEvent: customClickEvent });
    }
}

angular.module('dotic').component('errorSiChart', {
    controller: ErrorSiController,
    templateUrl: template,
    bindings: {
        data: '<',
        onClick: '&',
    },
});
