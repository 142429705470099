import BoxesIndicatorService from '../../services/boxIndicator.service';
import template from './box-indicator.html';

const junctionBoxInfo = {
    type: 'BD',
    name: 'BD',
    color: '#ffb935',
    request:
        "SELECT COUNT(*) as nb_bp_derivation, bp_code, bp_codeext from t_cassette tc inner join t_ebp te on te.bp_abddate is null and te.bp_code = tc.cs_bp_code and bp_typelog = 'PBO'where tc.cs_abddate is null and cs_num :: text = '3'group by bp_codeext, bp_code order by bp_codeext",
};

class BoxIndicator {
    chart = null;
    hasJunctionBox = false;
    indicatorPBO = false;
    isOld = false;
    junctionBox = null;
    junctionBoxInfo = junctionBoxInfo;
    loading = true;
    pdf = false;
    table = [];

    constructor($indicatorBoxesService, $timeout) {
        this._$indicatorBoxesService = $indicatorBoxesService;
        this._$timeout = $timeout;
    }

    $onInit() {
        this.loading = true;
        const table = (this.nbBpe || []).map((bpe) => {
            if (bpe.capacity !== '') {
                bpe.capacity = parseInt(bpe.capacity, 10);
            } else {
                bpe.capacity = '-';
            }

            return bpe;
        });

        this.hasJunctionBox = !!this.junctionBox?.length;
        this.cleanData = this.hasJunctionBox ? this.junctionBox.filter((value) => value.nbBpDerivation > 0) : [];

        const isOld = !this.nbBpe || !this.nbBpe[0] || angular.isUndefined(this.nbBpe[0].type);
        const chart = this._$indicatorBoxesService.computeBoxNumber(
            table,
            Object.values(BoxesIndicatorService.defaultBoxes),
        );

        this.junctionBoxSum = this.cleanData.reduce((acc, value) => acc + parseInt(value.nbBpDerivation, 10), 0) ?? 0;

        this._$timeout(() => {
            this.table = table;
            this.chart = chart;

            if (this.pdf) {
                this.height = 510;
            } else {
                this.height = 130 + (this.chart.labels.length + this.table.length) * 27;
            }

            this.isOld = isOld;
            this.loading = false;
        });
    }
}

angular.module('dotic').component('boxIndicator', {
    controller: BoxIndicator,
    templateUrl: template,
    bindings: {
        data: '<',
        junctionBox: '<',
        nbBpe: '<',
        parentRef: '<',
        pdf: '<',
    },
});
