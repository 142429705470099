import template from './controlPointChart.html';

class ControlPointChartController {
    chartSize = 200;
    controlPointBySeverity = null;
    controlPointData = null;
    displayGridLinks = false;
    hasEvent = false;
    horizontalBarChartOptions = {
        responsive: true,
        tooltips: {
            enabled: false,
        },
        scales: {},
    };
    loading = true;
    onlyDoughnut = false;
    reportControls = [];

    constructor($chartService, $indicatorControlPointService) {
        this._$indicatorControlPointService = $indicatorControlPointService;

        this.horizontalBarChartOptions.scales = $chartService.getDefaultScaleConfiguration(true, {
            fontSize: 12,
            minRotation: 8,
        });
    }

    $onChanges() {
        if (angular.isUndefined(this.reportControls)) {
            return null;
        }

        if (angular.isDefined(this.size)) {
            this.chartSize = parseInt(this.size, 10);
        }

        this.loading = true;

        const controlPointSummary = this._$indicatorControlPointService.computeChartData(this.reportControls);

        this.controlPointData = this._$indicatorControlPointService.loadGlobalControlChart(
            controlPointSummary,
            null,
            this.reportControls.length,
            0,
            false,
            this.displayGridLinks,
        );

        this.controlPointBySeverity = this._$indicatorControlPointService.loadControlPointBySeverityChart(
            controlPointSummary,
            null,
            false,
            this.displayGridLinks,
        );

        this.loading = false;
    }

    onClickAction(customClickEvent) {
        if (!this.hasEvent) {
            return;
        }

        this.onClick({ customClickEvent: customClickEvent });
    }
}

angular.module('dotic').component('controlPointChart', {
    controller: ControlPointChartController,
    bindings: {
        displayGridLinks: '<',
        hasEvent: '<',
        notResponsive: '<',
        onClick: '&',
        onlyDoughnut: '<',
        reportControls: '<',
        size: '<',
    },
    templateUrl: template,
});
