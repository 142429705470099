// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/utilisateurs/utilisateur-sous-traitant1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/utilisateurs/utilisateur-sous-traitant2.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 1.2 / {{ 'shared.subcontractor' | translate }} : </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpUser.warningSubcontractor' | translate }}.</span> </p> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpUser.userSection' | translate }} : <img class=\"help-Doc__marginTop help-Doc__img-double-up help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpUser.userSection' | translate }}\"/> <img class=\"help-Doc__marginBottom help-Doc__img-double-down help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'helpUser.userSection' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionUtilisateur/subContractorUser/subContractorSee.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;