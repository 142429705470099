// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/statistic-control/stat-see.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/statistic-control/stat-see-detail.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/statistic-control/stat-regenerer.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 6.1.3 / {{ 'shared.seeDetail' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'shared.controlStatistic' | translate }}\"/> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'shared.controlStatistic' | translate: { COUNT: 1 } }} \"/> <li class=\"help-Doc__subTitre4\"> {{ 'helpStats.oldRequest' | translate }}: <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span>! {{ 'helpStats.warning' | translate }}.</span> </p> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"{{ 'helpStats.oldRequest' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionStatistic/controlStatistic/statSee.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;