import userManualGestionGeoPackageGestionGeoPackageGenerationTemplate from './geopackageGeneration.html';
import userManualGestionGeoPackageGestionGeoPackageInfoTemplate from './geopackageInfo.html';
import userManualGestionGeoPackageGestionGeoPackageTemplate from './geopackage.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.gestionGeoPackage', {
            url: '/gestion-geopackage',
            templateUrl: userManualGestionGeoPackageGestionGeoPackageTemplate,
            data: {
                title: 'Gestion des exports Geopackage',
                navigation: [],
                previous: 'app.help.fillingDelete',
                next: 'app.help.geoPackageInfo',
            },
        })
        .state('app.help.geoPackageInfo', {
            url: '/gestion-geopackage/informations',
            templateUrl: userManualGestionGeoPackageGestionGeoPackageInfoTemplate,
            data: {
                title: 'Gestion des exports Geopackage',
                navigation: [],
                previous: 'app.help.gestionGeoPackage',
                next: 'app.help.geoPackageGenerate',
            },
        })
        .state('app.help.geoPackageGenerate', {
            url: '/gestion-geopackage/generation',
            templateUrl: userManualGestionGeoPackageGestionGeoPackageGenerationTemplate,
            data: {
                title: 'Gestion des exports Geopackage',
                navigation: [],
                previous: 'app.help.geoPackageInfo',
                next: 'app.help.carto',
            },
        });
});
