const statusTable = [
    {
        id: 'A Creer',
        code: 'C',
        title: 'À créer',
        icon: 'architecture-alt',
        color: '#0b99e0',
    },
    {
        id: 'Existant',
        code: 'E',
        title: 'Existant',
        icon: 'search-property',
        color: '#50d0c3',
    },
    {
        id: 'Travaux',
        code: 'T',
        title: 'En travaux',
        icon: 'under-construction-alt',
        color: '#dd3073',
    },
    {
        id: 'En Service',
        code: 'S',
        title: 'En service',
        icon: 'checked',
        color: '#a5d955',
    },
    {
        id: 'Hors Service',
        code: 'H',
        title: 'Hors service',
        icon: 'barricade',
        color: '#e971b1',
    },
    {
        id: 'Abandonne',
        code: 'A',
        title: 'Abandonné',
        icon: 'recycling-man',
        color: '#5069a9',
    },
    {
        id: 'Inconnu',
        code: 'U',
        title: 'Inconnu',
        icon: 'question',
        color: '#bababa',
    },
    {
        id: 'Autre',
        code: '',
        title: 'Autre',
        icon: 'infinite',
        color: '#edb349',
    },
];

class StatusService {
    getData(id) {
        return statusTable.find((s) => s.id.toLowerCase() === id.toLowerCase());
    }

    sumByTypePhy(data) {
        if (!data) {
            return;
        }

        const natures = {};

        data.table.forEach((entry) => {
            const { avancement, count } = entry;
            if (!natures[avancement]) {
                const status = this.getData(avancement);

                const title = status?.title || '';
                const color = status?.color || '';
                const icon = status?.icon || '';

                natures[avancement] = {
                    count: 0,
                    title,
                    color,
                    icon,
                };
            }

            natures[avancement].count += count;
        });

        return natures;
    }
}

angular.module('dotic').factory('$indicatorStatusService', () => new StatusService());
