angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.controlConfigurationList', {
        url: '/controlConfiguration?search&p',
        component: 'controlConfigurationListPage',
        params: {
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    $stateProvider.state('app.controlConfigurationDetail', {
        url: '/configurations/:controlConfigurationId',
        component: 'controlConfigurationDetailPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    $stateProvider.state('app.controlConfigurationGridsLink', {
        url: '/configurations/:controlConfigurationId/link',
        component: 'controlConfigurationGridsLinkPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
