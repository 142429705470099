angular.module('dotic').factory('fillingStatisticsProvider', fillingStatisticsProvider);

function fillingStatisticsProvider(ApiProvider, RestProvider, URI_KEYS) {
    return {
        download,
        generate,
        getOne,
        list,
        remove,
    };

    /**
     * GET /historyFillingStatistics/
     * @param {object} queryParams
     */
    function list(queryParams) {
        return ApiProvider.one(URI_KEYS.HISTORY_FILLING_STATISTICS).one('list').customGET(null, queryParams);
    }

    /**
     * POST /historyFillingStatistics/generate
     */
    async function generate(params) {
        const response = await ApiProvider.withConfig((RestangularConfigurer) => {
            RestangularConfigurer.setFullResponse(true);
        })
            .one(URI_KEYS.HISTORY_FILLING_STATISTICS)
            .one('generate')
            .customPOST(params);

        const { status, data } = response;

        if (status === 202) {
            return {
                ...data,
                exists: true,
            };
        }

        return data;
    }

    /**
     * GET /historyFillingStatistics/:fillingStatId
     * @param {string} fillingStatId
     *
     * @returns {*} Promise
     */

    function getOne(fillingStatId) {
        return ApiProvider.one(URI_KEYS.HISTORY_FILLING_STATISTICS, fillingStatId).get();
    }

    /**
     *
     * @param {String} id
     * @param {String} type 'report' Or 'synthesis'
     * @param {String} idOfType optional
     */
    function download(id, type, idOfType = null) {
        let url;
        if (idOfType) {
            url = `${URI_KEYS.HISTORY_FILLING_STATISTICS}/${id}/download/${type}/type/${idOfType}/idOfType`;
        } else {
            url = `${URI_KEYS.HISTORY_FILLING_STATISTICS}/${id}/download/${type}/type`;
        }

        return RestProvider.downloadFromPath(url);
    }

    /**
     * DELETE /historyFillingStatistics/:fillingStatId
     * @param {String} id
     * @returns {Promise<*>}
     */
    function remove(id) {
        return ApiProvider.one(URI_KEYS.HISTORY_FILLING_STATISTICS, id).remove();
    }
}
