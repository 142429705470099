class ControlGridAdminFormControlController {
    constructor($authorizationService, $phaseService, $segmentsService, $uibModalInstance, controlData, index) {
        this._$uibModalInstance = $uibModalInstance;
        this._$segmentsService = $segmentsService;
        this.index = index;
        this.controlData = controlData;
        this.isAllowed = $authorizationService.isAllowed;

        this.newControl = {
            ...controlData,
        };
        this.phaseList = ['PRE', 'DIA', 'AVP', 'PRO', 'EXE', 'REC', 'MCO'];
        this.segmentList = this._$segmentsService.getAll();
        this.segments = this.segmentList.map((s) => {
            return { segmentName: s, isEnabled: false };
        });

        if (this.controlData.defaultVariable && angular.isArray(this.controlData.variables)) {
            this.newControl.defaultVariable = this.controlData.variables.findIndex(
                (v) => String(v) === String(this.controlData.defaultVariable),
            );
            if (this.newControl.defaultVariable === -1) {
                this.newControl.defaultVariable = 0;
            }
        } else {
            this.newControl.defaultVariable = null;
        }

        if (this.controlData.defaultVariable2 && angular.isArray(this.controlData.variables2)) {
            this.newControl.defaultVariable2 = this.controlData.variables2.findIndex(
                (v) => String(v) === String(this.controlData.defaultVariable2),
            );
            if (this.newControl.defaultVariable2 === -1) {
                this.newControl.defaultVariable2 = 0;
            }
        } else {
            this.newControl.defaultVariable2 = null;
        }

        this.title = 'shared.editControlPoint';
        this.textButton = 'shared.validate';

        this.phaseList = $phaseService.getAll();
        this.phases = this.phaseList.map((p) => {
            return { PhaseName: p, isEnabled: false };
        });

        this.newControl.networkSegments = this.segments.map(
            (obj) => controlData.networkSegments.find((o) => o.segmentName === obj.segmentName) || obj,
        );
    }

    submit() {
        if (this.adminControlForm.$valid === true) {
            const {
                _id,
                thematicName,
                controlPoint,
                object,
                shortDescription,
                description,
                lastModificationDate,
                severity,
                offer,
                tablesSQL,
                usedAttributes,
                variable1Type,
                variableUnit,
                variables,
                variable2Type,
                variable2Unit,
                variable2,
                phases,
                networkSegments,
                idClient,
                variable,
                variables2,
                defaultVariable2,
                defaultVariable,
            } = this.newControl;
            const data = {
                thematicName,
                controlPoint,
                object,
                shortDescription,
                description,
                lastModificationDate,
                severity,
                offer,
                tablesSQL,
                usedAttributes,
                variable1Type,
                variables,
                variable,
                variableUnit: variableUnit,
                variable2Type,
                variables2,
                variable2Unit,
                phases,
                networkSegments,
                idClient,
                defaultVariable2,
                variable2,
                defaultVariable,
            };

            if (_id) {
                data._id = _id;
            }

            if (this.index !== null) {
                data.index = this.index;
            }

            switch (variable1Type) {
                case 'input':
                    data.defaultVariable = variable;
                    data.variable = variable;
                    break;
                case 'select':
                    data.defaultVariable = variables[defaultVariable];
                    data.variables = variables;
                    data.variable = null;
                    break;
                case 'NC':
                default:
                    data.variable = 'NC';
                    data.defaultVariable = 'NC';
                    break;
            }

            switch (variable2Type) {
                case 'input':
                    data.defaultVariable2 = variable2;
                    data.variable2 = variable2;
                    break;
                case 'select':
                    data.defaultVariable2 = variables2[defaultVariable2];
                    data.variables2 = variables2;
                    data.variable2 = null;
                    break;
                case 'NC':
                    data.variable2 = 'NC';
                    data.defaultVariable2 = 'NC';
                    break;
                default:
                    break;
            }

            this.closePopup(data);
        }
    }

    cancel() {
        this._$uibModalInstance.dismiss();
    }

    findIndex(name, type) {
        if (type === 'phase') {
            return this.newControl.phases.findIndex((phase) => phase.phaseName === name);
        } else if (type === 'segment') {
            return this.newControl.networkSegments.findIndex((segment) => segment.segmentName === name);
        }
    }

    switchPhase(phaseName) {
        this.newControl.phases[this.findIndex(phaseName, 'phase')].isEnabled =
            !this.newControl.phases[this.findIndex(phaseName, 'phase')].isEnabled;
    }

    switchSegment(segmentName) {
        this.newControl.networkSegments[this.findIndex(segmentName, 'segment')].isEnabled =
            !this.newControl.networkSegments[this.findIndex(segmentName, 'segment')].isEnabled;
    }

    addEntryToVariables1() {
        this.newControl.variables.push(null);
    }

    addEntryToVariables2() {
        this.newControl.variables2.push(null);
    }

    closePopup(data) {
        this._$uibModalInstance.close(data);
    }
}

angular.module('dotic').controller('ControlGridAdminFormControlController', ControlGridAdminFormControlController);
