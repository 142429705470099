// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../assets/images/app/Logo_ConnectControl_White_2_lines.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"text-center loginscreen\"> <img class=\"loginscreen-logo\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"Logo ConnectControl\"/> <h3 class=\"loginscreen-title loginscreen-title-big\">{{ 'welcome.accountCreated' | translate }}</h3> <p class=\"loginscreen-message pre-line\">{{ 'welcome.message-body' | translate }}</p> <p class=\"loginscreen-message\"> <a target=\"_blank\" ng-href=\"{{ $ctrl.mailTo }}\">{{ $ctrl.supportEmail }}</a> </p> <p class=\"loginscreen-message pre-line\">{{ 'welcome.message-footer' | translate }}</p> <cc-button size=\"big\" ng-click=\"$ctrl.logout()\">{{ 'shared.logout' | translate }}</cc-button> <div class=\"loginscreen-copyright m-t-20\"><cc-version></cc-version></div> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/account/welcome/welcome.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;