angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.dataModelList', {
        url: '/dataModels?search&p',
        params: {
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'dataModelListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.dataModelEdit', {
        url: '/dataModels/:dataModelId?search&name&p',
        params: {
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            name: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'dataModelEditPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
});
