import EnvironmentSettingsService from './environment-settings.service';

angular.module('dotic').factory('$interventionGridService', interventionGridService);

function interventionGridService() {
    return {
        excelToJson: excelToJson,
        jsonToExcel: jsonToExcel,
    };

    function excelToJson(rows) {
        return rows
            .filter((row) => row.CONTROLE)
            .map((row) => {
                return {
                    controlPoint: row.CONTROLE,
                    errorCode: row.CONDITION_TRAITEMENT,
                    possibleCause: row.MODE_OPERATOIRE,
                    recipientOperatingMode: row.DESTINATAIRE,
                    targetSIOperatingMode: row.CIBLE_SI || row.CONTROLE_COLLECTIVITE,
                };
            });
    }

    function jsonToExcel(data) {
        return data.map((obj) => {
            const lastColumn = EnvironmentSettingsService.isSfr() ? 'CONTROLE_COLLECTIVITE' : 'CIBLE_SI';

            return {
                CONTROLE: obj.controlPoint,
                CONDITION_TRAITEMENT: obj.errorCode || '',
                MODE_OPERATOIRE: obj.possibleCause || '',
                DESTINATAIRE: obj.recipientOperatingMode || '',
                [lastColumn]: obj.targetSIOperatingMode || '',
            };
        });
    }
}
