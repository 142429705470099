angular.module('dotic').factory('interventionGridProvider', interventionGridProvider);

function interventionGridProvider(ApiProvider, RestProvider, URI_KEYS) {
    return {
        createGrid,
        getGrids,
        getGrid,
        updateGrid,
        deleteGrid,
    };

    function getGrids() {
        return RestProvider.get([URI_KEYS.INTERVENTION_GRID]);
    }

    function getGrid(gridId) {
        return RestProvider.get([URI_KEYS.INTERVENTION_GRID, gridId]);
    }

    function createGrid(grid) {
        return ApiProvider.one(URI_KEYS.INTERVENTION_GRID).customPOST(grid);
    }

    function updateGrid(gridId, grid) {
        return ApiProvider.one(URI_KEYS.INTERVENTION_GRID, gridId).customPUT(grid);
    }

    function deleteGrid(gridId) {
        return ApiProvider.one(URI_KEYS.INTERVENTION_GRID, gridId).remove();
    }
}
