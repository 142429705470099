import { pick as radashPick } from 'radash';

import template from './controlPointFormModal.html';

class ControlPointFormModalComponent {
    close;
    controlForm = {};
    defaultVariable1Index = null;
    defaultVariable2Index = null;
    dismiss;
    isEditMode = false;
    newControl = {};
    resolve = {
        controlData: null,
        index: null,
        objectList: null,
        thematicList: null,
    };

    constructor($phaseService, $segmentsService, $toasterService, gridControlProvider) {
        this._$toasterService = $toasterService;
        this._$phaseService = $phaseService;
        this._$segmentsService = $segmentsService;
        this._gridControlProvider = gridControlProvider;
    }

    async $onInit() {
        this.loading = true;

        this.defaultVariable1Index = this.getDefaultVariableIndex(
            this.resolve.controlData?.defaultVariable,
            this.resolve.controlData?.variables,
        );
        this.defaultVariable2Index = this.getDefaultVariableIndex(
            this.resolve.controlData?.defaultVariable2,
            this.resolve.controlData?.variables2,
        );

        this.isEditMode = this.resolve.index !== null && this.resolve.controlData;

        if (this.isEditMode) {
            this.newControl = this.initControlPointEdition();
            this.title = 'shared.editControlPoint';
            this.textButton = 'shared.validate';

            this.loading = false;

            return;
        }

        this.newControl = await this.initControlPoint();
        this.title = 'controlGridFormControl.addControlPoint';
        this.textButton = 'shared.create';

        this.loading = false;
    }

    addEntryToVariables1() {
        this.newControl.variables = [...this.newControl.variables, null];
    }

    addEntryToVariables2() {
        this.newControl.variables2 = [...this.newControl.variables2, null];
    }

    cancel() {
        this.close({});
    }

    closePopup(formData) {
        this.close({ $value: formData });
    }

    getDefaultVariableIndex(defaultVariable = null, variables = []) {
        if (!defaultVariable || !variables.length) {
            return null;
        }

        const defaultVariableIndex = variables.findIndex((variable) => String(variable) === String(defaultVariable));

        return Math.max(0, defaultVariableIndex);
    }

    async initControlPoint() {
        const defaultNewControl = {
            networkSegments: this._$segmentsService.getAll().map((segment) => {
                return { segmentName: segment, isEnabled: false };
            }),
            phases: this._$phaseService.getAll().map((phase) => {
                return { phaseName: phase, enabled: false };
            }),
            variables: [null],
            variables2: [null],
            tablesSQL: '',
            usedAttributes: '',
        };

        try {
            const defaultControlGrid = await this._gridControlProvider.getNew();

            return {
                data: [defaultControlGrid],
                ...defaultNewControl,
            };
        } catch (error) {
            if (error.data?.message) {
                this._$toasterService.error(error.data.message);
            }

            return defaultNewControl;
        }
    }

    initControlPointEdition() {
        const networkSegments = this._$segmentsService
            .getAll()
            .map((segment) => {
                return { segmentName: segment, isEnabled: false };
            })
            .map((segment) => {
                return (
                    this.resolve.controlData.networkSegments.find(
                        (networkSegment) => networkSegment.segmentName === segment.segmentName,
                    ) || segment
                );
            });

        const phases = this._$phaseService
            .getAll()
            .map((phase) => {
                return { phaseName: phase, enabled: false };
            })
            .map((phase) => {
                return (
                    this.resolve.controlData.phases.find(
                        (phaseToCompare) => phaseToCompare.phaseName === phase.phaseName,
                    ) || phase
                );
            });

        return {
            ...radashPick(this.resolve.controlData, [
                '_id',
                'controlPoint',
                'defaultVariable',
                'defaultVariable2',
                'description',
                'idClient',
                'object',
                'offer',
                'severity',
                'shortDescription',
                'thematicName',
                'variable1Type',
                'variable2Type',
                'variable2Unit',
                'variables',
                'variables2',
                'variableUnit',
            ]),
            lastModificationDate: new Date(),
            networkSegments: networkSegments,
            phases: phases,
            tablesSQL: this.resolve.controlData.tablesSQL.join(','),
            usedAttributes: this.resolve.controlData.usedAttributes.join(','),
            variable: this.resolve.controlData.variable ?? this.resolve.controlData.defaultVariable,
            variable2: this.resolve.controlData.variable2 ?? this.resolve.controlData.defaultVariable2,
        };
    }

    isNetworkSegmentEnabled(segmentName) {
        return this.newControl.networkSegments?.some((networkSegment) => {
            return networkSegment.isEnabled && networkSegment.segmentName === segmentName;
        });
    }

    isPhaseEnabled(phaseName) {
        return this.newControl.phases?.some((phase) => {
            return phase.isEnabled && phase.phaseName === phaseName;
        });
    }

    submit() {
        if (!this.controlForm.$valid) {
            return;
        }

        const formData = {
            ...radashPick(this.newControl, [
                'controlPoint',
                'description',
                'idClient',
                'lastModificationDate',
                'networkSegments',
                'object',
                'offer',
                'phases',
                'severity',
                'shortDescription',
                'thematicName',
                'variable',
                'variable1Type',
                'variable2',
                'variable2Type',
                'variable2Unit',
                'variables',
                'variables2',
                'variableUnit',
            ]),
            tablesSQL: this.newControl.tablesSQL.split(',').map((table) => table.trim()),
            usedAttributes: this.newControl.usedAttributes.split(',').map((usedAttribute) => usedAttribute.trim()),
        };

        if (this.newControl._id) {
            formData._id = this.newControl._id;
        }

        if (!Number.isNaN(this.resolve.index)) {
            formData.index = this.resolve.index;
        }

        switch (this.newControl.variable1Type) {
            case 'input':
                formData.defaultVariable = this.newControl.variable;
                break;
            case 'select':
                formData.defaultVariable = this.newControl.variables[this.defaultVariable1Index];
                formData.variable = null;
                break;
            case 'NC':
            default:
                formData.variable = 'NC';
                formData.defaultVariable = 'NC';
                formData.variableUnit = 'NC';
        }

        switch (this.newControl.variable2Type) {
            case 'input':
                formData.defaultVariable2 = this.newControl.variable2;
                break;
            case 'select':
                formData.defaultVariable2 = this.newControl.variables2[this.defaultVariable2Index];
                formData.variable2 = null;
                break;
            case 'NC':
            default:
                formData.variable2 = 'NC';
                formData.defaultVariable2 = 'NC';
                formData.variable2Type = 'NC';
        }

        this.closePopup(formData);
    }

    switchPhase(phaseName) {
        const phaseIndex = this.newControl.phases.findIndex((phase) => {
            return phase.phaseName === phaseName;
        });

        this.newControl.phases[phaseIndex].isEnabled = !this.newControl.phases[phaseIndex].isEnabled;
    }

    switchSegment(segmentName) {
        const segmentIndex = this.newControl.networkSegments.findIndex((segment) => {
            return segment.segmentName === segmentName;
        });

        this.newControl.networkSegments[segmentIndex].isEnabled =
            !this.newControl.networkSegments[segmentIndex].isEnabled;
    }
}

angular.module('dotic').component('controlPointFormModal', {
    templateUrl: template,
    controller: ControlPointFormModalComponent,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
});
