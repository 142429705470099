angular.module('dotic').filter('humanizeCompanies', ($filter) => {
    const translate = $filter('translate');

    return (companies = []) => {
        if (!angular.isArray(companies)) {
            return '';
        }

        if (companies.length > 3) {
            return `${companies.length} ${translate('shared.company')}`;
        }

        return companies.map((company) => company.name).join(', ');
    };
});
