import EnvironmentSettingsService from '../../../services/environment-settings.service';
import exportGeoPackageDetailTemplate from './export-geopackage-detail.html';

class ExportGeoPackageDetailPage {
    exportItem = {};
    loading = true;
    user = {};
    userId = null;

    constructor($auth, $filter, $modalService, $timeout, $toasterService, userProvider) {
        this._$modalService = $modalService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._userProvider = userProvider;

        this.isOrange = EnvironmentSettingsService.isOrange();
        this.userId = $auth.getPayload().userId;
    }

    async $onInit() {
        this.loading = true;

        try {
            this.user = await this._userProvider.get(this.userId);
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
            this.user = { settings: { feature: { hasDebugMode: false } } };
        }
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async openListModal(items, translateKey, field = 'name') {
        if (!items) {
            return;
        }

        await this._$modalService.triggerListModal(
            items.map((item) => ({ name: item[field] })),
            this._translate(translateKey),
        );
    }
}

angular.module('dotic').component('exportGeoPackageDetailPage', {
    controller: ExportGeoPackageDetailPage,
    templateUrl: exportGeoPackageDetailTemplate,
    bindings: {
        exportItem: '<',
    },
});
