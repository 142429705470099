class HelpZipModalController {
    /** @ngInject */
    constructor($uibModalInstance) {
        this._$uibModalInstance = $uibModalInstance;
    }

    cancel() {
        this._$uibModalInstance.dismiss();
    }
}

angular.module('dotic').controller('HelpZipModalController', HelpZipModalController);
