// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/projets/projet-creation-text.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../assets/images/help/projets/projet-creation-page.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 2.2 / {{ 'shared.createProject' | translate }} : </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpProject.warning3' | translate }}.</span> </p> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span> ! {{ 'helpProject.warning' | translate }}. <br/> {{ 'helpProject.warning2' | translate }}. </span> </p> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W80\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'shared.mandatoryFields' | translate }}\"/> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'shared.createProject' | translate }}\"/> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionProjet/creerProjet.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;