import { FMEJobEnum } from '../../../models/deposit.model';
import template from './linkedDownloadModal.html';

class LinkedDownloadModal {
    constructor($scope, depositProvider, $filter) {
        this._$scope = $scope;
        this._depositProvider = depositProvider;
        this._translate = $filter('translate');

        this.title = this._translate('shared.deliverable', { COUNT: 1 });
        this.action = this._translate('shared.download');
        this.deposit = null;
        this.depositList = [];
        this.seletedDepositId = null;
        this.loading = true;
        this.form = {};
        this.job = FMEJobEnum.LOAD;

        this.loading = true;
    }

    $onInit() {
        const { deposit, job, title, action } = this.resolve;

        if (title) {
            this.title = title;
        }

        if (action) {
            this.action = action;
        }

        if (job) {
            this.job = job;
        }

        this.setDeposit(deposit);
    }

    setDeposit(deposit) {
        this.deposit = deposit;

        if (!this.deposit) {
            throw new Error(this._translate('deposit.unableToDownloadTheDeliverable'));
        }

        let projectId;
        if (deposit.project.id) {
            projectId = deposit.project.id;
        } else if (angular.isString(deposit.project)) {
            projectId = deposit.project;
        } else {
            throw new Error(`[projectId undefined] ${this._translate('shared.contactSupport')}`);
        }

        const { phase } = deposit;
        const deliveryZoneId = deposit.deliveryZone.id;

        this._depositProvider.listVersions({ projectId, deliveryZoneId, phase, job: this.job }).then((res) => {
            this.depositList = res
                .sort((a, b) => b.version - a.version)
                .map(({ id, name }) => ({ key: id, value: name }));
            this.loading = false;
        });
    }

    closePopup() {
        this.close();
    }

    submit() {
        if (!this.form.$invalid) {
            this.close({
                $value: {
                    depositId: this.deposit.id,
                    deposit2: this.seletedDepositId,
                },
            });
        }
    }
}

angular.module('dotic').component('linkedDownloadModal', {
    templateUrl: template,
    controller: LinkedDownloadModal,
    bindings: {
        resolve: '<',
        job: '@',
        close: '&',
    },
});
