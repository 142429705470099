angular.module('dotic').factory('projectProvider', projectProvider);

function projectProvider(ApiProvider, URI_KEYS) {
    return {
        create: createProject,
        delete: deleteProject,
        get: getProject,
        getEdit: getProjectEdit,
        getAll: getAllProject,
        getAllDeliveryZone: getAllDeliveryZone,
        getAssignments: getProjectAssignments,
        refreshTables: refreshTables,
        update: updateProject,
    };

    /**
     * POST /projects
     *
     * @param {object} project
     *
     * @returns {*} Promise
     */
    function createProject(project) {
        return ApiProvider.one(URI_KEYS.PROJECT).customPOST(project);
    }

    /**
     * DELETE /projects/:projectId
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function deleteProject(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).remove();
    }

    /**
     * GET /projects/:projectId
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getProject(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).get();
    }

    /**
     * GET /projects/:projectId/edit
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getProjectEdit(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one('edit').get();
    }

    /**
     * GET /projects/:projectId/assignments
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getProjectAssignments(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one('assignments').get();
    }

    /**
     * GET /projects
     * @param {object} queryParams
     * @returns {*} Promise
     */
    function getAllProject(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.PROJECT).get(queryParams);
    }

    /**
     * PUT /projects/:projectId
     *
     * @param {string} projectId
     * @param {object} project
     *
     * @returns {*} Promise
     */
    function updateProject(projectId, project) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).customPUT(project);
    }

    /**
     * GET /projects/:projectId/deliveryZones
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getAllDeliveryZone(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one(URI_KEYS.DELIVERY_ZONE).getList();
    }

    function refreshTables(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one('refresh').get();
    }
}
