const PostProcessingSlug = {
    ABANDONED_OBJECT: 'objets-abandonnes',
};

class PostProcessingService {
    isPostProcessingAbandonedObject(slug) {
        const postProcessingSlug = [PostProcessingSlug.ABANDONED_OBJECT];

        return postProcessingSlug.includes(slug);
    }
}

angular.module('dotic').factory('$postProcessingService', () => new PostProcessingService());
