// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/lStock/lStock-liste.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/lStock/lStock-detail.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 5.3.1 / {{ 'helpDeliverable.seeStocked' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpDeliverable.seeStocked' | translate }}\"/> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.detailStocked' | translate }} : <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'helpDeliverable.detailStocked' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionDepot/livrableStocke/seeLStocke.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;