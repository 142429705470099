import template from './controlConfigurationListModal.html';
class ControlConfigurationListModalController {
    constructor($scope) {
        this._$scope = $scope;

        this.controlConfigurations = [];
        this.loading = true;
    }

    $onInit() {
        const { controlConfigurations } = this.resolve;

        if (angular.isArray(controlConfigurations)) {
            this.setControlConfigurations(controlConfigurations);
        } else if (angular.isFunction(controlConfigurations)) {
            controlConfigurations()
                .then((results) => this.setControlConfigurations(results))
                .then(() => {
                    this._$scope.$apply();
                });
        } else {
            this.loading = false;
        }
    }

    setControlConfigurations(controlConfigurations) {
        this.loading = false;
        this.controlConfigurations = controlConfigurations;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('controlConfigurationListModal', {
    templateUrl: template,
    controller: ControlConfigurationListModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
