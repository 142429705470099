import template from './filterDate.html';

/**
 * @ngdoc component
 * @name ccFilterDate
 *
 * @description
 * A component to show a date filter
 *
 * Example:
 *
 * <cc-filter-date value="" placeholder="My date field"></cc-filter-date>
 *
 * @param {string} value       The selected date
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterDate', {
    templateUrl: template,
    bindings: {
        value: '=',
        placeholder: '@',
    },
});
