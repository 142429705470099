// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/dashboard/dashboard-guest.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 10.2.2 / {{ 'helpNav.dashboardElements' | translate }} : <em class=\"help-Doc-page__em\">{{ 'role.user' | translate: { COUNT: 1 } }}</em> </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span> ! {{ 'helpNav.userCanSeeAssignedProjects' | translate }}. <br/> ! {{ 'helpNav.subcontractorCanSee' | translate }}. </span> </p> <img class=\"help-Doc__img help-Doc__W95 help-Doc__marginLeft\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'shared.dashboard' | translate }}\"/> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/dashbordAndNavigation/tableauDeBord/tableauUser.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;