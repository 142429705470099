import userManualAgregationAgregationInfoTemplate from './agregationInfo.html';
import userManualAgregationAgregationTemplate from './agregation.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.agregation', {
            url: '/agregation/agregation',
            templateUrl: userManualAgregationAgregationTemplate,
            data: {
                title: 'Gestion agrégation des livrables',
                navigation: [
                    {
                        title: 'Gestion des agrégations',
                        sref: 'app.help.agregation',
                    },
                ],
                previous: 'app.help.tableauUser',
                next: 'app.help.agregationInfo',
            },
        })
        .state('app.help.agregationInfo', {
            url: '/agregation/Informations',
            templateUrl: userManualAgregationAgregationInfoTemplate,
            data: {
                title: 'Informations sur les agrégations',
                navigation: [
                    {
                        title: 'Gestion des agrégations',
                        sref: 'app.help.agregation',
                    },
                    { title: 'Information', sref: 'app.help.agregationInfo' },
                ],
                previous: 'app.help.agregation',
            },
        });
});
