import template from './change-password-modal.component.html';

class ChangePasswordModalComponent {
    close = null;

    updatePassword(newPassword) {
        this.close({ $value: newPassword });
    }
}

angular.module('dotic').component('changePasswordModal', {
    bindings: {
        close: '&',
    },
    controller: ChangePasswordModalComponent,
    templateUrl: template,
});
