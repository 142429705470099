import { sum as radashSum } from 'radash';

import { ColorService } from '../../../services/color.service';
import template from './nb-connections-by-implantation-type-indicator.html';

class NbConnectionsByImplantationTypeIndicatorComponent {
    chartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    data = [];
    loading = false;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
    }

    getChartData(data) {
        const colors = data.map((currentData) => ColorService.getImplantationTypeColor(currentData.libelle));

        return {
            bigLegend: {
                figure: radashSum(data, (item) => item.nbConnections),
                name: this._translate('shared.junction'),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: data.map((value) => value.nbConnections),
                },
            ],
            labels: data.map((item) => item.libelle),
        };
    }
}

angular.module('dotic').component('nbConnectionsByImplantationTypeIndicator', {
    controller: NbConnectionsByImplantationTypeIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        loading: '<',
    },
});
