import controlConfigurationFormModalTemplate from './formModal/controlConfigurationFormModal.html';
import template from './controlConfigurationList.html';

class ControlConfigurationListPage {
    constructor(
        $auth,
        $authorizationService,
        $companySettings,
        $deleteService,
        $filter,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        controlConfigurationProvider,
        projectProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$deleteService = $deleteService;
        this._$state = $state;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._$timeout = $timeout;
        this.companyId = $auth.getPayload().company;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._projectProvider = projectProvider;
        this._translate = $filter('translate');
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;

        this.isAllowed = $authorizationService.isAllowed;
        this.loading = true;
        this.controlConfigurationList = [];
        this.filteredControlConfigurationList = [];
        this.filter = { search: $state.params.search || '' };
        this.projectList = [];

        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('shared.controlConfiguration'),
                    href: $state.href('app.controlConfigurationList'),
                    key: 'controlConfigurations',
                },
            ],
        });

        $scope.$watchGroup(['$ctrl.controlConfigurationList', '$ctrl.filter.search', '$ctrl.filter.date'], () =>
            this.setScopeControlConfigurationWithFilters(),
        );

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';

            $scope.$emit('updateNavigationUrl');
        });

        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });

        this._userMetricsProvider.listControlConfigurations();
    }

    selectVisibleRows(event) {
        if (event) {
            event.preventDefault();
        }

        this._$scope.$broadcast('getVisibleRows');
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        // This loading shouldn't be present, but it's used here as a short-cut to trigger digest cycle
        this.loading = true;

        this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
            this.companyId,
        );

        if (this.projectList.length < 1) {
            await this.getProjects();
        }

        await this.getControlConfigurations();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    setScopeControlConfigurationWithFilters() {
        if (this.tableSelect) {
            this.tableSelect.empty();
        }

        const search = this.filter.search.toLocaleLowerCase();
        this._$state.go('.', { search });

        // Validate configurations (remove old/invalid ones)
        const items = this.controlConfigurationList.filter((item) => item.dataModel);

        // Filter out
        const results = items.filter(
            (item) =>
                this.filter.search === '' ||
                item.name.toLocaleLowerCase().indexOf(search) > -1 ||
                item.dataModel.name.toLocaleLowerCase().indexOf(search) > -1,
        );

        const groups = results.reduce((acc, item) => {
            const { slug, name } = item.dataModel;
            if (!acc[slug]) {
                acc[slug] = {
                    name,
                    slug,
                    isOpen: true,
                    controlConfigurations: [],
                };
            }

            acc[slug].controlConfigurations.push(item);

            return acc;
        }, {});

        this.filteredControlConfigurationList = Object.values(groups).sort((a, b) => a.name.localeCompare(b.name));
    }

    /**
     * Open/close controlConfiguration grouped by dataModel
     * @param {string} slug
     */
    toggleGroup(slug) {
        const item = this.filteredControlConfigurationList.find((group) => group.slug === slug);
        if (item) {
            item.isOpen = !item.isOpen;
        }
    }

    createControlConfiguration() {
        this.openModal(false, {
            company: this.companyId,
            project: [],
        });
    }

    openModal(isEditMode, controlConfiguration) {
        const modal = this._$uibModal.open({
            templateUrl: controlConfigurationFormModalTemplate,
            controller: 'ControlConfigurationFormModalController as $ctrl',
            backdrop: 'static',
            size: 'sm',
            resolve: {
                isEditMode,
                controlConfiguration,
            },
        });

        modal.result.then(
            () => this.initData(),
            () => {},
        );
    }

    async deleteControlConfigurations() {
        const selectedControlConfigurations = this.controlConfigurationList.filter((controlConfiguration) =>
            this.tableSelect.isActive(controlConfiguration),
        );
        if (selectedControlConfigurations.length < 1) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlConfiguration', {
                COUNT: selectedControlConfigurations.length,
                NAME: selectedControlConfigurations[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedControlConfigurationIds = selectedControlConfigurations.map(
            (selectedControlConfiguration) => selectedControlConfiguration.id,
        );
        await this._$deleteService.deleteArrayOfIds(
            selectedControlConfigurationIds,
            (controlConfigurationId) => this._controlConfigurationProvider.delete(controlConfigurationId),
            'removeModal.successControlConfiguration',
        );

        await this.initData();
    }

    static addCCData(cc) {
        if (cc.startAt && cc.endAt) {
            cc.date = `${new Date(cc.startAt).toLocaleDateString('fr-FR')} - ${new Date(cc.endAt).toLocaleDateString(
                'fr-FR',
            )}`;
        }

        if (cc.projects) {
            cc.countProjects = cc.projects.length;
        }

        return cc;
    }

    async getControlConfigurations() {
        this.loading = true;

        try {
            const controlConfigurationList = await this._controlConfigurationProvider.getAll();
            this.controlConfigurationList = controlConfigurationList
                .map(ControlConfigurationListPage.addCCData)
                .map((used) => ({ ...used, isUsed: used.countDeposits > 0 }));
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.loading = false;
    }

    async getProjects() {
        this.loading = true;

        try {
            this.projectList = await this._projectProvider.getAll();
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.loading = false;
    }

    /**
     * Check tooltip text
     * @param controlConfigurations
     * @return {*}
     */
    checkHasDefaultTooltip(controlConfigurations) {
        if (this.checkHasDefault(controlConfigurations)) {
            return this._translate('controlConfigurationList.removeDefault');
        } else {
            return this._translate('controlConfigurationList.removeDefaultDisabled');
        }
    }

    /**
     * Check if has default set in configuration
     * @param controlConfigurations
     * @return {*}
     */
    checkHasDefault(controlConfigurations) {
        return controlConfigurations.find((conf) => conf.isDefaultConfigurationDataModel);
    }

    /**
     * remove configuration control
     * @param controlConfigurations
     */
    async removeDefaultCC(controlConfigurations) {
        const defaultControlConfiguration = controlConfigurations.find(
            (element) => element.isDefaultConfigurationDataModel,
        );

        if (!defaultControlConfiguration) {
            return;
        }

        try {
            await this._controlConfigurationProvider.update(defaultControlConfiguration.id, {
                isDefaultConfigurationDataModel: false,
            });
            this._$toasterService.success({
                body: this._translate('removeModal.successDefaultControlConfiguration'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }

    // TODO 1 conf control per dataModel
    async setDefaultCCByDataModel(controlConfiguration) {
        try {
            await this._controlConfigurationProvider.update(controlConfiguration.id, {
                isDefaultConfigurationDataModel: true,
            });
            this._$toasterService.success({
                body: this._translate('controlConfigurationList.updated'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }

    getProjectsAssociated(controlConfigurationId) {
        this.projectAssociatedList = this.projectList.filter((project) =>
            project.controlConfigurations.some(
                (controlConfiguration) => controlConfiguration.id === controlConfigurationId,
            ),
        );

        return this.projectAssociatedList;
    }
}

angular.module('dotic').component('controlConfigurationListPage', {
    templateUrl: template,
    controller: ControlConfigurationListPage,
});
