import template from './indicator.html';

angular.module('dotic').component('indicator', {
    templateUrl: template,
    bindings: {
        position: '@',
        reverse: '@',
        value: '<',
        result2: '<',
        text: '@',
        icon: '@',
        textIcon: '@',
        textIconSmall: '@',
        color: '@',
        unit: '@',
        unit2: '@',
        compare: '<',
        value1: '<',
        value2: '<',
        tooltip: '@',
    },
});
