import template from './distance-indicator.html';

class DistanceIndicator {
    chartSize = 180;
    data = [];
    distanceRange = null;
    filteredData = [];
    numberTotalDistances = 0;

    constructor($filter, $timeout) {
        this._translate = $filter('translate');
        this._$timeout = $timeout;
    }

    $onInit() {
        // Force open the indicators
        if (this.parentRef && !this.parentRef.isOpen() && this.opened) {
            this.parentRef.toggle();
        }

        if (!this.data || !this.data[0] || angular.isUndefined(this.data[0]['distanceKm'])) {
            return;
        }

        this.data.sort((a, b) => a.distanceKm - b.distanceKm);
        this.filteredData = [...this.data];
        this.numberTotalDistances = this.data.length;

        if (angular.isDefined(this.size)) {
            this.chartSize = parseInt(this.size, 10);
        }

        this.distanceNroSro = !!this.data.find((data) => data['nro']);
        this.distanceSroPbo = !!this.data.find((data) => data['zsCode']);
        this.distanceNroPbo = !!this.data.find((data) => data['znCode'] && data['bpCode']);

        this.distanceRange = this.preparedChartDistanceRange(this.data);
    }

    clearFilter() {
        this.data.sort((a, b) => a.distanceKm - b.distanceKm);
        this.filteredData = [...this.data];
    }

    preparedChartDistanceRange(data) {
        let zeroToThreeHundred = 0;
        let threeHundredToFiveHundred = 0;
        let fiveHundredToThousand = 0;
        let thousandToThreeThousand = 0;
        let overThreeThousand = 0;

        data.forEach((range) => {
            if (range.distanceKm > 0 && range.distanceKm <= 0.3) {
                zeroToThreeHundred += 1;
            } else if (range.distanceKm <= 0.5) {
                threeHundredToFiveHundred += 1;
            } else if (range.distanceKm <= 1) {
                fiveHundredToThousand += 1;
            } else if (range.distanceKm <= 3) {
                thousandToThreeThousand += 1;
            } else {
                overThreeThousand += 1;
            }
        });

        const labels = [
            this._translate('shared.zeroToThreeHundred'),
            this._translate('shared.threeHundredToFiveHundred'),
            this._translate('shared.fiveHundredToThousand'),
            this._translate('shared.thousandToThreeThousand'),
            this._translate('shared.overThreeThousand'),
        ];

        const datasets = [
            {
                data: [
                    zeroToThreeHundred,
                    threeHundredToFiveHundred,
                    fiveHundredToThousand,
                    thousandToThreeThousand,
                    overThreeThousand,
                ],
                backgroundColor: ['#50d0c3', '#00a2fb', '#5069a9', '#edb349', '#dd3073'],
            },
        ];

        return {
            bigLegend: {
                figure: this.numberTotalDistances,
                name: this._translate('shared.distance', {
                    COUNT: this.numberTotalDistances,
                }),
            },
            labels,
            datasets,
        };
    }

    getEvent(customClickEvent) {
        if (!customClickEvent.isDoughnut) {
            return;
        }

        if (!this.parentRef.isOpen()) {
            this.parentRef.toggle();
        }

        this.hasAnomaliesOpen = true;

        this._$timeout(() => {
            switch (customClickEvent.index) {
                case 0:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0 && range.distanceKm <= 0.3);
                    break;
                case 1:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0.3 && range.distanceKm <= 0.5);
                    break;
                case 2:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0.5 && range.distanceKm <= 1);
                    break;
                case 3:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 1 && range.distanceKm <= 3);
                    break;
                case 4:
                default:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 3);
                    break;
            }
        });
    }
}

angular.module('dotic').component('distanceIndicator', {
    controller: DistanceIndicator,
    templateUrl: template,
    bindings: {
        data: '<',
        opened: '<',
        parentRef: '<',
        size: '<',
    },
});
