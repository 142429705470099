import template from './superAdminFormModal.html';
import { UsersApi } from '../../../../sdk/connect-control-api-v1/src';

class SuperAdminFormModalComponent {
    constructor($apiClientService, $filter, $toasterService) {
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._usersApi = new UsersApi($apiClientService.client);

        this.close = undefined;
        this.user = {};
        this.userForm = {};
    }

    cancel() {
        this.close({ $value: false });
    }

    async saveUser() {
        if (!this.userForm.$valid) {
            return;
        }

        const user = angular.copy(this.user);
        user.role = 'superAdmin';
        try {
            await this._usersApi.create(user);
            this._$toasterService.success({
                body: this._translate('userFormModal.userCreated'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.close({ $value: true });
    }
}

angular.module('dotic').component('superAdminFormModal', {
    bindings: {
        close: '&',
    },
    controller: SuperAdminFormModalComponent,
    templateUrl: template,
});
