class DepositRemoveController {
    constructor($uibModalInstance) {
        this._$uibModalInstance = $uibModalInstance;
    }

    submit() {
        this._$uibModalInstance.close({ $value: true });
    }

    cancel() {
        this._$uibModalInstance.dismiss({ $value: false });
    }
}

angular.module('dotic').controller('DepositRemoveController', DepositRemoveController);
