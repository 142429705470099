class ComparisonDepositController {
    constructor(
        $authorizationService,
        $comparisonService,
        $filter,
        $location,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $tableService,
        $timeout,
        depositProvider,
    ) {
        this._$state = $state;
        this._translate = $filter('translate');
        this._$timeout = $timeout;
        this._$scope = $scope;
        this._$location = $location;
        this._depositProvider = depositProvider;
        this._$comparisonService = $comparisonService;
        this._$segmentsService = $segmentsService;
        this._$tableService = $tableService;
        this._$phaseService = $phaseService;
        this.isAllowed = $authorizationService.isAllowed;
        this.selection = {
            user: [],
            phase: [],
            date: '',
        };
        this.deposits = [];
        this.segments = this._$segmentsService.getAll();
        //filters options
        this.phaseList = [];
        this.usersList = [];

        $scope.$watchGroup(['$ctrl.selection.date', '$ctrl.selection.phase', '$ctrl.selection.user'], () =>
            this.updateFilters(),
        );
    }

    async $onInit() {
        this.loading = true;
        this.tableDetail = this._$tableService.detail();
        this.phaseList = this._$phaseService.getAll().map((value) => ({ key: value, value }));

        this.deposit = await this.getDeposit();
        this.deposits = await this.loadData();
        this.updateFilters();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'compare',
                    title: this._translate('compareReport.compareReport'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: 'Livrables contrôlés',
                href: this._$state.href('app.depositJob-control'),
                key: 'deposits',
            },
            allowedPreviousKeys: ['deposits'],
        });
    }

    async getDeposit() {
        const deposit = await this._depositProvider.get(this._$state.params.deposit);
        deposit.networkSegments = this._$segmentsService.setNetworkSegments(deposit.networkSegments);

        return deposit;
    }

    async loadData() {
        const deposits = await this._depositProvider.getComparableDeposit(this.deposit.id);

        const _deposits = deposits.map(
            ({
                _id,
                name,
                phases,
                createdAt,
                createdBy,
                numberOfPlug,
                deliveryZone,
                version,
                project,
                networkSegments,
            }) => ({
                _id,
                name,
                phases,
                createdAt,
                createdBy,
                numberOfPlug,
                deliveryZone,
                version,
                project,
                networkSegments: this._$segmentsService.setNetworkSegments(networkSegments),
            }),
        );
        // Add users in userList (for filter)
        _deposits.forEach((d) => {
            if (
                !this.usersList.some((item) => {
                    // if user has been removed, return false
                    return d.createdBy ? item.key === d.createdBy._id : false;
                })
            ) {
                if (d.createdBy) {
                    this.usersList.push({
                        key: d.createdBy._id,
                        value: d.createdBy.fullname,
                    });
                }
            }
        });

        return _deposits;
    }

    updateFilters() {
        const { user, phase, date } = this.selection;

        this._$state.go('.', { user, phase, date });

        this.filteredDeposits = this.deposits
            .filter((item) => this.onDateChange(item, date))
            .filter((item) => this.onPhaseChange(item, phase))
            .filter((item) => this.onUserChange(item, user));
    }

    onDateChange(deposit, date) {
        const isDepositDateCompatible =
            moment(date.startDate).utc() <= moment(deposit.createdAt).utc() &&
            moment(date.endDate).utc() >= moment(deposit.createdAt).utc();

        if (date === '' || isDepositDateCompatible) {
            return deposit;
        }
    }

    onPhaseChange(deposit, phases) {
        const hasPhase = phases.some((phase) => deposit.phases?.includes(phase));

        if (!phases.length || hasPhase) {
            return deposit;
        }
    }

    onUserChange(deposit, users) {
        const hasUser = users.some((user) => deposit.createdBy?._id === user);

        if (!users.length || hasUser) {
            return deposit;
        }
    }

    showDetails(deposit) {
        this.depositDetail = [];
        this.tableDetail.toggle(deposit);
    }

    async compareReport(deposit) {
        await this._$comparisonService.goToComparePage(this.deposit._id, deposit._id, 'deposit');
    }
}

angular.module('dotic').controller('ComparisonDepositController', ComparisonDepositController);
