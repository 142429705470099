angular.module('dotic').config(($stateProvider, $urlRouterProvider) => {
    $stateProvider.state('login', {
        url: '/security/login',
        component: 'loginPage',
        data: {
            authenticationRequired: false,
            roleRequired: [],
        },
        params: {
            redirectUrl: null,
        },
    });

    $stateProvider.state('welcome', {
        url: '/welcome',
        component: 'welcomePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'user', 'extendedUser'],
        },
    });

    $urlRouterProvider.when('/login/oauth2', {
        template: '',
        controller: function ($location) {
            const hash = $location.path().substr(1);

            const splitted = hash.split('&');
            const params = {};

            splitted.forEach((element) => {
                const param = element.split('=');
                const [key, value] = param;
                params[key] = value;
            });
        },
    });
});
