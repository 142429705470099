import { saveAs } from 'file-saver';

export default class FileService {
    // 5 Mo
    static excelMaxSize = 5 * 1024 * 1024;

    constructor($filter, $toasterService) {
        this._translate = $filter('translate');
        this._$toasterService = $toasterService;
    }

    /**
     * Take a file and return it's base64 string equivalent
     * @param {File} file
     */
    convertToBase64(file) {
        if (!file) {
            return null;
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    downloadZipFromData(response) {
        const fileName = response.headers['content-disposition']?.split('filename=')[1].split(';')[0].slice(1, -1);

        // Use saveAs from FileService to download the file
        saveAs(response.body, fileName ?? this._translate('shared.file', { COUNT: 1 }));
    }
}

angular.module('dotic').factory('$fileService', FileService);
