class CompareReportsFooterComponent {
    constructor() {
        this.loaded = false;
        this.report1 = null;
        this.report2 = null;
    }

    $onInit() {
        this.loaded = false;
        if (!(this.report1?.project && this.report1.deposit && this.report1.deposit.deliveryZone)) {
            return;
        }

        if (!(this.report2?.project && this.report2.deposit && this.report2.deposit.deliveryZone)) {
            return;
        }

        this.loaded = true;
    }
}

angular.module('dotic').component('compareReportsFooter', {
    controller: CompareReportsFooterComponent,
    template: `<div class="report-info">
    <span class="report-name">Comparaison de rapport</span>
    {{$ctrl.report1.report.project.name}} {{$ctrl.report1.report.deposit.deliveryZone.name}}
    {{$ctrl.report1.report.deposit.phase}} v{{$ctrl.report1.report.deposit.version}} et
    v{{$ctrl.report2.report.deposit.version}} | {{$ctrl.report1.report.date | date:"d MMMM yyyy 'à' HH:mm:ss"}} /
    {{$ctrl.report2.report.date | date:"d MMMM yyyy 'à' HH:mm:ss"}}
</div>
<div class="page__footer-number"></div>`,
    bindings: {
        report1: '<',
        report2: '<',
    },
});
