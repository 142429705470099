/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import FttxHeritageInventory from '../model/FttxHeritageInventory';
import GetFttxHeritageInventories200Response from '../model/GetFttxHeritageInventories200Response';
import PaginationParameters from '../model/PaginationParameters';

/**
* FttxHeritageInventories service.
* @module api/FttxHeritageInventoriesApi
* @version 1.0.0
*/
export default class FttxHeritageInventoriesApi {

    /**
    * Constructs a new FttxHeritageInventoriesApi. 
    * @alias module:api/FttxHeritageInventoriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create a new Fttx Heritage Inventory
     * @param {Object} body Fttx Heritage Inventory to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FttxHeritageInventory} and HTTP response
     */
    createFttxHeritageInventoryWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createFttxHeritageInventory");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = FttxHeritageInventory;
      return this.apiClient.callApi(
        '/api/v1/fttx-heritage-inventories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a new Fttx Heritage Inventory
     * @param {Object} body Fttx Heritage Inventory to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FttxHeritageInventory}
     */
    createFttxHeritageInventory(body) {
      return this.createFttxHeritageInventoryWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download Fttx Heritage Inventory
     * @param {String} id 
     * @param {Object} body Parameters to download the inventory
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadFttxHeritageInventoriesWithHttpInfo(id, body) {
      let postBody = body;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling downloadFttxHeritageInventories");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling downloadFttxHeritageInventories");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fttx-heritage-inventories/{id}/download', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download Fttx Heritage Inventory
     * @param {String} id 
     * @param {Object} body Parameters to download the inventory
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadFttxHeritageInventories(id, body) {
      return this.downloadFttxHeritageInventoriesWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Fttx Heritage Inventories
     * @param {Object} opts Optional parameters
     * @param {module:model/PaginationParameters} [paginationParameters] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetFttxHeritageInventories200Response} and HTTP response
     */
    getFttxHeritageInventoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'paginationParameters': opts['paginationParameters']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetFttxHeritageInventories200Response;
      return this.apiClient.callApi(
        '/api/v1/fttx-heritage-inventories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Fttx Heritage Inventories
     * @param {Object} opts Optional parameters
     * @param {module:model/PaginationParameters} opts.paginationParameters 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetFttxHeritageInventories200Response}
     */
    getFttxHeritageInventories(opts) {
      return this.getFttxHeritageInventoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Fttx Heritage Inventory
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FttxHeritageInventory} and HTTP response
     */
    getFttxHeritageInventoryWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getFttxHeritageInventory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = FttxHeritageInventory;
      return this.apiClient.callApi(
        '/api/v1/fttx-heritage-inventories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Fttx Heritage Inventory
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FttxHeritageInventory}
     */
    getFttxHeritageInventory(id) {
      return this.getFttxHeritageInventoryWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * delete a Fttx Heritage Inventory
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    removeFttxHeritageInventoryWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling removeFttxHeritageInventory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/fttx-heritage-inventories/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * delete a Fttx Heritage Inventory
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    removeFttxHeritageInventory(id) {
      return this.removeFttxHeritageInventoryWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
