import template from './input.html';

class CcInputController {
    shouldShowError() {
        return this.form[this.name]?.$touched && this.form[this.name].$invalid;
    }

    shouldShowTips() {
        return Boolean(this.tips);
    }
}

angular.module('dotic').component('ccInput', {
    templateUrl: template,
    controller: CcInputController,
    bindings: {
        errors: '<',
        form: '<',
        label: '@',
        model: '=',
        name: '@',
        required: '<',
        placeholder: '@',
        disabled: '<',
        readonly: '<',
        small: '@',
        type: '@',
        min: '@',
        step: '@',
        autocomplete: '@',
        tips: '@',
    },
});
