angular.module('dotic').factory('authenticationProvider', authenticationProvider);

function authenticationProvider(AuthProvider) {
    return {
        changeCompany,
        forgotPassword,
        updateToken,
    };

    function changeCompany(companyId) {
        return AuthProvider.one('change-company').customPOST({
            companyId: companyId,
        });
    }

    /**
     * POST /forgotPasswords
     *
     * @param {string} email
     *
     * @returns {*} Promise
     */
    function forgotPassword(email) {
        return AuthProvider.one('forgotPassword').customPOST({ email: email });
    }

    function updateToken() {
        return AuthProvider.one('update').post();
    }
}
