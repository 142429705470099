import template from './viewModalList.html';
class ViewModalListController {
    constructor($scope) {
        this._$scope = $scope;

        this.loading = true;
        this.nameValue = '';
        this.dataModelValuesList = [];
        this.messageError = false;
    }

    $onInit() {
        if (!this.resolve.dataModelValues.length) {
            this.messageError = true;

            return;
        }

        this.dataModelValuesList = this.resolve.dataModelValues;
        this.nameValue = this.dataModelValuesList[0].name;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('viewModalList', {
    templateUrl: template,
    controller: ViewModalListController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
