import template from './filterDatepicker.html';

const TIME_STRING_FORMAT = 'HH:mm:ss';
const DATE_STRING_FORMAT = 'DD/MM/YYYY';
const DATE_TIME_STRING_FORMAT = `${DATE_STRING_FORMAT} ${TIME_STRING_FORMAT}`;

class CcFilterDatepickerController {
    constructor($scope) {
        this.timeString = '';
        this.dateString = '';
        this.name = '';
        $scope.$on('clearFilter', () => this.clearFilter());

        $scope.$on('updateDateTime', (event, name, datetime) => {
            if (name === this.name) {
                this.timeString = moment(datetime).format(TIME_STRING_FORMAT);
                this.time = moment(datetime).toDate();
                this.dateString = moment(datetime).format(DATE_STRING_FORMAT);
                this.date = moment(datetime).toDate();
                this.updateDate();
                this.updateTime();
            }
        });
    }

    clearFilter() {
        this.timeString = '';
        this.dateString = '';
        this.value = '';
        this.date = '';
        this.time = '';
    }

    updateDate() {
        if (!this.date) {
            this.clearFilter();
        } else {
            this.dateString = moment(this.date).format(DATE_STRING_FORMAT);

            if (this.timeString === '') {
                this.timeString = '00:00:00';
                this.time = angular.copy(this.date);
            }

            this.value = moment(`${this.dateString} ${this.timeString}`, DATE_TIME_STRING_FORMAT).format();
        }
    }

    updateTime() {
        this.timeString = moment(this.time).format(TIME_STRING_FORMAT);
        if (this.dateString === '') {
            this.dateString = moment(this.time).format(DATE_STRING_FORMAT);
            this.date = angular.copy(this.time);
        }

        this.value = moment(`${this.dateString} ${this.timeString}`, DATE_TIME_STRING_FORMAT).format();
    }
}

/**
 * @ngdoc component
 * @name ccFilterDatepicker
 *
 * @description
 * A Date and time picker
 *
 * Example:
 *
 * <cc-filter-datepicker value="" placeholder="My field"></cc-filter-dropdown>
 *
 * @param {string} value       The model
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterDatepicker', {
    templateUrl: template,
    controller: CcFilterDatepickerController,
    bindings: {
        options: '<',
        value: '=',
        placeholder: '@',
        name: '@',
        nullValue: '<',
    },
});
