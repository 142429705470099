import helpTemplate from './help.html';
import indexTemplate from './index.html';
import userManualDashbordAndNavigationBarreNavigationBarreNavigationTemplate from './userManual/dashbordAndNavigation/barreNavigation/barreNavigation.html';
import userManualDashbordAndNavigationBarreNavigationGestionAccesTemplate from './userManual/dashbordAndNavigation/barreNavigation/gestionAcces.html';
import userManualDashbordAndNavigationBarreNavigationMonProfilTemplate from './userManual/dashbordAndNavigation/barreNavigation/monProfil.html';
import userManualDashbordAndNavigationBarreNavigationNotificationTemplate from './userManual/dashbordAndNavigation/barreNavigation/notification.html';
import userManualDashbordAndNavigationDashAndNavTemplate from './userManual/dashbordAndNavigation/dashAndNav.html';
import userManualDashbordAndNavigationTableauDeBordTableauAdminTemplate from './userManual/dashbordAndNavigation/tableauDeBord/tableauAdmin.html';
import userManualDashbordAndNavigationTableauDeBordTableauDeBordTemplate from './userManual/dashbordAndNavigation/tableauDeBord/tableauDeBord.html';
import userManualDashbordAndNavigationTableauDeBordTableauUserTemplate from './userManual/dashbordAndNavigation/tableauDeBord/tableauUser.html';
import userManualGestionConfigurationsConfigurationsCtrlConfigurationCtrlTemplate from './userManual/gestionConfigurations/configurationsCtrl/configurationCtrl.html';
import userManualGestionConfigurationsConfigurationsCtrlCreerConfigurationCtrlTemplate from './userManual/gestionConfigurations/configurationsCtrl/creerConfigurationCtrl.html';
import userManualGestionConfigurationsConfigurationsCtrlInformationConfigurationCtrlTemplate from './userManual/gestionConfigurations/configurationsCtrl/informationConfigurationCtrl.html';
import userManualGestionConfigurationsConfigurationsCtrlParametrerConfigurationCtrlTemplate from './userManual/gestionConfigurations/configurationsCtrl/parametrerConfigurationCtrl.html';
import userManualGestionConfigurationsConfigurationsCtrlSupprimerConfigurationCtrlTemplate from './userManual/gestionConfigurations/configurationsCtrl/supprimerConfigurationCtrl.html';
import userManualGestionConfigurationsGestionConfigurationsTemplate from './userManual/gestionConfigurations/gestionConfigurations.html';
import userManualGestionConfigurationsGrilleInterventionInterventionGridTemplate from './userManual/gestionConfigurations/grilleIntervention/interventionGrid.html';
import userManualGestionConfigurationsGrilleInterventionInterventionListTemplate from './userManual/gestionConfigurations/grilleIntervention/interventionList.html';
import userManualGestionConfigurationsGrillesControlCompareTwoGridsCtrlTemplate from './userManual/gestionConfigurations/grillesControl/compareTwoGridsCtrl.html';
import userManualGestionConfigurationsGrillesControlExportGControlTemplate from './userManual/gestionConfigurations/grillesControl/exportGControl.html';
import userManualGestionConfigurationsGrillesControlFiltresGControlTemplate from './userManual/gestionConfigurations/grillesControl/filtresGControl.html';
import userManualGestionConfigurationsGrillesControlGrillesControlTemplate from './userManual/gestionConfigurations/grillesControl/grillesControl.html';
import userManualGestionConfigurationsGrillesControlInformationGControlTemplate from './userManual/gestionConfigurations/grillesControl/informationGControl.html';
import userManualGestionConfigurationsGrillesControlParametrerDupliquerGControlTemplate from './userManual/gestionConfigurations/grillesControl/parametrerDupliquerGControl.html';
import userManualGestionConfigurationsGrillesControlParametrerEditerControlGControlTemplate from './userManual/gestionConfigurations/grillesControl/parametrerEditerControlGControl.html';
import userManualGestionConfigurationsGrillesControlParametrerEditerGControlTemplate from './userManual/gestionConfigurations/grillesControl/parametrerEditerGControl.html';
import userManualGestionConfigurationsGrillesControlParametrerGControlTemplate from './userManual/gestionConfigurations/grillesControl/parametrerGControl.html';
import userManualGestionConfigurationsGrillesControlSupprimerGControlTemplate from './userManual/gestionConfigurations/grillesControl/supprimerGControl.html';
import userManualGestionConfigurationsGrillesRemplissageCompareTwoFillGridTemplate from './userManual/gestionConfigurations/grillesRemplissage/compareTwoFillGrid.html';
import userManualGestionConfigurationsGrillesRemplissageExportGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/exportGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageFiltresGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/filtresGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageGrillesRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/grillesRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageInformationGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/informationGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageParametrerAttributGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/parametrerAttributGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageParametrerDupliquerGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/parametrerDupliquerGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageParametrerEditerGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/parametrerEditerGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageParametrerGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/parametrerGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageParametrerSeeListValueGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/parametrerSeeListValueGRemplissage.html';
import userManualGestionConfigurationsGrillesRemplissageSupprimerGRemplissageTemplate from './userManual/gestionConfigurations/grillesRemplissage/supprimerGRemplissage.html';
import userManualGestionConfigurationsLinkBetweenGridsConfigurationCtrl from './userManual/gestionConfigurations/configurationsCtrl/linkBetweenGridsConfigurationCtrl.html';
import userManualGestionConfigurationsZonesLivraisonCreerZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/creerZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonImportZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/importZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonInformationZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/informationZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonParametrerZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/parametrerZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonSousTraitantZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/sousTraitantZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonSupprimerZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/supprimerZoneLivraison.html';
import userManualGestionConfigurationsZonesLivraisonZoneLivraisonTemplate from './userManual/gestionConfigurations/zonesLivraison/zoneLivraison.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help', {
            abstract: true,
            url: '/help',
            templateUrl: indexTemplate,
            controller: 'HelpController as $ctrl',
        })
        /* Accueil */
        .state('app.help.home', {
            url: '/',
            templateUrl: helpTemplate,
            data: {
                title: 'Accueil',
                helpMenu: true,
            },
        })
        /*  Gestion configurations */
        .state('app.help.gestionConfigurations', {
            url: '/gestion-configurations/',
            templateUrl: userManualGestionConfigurationsGestionConfigurationsTemplate,
            data: {
                title: 'Gestion des configurations',
                navigation: [],
                previous: 'app.help.supprimerProjet',
                next: 'app.help.grillesRemplissage',
            },
        })
        /* Gestion Grilles remplissage */
        .state('app.help.grillesRemplissage', {
            url: '/gestion-configuration/grilles-remplissage',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageGrillesRemplissageTemplate,
            data: {
                title: 'Grilles de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                ],
                previous: 'app.help.gestionConfigurations',
                next: 'app.help.informationGRemplissage',
            },
        })
        .state('app.help.informationGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/informations',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageInformationGRemplissageTemplate,
            data: {
                title: 'Informations sur les grilles de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.grillesRemplissage',
                next: 'app.help.exportGRemplissage',
            },
        })
        .state('app.help.exportGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/export',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageExportGRemplissageTemplate,
            data: {
                title: 'Exporter une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.informationGRemplissage',
                next: 'app.help.parametrerGRemplissage',
            },
        })
        .state('app.help.parametrerGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/parametrer',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageParametrerGRemplissageTemplate,
            data: {
                title: 'Paramétrer une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.exportGRemplissage',
                next: 'app.help.parametrerDupliquerGRemplissage',
            },
        })
        .state('app.help.parametrerDupliquerGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/parametrer-dupliquer',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageParametrerDupliquerGRemplissageTemplate,
            data: {
                title: 'Dupliquer une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.parametrerGRemplissage',
                next: 'app.help.parametrerEditerGRemplissage',
            },
        })
        .state('app.help.parametrerEditerGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/parametrer-editer',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageParametrerEditerGRemplissageTemplate,
            data: {
                title: 'Editer une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.parametrerDupliquerGRemplissage',
                next: 'app.help.parametrerAttributGRemplissage',
            },
        })
        .state('app.help.parametrerAttributGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/parametrer-attribut',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageParametrerAttributGRemplissageTemplate,
            data: {
                title: 'Attribut grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.parametrerEditerGRemplissage',
                next: 'app.help.parametrerSeeListValueGRemplissage',
            },
        })
        .state('app.help.parametrerSeeListValueGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/liste-valeur-attribut',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageParametrerSeeListValueGRemplissageTemplate,
            data: {
                title: 'Liste de valeur attribut',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.parametrerAttributGRemplissage',
                next: 'app.help.filtresGRemplissage',
            },
        })
        .state('app.help.filtresGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/filtres',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageFiltresGRemplissageTemplate,
            data: {
                title: 'Filtrer une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.parametrerSeeListValueGRemplissage',
                next: 'app.help.compareTwoFillGrid',
            },
        })
        .state('app.help.compareTwoFillGrid', {
            url: '/gestion-configuration/grilles-remplissage/comparaison-grilles-remplissages',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageCompareTwoFillGridTemplate,
            data: {
                title: 'Comparer deux grilles de remplissages',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.filtresGRemplissage',
                next: 'app.help.supprimerGRemplissage',
            },
        })
        .state('app.help.supprimerGRemplissage', {
            url: '/gestion-configuration/grilles-remplissage/supprimer',
            templateUrl: userManualGestionConfigurationsGrillesRemplissageSupprimerGRemplissageTemplate,
            data: {
                title: 'Supprimer une grille de remplissage',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de remplissage',
                        sref: 'app.help.grillesRemplissage',
                    },
                ],
                previous: 'app.help.compareTwoFillGrid',
                next: 'app.help.grillesControl',
            },
        })
        /* Gestion Grilles controle */
        .state('app.help.grillesControl', {
            url: '/gestion-configuration/grilles-control',
            templateUrl: userManualGestionConfigurationsGrillesControlGrillesControlTemplate,
            data: {
                title: 'Grilles de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                ],
                previous: 'app.help.supprimerGRemplissage',
                next: 'app.help.informationGControl',
            },
        })
        .state('app.help.informationGControl', {
            url: '/gestion-configuration/grilles-control/informations',
            templateUrl: userManualGestionConfigurationsGrillesControlInformationGControlTemplate,
            data: {
                title: 'Informations sur les grilles de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.grillesControl',
                next: 'app.help.exportGControl',
            },
        })
        .state('app.help.exportGControl', {
            url: '/gestion-configuration/grilles-control/exporter',
            templateUrl: userManualGestionConfigurationsGrillesControlExportGControlTemplate,
            data: {
                title: 'Exporter une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.informationGControl',
                next: 'app.help.parametrerGControl',
            },
        })
        .state('app.help.parametrerGControl', {
            url: '/gestion-configuration/grilles-control/parametrer',
            templateUrl: userManualGestionConfigurationsGrillesControlParametrerGControlTemplate,
            data: {
                title: 'Paramétrer une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.exportGControl',
                next: 'app.help.parametrerDupliquerGControl',
            },
        })
        .state('app.help.parametrerDupliquerGControl', {
            url: '/gestion-configuration/grilles-control/parametre-dupliquer',
            templateUrl: userManualGestionConfigurationsGrillesControlParametrerDupliquerGControlTemplate,
            data: {
                title: 'Dupliquer une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.parametrerGControl',
                next: 'app.help.parametrerEditerGControl',
            },
        })
        .state('app.help.parametrerEditerGControl', {
            url: '/gestion-configuration/grilles-control/parametrer-editer',
            templateUrl: userManualGestionConfigurationsGrillesControlParametrerEditerGControlTemplate,
            data: {
                title: 'Editer une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.parametrerDupliquerGControl',
                next: 'app.help.parametrerEditerControlGControl',
            },
        })
        .state('app.help.parametrerEditerControlGControl', {
            url: '/gestion-configuration/grilles-control/parametrer-editer-control',
            templateUrl: userManualGestionConfigurationsGrillesControlParametrerEditerControlGControlTemplate,
            data: {
                title: 'Editer un point de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.parametrerEditerGControl',
                next: 'app.help.filtresGControl',
            },
        })
        .state('app.help.filtresGControl', {
            url: '/gestion-configuration/grilles-control/filtres',
            templateUrl: userManualGestionConfigurationsGrillesControlFiltresGControlTemplate,
            data: {
                title: 'Filtrer une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.parametrerEditerControlGControl',
                next: 'app.help.compareTwoGridsCtrl',
            },
        })
        .state('app.help.compareTwoGridsCtrl', {
            url: '/gestion-configuration/grilles-control/comparaison',
            templateUrl: userManualGestionConfigurationsGrillesControlCompareTwoGridsCtrlTemplate,
            data: {
                title: 'Comparer deux grilles de contrôles',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.filtresGControl',
                next: 'app.help.supprimerGControl',
            },
        })
        .state('app.help.supprimerGControl', {
            url: '/gestion-configuration/grilles-control/supprimer',
            templateUrl: userManualGestionConfigurationsGrillesControlSupprimerGControlTemplate,
            data: {
                title: 'Supprimer une grille de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Grilles de contrôle',
                        sref: 'app.help.grillesControl',
                    },
                ],
                previous: 'app.help.compareTwoGridsCtrl',
                next: 'app.help.configurationCtrl',
            },
        })
        /* Gestion Configuration de contrôle */
        .state('app.help.configurationCtrl', {
            url: '/gestion-configuration/configurations-control',
            templateUrl: userManualGestionConfigurationsConfigurationsCtrlConfigurationCtrlTemplate,
            data: {
                title: 'Configurations de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                ],
                previous: 'app.help.supprimerGControl',
                next: 'app.help.informationConfigurationCtrl',
            },
        })
        .state('app.help.informationConfigurationCtrl', {
            url: '/gestion-configuration/configurations-control/informations',
            templateUrl: userManualGestionConfigurationsConfigurationsCtrlInformationConfigurationCtrlTemplate,
            data: {
                title: 'Information sur les configurations de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Configurations de contrôle',
                        sref: 'app.help.configurationCtrl',
                    },
                ],
                previous: 'app.help.configurationCtrl',
                next: 'app.help.creerConfigurationCtrl',
            },
        })
        .state('app.help.creerConfigurationCtrl', {
            url: '/gestion-configuration/configurations-control/creer',
            templateUrl: userManualGestionConfigurationsConfigurationsCtrlCreerConfigurationCtrlTemplate,
            data: {
                title: 'Créer une configuration de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Configurations de contrôle',
                        sref: 'app.help.configurationCtrl',
                    },
                ],
                previous: 'app.help.informationConfigurationCtrl',
                next: 'app.help.parametrerConfigurationCtrl',
            },
        })
        .state('app.help.parametrerConfigurationCtrl', {
            url: '/gestion-configuration/configurations-control/parametrer',
            templateUrl: userManualGestionConfigurationsConfigurationsCtrlParametrerConfigurationCtrlTemplate,
            data: {
                title: 'Paramétrer une configuration de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Configurations de contrôle',
                        sref: 'app.help.configurationCtrl',
                    },
                ],
                previous: 'app.help.creerConfigurationCtrl',
                next: 'app.help.supprimerConfigurationCtrl',
            },
        })
        .state('app.help.supprimerConfigurationCtrl', {
            url: '/gestion-configuration/configurations-control/supprimer',
            templateUrl: userManualGestionConfigurationsConfigurationsCtrlSupprimerConfigurationCtrlTemplate,
            data: {
                title: 'Supprimer une configuration de contrôle',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Configurations de contrôle',
                        sref: 'app.help.configurationCtrl',
                    },
                ],
                previous: 'app.help.parametrerConfigurationCtrl',
                next: 'app.help.zoneLivraison',
            },
        })
        /* Only for Orange */
        .state('app.help.linkBetweenGridsConfigurationCtrl', {
            url: '/gestion-configuration/configurations-control/lien-grilles',
            templateUrl: userManualGestionConfigurationsLinkBetweenGridsConfigurationCtrl,
            data: {
                title: 'Lien entre les grilles',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Configurations de contrôle',
                        sref: 'app.help.configurationCtrl',
                    },
                ],
                previous: 'app.help.supprimerConfigurationCtrl',
                next: 'app.help.zoneLivraison',
            },
        })
        /* Gestion Zones de livraison */
        .state('app.help.zoneLivraison', {
            url: '/gestion-configuration/zone-Livraison',
            templateUrl: userManualGestionConfigurationsZonesLivraisonZoneLivraisonTemplate,
            data: {
                title: 'Zones de livraison',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                ],
                previous: 'app.help.supprimerConfigurationCtrl',
                next: 'app.help.informationZoneLivraison',
            },
        })
        .state('app.help.informationZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/informations',
            templateUrl: userManualGestionConfigurationsZonesLivraisonInformationZoneLivraisonTemplate,
            data: {
                title: 'Informations sur les zones de livraison',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.zoneLivraison',
                next: 'app.help.creerZoneLivraison',
            },
        })
        .state('app.help.creerZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/creer',
            templateUrl: userManualGestionConfigurationsZonesLivraisonCreerZoneLivraisonTemplate,
            data: {
                title: 'Créer une zone de livraison',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.informationZoneLivraison',
                next: 'app.help.sousTraitantZoneLivraison',
            },
        })
        .state('app.help.sousTraitantZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/sous-traitant',
            templateUrl: userManualGestionConfigurationsZonesLivraisonSousTraitantZoneLivraisonTemplate,
            data: {
                title: 'Rattacher des sous-traitants',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.creerZoneLivraison',
                next: 'app.help.importZoneLivraison',
            },
        })
        .state('app.help.importZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/import',
            templateUrl: userManualGestionConfigurationsZonesLivraisonImportZoneLivraisonTemplate,
            data: {
                title: 'Importer des sous-traitants',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.sousTraitantZoneLivraison',
                next: 'app.help.parametrerZoneLivraison',
            },
        })
        .state('app.help.parametrerZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/parametrer',
            templateUrl: userManualGestionConfigurationsZonesLivraisonParametrerZoneLivraisonTemplate,
            data: {
                title: 'Paramétrer une zone de livraison',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.importZoneLivraison',
                next: 'app.help.supprimerZoneLivraison',
            },
        })
        .state('app.help.supprimerZoneLivraison', {
            url: '/gestion-configuration/zone-Livraison/supprimer',
            templateUrl: userManualGestionConfigurationsZonesLivraisonSupprimerZoneLivraisonTemplate,
            data: {
                title: 'Supprimer une zone de livraison',
                navigation: [
                    {
                        title: 'Gestion des configurations',
                        sref: 'app.help.gestionConfigurations',
                    },
                    {
                        title: 'Zones de livraison',
                        sref: 'app.help.zoneLivraison',
                    },
                ],
                previous: 'app.help.parametrerZoneLivraison',
                next: 'app.help.interventionGrid',
            },
        })
        /* Grille intervention */
        .state('app.help.interventionGrid', {
            url: '/grilles-intervention/grilles-intervention',
            templateUrl: userManualGestionConfigurationsGrilleInterventionInterventionGridTemplate,
            data: {
                title: 'Grilles intervention',
                navigation: [
                    {
                        title: 'Grilles intervention',
                        sref: 'app.help.interventionGrid',
                    },
                ],
                previous: 'app.help.supprimerZoneLivraison',
                next: 'app.help.interventionList',
            },
        })
        .state('app.help.interventionList', {
            url: '/grilles-intervention/liste',
            templateUrl: userManualGestionConfigurationsGrilleInterventionInterventionListTemplate,
            data: {
                title: 'Liste grilles intervention',
                navigation: [
                    {
                        title: 'Grilles intervention',
                        sref: 'app.help.interventionGrid',
                    },
                    { title: 'list', sref: 'app.help.interventionList' },
                ],
                previous: 'app.help.interventionGrid',
                next: 'app.help.gestionDepot',
            },
        })
        /* Tableau de bord  & Barre navigation*/
        .state('app.help.dashbord&Nav', {
            url: '/home/',
            templateUrl: userManualDashbordAndNavigationDashAndNavTemplate,
            data: {
                title: "Gestion Menu d'accueil",
                navigation: [],
                previous: 'app.help.synoLinkedMviewer',
                next: 'app.help.barreNavigation',
            },
        })
        .state('app.help.barreNavigation', {
            url: '/home/barre-navigation',
            templateUrl: userManualDashbordAndNavigationBarreNavigationBarreNavigationTemplate,
            data: {
                title: 'Barre de Navigation',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                ],
                previous: 'app.help.dashbord&Nav',
                next: 'app.help.gestionAcces',
            },
        })
        .state('app.help.gestionAcces', {
            url: '/home/barre-navigation/connexion',
            templateUrl: userManualDashbordAndNavigationBarreNavigationGestionAccesTemplate,
            data: {
                title: 'Gestion Accès - Déconnection',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                    {
                        title: 'Barre de Navigation',
                        sref: 'app.help.barreNavigation',
                    },
                ],
                previous: 'app.help.barreNavigation',
                next: 'app.help.monProfil',
            },
        })
        .state('app.help.monProfil', {
            url: '/home/barre-navigation/mon-profil',
            templateUrl: userManualDashbordAndNavigationBarreNavigationMonProfilTemplate,
            data: {
                title: 'Mon Profil - Mode Administrateur',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                    {
                        title: 'Barre de Navigation',
                        sref: 'app.help.barreNavigation',
                    },
                ],
                previous: 'app.help.gestionAcces',
                next: 'app.help.notification',
            },
        })
        .state('app.help.notification', {
            url: '/home/barre-navigation/notifications',
            templateUrl: userManualDashbordAndNavigationBarreNavigationNotificationTemplate,
            data: {
                title: 'Gestion des notifications',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                    {
                        title: 'Barre de Navigation',
                        sref: 'app.help.barreNavigation',
                    },
                ],
                previous: 'app.help.monProfil',
                next: 'app.help.tableauDeBord',
            },
        })
        /* Tableau de bord */
        .state('app.help.tableauDeBord', {
            url: '/home/tableau-de-bord',
            templateUrl: userManualDashbordAndNavigationTableauDeBordTableauDeBordTemplate,
            data: {
                title: 'Tableau de bord',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                ],
                previous: 'app.help.notification',
                next: 'app.help.tableauAdmin',
            },
        })
        .state('app.help.tableauAdmin', {
            url: '/home/tableau-de-bord/admin',
            templateUrl: userManualDashbordAndNavigationTableauDeBordTableauAdminTemplate,
            data: {
                title: 'Éléments - Mode Administrateur',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                    { title: 'Tableau de bord', sref: 'app.help.tableauBord' },
                ],
                previous: 'app.help.tableauDeBord',
                next: 'app.help.tableauUser',
            },
        })
        .state('app.help.tableauUser', {
            url: '/home/tableau-de-bord/user',
            templateUrl: userManualDashbordAndNavigationTableauDeBordTableauUserTemplate,
            data: {
                title: 'Éléments - Mode Utilisateur',
                navigation: [
                    {
                        title: "Gestion Menu d'accueil",
                        sref: 'app.help.dashbord&Nav',
                    },
                    { title: 'Tableau de bord', sref: 'app.help.tableauBord' },
                ],
                previous: 'app.help.tableauAdmin',
            },
        });
});
