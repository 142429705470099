angular.module('dotic').factory('companyProvider', companyProvider);

function companyProvider(ApiProvider, RestProvider, Upload, URI_KEYS) {
    return {
        addUser,
        addUserById,
        create: createCompany,
        delete: deleteCompany,
        get: getCompany,
        getEmployeeDetailsForCompany,
        getAll: getAllCompanies,
        getAllGridFillName, // TODO : get all fillGrids by company form /fillGrids endpoint
        getAllProjectsForDeposit: getAllProjectsForDeposit,
        getAllSubCompanies,
        getCompaniesList: getCompaniesList,
        getCompaniesUsersList: getCompaniesUsersList,
        getCompanySubCompanies,
        getEdit,
        getNew,
        removeUser: removeUserCompany,
        createAndRunDeposit: createAndRunDeposit,
        update: updateCompany,
        updateUser: updateUserCompany,
    };

    /**
     * POST /companies/:companyId/users/add
     */
    function addUser(companyId, userData) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one(URI_KEYS.USER).one('add').customPOST(userData);
    }

    /**
     * POST /companies/:companyId/users/add/:userId
     */
    function addUserById(companyId, userId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one(URI_KEYS.USER).one('add').one(userId).post();
    }

    /**
     * POST /companies
     */
    function createCompany(company) {
        return ApiProvider.one(URI_KEYS.COMPANY).customPOST(company);
    }

    /**
     * DELETE /companies/:companyId
     */
    function deleteCompany(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).remove();
    }

    /**
     * GET /companies/:companyId
     */
    function getCompany(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).get();
    }

    /**
     * GET /companies/:companyId/employees
     */
    function getEmployeeDetailsForCompany(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one('employees').get();
    }

    /**
     * GET /companies/:companyId
     */
    function getEdit(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one('edit').get();
    }

    /**
     * GET /companies/new
     * Get a blank company with default values from api
     */
    function getNew() {
        return ApiProvider.one(URI_KEYS.COMPANY).one('new').get();
    }

    /**
     * GET /companies
     */
    function getAllCompanies() {
        return ApiProvider.one(URI_KEYS.COMPANY).getList();
    }

    /**
     * GET /companies/list
     */
    function getCompaniesList() {
        return ApiProvider.one(URI_KEYS.COMPANY, 'list').getList();
    }

    /**
     * GET /companies/supervision-companies
     */
    function getCompaniesUsersList() {
        return ApiProvider.one(URI_KEYS.COMPANY, 'supervision-companies').getList();
    }

    /**
     * GET /companies/:companyId/projects-deposit
     */
    function getAllProjectsForDeposit(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one('projects-deposit').get();
    }

    /**
     * DELETE /companies/:companyId/users/:userId/remove
     */
    function removeUserCompany(companyId, userId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one(URI_KEYS.USER, userId).one('remove').remove();
    }

    /**
     * New version of the deposit
     * This endpoint does not need to call /run
     * POST /companies/:companyId/deposits/create-run
     */
    function createAndRunDeposit(companyId, file, deposit) {
        return Upload.upload({
            url: `${APP_SETTINGS.baseApiUrl}/${URI_KEYS.COMPANY}/${companyId}/${URI_KEYS.DEPOSIT}/create-run`,
            data: { file, deposit },
        });
    }

    /**
     * PUT /companies/:companyId
     */
    function updateCompany(companyId, company) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).customPUT(company);
    }

    /**
     * PUT /companies/:companyId/user/:userId
     */
    function updateUserCompany(companyId, user) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one(URI_KEYS.USER, user.id).customPUT(user);
    }

    /**
     * GET /companies/:companyId/sub-companies
     */
    function getCompanySubCompanies(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one('sub-companies').get();
    }

    /**
     * GET /companies/:companyId/sub-companies
     */
    function getAllSubCompanies() {
        return ApiProvider.one(URI_KEYS.COMPANY).one('sub-companies').get();
    }

    /**
     * GET /companies/:companyId/gridFills/name
     */
    function getAllGridFillName(companyId) {
        return ApiProvider.one(URI_KEYS.COMPANY, companyId).one(`${URI_KEYS.FILL_GRID}/name`).get();
    }
}
