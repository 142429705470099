class PhaseService {
    getAll() {
        return ['DIA', 'PRE', 'AVP', 'PRO', 'EXE', 'REC', 'MCO'];
    }

    /**
     *
     * @return {{
     *  REC: {backgroundColor: string, hoverBackgroundColor: string},
     *  PRE: {backgroundColor: string, hoverBackgroundColor: string},
     *  EXE: {backgroundColor: string, hoverBackgroundColor: string},
     *  MCO: {backgroundColor: string, hoverBackgroundColor: string},
     *  AVP: {backgroundColor: string, hoverBackgroundColor: string},
     *  PRO: {backgroundColor: string, hoverBackgroundColor: string},
     *  DIA: {backgroundColor: string, hoverBackgroundColor: string}
     *  }}
     */
    getColors() {
        return {
            DIA: {
                backgroundColor: '#9c27b0',
                hoverBackgroundColor: '#a816c1',
            },
            PRE: {
                backgroundColor: '#f06292',
                hoverBackgroundColor: '#ff508c',
            },
            AVP: {
                backgroundColor: '#5069a9',
                hoverBackgroundColor: '#4564b4',
            },
            PRO: {
                backgroundColor: '#b3e5fc',
                hoverBackgroundColor: '#aae8ff',
            },
            EXE: {
                backgroundColor: '#039be5',
                hoverBackgroundColor: '#00a5ff',
            },
            REC: {
                backgroundColor: '#00a889',
                hoverBackgroundColor: '#00bd96',
            },
            MCO: {
                backgroundColor: '#edb349',
                hoverBackgroundColor: '#ffb935',
            },
        };
    }

    isDataPhaseEnabled(phase, item) {
        const currentPhase = item.phases.find((phaseToCompare) => phaseToCompare.phaseName === phase);

        return currentPhase ? currentPhase.isEnabled : false;
    }
}

angular.module('dotic').factory('$phaseService', () => new PhaseService());
