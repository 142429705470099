angular.module('dotic').factory('dataModelProvider', dataModelProvider);

function dataModelProvider(ApiProvider, URI_KEYS) {
    return {
        create: createDataModel,
        delete: deleteDataModel,
        get: getDataModel,
        getAll: getAllDataModels,
        getAllNames: getAllDataModelNames,
        getDataModelValue: getDataModelValue,
        getList: getList,
        remove: removeDataModel,
        update: updateDataModel,
    };

    /**
     * POST /dataModels
     *
     * @returns {*} Promise
     */
    function createDataModel(dataModel) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL).customPOST(dataModel);
    }

    /**
     * DELETE /dataModels/:dataModelId
     *
     * @param {string} dataModelId
     *
     * @returns {*} Promise
     */
    function deleteDataModel(dataModelId) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL, dataModelId).remove();
    }

    /**
     * GET /dataModels/:dataModelId
     *
     * @param {string} dataModelId
     *
     * @returns {*} Promise
     */
    function getDataModel(dataModelId) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL, dataModelId).get();
    }

    /**
     * GET /dataModels
     *
     * @returns {*} Promise
     */
    function getAllDataModels(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL).customGET(null, queryParams);
    }

    /**

     * GET /dataModels
     *
     * @returns {*} Promise
     */
    function getAllDataModelNames() {
        return ApiProvider.one(`${URI_KEYS.DATA_MODEL}/name`).getList();
    }

    /**
     * GET /dataModels/list
     *
     * @returns {*} Promise
     */
    function getList() {
        return ApiProvider.one(URI_KEYS.DATA_MODEL, 'list').getList();
    }

    /**
     * PUT /dataModels/:dataModelId
     *
     * @param {string} dataModelId
     * @param {object} dataModel
     *
     * @returns {*} Promise
     */
    function updateDataModel(dataModelId, dataModel) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL, dataModelId).customPUT(dataModel);
    }

    /**
     * GET /dataModels/:dataModelId/value/:fillGridDataModelRelationName
     *
     * @param {string} dataModelId
     * @param {string} fillGridDataModelRelationName
     * @returns {*} Promise
     */
    function getDataModelValue(dataModelId, fillGridDataModelRelationName) {
        return ApiProvider.one(`${URI_KEYS.DATA_MODEL}/${dataModelId}/value/${fillGridDataModelRelationName}`).get();
    }

    /**
     * DELETE /dataModels/:dataModelId
     *
     * @param {string} dataModelId
     *
     * @returns {*} Promise
     */
    function removeDataModel(dataModelId) {
        return ApiProvider.one(URI_KEYS.DATA_MODEL, dataModelId).remove();
    }
}
