export const definitions = {
    avgpbo: {
        description:
            ' Désigne le Point de Branchement Optique, installé en amont à proximité du logement et destiné à le desservir. ',
        thresholds:
            "Désigne le nombre moyen de logements raccordables au PBO. Celui-ci doit être > 2 et < 8. Il est l'un des indicateurs permettant de qualifier la conception de la distribution optique.",
        titlePBO: 'PBO',
        titleThresholds: 'Nombre moyen de lignes raccordables',
    },
    bpe: {
        description:
            ' Désigne le Boîtier de Protection d’Épissure installé sur la partie structurante du réseau optique pour raccorder un câble optique en amont vers 1 ou plusieurs câbles optiques en avals.',
        title: 'BPE',
    },
    bpi: {
        description:
            ' Désigne le Boîtier de Pied d’Immeuble, une solution d’interconnexion permettant d’épissurer un câble principal vers 6 ou 12 câbles secondaires en optimisant la densité du raccordement.',
        title: 'BPI',
    },
    bti: {
        description:
            " Désigne le Boîtier de Transition d'Intérieur, premier boîtier fibre que l'on trouve au pied d'immeuble.",
        title: 'BTI',
    },
    connectability: {
        descriptionConnectability:
            'Désigne le pourcentage de logements qui peuvent être raccordés au PBO sur le territoire concerné.',
        descriptionLocal: "Désigne le local dans lequel est hébergé l'infrastructure optique.",
        descriptionSUF: "Désigne le Site Utilisateur Final, c'est à dire le logement dans lequel est installé le PTO.",
        titleConnectability: 'Taux de raccordabilité',
        titleLocal: 'LOCAUX',
        titleSUF: 'SUF',
    },
    controlPoint: {
        descriptionControlPoint:
            "Désigne un contrôle qui pourra être répété autant de fois qu'il y a d'enregistrements concernés dans le jeu de données contrôlé.",
        descriptionAnomaly: 'Désigne un contrôle qui a détecté une ou plusieurs erreur(s) dans le jeu de donnée.',
        descriptionInoperative: "Désigne un contrôle qui n'a pas pu se réaliser faute de données.",
        descriptionSuccess: "Désigne un contrôle qui n'a détecté aucune anomalie dans le jeu de donnée.",
        titleAnomaly: 'Point de contrôles en anomalie',
        titleControlPoint: 'Point de contrôles',
        titleInoperative: 'Point de contrôles inopérant',
        titleSuccess: 'Point de contrôles réussi',
    },
    housing: {
        description: 'Équivalent aux logements qui ont une continuité optique. ',
        title: 'Adresse',
    },
    housingTree: {
        description:
            'Désigne le logement pour lequel il existe une continuité optique entre le point de mutualisation et le point de branchement optique (PBO). ',
        title: 'Logement',
    },
    nro: {
        description:
            " Désigne le Nœud de Raccordement Optique, un point de concentration d’un réseau en fibre optique où sont installés les équipements actifs et passifs. C'est au NRO que l'opérateur commercial vient se connecter.",
        title: 'NRO',
    },
    opticalCable: {
        titleCX: 'CX',
        descriptionCX: 'COLLECTE TRANSPORT DISTRIBUTION',
        titleCO: 'CO',
        descriptionCO: 'COLLECTE',
        titleCT: 'CT',
        descriptionCT: 'COLLECTE TRANSPORT',
        titleCD: 'CD',
        descriptionCD: 'COLLECTE DISTRIBUTION',
        titleTD: 'TD',
        descriptionTD: 'TRANSPORT DISTRIBUTION',
        titleTR: 'TR',
        descriptionTR: 'TRANSPORT',
        titleDI: 'DI',
        descriptionDI: 'DISTRIBUTION',
        titleRA: 'RA',
        descriptionRA: 'RACCORDEMENT FINAL',
        titleBM: 'BM',
        descriptionBM: 'BOUCLE METROPOLITAINE',
        titleLH: 'LH',
        descriptionLH: 'LONGUE DISTANCE (LONG HAUL)',
    },
    pbo: {
        description:
            ' Désigne le Point de Branchement Optique, installé à proximité du logement et destiné à desservir ce logement.',
        title: 'PBO',
    },
    pto: {
        description:
            ' Désigne le Point de Terminaison Optique ou point terminal du réseau FTTH situé dans un local raccordable.',
        title: 'PTO',
    },
    plugTree: {
        description: 'Désigne la prise de terminaison optique située dans un logement pouvant être raccordé au PBO.',
        title: 'Prise',
    },
    sro: {
        description:
            'Sous-Répartiteur Optique aussi appelé PM (point de mutualisation). Désigne un point de concentration intermédiaire du réseau FTTH, relié en amont à un répartiteur général et en aval à un ensemble de prises.',
        descriptionSROL: 'Sous-Répartiteur Optique colocalisé dans le Nœud de Raccordement Optique (NRO).',
        title: 'SRO',
        titleSROL: 'SROL',
    },
    defStatus: {
        description:
            "Dans GraceTHD, l’attribut (xx_avct)  synthétise l'avancement. Utile pour distinguer en phase d'étude ce qui est existant et à créer. En usage conditionnel. Les valeurs autorisées sont : ‘Existant, À créer, En service, En travaux, Hors Service et Abandonné’ ",
        title: 'Avancement',
    },
    technicalPoints: {
        description:
            "Désigne l'ensemble des supports permettant le cheminement des câbles optiques. Les points techniques peuvent être souterrains (Chambres) ou aériens (Poteaux, Ancrages, Façades).",
        title: 'Points techniques aériens / souterrains',
    },
};

export const thematicsDef = [
    {
        key: 'remplissage',
        description:
            'Vérifie le remplissage logique d’un attribut par rapport à la grille de remplissage ou par rapport à d’autres attributs renseignés.',
    },
    {
        key: 'structure',
        description:
            'Vérifie des informations plus techniques de base de données : types de géométrie, clé primaire, modèle de données, …',
    },
    {
        key: 'coherence metier',
        description: 'Vérifie des informations liées à des règles métiers.',
    },
    {
        key: 'donnees et modele',
        description: 'Vérifie que les informations renseignées respectent les contraintes attributaires du GraceTHD.',
    },
    {
        key: 'geometrie et topologie',
        description: 'Vérifie les contrôles sur la géométrie et la topologie des données.',
    },
    {
        key: 'geometrie',
        description: 'Vérifie les géométries : projection géographique, croisement de câble, supperposition de nœud, …',
    },
    {
        key: 'topologie',
        description:
            'Vérifie les aspects topologiques liés à des logiques métiers et aux particularités géométriques du GraceTHD.',
    },
    {
        key: 'format et coherence grace',
        description: 'Vérifie des informations liées à des règles métiers. ',
    },
    {
        key: 'format et coherence gr@ce',
        description: 'Vérifie des informations liées à des règles métiers. ',
    },
];
