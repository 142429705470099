import template from './controlConfigurationGridsLink.html';

class ControlConfigurationGridsLinkController {
    constructor(
        $filter,
        $gridControlService,
        $location,
        $scope,
        $state,
        $stateParams,
        controlConfigurationProvider,
        userMetricsProvider,
    ) {
        this.$location = $location;
        this._translate = $filter('translate');
        this._$gridControlService = $gridControlService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._controlConfigurationProvider = controlConfigurationProvider;

        /**
         * @type {'controlGrid'|'fillGrid'|undefined}
         */
        this.tab = undefined;

        this.controlGridData = [];
        this.fillGridData = [];
        this.controlConfiguration = {};

        this.loading = true;
        this.controlConfigurationId = $stateParams.controlConfigurationId;

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    title: this._translate('shared.linkBetweenGrids'),
                    key: 'controlConfigurationGridsLink',
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlConfiguration'),
                href: this._$state.href('app.controlConfigurationList'),
                key: 'controlConfigurations',
            },
            allowedPreviousKeys: ['controlConfigurations', 'fillGrid', 'controlGrid'],
        });

        userMetricsProvider.openControlConfiguration(this.controlConfigurationId);
    }

    /**
     * @param {'controlGrid'|'fillGrid'} tabName
     */
    switchTab(tabName) {
        this.tab = tabName;
    }

    getControlConfigurations(controlConfigurationId) {
        return this._controlConfigurationProvider.get(controlConfigurationId).then((controlConfiguration) => {
            this.controlConfiguration = controlConfiguration;
        });
    }

    getControlGrid() {
        return this._controlConfigurationProvider
            .getControlGridLinks(this.controlConfigurationId)
            .then((controlGrid) => {
                this.controlGridData = controlGrid.data.map((data) => {
                    return {
                        ...data,
                        isActive: this.getIsActive(data),
                        isGenericControlPoint: this.genericControlPoints.includes(data.controlPoint),
                    };
                });
                // Set default grid to load in page
                this.switchTab('controlGrid');
            });
    }

    getFillGrid() {
        return this._controlConfigurationProvider.getFillGridLinks(this.controlConfigurationId).then((fillGrid) => {
            this.fillGridData = fillGrid.data.map((data) => {
                return {
                    ...data,
                    isActive: data.phases.some((phaseData) => phaseData.isEnabled),
                };
            });
        });
    }

    getIsActive(data) {
        const genericIsActiveFilter =
            data.networkSegments.some((networkSegment) => networkSegment.isEnabled) &&
            data.phases.some((phaseData) => phaseData.isEnabled);

        const nonGenericIsActiveFilter =
            genericIsActiveFilter &&
            data.attributes.length &&
            !data.attributes.some((attribute) => attribute.required === 'noRequired' || !attribute.required);

        return this.genericControlPoints.includes(data.controlPoint) ? genericIsActiveFilter : nonGenericIsActiveFilter;
    }

    $onInit() {
        this.loading = true;
        this.genericControlPoints = this._$gridControlService.getGenericControlPoints();
        this.getControlConfigurations(this.controlConfigurationId).then(() => {
            this.loading = false;
        });

        this.getControlGrid();
        this.getFillGrid();
    }
}

angular.module('dotic').component('controlConfigurationGridsLinkPage', {
    controller: ControlConfigurationGridsLinkController,
    templateUrl: template,
});
