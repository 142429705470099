import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../../../services/chart.service';
import { ColorService } from '../../../../services/color.service';
import { DistanceRangeEnum } from '../../../../models/grace.model';
import template from './local-number-by-distance-range.component.html';

const KeyEnum = {
    DISTANCE_RANGE: 'distanceRange',
    IMPLANTATION: 'implantation',
    LOCAL_COUNT: 'localCount',
};

class LocalNumberByDistanceRangeComponent {
    static orderedDistanceRanges = [
        DistanceRangeEnum.FROM_0_TO_50,
        DistanceRangeEnum.FROM_50_TO_100,
        DistanceRangeEnum.FROM_100_TO_150,
        DistanceRangeEnum.FROM_150_TO_200,
        DistanceRangeEnum.FROM_200_TO_300,
        DistanceRangeEnum.FROM_300_TO_500,
        DistanceRangeEnum.MORE_THAN_500,
    ];

    barChartData = { datasets: [], labels: [] };
    chartSize = 180;
    columns = [];
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.barChartData = this.getBarChartData(this.data);
        this.doughnutChartData = this.getDoughnutChartData(this.data);
        this.columns = this.getColumns();
        this.tableTitle = this._translate('shared.detailLocalCount');
    }

    getBarChartData(data) {
        const implantations = radashUnique(data.map((currentData) => currentData[KeyEnum.IMPLANTATION]));

        const datasets = implantations.map((implantationType) => {
            const filteredData = data.filter(
                (currentData) => !implantationType.localeCompare(currentData[KeyEnum.IMPLANTATION]),
            );

            const localCounts = LocalNumberByDistanceRangeComponent.orderedDistanceRanges.map((distanceRange) => {
                const dataWithProperDistanceRange = filteredData.find(
                    (currentData) => !distanceRange.localeCompare(currentData[KeyEnum.DISTANCE_RANGE]),
                );

                if (!dataWithProperDistanceRange) {
                    return 0;
                }

                return dataWithProperDistanceRange[KeyEnum.LOCAL_COUNT];
            });

            return ChartService.getTableChartDataset(
                localCounts,
                implantationType,
                ColorService.getImplantationTypeColor(implantationType),
            );
        });

        return {
            datasets: datasets,
            labels: LocalNumberByDistanceRangeComponent.orderedDistanceRanges,
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.DISTANCE_RANGE,
                translation: `${this._translate('shared.distanceRange')} (m)`,
            },
            {
                key: KeyEnum.LOCAL_COUNT,
                translation: this._translate('indicator.localNumber'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const elementsGroupedByDistanceRange = radashGroup(data, (currentData) => currentData[KeyEnum.DISTANCE_RANGE]);
        const localCounts = LocalNumberByDistanceRangeComponent.orderedDistanceRanges.reduce((acc, distanceRange) => {
            return {
                ...acc,
                [distanceRange]: radashSum(
                    elementsGroupedByDistanceRange[distanceRange] || [],
                    (currentData) => currentData[KeyEnum.LOCAL_COUNT] || 0,
                ),
            };
        }, {});

        const localCount = radashSum(Object.values(localCounts));

        const colors = Object.keys(localCounts).map((distanceRange) =>
            ColorService.getDistanceRangeColor(distanceRange),
        );

        return {
            bigLegend: {
                figure: localCount,
                name: this._translate('shared.local', { COUNT: localCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: Object.values(localCounts),
                },
            ],
            labels: LocalNumberByDistanceRangeComponent.orderedDistanceRanges,
        };
    }
}

angular.module('dotic').component('localNumberByDistanceRangeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: LocalNumberByDistanceRangeComponent,
    templateUrl: template,
});
