import { HelpersService } from '../../../services/helpers.service';
import template from './compareErrorsModal.html';

class CompareErrorsModalComponent {
    constructor($filter, $scope, $state, $stateParams) {
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._translate = $filter('translate');

        this.controlErrors = [];

        this.controlErrorsLastVersion = [];
        this.controlErrorsPreviousVersion = [];

        this.bottomTemplate = false;
        this.loading = true;
        this.error = false;

        this.filters = {
            searchErrors: '', // This filter must not match filter of page
        };
    }

    $onInit() {
        this.bottomTemplate = this.resolve.bottomTemplate;
        this.control = this.resolve.control;

        if (angular.isArray(this.resolve.controlErrors)) {
            this.setItems(this.resolve.controlErrors);
        } else {
            this.loading = false;
        }

        this.initValueFilter();
        this.initListFilter();
        this.initWatchFilters();
    }

    setItems(items) {
        if (!items) {
            this.error = true;
            this.loading = false;

            return;
        }

        const lastVersion = items[0];
        const previousVersion = items[1];

        this.controlErrorsLastVersion = 'errors' in lastVersion ? lastVersion.errors : lastVersion;
        this.controlErrorsPreviousVersion = 'errors' in previousVersion ? previousVersion.errors : previousVersion;

        const stability = this.controlAnoStability(this.controlErrorsLastVersion, this.controlErrorsPreviousVersion);
        const regression = this.controlAnoRegression(this.controlErrorsLastVersion, this.controlErrorsPreviousVersion);

        const improvement = this.controlAnoImprovement(
            this.controlErrorsPreviousVersion,
            this.controlErrorsLastVersion,
        );

        const difference = this.controlAnoDiff(this.controlErrorsLastVersion, this.controlErrorsPreviousVersion);

        this.compareVersion = [...stability, ...regression, ...improvement, ...difference];

        this.loading = false;
    }

    controlAnoStability(lastVersion, previousVersion) {
        return lastVersion
            .filter((errLast) => {
                return previousVersion.some((errPrev) => {
                    return (
                        errLast.table === errPrev.table &&
                        errLast.attribute === errPrev.attribute &&
                        errLast.value === errPrev.value &&
                        errLast.count === errPrev.count
                    );
                });
            })
            .map((element) => {
                const anoCompare = previousVersion.find((previousElement) => {
                    return (
                        previousElement.table === element.table &&
                        previousElement.attribute === element.attribute &&
                        previousElement.value === element.value &&
                        previousElement.count === element.count
                    );
                });

                const stability = true;
                const onlyLastVersion = false;
                const onlyPreviousVersion = false;

                return {
                    ...element,
                    anoCompare,
                    stability,
                    onlyLastVersion,
                    onlyPreviousVersion,
                };
            });
    }

    controlAnoDiff(lastVersion, previousVersion) {
        return lastVersion
            .filter((errLast) => {
                return previousVersion.some((errPrev) => {
                    return (
                        errLast.table === errPrev.table &&
                        errLast.attribute === errPrev.attribute &&
                        errLast.value === errPrev.value &&
                        errLast.count !== errPrev.count
                    );
                });
            })
            .map((element) => {
                const anoCompare = previousVersion.find((previousElement) => {
                    return (
                        previousElement.table === element.table &&
                        previousElement.attribute === element.attribute &&
                        previousElement.value === element.value &&
                        previousElement.count !== element.count
                    );
                });

                const stability = false;
                const onlyLastVersion = false;
                const onlyPreviousVersion = false;

                return {
                    ...element,
                    anoCompare,
                    stability,
                    onlyLastVersion,
                    onlyPreviousVersion,
                };
            });
    }

    controlAnoRegression(lastVersion, previousVersion) {
        return lastVersion
            .filter((errLast) => {
                return !previousVersion.some((errPrev) => {
                    return (
                        errLast.table === errPrev.table &&
                        errLast.attribute === errPrev.attribute &&
                        errLast.value === errPrev.value
                    );
                });
            })
            .map((element) => {
                const stability = false;
                const onlyLastVersion = true;
                const onlyPreviousVersion = false;
                const previousVersionCount = 0;

                return {
                    ...element,
                    previousVersionCount,
                    stability,
                    onlyLastVersion,
                    onlyPreviousVersion,
                };
            });
    }

    controlAnoImprovement(previousVersion, lastVersion) {
        return previousVersion
            .filter((errPrev) => {
                return !lastVersion.some((errLast) => {
                    return (
                        errLast.table === errPrev.table &&
                        errLast.attribute === errPrev.attribute &&
                        errLast.value === errPrev.value
                    );
                });
            })
            .map((element) => {
                const stability = false;
                const onlyLastVersion = false;
                const onlyPreviousVersion = true;
                const lastVersionCount = 0;

                return {
                    ...element,
                    stability,
                    onlyLastVersion,
                    onlyPreviousVersion,
                    lastVersionCount,
                };
            });
    }

    hasIdClient() {
        if (!this.control.idClient) {
            return false;
        }

        return this.control.idClient.trim() !== '';
    }

    //filters
    initWatchFilters() {
        this._$scope.$watchGroup(['$ctrl.filters.statusDiff', '$ctrl.filters.searchErrors'], () =>
            this.filterCompareErrors(),
        );
    }

    filterCompareErrors() {
        this.updateUrlFilters();
        const searchErrors = this.filters.searchErrors.toLowerCase();

        this.filteredCompareVersion = this.compareVersion
            .filter(this.filterSearch(searchErrors))
            .filter(this.filterStatusDiff());

        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
    }

    updateUrlFilters() {
        this._$state.go('.', {
            statusDiff: this.filters.statusDiff,
            searchErrors: this.filters.searchErrors,
        });
    }

    initFilterSelectListData() {
        const selectListData = {
            isSame: this._translate('shared.same'),
            isDifferent: this._translate('shared.different'),
            onlyLastVersion: this._translate('shared.onlyInVersion', {
                NAME: this.control.version1,
            }),
            onlyPreviousVersion: this._translate('shared.onlyInVersion', {
                NAME: this.control.version2,
            }),
        };

        this.filterOptionsStatus = {
            status: Object.keys(selectListData).map((key) => ({
                key,
                value: selectListData[key],
            })),
        };
    }

    initValueFilter() {
        this.filters = {
            statusDiff: this._$state.params.statusDiff || '',
            searchErrors: this._$state.params.searchErrors || '',
        };
    }

    initListFilter() {
        this.initFilterSelectListData();
        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
    }

    filterStatusDiff() {
        return (anomaly) => {
            switch (this.filters.statusDiff) {
                case 'isSame':
                    return anomaly.stability === true;
                case 'isDifferent':
                    return anomaly.stability === false;
                case 'onlyLastVersion':
                    return anomaly.onlyLastVersion === true;
                case 'onlyPreviousVersion':
                    return anomaly.onlyPreviousVersion === true;
                default:
                    return true;
            }
        };
    }

    filterSearch(search) {
        return (error) =>
            search === '' ||
            (error.table || '').toLowerCase().indexOf(search) > -1 ||
            (error.attribute || '').toLowerCase().indexOf(search) > -1 ||
            (error.message || '').toLowerCase().indexOf(search) > -1 ||
            (error.value || '').toLowerCase().indexOf(search) > -1;
    }

    cleanFilter(filters) {
        HelpersService.cleanFilter(filters);
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('compareErrorsModal', {
    templateUrl: template,
    controller: CompareErrorsModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
