import { FMEJobEnum } from '../../models/deposit.model';
import template from './job-status.html';

class CcJobStatusController {
    $onInit() {
        this.job = this.getJob();
    }

    getJob() {
        switch (this.jobName) {
            case FMEJobEnum.CONTROL:
            case FMEJobEnum.LOAD:
                return this.deposit.FMEJobs.find((FMEJob) => FMEJob.job === this.jobName);
            case FMEJobEnum.GEOPACKAGE:
                return this.deposit.fmeJob;
            default:
                return { status: 'UNKNOWN' };
        }
    }
}

angular.module('dotic').component('ccJobStatus', {
    templateUrl: template,
    controller: CcJobStatusController,
    bindings: {
        deposit: '<',
        jobName: '@',
    },
});
