import template from './welcome.html';

class WelcomePage {
    constructor($state, $auth, $toasterService, userProvider) {
        this._$state = $state;
        this._$auth = $auth;
        this._userProvider = userProvider;
        this._$toasterService = $toasterService;

        this.supportEmail = APP_SETTINGS.SSO_SUPPORT_EMAIL ?? 'sso@dotic.fr';
        this.mailTo = encodeURI(`mailto:${this.supportEmail}`);
    }

    $onInit() {
        this.getUserInfo();
    }

    getUserInfo() {
        const { userId } = this._$auth.getPayload();
        this._userProvider
            .userInfo(userId)
            .then((user) => {
                if (user.companyCount && user.companyCount > 0) {
                    return this._$state.go('app.depositJob-control');
                }

                this.prepareMailTo(user);
            })
            .catch(this._$toasterService.error);
    }

    prepareMailTo(me) {
        const subject = 'ConnectControl - Demande de rattachement RIP';
        const body = `Nom, Prénom: ${me.fullname}\nEmail du compte: ${me.email}\nRIP souhaités:`;
        this.mailTo = encodeURI(`mailto:${this.supportEmail}?subject=${subject}&body=${body}`);
    }
}

angular.module('dotic').component('welcomePage', {
    controller: WelcomePage,
    templateUrl: template,
});
