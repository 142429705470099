import template from './fillingStatisticDetail.html';

class FillingStatisticDetailPage {
    constructor(
        $authorizationService,
        $fillingStatisticsService,
        $filter,
        $filterService,
        $location,
        $log,
        $modalService,
        $scope,
        $sliderService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        fillingStatisticsProvider,
        LoaderService,
        reportProvider,
        userMetricsProvider,
    ) {
        this._$fillingStatisticsService = $fillingStatisticsService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$log = $log;
        this._$scope = $scope;
        this._$sliderService = $sliderService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._fillingStatisticsProvider = fillingStatisticsProvider;
        this._LoaderService = LoaderService;
        this._reportProvider = reportProvider;
        this._userMetricsProvider = userMetricsProvider;
        this.isAllowed = $authorizationService.isAllowed;
        this._$modalService = $modalService;

        this.fillingStatId = this._$stateParams.fillingStatisticId;

        this.loading = true;

        this.hasFillingOpen = true;

        // Data
        this.hasData = false;
        this.projectIds = [];
        this.projectsName = [];
        this.deliveryZonesName = [];
        this.fillGrid = {};
        this.controlGrid = {};
        this.depositsData = [];
        this.status = 'PENDING';

        this.hasConditionTestable = false;

        this.isDownloaded = true;
        this.firstOpenDetail = false;
        this.isDepositViewOpen = false;
        this.fillingStatistics = null;

        this.fillingRateTable = null;

        this.getHorizontalBarChartFilledOptions = this._$fillingStatisticsService.getHorizontalBarChartFilledOptions();

        // filter
        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'attributeNames',
                selected: 'attribute',
                placeholder: 'filter.all.attributes',
                hasSearch: true,
                translateKeyLabel: 'shared.attribute',
                translateParamsLabel: { COUNT: 1 },
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'testableConditionList',
                value: 'testableName',
                placeholder: 'filter.all.testableCondition',
                translateKeyLabel: 'shared.testableCondition',
                hasSearch: true,
            },
            {
                allowed: true,
                name: 'severityList',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                saveValue: true,
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'requiredList',
                value: 'required',
                placeholder: 'filter.all.status',
                saveValue: true,
                translateKeyLabel: 'shared.status',
            },
            {
                allowed: true,
                name: 'tableList',
                selected: 'table',
                placeholder: 'filter.all.table',
                hasSearch: true,
                translateKeyLabel: 'shared.table',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'fillingRate',
                min: 'minPercentageAverage',
                max: 'maxPercentageAverage',
                options: 'options',
                translateKeyLabel: 'shared.fillingRate',
                type: 'slider',
                saveValue: true,
            },
        ];

        this.filter = this.initValueFilter($stateParams);

        this.filterValues = {
            attributeNames: [],
            severityList: [
                { key: 'minor', value: this._humanizeSeverity('minor') },
                { key: 'major', value: this._humanizeSeverity('major') },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            requiredList: [
                {
                    key: 'optional',
                    value: this._translate('status.optional', { COUNT: 1 }),
                },
                {
                    key: 'required',
                    value: this._translate('status.required', { COUNT: 1 }),
                },
            ],
            tableList: [],
            testableConditionList: [
                { key: true, value: this._translate('shared.yes') },
                { key: false, value: this._translate('shared.no') },
            ],
        };

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'fillingStatistic',
                    title: this._translate('statisticsPage.details', { TYPE: 'fill' }),
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.fillingStatistic'),
                href: $state.href('app.statisticsFillHome'),
                key: 'fillingStatistics',
            },
            allowedPreviousKeys: ['fillingStatistics', 'fillingStatisticsCompare'],
        });
    }

    $onInit() {
        this._fillingStatisticsProvider
            .getOne(this.fillingStatId)
            .then((data) => {
                this._userMetricsProvider.openFillingStatistics(this.fillingStatId);

                const {
                    deposits,
                    projects,
                    projectsDeposits,
                    projectIdsDeposits,
                    deliveryZonesDeposits,
                    deliveryZones,
                    controlGrid,
                    fillGrid,
                    synthesisControl,
                    projectIds,
                    status,
                    fillGridName,
                } = data;

                try {
                    this.hasData = true;
                    this.fillingStatistics = data;
                    this.projectIds = projectIds;
                    this.projectsName = projects.map((project) => project.name);
                    if (projectsDeposits && projectsDeposits.length > 0) {
                        this.projectsDepositName = projectsDeposits.map((project) => project.name);
                    }

                    if (deliveryZonesDeposits && deliveryZonesDeposits.length > 0) {
                        this.deliveryZonesDepositName = deliveryZonesDeposits.map((dz) => dz.name);
                    }

                    if (projectIdsDeposits && projectIdsDeposits.length > 0) {
                        this.projectIdsDeposits = projectIdsDeposits;
                    }

                    this.deliveryZonesName = deliveryZones.map((zone) => zone.name);
                    this.fillGrid = fillGrid;
                    this.fillGridName = fillGridName;
                    this.controlGrid = controlGrid;
                    this.depositsData = deposits;
                    this.synthesisControl = synthesisControl;
                    this.status = status;

                    // Keep all optional attributes
                    this.depositSynthesis = this._$fillingStatisticsService
                        .preparedSynthesisControl(this.fillingStatistics)
                        .filter((deposit) => deposit.required === 'required' || deposit.required === 'optional');

                    if (
                        this.depositSynthesis.find(
                            (deposit) => deposit.required === 'optional' && deposit.testableCondition,
                        )
                    ) {
                        this.hasConditionTestable = true;
                    }

                    this.loadBarChartsByRateRequired();
                    this.initChartsActiveTable();
                    this.prepareFilters();
                    this.initWatchFilters();
                } catch (e) {
                    this._$log.error(e.message);
                    this.hasData = false;
                    this.loading = false;
                    this.isDownloaded = false;
                }
            })
            .then(() => {
                this.tableDetail = this._$tableService.detail();
                this.loading = false;
                this.isDownloaded = false;
            })
            .catch(() => {
                this._$toasterService.error();
                this.hasData = false;
                this.loading = false;
                this.isDownloaded = false;
            });
    }

    showDepositHistoryChart(deposit, firstOpenDetail) {
        this.firstOpenDetail = firstOpenDetail;
        this.chartOldVersions = this._$fillingStatisticsService.preparedHistoryChart(deposit);
    }

    toggleDetails(control) {
        this.tableDetail.toggle(control);
        if (control?.depositSynthesis?.length) {
            this.showDepositHistoryChart(control.depositSynthesis[0], true);
        }
    }

    toggleDepositsVisibility(open, scrollTo = false) {
        let toggled = open;
        if (typeof toggled !== 'boolean') {
            toggled = !this.hasDepositsOpen;
        }

        if (scrollTo) {
            this._$timeout(() => {
                this.isDepositViewOpen = true;
                const element = angular.element('#deposits');
                angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
            }, 0);
        }

        this.hasDepositsOpen = toggled;
    }

    initChartsActiveTable() {
        this.fillingRateTable = this._$fillingStatisticsService.prepareActiveTableByDeposit(this.depositsData);
    }

    loadBarChartsByRateRequired() {
        this.fillingRateAttributRequired = this._$fillingStatisticsService.preparedChartPercentByPart(
            this.depositSynthesis,
        );
    }

    initValueFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        const fillingRate = {
            isSlider: true,
            options: this._$sliderService.optionPercent(),
            maxPercentageAverage: this._$state.params.maxPercentageAverage
                ? parseInt(this._$state.params.maxPercentageAverage)
                : 100,
            minPercentageAverage: this._$state.params.minPercentageAverage
                ? parseInt(this._$state.params.minPercentageAverage)
                : 0,
        };

        return {
            fillingRate: fillingRate,
            severity: this._$filterService.getFilterValue(params.severity),
            required: params.required || '',
            table: this._$filterService.getFilterValue(params.table),
            search: params.search || '',
            attribute: this._$filterService.getFilterValue(params.attribute),
            testableName: params.testableCondition || '',
        };
    }

    initFilterFillTable() {
        const tableList = new Map();
        this.synthesisControl.forEach(({ tableName }) => {
            tableList.set(tableName, tableName);
        });
        this.filterValues.tableList = Array.from(tableList, this.mapToObject);
    }

    initFilterAttributeNames() {
        const attributeNames = new Map();
        this.synthesisControl.forEach(({ attributeName }) => {
            attributeNames.set(attributeName, attributeName);
        });
        this.filterValues.attributeNames = Array.from(attributeNames, this.mapToObject);
    }

    prepareFilters() {
        this.initFilterFillTable();
        this.initFilterAttributeNames();
    }

    mapToObject([key, value]) {
        return { key, value };
    }

    initWatchFilters() {
        this._$scope.$watchGroup(
            [
                '$ctrl.filter.attribute',
                '$ctrl.filter.severity',
                '$ctrl.filter.required',
                '$ctrl.filter.search',
                '$ctrl.filter.table',
                '$ctrl.filter.testableName',
                '$ctrl.filter.fillingRate.maxPercentageAverage',
                '$ctrl.filter.fillingRate.minPercentageAverage',
            ],
            () => this.setScopeGridFillViewWithFilters(),
        );
    }

    setScopeGridFillViewWithFilters() {
        this._$state.go('.', {
            attribute: this.filter.attribute.join(','),
            severity: this.filter.severity.join(','),
            maxPercentageAverage: this.filter.fillingRate.maxPercentageAverage,
            minPercentageAverage: this.filter.fillingRate.minPercentageAverage,
            required: this.filter.required,
            search: this.filter.search,
            table: this.filter.table.join(','),
            testableName: this.filter.testableName,
        });

        this.filteredDepositSynthesis = this.depositSynthesis
            .filter(this._$filterService.genericMultiFilter(this.filter.severity, 'severity'))
            .filter(this.filterRequired(this.filter.required))
            .filter(this.filterSearch(this.filter.search))
            .filter(this.filterMax(this.filter.fillingRate.maxPercentageAverage))
            .filter(this.filterMin(this.filter.fillingRate.minPercentageAverage))
            .filter(this._$filterService.genericMultiFilter(this.filter.table, 'tableName'))
            .filter(this._$filterService.genericMultiFilter(this.filter.attribute, 'attributeName'))
            .filter(this.filterTestableCondition(this.filter.testableName));
    }

    cancelFilters() {
        this.filter = this.initValueFilter({}, false);
        this.filter.fillingRate.minPercentageAverage = 0;
        this.filter.fillingRate.maxPercentageAverage = 100;
        this.setScopeGridFillViewWithFilters();
    }

    removeFilter(filterName, initialValue) {
        if (filterName === 'slider') {
            this.filter.fillingRate.minPercentageAverage = initialValue[0];
            this.filter.fillingRate.maxPercentageAverage = initialValue[1];
        } else {
            this.filter[filterName] = initialValue;
        }

        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    filterMin(value) {
        return ({ percentageFill }) =>
            value === 0 || !angular.isNumber(value) || (percentageFill !== null && percentageFill >= value);
    }

    filterMax(value) {
        return ({ percentageFill }) =>
            value === 100 || !angular.isNumber(value) || (percentageFill !== null && percentageFill < value + 1);
    }

    filterSearch(search) {
        const wordSearch = search.toLowerCase();

        return (attribute) =>
            search === '' ||
            attribute.attributeName.toLowerCase().includes(wordSearch) ||
            attribute.description.toLowerCase().includes(wordSearch);
    }

    filterRequired(required) {
        return (attribute) => required === '' || attribute.required === required;
    }

    filterTestableCondition(testableName) {
        return (attribute) => testableName === '' || attribute.testableCondition === testableName;
    }

    onClickControl(data) {
        this.depositInfo = [];

        data.forEach((deposit) => {
            if (deposit.anomalyCount <= 0) {
                return;
            } else {
                const getErrors = () =>
                    this._reportProvider
                        .getProjectControlJson(deposit.reportId, '002', deposit.severity)
                        .catch((err) => {
                            this._$toasterService.error(err);
                        });

                return this.depositInfo.push({
                    ...deposit,
                    controlErrors: getErrors,
                    dataControlPoint002: {
                        controlPoint: '002',
                        shortDescription: 'Attribut obligatoire non renseigné',
                        description:
                            'Un attribut noté "Obligatoire" dans la grille de remplissage ne doit pas avoir de valeur NULL ou vide.',
                        attributName: deposit.attributeName,
                    },
                });
            }
        });
        this._$uibModal.open({
            size: 'xxl',
            component: 'controlErrorsFillingStatModal',
            resolve: {
                data: () => this.depositInfo,
            },
        });
    }

    async openProjectIdsModal(projectIds) {
        await this._$modalService.triggerListModal(
            projectIds.map((id) => ({ name: id })),
            this._translate('shared.businessCode'),
        );
    }

    async removeFillingStat() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.fillingStatistic', {
                COUNT: 1,
                NAME: this.fillingStatistics.statisticName,
            }),
        );
        if (!isAccepted) {
            return;
        }

        this._LoaderService.open(`
        <p>${this._translate('shared.deleting')}.</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        try {
            await this._fillingStatisticsProvider.remove(this.fillingStatId);
            this._$toasterService.success({
                body: this._translate('removeModal.successStatistic', { COUNT: 1 }),
            });
            await this._$state.go('app.statisticsFillHome');
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this._LoaderService.dismiss();
        }
    }

    async download(type, idOfType) {
        this.isDownloaded = true;

        try {
            this._userMetricsProvider.downloadFillingStatistics(this.fillingStatId);
            await this._fillingStatisticsProvider.download(this.fillingStatId, type, idOfType);
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this.isDownloaded = false;
        }
    }

    getDataSetIndexResult(element) {
        switch (element.datasetIndexResult) {
            case 0:
                this.filter.fillingRate = {
                    ...this.filter.fillingRate,
                    minPercentageAverage: 0,
                    maxPercentageAverage: 25,
                };
                break;
            case 1:
                this.filter.fillingRate = {
                    ...this.filter.fillingRate,
                    minPercentageAverage: 26,
                    maxPercentageAverage: 50,
                };
                break;
            case 2:
                this.filter.fillingRate = {
                    ...this.filter.fillingRate,
                    minPercentageAverage: 51,
                    maxPercentageAverage: 75,
                };
                break;
            case 3:
                this.filter.fillingRate = {
                    ...this.filter.fillingRate,
                    minPercentageAverage: 76,
                    maxPercentageAverage: 100,
                };
                break;
        }
    }

    scrollElement(customClickEvent) {
        this.cancelFilters();
        this.hasFillingOpen = true;

        if (customClickEvent.labelsIndexSeverity === 0) {
            this.filter.required = 'required';
            this.getDataSetIndexResult(customClickEvent);
        } else if (customClickEvent.labelsIndexSeverity === 1) {
            this.filter.required = 'optional';
            if (this.hasConditionTestable) {
                this.filter.testableName = true;
            }

            this.getDataSetIndexResult(customClickEvent);
        } else if (customClickEvent.labelsIndexSeverity === 2) {
            this.filter.required = 'optional';
            if (this.hasConditionTestable) {
                this.filter.testableName = false;
            }

            this.getDataSetIndexResult(customClickEvent);
        }

        const filterRequired = this.filterOptions.find((item) => item.name === 'requiredList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: filterRequired,
            saveChanges: false,
            openDropdown: false,
        });

        const slideFilter = this.filterOptions.find((item) => item.name === 'fillingRate');
        this._$scope.$broadcast('forceFilterRender', {
            filter: slideFilter,
            saveChanges: false,
            openDropdown: false,
        });

        const $element = angular.element('#showfillingRateTables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
        }, 0);
    }

    compareWith(fillingStatistic) {
        this._$state.go('app.comparisonPage', {
            fillStat: fillingStatistic._id,
        });
    }
}

angular.module('dotic').component('fillingStatisticDetailPage', {
    controller: FillingStatisticDetailPage,
    templateUrl: template,
});
