angular.module('dotic').factory('$pendingRequestsProvider', function () {
    let pending = [];

    this.add = (request) => {
        pending.push(request);
    };

    this.remove = (date) => {
        pending = pending.filter((request) => request.date !== date);
    };

    this.cancelAll = () => {
        pending.forEach((request) => {
            request.canceller.resolve();
        });
        pending.length = 0;
    };

    return this;
});
