import { defaultPagination } from '../../models/common.model';

class ServerListController {
    deliveryZoneList = [];
    loading = true;
    page = 0;
    pageSize = 10;
    servers = {
        data: [],
        page: 1,
        count: 0,
    };
    sort = { column: 'name', order: 1 };
    tableDetail = {};
    tableSelect = {};

    constructor(
        $auth,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        companyProvider,
        deliveryZoneProvider,
        serverProvider,
        userMetricsProvider,
    ) {
        this._$deleteService = $deleteService;
        this._$scope = $scope;
        this._$toasterService = $toasterService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$uibModal = $uibModal;
        this._$modalService = $modalService;
        this._companyProvider = companyProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._translate = $filter('translate');
        this._$tableService = $tableService;
        this._serverProvider = serverProvider;

        this.companyId = $auth.getPayload().company;
        this.filter = {
            search: $state.params.search || '',
        };
        $scope.$watchGroup(['$ctrl.filter.search'], async () => {
            if (this.tableSelect) {
                this.tableSelect.empty();
            }

            this._$state.go('.', { search: this.filter.search.toLocaleLowerCase() });
            this.servers = await this.getServers();
        });

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';
            $scope.$emit('updateNavigationUrl');
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    href: $location.path(),
                    key: 'servers',
                    title: this._translate('shared.server'),
                },
            ],
        });

        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });

        this._userMetricsProvider.listServers();
    }

    selectAllServers() {
        this._$scope.$broadcast('getVisibleRows');
    }

    async $onInit() {
        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();
        this.servers = await this.getServers();
    }

    async getServers(paginationParameters = { page: 0, limit: this.pageSize }) {
        this.loading = true;
        try {
            const servers = await this._serverProvider.getAll({
                ...paginationParameters,
                order: this.sort.order,
                orderBy: this.sort.column,
                search: this.filter.search,
            });

            await Promise.all(
                servers.data.map(async (server) => {
                    if (server.companies.length === 1) {
                        // Property doesn't exist on the model, this is a shortcut to avoid infinite re-rendering.
                        server.companyName = (await this._companyProvider.get(server.companies[0])).name;
                    }
                }),
            );

            return servers;
        } catch {
            return {
                ...defaultPagination,
                totalCount: 0,
            };
        } finally {
            this._$timeout(() => (this.loading = false));
        }
    }

    async getCompanies(companyIds) {
        try {
            return await Promise.all(companyIds.map(async (company) => await this._companyProvider.get(company)));
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.error') });

            return [];
        }
    }

    async onChange(page, perPage, sort) {
        this.pageSize = perPage;
        this.page = page;
        this.sort = sort;

        this.servers = await this.getServers({
            limit: perPage,
            page: page - 1,
            order: sort.order,
            orderBy: sort.column,
        });
    }

    async openCompanyModal(server) {
        const companies = this.getCompanies(server.companies);
        await this._$modalService.triggerListModal(companies, this._translate('shared.company'));
    }

    selectedServerHasDependencies() {
        const selectedServer = this.getSelectedServers();

        return selectedServer.some((server) => server.countDeposits > 0 || server.countDeliveryZones > 0);
    }

    async removeSelectedServers() {
        const selectedServers = this.getSelectedServers();
        if (selectedServers.length === 0 || this.selectedServerHasDependencies()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.storage', {
                COUNT: selectedServers.length,
                NAME: selectedServers[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedServerIds = selectedServers.map((selectedServer) => selectedServer.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedServerIds,
            (serverId) => this._serverProvider.removeServer(serverId),
            'removeModal.successGrid',
        );

        this.servers = await this.getServers();
    }

    removeTooltip() {
        if (!this.tableSelect.hasSelected) {
            return '';
        }

        if (this.selectedServerHasDependencies()) {
            return this._translate('serverList.deliverableOrDeliveryZoneAttached');
        }

        return this._translate('shared.deleteSelection');
    }

    viewDetails(server) {
        this.deliveryZoneList = [];

        if (this.serverDetailsShown === server.id) {
            this.serverDetailsShown = null;

            return;
        }

        this.serverDetailsShown = server.id;
    }

    async openDeliveryZoneModal(server) {
        await this._$modalService.triggerListModal(
            await this._deliveryZoneProvider.list({
                serverId: server.id,
            }),
        );
    }

    getSelectedServers() {
        const ids = this.tableSelect.getSelection();

        return this.servers.data.filter((server) => ids.includes(server.id));
    }
}

angular.module('dotic').controller('ServerListController', ServerListController);
