// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../assets/images/app/Logo_ConnectControl_White_2_lines.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<toaster-container toaster-options=\"{\n                'closeButton': true,\n                'debug': false,\n                'progressBar': true,\n                'preventDuplicates': false,\n                'position-class': 'toast-bottom-right',\n                'onclick': null,\n                'showDuration': '400',\n                'hideDuration': '1000',\n                'timeOut': '7000',\n                'extendedTimeOut': '1000',\n                'showEasing': 'swing',\n                'hideEasing': 'linear',\n                'showMethod': 'fadeIn',\n                'hideMethod': 'fadeOut'\n            }\"></toaster-container> <div ng-if=\"$ctrl.ssoUrl && $ctrl.alwaysRedirectToSso\" class=\"middle-box text-center loginscreen text-white\"> <p> {{ 'welcome.redirect' | translate }}&nbsp; <a href=\"{{ $ctrl.ssoUrl }}\">{{ $ctrl.ssoUrl }}</a> </p> </div> <div class=\"middle-box text-center loginscreen\" ng-if=\"!$ctrl.ssoUrl || !$ctrl.alwaysRedirectToSso\"> <img class=\"loginscreen-logo\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"Logo ConnectControl\"/> <h3 class=\"loginscreen-title\">{{ 'shared.newPassword' | translate }}</h3> <change-password is-center-mode=\"true\" is-confirm-mode-activated=\"true\" is-hint-displayed=\"true\" is-text-white=\"true\" on-submit=\"$ctrl.updatePassword(value)\"></change-password> <div class=\"loginscreen-copyright m-t-20\"><cc-version></cc-version></div> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/security/change-user-password/change-user-password.component.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;