import { sum as radashSum } from 'radash';

import {
    FieldOccurrenceAdditionalFieldEnum,
    FieldOccurrenceKeyEnum,
} from '../../../../services/indicator-field-occurrence.service';
import { DoughnutChartColorEnum } from '../../../../services/color.service';
import template from './field-occurrence.component.html';

class FieldOccurrenceComponent {
    chartSize = 180;
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    parentRef = {};

    constructor($indicatorFieldOccurrenceService, $filter) {
        this._$indicatorFieldOccurrenceService = $indicatorFieldOccurrenceService;
        this._translate = $filter('translate');
    }

    $onInit() {
        this.doughnutChartData = this.getDoughnutChartData(this.data);
    }

    getDoughnutChartData(data) {
        const elementsGroupedByField = this._$indicatorFieldOccurrenceService.getElementsGroupedByField(data);

        const valueCounts = Object.entries(elementsGroupedByField).reduce((acc, [key, values]) => {
            return {
                ...acc,
                [key]: radashSum(
                    (values || []).filter((value) => value[FieldOccurrenceKeyEnum.VALUE]),
                    (currentData) => currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            };
        }, {});

        const occurrenceCount = this._$indicatorFieldOccurrenceService.getOccurrenceCount(elementsGroupedByField);

        if (occurrenceCount) {
            valueCounts[FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC] =
                occurrenceCount - radashSum(Object.values(valueCounts));
        }

        return {
            bigLegend: {
                figure: occurrenceCount,
                name: this._translate('shared.address', { COUNT: occurrenceCount }),
            },
            datasets: [
                {
                    // TODO: To refacto later to make sure the order is always good
                    backgroundColor: [
                        DoughnutChartColorEnum.BIG_SUCCESS,
                        DoughnutChartColorEnum.SUCCESS,
                        DoughnutChartColorEnum.IN_PROGRESS,
                        DoughnutChartColorEnum.BIG_FAILURE,
                    ],
                    data: Object.values(valueCounts),
                },
            ],
            labels: [
                ...Object.entries(valueCounts).map(([key, value]) =>
                    this._$indicatorFieldOccurrenceService.getFieldTranslation(key, value),
                ),
            ],
        };
    }
}

angular.module('dotic').component('fieldOccurrenceIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: FieldOccurrenceComponent,
    templateUrl: template,
});
