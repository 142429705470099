import { jwtDecode } from 'jwt-decode';

class SecurityService {
    decodeToken(token) {
        try {
            return jwtDecode(token);
        } catch {
            return null;
        }
    }

    isPayloadExpired(payload) {
        // As JWT exp date is expressed in seconds and Date.now() in milliseconds, we need to do a conversion to compare those two.
        return Date.now() > payload.exp * 1000;
    }

    isTokenValid(token) {
        const payload = this.decodeToken(token);
        if (!payload) {
            return false;
        }

        return !this.isPayloadExpired(payload);
    }
}

angular.module('dotic').factory('$securityService', () => new SecurityService());
