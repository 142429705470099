import * as Sentry from '@sentry/browser';

function userService() {
    let user = {};
    let company = {};

    return {
        getConnectedUser: getConnectedUser,
        setConnectedUser: setConnectedUser,
        getConnectedCompany: getConnectedCompany,
        setConnectedCompany: setConnectedCompany,
    };

    function getConnectedUser() {
        return user;
    }

    function setConnectedUser(newUser) {
        user = newUser;
        if (user) {
            Sentry.setUser({ id: user.id });
        }
    }

    function getConnectedCompany() {
        return company;
    }

    function setConnectedCompany(newCompany) {
        company = newCompany;
        if (company) {
            Sentry.setContext('company', {
                name: company.name,
                id: company.id,
            });
        }
    }
}

angular.module('dotic').factory('$userService', userService);
