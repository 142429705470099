import userManualGestionUtilisateurGestionUtilisateurTemplate from './gestionUtilisateur.html';
import userManualGestionUtilisateurPrincipalUserCreerUtilisateurTemplate from './principalUser/creerUtilisateur.html';
import userManualGestionUtilisateurPrincipalUserInformationUtilisateurTemplate from './principalUser/informationUtilisateur.html';
import userManualGestionUtilisateurPrincipalUserModifierUtilisateurTemplate from './principalUser/modifierUtilisateur.html';
import userManualGestionUtilisateurPrincipalUserPrincipalUserTemplate from './principalUser/principalUser.html';
import userManualGestionUtilisateurPrincipalUserSupprimerUtilisateurTemplate from './principalUser/supprimerUtilisateur.html';
import userManualGestionUtilisateurSubContractorUserSubContractorSeeTemplate from './subContractorUser/subContractorSee.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.gestionUtilisateur', {
            url: '/gestion-utilisateurs/',
            templateUrl: userManualGestionUtilisateurGestionUtilisateurTemplate,
            data: {
                title: 'Utilisateurs',
                navigation: [{ title: 'Gestion des utilisateurs' }],
                next: 'app.help.principalUser',
            },
        })
        .state('app.help.principalUser', {
            url: '/gestion-utilisateurs/principaux-utilisateurs',
            templateUrl: userManualGestionUtilisateurPrincipalUserPrincipalUserTemplate,
            data: {
                title: 'Admin ou utilisateur',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                ],
                previous: 'app.help.gestionUtilisateur',
                next: 'app.help.informationUtilisateur',
            },
        })
        .state('app.help.informationUtilisateur', {
            url: '/gestion-utilisateurs/informations',
            templateUrl: userManualGestionUtilisateurPrincipalUserInformationUtilisateurTemplate,
            data: {
                title: 'Informations utilisateur',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                    { title: 'Utilisateurs', sref: 'app.help.principalUser' },
                ],
                previous: 'app.help.principalUser',
                next: 'app.help.creerUtilisateur',
            },
        })
        .state('app.help.creerUtilisateur', {
            url: '/gestion-utilisateurs/creer',
            templateUrl: userManualGestionUtilisateurPrincipalUserCreerUtilisateurTemplate,
            data: {
                title: 'Créer un utilisateur',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                    {
                        title: 'Utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                ],
                previous: 'app.help.informationUtilisateur',
                next: 'app.help.modifierUtilisateur',
            },
        })
        .state('app.help.modifierUtilisateur', {
            url: '/gestion-utilisateurs/modifier',
            templateUrl: userManualGestionUtilisateurPrincipalUserModifierUtilisateurTemplate,
            data: {
                title: 'Modifier un utilisateur',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                    {
                        title: 'Utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                ],
                previous: 'app.help.creerUtilisateur',
                next: 'app.help.supprimerUtilisateur',
            },
        })
        .state('app.help.supprimerUtilisateur', {
            url: '/gestion-utilisateurs/supprimer',
            templateUrl: userManualGestionUtilisateurPrincipalUserSupprimerUtilisateurTemplate,
            data: {
                title: 'Supprimer un utilisateur',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                    {
                        title: 'Utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                ],
                previous: 'app.help.modifierUtilisateur',
                next: 'app.help.subContractorSee',
            },
        })
        /* Sous-traitant */
        .state('app.help.subContractorSee', {
            url: '/gestion-utilisateurs/voir',
            templateUrl: userManualGestionUtilisateurSubContractorUserSubContractorSeeTemplate,
            data: {
                title: 'Voir un sous-traitant',
                navigation: [
                    {
                        title: 'Gestion des utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                    {
                        title: 'Utilisateurs',
                        sref: 'app.help.gestionUtilisateur',
                    },
                ],
                previous: 'app.help.supprimerUtilisateur',
                next: 'app.help.gestionProjet',
            },
        });
});
