import * as radash from 'radash';

function controlStatisticService($filter) {
    const translate = $filter('translate');

    return {
        groupByControlPoint,
        makeDateFileName,
        makeNameStatistics,
        preparationDataForExcel,
        preparationIndicatorsForExcel,
        preparationSynthesisForExcel,
    };

    function groupByControlPoint(controls) {
        const results = controls.reduce((acc, item) => {
            const keys = [
                'name',
                'controlPoint',
                'idClient',
                'diff',
                'thematic',
                'object',
                'severity',
                'description',
                'errorCode',
                'possibleCause',
                'recipientOperatingMode',
                'targetSIOperatingMode',
            ];

            if (!acc[item.name]) {
                acc[item.name] = {
                    ...radash.pick(item, keys),
                    controls: [],
                };
            }

            acc[item.name].diff += item.diff;
            acc[item.name].controls.push(radash.omit(item, keys));

            return acc;
        }, {});

        return Object.values(results);
    }

    function mapKeyToUpperCase(obj) {
        if (!angular.isObject(obj)) {
            throw new Error("obj it isn't an object");
        }

        const objConvertedToLowerCase = {};
        for (const [key, value] of Object.entries(obj)) {
            objConvertedToLowerCase[key.toUpperCase()] = value;
        }

        return objConvertedToLowerCase;
    }

    function preparationDataForExcel(controlsSynthesis, names, keySelected) {
        return controlsSynthesis.map(({ ...rest }) => {
            return mapKeyToUpperCase({
                ...prepareDataTwoStatistics(rest, names, keySelected),
            });
        });
    }

    // Récupère les valeurs pour chaque attribut
    function prepareDataTwoStatistics(statistics, names, keySelected) {
        let result = {};
        const attributesInTable = preparedListAttributes(keySelected);
        for (const attribute of attributesInTable) {
            result = {
                ...result,
                ...getValueOfTwoStatistics(statistics, names, attribute),
            };
        }

        return result;
    }

    // Défini les attributs du fichier Excel (colonnes du tableau)
    function preparedListAttributes(keySelected = '') {
        const listDefaultAttributes = ['controlPoint', 'severity', 'description'];
        const listAllAttributes = [
            'controlPoint',
            'severity',
            'description',
            'controlCount',
            'anomalyCount',
            'percentageSuccess',
            'indicatorControl',
            'indicatorError',
        ];

        return keySelected !== '' ? Array.from(new Set([...listDefaultAttributes, keySelected])) : listAllAttributes;
    }

    // Récupère les valeurs de chacune des statistiques pour chaque point de contrôle
    function getValueOfTwoStatistics(controlPoint, { nameOrigin, nameToCompare }, key) {
        const translation = getTranslation(key);

        if (['severity', 'controlPoint', 'description', 'indicatorControl', 'indicatorError'].includes(key)) {
            return {
                [translation]: translateValue(key, controlPoint),
            };
        } else {
            return {
                [`${translation}_${nameOrigin}`]: translateValue(key, controlPoint),
                [`${translation}_${nameToCompare}`]: translateValue(key, controlPoint.totalCompare),
            };
        }
    }

    // Renvoie la valeur correspondant à la clé transmise
    function translateValue(key, value) {
        switch (key) {
            /**
             * Partie comparaisons d'anomalies
             */
            case 'severity':
                return value.severity;
            case 'controlPoint':
                return value.controlPoint;
            case 'description':
                return value.description;
            case 'controlCount':
                return value.total.total;
            case 'anomalyCount':
                return value.total.errors;
            case 'indicatorControl': {
                const indicControl = -value.evolution.indicControl;

                return indicControl === null || !angular.isNumber(indicControl) ? '-' : `${indicControl.toFixed(2)}`;
            }
            case 'indicatorError': {
                const indicError = -value.evolution.indicError;

                return indicError === null || !angular.isNumber(indicError) ? '-' : `${indicError.toFixed(2)}`;
            }
            case 'percentageSuccess': {
                const percent = (value.total.success / value.total.total) * 100;
                if (percent === 0 || percent === 100) {
                    return `${percent.toFixed(0)}%`;
                }

                return isNaN(percent) || angular.isUndefined(value.total.success) ? '-' : `${percent.toFixed(2)}%`;
            }
            /**
             * Partie comparaison livrables
             */
            case 'deposit':
                return value.name;
            case 'project':
                return value.project.name;
            case 'depositDate':
                return makeNameStatistics(value.createdAt);
            case 'phase':
                return value.phase;
            case 'blocking':
                return value.diff2.blocking === 0 ? 0 : -value.diff2.blocking;
            case 'major':
                return value.diff2.blocking === 0 ? 0 : -value.diff2.major;
            case 'minor':
                return value.diff2.blocking === 0 ? 0 : -value.diff2.minor;
            default:
                return value;
        }
    }

    // Renvoie les balises de traduction coorespondant à la clé transmise
    function getTranslation(key) {
        switch (key) {
            /**
             * Partie comparaison d'anomalies
             */
            case 'description':
                return translate('shared.shortDescription');
            case 'controlCount':
                return translate('shared.nbControl');
            case 'controlPoint':
                return translate('shared.controlPoint', { COUNT: 1 });
            case 'anomalyCount':
                return translate('statisticsPage.nbAnomalies2lines');
            case 'indicatorControl':
                return translate('shared.deltaEvolutionControlNb');
            case 'indicatorError':
                return translate('shared.deltaEvolutionErrorNb');
            case 'percentageSuccess':
                return translate('statisticsPage.percentageSuccessful');
            /**
             * Partie synthèse
             */
            case 'statisticName':
                return translate('statisticsPage.statisticName');
            case 'nbDeposits':
                return translate('shared.deposit');
            case 'createdAt':
                return translate('shared.executionDate');
            case 'affaireCode':
                return translate('shared.businessCode', { COUNT: 1 });
            case 'phases':
                return translate('shared.step');
            case 'subCompanies':
                return translate('shared.subCompany');
            case 'depositBy':
                return translate('deposit.depositBy');
            case 'removeDeposit':
                return translate('statisticsPage.removeDeposit');
            case 'segments':
                return translate('shared.networkSegment');
            /**
             * Partie comparaison des livrables
             */
            case 'depositDate':
                return translate('deposit.depositDate');
            /**
             * Partie indicateurs
             */
            case 'A Creer':
                return translate('shared.toCreate');
            case 'En Service':
                return translate('shared.inUse');
            case 'Existant':
                return translate('shared.existing');
            case 'Travaux':
                return translate('shared.wip');
            case 'Autre':
                return translate('shared.other');
            case 'Inconnu':
                return translate('shared.unknown');
            case 'individual':
                return translate('shared.individuals');
            case 'communal':
                return translate('shared.collective');
            case 'code':
                return translate('shared.identifier');
            case 'codeExterne':
                return translate('shared.externalIdentifier');
            case 'codeImb':
                return translate('shared.buildingIdentifier');
            case 'position':
                return translate('shared.cablePosition');
            case 'codeCable':
                return translate('shared.idCable');
            case 'codeExtCable':
                return translate('shared.externalIdentifier');
            case 'localEntrance':
                return translate('shared.entranceRoom');
            case 'boxEntrance':
                return translate('shared.entranceBox');
            case 'localExit':
                return translate('shared.localOutlet');
            case 'boxExit':
                return translate('shared.boxOut');

            case 'real':
                return translate('reportCompare.realLength');
            case 'carto':
                return translate('reportCompare.lengthCarto');

            case 'sum_nbl_hab':
            case 'sum_nbpr_hab':
                return translate('shared.privateIndividual');
            case 'sum_nbl_pro':
            case 'sum_nbpr_pro':
                return translate('shared.professionals');
            case 'sum_nbl':
                return translate('shared.nbDwellingsConnected');
            case 'sum_nbpr':
                return translate('shared.nbPotentialOutlets');
            case 'deltaComparison':
                return translate('statisticsPage.deltaComparison');
            case 'deltaReport':
                return translate('deposit.deltaComparison');
            case 'nbLocals':
                return translate('statisticsPage.nbLocals');
            case 'nbLocalsConnected':
                return translate('statisticsPage.nbLocalsConnected');
            case 'nbLocalsRatio':
                return translate('statisticsPage.nbLocalsRatio');
            case 'nbSuf':
                return translate('statisticsPage.nbSuf');
            case 'nbSufConnected':
                return translate('statisticsPage.nbSufConnected');
            case 'nbSufRatio':
                return translate('statisticsPage.nbSufRatio');
            case 'nroTypePhy':
                return translate('statisticsPage.nroTypePhy');
            case 'mediaTypes':
                return translate('shared.mediaTypes');
            case 'sroTypePhy':
                return translate('statisticsPage.sroTypePhy');
            case 'type_ebp_pto':
                return translate('pto');
            default:
                return translate(`shared.${key}`);
        }
    }

    function makeNameStatistics(statisticsDate) {
        return moment(statisticsDate).format('DD/MM/YYYY HH:mm');
    }

    function makeDateFileName(statisticsDate) {
        return moment(statisticsDate).format('DD-MM-YYYY-HH-mm');
    }

    function getValueOfSynthesis(statistic, key) {
        if (!statistic[key] || statistic[key] === null) {
            return '-';
        } else {
            return statistic[key];
        }
    }

    function getValueOfSubContractor(deposit, key) {
        if (deposit.project[key].length === 0) {
            return translate('shared.no');
        } else {
            let retour = translate('shared.no');
            deposit.project[key].forEach((assignement) => {
                if (assignement.type === 'subcontractor' && assignement.user === deposit.createdBy._id) {
                    retour = translate('shared.yes');
                }
            });

            return retour;
        }
    }

    function preparationSynthesisForExcel(statisticOrigin, statisticToCompare) {
        const datas = [];
        [statisticOrigin, statisticToCompare].forEach((statistic) => {
            statistic.deposits.forEach((deposit) => {
                datas.push(
                    mapKeyToUpperCase({
                        [getTranslation('statisticName')]: getValueOfSynthesis(statistic, 'statisticName'),
                        [getTranslation('createdAt')]: makeNameStatistics(statistic.createdAt),
                        [getTranslation('createdBy')]: getValueOfSynthesis(statistic.createdBy, 'fullname'),
                        [getTranslation('startDate')]: makeNameStatistics(statistic.startDate),
                        [getTranslation('endDate')]: makeNameStatistics(statisticOrigin.endDate),
                        [getTranslation('deposit')]: getValueOfSynthesis(deposit, 'name'),
                        [getTranslation('depositDate')]: makeNameStatistics(deposit.createdAt),
                        [getTranslation('depositBy')]: !deposit.deposit
                            ? '-'
                            : getValueOfSynthesis(deposit.deposit.createdBy, 'fullname'),
                        [getTranslation('project')]: getValueOfSynthesis(deposit, 'projectName'),
                        [getTranslation('controlConfiguration')]: !deposit.deposit
                            ? '-'
                            : getValueOfSynthesis(deposit.deposit.controlConfiguration, 'name'),
                        [getTranslation('affaireCode')]: !deposit.deposit
                            ? '-'
                            : getValueOfSynthesis(deposit.deposit.deliveryZone, 'projectId'),
                        [getTranslation('deliveryZone')]: getValueOfSynthesis(deposit, 'deliveryZoneName'),
                        [getTranslation('subCompanies')]:
                            statistic.subCompanies.length === 0 || !deposit.deposit
                                ? '-'
                                : getValueOfSubContractor(deposit.deposit, 'assignments'),
                        [getTranslation('segments')]: deposit.networkSegments
                            .filter(({ isEnabled }) => isEnabled)
                            .map(({ segmentName }) => segmentName)
                            .join(', '),
                        [getTranslation('phases')]: getValueOfSynthesis(deposit, 'phase'),
                        [translate('shared.file', { COUNT: 1 })]: !deposit.deposit
                            ? '-'
                            : getValueOfSynthesis(deposit.deposit.zipFile, 'originalname'),
                        [getTranslation('removeDeposit')]: translate(!deposit.deposit ? 'shared.yes' : 'shared.no'),
                    }),
                );
            });
            datas.push({});
        });

        return datas;
    }

    function getStatusList(indicatorOrigin, indicatorToCompare, key) {
        const dataOrigin = [];
        const dataToCompare = [];

        if (!indicatorOrigin[key] && !indicatorToCompare[key]) {
            return [];
        }

        if (key !== 'lgCable') {
            if (indicatorOrigin[key]) {
                if (
                    !indicatorToCompare[key] ||
                    indicatorOrigin[key].status.length > indicatorToCompare[key].status.length
                ) {
                    if (indicatorOrigin[key] === 0) {
                        return [];
                    }

                    return indicatorOrigin[key].status;
                }

                const missingStatus = indicatorOrigin[key].status.filter(
                    (x) => !indicatorToCompare[key].status.includes(x),
                );

                dataToCompare.push(...indicatorToCompare[key].status, ...missingStatus);

                return dataToCompare;
            }

            if (indicatorToCompare[key]) {
                if (
                    !indicatorOrigin[key] ||
                    indicatorToCompare[key].status.length > indicatorOrigin[key].status.length
                ) {
                    if (indicatorToCompare[key] === 0) {
                        return [];
                    }

                    return indicatorToCompare[key].status;
                }

                const missingStatus = indicatorToCompare[key].status.filter(
                    (x) => !indicatorOrigin[key].status.includes(x),
                );

                dataOrigin.push(...indicatorOrigin[key].status, ...missingStatus);

                return dataOrigin;
            }
        } else {
            const [typesOrigin, typesToCompare] = [indicatorOrigin, indicatorToCompare].map((indicator) => {
                if (!angular.isArray(indicator[key])) {
                    return [];
                }

                return indicator[key].reduce((previous, cable) => {
                    if (!previous.includes(cable.type)) {
                        previous.push(cable.type);
                    }

                    return previous;
                }, []);
            });

            if (typesOrigin.length > typesToCompare.length) {
                return typesOrigin;
            }

            return typesToCompare;
        }
    }

    function preparationIndicatorsForExcel(indicators, isGraceV3 = false) {
        return {
            controlPoints: getTabIndicator(indicators, 'controlPoints', null),
            nbControls: getTabIndicator(indicators, 'nbControls', null),
            nbRecordsPerTable: getTabIndicator(indicators, 'tables', null),
            avgpbo: getTabIndicator(indicators, null, ['avgpbo']),
            connectivity: getTabIndicator(
                indicators,
                'connectivity',
                isGraceV3
                    ? ['nbLocals', 'nbLocalsConnected', 'nbLocalsRatio']
                    : ['nbSuf', 'nbSufConnected', 'nbSufRatio'],
            ),
            nbHousing: getTabIndicator(indicators, 'newPlug', ['sum_nbl_hab', 'sum_nbl_pro', 'sum_nbl']),
            nbPlugs: getTabIndicator(indicators, 'newPlug', ['sum_nbpr_hab', 'sum_nbpr_pro', 'sum_nbpr']),
            housingType: getTabIndicator(indicators, 'housing', ['individual', 'communal']),
            nbCases: getTabIndicator(indicators, null, ['pbo', 'bpe', 'type_ebp_pto']),
            nroSro: getTabIndicator(indicators, null, ['nroTypePhy', 'sroTypePhy']),
            nbBti: getTabIndicator(indicators, 'nbBti', null),
            cableBti: getTabIndicator(indicators, 'cableBti', null),
            technicalPoint: getTabIndicator(indicators, 'nature_type_phy', 'table'),
            pathways: getTabIndicator(indicators, 'pathway', 'table'),
            lgCablesDiff: getTabIndicator(indicators, 'opticalCableSupport', 'table'),
            diffCables: getTabIndicator(indicators, 'lgCable', null),
            distanceBetweenNroPbo: getTabIndicator(indicators, 'distanceBetweenNroPbo', null),
            distanceBetweenNroSro: getTabIndicator(indicators, 'distanceBetweenNroSro', null),
            distanceBetweenSroPbo: getTabIndicator(indicators, 'distanceBetweenSroPbo', null),
            junctionBox: getTabIndicator(indicators, 'junctionBox', null),
        };
    }

    function getTabIndicator(indicators, key, subKeys) {
        const indicatorOrigin = indicators[0] || {},
            indicatorToCompare = indicators[1] || {};
        switch (key) {
            case 'cableBti': {
                return getCableBti(indicators, key);
            }

            case 'nbBti': {
                return getNbBti(indicators, key);
            }

            case 'junctionBox': {
                return getJunctionBox(indicators, key);
            }

            case 'newPlug':
            case 'housing':
            case 'connectivity': {
                const tabThree = [];
                indicators.forEach((indicator) => {
                    const obj = {};
                    if (indicator.statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = indicator.statisticName;
                    } else if (indicator.reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = indicator.reportName;
                    }

                    subKeys.forEach((subKey) => {
                        if (indicator[key] === 0 || angular.isUndefined(indicator[key])) {
                            obj[getTranslation(subKey)] = '-';
                        } else {
                            let value = indicator[key][subKey] || 0;

                            if (value % 1 !== 0 && value.toString().split('.')[1].length > 2) {
                                value = parseFloat(value.toFixed(2));
                            }

                            obj[getTranslation(subKey)] = value;
                        }
                    });
                    tabThree.push(mapKeyToUpperCase(obj));
                });
                if (indicators.length === 2) {
                    tabThree.push({});
                    // Add difference line
                    const obj = {};
                    if (indicators[0].statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    }

                    if (indicators[0].reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = getTranslation('deltaReport');
                    }

                    subKeys.forEach((subKey) => {
                        if (angular.isUndefined(indicatorOrigin[key]) && angular.isUndefined(indicatorToCompare[key])) {
                            obj[getTranslation(subKey)] = '-';
                        }

                        if (angular.isUndefined(indicatorOrigin[key])) {
                            indicatorOrigin[key] = {
                                nbSuf: 0,
                                nbSufConnected: 0,
                                nbSufRatio: 0,
                            };
                        }

                        if (angular.isUndefined(indicatorToCompare[key])) {
                            indicatorToCompare[key] = {
                                nbSuf: 0,
                                nbSufConnected: 0,
                                nbSufRatio: 0,
                            };
                        }

                        let value = (indicatorToCompare[key][subKey] || 0) - (indicatorOrigin[key][subKey] || 0);
                        if (value % 1 !== 0 && value.toString().split('.')[1].length > 2) {
                            value = parseFloat(value.toFixed(2));
                        }

                        obj[getTranslation(subKey)] = value;
                    });
                    tabThree.push(mapKeyToUpperCase(obj));
                }

                return tabThree;
            }

            case null: {
                const tab = [];
                indicators.forEach((indicator) => {
                    const obj = {};
                    if (indicator.statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = indicator.statisticName;
                    } else if (indicator.reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = indicator.reportName;
                    }

                    subKeys.forEach((subKey) => {
                        if (angular.isUndefined(indicator[subKey])) {
                            obj[getTranslation(subKey)] = '-';
                        } else if (subKey === 'nroTypePhy' || subKey === 'sroTypePhy') {
                            obj[getTranslation(subKey)] =
                                indicator[subKey].length > 0 ? indicator[subKey].reduce((a, b) => a + b.count, 0) : '-';
                        } else {
                            let value = indicator[subKey];
                            if (value % 1 !== 0 && value.toString().split('.')[1].length > 2) {
                                value = parseFloat(value.toFixed(2));
                            }

                            obj[subKey] = value;
                        }
                    });
                    tab.push(mapKeyToUpperCase(obj));
                });

                if (indicators.length === 2) {
                    tab.push({});
                    // Add diiference line
                    const obj = {};
                    if (indicators[0].statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    } else if (indicators[0].reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = getTranslation('deltaReport');
                    }

                    subKeys.forEach((subKey) => {
                        if (
                            angular.isUndefined(indicatorOrigin[subKey]) ||
                            angular.isUndefined(indicatorToCompare[subKey])
                        ) {
                            obj[getTranslation(subKey)] = '-';
                        } else if (subKey === 'nroTypePhy' || subKey === 'sroTypePhy') {
                            const totalOrigin = angular.isArray(indicatorOrigin[subKey])
                                ? indicatorOrigin[subKey].reduce((a, b) => a + b.count || 0, 0)
                                : 0;
                            const totalCompare = angular.isArray(indicatorToCompare[subKey])
                                ? indicatorToCompare[subKey].reduce((a, b) => a + b.count || 0, 0)
                                : 0;
                            obj[getTranslation(subKey)] = totalCompare - totalOrigin;
                        } else {
                            let value = indicatorToCompare[subKey] - indicatorOrigin[subKey];
                            if (value % 1 !== 0 && value.toString().split('.')[1].length > 2) {
                                value = parseFloat(value.toFixed(2));
                            }

                            obj[subKey] = value;
                        }
                    });
                    tab.push(mapKeyToUpperCase(obj));
                }

                return tab;
            }

            case 'nature_type_phy':
            case 'lgCable': {
                const tab = [];
                const statusList = getStatusList(indicatorOrigin, indicatorToCompare, key);
                indicators.forEach((indicator) => {
                    const obj = {};
                    if (indicator.statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = indicator.statisticName;
                    } else if (indicator.reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = indicator.reportName;
                    }

                    if (key === 'nature_type_phy') {
                        if (angular.isDefined(indicator[key])) {
                            statusList.forEach((statut) => {
                                obj[getTranslation(statut)] = indicator[key][subKeys]
                                    ? parseFloat(
                                          indicator[key][subKeys]
                                              .filter((value) => value.avancement === statut)
                                              .reduce((a, b) => a + b.count, 0)
                                              .toFixed(2),
                                      )
                                    : '-';
                            });
                        }

                        if (!statusList.length) {
                            obj[getTranslation('mediaTypes')] = '-';
                        }
                    } else if (key === 'lgCable') {
                        if (angular.isArray(indicator[key])) {
                            statusList.forEach((statut) => {
                                obj[statut] =
                                    indicator[key].filter((value) => value.type === statut).length > 0
                                        ? parseFloat(
                                              indicator[key]
                                                  .filter((value) => value.type === statut)
                                                  .reduce((a, b) => a + b.count, 0)
                                                  .toFixed(2),
                                          )
                                        : '-';
                            });
                        }

                        obj[`${translate('shared.cable')} ${getTranslation('totaux')}`] = angular.isArray(
                            indicator[key],
                        )
                            ? indicator[key].reduce((a, b) => a + b.nbCables, 0)
                            : 0;
                    }

                    tab.push(mapKeyToUpperCase(obj));
                });

                if (indicators.length === 2) {
                    // add difference line
                    tab.push({});
                    const obj = {};
                    if (indicators[0].statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    } else if (indicators[0].reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = getTranslation('deltaReport');
                    }

                    if (key === 'nature_type_phy') {
                        if (angular.isDefined(indicatorOrigin[key]) && angular.isDefined(indicatorToCompare[key])) {
                            statusList.forEach((statut) => {
                                obj[getTranslation(statut)] =
                                    angular.isArray(indicatorOrigin[key][subKeys]) ||
                                    angular.isArray(indicatorToCompare[key][subKeys])
                                        ? parseInt(
                                              (indicatorToCompare[key][subKeys] || [])
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.count, 0)
                                                  .toFixed(0),
                                          ) -
                                              parseInt(
                                                  (indicatorOrigin[key][subKeys] || [])
                                                      .filter((value) => value.avancement === statut)
                                                      .reduce((a, b) => a + b.count, 0)
                                                      .toFixed(0),
                                              ) || 0
                                        : '-';
                            });
                        }

                        if (!statusList.length) {
                            obj[getTranslation('mediaTypes')] = '-';
                        }
                    } else if (key === 'lgCable') {
                        statusList.forEach((statut) => {
                            obj[statut] =
                                parseFloat(
                                    angular.isArray(indicatorToCompare[key])
                                        ? indicatorToCompare[key]
                                              .filter((value) => value.type === statut)
                                              .reduce((a, b) => a + b.count, 0)
                                              .toFixed(3)
                                        : 0,
                                ) -
                                parseFloat(
                                    angular.isArray(indicatorOrigin[key])
                                        ? indicatorOrigin[key]
                                              .filter((value) => value.type === statut)
                                              .reduce((a, b) => a + b.count, 0)
                                              .toFixed(3)
                                        : 0,
                                );
                        });

                        const indicatorToCompareCableNumber = angular.isArray(indicatorToCompare[key])
                            ? indicatorToCompare[key].reduce((a, b) => a + b.nbCables, 0)
                            : 0;
                        const indicatorOriginCableNumber = angular.isArray(indicatorOrigin[key])
                            ? indicatorOrigin[key].reduce((a, b) => a + b.nbCables, 0)
                            : 0;
                        obj[`${translate('shared.cable')} ${getTranslation('totaux')}`] =
                            indicatorToCompareCableNumber - indicatorOriginCableNumber;
                    }

                    tab.push(mapKeyToUpperCase(obj));
                }

                return tab;
            }

            case 'pathway':
            case 'opticalCableSupport': {
                const tab = [];
                const statusList = getStatusList(indicatorOrigin, indicatorToCompare, key);

                indicators.forEach((indicator) => {
                    const obj = {};
                    if (indicator.statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = indicator.statisticName;
                    } else if (indicator.reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = indicator.reportName;
                    }

                    if (key === 'pathway') {
                        if (angular.isDefined(indicator[key])) {
                            statusList.forEach((statut) => {
                                obj[`${getTranslation(statut)} ${getTranslation('real')} Km`] =
                                    indicator[key][subKeys] &&
                                    indicator[key][subKeys].filter((value) => value.avancement === statut).length > 0
                                        ? parseFloat(
                                              indicator[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countReal, 0)
                                                  .toFixed(3),
                                          )
                                        : '-';

                                obj[`${getTranslation(statut)} ${getTranslation('carto')} Km`] =
                                    indicator[key][subKeys] &&
                                    indicator[key][subKeys].filter((value) => value.avancement === statut).length > 0
                                        ? parseFloat(
                                              indicator[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countGeom, 0)
                                                  .toFixed(3),
                                          )
                                        : '-';
                            });

                            obj[`${getTranslation('pathways')} ${getTranslation('totaux')}`] = indicator[key][subKeys]
                                ? indicator[key][subKeys].reduce((a, b) => a + b.nbChems, 0)
                                : '-';
                        }
                    } else if (angular.isDefined(indicator[key])) {
                        statusList.forEach((statut) => {
                            obj[getTranslation(statut)] =
                                indicator[key][subKeys] &&
                                indicator[key][subKeys].filter((value) => value.avancement === statut).length > 0
                                    ? parseFloat(
                                          indicator[key][subKeys]
                                              .filter((value) => value.avancement === statut)
                                              .reduce((a, b) => a + b.data_saisie, 0)
                                              .toFixed(2),
                                      )
                                    : '-';
                        });
                        if (!statusList.length) {
                            obj['Longueur totale'] = '-';
                        }
                    }

                    tab.push(mapKeyToUpperCase(obj));
                });

                if (indicators.length === 2) {
                    // add difference line
                    tab.push({});
                    const obj = {};
                    if (indicators[0].statisticName) {
                        // Si comparaison de statistique de contrôle
                        obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    } else if (indicators[0].reportName) {
                        // Si comparaison de rapport
                        obj[getTranslation('report')] = getTranslation('deltaReport');
                    }

                    if (key === 'pathway') {
                        if (angular.isDefined(indicatorOrigin[key]) && angular.isDefined(indicatorToCompare[key])) {
                            statusList.forEach((statut) => {
                                obj[`${getTranslation(statut)} ${getTranslation('real')} Km`] =
                                    indicatorToCompare[key][subKeys] && indicatorOrigin[key][subKeys]
                                        ? parseFloat(
                                              indicatorToCompare[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countReal, 0)
                                                  .toFixed(3),
                                          ) -
                                          parseFloat(
                                              indicatorOrigin[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countReal, 0)
                                                  .toFixed(3),
                                          )
                                        : '-';

                                obj[`${getTranslation(statut)} ${getTranslation('carto')} Km`] =
                                    indicatorToCompare[key][subKeys] && indicatorOrigin[key][subKeys]
                                        ? parseFloat(
                                              indicatorToCompare[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countGeom, 0)
                                                  .toFixed(3),
                                          ) -
                                          parseFloat(
                                              indicatorOrigin[key][subKeys]
                                                  .filter((value) => value.avancement === statut)
                                                  .reduce((a, b) => a + b.countGeom, 0)
                                                  .toFixed(3),
                                          )
                                        : '-';
                            });

                            obj[`${getTranslation('pathways')} ${getTranslation('totaux')}`] =
                                (indicatorToCompare[key][subKeys] || []).reduce((a, b) => a + b.nbChems, 0) -
                                (indicatorOrigin[key][subKeys] || []).reduce((a, b) => a + b.nbChems, 0);
                        }
                    } else if (angular.isDefined(indicatorOrigin[key]) && angular.isDefined(indicatorToCompare[key])) {
                        statusList.forEach((statut) => {
                            obj[getTranslation(statut)] =
                                indicatorToCompare[key][subKeys] && indicatorOrigin[key][subKeys]
                                    ? parseFloat(
                                          indicatorToCompare[key][subKeys]
                                              .filter((value) => value.avancement === statut)
                                              .reduce((a, b) => a + b.data_saisie, 0)
                                              .toFixed(3),
                                      ) -
                                      parseFloat(
                                          indicatorOrigin[key][subKeys]
                                              .filter((value) => value.avancement === statut)
                                              .reduce((a, b) => a + b.data_saisie, 0)
                                              .toFixed(3),
                                      )
                                    : '-';
                        });
                    }

                    tab.push(mapKeyToUpperCase(obj));
                }

                return tab;
            }

            case 'controlPoints': {
                const tabControlPoints = [];
                const tab = getControlPoints(indicators, key);

                tab.forEach((item) => tabControlPoints.push(item));
                // add difference line
                if (indicators.length === 2 && indicators[0].statisticName) {
                    tabControlPoints.push({});
                    const obj = {};
                    const nbControlsOrigin =
                        (indicatorOrigin[key]?.successControls ?? 0) +
                        (indicatorOrigin[key]?.anomalyControl ?? 0) +
                        (indicatorOrigin[key]?.errorControls ?? 0) +
                        (indicatorOrigin[key]?.warningControls ?? 0);
                    const nbControlsToCompare =
                        (indicatorToCompare[key].successControls ?? 0) +
                        (indicatorToCompare[key].anomalyControl ?? 0) +
                        (indicatorToCompare[key].errorControls ?? 0) +
                        (indicatorToCompare[key].warningControls ?? 0);

                    obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    obj[translate('shared.noAnomalyControl')] =
                        indicatorToCompare[key].successControls - indicatorOrigin[key].successControls;
                    obj[translate('shared.anomalyControlPoint')] =
                        indicatorToCompare[key].anomalyControl - indicatorOrigin[key].anomalyControl;
                    obj[translate('shared.inoperativeControl')] =
                        indicatorToCompare[key].errorControls - indicatorOrigin[key].errorControls;

                    obj[translate('shared.notMetControlPoint')] =
                        indicatorToCompare[key].warningControls - indicatorOrigin[key].warningControls;

                    obj[translate('shared.controlPoint')] = nbControlsToCompare - nbControlsOrigin;
                    obj[' '] = ' ';
                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        indicatorToCompare[key].blockingSuccessControls - indicatorOrigin[key].blockingSuccessControls;
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                        indicatorToCompare[key].blockingAnomalyControls - indicatorOrigin[key].blockingAnomalyControls;
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        indicatorToCompare[key].blockingErrorControls - indicatorOrigin[key].blockingErrorControls;
                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        indicatorToCompare[key].blockingWarningControls - indicatorOrigin[key].blockingWarningControls;
                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                        indicatorToCompare[key].majorSuccessControls - indicatorOrigin[key].majorSuccessControls;
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                        indicatorToCompare[key].majorAnomalyControls - indicatorOrigin[key].majorAnomalyControls;
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                        indicatorToCompare[key].majorErrorControls - indicatorOrigin[key].majorErrorControls;
                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`] =
                        indicatorToCompare[key].majorWarningControls - indicatorOrigin[key].majorWarningControls;
                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        indicatorToCompare[key].minorSuccessControls - indicatorOrigin[key].minorSuccessControls;
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'minor' })] =
                        indicatorToCompare[key].minorAnomalyControls - indicatorOrigin[key].minorAnomalyControls;
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        indicatorToCompare[key].minorErrorControls - indicatorOrigin[key].minorErrorControls;
                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        indicatorToCompare[key].minorWarningControls - indicatorOrigin[key].minorWarningControls;
                    tabControlPoints.push(mapKeyToUpperCase(obj));
                } else if (indicators.length === 2 && indicators[0].reportName) {
                    tabControlPoints.push({});
                    const obj = {};
                    obj[getTranslation('report')] = getTranslation('deltaReport');
                    obj[translate('shared.noAnomalyControl')] =
                        tabControlPoints[1][translate('shared.noAnomalyControl').toUpperCase()] -
                        tabControlPoints[0][translate('shared.noAnomalyControl').toUpperCase()];
                    obj[translate('shared.anomalyControlPoint')] =
                        tabControlPoints[1][translate('shared.anomalyControlPoint').toUpperCase()] -
                        tabControlPoints[0][translate('shared.anomalyControlPoint').toUpperCase()];
                    obj[translate('shared.inoperativeControl')] =
                        tabControlPoints[1][translate('shared.inoperativeControl').toUpperCase()] -
                        tabControlPoints[0][translate('shared.inoperativeControl').toUpperCase()];

                    obj[translate('shared.notMetControlPoint')] =
                        tabControlPoints[1][translate('shared.notMetControlPoint').toUpperCase()] -
                        tabControlPoints[0][translate('shared.notMetControlPoint').toUpperCase()];

                    obj[translate('shared.controlPoint')] =
                        tabControlPoints[1][translate('shared.controlPoint').toUpperCase()] -
                        tabControlPoints[0][translate('shared.controlPoint').toUpperCase()];
                    obj[' '] = ' ';
                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                        tabControlPoints[1][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' }).toUpperCase()
                        ] -
                        tabControlPoints[0][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' }).toUpperCase()
                        ];
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ];

                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.blocking', {
                                COUNT: 1,
                            })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.blocking', {
                                COUNT: 1,
                            })}`.toUpperCase()
                        ];

                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                        tabControlPoints[1][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' }).toUpperCase()
                        ] -
                        tabControlPoints[0][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' }).toUpperCase()
                        ];
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ];

                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ];

                    obj[`${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[translate('shared.anomalyControlPoint', { SEVERITY: 'minor' })] =
                        tabControlPoints[1][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'minor' }).toUpperCase()
                        ] -
                        tabControlPoints[0][
                            translate('shared.anomalyControlPoint', { SEVERITY: 'minor' }).toUpperCase()
                        ];
                    obj[`${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ];

                    obj[`${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        tabControlPoints[1][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabControlPoints[0][
                            `${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ];
                    tabControlPoints.push(mapKeyToUpperCase(obj));
                }

                return tabControlPoints;
            }

            case 'nbControls': {
                const tabNbControls = [];
                const tab = getNbControles(indicators, key);
                tab.forEach((item) => tabNbControls.push(item));

                if (indicators.length === 2 && angular.isArray(indicators[0][key])) {
                    // if statistic of contrôle
                    // add difference line
                    tabNbControls.push({});
                    const obj = {};
                    const nbControlesOrigin = indicatorOrigin[key].reduce(
                        (a, b) => a + b.data.reduce((acc, val) => acc + val, 0),
                        0,
                    );
                    const controlsOrigin = getControlsFaultyAndSuccess(indicatorOrigin, key, nbControlesOrigin);
                    const controlSuccessPercentageOrigin = controlsOrigin.controlsSuccess;
                    const controlFaultyPercentageOrigin = controlsOrigin.controlsFaulty;

                    const nbControlesToCompare = indicatorToCompare[key].reduce(
                        (a, b) => a + b.data.reduce((acc, val) => acc + val, 0),
                        0,
                    );
                    const controlsToCompare = getControlsFaultyAndSuccess(
                        indicatorToCompare,
                        key,
                        nbControlesToCompare,
                    );
                    const controlSuccessPercentageToCompare = controlsToCompare.controlsSuccess;
                    const controlFaultyPercentageToCompare = controlsToCompare.controlsFaulty;

                    obj[getTranslation('statisticName')] = getTranslation('deltaComparison');
                    obj[translate('shared.nbSuccessfulChecks')] = `${
                        parseFloat((controlSuccessPercentageToCompare - controlSuccessPercentageOrigin).toFixed(2)) || 0
                    } %`;
                    obj[translate('shared.nbFaultyChecks')] = `${
                        parseFloat((controlFaultyPercentageToCompare - controlFaultyPercentageOrigin).toFixed(2)) || 0
                    }%`;
                    obj[`${'shared.nbControl'} ${getTranslation('totaux')}`] = nbControlesToCompare - nbControlesOrigin;
                    obj[' '] = ' ';
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        indicatorToCompare[key][0].data[0] - indicatorOrigin[key][0].data[0];
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                        indicatorToCompare[key][0].data[1] - indicatorOrigin[key][0].data[1];
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        indicatorToCompare[key][0].data[2] - indicatorOrigin[key][0].data[2];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        indicatorToCompare[key][1].data[0] - indicatorOrigin[key][1].data[0];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                        indicatorToCompare[key][1].data[1] - indicatorOrigin[key][1].data[1];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        indicatorToCompare[key][1].data[2] - indicatorOrigin[key][1].data[2];
                    tabNbControls.push(mapKeyToUpperCase(obj));
                } else if (indicators.length === 2 && indicators[0].reportName) {
                    tabNbControls.push({});
                    // TODO : remplacer cette partie et mettre les bonnes valeurs
                    const obj = {};
                    obj[getTranslation('report')] = getTranslation('deltaReport');
                    obj[translate('shared.nbSuccessfulChecks')] = `${(
                        tabNbControls[1][translate('shared.nbSuccessfulChecks').toUpperCase()].split(' ')[0] -
                        tabNbControls[0][translate('shared.nbSuccessfulChecks').toUpperCase()].split(' ')[0]
                    ).toFixed(2)} %`;
                    obj[translate('shared.nbFaultyChecks')] = `${(
                        tabNbControls[1][translate('shared.nbFaultyChecks').toUpperCase()].split(' ')[0] -
                        tabNbControls[0][translate('shared.nbFaultyChecks').toUpperCase()].split(' ')[0]
                    ).toFixed(2)} %`;
                    obj[`${translate('shared.nbControl')} ${getTranslation('totaux')}`] =
                        tabNbControls[1][`${translate('shared.nbControl')} ${getTranslation('totaux')}`.toUpperCase()] -
                        tabNbControls[0][`${translate('shared.nbControl')} ${getTranslation('totaux')}`.toUpperCase()];
                    obj[' '] = ' ';
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`.toUpperCase()
                        ];
                    obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                        tabNbControls[1][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ] -
                        tabNbControls[0][
                            `${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`.toUpperCase()
                        ];
                    tabNbControls.push(mapKeyToUpperCase(obj));
                }

                return tabNbControls;
            }

            case 'tables': {
                const tab = [];

                if (indicators.length === 2) {
                    return [];
                }

                indicators.forEach((indicator) => {
                    const obj = {};
                    obj[getTranslation('table')] = indicator[key].length;
                    obj[`${getTranslation('activeTable')}`] = indicator[key].filter((table) => table.activated).length;
                    obj[`${getTranslation('emptyTable')}`] = indicator[key].filter(
                        (table) => !table.activated && !table.nb_lignes_par_table,
                    ).length;
                    obj[getTranslation('nbRecordPerTable')] = indicator[key].reduce(
                        (a, b) => a + b.nb_lignes_par_table,
                        0,
                    );
                    obj[getTranslation('abandonedObject')] = indicator[key].reduce(
                        (a, b) => a + b.abandoned_objects,
                        0,
                    );
                    tab.push(mapKeyToUpperCase(obj));
                    tab.push({});
                    indicator[key].forEach((table) => {
                        obj[getTranslation('table')] = table.nom_table;
                        obj[`${getTranslation('activeTable')}`] = translate(
                            table.activated ? 'shared.yes' : 'shared.no',
                        ).toUpperCase();
                        obj[`${getTranslation('emptyTable')}`] = translate(
                            !table.activated ? 'shared.yes' : 'shared.no',
                        ).toUpperCase();
                        obj[getTranslation('nbRecordPerTable')] = table.nb_lignes_par_table;
                        obj[getTranslation('abandonedObject')] = table.abandoned_objects || 0;
                        tab.push(mapKeyToUpperCase(obj));
                    });
                });

                return tab;
            }

            case 'distanceBetweenNroPbo':
            case 'distanceBetweenNroSro':
            case 'distanceBetweenSroPbo': {
                return getDistanceRange(indicators, key);
            }
        }
    }

    function getJunctionBox(indicators, key) {
        const tab = [],
            obj = {};

        indicators.forEach((indicator) => {
            if (indicator.statisticName) {
                // Si comparaison de statistique de contrôle
                obj[getTranslation('statisticName')] = indicator.statisticName;
            } else if (indicator.reportName) {
                // Si comparaison de rapport
                obj[getTranslation('report')] = indicator.reportName;
            }

            if (!indicator[key] || !indicator[key].length) {
                obj[getTranslation('nbOccurrence')] = 0;
                obj[getTranslation('identifier')] = '-';
                obj[getTranslation('externalIdentifier')] = '-';

                tab.push(mapKeyToUpperCase(obj));
            }

            if (indicator[key] && indicator[key].length) {
                indicator[key].forEach((item) => {
                    obj[getTranslation('nbOccurrence')] = item.nbBpDerivation || 0;
                    obj[getTranslation('identifier')] = item.bpCode || '-';
                    obj[getTranslation('externalIdentifier')] = item.bpCodeExt || '-';

                    tab.push(mapKeyToUpperCase(obj));
                });
            }

            tab.push({});
        });

        return tab;
    }

    function getNbBti(indicators, key) {
        const tab = [],
            obj = {};

        indicators.forEach((indicator) => {
            if (indicator.statisticName) {
                // Si comparaison de statistique de contrôle
                obj[getTranslation('statisticName')] = indicator.statisticName;
            } else if (indicator.reportName) {
                // Si comparaison de rapport
                obj[getTranslation('report')] = indicator.reportName;
            }

            if (!indicator[key] || !indicator[key].length) {
                obj[getTranslation('code')] = '-';
                obj[getTranslation('codeExterne')] = '-';
                obj[getTranslation('codeImb')] = '-';
                tab.push(mapKeyToUpperCase(obj));
            }

            if (indicator[key] && indicator[key].length) {
                indicator[key].forEach((item) => {
                    obj[getTranslation('code')] = item.code || '-';
                    obj[getTranslation('codeExterne')] = item.codeExterne || '-';
                    obj[getTranslation('codeImb')] = item.codeImb || '-';

                    tab.push(mapKeyToUpperCase(obj));
                });
            }

            tab.push({});
        });

        return tab;
    }

    function getCableBti(indicators, key) {
        const tab = [],
            obj = {};

        indicators.forEach((indicator) => {
            if (indicator.statisticName) {
                // if statistic
                obj[getTranslation('statisticName')] = indicator.statisticName;
            } else if (indicator.reportName) {
                // if report
                obj[getTranslation('report')] = indicator.reportName;
            }

            if (!indicator[key] || !indicator[key].length) {
                obj[getTranslation('position')] = '-';
                obj[getTranslation('codeCable')] = '-';
                obj[getTranslation('codeExtCable')] = '-';
                obj[getTranslation('localEntrance')] = '-';
                obj[getTranslation('boxEntrance')] = '-';
                obj[getTranslation('localExit')] = '-';
                obj[getTranslation('boxExit')] = '-';

                tab.push(mapKeyToUpperCase(obj));
            }

            if (indicator[key] && indicator[key].length) {
                indicator[key]
                    .sort((a, b) => a.position.localeCompare(b.position) || a.codeCable.localeCompare(b.codeCable))
                    .forEach((item) => {
                        obj[getTranslation('position')] = item.position || '-';
                        obj[getTranslation('codeCable')] = item.codeCable || '-';
                        obj[getTranslation('codeExtCable')] = item.codeExtCable || '-';
                        obj[getTranslation('localEntrance')] = item.localEntrance || '-';
                        obj[getTranslation('boxEntrance')] = item.boxEntrance || '-';
                        obj[getTranslation('localExit')] = item.localExit || '-';
                        obj[getTranslation('boxExit')] = item.boxExit || '-';

                        tab.push(mapKeyToUpperCase(obj));
                    });
            }

            tab.push({});
        });

        return tab;
    }

    function getDistanceRange(indicators, key) {
        const tab = [],
            obj = {};

        indicators.forEach((indicator) => {
            const distanceRangeData = {
                zeroToThreeHundred: 0,
                threeHundredToFiveHundred: 0,
                fiveHundredToThousand: 0,
                thousandToThreeThousand: 0,
                overThreeThousand: 0,
            };

            if (indicator.statisticName) {
                // Si comparaison de rapports de contrôles
                obj[getTranslation('statisticName')] = indicator.statisticName;
            } else if (indicator.reportName) {
                // Si comparaison de rapports de contrôles
                obj[getTranslation('report')] = indicator.reportName;
            }

            if (!indicator[key] || !indicator[key].length) {
                obj[`${translate('shared.distance', { COUNT: 0 })} ${translate('shared.totale')}`] = 0;
                obj[`${translate('shared.zeroToThreeHundred')}`] = 0;
                obj[`${translate('shared.threeHundredToFiveHundred')}`] = 0;
                obj[`${translate('shared.fiveHundredToThousand')}`] = 0;
                obj[`${translate('shared.thousandToThreeThousand')}`] = 0;
                obj[`${translate('shared.overThreeThousand')}`] = 0;
            }

            if (indicator[key] && indicator[key].length) {
                indicator[key].forEach((range) => {
                    if (range.distanceKm > 0 && range.distanceKm <= 0.3) {
                        distanceRangeData.zeroToThreeHundred += 1;
                    } else if (range.distanceKm > 0.3 && range.distanceKm <= 0.5) {
                        distanceRangeData.threeHundredToFiveHundred += 1;
                    } else if (range.distanceKm > 0.5 && range.distanceKm <= 1) {
                        distanceRangeData.fiveHundredToThousand += 1;
                    } else if (range.distanceKm > 1 && range.distanceKm <= 3) {
                        distanceRangeData.thousandToThreeThousand += 1;
                    } else if (range.distanceKm > 3) {
                        distanceRangeData.overThreeThousand += 1;
                    }
                });

                obj[`${translate('shared.distance', { COUNT: 0 })} ${translate('shared.totale')}`] =
                    indicator[key].length;

                obj[`${translate('shared.zeroToThreeHundred')}`] = `${(
                    (distanceRangeData.zeroToThreeHundred / indicator[key].length) *
                    100
                ).toFixed(2)} %`;
                obj[`${translate('shared.threeHundredToFiveHundred')}`] = `${(
                    (distanceRangeData.threeHundredToFiveHundred / indicator[key].length) *
                    100
                ).toFixed(2)} %`;
                obj[`${translate('shared.fiveHundredToThousand')}`] = `${(
                    (distanceRangeData.fiveHundredToThousand / indicator[key].length) *
                    100
                ).toFixed(2)} %`;
                obj[`${translate('shared.thousandToThreeThousand')}`] = `${(
                    (distanceRangeData.thousandToThreeThousand / indicator[key].length) *
                    100
                ).toFixed(2)} %`;
                obj[`${translate('shared.overThreeThousand')}`] = `${(
                    (distanceRangeData.overThreeThousand / indicator[key].length) *
                    100
                ).toFixed(2)} %`;
            }

            tab.push(mapKeyToUpperCase(obj));
        });

        return tab;
    }

    function getControlPoints(indicators, key) {
        const tab = [],
            obj = {};
        indicators.forEach((indicator) => {
            if (indicators.length === 2 && indicator.statisticName) {
                // Si comparaison de statistique de contrôle
                obj[getTranslation('statisticName')] = indicator.statisticName;
                obj[translate('shared.noAnomalyControl')] = angular.isDefined(indicator[key].successControls)
                    ? indicator[key].successControls
                    : indicator[key].success;
                obj[translate('shared.anomalyControlPoint')] = angular.isDefined(indicator[key].anomalyControl)
                    ? indicator[key].anomalyControl
                    : indicator[key].error;
                obj[translate('shared.inoperativeControl')] = indicator[key].errorControls;

                obj[translate('shared.notMetControlPoint')] = indicator[key].warningControls;

                obj[translate('shared.controlPoint')] =
                    (indicator[key].successControls ?? 0) +
                    (indicator[key].anomalyControl ?? 0) +
                    (indicator[key].errorControls ?? 0) +
                    (indicator[key].warningControls ?? 0);
                obj[' '] = ' ';
                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].blockingSuccessControls;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                    indicator[key].blockingAnomalyControls;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].blockingErrorControls;
                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].blockingWarningControls;
                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].majorSuccessControls;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] =
                    indicator[key].majorAnomalyControls;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].majorErrorControls;
                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].majorWarningControls;
                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].minorSuccessControls;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'minor' })] =
                    indicator[key].minorAnomalyControls;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].minorErrorControls;
                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].minorWarningControls;
            } else {
                if (indicator.reportName) {
                    // Si comparaison de rapports de contrôles
                    obj[getTranslation('report')] = indicator.reportName;
                }

                obj[translate('shared.noAnomalyControl')] = indicator[key].filter(
                    (control) => control.invalid_count === 0 && control.error === '',
                ).length;
                obj[translate('shared.anomalyControlPoint')] = indicator[key].filter(
                    (control) => control.invalid_count > 0,
                ).length;
                obj[translate('shared.inoperativeControl')] = indicator[key].filter(
                    (control) => control.error !== '' && !control.warningControlPoint,
                ).length;

                obj[translate('shared.notMetControlPoint')] = indicator[key].filter(
                    (control) => control.error !== '' && control.warningControlPoint,
                ).length;

                obj[translate('shared.controlPoint')] = indicator[key].length;
                obj[' '] = ' ';
                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.invalid_count === 0 && control.error === '' && control.severity === 'blocking',
                    ).length;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] = indicator[key].filter(
                    (control) => control.invalid_count > 0 && control.severity === 'blocking',
                ).length;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'blocking' && !control.warningControlPoint,
                    ).length;

                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'blocking' && control.warningControlPoint,
                    ).length;

                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.major', { COUNT: 1 })}`] = indicator[
                    key
                ].filter(
                    (control) => control.invalid_count === 0 && control.error === '' && control.severity === 'major',
                ).length;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'blocking' })] = indicator[key].filter(
                    (control) => control.invalid_count > 0 && control.severity === 'major',
                ).length;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'major' && !control.warningControlPoint,
                    ).length;

                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'major' && control.warningControlPoint,
                    ).length;

                obj[`${translate('shared.noAnomalyControl')} ${translate('severity.minor', { COUNT: 1 })}`] = indicator[
                    key
                ].filter(
                    (control) => control.invalid_count === 0 && control.error === '' && control.severity === 'minor',
                ).length;
                obj[translate('shared.anomalyControlPoint', { SEVERITY: 'minor' })] = indicator[key].filter(
                    (control) => control.invalid_count > 0 && control.severity === 'minor',
                ).length;
                obj[`${translate('shared.inoperativeControl')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'minor' && !control.warningControlPoint,
                    ).length;

                obj[`${translate('shared.notMetControlPoint')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].filter(
                        (control) =>
                            control.error !== '' && control.severity === 'minor' && control.warningControlPoint,
                    ).length;
            }

            tab.push(mapKeyToUpperCase(obj));
        });

        return tab;
    }

    function getNbControles(indicators, key) {
        const tab = [],
            obj = {};

        indicators.forEach((indicator) => {
            if (indicators.length === 2 && angular.isArray(indicator[key])) {
                // Si comparaison de statistique de contrôle
                const nbControls = indicator[key].reduce((a, b) => {
                    return a + b.data.reduce((acc, val) => acc + val, 0);
                }, 0);

                const nbControlsByStatus = getControlsFaultyAndSuccess(indicator, key, nbControls);

                obj[getTranslation('statisticName')] = indicator.statisticName;
                obj[translate('shared.nbSuccessfulChecks')] =
                    `${parseFloat(nbControlsByStatus.controlsSuccess.toFixed(2)) || 0}%`;
                obj[translate('shared.nbFaultyChecks')] =
                    `${parseFloat(nbControlsByStatus.controlsFaulty.toFixed(2)) || 0}%`;
                obj[`${translate('shared.nbControl')} ${getTranslation('totaux')}`] = nbControls;
                obj[' '] = ' ';
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key][0].data[0];
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key][0].data[1];
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key][0].data[2];
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key][1].data[0];
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key][1].data[1];
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key][1].data[2];
            } else {
                const nbSuccessBlocking = indicator.controlPoints
                    .filter((control) => control.severity === 'blocking')
                    .reduce((a, b) => a + b.valid_count, 0);
                const nbSuccessMajor = indicator.controlPoints
                    .filter((control) => control.severity === 'major')
                    .reduce((a, b) => a + b.valid_count, 0);
                const nbSuccessMinor = indicator.controlPoints
                    .filter((control) => control.severity === 'minor')
                    .reduce((a, b) => a + b.valid_count, 0);

                if (indicators.length === 2) {
                    // Si comparaison de rapport
                    obj[getTranslation('report')] = indicator.reportName;
                }

                obj[translate('shared.nbSuccessfulChecks')] = `${
                    parseFloat(((indicator[key].success / indicator[key].total) * 100).toFixed(2)) || 0
                } %`;
                obj[translate('shared.nbFaultyChecks')] = `${
                    parseFloat(((indicator[key].error / indicator[key].total) * 100).toFixed(2)) || 0
                } %`;
                obj[`${translate('shared.nbControl')} ${getTranslation('totaux')}`] = indicator[key].total;
                obj[''] = '';
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    nbSuccessBlocking;
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                    nbSuccessMajor;
                obj[`${translate('shared.nbSuccessfulChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    nbSuccessMinor;
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.blocking', { COUNT: 1 })}`] =
                    indicator[key].errorBlocking;
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.major', { COUNT: 1 })}`] =
                    indicator[key].errorMajor;
                obj[`${translate('shared.nbFaultyChecks')} ${translate('severity.minor', { COUNT: 1 })}`] =
                    indicator[key].errorMinor;
            }

            tab.push(mapKeyToUpperCase(obj));
        });

        return tab;
    }

    function getControlsFaultyAndSuccess(indicator, key, nbControls) {
        const controlsSuccess =
            nbControls > 0 ? (indicator[key][0].data.reduce((acc, val) => acc + val, 0) / nbControls) * 100 : 0;
        const controlsFaulty =
            nbControls > 0 ? (indicator[key][1].data.reduce((acc, val) => acc + val, 0) / nbControls) * 100 : 0;

        return { controlsSuccess, controlsFaulty };
    }
}

angular.module('dotic').factory('$controlStatisticsService', controlStatisticService);
