import { omit as radashOmit } from 'radash';

import EnvironmentSettingsService from '../../../services/environment-settings.service';
import FileService from '../../../services/file.service';
import template from './gridFillForm.html';

class GridFillFormPage {
    constructor(
        $excelService,
        $fillGridService,
        $filter,
        $gridService,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        companyProvider,
        dataModelProvider,
        fillGridProvider,
    ) {
        this._$excelService = $excelService;
        this._$fillGridService = $fillGridService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$timeout = $timeout;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._dataModelProvider = dataModelProvider;
        this._fillGridProvider = fillGridProvider;
        this._$gridService = $gridService;

        this.isOrange = EnvironmentSettingsService.isOrange();

        this.text = null;
        this.textButton = null;
        this.gridFill = {
            company: [],
            dataModel: null,
            description: null,
            isClonable: !this.isOrange,
            isEditable: !this.isOrange,
            isTemplate: true,
        };
        this.gridFillList = [];
        this.phaseList = [];
        this.dataModels = [];
        this.companies = [];
        this.tableList = [];
        this.severityList = [
            { key: 'minor', value: this._humanizeSeverity('minor') },
            { key: 'major', value: this._humanizeSeverity('major') },
            { key: 'blocking', value: this._humanizeSeverity('blocking') },
        ];
        this.relationList = [];
        this.tableTypes = [
            {
                key: '^t_',
                value: this._translate('fillGrid.linkedTable'),
            },
            {
                key: '^l_',
                value: this._translate('fillGrid.listedTable'),
            },
        ];
        this.phaseActivatedList = [
            {
                key: 'activated',
                value: this._translate('shared.activated', { GENDER: 'male' }),
            },
            {
                key: 'disable',
                value: this._translate('shared.disabled'),
            },
        ];
        this.requiredList = [
            {
                key: 'required',
                value: this._translate('status.required', { COUNT: 1 }),
            },
            {
                key: 'optional',
                value: this._translate('status.optional', { COUNT: 1 }),
            },
            {
                key: 'noRequired',
                value: this._translate('status.noRequired', { COUNT: 1 }),
            },
        ];
        this.filter = {
            table: $state.params.table || '',
            tableType: $state.params.tableType || '',
            phase: $state.params.phase || '',
            phaseActivated: $state.params.phaseActivated || '',
            relation: $state.params.relation || '',
            severity: $state.params.severity || '',
            required: $state.params.required || '',
            search: $state.params.search || '',
        };
        this.filteredGridFillList = [];
        this.onSaveGridFillClick = async () => {};
        this.gridFillId = this._$stateParams.gridFillId;

        this.loading = true;
        this.isButtonWaiting = false;
        this._$scope.$watchGroup(
            [
                '$ctrl.filter.table',
                '$ctrl.filter.tableType',
                '$ctrl.filter.phase',
                '$ctrl.filter.phaseActivated',
                '$ctrl.filter.relation',
                '$ctrl.filter.severity',
                '$ctrl.filter.required',
                '$ctrl.filter.search',
                '$ctrl.fillGrid.isAdvanced',
                '$ctrl.gridFillList',
            ],
            () => this.setScopeGridFillViewWithFilters(),
        );

        const navigation = {
            defaultPrevious: {
                href: this._$state.href('app.gridFillList'),
                key: 'fillGrids',
                title: this._translate('shared.fillGrid'),
            },
            allowedPreviousKeys: ['fillGrids'],
        };
        if (this.gridFillId) {
            navigation.newPage = [
                {
                    title: 'Éditer une grille de remplissage',
                    href: this._$state.href('app.gridFillEdit'),
                },
            ];
        } else {
            navigation.newPage = [
                {
                    title: this._translate('fillGrid.createFillGrid'),
                    href: this._$state.href('app.gridFillCreate'),
                },
            ];
        }

        $scope.$emit('keepPreviousNavigation', navigation);
    }

    async $onInit() {
        this.loading = true;

        try {
            this.dataModels = await this.getDataModels();
            this.companies = await this.getCompanies();

            this.tableDetail = this._$tableService.detail();

            if (!this.gridFillId) {
                this.text = this._translate('shared.create');
                this.textButton = this._translate('shared.create');
                this.onSaveGridFillClick = this.createGridFill;

                return;
            }
            this.text = this._translate('shared.edit');
            this.textButton = this._translate('shared.save');
            this.onSaveGridFillClick = this.updateFillGrid;

            await this.setGridFill();
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this._$timeout(() => {
                this.loading = false;
            });
        }
    }

    changeRequired(item) {
        if (item.required !== 'optional') {
            item.condition = null;
            item.testableCondition = null;
        }
    }

    async getCompanies() {
        try {
            const companies = await this._companyProvider.getAll();

            return this._orderBy(companies, 'name');
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    async getDataModels() {
        try {
            return await this._dataModelProvider.getAll();
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    async setGridFill() {
        try {
            const gridFill = await this._fillGridProvider.get(this.gridFillId);
            this.gridFill = gridFill;
            this.prepareData();
            this.phaseList = gridFill.data[0].phases;
            this.prepareFilters(this.gridFillList);
            this.lastFillGridData = [...this.gridFillList];
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    prepareData() {
        this.gridFillList = this.gridFill.data.map((attribute) => ({
            ...attribute,
            required: attribute.required || 'noRequired',
            areAllPhasesDisabled: attribute.phases.every((phase) => !phase.isEnabled),
        }));
    }
    phaseChange(item) {
        this.arePhasesDisable(item);
    }

    arePhasesDisable(item) {
        item.required = item.phases.every((phase) => !phase.isEnabled) ? 'noRequired' : 'required';
        item.areAllPhasesDisabled = item.phases.every((phase) => !phase.isEnabled);
        this.changeRequired(item);
    }

    filterSearch(search) {
        return (attr) => search === '' || attr.attributName.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    filterPhases(phase) {
        return (attr) =>
            phase === '' ||
            attr.phases.find((attributePhase) => attributePhase.phaseName === phase && attributePhase.isEnabled);
    }

    filterRelations(relation) {
        return (attr) => relation === '' || attr.relation === relation;
    }

    filterTableTypes(regexpTableType) {
        return (attr) => regexpTableType === null || regexpTableType.test(attr.relation);
    }

    filterTables(table) {
        return (attr) => table === '' || attr.tableName === table;
    }

    filterSeverity(severity) {
        return (attr) => severity === '' || attr.severity === severity;
    }

    filterRequired(required) {
        return (attr) => required === '' || attr.required === required;
    }

    static filterByPhases(phaseActivated) {
        if (phaseActivated === 'activated') {
            return (control) => control.phases.some((phase) => phase.isEnabled);
        } else if (phaseActivated === 'disable') {
            return (control) => control.phases.every((phase) => !phase.isEnabled);
        }

        return () => true;
    }

    setScopeGridFillViewWithFilters() {
        const { phase, phaseActivated, relation, search, severity, table, tableType, required } = this.filter;

        this._$state.go('.', {
            phase,
            phaseActivated,
            relation,
            severity,
            table,
            tableType,
            required,
            search,
        });

        let regexpTableType = null;
        if (tableType && tableType !== '') {
            regexpTableType = new RegExp(tableType, 'i');
        }

        this.filteredGridFillList = this.gridFillList
            .filter(GridFillFormPage.filterByPhases(phaseActivated))
            .filter(this.filterSearch(search))
            .filter(this.filterPhases(phase))
            .filter(this.filterRelations(relation))
            .filter(this.filterTables(table))
            .filter(this.filterTableTypes(regexpTableType))
            .filter(this.filterSeverity(severity))
            .filter(this.filterRequired(required));
    }

    prepareFilters(gridFillList) {
        const phaseList = new Map();
        const tableList = new Map();
        const relationList = new Map();

        gridFillList[0].phases.forEach(({ phaseName }) => {
            phaseList.set(phaseName, phaseName);
        });
        gridFillList.forEach(({ relation, tableName }) => {
            tableList.set(tableName, tableName);
            if (angular.isString(relation)) {
                relationList.set(relation, relation);
            }
        });

        this.phaseList = Array.from(phaseList, GridFillFormPage.mapToObject);
        this.tableList = Array.from(tableList, GridFillFormPage.mapToObject);
        this.relationList = Array.from(relationList, GridFillFormPage.mapToObject);
    }

    static mapToObject([key, value]) {
        return {
            key,
            value,
        };
    }

    async importFile(file) {
        if (file?.size < FileService.excelMaxSize) {
            return this.processFile(file);
        }

        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'shared.warning',
            'shared.canImportFile',
            {
                SIZE: file?.size,
            },
        );

        if (!isAccepted) {
            return;
        }

        await this.processFile(file);
    }

    async processFile(file) {
        this.loading = true;

        const data = await this._$excelService.importXLSX(URL.createObjectURL(file));
        if (!data?.length) {
            this._$timeout(() => (this.loading = false));

            return;
        }

        const fills = this._$fillGridService.excelToJson(data);
        const isDataWillUpdated = this.gridFillList.length !== 0 ? this.isFillGridHaveSameDataOrMore(fills.grid) : true;
        if (isDataWillUpdated) {
            this.gridFillList = fills.grid;
            this.prepareFilters(this.gridFillList);
            this._$toasterService.success({
                body: this._translate('fillGrid.successImport'),
            });
        } else {
            this._$toasterService.error({
                body: this._translate('fillGrid.failImport'),
            });
        }

        this._$timeout(() => (this.loading = false));
    }

    isFillGridHaveSameDataOrMore(newDataFillGrid) {
        let isFoundAllElementData = true;

        this.gridFillList.forEach((element1) => {
            if (
                // controle si il n'existe plus
                !newDataFillGrid.some((element2) => this.isSameData(element1, element2))
            ) {
                isFoundAllElementData = false;
            }
        });

        return isFoundAllElementData;
    }

    isSameData(elementData1, elementData2) {
        return (
            elementData1.tableName === elementData2.tableName && elementData1.attributName === elementData2.attributName
        );
    }

    async createGridFill() {
        if (!(await this.isSaveAccepted())) {
            return;
        }

        if (!this.gridFillList.length) {
            return this._$toasterService.error({
                body: this._translate('fillGrid.importRequired'),
            });
        }

        this.isButtonWaiting = true;
        this.gridFillList = this.gridFillList.map((element) => radashOmit(element, ['areAllPhasesDisabled']));

        const gridFill = angular.copy(this.gridFill);
        gridFill.data = angular.copy(this.gridFillList);

        try {
            await this._fillGridProvider.create(gridFill);
            this._$toasterService.success(this._translate('shared.savedGrid'));
            this._$state.go('app.gridFillList');
        } catch (error) {
            if (!error.data) {
                this._$toasterService.error(error);

                return;
            }

            this._$gridService.displayDuplicatedError(error.data);
        } finally {
            this.isButtonWaiting = false;
        }
    }

    isSaveAccepted() {
        if (this.gridFill.isClonable === !this.isOrange && this.gridFill.isEditable === !this.isOrange) {
            return true;
        }

        return this._$modalService.triggerConfirmationModal('shared.save', 'shared.differentActionConfirm');
    }

    async updateFillGrid() {
        if (!(await this.isSaveAccepted())) {
            return;
        }

        this.isButtonWaiting = true;
        const gridFill = angular.copy(this.gridFill);
        gridFill.data = angular.copy(this.gridFillList).map((element) => radashOmit(element, ['areAllPhasesDisabled']));

        try {
            await this._fillGridProvider.update(this.gridFillId, gridFill);
            this._$toasterService.success(this._translate('shared.savedGrid'));
            this.gridFillList = this.gridFillList.map((element) => radashOmit(element, ['areAllPhasesDisabled']));
            this.lastFillGridData = [...this.gridFillList];
            this._$state.go('app.fillGridView', { gridFillId: gridFill.id });
        } catch (error) {
            if (!error.data) {
                this._$toasterService.error(error);

                return;
            }

            this._$gridService.displayDuplicatedError(error.data);
        } finally {
            this.isButtonWaiting = false;
        }
    }

    setCompany(value) {
        const { company } = this.gridFill;
        if (company.indexOf(value) > -1) {
            this.gridFill.company = company.filter((company) => company !== value);
        } else {
            this.gridFill.company = [...company, value];
        }
    }

    setSeverity(item, severity) {
        item.severity = severity;
    }

    listCompanies(companies) {
        return (companies || [])
            .map((company) => this.companies.find(({ id }) => id === company))
            .filter((company) => company)
            .map(({ name }) => name)
            .join(', ');
    }

    getDataModelName(value) {
        if (value !== null) {
            const dataModel = this.dataModels.find((dataModel) => dataModel.id === value || dataModel.id === value.id);

            if (dataModel) {
                return dataModel.name;
            }
        }

        return '';
    }

    setDataModel(value) {
        this.gridFill.dataModel = value;
    }
}

angular.module('dotic').component('gridFillFormPage', {
    controller: GridFillFormPage,
    templateUrl: template,
});
