import { HelpersService } from '../../../services/helpers.service';

const gridToSelectData = ({ id, fullname }) => ({ id, name: fullname });
const dataModelToSelectData = ({ _id, name }) => ({ id: _id, name: name });

class ControlConfigurationFormModalController {
    constructor(
        $filter,
        $stateParams,
        $timeout,
        $toasterService,
        $uibModalInstance,
        controlConfiguration,
        controlConfigurationProvider,
        dataModelProvider,
    ) {
        this._$toasterService = $toasterService;
        this._$uibModalInstance = $uibModalInstance;
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._$timeout = $timeout;
        this._$stateParams = $stateParams;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._dataModelProvider = dataModelProvider;

        this.controlConfiguration = controlConfiguration;
        this.controlGridList = [];
        this.fillGridList = [];
        this.projectList = [];
        this.dataModels = [];

        this.dataModelId = '';

        this.controlConfigurationId = $stateParams.controlConfigurationId || null;
    }

    $onInit() {
        this.setTitleModal();

        this._dataModelProvider
            .getAllNames()
            .then((dataModels) => {
                const dataModelListMap = dataModels.map(dataModelToSelectData);
                this.dataModels = this._orderBy(dataModelListMap, 'name');

                return this.loadConfigurationControl()
                    .then((controlConfiguration) => {
                        this._$timeout(() => {
                            if (controlConfiguration) {
                                this.setDataModel(controlConfiguration.dataModel.id);
                            }
                        });
                    })
                    .catch(this._$toasterService.error);
            })
            .catch(this._$toasterService.error);
    }

    setDataModel(dataModelId) {
        if (dataModelId) {
            this.dataModelId = dataModelId;

            this._controlConfigurationProvider
                .getDataToCreate(this.dataModelId)
                .then(({ projects, controlGrids, fillGrids }) => {
                    this._$timeout(() => {
                        const controlGridListMap = controlGrids.map(gridToSelectData);
                        this.controlGridList = this._orderBy(controlGridListMap, 'name');

                        if (controlGrids && controlGrids.length === 1) {
                            this.controlConfiguration.controlGrid = this.controlGridList[0].id;
                        }

                        const fillGridListMap = fillGrids.map(gridToSelectData);
                        this.fillGridList = this._orderBy(fillGridListMap, 'name');

                        if (fillGrids && fillGrids.length === 1) {
                            this.controlConfiguration.fillGrid = this.fillGridList[0].id;
                        }

                        this.projectList = HelpersService.toKeyValue(projects, {
                            keyField: 'id',
                            valueField: 'name',
                        });
                    });
                });
        }
    }

    loadConfigurationControl() {
        if (!this.controlConfigurationId) {
            return Promise.resolve(null);
        }

        return this._controlConfigurationProvider.get(this.controlConfigurationId);
    }

    onSubmit() {
        const { id } = this.controlConfiguration;

        let stateControlConf;
        let promise;

        this.controlConfiguration.dataModel = this.dataModelId;
        if (id) {
            stateControlConf = 'UPDATE';
            promise = this._controlConfigurationProvider.update(id, this.controlConfiguration);
        } else {
            stateControlConf = 'CREATE';
            promise = this._controlConfigurationProvider.create(this.controlConfiguration);
        }

        promise
            .then((controlConfiguration) => {
                if (stateControlConf === 'UPDATE') {
                    this._$toasterService.success({
                        body: this._translate('controlConfigurationFormModal.controlConfigurationModified'),
                    });
                } else {
                    this._$toasterService.success({
                        body: this._translate('controlConfigurationFormModal.controlConfigurationAdded'),
                    });
                }

                this._$uibModalInstance.close(controlConfiguration);
            })
            .catch((e) => {
                if (e.data.code === 'NAME_DUPLICATE') {
                    this.controlConfigurationForm.name.$setValidity('duplicate', false);
                    this.controlConfigurationForm.name.$touched = true;
                } else {
                    this._$toasterService.error(e);
                }
            });
    }

    setTitleModal() {
        return angular.isDefined(this.controlConfiguration.name)
            ? this.controlConfiguration.name
            : this._translate('shared.controlConfiguration', { COUNT: 1 });
    }

    unsetError() {
        this.controlConfigurationForm.name.$setValidity('duplicate', true);
    }

    onCancel() {
        this._$uibModalInstance.dismiss();
    }
}

angular.module('dotic').controller('ControlConfigurationFormModalController', ControlConfigurationFormModalController);
