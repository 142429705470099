import template from './controlErrorsModal.html';

class ControlErrorsModalComponent {
    constructor($scope) {
        this._$scope = $scope;

        this.controlErrors = [];
        this.fillingStatistics = {};

        this.bottomTemplate = false;
        this.loading = true;
        this.error = false;

        this.paginationParameters = {
            page: 0,
            pageSize: 10,
            totalCount: 0,
            sort: {},
        };

        this.filter = {
            search: '',
        };

        $scope.$watchGroup(['$ctrl.filter.search'], () => this.setFilters());
    }

    $onInit() {
        this.bottomTemplate = this.resolve.bottomTemplate;
        this.fillingStatistics = this.resolve.fillingStatistics;

        if (this.fillingStatistics) {
            this.filter.search = this.fillingStatistics.attributName || '';
        }

        this.control = this.resolve.control;

        this.initModal(this.resolve.controlErrors);
    }

    initModal(controlErrors) {
        if (angular.isArray(controlErrors)) {
            this.setItems(controlErrors);
            this.loading = false;
        }

        if (angular.isFunction(controlErrors)) {
            controlErrors({ page: this.paginationParameters.page - 1, limit: this.paginationParameters.pageSize })
                .then((results) => {
                    this.paginationParameters.totalCount = results.count;
                    this.setItems(results);
                })
                .then(() => {
                    this._$scope.$apply();
                    this.loading = false;
                });
        } else {
            this.setItems(controlErrors);
            this.loading = false;
        }
    }

    onChange(page, pageSize, sort) {
        this.paginationParameters = {
            ...this.paginationParameters,
            page: page,
            pageSize: pageSize,
            sort: sort,
        };

        this.initModal(this.resolve.controlErrors);
    }

    setItems(items) {
        if (!items) {
            this.error = true;
            this.loading = false;

            return;
        }

        const formattedErrors = 'errors' in items ? items.errors : items;

        this.controlErrors = formattedErrors.map((control) => {
            return {
                ...control,
                count: Number(control.count),
            };
        });

        if (this.fillingStatistics) {
            this.filteredControlErrors = this.controlErrors.filter(
                (control) => control.attribute === this.fillingStatistics.attributName,
            );
        } else {
            this.filteredControlErrors = angular.copy(this.controlErrors);
        }

        this.loading = false;
    }

    hasIdClient() {
        if (!this.control.idClient) {
            return false;
        }

        return this.control.idClient.trim() !== '';
    }

    filterSearch(search) {
        return (error) =>
            search === '' ||
            (error.table || '').toLowerCase().indexOf(search) > -1 ||
            (error.attribute || '').toLowerCase().indexOf(search) > -1 ||
            (error.message || '').toLowerCase().indexOf(search) > -1 ||
            (error.value || '').toLowerCase().indexOf(search) > -1 ||
            (error.externalName || '').toLowerCase().indexOf(search) > -1 ||
            (error.externalValue || '').toLowerCase().indexOf(search) > -1;
    }

    setFilters() {
        if (this.fillingStatistics) {
            const search = this.filter.search;
            this.filteredControlErrors = this.controlErrors.filter(this.filterSearch(search));
        } else {
            const search = this.filter.search.toLowerCase();
            this.filteredControlErrors = this.controlErrors.filter(this.filterSearch(search));
        }
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('controlErrorsModal', {
    templateUrl: template,
    controller: ControlErrorsModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
