import template from '../remove/removeModal.html';

class RemoveModalComponent {
    /** @ngInject */
    constructor() {
        this.close = undefined;
        this.dismiss = undefined;
        this.resolve = {
            message: '',
            submitText: '',
        };
    }

    cancel() {
        this.close({ $value: false });
    }

    submit() {
        this.close({ $value: true });
    }

    get message() {
        return this.resolve.message;
    }

    get submitText() {
        return this.resolve.submitText;
    }
}

angular.module('dotic').component('removeModal', {
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: RemoveModalComponent,
    templateUrl: template,
});
