import serverDetailTemplate from '../../server/detail/serverDetail.html';

class ServerDetailPage {
    constructor(
        $auth,
        $authorizationService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        $uibModal,
        deliveryZoneProvider,
        serverProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._translate = $filter('translate');

        this._deliveryZoneProvider = deliveryZoneProvider;
        this._serverProvider = serverProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;

        this.isAllowed = $authorizationService.isAllowed;

        this.companyId = $auth.getPayload().company;

        this.loading = true;
        this.server = null;
        this.serverId = $stateParams.serverId;
        this.deliveryZoneList = [];
        this.projectList = [];

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    title: 'Détails de la base de stockage',
                    key: 'serverDetail',
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: 'Bases de stockage',
                href: this._$state.href('app.serverList'),
                key: 'servers',
            },
            allowedPreviousKeys: ['serverList', 'deposits', 'deliveryZoneDetail', 'projectDetail'],
        });
    }

    async $onInit() {
        this.loading = true;

        this._userMetricsProvider.openServer(this.serverId);
        this.server = await this.getServer(this.serverId);
        this.deliveryZoneList = await this.getDeliveryZones();
        this.projectList = this.getProjectList();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    isInUse() {
        return !this.server || this.server.countDeposits > 0 || this.server.countDeliveryZones > 0;
    }

    deleteTooltip() {
        if (this.isInUse()) {
            return this._translate('serverList.deliverableOrDeliveryZoneAttached');
        }

        return this._translate('shared.deleteSelection');
    }

    getServer(serverId) {
        return this._serverProvider.getDetail(serverId);
    }

    getDeliveryZones() {
        return this._deliveryZoneProvider.list({ serverId: this.serverId });
    }

    getProjectList() {
        return this.deliveryZoneList
            .map((deliveryZone) => deliveryZone.project)
            .reduce((acc, project) => {
                if (project && acc.findIndex((a) => a._id === project._id) === -1) {
                    acc.push(project);
                }

                return acc;
            }, []);
    }

    async removeServer() {
        if (this.isInUse()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.storage', {
                COUNT: 1,
                NAME: this.server.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._serverProvider.removeServer(this.server.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successStorage', { COUNT: 1 }),
            });
            await this._$state.go('app.serverList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular.module('dotic').component('serverDetailPage', {
    controller: ServerDetailPage,
    templateUrl: serverDetailTemplate,
});
