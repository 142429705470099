angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.interventionGridsList', {
        url: '/interventionGrids',
        component: 'interventionGridListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin', 'admin'],
        },
    });
    $stateProvider.state('app.interventionGridCreate', {
        url: '/interventionGrids/create',
        component: 'interventionGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.interventionGridEdit', {
        url: '/interventionGrids/{gridId:[a-f0-9]+}/edit',
        component: 'interventionGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.interventionGridView', {
        url: '/interventionGrids/{gridId:[a-f0-9]+}/view',
        component: 'interventionGridViewPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin', 'admin'],
        },
    });
});
