/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.22.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* DeliveryZones service.
* @module api/DeliveryZonesApi
* @version 1.22.0
*/
export default class DeliveryZonesApi {

    /**
    * Constructs a new DeliveryZonesApi. 
    * @alias module:api/DeliveryZonesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create a delivery zone
     * @param {Object.<String, Object>} body Delivery zone to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    creatDeliveryZoneWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling creatDeliveryZone");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a delivery zone
     * @param {Object.<String, Object>} body Delivery zone to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    creatDeliveryZone(body) {
      return this.creatDeliveryZoneWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a delivery zone by id
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getByIdWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a delivery zone by id
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getById(id) {
      return this.getByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a delivery zone's data to edit
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getDataToEditWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDataToEdit");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones/{id}/edit', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a delivery zone's data to edit
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getDataToEdit(id) {
      return this.getDataToEditWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a list of all delivery zones
     * @param {Object} opts Optional parameters
     * @param {Object.<String, Object>} [params] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'params': opts['params']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a list of all delivery zones
     * @param {Object} opts Optional parameters
     * @param {Object.<String, Object>} opts.params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    list(opts) {
      return this.listWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove a delivery zone
     * @param {String} deliveryZoneId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    removeWithHttpInfo(deliveryZoneId) {
      let postBody = null;
      // verify the required parameter 'deliveryZoneId' is set
      if (deliveryZoneId === undefined || deliveryZoneId === null) {
        throw new Error("Missing the required parameter 'deliveryZoneId' when calling remove");
      }

      let pathParams = {
        'deliveryZoneId': deliveryZoneId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones/{deliveryZoneId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove a delivery zone
     * @param {String} deliveryZoneId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    remove(deliveryZoneId) {
      return this.removeWithHttpInfo(deliveryZoneId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Resolve a delivery zone deposit filename
     * @param {String} project 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    resolveDepositWithHttpInfo(project, name) {
      let postBody = null;
      // verify the required parameter 'project' is set
      if (project === undefined || project === null) {
        throw new Error("Missing the required parameter 'project' when calling resolveDeposit");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling resolveDeposit");
      }

      let pathParams = {
        'project': project,
        'name': name
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones/resolve-deposit/{project}/{name}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Resolve a delivery zone deposit filename
     * @param {String} project 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    resolveDeposit(project, name) {
      return this.resolveDepositWithHttpInfo(project, name)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a delivery zone
     * @param {String} deliveryZoneId 
     * @param {Object.<String, Object>} body Delivery Zone to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    updateWithHttpInfo(deliveryZoneId, body) {
      let postBody = body;
      // verify the required parameter 'deliveryZoneId' is set
      if (deliveryZoneId === undefined || deliveryZoneId === null) {
        throw new Error("Missing the required parameter 'deliveryZoneId' when calling update");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling update");
      }

      let pathParams = {
        'deliveryZoneId': deliveryZoneId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deliveryZones/{deliveryZoneId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a delivery zone
     * @param {String} deliveryZoneId 
     * @param {Object.<String, Object>} body Delivery Zone to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    update(deliveryZoneId, body) {
      return this.updateWithHttpInfo(deliveryZoneId, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
