import dashboardAdminTemplate from './dashboardAdmin.html';
import dashboardSuperAdminTemplate from './superAdmin/dashboard.html';
import dashboardUserTemplate from './dashboardUser.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.dashboard', {
            url: '/dashboard',
            template: '',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'superAdmin', 'user'],
            },
            onEnter: ($auth, $state) => {
                const userRole = $auth.getPayload().userRole;
                switch (userRole) {
                    case 'extendedUser':
                    case 'user':
                        return $state.go('app.dashboardUser');
                    case 'admin':
                        return $state.go('app.dashboardAdmin');
                    case 'superAdmin':
                        return $state.go('app.dashboardSuperAdmin');
                }
            },
        })
        .state('app.dashboardSuperAdmin', {
            url: '/superAdmin/dashboard?phase&company&user&createdAtStart&createdAtEnd&groupByLastVersion',
            params: {
                phase: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                company: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                user: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtStart: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtEnd: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                groupByLastVersion: {
                    type: 'bool',
                    value: false,
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: dashboardSuperAdminTemplate,
            controller: 'DashboardSuperAdminController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        })
        .state('app.dashboardAdmin', {
            url: '/admin/dashboard',
            templateUrl: dashboardAdminTemplate,
            controller: 'DashboardController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.dashboardUser', {
            url: '/user/dashboard',
            templateUrl: dashboardUserTemplate,
            controller: 'DashboardController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['user', 'extendedUser'],
            },
        });
});
