import companyListTemplate from './companyList.html';
import { RemoveModalTypeEnum } from '../../models/modal.model';

class CompanyListPage {
    companyList = [];
    filteredCompanyList = [];
    loading = true;
    offers = [];

    constructor(
        $filter,
        $filterService,
        $modalService,
        $scope,
        $state,
        $timeout,
        $toasterService,
        $uibModal,
        companyProvider,
    ) {
        this._$filterService = $filterService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._companyProvider = companyProvider;
        this._$modalService = $modalService;
        this._translate = $filter('translate');

        this.filters = {
            offer: $state.params.offer || '',
            search: $state.params.search || '',
        };
        this.defaultSort = $state.params.sortedBy || 'name';

        $scope.$watchGroup(['$ctrl.companyList', '$ctrl.filters.offer', '$ctrl.filters.search'], () =>
            this.filterCompanies(),
        );

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('shared.company'),
                    href: $state.href('app.companyList'),
                    key: 'companies',
                },
            ],
        });
    }

    async $onInit() {
        await this.loadCompanies();
    }

    async activateOrSuspendCompany(company, isSuspended) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.company', { NAME: company.name }),
            isSuspended ? RemoveModalTypeEnum.SUSPEND : RemoveModalTypeEnum.REACTIVATE,
        );

        if (!isAccepted) {
            return;
        }

        try {
            await this._companyProvider.update(company.id, { isSuspended: isSuspended });

            this._$toasterService.success({
                body: this._translate('companyForm.editCompanySuccessMessage', {
                    TYPE: isSuspended ? RemoveModalTypeEnum.SUSPEND : RemoveModalTypeEnum.REACTIVATE,
                }),
            });
            await this.loadCompanies();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    countHasSubCompanies(item) {
        return angular.isDefined(item.subCompanies) ? item.subCompanies.length : 0;
    }

    filterCompanies() {
        this._$state.go('.', { offer: this.filters.offer, search: this.filters.search.toLocaleLowerCase() });

        this.filteredCompanyList = this.companyList
            .filter(this._$filterService.companySearch(this.filters.search))
            .filter(this._$filterService.companyOffer(this.filters.offer));
    }

    async loadCompanies() {
        this.loading = true;

        try {
            this.companyList = await this._companyProvider.getAll();
            this.setOffersFromUsers();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    openSubCompaniesModal(item) {
        this._$uibModal.open({
            size: 'sm',
            component: 'subCompaniesModal',
            resolve: {
                companies: () => item.subCompanies,
            },
        });
    }

    async removeCompany(company) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.company', {
                NAME: company.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._companyProvider.delete(company.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successCompany'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.loadCompanies();
    }

    setOffersFromUsers() {
        this.offers = this._$filterService.formatForFilter(
            this.companyList,
            (company) => company.subscription,
            (company) => company.subscription,
            'humanizeOffer',
        );
    }
}

angular.module('dotic').component('companyListPage', {
    controller: CompanyListPage,
    templateUrl: companyListTemplate,
});
