// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/statistic-filling/filling-stat-info.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 6.2.1 / {{ 'shared.informations' | translate }} : </h3> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'shared.informations' | translate }}\"/> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionStatistic/fillingStatistic/fillingInfo.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;