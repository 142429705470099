angular.module('dotic').factory('$indicatorDistanceRangeService', indicatorDistanceRangeService);

function indicatorDistanceRangeService($filter) {
    const translate = $filter('translate');

    return {
        initRangeDistanceData,
        preparedChartDistanceRange,
        preparedChartDistanceRangeMulti,
    };

    function initRangeDistanceData(data) {
        const distanceRangeData = {
            zeroToThreeHundred: 0,
            threeHundredToFiveHundred: 0,
            fiveHundredToThousand: 0,
            thousandToThreeThousand: 0,
            overThreeThousand: 0,
        };

        if (!data.length) {
            return distanceRangeData;
        }

        data.forEach((range) => {
            if (range.distanceKm > 0 && range.distanceKm <= 0.3) {
                distanceRangeData.zeroToThreeHundred += 1;
            } else if (range.distanceKm <= 0.5) {
                distanceRangeData.threeHundredToFiveHundred += 1;
            } else if (range.distanceKm <= 1) {
                distanceRangeData.fiveHundredToThousand += 1;
            } else if (range.distanceKm <= 3) {
                distanceRangeData.thousandToThreeThousand += 1;
            } else {
                distanceRangeData.overThreeThousand += 1;
            }
        });

        return distanceRangeData;
    }

    function preparedChartDistanceRange(data) {
        const distanceBetweenNroPbo = this.initRangeDistanceData(data);

        const labels = [
            translate('shared.zeroToThreeHundred'),
            translate('shared.threeHundredToFiveHundred'),
            translate('shared.fiveHundredToThousand'),
            translate('shared.thousandToThreeThousand'),
            translate('shared.overThreeThousand'),
        ];

        const datasets = [
            {
                data: [
                    distanceBetweenNroPbo.zeroToThreeHundred,
                    distanceBetweenNroPbo.threeHundredToFiveHundred,
                    distanceBetweenNroPbo.fiveHundredToThousand,
                    distanceBetweenNroPbo.thousandToThreeThousand,
                    distanceBetweenNroPbo.overThreeThousand,
                ],
                backgroundColor: ['#50d0c3', '#00a2fb', '#5069a9', '#edb349', '#dd3073'],
            },
        ];

        const numberTotalDistances = data.length;

        return {
            bigLegend: {
                figure: numberTotalDistances,
                name: translate('shared.distance', {
                    COUNT: numberTotalDistances,
                }),
            },
            labels,
            datasets,
        };
    }

    function preparedChartDistanceRangeMulti(dataOrigin, dataToCompare) {
        const distanceBetweenNroPboOrigin = this.initRangeDistanceData(dataOrigin);
        const distanceBetweenNroPboToCompare = this.initRangeDistanceData(dataToCompare);

        const labels = [
            translate('shared.zeroToThreeHundred'),
            translate('shared.threeHundredToFiveHundred'),
            translate('shared.fiveHundredToThousand'),
            translate('shared.thousandToThreeThousand'),
            translate('shared.overThreeThousand'),
        ];

        const datasets = [
            {
                label: 'Dataset 1',
                data: [
                    distanceBetweenNroPboOrigin.zeroToThreeHundred,
                    distanceBetweenNroPboOrigin.threeHundredToFiveHundred,
                    distanceBetweenNroPboOrigin.fiveHundredToThousand,
                    distanceBetweenNroPboOrigin.thousandToThreeThousand,
                    distanceBetweenNroPboOrigin.overThreeThousand,
                ],
                backgroundColor: ['#50d0c3', '#00a2fb', '#5069a9', '#edb349', '#dd3073'],
            },
            {
                label: 'Dataset 2',
                data: [
                    distanceBetweenNroPboToCompare.zeroToThreeHundred,
                    distanceBetweenNroPboToCompare.threeHundredToFiveHundred,
                    distanceBetweenNroPboToCompare.fiveHundredToThousand,
                    distanceBetweenNroPboToCompare.thousandToThreeThousand,
                    distanceBetweenNroPboToCompare.overThreeThousand,
                ],
                backgroundColor: ['#7fffd4', '#06daf0', '#adb9d8', '#f4d090', '#f5bcda'],
            },
        ];

        const numberTotalDistancesOriginLength = dataOrigin.length ?? 0;
        const numberTotalDistancesToCompareLength = dataToCompare.length ?? 0;

        return {
            bigLegend: {
                figure: `${numberTotalDistancesOriginLength} vs ${numberTotalDistancesToCompareLength}`,
                name: `${translate('shared.distance', {
                    COUNT: numberTotalDistancesOriginLength || numberTotalDistancesToCompareLength,
                })} `,
            },
            labels,
            datasets,
        };
    }
}
