import template from './userMetrics.html';
class UserMetricsDetailsPage {
    constructor($state, $stateParams, $scope, $filter, $location, userProvider, userMetricsProvider) {
        this._$scope = $scope;
        this._$stateParams = $stateParams;
        this._$state = $state;
        this._translate = $filter('translate');
        this._userProvider = userProvider;
        this._userMetricsProvider = userMetricsProvider;

        this.loading = true;
        this.stats = null;
        this.history = null;

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'userProfile',
                    title: this._translate('shared.statistics'),
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('role.user'),
                href: this._$state.href('app.userList'),
                key: 'users',
            },
            allowedPreviousKeys: ['user'],
        });
    }

    $onInit() {
        return this.loadStats();
    }

    loadStats() {
        this.loading = true;

        return this._userProvider
            .stats(this._$stateParams.userId)
            .then((stats) => {
                this.stats = stats;

                return this._userMetricsProvider.getMetricsByUser(this._$stateParams.userId);
            })
            .then((history) => {
                this.history = history;
                this.loading = false;
            });
    }
}

angular.module('dotic').component('userMetricsDetailsPage', {
    templateUrl: template,
    controller: UserMetricsDetailsPage,
});
