import template from './postProcessingListModal.html';

class PostProcessingListModalController {
    constructor($timeout, $filter) {
        this._$timeout = $timeout;
        this._translate = $filter('translate');

        this.postProcessings = [];
        this.selectedPostProcessings = [];
        this.action = this._translate('shared.download');
        this.title = '';
        this.loading = true;
    }

    $onInit() {
        this.title = this.resolve.title ?? '';
        this.action = this.resolve.action ?? '';
        this.postProcessings = this.resolve.postProcessingList ?? [];

        this.loading = false;
    }

    closePopup() {
        this.close();
    }

    submit() {
        if (!this.form.$invalid) {
            this.close({
                $value: {
                    selectedPostProcessings: this.selectedPostProcessings,
                },
            });
        }
    }
}

angular.module('dotic').component('postProcessingListModal', {
    templateUrl: template,
    controller: PostProcessingListModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
