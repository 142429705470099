angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.aggregationHome', {
        url: '/aggregation',
        component: 'aggregationHomePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
