class ControlGridConditionController {
    constructor($uibModalInstance, condition) {
        this._$uibModalInstance = $uibModalInstance;

        this.condition = condition;
    }

    closePopup() {
        this._$uibModalInstance.close();
    }
}

angular.module('dotic').controller('ControlGridConditionController', ControlGridConditionController);
