import * as radash from 'radash';
import template from './filter.component.html';

class FilterComponent {
    constructor($auth, $document, $location, $log, $scope, $state, $timeout, userProvider) {
        this._$timeout = $timeout;
        this._$scope = $scope;
        this._$state = $state;
        this._$auth = $auth;
        this._$location = $location;
        this._$log = $log;
        this._userProvider = userProvider;

        this.elContainer = $document.find('#filter-modal-container');
        this.elBlocker = $document.find('#filter-modal-blocker');
        this.elContainerContent = $document.find('#filter-modal-container-content');
        this.elAddFilter = $document.find('#filter-modal-addFilter');
        this.elSearchCross = $document.find('#searchCross');
        this.elModal = $document.find('div.filter-modal');

        this.activeFilters = [];
        this.activeSearch = false;

        this.filterOnBottom = false;
        this.saveFilters = true; // Save filters in localStorage and db
        this.localStorageName = 'CC_FILTERS';
        this.localStorageValue = this.getLocalStorageFilters();
        this.preserveFilters = false;

        $scope.$watch('$ctrl.filter.search', () => {
            this.activeSearch = !!this.filter.search;
            this.toggleSearchCross();
        });

        $scope.$watch(
            '$ctrl.filter',
            () => {
                this.storeFilters();
            },
            true,
        );

        $scope.$watch(
            '$ctrl.slider',
            () => {
                this.storeFilters();
            },
            true,
        );

        // Force render of a filter when a filter value is passed without using $onChanges or component interface
        $scope.$on('forceFilterRender', (event, params) => {
            this.addFilter(params.filter, params.saveChanges, params.openDropdown);
        });
    }

    $onInit() {
        this.updateBadge();
        this.toggleSearchCross();
        this.isBottomFilter();
        this.applyFromLocalStorage();
    }

    $onChanges() {
        // Retrieves filters transmitted by other pages.
        Object.keys(this.filter).forEach((key) => {
            if ((this.filter[key]?.length && key !== 'search') || (key === 'date' && this.filter[key] !== '')) {
                const urlFilter = this.filterOptions.find((item) => item.value === key || item.selected === key);
                this.addFilter(urlFilter, false, false);
            }
        });

        if (this.slider) {
            const slider = this.filterOptions.find((item) => item.type === 'slider');
            if (this.slider[slider.min] !== 0 || this.slider[slider.max] !== 100) {
                const urlFilter = this.filterOptions.find((item) => item.type === 'slider');
                this.addFilter(urlFilter, false, false);
            }
        }

        this.updateBadge();
        this.activeSearch = !!this.filter.search;
        this.toggleSearchCross();
    }

    toggleModal() {
        this.elContainer.toggleClass('filter-active');
        this.elBlocker.toggle();
        this.elAddFilter.removeClass('filter-active');
        if (this.slider) {
            this._$scope.$broadcast('rzSliderForceRender');
        }
    }

    toggleAddFilterModal() {
        this.elAddFilter.toggleClass('filter-active');
        if (this.elAddFilter[0].classList.contains('filter-active')) {
            this.updateWrapperScroll();
        }
    }

    hideAddFilterModal() {
        this.elAddFilter.removeClass('filter-active');
    }

    hideFilterModal() {
        this.elContainer.toggleClass('filter-active');
        this.hideAddFilterModal();
        this.elBlocker.toggle(false);
    }

    toggleSearchCross() {
        if (this.activeSearch) {
            this.elSearchCross.addClass('filter-active');

            return;
        }

        this.elSearchCross.removeClass('filter-active');
    }

    openDropdown(filterType) {
        this._$timeout(() => {
            if (!this.elContainer.hasClass('filter-active')) {
                return;
            }

            const lines = this.elContainerContent[0].children;
            const len = lines.length - 1;
            const lastLine = lines[len];
            const elementToFind =
                filterType === 'date' ? '.date-picker' : '.dropdown-selector.cc-filter-dropdown.dropdown-toggle';

            angular.element(lastLine).find(elementToFind).click();
        });
    }

    addFilter(filter, saveChanges = true, openDropdown = true) {
        const isAlreadyActive = this.activeFilters.some((item) => item.name === filter.name);
        if (isAlreadyActive || !filter) {
            return;
        }

        this.elAddFilter.removeClass('filter-active');
        this.activeFilters.push({ ...filter, active: true });
        this.updateBadge();
        this.updateModalScroll();
        if (openDropdown) {
            this.openDropdown(filter.type);
        }

        if (saveChanges) {
            this.storeFilters();
        }
    }

    countFilterWithValue() {
        let count = 0;
        Object.keys(this.filter).forEach((key) => {
            if (
                this.filter[key] !== '' &&
                this.filter[key]?.toString().length &&
                key !== 'search' &&
                !this.filter[key].isSlider
            ) {
                ++count;
            }

            if (this.filter.createdAtEnd && this.filter.createdAtStart) {
                ++count;
            }
        });

        if (this.slider) {
            const slider = this.filterOptions.find((item) => item.type === 'slider');
            const min = this.slider[slider.min];
            const max = this.slider[slider.max];
            if (min !== 0 || max !== 100) {
                ++count;
            }
        }

        return count;
    }

    updateBadge() {
        const filterActive = this.countFilterWithValue();

        if (filterActive) {
            angular.element('#filter-button').find('.filter-button-badge, i.fas.fa-trash').addClass('filter-active');

            return filterActive;
        }

        angular
            .element('#filter-button')
            .find('.filter-button-badge, .filter-button-close')
            .removeClass('filter-active');
    }

    isFiltering(item) {
        let isFiltering;

        switch (item.type) {
            case 'multi-select':
                isFiltering = this.filter[item.selected].length > 0;
                break;

            case 'date':
                isFiltering = this.filter[item.value]?.toString().length > 0;
                break;

            case 'slider':
                isFiltering = this.slider[item.min] !== 0 || this.slider[item.max] !== 100;
                break;

            default:
                // case: select
                isFiltering = this.filter[item.value] !== '' && angular.isDefined(this.filter[item.value]);
        }

        return isFiltering ? { border: '1px solid #0099fa', color: '#0082d5' } : { border: '1px solid #bdbdbd' };
    }

    updateModalScroll() {
        // Doesn't work without timeout
        this._$timeout(() => {
            this.elContainerContent[0].scrollTop = this.elContainerContent[0].scrollHeight;
        });
    }

    updateWrapperScroll() {
        // Doesn't work without timeout
        this._$timeout(() => {
            this.elModal[0].scrollIntoView({
                block: 'nearest',
                behavior: 'smooth',
            });
        });
    }

    removeSearch() {
        this.onCancelSearch();
        this.activeSearch = false;
        this.toggleSearchCross();
        this.storeFilters();
    }

    removeFilter(element, key) {
        this.activeFilters = this.activeFilters.filter((activeFilter) => activeFilter !== key);

        let initialValue;
        let keyValue;
        switch (key.type) {
            case 'multi-select':
                initialValue = [];
                keyValue = key.selected ?? key.value;
                break;
            case 'slider':
                initialValue = [0, 100];
                keyValue = key.type;
                break;
            default:
                // case: select
                // case: date
                initialValue = '';
                keyValue = key.selected ?? key.value;
        }

        this.onCancelFilter({ keyValue: keyValue, initialValue: initialValue });
        angular.element(element.currentTarget.parentNode).remove();
        this.updateBadge();
        this.storeFilters();
    }

    removeAllFilters($event) {
        $event.stopPropagation();
        angular.element(document).find('.filter-modal-container-content-line').remove();
        this.activeFilters = [];
        this.onCancelFilters();
        this.updateBadge();
        this.storeFilters();
    }

    updateFilterList(element) {
        const inActiveFilters = this.activeFilters.find((item) => item.name === element.name);
        const isEmpty = this.filterValues[element.name] ? !!this.filterValues[element.name].length : true;

        return !inActiveFilters && isEmpty && element.allowed;
    }

    updateAddFilterButton() {
        const elAddFilterList = angular.element(this.elAddFilter).find('#addFilterList li');

        return !elAddFilterList.length;
    }

    saveToLocalStorage(filterObj = this.createLocalStorageObject()) {
        localStorage.setItem(this.localStorageName, angular.toJson(filterObj));
    }

    async saveToDb(filterObj = this.createLocalStorageObject()) {
        try {
            const userId = this._$auth.getPayload().userId;
            await this._userProvider.updateFiltersToDb(userId, filterObj);
        } catch (error) {
            this._$log.error('Unable to save filters to database', error);
        }
    }

    applyFromLocalStorage() {
        if (!this.saveFilters) {
            return;
        }

        const hasParams = Object.keys(this._$location.search()).length > 0;

        if (hasParams) {
            // Preserve filters
            this.preserveFilters = true;

            return;
        }

        // If LocalStorage is applied
        const elements = this._$state.router.globals.current.name.split('.');
        const parent = this.localStorageValue[elements[0]];

        if (!parent) {
            return; // If the user has no filter data
        }

        const data = parent[elements[1]];

        if (!data?.length) {
            return;
        }

        for (const filter of data) {
            if (!filter.name || !filter.value || angular.isUndefined(filter.data) || filter.data === null) {
                throw new Error(`The filter object is not well formed: ${angular.toJson(this.localStorageValue)}`);
            }

            const filterItem = this.filterOptions.find((item) => item.name === filter.name);
            if (!filterItem) {
                continue;
            }

            this.addFilter(filterItem, false, false);

            if (filterItem.type !== 'slider') {
                if (filterItem.type === 'date' && filter.data !== '') {
                    filter.data.startDate = moment(filter.data.startDate);
                    filter.data.endDate = moment(filter.data.endDate);
                }

                this.filter[filter.value] = filter.data;
            } else {
                this.slider[filterItem.min] = filter.value[0];
                this.slider[filterItem.max] = filter.value[1];
            }
        }
    }

    createLocalStorageObject() {
        const filterItems = this.getActiveFiltersName();

        const elements = this._$state.router.globals.current.name.split('.');
        const parent = elements[0];
        const path = elements[1];

        return {
            ...this.localStorageValue,
            [parent]: {
                ...this.localStorageValue[parent],
                [path]: filterItems,
            },
        };
    }

    getActiveFiltersName() {
        const filterArray = [];
        this.activeFilters.forEach((filter) => {
            let initialValue;
            let filterValue;
            switch (filter.type) {
                case 'multi-select':
                    initialValue = [];
                    filterValue = filter.selected;
                    break;
                case 'slider':
                    initialValue = [0, 100];
                    filterValue = [this.slider[filter.min], this.slider[filter.max]];
                    break;

                default:
                    // case 'select' and 'date'
                    initialValue = '';
                    filterValue = filter.value;
            }

            const filterItem = {
                name: filter.name,
                value: filterValue,
                data: filter.saveValue ? this.filter[filterValue] : initialValue,
            };

            filterArray.push(filterItem);
        });

        return filterArray;
    }

    storeFilters() {
        if (this.preserveFilters || !this.saveFilters) {
            return;
        }

        const filterObj = this.createLocalStorageObject();
        const localStorageObj = this.getLocalStorageFilters();

        // Saves filterObj if the new is different from the old one
        if (!radash.isEqual(filterObj, localStorageObj)) {
            this.saveToLocalStorage(filterObj);
            this.saveToDb(filterObj);
        }
    }

    getLocalStorageFilters() {
        return angular.fromJson(localStorage.getItem(this.localStorageName)) || {};
    }

    isBottomFilter() {
        this.filterOnBottom = this.bottomFilter;
    }
}

/**
 * @ngdoc component
 * @name ccFilter
 *
 * @description
 * The component that contains all filters
 *
 * @param {array of objects} filterOptions List of objects where each object is a filter with its parameters (More informations at the bottom)
 * @param {object} filter List of filters with its values get by url
 * @param {object of arrays} filterValues List of filter values
 * @param {function} onCancelFilters Function that remove all filters except search
 * @param {function} onCancelFilter Function that remove a specific filter
 * @param {function} onCancelSearch Function that remove search filter
 * @param {function} slider Slider object with its properties
 * @param {function} bottomFilter Option that displays filters differently
 */
angular.module('dotic').component('ccFilter', {
    bindings: {
        bottomFilter: '<',
        filter: '=',
        filterOptions: '<',
        filterValues: '<',
        onCancelFilters: '&',
        onCancelFilter: '&',
        onCancelSearch: '&',
        slider: '=',
    },
    controller: FilterComponent,
    templateUrl: template,
});

/*
Details of the filterOption properties

 ➜ GENERAL filterOption properties :
    ▪︎ name : name of the filter [Required]
    ▪︎ placeholder: name that match with the translation object 'filter.all.YOURVALUE' [Required]
    ▪︎ type : type of the filter which has to be one of the following : ('select', 'multi-select', 'slider', 'date') [Default: 'select']
    ▪︎ hasSearch: Insert a search bar in the dropdown of values [Optional]
    ▪︎ allowed : Option to add a condition to display the filter [Optional]
    ▪︎ saveValue : Option to save the filter data in the database. ⚠️ The filter data must be non-dynamic [Optional]

 ➜ SPECIFIC filterOption properties depending his type :
    ▪︎ type: 'select' / 'date':
                ▫︎ value : value of the filter [Required]

    ▪︎ type: 'multi-select':
                ▫︎ selected : value of the filter [Required]

    ▪︎ type: 'slider':
        ⚠️ : The slider is handle by this.slider and not this.filter
        ⚠️ : The slider placeholder may not match with the translation object 'filter.all'
                ▫︎ min : minimum value of the slider [Required]
                ▫︎ max : maximum value of the slider [Required]
*/
