import template from './forgotPassword.html';

import { AuthApi } from '../../../../sdk/connect-control-api-v1/src';

class ForgotPasswordPage {
    emailFormValue = '';
    forgotPasswordForm = {};

    constructor($apiClientService, $auth, $filter, $scope, $state, $toasterService) {
        this._$auth = $auth;
        this._$state = $state;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this._authApi = new AuthApi($apiClientService.client);

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('forgotPassword.passwordForgotten'),
                    href: $state.href('forgotPassword'),
                },
            ],
        });
    }

    $onInit() {
        if (this._$auth.isAuthenticated()) {
            this._$state.go('app.depositJob-control');
        }
    }

    async requestResetPassword(email) {
        try {
            await this._authApi.requestResetPasswordWithHttpInfo({
                email: email,
                changePasswordPath: 'change-password',
            });
            this._$toasterService.success(this._translate('shared.sendRequestResetPasswordEmail'));

            this._$state.go('login');
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
    }
}

angular.module('dotic').component('forgotPasswordPage', {
    controller: ForgotPasswordPage,
    templateUrl: template,
});
