import aggregationDetailTemplate from './aggregation-detail.html';
import EnvironmentSettingsService from '../../../services/environment-settings.service';

class AggregationDetailPage {
    aggregationItem = {};
    loading = true;
    user = {};
    userId = null;

    constructor($auth, $filter, $modalService, $timeout, $toasterService, userProvider) {
        this._$modalService = $modalService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._userProvider = userProvider;

        this.isOrange = EnvironmentSettingsService.isOrange();
        this.userId = $auth.getPayload().userId;
    }

    async $onInit() {
        this.loading = true;

        try {
            this.user = await this._userProvider.get(this.userId);
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
            this.user = { settings: { feature: { hasDebugMode: false } } };
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async openBusinessCodesModal(businessCodes) {
        await this._$modalService.triggerListModal(
            businessCodes.map((name) => ({ name: name })),
            this._translate('shared.businessCode'),
        );
    }

    async openDataModelsModal(dataModels) {
        await this._$modalService.triggerListModal(
            dataModels.map((name) => ({ name: name })),
            this._translate('shared.dataModel'),
        );
    }
}

angular.module('dotic').component('aggregationDetailPage', {
    controller: AggregationDetailPage,
    templateUrl: aggregationDetailTemplate,
    bindings: {
        aggregationItem: '<',
    },
});
