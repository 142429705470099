class CcTableXhrController {
    constructor($compile, $log, $element, $scope, $timeout, $state, $stateParams, $filter) {
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._translate = $filter('translate');
        this._$log = $log;

        this.paginationWidth = $scope.paginationWidth || 11;

        this.total = $scope.total;

        const page = this.getPageNumber();
        const pageSize = Number.parseInt($scope.pageSize) || 20;
        const nbPages = this.getNbPages(pageSize);

        this.sort = this.getDefaultSort();
        this.pagination = {
            page,
            pageSize,
            nbPages,
        };

        const footerTemplate = `<div class="table__footer">
<span class="table__length-selector">${this._translate(
            'shared.show',
        )}<select class="table__length-selector-select" ng-model="pageSize" ng-options="size for size in pageSizes"></select>${this._translate('shared.result')}</span><span class="table__nbrows"></span>
                <span class="table__paging"></span></div>`;

        const loaderTemplate = `
    <div class="table__content-loader" ng-show="loading">
        <spinner></spinner>
    </div>
`;

        const pageSizes = [10, 20, 50, 100];

        if (!pageSizes.includes(this.pagination.pageSize)) {
            pageSizes.push(this.pagination.pageSize);
            pageSizes.sort((a, b) => a - b);
        }

        $scope.$watch('rows', () => {
            this.updateTable();
        });

        $scope.$watch('page', (p) => {
            this.setPage(p);
        });

        // Compare params and reset pagination on change
        $scope.$watchCollection(
            () => $stateParams,
            ({ p: newPage }, { p: prevPage }) => {
                if (newPage !== prevPage) {
                    this.setPage(newPage);
                }
            },
        );

        // Watch selection
        $scope.$on('getVisibleRows', () => {
            this._$scope.$emit('selectRows', { rows: this.getRows() });
        });

        // Compatibility
        $scope.$on('getAllRows', () => {
            $scope.$emit('selectRows', { rows: this.getRows() });
        });

        $timeout(() => {
            let tableLines = angular.element('body').find('.table__content-table [cc-table-repeat]');
            if (tableLines.length === 0) {
                tableLines = angular.element('body').find('.table__content-table tbody tr');
            }

            tableLines.each((i, e) => {
                const hasLink = angular.element(e).find('td[ui-sref], td[data-ui-sref], td[ng-click]').length > 0;
                if (hasLink) {
                    angular.element(e).addClass('table-row--with-link');
                }
            });
        });

        // *
        // Create pagination scope
        const paginationScope = $scope.$new(true);
        paginationScope.pageSizes = pageSizes;
        paginationScope.pageSize = this.pagination.pageSize;

        paginationScope.$watch('pageSize', (nextValue, prevValue) => {
            this.pagination.pageSize = nextValue;
            if (prevValue !== nextValue) {
                this.goToPage(1);
                this.onChange();
            }
        });

        const footer = $compile(footerTemplate)(paginationScope);
        this.paging = footer.find('span.table__paging');
        this.numberOfRows = footer.find('span.table__nbrows');
        $element.after(footer);

        // *
        // Create loader scope
        const loaderScope = $scope.$new(true);
        loaderScope.loading = $scope.loading || false;
        const loader = $compile(loaderTemplate)(loaderScope);
        this._loaderScope = loaderScope;
        $element.append(loader);
    }

    getDefaultSort() {
        const { sort } = this._$scope;
        if (sort && angular.isObject(sort)) {
            return { ...sort };
        }

        return {
            column: '',
            order: -1,
        };
    }

    getPageNumber() {
        const { p } = this._$stateParams;

        // Get page number from query string params
        let startPage = p ? parseInt(p) : 1;
        if (!angular.isNumber(startPage) || isNaN(startPage)) {
            startPage = 1;
        }

        // Safety check
        if (this.pagination && startPage > this.pagination.nbPages) {
            startPage = 1;
        }

        return startPage;
    }

    setPage(p) {
        let page = parseInt(p, 10);
        if (!page || isNaN(page)) {
            page = 1;
        }

        // Update state params
        if (page !== parseInt(this._$stateParams.p)) {
            this._$timeout(() => {
                this._$state.go('.', { p: page > 1 ? page : null });
            });
        }

        this.pagination.page = page;
        this.createPageLinks();
    }

    goToPage(pageIndex, forceOnChange = false) {
        let page = parseInt(pageIndex, 10);
        if (!page || isNaN(page)) {
            page = 1;
        }

        if (this.pagination.page === page && !forceOnChange) {
            return;
        }

        this.setPage(pageIndex);
        this.toggleLoader(true);
        this.onChange();
    }

    onChange() {
        const { sort, pagination } = this;
        const { page, pageSize } = pagination;

        // Trigger change to the parent
        if (this._$scope.onChange) {
            this._$scope.onChange({ page: page, pageSize: pageSize, sort: sort });
        }
    }

    updateTable() {
        this.computeTotalPages();
        this.createPageLinks();
        this.createNbRowsSpan();
        this.updateContent();
    }

    updateContent() {
        const rows = this.getRows();
        this.toggleLoader(false);
        this._$scope.$parent[this.currentPage] = rows;
    }

    createNbRowsSpan() {
        this.numberOfRows.contents().detach();
        if (this._$scope.total !== 0) {
            const line = angular.element(`<span> | ${this.getNbRows()}</span>`);
            this.numberOfRows.append(line);
        }
    }

    createPageLinks() {
        this.paging.contents().detach();
        if (this.pagination.nbPages > 1) {
            const pageLinks = [this.createPreviousLink()];
            if (this.pagination.nbPages <= this.paginationWidth) {
                for (let i = 1; i <= this.pagination.nbPages; i++) {
                    pageLinks.push(this.createPageLink(i));
                }
            } else {
                this.addPageLinksWithEllipses(pageLinks);
            }

            pageLinks.push(this.createNextLink());
            this.paging.append(pageLinks);
        }
    }

    addPageLinksWithEllipses(pageLinks) {
        let before = Math.floor((this.paginationWidth - 1) / 2);
        let after = Math.floor(this.paginationWidth / 2);
        let ellipsisBefore = true;
        let ellipsisAfter = true;
        let start, end;
        if (before > this.pagination.page - 1) {
            after += before - this.pagination.page + 1;
            before = this.pagination.page - 1;
            ellipsisBefore = false;
            start = 1;
        } else if (after > this.pagination.nbPages - this.pagination.page) {
            before += after - this.pagination.nbPages + this.pagination.page;
            after = this.pagination.nbPages - this.pagination.page;
            ellipsisAfter = false;
            end = this.pagination.nbPages;
        }

        start = start || this.pagination.page - before + 2;
        end = end || this.pagination.page + after - 2;
        if (ellipsisBefore) {
            pageLinks.push(this.createPageLink(1));
            pageLinks.push(this.createPageLink());
        }

        for (let i = start; i <= end; i++) {
            pageLinks.push(this.createPageLink(i));
        }
        if (ellipsisAfter) {
            pageLinks.push(this.createPageLink());
            pageLinks.push(this.createPageLink(this.pagination.nbPages));
        }
    }

    createPageLink(page) {
        const link = angular.element(`<span class="table__paging-item">${page || '...'}</span>`);
        if (page) {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.goToPage(page);
                });
            });
        }

        if (page === this.pagination.page) {
            link.addClass('table__paging-item--active');
        }

        return link;
    }

    createPreviousLink() {
        const link = angular.element(
            `<span class="table__paging-item table__paging-item--prev" title="${this._translate(
                'shared.toPrevious',
            )}">${this._translate('shared.previous')}</span>`,
        );
        if (this.pagination.page === 1) {
            link.attr('disabled');
        } else {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.goToPage(this.pagination.page - 1);
                });
            });
        }

        return link;
    }

    createNextLink() {
        const link = angular.element(
            `<span class="table__paging-item table__paging-item--next" title="${this._translate(
                'shared.toNext',
            )}">${this._translate('shared.next')}</span>`,
        );
        if (this.pagination.page === this.pagination.nbPages) {
            link.attr('disabled');
        } else {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.goToPage(this.pagination.page + 1);
                });
            });
        }

        return link;
    }

    getNbPages(pageSize = 20) {
        const total = this._$scope.total || this.getRows().length;

        return Math.max(1, Math.ceil(total / pageSize));
    }

    getNbRows() {
        const nbRows = this._$scope.total;

        return nbRows;
    }

    computeTotalPages() {
        this.pagination.nbPages = this.getNbPages(this.pagination.pageSize);
    }

    getRows() {
        return this._$scope.rows || [];
    }

    toggleLoader(value) {
        if (typeof value === 'boolean') {
            this._loaderScope.loading = value;
        } else {
            this._loaderScope.loading = !this._loaderScope.loading;
        }
    }

    toggleSort(sortColumn) {
        if (this.sort.column === sortColumn) {
            this.sort.order = this.sort.order === 1 ? -1 : 1;
        } else {
            this.sort.order = -1;
            this.sort.column = sortColumn;
        }

        this.updateSortIcons();
        this.goToPage(1, true);
    }

    updateSortIcons() {
        this.sortColumns.forEach((sortColumn) => {
            sortColumn.find('span[class^="table__content-header-cell"]').detach();
            if (this.sort.column === sortColumn.attr('cc-table-sort')) {
                sortColumn.append(
                    angular.element(
                        this.sort.order === 1
                            ? '<span class="table__content-header-cell--asc"></span>'
                            : '<span class="table__content-header-cell--desc"></span>',
                    ),
                );
            }
        });
    }
}

angular.module('dotic').controller('ccTableXhrController', CcTableXhrController);
