import { FMEJobEnum } from '../../../models/deposit.model';

// phases
const labelsPhases = ['PRE', 'DIA', 'AVP', 'PRO', 'EXE', 'REC', 'MCO'];
const backgroundColorPhases = ['#f06292', '#9c27b0', '#5069a9', '#b3e5fc', '#039be5', '#00a889', '#edb349'];
const hoverBackgroundColorPhases = ['#ff508c', '#a816c1', '#4564b4', '#aae8ff', '#00a5ff', '#00bd96', '#ffb935'];

// status
const labelsStatus = ['Succès', 'Échec'];
const backgroundColorStatus = ['#00a889', '#dd3073 '];
const hoverBackgroundColorStatus = ['#00866e', '#b91e5a '];

class DashboardSuperAdminController {
    constructor(
        $chartService,
        $filter,
        $phaseService,
        $scope,
        $state,
        $tableService,
        $toasterService,
        $uibModal,
        superAdminProvider,
        userProvider,
    ) {
        this._$scope = $scope;
        this._$state = $state;
        this._$uibModal = $uibModal;
        this._$toasterService = $toasterService;
        this._$tableService = $tableService;
        this._$phaseService = $phaseService;
        this._superAdminProvider = superAdminProvider;
        this._userProvider = userProvider;
        this._translate = $filter('translate');

        this.loading = true;
        this.totalDepositPlugs = 0;
        this.companyDataSorted = [];

        this.hasIdCompany = null;
        this.hasUserOpen = false;
        this.companyDetail = null;

        this.sumTotalDepositCompany = 0;
        this.sumTotalControlCompany = 0;
        this.sumTotalLoadCompany = 0;

        this.filterAdmin = [];
        this.filterUser = [];
        this.filterExtendedUser = [];

        this.filterOptions = {
            phases: [],
            companies: [],
            users: [],
        };

        this.filter = {
            phase: $state.params.phase || '',
            company: $state.params.company || '',
            user: $state.params.user || '',
            createdAtStart: $state.params.createdAtStart || '',
            createdAtEnd: $state.params.createdAtEnd || '',
            groupByLastVersion: $state.params.groupByLastVersion || false,
        };

        this.depositsChartData = null;
        this.depositStatusChartData = null;
        this.depositJobChartData = null;
        this.phasesLoadChartData = null;
        this.phasesControlChartData = null;
        this.depositsPhasesChartData = null;
        this.depositStatusByCompanyChartData = null;
        this.depositJobByCompanyChartData = null;
        this.phasesLoadByCompanyChartData = null;
        this.phasesControlByCompanyChartData = null;

        this.sumEmployesTree = null;

        this.horizontalBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(),
        };

        this.horizontalBarChartByUserOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(),
        };

        $scope.$watchGroup(
            [
                '$ctrl.filter.phase',
                '$ctrl.filter.company',
                '$ctrl.filter.user',
                '$ctrl.filter.createdAtStart',
                '$ctrl.filter.createdAtEnd',
                '$ctrl.filter.groupByLastVersion',
            ],
            (next, prev) => {
                if (prev !== next) {
                    this.setScopeDataWithFilters();
                }
            },
        );

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('shared.dashboard'),
                    href: $state.href('app.dashboardSuperAdmin'),
                },
            ],
        });
    }

    $onInit() {
        const promises = [this.loadData(), this.getUsers()];

        Promise.all(promises).then(() => {
            this.prepareFilters();
        });

        this.tableDetail = this._$tableService.detail();
    }

    loadData() {
        this.loading = true;

        return this._superAdminProvider
            .getApplicationStats({
                groupByLastVersion: this.filter.groupByLastVersion,
                phase: this.filter.phase,
                company: this.filter.company,
                user: this.filter.user,
                createdAtStart: this.filter.createdAtStart,
                createdAtEnd: this.filter.createdAtEnd,
            })
            .then((result) => {
                this.totalDepositPlugs = result.totalDepositPlugs;
                this.totalCompanies = result.totalCompanies;
                this.totalUsers = result.totalUsers;
                this.totalUsersCompanies = result.totalUsersCompanies;

                const depositTypeTotal = result.companies.map((company) => {
                    const stats = this.groupDepositStats(company.depositStats);

                    return { ...company, ...stats };
                });

                const countDataCompany = depositTypeTotal.map((company) => {
                    const loadDeposit = company.resultAgg.load;
                    const controlDeposit = company.resultAgg.control;
                    const succeedDeposit = company.resultAgg.SUCCESS;
                    const failedDeposit = company.resultAgg.FME_FAILURE;

                    const arrayPhases = company.resultAggregationPhases;
                    const prePhaseTotal = arrayPhases.PRE;
                    const diaPhaseTotal = arrayPhases.DIA;
                    const avpPhaseTotal = arrayPhases.AVP;
                    const proPhaseTotal = arrayPhases.PRO;
                    const exePhaseTotal = arrayPhases.EXE;
                    const recPhaseTotal = arrayPhases.REC;
                    const mcoPhaseTotal = arrayPhases.MCO;

                    const arrayPhasesReport = company.resultAggReportPhase;
                    const prePhaseReport = arrayPhasesReport.PRE;
                    const diaPhaseReport = arrayPhasesReport.DIA;
                    const avpPhaseReport = arrayPhasesReport.AVP;
                    const proPhaseReport = arrayPhasesReport.PRO;
                    const exePhaseReport = arrayPhasesReport.EXE;
                    const recPhaseReport = arrayPhasesReport.REC;
                    const mcoPhaseReport = arrayPhasesReport.MCO;

                    const arrayPhasesLoad = company.resultAggLoadPhase;
                    const prePhaseLoad = arrayPhasesLoad.PRE;
                    const diaPhaseLoad = arrayPhasesLoad.DIA;
                    const avpPhaseLoad = arrayPhasesLoad.AVP;
                    const proPhaseLoad = arrayPhasesLoad.PRO;
                    const exePhaseLoad = arrayPhasesLoad.EXE;
                    const recPhaseLoad = arrayPhasesLoad.REC;
                    const mcoPhaseLoad = arrayPhasesLoad.MCO;

                    const totalDeposit = company.depositStats.reduce((acc, item) => {
                        return acc + item.count;
                    }, 0);

                    const totalPlug = company.depositStats.reduce((acc, item) => {
                        return acc + item.countPlugs;
                    }, 0);

                    const sortedDeposit = company.depositStats.sort((a, b) => {
                        return new Date(b.lastDeposit) - new Date(a.lastDeposit);
                    });

                    const lastDeposit = sortedDeposit.length > 0 ? sortedDeposit[0].lastDeposit : null;

                    const totalControlLoadDeposit = controlDeposit + loadDeposit;

                    return {
                        ...company,
                        prePhaseReport,
                        diaPhaseReport,
                        avpPhaseReport,
                        proPhaseReport,
                        exePhaseReport,
                        recPhaseReport,
                        mcoPhaseReport,
                        prePhaseLoad,
                        diaPhaseLoad,
                        avpPhaseLoad,
                        proPhaseLoad,
                        exePhaseLoad,
                        recPhaseLoad,
                        mcoPhaseLoad,
                        prePhaseTotal,
                        diaPhaseTotal,
                        avpPhaseTotal,
                        proPhaseTotal,
                        exePhaseTotal,
                        recPhaseTotal,
                        mcoPhaseTotal,
                        controlDeposit,
                        loadDeposit,
                        succeedDeposit,
                        failedDeposit,
                        totalDeposit,
                        totalPlug,
                        lastDeposit,
                        totalControlLoadDeposit,
                    };
                });

                // Trie par nom entreprise
                this.companyDataSorted = countDataCompany.sort((a, b) =>
                    a.name.toString().localeCompare(b.name.toString()),
                );

                // Calcul somme des dépôts réels des entreprises
                this.sumTotalDepositCompany = this.companyDataSorted.reduce((acc, item) => {
                    return acc + item.totalDeposit;
                }, 0);

                // Calcul somme des dépôts réels des entreprises
                this.sumTotalControlCompany = this.companyDataSorted.reduce((acc, item) => {
                    return acc + item.controlDeposit;
                }, 0);

                // Calcul somme des dépôts réels des entreprises
                this.sumTotalLoadCompany = this.companyDataSorted.reduce((acc, item) => {
                    return acc + item.loadDeposit;
                }, 0);

                this.loadDepositGlobalChart();
                this.loadDoughnutGlobalCharts();
                this.loadDepositPhasesGlobalChart();
                this.loading = false;
            });
    }

    groupDepositStats(stats) {
        const defaultCounts = {
            SUCCESS: 0,
            FME_FAILURE: 0,
            control: 0,
            load: 0,
        };

        const defaultPhaseCounts = {
            PRE: 0,
            DIA: 0,
            AVP: 0,
            PRO: 0,
            EXE: 0,
            REC: 0,
            MCO: 0,
        };

        const resultAgg = stats.reduce((acc, item) => {
            acc[item.job] += item.count;
            acc[item.status] += item.count;

            return acc;
        }, defaultCounts);

        const resultAggLoadPhase = stats.reduce(
            (acc, depot) => {
                if (depot.job === FMEJobEnum.LOAD) {
                    acc[depot.phase] += depot.count;
                }

                return acc;
            },
            { ...defaultPhaseCounts },
        );

        const resultAggReportPhase = stats.reduce(
            (acc, depot) => {
                if (depot.job === FMEJobEnum.CONTROL) {
                    acc[depot.phase] += depot.count;
                }

                return acc;
            },
            { ...defaultPhaseCounts },
        );

        const resultAggregationPhases = stats.reduce(
            (acc, item) => {
                acc[item.phase] += item.count;

                return acc;
            },
            { ...defaultPhaseCounts },
        );

        return {
            resultAgg,
            resultAggregationPhases,
            resultAggLoadPhase,
            resultAggReportPhase,
        };
    }

    // Charts global entreprise

    loadDepositGlobalChart() {
        // Map all status and job
        this.companyDataSorted.sort(
            (a, b) => parseInt(b.totalControlLoadDeposit, 10) - parseInt(a.totalControlLoadDeposit, 10),
        );

        // Array of all status SUCCESS deposit by Company
        const arraySucceed = this.companyDataSorted.map(({ resultAgg }) => resultAgg.SUCCESS);
        // Array of all status Failed deposit by Company
        const arrayFailed = this.companyDataSorted.map(({ resultAgg }) => resultAgg.FME_FAILURE);
        // Array of all job control deposit by Company
        const arrayControl = this.companyDataSorted.map(({ resultAgg }) => resultAgg.control);
        // Array of all job load deposit by Company
        const arrayLoad = this.companyDataSorted.map(({ resultAgg }) => resultAgg.load);

        // Name of company
        const labelsCompany = this.companyDataSorted.map((element) => element.name.toUpperCase());

        // Chart stacked
        this.depositsChartData = {
            labels: labelsCompany,
            datasets: [
                {
                    stack: 'status',
                    label: this._translate('shared.deliverableValidated'),
                    backgroundColor: '#00997d',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arraySucceed,
                },
                {
                    stack: 'status',
                    label: this._translate('shared.deliverableError'),
                    backgroundColor: '#dd3073',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayFailed,
                },
                {
                    stack: 'job',
                    label: this._translate('shared.controlDeposit'),
                    backgroundColor: '#0b99e0',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayControl,
                },
                {
                    stack: 'job',
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: '#edb349',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayLoad,
                },
            ],
        };
    }

    loadDoughnutGlobalCharts() {
        // Array depot par status et job
        const dataDepositStatus = [];
        const dataDepositJob = [];

        // Array Etape par job
        const dataPhasesLoad = [];
        const dataPhasesReport = [];

        // Data pour les status
        const sumTotalSucceedCompany = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.succeedDeposit;
        }, 0);

        const sumTotalFailedCompany = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.failedDeposit;
        }, 0);

        dataDepositStatus.push(sumTotalSucceedCompany, sumTotalFailedCompany);

        // Data pour les controls

        dataDepositJob.push(this.sumTotalControlCompany, this.sumTotalLoadCompany);

        // Data pour les phases / Load

        const sumPreLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.prePhaseLoad;
        }, 0);
        const sumDiaLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.diaPhaseLoad;
        }, 0);
        const sumAvpLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.avpPhaseLoad;
        }, 0);
        const sumProLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.proPhaseLoad;
        }, 0);
        const sumExeLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.exePhaseLoad;
        }, 0);
        const sumRecLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.recPhaseLoad;
        }, 0);
        const sumMcoLoad = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.mcoPhaseLoad;
        }, 0);

        dataPhasesLoad.push(sumPreLoad, sumDiaLoad, sumAvpLoad, sumProLoad, sumExeLoad, sumRecLoad, sumMcoLoad);

        // // Data pour les phases / Control

        const sumPreReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.prePhaseReport;
        }, 0);
        const sumDiaReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.diaPhaseReport;
        }, 0);
        const sumAvpReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.avpPhaseReport;
        }, 0);
        const sumProReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.proPhaseReport;
        }, 0);
        const sumExeReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.exePhaseReport;
        }, 0);
        const sumRecReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.recPhaseReport;
        }, 0);
        const sumMcoReport = this.companyDataSorted.reduce((acc, item) => {
            return acc + item.mcoPhaseReport;
        }, 0);

        dataPhasesReport.push(
            sumPreReport,
            sumDiaReport,
            sumAvpReport,
            sumProReport,
            sumExeReport,
            sumRecReport,
            sumMcoReport,
        );

        this.depositStatusChartData = {
            bigLegend: {
                figure: this.sumTotalDepositCompany,
                name:
                    this.sumTotalDepositCompany > 1
                        ? this._translate('shared.statusPlu')
                        : this._translate('shared.status'),
            },
            labels: labelsStatus,
            datasets: [
                {
                    data: dataDepositStatus,
                    backgroundColor: backgroundColorStatus,
                    hoverBackgroundColor: hoverBackgroundColorStatus,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        this.depositJobChartData = {
            bigLegend: {
                figure: this.sumTotalDepositCompany,
                name:
                    this.sumTotalDepositCompany > 1
                        ? this._translate('shared.states')
                        : this._translate('shared.state'),
            },
            labels: [
                this._translate('shared.control', {
                    COUNT: this.sumTotalControlCompany,
                }),
                this.sumTotalLoadCompany > 1 ? this._translate('shared.storages') : this._translate('shared.storage'),
            ],
            datasets: [
                {
                    data: dataDepositJob,
                    backgroundColor: ['#0b99e0', '#edb349'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        this.phasesLoadChartData = {
            bigLegend: {
                figure: this.sumTotalLoadCompany,
                name:
                    this.sumTotalLoadCompany > 1
                        ? this._translate('shared.storages')
                        : this._translate('shared.storage'),
            },
            labels: labelsPhases,
            datasets: [
                {
                    data: dataPhasesLoad,
                    backgroundColor: backgroundColorPhases,
                    hoverBackgroundColor: hoverBackgroundColorPhases,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        this.phasesControlChartData = {
            bigLegend: {
                figure: this.sumTotalControlCompany,
                name: this._translate('shared.control', {
                    COUNT: this.sumTotalControlCompany,
                }),
            },
            labels: labelsPhases,
            datasets: [
                {
                    data: dataPhasesReport,
                    backgroundColor: backgroundColorPhases,
                    hoverBackgroundColor: hoverBackgroundColorPhases,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    loadDepositPhasesGlobalChart() {
        this.companyDataSorted.sort(
            (a, b) => parseInt(b.totalControlLoadDeposit, 10) - parseInt(a.totalControlLoadDeposit, 10),
        );

        // Array toutes les phases
        const arrayPhases = this.companyDataSorted.map((element) => element.resultAggregationPhases);

        // Array of all phase PRE by Company
        const arrayPRE = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.PRE);
        // Array of all phase DIA by Company
        const arrayDIA = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.DIA);
        // Array of all phase AVP by Company
        const arrayAVP = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.AVP);
        // Array of all phase PRO by Company
        const arrayPRO = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.PRO);
        // Array of all phase EXE by Company
        const arrayEXE = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.EXE);
        // Array of all phase REC by Company
        const arrayREC = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.REC);
        // Array of all phase MCO by Company
        const arrayMCO = arrayPhases.map((resultAggregationPhases) => resultAggregationPhases.MCO);

        const labelsCompany = this.companyDataSorted.map((element) => element.name.toUpperCase());

        this.depositsPhasesChartData = {
            labels: labelsCompany,
            datasets: [
                {
                    label: 'PRE',
                    backgroundColor: '#f06292',
                    hoverBackgroundColor: '#ff508c',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayPRE,
                },
                {
                    label: 'DIA',
                    backgroundColor: '#9c27b0',
                    hoverBackgroundColor: ' #a816c1',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayDIA,
                },
                {
                    label: 'AVP',
                    backgroundColor: '#5069a9',
                    hoverBackgroundColor: '#4564b4',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayAVP,
                },
                {
                    label: 'PRO',
                    backgroundColor: '#b3e5fc ',
                    hoverBackgroundColor: '#aae8ff ',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayPRO,
                },
                {
                    label: 'EXE',
                    backgroundColor: '#039be5 ',
                    hoverBackgroundColor: '#00a5ff ',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayEXE,
                },
                {
                    label: 'REC',
                    backgroundColor: '#00a889 ',
                    hoverBackgroundColor: '#00bd96 ',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayREC,
                },
                {
                    label: 'MCO',
                    backgroundColor: '#edb349',
                    hoverBackgroundColor: '#ffb935',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: arrayMCO,
                },
            ],
        };
    }

    // Charts by company

    loadDoughnutByCompanyCharts(company) {
        const groups = this.groupDepositStats(company.depositStats);

        const statusByCompany = [groups.resultAgg.SUCCESS, groups.resultAgg.FME_FAILURE];
        const jobByCompany = [groups.resultAgg.control, groups.resultAgg.load];

        const phaseLoadCompany = Object.values(groups.resultAggLoadPhase);
        const phaseReportCompany = Object.values(groups.resultAggReportPhase);

        // Depot par Statut - entreprise
        this.depositStatusByCompanyChartData = {
            bigLegend: {
                figure: groups.resultAgg.control + groups.resultAgg.load,
                name: this._translate('shared.deliverable', { COUNT: statusByCompany.length }),
            },
            labels: labelsStatus,
            datasets: [
                {
                    data: statusByCompany,
                    backgroundColor: backgroundColorStatus,
                    hoverBackgroundColor: hoverBackgroundColorStatus,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        // Depot par Etat - entreprise
        this.depositJobByCompanyChartData = {
            bigLegend: {
                figure: groups.resultAgg.control + groups.resultAgg.load,
                name: this._translate('shared.deliverable', { COUNT: jobByCompany.length }),
            },
            labels: [
                this._translate('shared.control', {
                    COUNT: groups.resultAgg.control,
                }),
                groups.resultAgg.load > 1 ? this._translate('shared.storages') : this._translate('shared.storage'),
            ],
            datasets: [
                {
                    data: jobByCompany,
                    backgroundColor: ['#0b99e0', '#edb349'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
        // Depot par Etape  stocké- entreprise
        this.phasesLoadByCompanyChartData = {
            bigLegend: {
                figure: groups.resultAgg.load,
                name:
                    groups.resultAgg.load > 1 ? this._translate('shared.storages') : this._translate('shared.storage'),
            },
            labels: labelsPhases,
            datasets: [
                {
                    data: phaseLoadCompany,
                    backgroundColor: backgroundColorPhases,
                    hoverBackgroundColor: hoverBackgroundColorPhases,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        // Depot par Etape  controlé- entreprise
        this.phasesControlByCompanyChartData = {
            bigLegend: {
                figure: groups.resultAgg.control,
                name: this._translate('shared.control', {
                    COUNT: groups.resultAgg.control,
                }),
            },
            labels: labelsPhases,
            datasets: [
                {
                    data: phaseReportCompany,
                    backgroundColor: backgroundColorPhases,
                    hoverBackgroundColor: hoverBackgroundColorPhases,
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    loadDepositByCompanyChart(company) {
        const groups = this.groupDepositStats(company.depositStats);

        // Array of all status SUCCESS deposit by Company
        const succeedDataCompany = [groups.resultAgg.SUCCESS];
        // Array of all status Failed deposit by Company
        const failedDataCompany = [groups.resultAgg.FME_FAILURE];
        // Array of all job control deposit by Company
        const controlDataCompany = [groups.resultAgg.control];
        // Array of all job load deposit by Company
        const loadDataCompany = [groups.resultAgg.load];

        // Name of company
        const labelCompany = [company.name.toUpperCase()];

        const baseOption = {
            stack: 'status',
            borderColor: '#fff',
            borderWidth: 1,
        };

        // Chart stacked
        this.depositsByCompanyChartData = {
            labels: labelCompany,
            datasets: [
                {
                    ...baseOption,
                    label: this._translate('shared.deliverableValidated'),
                    backgroundColor: '#00997d',
                    data: succeedDataCompany,
                },
                {
                    ...baseOption,
                    label: this._translate('shared.deliverableError'),
                    backgroundColor: '#dd3073',
                    data: failedDataCompany,
                },
                {
                    ...baseOption,
                    stack: 'job',
                    label: this._translate('shared.controlDeposit'),
                    backgroundColor: '#0b99e0',
                    data: controlDataCompany,
                },
                {
                    ...baseOption,
                    stack: 'job',
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: '#edb349',
                    data: loadDataCompany,
                },
            ],
        };
    }

    loadDepositPhasesByCompany(company) {
        const groups = this.groupDepositStats(company.depositStats);

        // Array of all phase PRE by Company
        const dataCompanyPRE = [groups.resultAggregationPhases.PRE];

        // Array of all phase DIA by Company
        const dataCompanyDIA = [groups.resultAggregationPhases.DIA];
        // Array of all phase AVP by Company
        const dataCompanyAVP = [groups.resultAggregationPhases.AVP];
        // Array of all phase PRO by Company
        const dataCompanyPRO = [groups.resultAggregationPhases.PRO];
        // Array of all phase EXE by Company
        const dataCompanyEXE = [groups.resultAggregationPhases.EXE];
        // Array of all phase REC by Company
        const dataCompanyREC = [groups.resultAggregationPhases.REC];
        // Array of all phase MCO by Company
        const dataCompanyMCO = [groups.resultAggregationPhases.MCO];

        const labelCompany = [company.name.toUpperCase()];

        const phaseChartBaseOption = {
            borderColor: '#fff',
            borderWidth: 1,
            barPercentage: 0.7,
        };

        this.depositsPhasesByCompanyChartData = {
            labels: labelCompany,
            datasets: [
                {
                    ...phaseChartBaseOption,
                    label: 'PRE',
                    backgroundColor: '#f06292',
                    hoverBackgroundColor: '#ff508c',
                    data: dataCompanyPRE,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'DIA',
                    backgroundColor: '#9c27b0',
                    hoverBackgroundColor: '#a816c1',
                    data: dataCompanyDIA,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'AVP',
                    backgroundColor: '#5069a9',
                    hoverBackgroundColor: '#4564b4',
                    data: dataCompanyAVP,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'PRO',
                    backgroundColor: '#b3e5fc',
                    hoverBackgroundColor: '#aae8ff',
                    data: dataCompanyPRO,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'EXE',
                    backgroundColor: '#039be5',
                    hoverBackgroundColor: '#00a5ff',
                    data: dataCompanyEXE,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'REC',
                    backgroundColor: '#00a889',
                    hoverBackgroundColor: '#00bd96',
                    data: dataCompanyREC,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'MCO',
                    backgroundColor: '#edb349',
                    hoverBackgroundColor: '#ffb935',
                    data: dataCompanyMCO,
                },
            ],
        };
    }

    // Charts by users

    loadUserTreeCharts(company) {
        const filterAdmin = company.employees.filter((e) => e.role === 'admin');
        const filterUser = company.employees.filter((e) => e.role === 'user');
        const filterExtendedUser = company.employees.filter((e) => e.role === 'extendedUser');

        this.filterAdmin = filterAdmin;
        this.filterUser = filterUser;
        this.filterExtendedUser = filterExtendedUser;

        // Arbre répartition des employés
        this.sumEmployesTree = {
            parent: {
                icon: 'users-alt-5',
                color: '#0b99e0',
                text: this._translate('shared.employee', { COUNT: company.employees.length }).toLowerCase(),
                value: company.employees.length,
            },
            children: [
                {
                    icon: 'user-male',
                    color: '#e971b1',
                    text: this._translate('role.admin', {
                        COUNT: filterAdmin.length,
                    }),
                    value: filterAdmin.length,
                },
                {
                    icon: 'user-male',
                    color: '#5069a9',
                    text: this._translate('role.user', {
                        COUNT: filterUser.length,
                    }),
                    value: filterUser.length,
                },
                {
                    icon: 'user-male',
                    color: '#5069a9',
                    text: this._translate('role.extendedUser', {
                        COUNT: filterExtendedUser.length,
                    }),
                    value: filterExtendedUser.length,
                },
            ],
        };
    }

    loadDepositByUserChart(company) {
        const depositTotalByUser = company.depositStats.reduce((acc, depot) => {
            const users = depot.users.map((user) => {
                const dataUser = company.employees.find((e) => e.id === user.userId);

                return {
                    ...user,
                    fullname: dataUser ? dataUser.fullname : this._translate('shared.oldUser'),
                    job: depot.job,
                    status: depot.status,
                    phase: depot.phase,
                };
            });

            return acc.concat(users);
        }, []);

        const groupByUser = depositTotalByUser.reduce((acc, item) => {
            acc[item.userId] = [...(acc[item.userId] || []), item];

            return acc;
        }, []);

        const depositTypeTotalByUser = Object.values(groupByUser).map((userResults) => {
            const defaultCountsByUser = {
                SUCCESS: 0,
                FME_FAILURE: 0,
                QUEUED: 0,
                SUBMITTED: 0,
                control: 0,
                load: 0,
            };

            const defaultPhaseCountsByUser = {
                PRE: 0,
                DIA: 0,
                AVP: 0,
                PRO: 0,
                EXE: 0,
                REC: 0,
                MCO: 0,
            };

            const resultDepositByUser = userResults.reduce((acc, item) => {
                acc[item.job] += item.count;
                acc[item.status] += item.count;

                return acc;
            }, defaultCountsByUser);

            const resultPhaseByUser = userResults.reduce((acc, depot) => {
                acc[depot.phase] += depot.count;

                return acc;
            }, defaultPhaseCountsByUser);

            return {
                id: userResults[0].userId,
                fullname: userResults[0].fullname,
                resultPhaseByUser,
                resultDepositByUser,
                sumDepositByUser: resultDepositByUser.control + resultDepositByUser.load,
            };
        });

        const sortedDataTotaldepositByUser = depositTypeTotalByUser.sort(
            (a, b) => parseInt(b.sumDepositByUser, 10) - parseInt(a.sumDepositByUser, 10),
        );
        // Map all status and job
        const arrayStatusByUser = sortedDataTotaldepositByUser.map((e) => e.resultDepositByUser);
        const arraySucceedByUser = arrayStatusByUser.map((resultDepositByUser) => resultDepositByUser.SUCCESS);
        const arrayFailedByUser = arrayStatusByUser.map((resultDepositByUser) => resultDepositByUser.FME_FAILURE);
        const arrayControlByUser = arrayStatusByUser.map((resultDepositByUser) => resultDepositByUser.control);
        const arrayLoadByUser = arrayStatusByUser.map((resultDepositByUser) => resultDepositByUser.load);

        // Map all phases by job
        const arrayPhaseByUser = sortedDataTotaldepositByUser.map((e) => e.resultPhaseByUser);
        const arrayPreByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.PRE);
        const arrayDiaByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.DIA);
        const arrayAvpByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.AVP);
        const arrayProByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.PRO);
        const arrayExeByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.EXE);
        const arrayRecByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.REC);
        const arrayMcoByUser = arrayPhaseByUser.map((resultPhaseByUser) => resultPhaseByUser.MCO);

        // Name of Users
        const labelsUsers = sortedDataTotaldepositByUser.map((e) => e.fullname.toUpperCase());

        const userChartBaseOption = {
            stack: 'status',
            borderColor: '#fff',
            borderWidth: 1,
        };

        // Chart stacked
        this.depositsByUserChartData = {
            labels: labelsUsers,
            datasets: [
                {
                    ...userChartBaseOption,
                    label: this._translate('shared.deliverableValidated'),
                    backgroundColor: '#00997d',
                    data: arraySucceedByUser,
                },
                {
                    ...userChartBaseOption,
                    label: this._translate('shared.deliverableError'),
                    backgroundColor: '#dd3073',
                    data: arrayFailedByUser,
                },
                {
                    ...userChartBaseOption,
                    stack: 'job',
                    label: this._translate('shared.controlDeposit'),
                    backgroundColor: '#0b99e0',
                    data: arrayControlByUser,
                },
                {
                    ...userChartBaseOption,
                    stack: 'job',
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: '#edb349',
                    data: arrayLoadByUser,
                },
            ],
        };

        const phaseChartBaseOption = {
            stack: 'phase',
            borderColor: '#fff',
            borderWidth: 1,
            barPercentage: 0.7,
        };

        this.depositsPhasesByUserChartData = {
            labels: labelsUsers,
            datasets: [
                {
                    ...phaseChartBaseOption,
                    label: 'PRE',
                    backgroundColor: '#f06292',
                    hoverBackgroundColor: '#ff508c',
                    data: arrayPreByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'DIA',
                    backgroundColor: '#9c27b0',
                    hoverBackgroundColor: ' #a816c1',
                    data: arrayDiaByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'AVP',
                    backgroundColor: '#5069a9',
                    hoverBackgroundColor: '#4564b4',
                    data: arrayAvpByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'PRO',
                    backgroundColor: '#b3e5fc ',
                    hoverBackgroundColor: '#aae8ff ',
                    data: arrayProByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'EXE',
                    backgroundColor: '#039be5 ',
                    hoverBackgroundColor: '#00a5ff ',
                    data: arrayExeByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'REC',
                    backgroundColor: '#00a889 ',
                    hoverBackgroundColor: '#00bd96 ',
                    data: arrayRecByUser,
                },
                {
                    ...phaseChartBaseOption,
                    label: 'MCO',
                    backgroundColor: '#edb349',
                    hoverBackgroundColor: '#ffb935',
                    data: arrayMcoByUser,
                },
            ],
        };
    }

    showDetails(company) {
        this.companyDetail = null;
        this.hasUserOpen = false;

        this.tableDetail.toggle(company);

        if (this.tableDetail.isToggled(company)) {
            this._superAdminProvider
                .getCompanyStats(company.id, {
                    groupByLastVersion: this.filter.groupByLastVersion,
                    phase: this.filter.phase,
                    company: this.filter.company,
                    user: this.filter.user,
                    createdAtStart: this.filter.createdAtStart,
                    createdAtEnd: this.filter.createdAtEnd,
                })
                .then((companyStats) => {
                    this.companyDetail = companyStats;
                    this.loadDoughnutByCompanyCharts(companyStats);
                    this.loadUserTreeCharts(companyStats);
                    this.loadDepositPhasesByCompany(companyStats);
                    this.loadDepositByCompanyChart(companyStats);
                    this.loadDepositByUserChart(companyStats);
                });
        }
    }

    toggleDetailByUsers(companyId) {
        this.hasIdCompany = companyId;
        this.hasUserOpen = !this.hasUserOpen;
    }

    toggleLastVersionDeposit() {
        this.filter.groupByLastVersion = !this.filter.groupByLastVersion;
    }

    updateStats() {
        return this.loadData().then(() => {
            this.hasIdCompany = null;

            // load company data if toggle is active
            if (this.companyDetail?.id) {
                this.showDetails(this.companyDetail);
            }
        });
    }

    getUsers() {
        return this._userProvider
            .getAllWithCompanies()
            .then((users) => {
                this.users = users.filter(({ role }) => role !== 'superAdmin');
            })
            .catch(this._$toasterService.error);
    }

    setScopeDataWithFilters() {
        const { phase, company, user, createdAtStart, createdAtEnd, groupByLastVersion } = this.filter;
        this._$state.go('.', {
            phase,
            company,
            user,
            createdAtStart,
            createdAtEnd,
            groupByLastVersion,
        });

        if (this.phase === '') {
            this.phase = null;
        }

        this.updateStats().then(() => {
            this.prepareFilters();
        });
    }

    prepareFilters() {
        const { company } = this.filter;

        const phases = this._$phaseService.getAll().map((phase) => ({ value: phase, key: phase }));

        const companies = this.companyDataSorted.map(({ id, name }) => ({
            key: id,
            value: name,
        }));

        let users = this.users.map(({ id, fullname, email, role }) => ({
            key: id,
            value: [fullname, email, role].join(' - '),
        }));

        if (company && company !== '') {
            const selected = this.companyDataSorted.find(({ id }) => id === company);
            users = users.filter((user) => !selected?.employees || selected.employees.indexOf(user.key) > -1);
        }

        this.filterOptions = {
            ...this.filterOptions,
            users,
            phases,
            companies,
        };
    }

    showCancelFilter() {
        return (
            this.filter.phase ||
            this.filter.company ||
            this.filter.user ||
            this.filter.createdAtStart ||
            this.filter.createdAtEnd
        );
    }

    cancelFilters() {
        this.filter.phase = '';
        this.filter.company = '';
        this.filter.user = '';
        this.filter.createdAtStart = '';
        this.filter.createdAtEnd = '';
    }

    viewDepositList(company) {
        this._superAdminProvider
            .getCompanyDepositList(company.id, {
                groupByLastVersion: this.filter.groupByLastVersion,
                phase: this.filter.phase,
                company: this.filter.company,
                user: this.filter.user,
                createdAtStart: this.filter.createdAtStart,
                createdAtEnd: this.filter.createdAtEnd,
            })
            .then((companyDeposit) => {
                this._$uibModal.open({
                    size: 'lg',
                    component: 'depositListVersionModal',
                    resolve: {
                        company: () => companyDeposit,
                    },
                });
            });
    }
}

angular.module('dotic').controller('DashboardSuperAdminController', DashboardSuperAdminController);
