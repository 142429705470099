import EnvironmentSettingsService from '../../services/environment-settings.service';
import template from './depositDetails.html';

class DepositDetailsController {
    constructor(
        $auth,
        $authorizationService,
        $filter,
        $timeout,
        $toasterService,
        depositProvider,
        userProvider,
        LoaderService,
        RestProvider,
    ) {
        this._translate = $filter('translate');
        this.isOrange = EnvironmentSettingsService.isOrange();
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._depositProvider = depositProvider;
        this._userProvider = userProvider;
        this._LoaderService = LoaderService;
        this._RestProvider = RestProvider;
        this.userId = $auth.getPayload().userId;

        this.depositDetail = {};
        this.user = {};
        this.loading = true;

        this.isAllowed = $authorizationService.isAllowed;
    }

    async $onInit() {
        this.loading = true;

        const id = this.deposit.id || this.deposit._id;

        try {
            this.user = await this._userProvider.get(this.userId);

            await this._depositProvider.getListDetail(id).then((deposit) => {
                this.depositDetail = deposit;

                if (deposit.FMEJobs && deposit.FMEJobs.length > 0) {
                    this.depositDetail.job = deposit.FMEJobs[0];
                }

                const { createdBy, company } = deposit;
                let createdByName = '';

                if (createdBy) {
                    createdByName = createdBy.fullname;
                    if (company.employees && company.employees.indexOf(createdBy.id) === -1) {
                        createdByName = `${createdBy.creditCompany.name} - ${createdByName}`;
                    }
                }

                this.depositDetail.createdByName = createdByName;

                const depositLoadReferenceParentId = this.depositDetail.depositLoadReferenceParentId ?? '';
                if (depositLoadReferenceParentId) {
                    this._depositProvider
                        .get(depositLoadReferenceParentId)
                        .then((data) => {
                            this.parentPostProcessReferenceIdLivrable = data.idLivrable;
                        })
                        .catch(this._$toasterService.error);
                }
            });
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
            this._$timeout(() => (this.loading = false));
        }
    }

    async downloadJobLog() {
        this._LoaderService.open(`
        <p>${this._translate('shared.downloadingFile')}.</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        try {
            await this._RestProvider.downloadFromPath(
                `fmeServers/transformations/jobs/${this.depositDetail.job.id}/logs`,
            );
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
        this._LoaderService.dismiss();
    }
}

angular.module('dotic').component('depositDetails', {
    templateUrl: template,
    controller: DepositDetailsController,
    bindings: {
        deposit: '<',
        referenceIdLivrable: '<',
        jobName: '@',
    },
});
