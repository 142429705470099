import template from './postProcessingForm.html';

class PostProcessingFormPage {
    constructor(
        $filter,
        $scope,
        $timeout,
        $state,
        $stateParams,
        $toasterService,
        companyProvider,
        postProcessingProvider,
    ) {
        this._$scope = $scope;
        this._$timeout = $timeout;
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._postProcessingProvider = postProcessingProvider;

        this.postProcessing = {
            company: [],
        };
        this.companies = [];

        this.textButton = null;

        this.notAllowedCharacters = '\\ / : * ? " < > | & = \' + % # ';

        this.onSavePostProcessingClick = () => {};

        this.postProcessingId = this._$stateParams.postProcessingId;

        this.loading = true;
    }

    $onInit() {
        return this.getCompanies()
            .then((companies) => {
                if (!this.postProcessingId) {
                    this._$scope.$emit('keepPreviousNavigation', {
                        newPage: [
                            {
                                title: this._translate('postProcessing.createPostProcess'),
                                href: this._$state.href('app.postProcessingCreate'),
                            },
                        ],
                        defaultPrevious: {
                            title: this._translate('postProcessing.postProcessingList'),
                            href: this._$state.href('app.postProcessingList'),
                            key: 'postProcessingList',
                        },
                        allowedPreviousKeys: ['postProcessingList'],
                    });

                    this.textButton = this._translate('shared.create');
                    this.onSavePostProcessingClick = this.createPostProcessing;
                    this._$timeout(() => {
                        this.companies = companies;
                        this.loading = false;
                    });
                } else {
                    this._$scope.$emit('keepPreviousNavigation', {
                        newPage: [
                            {
                                title: this._translate('postProcessing.editPostProcessing'),
                                href: this._$state.href('app.postProcessingEdit'),
                            },
                        ],
                        defaultPrevious: {
                            title: this._translate('postProcessing.postProcessingList'),
                            href: this._$state.href('app.postProcessingList'),
                            key: 'postProcessingList',
                        },
                        allowedPreviousKeys: ['postProcessingList'],
                    });
                    this.textButton = this._translate('shared.save');
                    this.onSavePostProcessingClick = this.updatePostProcessing;

                    this.getPostProcessing()
                        .then(() => {
                            this._$timeout(() => {
                                this.companies = companies;
                                this.loading = false;

                                this.postProcessingPath = this.companies
                                    .filter((company) => this.postProcessing.company.includes(company.id))
                                    .map((company) => ({
                                        postProcessingPayload: `${company.id}${this.postProcessing.id}`,
                                        companyName: company.name.toUpperCase(),
                                    }));
                            });
                        })
                        .catch(this._$toasterService.error);
                }
            })
            .catch(this._$toasterService.error);
    }

    getCompanies() {
        return this._companyProvider
            .getAll()
            .then((companies) => {
                return this._orderBy(companies, 'name');
            })
            .catch(this._$toasterService.error);
    }

    getPostProcessing() {
        return this._postProcessingProvider
            .get(this.postProcessingId)
            .then((postProcessing) => {
                this.postProcessing = postProcessing;
            })
            .catch(this._$toasterService.error);
    }

    createPostProcessing() {
        this.isButtonWaiting = true;
        const postProcessing = angular.copy(this.postProcessing);

        this._postProcessingProvider
            .create(postProcessing)
            .then(() => {
                this._$toasterService.success(this._translate('postProcessing.postProcessingCreation'));
                this._$state.go('app.postProcessingList');
            })
            .catch(this._$toasterService.error)
            .finally(() => {
                this.isButtonWaiting = false;
            });
    }

    updatePostProcessing() {
        this.isButtonWaiting = true;
        const postProcessing = angular.copy(this.postProcessing);

        this._postProcessingProvider
            .update(this.postProcessingId, postProcessing)
            .then(() => {
                this._$toasterService.success(this._translate('postProcessing.updatedPostProcessing'));
                this._$state.go('app.postProcessingList');
            })
            .catch(this._$toasterService.error)
            .finally(() => {
                this.isButtonWaiting = false;
            });
    }

    setCompany(companiesSelected, value) {
        const { company } = this.postProcessing;

        if (company.indexOf(value) > -1) {
            this.postProcessing.company = company.filter((company) => company !== value);
        } else {
            this.postProcessing.company = [...company, value];
        }

        this.postProcessing.companyToDeleteFiles =
            companiesSelected.length > this.postProcessing.company.length ? value : null;
    }

    listCompanies(companies) {
        return (companies || [])
            .map((company) => this.companies.find(({ id }) => id === company))
            .filter((company) => company)
            .map(({ name }) => name)
            .join(', ');
    }

    isValidFileExtension() {
        const regexPattern = new RegExp('^\\.[A-Za-z]+$');

        return regexPattern.test(this.postProcessing.fileExtension);
    }
}

angular.module('dotic').component('postProcessingFormPage', {
    controller: PostProcessingFormPage,
    templateUrl: template,
});
