import template from './confirmModal.html';

class ConfirmModalComponent {
    close;
    resolve = {
        title: '',
        message: '',
    };

    cancel() {
        this.close({ $value: false });
    }

    confirm() {
        this.close({ $value: true });
    }

    getMessage() {
        return this.resolve.message;
    }

    getTitle() {
        return this.resolve.title;
    }
}

angular.module('dotic').component('confirmModal', {
    templateUrl: template,
    controller: ConfirmModalComponent,
    bindings: {
        close: '&',
        resolve: '<',
    },
});
