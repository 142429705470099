import { defaultPagination } from '../../models/common.model';
import { GridActionEnum } from '../../models/grid.model';
import { HelpersService } from '../../services/helpers.service';
import template from './fillGridList.html';

class FillGridListController {
    companies = [];
    dataModels = [];
    fillGrids = { ...defaultPagination };
    loading = true;
    page = 0;
    pageSize = 10;
    sort = { column: 'createdAt', order: -1 };

    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $gridService,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        companyProvider,
        dataModelProvider,
        fillGridProvider,
    ) {
        this._$deleteService = $deleteService;
        this._translate = $filter('translate');
        this._$gridService = $gridService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._dataModelProvider = dataModelProvider;
        this._fillGridProvider = fillGridProvider;

        this.isAllowed = $authorizationService.isAllowed;

        this.filter = {
            company: $state.params.company || '',
            dataModel: $state.params.dataModel || '',
            isTemplate: $state.params.isTemplate || false,
            search: $state.params.search || '',
        };

        this.tableSelect = $tableService.select();

        $scope.$watchGroup(['$ctrl.filter.company', '$ctrl.filter.dataModel', '$ctrl.filter.search'], async () => {
            this.page = 1;
            $state.go('.', {
                ...this.filter,
            });
            await this.loadData();
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'fillGrids',
                    title: this._translate(
                        this.filter.isTemplate ? 'fillGrid.templateGridsList' : 'fillGrid.workingGridsList',
                    ),
                    href: $location.path(),
                },
            ],
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async loadData() {
        this.loading = true;

        try {
            this.fillGrids = await this._$gridService.search(
                this.page,
                this.pageSize,
                this.sort,
                { ...this.filter, template: this.filter.isTemplate },
                true,
            );

            if (!this.dataModels.length) {
                this.dataModels = HelpersService.toKeyValue(await this._dataModelProvider.getAll());
            }

            if (!this.companies.length) {
                this.companies = HelpersService.toKeyValue(await this._companyProvider.getAll());
            }
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function.
        this._$timeout(() => (this.loading = false));
    }

    async onPaginationChange(page, perPage, sort) {
        this.pageSize = perPage;
        this.page = page;
        this.sort = sort;

        await this.loadData();
    }

    getPossibleActions(fillGrid) {
        return this._$gridService.getPossibleActions(fillGrid, [GridActionEnum.DUPLICATION, GridActionEnum.EDITION]);
    }

    getSelectionType() {
        const removable = this.fillGrids.data.filter((item) => this.isAllowedToRemove(item));

        switch (this.tableSelect.selectionSize()) {
            case removable.length:
                return 'all';
            case 0:
                return 'none';
            case this.pageSize:
                return 'visible';
            default:
                return 'few';
        }
    }

    isAllowedToRemove(item) {
        return item?.countDeposits === 0 || this.isAllowed(['superAdmin']);
    }

    async removeFillGrids() {
        // Get selected rows
        const selectedGrids = this._$gridService.selectDataToRemove(
            this.fillGrids.data,
            this.tableSelect.isActive,
            this.isAllowed,
        );
        if (!selectedGrids.length) {
            return;
        }

        const infoText = await this._$gridService.removeMessage(selectedGrids, this.filter.isTemplate, true);

        const isAccepted = await this._$modalService.triggerRemoveModal(infoText);
        if (!isAccepted) {
            return;
        }

        const selectedGridIds = selectedGrids.map((selectedGrid) => selectedGrid.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedGridIds,
            (gridId) => this._fillGridProvider.delete(gridId),
            'removeModal.successGrid',
            'removeModal.failureGrid',
        );

        await this.loadData();
    }

    async exportGrid(fillGridId) {
        return await this._$gridService.onExportGrid(fillGridId, true);
    }
}

angular.module('dotic').component('fillGridList', {
    controller: FillGridListController,
    templateUrl: template,
});
