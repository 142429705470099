import template from './dataModelList.html';

class DataModelListPage {
    dataModelList = [];
    filteredDataModelList = [];

    constructor(
        $excelService,
        $filter,
        $modalService,
        $scope,
        $state,
        $timeout,
        $toasterService,
        dataModelProvider,
        fillGridProvider,
        gridControlProvider,
    ) {
        this._translate = $filter('translate');
        this._$excelService = $excelService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._dataModelProvider = dataModelProvider;
        this._gridControlProvider = gridControlProvider;
        this._fillGridProvider = fillGridProvider;
        this._$modalService = $modalService;

        this.filter = { search: $state.params.search || '' };

        $scope.$watchGroup(['$ctrl.dataModelList', '$ctrl.filter.search'], () => this.filterDataModel());

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';

            $scope.$emit('updateNavigationUrl');
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'dataModels',
                    title: this._translate('shared.dataModel'),
                    href: $state.href('app.dataModelList'),
                },
            ],
        });
    }

    async $onInit() {
        await this.loadDataModelList();
    }

    filterDataModel() {
        const search = this.filter.search.toLocaleLowerCase();

        this._$state.go('.', {
            search,
        });

        this.filteredDataModelList = this.dataModelList.filter(
            (dataModel) =>
                dataModel === '' ||
                dataModel.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                dataModel.slug.toLowerCase().indexOf(search.toLowerCase()) > -1,
        );
    }

    async hasGrids(dataModelId) {
        const controlGrids = await this._gridControlProvider.listAllControlGrids({
            dataModel: dataModelId,
            page: 0,
            limit: 1,
            template: true,
        });
        const fillGrids = await this._fillGridProvider.listAllFillGrids({
            dataModel: dataModelId,
            page: 0,
            limit: 1,
            template: true,
        });

        return !!fillGrids.totalCount || !!controlGrids.totalCount;
    }

    async loadDataModelList() {
        this.loading = true;

        try {
            this.dataModelList = await this._dataModelProvider.getAll();
            await Promise.all(
                this.dataModelList.map(async (dataModel) => {
                    dataModel.hasGrids = await this.hasGrids(dataModel.id);
                }),
            );
        } catch (error) {
            this._$toasterService.error(error.message);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async removeDataModel(dataModel) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.dataModel', {
                NAME: dataModel.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._dataModelProvider.remove(dataModel.id);
            this._$toasterService.success({
                body: this._translate('removeModal.successDataModel'),
            });
            await this.loadDataModelList();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    onExportDataModelClick(dataModel) {
        const values = dataModel.values.map((value) => ({
            NOM: value.name,
            CODE: value.code,
            LIBELLE: value.label,
            DESCRIPTION: value.description,
        }));

        return this._$excelService.downloadXLSX({ headers: true }, values, `export_${dataModel.slug}`);
    }
}

angular.module('dotic').component('dataModelListPage', {
    templateUrl: template,
    controller: DataModelListPage,
});
