angular.module('dotic').factory('dashboardExtendedProvider', dashboardExtendedProvider);

function dashboardExtendedProvider(ApiProvider, URI_KEYS) {
    return {
        getDataExtended,
    };

    /**
     *  GET /dashboardExtended /:userId/dashboardExtendedData
     * get all data from user company
     * @param {string} userId
     *
     * @param {object|boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @param {string|null} user
     * @param {string|null} timeRange
     * @returns {*} Promise
     */
    function getDataExtended(
        // userId,
        { groupByLastVersion = false, phase = null, company = null, user = null, timeRange = null },
    ) {
        const qs = prepareParams({
            groupByLastVersion,
            phase,
            company,
            user,
            timeRange,
        });

        return ApiProvider.one(URI_KEYS.DASHBOARD_EXTENDED).one('dashboardExtendedData').get(qs);
    }

    /**
     * @param {boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @param {string|null} user
     * @param {string|null} timeRange
     * @returns {{groupByLastVersion: boolean}}
     * @private
     */
    function prepareParams({ groupByLastVersion = false, phase = null, company = null, user = null, timeRange = '' }) {
        const params = { groupByLastVersion };
        if (phase && phase !== '') {
            params.phase = phase;
        }

        if (company && company !== '') {
            params.company = company;
        }

        // Rename param user to createdBy
        if (user && user !== '') {
            params.createdBy = user;
        }

        if (timeRange && timeRange !== '') {
            params.timeRange = timeRange;
        }

        return params;
    }
}
