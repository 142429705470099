import { omit as radashOmit } from 'radash';

import template from './metadata.html';

class FillingStatisticMetadata {
    constructor($filter, $modalService, $timeout) {
        this._$modalService = $modalService;
        this._$timeout = $timeout;
        this._translate = $filter('translate');

        this.loading = true;
        this.metadata = undefined;
    }

    $onInit() {
        if (angular.isUndefined(this.metadata)) {
            return;
        }

        this.metadata = this.cleanMetadata();
        this._$timeout(() => {
            this.loading = false;
        });
    }

    cleanMetadata() {
        return {
            ...radashOmit(this.metadata, [
                'deliveryZonesDeposits',
                'deposits',
                'projectIdsDeposits',
                'projectsDeposits',
            ]),
            deliveryZones: this.joinArray(this.metadata.deliveryZones, this.metadata.deliveryZonesDeposits).map(
                (zone) => ({ id: zone.id, name: zone.name }),
            ),
            fillingStatisticsDeposits: this.preparedDepositsMetadata(this.metadata.deposits),
            projects: this.joinArray(this.metadata.projects, this.metadata.projectsDeposits),
            projectIds: this.joinArray(this.metadata.projectIds, this.metadata.projectIdsDeposits),
        };
    }

    async openProjectIdsModal(projectIds) {
        await this._$modalService.triggerListModal(
            projectIds.map((id) => ({ name: id })),
            this._translate('shared.businessCode'),
        );
    }

    preparedDepositsMetadata(deposits) {
        if (!angular.isArray(deposits)) {
            throw Error("Deposit isn't a array");
        }

        return deposits
            .map((deposit) => ({
                createdAt: deposit.createdAt,
                name: deposit.name,
                version: deposit.version,
                zip: deposit.zip,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    joinArray(arr1, arr2) {
        if (angular.isArray(arr1) && angular.isArray(arr2)) {
            return [...arr1, ...arr2];
        }

        if (angular.isArray(arr1) && !angular.isArray(arr2)) {
            return arr1;
        }

        if (!angular.isArray(arr1) && angular.isArray(arr2)) {
            return arr2;
        }

        return [];
    }
}

angular.module('dotic').component('fillingStatisticMetadata', {
    templateUrl: template,
    controller: FillingStatisticMetadata,
    bindings: {
        metadata: '<',
        loading: '<',
    },
});
