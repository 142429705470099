import template from './exportErrorSIModal.html';

class ExportErrorSIModalComponent {
    constructor($scope) {
        this._$scope = $scope;

        this.errorSIAnomalies = [];

        this.bottomTemplate = false;
        this.loading = true;
        this.message = '';
        this.errorName = '';
        this.uniqueId = '';

        this.filter = {
            search: '',
        };

        $scope.$watchGroup(['$ctrl.filter.search'], () => this.setFilters());
    }

    $onInit() {
        const { errorSI } = this.resolve;

        this.errorSIAnomalies = errorSI.anomalies;
        this.message = errorSI.message;
        this.errorName = errorSI.errorName;

        if (errorSI.uniqueId) {
            this.uniqueId = errorSI.uniqueId;
        }

        this.loading = false;
    }

    filterSearch(search) {
        return (error) =>
            search === '' ||
            (error.code || '').toLowerCase().indexOf(search) > -1 ||
            (error.complementaryInfo1 || '').toLowerCase().indexOf(search) > -1 ||
            (error.complementaryInfo2 || '').toLowerCase().indexOf(search) > -1 ||
            (error.object || '').toLowerCase().indexOf(search) > -1 ||
            (error.objectId || '').toLowerCase().indexOf(search) > -1 ||
            (error.sourceTool || '').toLowerCase().indexOf(search) > -1;
    }

    setFilters() {
        const search = this.filter.search.toLowerCase().trim();
        this.filteredErrorSI = this.errorSIAnomalies.filter(this.filterSearch(search));
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('exportErrorSIModal', {
    templateUrl: template,
    controller: ExportErrorSIModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
