import template from './listModal.html';
class ListModalController {
    constructor($scope, $timeout) {
        this._$scope = $scope;
        this._$timeout = $timeout;

        this.items = [];
        this.loading = true;
    }

    $onInit() {
        const { items, title } = this.resolve;
        this.title = title;
        if (angular.isArray(items)) {
            this.setItems(items);
        } else if (angular.isFunction(items)) {
            this._$timeout(() => {
                items().then((results) => this.setItems(results));
            });
        } else {
            this.loading = false;
        }
    }

    setItems(items) {
        this.items = angular.isArray(items) ? items.filter((item) => item.name) : [];
        this.loading = false;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('listModal', {
    templateUrl: template,
    controller: ListModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
