import template from './simple-table.component.html';

class SimpleTableComponent {
    // columns is of type Array<{ key: string, translation: string }>
    columns = [];
    data = [];
    tableTitle = '';
}

angular.module('dotic').component('simpleTable', {
    bindings: {
        columns: '<',
        data: '<',
        tableTitle: '<',
    },
    controller: SimpleTableComponent,
    templateUrl: template,
});
