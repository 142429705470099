import template from './physical-types.html';

class PhysicalTypesController {
    constructor() {
        this.nro = [];
        this.sumNRO = 0;
        this.NROTree = null;
        this.oldDepositNRO = false;
        this.loaded = false;

        this.sro = [];
        this.sumSRO = 0;
        this.SROTree = null;
        this.oldDepositSRO = false;

        this.requestSRO =
            "SELECT count(*), st_typelog, st_typephy FROM t_sitetech WHERE st_typelog ='SRO' GROUP BY st_typelog,st_typephy";

        this.requestNRO =
            "SELECT count(*), st_typelog as st_typelog_nro, st_typephy FROM t_sitetech WHERE st_typelog ='NRO' GROUP BY st_typelog, st_typephy ";
    }

    $onInit() {
        if (!this.sro || !this.sro[0] || angular.isUndefined(this.sro[0].type)) {
            this.oldDepositSRO = true;
        } else {
            this.oldDepositSRO = false;

            this.sumSRO = this.sro.reduce((acc, cur) => acc + cur.count, 0);

            this.SROTree = {
                parent: {
                    textIcon: 'SRO',
                    color: '#00a889',
                    value: this.sro.reduce((acc, cur) => acc + cur.count, 0),
                },
                children: this.sro.map((item) => {
                    return {
                        icon: 'building',
                        color: '#e971b1',
                        text: item.type.toLocaleLowerCase(),
                        value: item.count,
                    };
                }),
            };
        }

        if (!this.nro || !this.nro[0] || angular.isUndefined(this.nro[0].type)) {
            this.oldDepositNRO = true;
        } else {
            this.oldDepositNRO = false;

            this.sumNRO = this.nro.reduce((acc, cur) => acc + cur.count, 0);

            this.NROTree = {
                parent: {
                    textIcon: 'NRO',
                    color: '#5069a9',
                    value: this.nro.reduce((acc, cur) => acc + cur.count, 0),
                },
                children: this.nro.map((item) => {
                    return {
                        icon: 'building',
                        color: '#e971b1',
                        text: item.type.toLocaleLowerCase(),
                        value: item.count,
                    };
                }),
            };
        }

        this.loaded = true;
    }
}

angular.module('dotic').component('physicalTypesIndicator', {
    controller: PhysicalTypesController,
    templateUrl: template,
    bindings: {
        sro: '<',
        nro: '<',
        parentRef: '<',
    },
});
