angular.module('dotic').factory('userProvider', userProvider);

function userProvider(ApiProvider, RestProvider, Upload, URI_KEYS) {
    return {
        create,
        me,
        userInfo,
        get,
        getAll,
        getAllWithCompanies,
        getEmployeesWithActivity,
        getActiveEmployeesByMonth,
        remove,
        removeAvatar,
        setHistoryHasRead,
        submitAvatar,
        update,
        updateSetting,
        updatePassword,
        stats,
        updateFiltersToDb,
    };

    // POST /users
    function create(user) {
        return RestProvider.post(URI_KEYS.USER, user);
    }

    // DELETE /users/:userId
    function remove(userId, query = {}) {
        return RestProvider.remove([URI_KEYS.USER, userId], query);
    }

    // GET /users
    function getAll(query = {}) {
        return RestProvider.get(URI_KEYS.USER, query);
    }

    // GET /users
    function getAllWithCompanies() {
        return ApiProvider.one(URI_KEYS.USER, 'list-with-companies').get();
    }

    // GET /users
    function getEmployeesWithActivity({ companyId = null, userId = null }) {
        const qs = prepareParams({ companyId, userId });

        return ApiProvider.one(URI_KEYS.USER, 'list-with-activity').get(qs);
    }

    // GET /users
    function getActiveEmployeesByMonth({ companyId = null, userId = null }) {
        const qs = prepareParams({ companyId, userId });

        return ApiProvider.one(URI_KEYS.USER, 'list-active-by-month').get(qs);
    }

    function prepareParams({ companyId = null, userId = null }) {
        const params = {};
        if (companyId && companyId !== '') {
            params.companyId = companyId;
        }

        if (userId && userId !== '') {
            params.userId = userId;
        }

        return params;
    }

    // PUT /users/history/read
    function setHistoryHasRead(userId, data = {}, query = {}) {
        return RestProvider.put([URI_KEYS.USER, userId, URI_KEYS.HISTORY, URI_KEYS.READ], data, query);
    }

    function me() {
        return ApiProvider.one(URI_KEYS.USER, 'me').get();
    }

    function userInfo(userId) {
        if (!userId) {
            throw new Error('no userId');
        }

        return ApiProvider.one(URI_KEYS.USER, userId).one('info').get();
    }

    function get(userId) {
        return ApiProvider.one(URI_KEYS.USER, userId).get();
    }

    // PUT /users
    function update(user, query = {}) {
        return RestProvider.put([URI_KEYS.USER, user.id], user, query);
    }

    function updateSetting(user, namespace, setting, value) {
        return RestProvider.put([URI_KEYS.USER, user.id, URI_KEYS.NAMESPACE, namespace, URI_KEYS.SETTING, setting], {
            value,
        });
    }

    // POST /user/avatar
    function submitAvatar(file, user) {
        return Upload.upload({
            url: `${APP_SETTINGS.baseApiUrl}/${URI_KEYS.USER}/${user.id}/avatar`,
            data: {
                file,
                user: {
                    id: user.id,
                },
            },
        });
    }

    function removeAvatar(userId, query = {}) {
        return RestProvider.remove([URI_KEYS.USER, userId, 'avatar'], query);
    }

    // PUT /users/password
    function updatePassword(user, password, newPassword) {
        return RestProvider.put([URI_KEYS.USER, user.id, 'password'], {
            password,
            newPassword,
        });
    }

    function stats(userId) {
        return ApiProvider.one(URI_KEYS.USER, userId).one('stats').get();
    }

    function updateFiltersToDb(userId, filters = {}) {
        return ApiProvider.one(`${URI_KEYS.USER}/${userId}/updatefilterstodb`).customPUT(filters);
    }
}
