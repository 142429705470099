function forgotPasswordRoutes($stateProvider) {
    $stateProvider.state('forgotPassword', {
        url: '/forgotPassword',
        component: 'forgotPasswordPage',
        data: {
            authenticationRequired: false,
            roleRequired: [],
        },
    });
}

angular.module('dotic').config(forgotPasswordRoutes);
