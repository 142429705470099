import { DoughnutChartColorEnum } from '../../../services/color.service';
import template from './plugs-status-indicator.html';

class PlugsStatusIndicatorComponent {
    chartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    data = { nbPlugsInProgress: 0, nbPlugsOperated: 0, nbPlugsPlanned: 0 };
    loading = false;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
    }

    getChartData(values) {
        return {
            bigLegend: {
                figure: values.nbPlugsPlanned,
                name: this._translate('shared.plugStatus', { COUNT: values.nbPlugsPlanned, TYPE: 'planned' }),
            },
            datasets: [
                {
                    data: [values.nbPlugsInProgress, values.nbPlugsOperated],
                    backgroundColor: [DoughnutChartColorEnum.IN_PROGRESS, DoughnutChartColorEnum.BIG_SUCCESS],
                },
            ],
            labels: [
                this._translate('shared.plugStatus', { COUNT: values.nbPlugsInProgress, TYPE: 'ongoing' }),
                this._translate('shared.plugStatus', { COUNT: values.nbPlugsOperated, TYPE: 'operated' }),
            ],
        };
    }
}

angular.module('dotic').component('plugsStatusIndicator', {
    controller: PlugsStatusIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        loading: '<',
    },
});
