import template from './postProcessingList.html';

class PostProcessingListPage {
    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        LoaderService,
        postProcessingProvider,
    ) {
        this._$deleteService = $deleteService;
        this.isAllowed = $authorizationService.isAllowed;
        this._translate = $filter('translate');
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._LoaderService = LoaderService;
        this._$timeout = $timeout;
        this._$uibModal = $uibModal;
        this._postProcessingProvider = postProcessingProvider;
        this._$scope = $scope;
        this.postProcessingList = [];
        this.companies = [];
        this.loading = true;
        this.postProcessingId = this._$stateParams.postProcessingId;
        this._$modalService = $modalService;
        this.filter = {
            search: $state.params.search || '',
        };
        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();

        $scope.$watchGroup(['$ctrl.filter.search', '$ctrl.postProcessingList'], () => this.filterPostProcessing());

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'postProcessingList',
                    title: this._translate('postProcessing.postProcessingList'),
                    href: $location.path(),
                },
            ],
        });

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';

            $scope.$emit('updateNavigationUrl');
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        await this.fetchPostProcessing();
    }

    filterPostProcessing() {
        const { search } = this.filter;

        this._$state.go('.', { search });

        this.postProcessings = this.postProcessingList.filter(this.filterSearch(search));
    }

    filterSearch(search) {
        const value = search.toLowerCase();

        return (elementToFilter) => {
            return (
                elementToFilter.name.toLocaleLowerCase().indexOf(value) > -1 ||
                elementToFilter.information.toLocaleLowerCase().indexOf(value) > -1 ||
                elementToFilter.company?.find(
                    (elementCompany) => elementCompany.name.toLocaleLowerCase().indexOf(value) > -1,
                )
            );
        };
    }

    async fetchPostProcessing() {
        this.loading = true;

        try {
            this.postProcessingList = await this._postProcessingProvider.getAll();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    isAllowedToRemove(item) {
        return item && this.isAllowed(['superAdmin']);
    }

    getSelectionType() {
        const size = this.tableSelect.selectionSize();

        const removable = this.postProcessingList.filter((item) => this.isAllowedToRemove(item));

        if (size === removable.length) {
            return 'all';
        }

        if (size === 0) {
            return 'none';
        }

        if (size === 10) {
            return 'visible';
        }

        return 'few';
    }

    openCompanyModal(postProcessing) {
        const companiesList = postProcessing.company;

        this._$uibModal.open({
            size: 'md',
            component: 'companiesListModal',
            resolve: {
                companies: () => companiesList,
            },
        });
    }

    async removePostProcessing() {
        const selectedPostProcessings = this.postProcessingList
            .filter(this.tableSelect.isActive)
            .filter((item) => this.isAllowedToRemove(item._id));

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.postProcessing', {
                COUNT: selectedPostProcessings.length,
                NAME: selectedPostProcessings[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedPostProcessingIds = this.tableSelect.getSelection();
        await this._$deleteService.deleteArrayOfIds(
            selectedPostProcessingIds,
            (postProcessingId) => this._postProcessingProvider.delete(postProcessingId),
            'removeModal.successPostProcessing',
        );

        this.tableSelect.empty();
        await this.fetchPostProcessing();
        this._LoaderService.dismiss();
    }

    showDetails(postProcessing) {
        this.tableDetail.toggle(postProcessing);
    }
}

angular.module('dotic').component('postProcessingListPage', {
    controller: PostProcessingListPage,
    templateUrl: template,
});
