angular.module('dotic').factory('superAdminProvider', superAdminProvider);

function superAdminProvider(ApiProvider, URI_KEYS) {
    return {
        getApplicationStats,
        getCompanyStats,
        getCompanyDepositList,
        getDeposits,
    };

    /**
     * get global stats
     * @param {object|boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @param {string|null} user
     * @param {string|null} createdAtStart
     * @param {string|null} createdAtEnd
     * @returns {*} Promise
     */
    function getApplicationStats({
        groupByLastVersion = false,
        phase = null,
        company = null,
        user = null,
        createdAtStart = null,
        createdAtEnd = null,
    }) {
        const qs = prepareParams({
            groupByLastVersion,
            phase,
            company,
            user,
            createdAtStart,
            createdAtEnd,
        });

        return ApiProvider.one(URI_KEYS.SUPER_ADMIN).one('applicationStats').get(qs);
    }

    /**
     * get company details stats
     * @param {string} companyId
     * @param {object|boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @returns {*} Promise
     */
    function getCompanyStats(
        companyId,
        { groupByLastVersion = false, phase = null, user = null, createdAtStart = null, createdAtEnd = null },
    ) {
        const qs = prepareParams({
            groupByLastVersion,
            phase,
            user,
            createdAtStart,
            createdAtEnd,
        });

        return ApiProvider.one(URI_KEYS.SUPER_ADMIN).one('companyStats', companyId).get(qs);
    }

    /**
     * get desposits lists
     * @param {string} companyId
     * @param {object|boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @param {string|null} user
     * @param {string|null} createdAtStart
     * @param {string|null} createdAtEnd
     * @returns {*} Promise
     */
    function getCompanyDepositList(
        companyId,
        {
            groupByLastVersion = false,
            phase = null,
            company = null,
            user = null,
            createdAtStart = null,
            createdAtEnd = null,
        },
    ) {
        const qs = prepareParams({
            groupByLastVersion,
            phase,
            company,
            user,
            createdAtStart,
            createdAtEnd,
        });

        return ApiProvider.one(URI_KEYS.SUPER_ADMIN).one('companyDepositList', companyId).get(qs);
    }

    /**
     * @param {boolean} groupByLastVersion
     * @param {string|null} phase
     * @param {string|null} company
     * @param {string|null} user
     * @param {string|null} createdAtStart
     * @param {string|null} createdAtEnd
     * @returns {{groupByLastVersion: boolean}}
     * @private
     */
    function prepareParams({
        groupByLastVersion = false,
        phase = null,
        company = null,
        user = null,
        createdAtStart = null,
        createdAtEnd = null,
    }) {
        const params = { groupByLastVersion };
        if (phase && phase !== '') {
            params.phase = phase;
        }

        if (company && company !== '') {
            params.company = company;
        }

        // Rename param user to createdBy
        if (user && user !== '') {
            params.createdBy = user;
        }

        if (createdAtStart && createdAtStart !== '') {
            params.createdAtStart = createdAtStart;
        }

        if (createdAtEnd && createdAtEnd !== '') {
            params.createdAtEnd = createdAtEnd;
        }

        return params;
    }

    function getDeposits() {
        return ApiProvider.one(URI_KEYS.SUPER_ADMIN).one('depositsList').get();
    }
}
