import depositRemoveTemplate from '../deposit/removeModal/depositRemove.html';
import EnvironmentSettingsService from '../../services/environment-settings.service';
import { FMEJobEnum } from '../../models/deposit.model';
import reportDetailTemplate from './reportDetail.html';

class ReportDetailPage {
    constructor(
        $auth,
        $authorizationService,
        $companySettings,
        $controlStatisticsService,
        $dataModelService,
        $excelService,
        $filter,
        $filterService,
        $gridControlService,
        $indicatorNewPlugService,
        $location,
        $log,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        LoaderService,
        RestProvider,
        companyProvider,
        depositProvider,
        reportProvider,
        userProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$controlStatisticsService = $controlStatisticsService;
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._$gridControlService = $gridControlService;
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$location = $location;
        this._$log = $log;
        this._$modalService = $modalService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._LoaderService = LoaderService;
        this._RestProvider = RestProvider;
        this._companyProvider = companyProvider;
        this._depositProvider = depositProvider;
        this._reportProvider = reportProvider;
        this._userProvider = userProvider;

        this.companyId = $auth.getPayload().company;
        this.controls = [];
        this.createdBy = {};
        this.deposit = {};
        this.depositUnknown = false;
        this.errorSI = [];
        this.hasAnomaliesOpen = false;
        // Check if there is export error SI Fiber to display
        this.hasErrorSIOpen = false;
        this.hasIndicatorsOpen = false;
        this.hasQGisEnabled = true;
        this.housingTree = null;
        this.isAllowed = $authorizationService.isAllowed;
        this.isBottomFilter = true;
        this.isDownloading = false;
        this.isOrange = EnvironmentSettingsService.isOrange();
        this.loading = true;
        this.newTable = [];
        this.plugTree = null;
        this.project = {};
        this.report = {};
        this.reportId = this._$stateParams.reportId;
        this.requestHousing = '';
        this.requestPlug = '';
        this.showAnomaliesType = this._$stateParams.showAnomaliesType;
        this.showIndic = this._$stateParams.showIndic;
        this.user = {};
        this.userId = this._$auth.getPayload().userId;

        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'status',
                placeholder: 'filter.all.severity',
                saveValue: true,
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'internalIdsList',
                selected: 'internalId',
                hasSearch: true,
                placeholder: 'filter.all.internalId',
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'objectList',
                selected: 'object',
                hasSearch: true,
                placeholder: 'filter.all.object',
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'resultList',
                value: 'result',
                placeholder: 'filter.all.result',
                translateParamsLabel: { COUNT: 1 },
                translateKeyLabel: 'shared.result',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'thematics',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
        ];
        this.basicResultList = [
            {
                key: 'unused',
                value: this._translate('shared.nonExistent'),
            },
            {
                key: 'error',
                value: this._translate('shared.inoperative'),
            },
            {
                key: 'fail',
                value: this._translate('shared.anomaly'),
            },
            {
                key: 'success',
                value: this._translate('shared.succeeded'),
            },
        ];
        this.warningResultList = [
            {
                key: 'warning',
                value: this._translate('shared.notMet'),
            },
        ];
        this.filterValues = {
            severityList: [
                {
                    key: 'minor',
                    value: this._humanizeSeverity('minor'),
                },
                {
                    key: 'major',
                    value: this._humanizeSeverity('major'),
                },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            internalIdsList: [],
            objectList: [],
            resultList: this.basicResultList,
            thematics: [],
        };
        this.filter = {
            status: [],
            internalId: [],
            result: '',
            object: [],
            thematic: [],
            search: '',
            // Search for errors SI Fiber
            searchSI: this.hasErrorSIOpen ? '' : undefined,
        };

        this._$scope.$watch('$ctrl.controls', () => {
            if (angular.isArray(this.controls)) {
                const thematics = new Map();
                const objectList = new Map();
                const internalIdsList = new Map();

                this.controls.forEach(({ thematic, object, controlPoint }) => {
                    thematics.set(thematic, this._humanizeThematic(thematic));
                    internalIdsList.set(controlPoint, controlPoint);
                    if (angular.isString(object)) {
                        objectList.set(object, object);
                    }
                });

                this.filterValues.thematics = Array.from(thematics, ([key, value]) => ({ key, value }));
                this.filterValues.objectList = Array.from(objectList, ([key, value]) => ({ key, value }));
                this.filterValues.internalIdsList = Array.from(internalIdsList, ([key, value]) => ({ key, value }));
            }
        });

        this._$scope.$watchGroup(
            [
                '$ctrl.filter.internalId',
                '$ctrl.filter.thematic',
                '$ctrl.filter.status',
                '$ctrl.filter.object',
                '$ctrl.filter.search',
                '$ctrl.filter.searchSI',
                '$ctrl.filter.result',
                '$ctrl.showIndic',
                '$ctrl.showAnomaliesType',
                '$ctrl.controls',
            ],
            () => {
                this.setScopeGridControlViewWithFilters();
            },
        );

        this.isGraceV3 = false;
    }

    $onInit() {
        this.loading = true;
        if (this.showAnomaliesType) {
            this.setAnomaliesFilter();
        }

        this._userProvider.get(this.userId).then((user) => (this.user = user));

        this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(this.companyId).then((result) => {
            this.hasLinkBetweenGridsEnabled = result;

            this.filterValues = {
                ...this.filterValues,
                resultList: this.hasLinkBetweenGridsEnabled
                    ? [...this.basicResultList, ...this.warningResultList]
                    : this.basicResultList,
            };
        });

        this.getReport()
            .then(() => this.setUserInterfaceSetting())
            .then(() => {
                this.tableDetail = this._$tableService.detail();
                this.loading = false;

                if (this.showIndic) {
                    this.showIndicAnchor();
                }

                if (this.showAnomaliesType) {
                    this.setAnomaliesFilter();
                    this.showAnomaliesAnchor();
                }

                if (this.errorSI) {
                    this.cancelErrorSIFilters();
                }
            });
    }

    toggleIndicatorsVisibility() {
        this.hasIndicatorsOpen = !this.hasIndicatorsOpen;
    }

    toggleAnomalieVisibility() {
        this.hasAnomaliesOpen = !this.hasAnomaliesOpen;
    }

    toggleErrorSIVisibility() {
        this.hasErrorSIOpen = !this.hasErrorSIOpen;
    }

    static filterSearchErrorSI(searchSI) {
        return (errorSI) =>
            searchSI === '' ||
            (errorSI.uniqueId || '').toLowerCase().indexOf(searchSI.toLowerCase()) > -1 ||
            (errorSI.message || '').toLowerCase().indexOf(searchSI.toLowerCase()) > -1 ||
            (errorSI.object || '').toLowerCase().indexOf(searchSI.toLowerCase()) > -1 ||
            (errorSI.sourceTool || '').toLowerCase().indexOf(searchSI.toLowerCase()) > -1;
    }

    static resultFilter(value, hasLinkBetweenGridsEnabled) {
        return (control) => {
            if (value === 'success') {
                return control.faults === 0;
            } else if (value === 'error') {
                return (
                    (control.faults === -2 && !control.warningControlPoint) ||
                    (control.faults === -1 && !control.warningControlPoint)
                );
            } else if (value === 'warning' && hasLinkBetweenGridsEnabled) {
                return control.warningControlPoint;
            } else if (value === 'unused') {
                return control.faults === -3;
            } else if (value === 'fail') {
                return control.faults > 0;
            }

            return true;
        };
    }

    setScopeGridControlViewWithFilters() {
        this._$state.go('.', {
            status: this.filter.status.join(','),
            thematic: this.filter.thematic.join(','),
            object: this.filter.object.join(','),
            result: this.filter.result,
            search: this.filter.search,
            searchSI: this.filter.searchSI,
            internalId: this.filter.internalId.join(','),
        });

        this.filteredControls = this.controls
            .filter(this._$gridControlService.filterSearch(this.filter.search.toLowerCase()))
            .filter(this._$filterService.genericMultiFilter(this.filter.object, 'object'))
            .filter(this._$filterService.genericMultiFilter(this.filter.status, 'severity'))
            .filter(this._$filterService.filterControlPoint(this.filter.internalId))
            .filter(ReportDetailPage.resultFilter(this.filter.result, this.hasLinkBetweenGridsEnabled))
            .filter(this._$filterService.genericMultiFilter(this.filter.thematic, 'thematic'));

        // filter errors SI export Fiber
        this.filterErrorSI = this.errorSI
            .filter(this._$filterService.genericMultiFilter(this.filter.object, 'object'))
            .filter(ReportDetailPage.filterSearchErrorSI(this.filter.searchSI.toLowerCase()));
    }

    initValueFilterUrl() {
        this.filter = {
            internalId: this._$filterService.getFilterValue(this._$state.params.internalId),
            object: this._$filterService.getFilterValue(this._$state.params.object),
            result: this._$state.params.result || '',
            search: this._$state.params.search || '',
            searchSI: this._$state.params.searchSI || '',
            status: this._$filterService.getFilterValue(this._$state.params.status),
            thematic: this._$filterService.getFilterValue(this._$state.params.thematic),
        };
    }

    getReport() {
        this.initValueFilterUrl();

        return this._reportProvider
            .getDetails(this.reportId)
            .then((report) => {
                const formattedReport = report;

                this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(report.deposit);

                delete formattedReport.depositId;

                return formattedReport;
            })
            .then((report) => {
                this._$scope.$emit('keepPreviousNavigation', {
                    newPage: [
                        {
                            key: 'report',
                            title: `Rapport ${report.deposit.name}`,
                            href: this._$location.path(),
                        },
                    ],
                    defaultPrevious: {
                        title: 'Livrables contrôlés',
                        href: this._$state.href('app.depositJob-control'),
                        key: 'deposits',
                    },
                    allowedPreviousKeys: [
                        'deposits',
                        'report',
                        'statistic',
                        'fillingStatistic',
                        'fillingStatisticsCompare',
                    ],
                });

                this.project = report.project;

                if (report.company) {
                    this.company = report.company;
                }

                this.createdBy = report.createdBy;

                this.deposit = report.deposit;

                if (report.exportErrors) {
                    this.errorSI = this.displayErrorSIData(report.exportErrors.data);
                }

                this.hasExportErrors = report?.exportErrors;

                this.deposit.networkSegments = this._$segmentsService.setNetworkSegments(this.deposit.networkSegments);

                if (this.deposit.networkSegments.length) {
                    this.hasSegmentTrDi = !!(
                        this.deposit.networkSegments.includes('TR') && this.deposit.networkSegments.includes('DI')
                    );
                    this.hasSegmentDi = !!(
                        this.deposit.networkSegments.includes('DI') && !this.deposit.networkSegments.includes('TR')
                    );
                }

                this.report = report;

                this.controls = this._$reportService.getControls(report.controls, this.deposit.gridControl).controls;
                this.controls = this.controls.map((controlPoint) => {
                    controlPoint.uniqId = `${controlPoint.severity}-${controlPoint.controlPoint}`;

                    return controlPoint;
                });
                this.hasQGisEnabled = report.deposit.dataModel.settings.hasQGisEnabled;

                return this._reportProvider.getStatistics(this.reportId);
            })
            .then((statistics) => {
                this.statistics = statistics;

                this.statistics.indicators.lgCable =
                    this.statistics.indicators.lgCable &&
                    this.statistics.indicators.lgCable.map((cable) => {
                        if (cable.capacity !== '') {
                            cable.capacity = parseInt(cable.capacity, 10);
                        } else {
                            cable.capacity = '-';
                        }

                        return cable;
                    });

                this.indicators = {
                    pbo: [
                        { value: statistics.indicators.pbo, id: 'pbo' },
                        { value: statistics.indicators.bpe, id: 'bpe' },
                        {
                            value: statistics.indicators.type_ebp_pto,
                            id: 'pto',
                        },
                    ],
                    avgpbo: statistics.indicators.avgpbo,
                    sroTypePhy: statistics.indicators.sroTypePhy,
                    nroTypePhy: statistics.indicators.nroTypePhy,
                    sro: statistics.indicators.sro,
                    housing: statistics.indicators.housing,
                    ftth: statistics.indicators.plug.ftth,
                    countPlugFTTE: statistics.indicators.plug.ftte,
                    plugSum: statistics.indicators.plug.ftte + statistics.indicators.plug.ftth,
                    nbBPE: statistics.indicators.nbBPE,
                    tables: statistics.indicators.tables,
                    opticalCableSupport: statistics.indicators.opticalCableSupport,
                    nature_type_phy: statistics.indicators.nature_type_phy,
                    lgCable: statistics.indicators.lgCable,
                    newPlug: statistics.indicators.newPlug,
                    newHousing: statistics.indicators.newPlug,
                    pathway: statistics.indicators.pathway,
                    connect: statistics.indicators.connectivity,
                    abandonedObject: statistics.indicators.abandonedObject,
                    nbBti: statistics.indicators.nbBti,
                    cableBti: statistics.indicators.cableBti,
                    distanceBetweenNroPbo: statistics.indicators.distanceBetweenNroPbo,
                    distanceBetweenNroSro: statistics.indicators.distanceBetweenNroSro,
                    distanceBetweenSroPbo: statistics.indicators.distanceBetweenSroPbo,
                    junctionBox: statistics.indicators.junctionBox,
                };

                this.isOldNewPlug = true;
                const isOldIndicatorPlug = !!(this.indicators.ftth > 0 && this.indicators.ftte > 0);

                this.newTable = {
                    recordsTable: this.indicators?.tables ?? [],
                    abandonedObjectTable: this.indicators.abandonedObject === 0 ? [] : this.indicators.abandonedObject,
                };

                if (isOldIndicatorPlug) {
                    this.plugTree = this._$indicatorNewPlugService.initOldPlugTree(this.indicators);
                    this.requestPlug =
                        "SELECT SUM(cast (ad_nbprhab as integer)) as nb FROM 't_adresse', SELECT SUM(cast (ad_nbprpro as integer)) as nb FROM 't_adresse'";
                    this.isOldNewPlug = false;
                }

                if (this.indicators.newPlug && !isOldIndicatorPlug) {
                    const plugsData =
                        this.indicators.newPlug ?? this._$indicatorNewPlugService.initIndicNewPlug(this.indicators);

                    this.housingTree = this._$indicatorNewPlugService.initHousingTree(plugsData, null, false);
                    this.plugTree = this._$indicatorNewPlugService.initPlugTree(plugsData, null, false);

                    if (this.isGraceV3) {
                        this.requestPlug =
                            "SELECT 'Nombre de prises par logements' as titre_nb_pr, SUM(cast (ad_nbfotte as integer)) as sum_nbpr_pro, SUM(cast (ad_nbfotth as integer)) as sum_nbpr_hab, SUM(cast (ad_nbfotth as integer)) + SUM(cast (ad_nbfotte as integer)) as sum_nbpr, FROM t_adresse WHERE ad_abddate::text = '0' or char_length(ad_abddate::text) = 0 or ad_abddate is null";
                        this.requestHousing =
                            "SELECT 'Nombre de prises par locaux' as titre_nb_l, SUM(cast (ad_nblres as integer)) as sum_nbl_hab, SUM(cast (ad_nblpro as integer)) as sum_nbl_pro, SUM(cast (ad_nblres as integer)) + SUM(cast (ad_nblpro as integer)) as sum_nbl FROM t_adresse WHERE ad_abddate::text = '0' or char_length(ad_abddate::text) = 0 or ad_abddate is null";
                    } else {
                        this.requestPlug =
                            "SELECT 'Nombre de prises par logements' as titre_nb_pr, SUM(cast (ad_nbprpro as integer)) as sum_nbpr_pro, SUM(cast (ad_nbprhab as integer)) as sum_nbpr_hab, SUM(cast (ad_nbprhab as integer)) + SUM(cast (ad_nbprpro as integer)) as sum_nbpr, FROM t_adresse WHERE ad_abddate::text = '0' or char_length(ad_abddate::text) = 0 or ad_abddate is null";
                        this.requestHousing =
                            "SELECT 'Nombre de prises par locaux' as titre_nb_l, SUM(cast (ad_nblhab as integer)) as sum_nbl_hab, SUM(cast (ad_nblpro as integer)) as sum_nbl_pro, SUM(cast (ad_nblhab as integer)) + SUM(cast (ad_nblpro as integer)) as sum_nbl FROM t_adresse WHERE ad_abddate::text = '0' or char_length(ad_abddate::text) = 0 or ad_abddate is null";
                    }

                    this.isOldNewPlug = false;
                }

                if (this.indicators.distanceBetweenNroPbo) {
                    if (!this.hasSegmentTrDi) {
                        this.statistics.indicators.distanceBetweenNroPbo = [];
                    }
                }

                if (this.indicators.distanceBetweenNroSro) {
                    if (!this.hasSegmentTrDi) {
                        this.statistics.indicators.distanceBetweenNroSro = [];
                    }
                }

                if (this.indicators.distanceBetweenSroPbo) {
                    if (!this.hasSegmentDi) {
                        this.statistics.indicators.distanceBetweenSroPbo = [];
                    }
                }
            })
            .catch((err) => {
                this._$toasterService.error(err);
            });
    }

    hasMViewer() {
        return this.deposit.project && this.deposit.project.geoserver && this.deposit.project.geoserver.slug;
    }

    async openDownloadQgisModal() {
        const results = await this._$modalService.triggerLinkedDownloadModal(
            this.deposit,
            this._translate('shared.loadDeposit', { COUNT: 1 }).toLowerCase(),
            FMEJobEnum.LOAD,
        );
        if (!results) {
            return;
        }

        let params;
        if (results.deposit2) {
            params = { deposit2: results.deposit2 };
        }

        try {
            await this._RestProvider.downloadFromPath(`deposits/${this.deposit.id}/qgis/zip`, params);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    async onClickDownloadControlXlsx(control) {
        this._LoaderService.open(`
        <p>${this._translate('shared.downloadingFile')}</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        const { controlPoint, severity } = control;

        try {
            await this._RestProvider.downloadFromPath(`reports/${this.reportId}/controls/${controlPoint}/xlsx`, {
                severity,
            });
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this._LoaderService.dismiss();
        }
    }

    async onClickDownloadControlShape(control) {
        this._LoaderService.open(`
        <p>${this._translate('shared.downloadingFile')}</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        const { controlPoint, severity } = control;

        try {
            await this._RestProvider.downloadFromPath(`reports/${this.reportId}/controls/${controlPoint}/shape`, {
                severity,
            });
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this._LoaderService.dismiss();
        }
    }

    onClickControl(control) {
        if (control.invalid_count <= 0) {
            return;
        }

        return this._$modalService.triggerControlErrorModal(
            control,
            this._$reportService.getErrors(this.reportId, control.controlPoint, control.severity),
        );
    }

    onClickErrorSI(errors) {
        if (errors.anomalies.length <= 0) {
            return;
        }

        this._$uibModal.open({
            size: 'xxl',
            component: 'exportErrorSIModal',
            resolve: {
                errorSI: () => errors,
            },
        });
    }

    displayErrorSIData(errorSI) {
        // Remove data without uniqueId
        return errorSI
            .filter((error) => error.uniqueId)
            .map((data) => {
                return {
                    ...data,
                    uniqueId: data.uniqueId,
                    object: data.objectType.includes('.') ? data.objectType.split('.')[1].toUpperCase() : '-',
                    sourceTool: data.objectType.includes('.') ? data.objectType.split('.')[0] : data.objectType,
                };
            })
            .reduce((acc, data) => {
                const findData = acc.find((item) => item.uniqueId === data.uniqueId);
                const anomaliesErrorSi = {
                    code: data.code,
                    complementaryInfo1: data.complementaryInfo1,
                    complementaryInfo2: data.complementaryInfo2,
                    uniqueId: data.uniqueId,
                    id: data.id,
                    object: data.object,
                    objectId: data.objectId,
                    sourceTool: data.sourceTool,
                };

                if (!findData) {
                    acc.push({
                        anomalies: [anomaliesErrorSi],
                        uniqueId: data.uniqueId,
                        severity: data.severity,
                        errorName: data.errorName,
                        message: data.message,
                        object: data.object,
                        sourceTool: data.sourceTool,
                    });
                } else {
                    findData.anomalies.push(anomaliesErrorSi);
                }

                return acc;
            }, []);
    }

    async getReportDownloadLink() {
        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`reports/${this.report.id}/pdf`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    async getReportSpecialDownloadLink() {
        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`reports/${this.report.id}/pdf-customer`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    async getQgisDownloadLink() {
        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`deposits/${this.deposit.id}/qgis/zip`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    async getErrorsDownloadLink() {
        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`reports/${this.report.id}/errors/zip`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    async getErrorsFiberDownloadLink() {
        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`reports/${this.report.id}/errors/fiber/xlsx`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    async getFMELogsDownloadLink() {
        if (!this.deposit) {
            return '#';
        }

        this.isDownloading = true;
        try {
            await this._RestProvider.downloadFromPath(`fmeServers/transformations/jobs/${this.deposit.idFME}/logs`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this.isDownloading = false;
        }
    }

    /**
     * Compatibility fix
     */
    getFillGridName() {
        if (this.deposit.fillGridName) {
            return this.deposit.fillGridName;
        }

        if (this.deposit.controlConfiguration?.fillGrid) {
            return this.deposit.controlConfiguration.fillGrid.fullname;
        }

        return '';
    }

    showDetails(control) {
        this.tableDetail.toggle(control);
    }

    viewCorrectiveAction(control) {
        this._$uibModal.open({
            size: 'xl',
            component: 'correctiveActionModal',
            resolve: {
                control: () => control,
            },
        });
    }

    compareReport(deposit) {
        this._$state.go('app.comparisonDepositPage', {
            deposit: deposit._id,
        });
    }

    setUserInterfaceSetting() {
        return this._userProvider
            .get(this.userId)
            .then((user) => {
                this.hasGraphsOpen = user.settings.interface.hasGraphsOpen;
            })
            .catch(this._$toasterService.error);
    }

    showIndicAnchor() {
        this.hasIndicatorsOpen = true;
        const element = angular.element('#showActivesTables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    showAnomaliesAnchor() {
        this.hasAnomaliesOpen = true;
        const element = angular.element('#showAnomaliesTables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    setAnomaliesFilter() {
        if (this.showAnomaliesType === 'success') {
            this.filter.result = 'success';
        }

        if (this.showAnomaliesType === 'blocking') {
            this.filter.status = ['blocking'];
            this.filter.result = 'fail';
        }

        if (this.showAnomaliesType === 'major') {
            this.filter.status = ['major'];
            this.filter.result = 'fail';
        }

        if (this.showAnomaliesType === 'minor') {
            this.filter.status = ['minor'];
            this.filter.result = 'fail';
        }

        if (this.showAnomaliesType === 'error') {
            this.filter.result = 'error';
        }

        if (this.showAnomaliesType === 'warning') {
            this.filter.result = 'warning';
        }
    }

    scrollElement(customClickEvent) {
        this.cancelFilters();

        if (customClickEvent.isDoughnut) {
            this.hasAnomaliesOpen = true;
            this._$reportService.scrollDoughnut(customClickEvent, this.filter);
        }

        if (customClickEvent.isBar) {
            this.hasAnomaliesOpen = true;

            if (customClickEvent.labelsIndexSeverity === 0) {
                this.filter.status = ['blocking'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            }

            if (customClickEvent.labelsIndexSeverity === 1) {
                this.filter.status = ['major'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            }

            if (customClickEvent.labelsIndexSeverity === 2) {
                this.filter.status = ['minor'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            }
        }

        const statusFilter = this.filterOptions.find((item) => item.name === 'severityList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: statusFilter,
            saveChanges: false,
            openDropdown: false,
        });

        const resultFilter = this.filterOptions.find((item) => item.name === 'resultList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: resultFilter,
            saveChanges: false,
            openDropdown: false,
        });

        const $element = angular.element('#showAnomaliesTables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
        }, 0);
    }

    scrollElementErrorSI(customClickEvent) {
        this.cancelErrorSIFilters();
        this.hasErrorSIOpen = true;

        if (customClickEvent.labels) {
            this.filter.object = [customClickEvent.labels];
        }

        const objectFilter = this.filterOptions.find((item) => item.name === 'objectList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: objectFilter,
            saveChanges: false,
            openDropdown: true,
        });

        const $element = angular.element('#showErrorSITables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
        }, 0);
    }

    cancelFilters() {
        this.filter.status = [];
        this.filter.internalId = [];
        this.filter.result = '';
        this.filter.object = [];
        this.filter.thematic = [];
    }

    cancelErrorSIFilters() {
        this.filter.object = [];
        this.filter.searchSI = '';
        this.filterErrorSI = this.errorSI;
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    downloadReportExcel() {
        this.isDownloading = true;
        try {
            this.statistics.indicators.controlPoints = this.controls;
            this.statistics.indicators.nbControls = this.statistics.controls.global;

            if (!this.statistics.indicators.tables?.length) {
                this.statistics.indicators.tables = [];
            }

            this.statistics.indicators.tables.forEach((table) => {
                const ab =
                    this.statistics.indicators.abandonedObject !== 0
                        ? this.statistics.indicators.abandonedObject.find((t) => t.tableName === table.nom_table)
                        : undefined;
                table.abandoned_objects = angular.isUndefined(ab) ? 0 : ab.count;
            });

            const tableSynthesis = this._$reportService.preparationSynthesisForExcel([this.report]);
            const tableAnomalies = this._$reportService.preparationAnomaliesForExcel(this.filteredControls);
            const tablesIndicators = this._$controlStatisticsService.preparationIndicatorsForExcel(
                [this.statistics.indicators],
                this.isGraceV3,
            );

            const fileName = [
                this._translate('shared.report', { COUNT: 1 }),
                this.report.deposit.name,
                this._$controlStatisticsService.makeDateFileName(this.report.deposit.createdAt),
            ].join('_');

            return this._$excelService.exportReportXLSX(
                [tableSynthesis, tableAnomalies],
                tablesIndicators,
                this.company.settings,
                fileName,
                'deposit.downloadReportSuccess',
            );
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        } finally {
            this.isDownloading = false;
        }
    }

    removeDeposit() {
        try {
            const modal = this._$uibModal.open({
                controller: 'DepositRemoveController as $ctrl',
                size: 'sm',
                templateUrl: depositRemoveTemplate,
            });

            modal.result.then(
                (result) => {
                    if (result.$value) {
                        this._LoaderService.open(`<p>${this._translate('deposit.deletingFiles', { COUNT: 1 })}</p>`);

                        this._depositProvider
                            .delete(this.deposit.id)
                            .then(() => {
                                this._LoaderService.dismiss();
                                this._$toasterService.info({
                                    body: this._translate('deposit.successDeleteDeposit', { COUNT: 1 }),
                                });
                            })
                            .then(() => this._$state.go('app.depositJob-control'));
                    }
                },
                () => {},
            );
        } catch (e) {
            this._LoaderService.dismiss();
            this._$toasterService.error(e);
        }
    }
}

angular.module('dotic').component('reportDetailPage', {
    controller: ReportDetailPage,
    templateUrl: reportDetailTemplate,
});
