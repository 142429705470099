angular.module('dotic').factory('$synopticService', ['$auth', 'synopticProvider', synopticService]);

function synopticService($auth, synopticProvider) {
    return {
        getServerUrl,
    };

    async function getServerUrl({ project = null, deposit = null, control = null, deliveryZone = null }) {
        const synoptic = await synopticProvider.get();
        const params = [`access_token=${$auth.getToken()}`];

        if (project) {
            params.push(`projectId=${project._id}`);

            return `${synoptic.url}/?${params.join('&')}`;
        } else if (deposit) {
            params.push(`depositId=${deposit.id}`);
            // View with another deposit
            if (control) {
                params.push(`controlId=${control.id}`);
            }

            return `${synoptic.url}/?${params.join('&')}`;
        } else if (deliveryZone) {
            params.push(`deliveryZone=${deliveryZone.id}`);

            return `${synoptic.url}/?${params.join('&')}`;
        }

        return null;
    }
}
