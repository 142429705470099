angular.module('dotic').factory('siteProvider', siteProvider);

function siteProvider(RestProvider, URI_KEYS) {
    return {
        getSites,
        getIndicators,
    };

    function getSites() {
        return RestProvider.get(['orange', URI_KEYS.SITE]);
    }

    function getIndicators(siteId) {
        return RestProvider.get(['orange', URI_KEYS.SITE, siteId]);
    }
}
