import { omit as radashOmit, parallel as radashParallel } from 'radash';

import { ProjectsApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './projectForm.html';

class ProjectFormController {
    constructor(
        $apiClientService,
        $auth,
        $filter,
        $geometryService,
        $phaseService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        companyProvider,
        controlConfigurationProvider,
        projectProvider,
    ) {
        this._$scope = $scope;
        this._$state = $state;
        this._$toasterService = $toasterService;
        this._orderBy = $filter('orderBy');
        this._$timeout = $timeout;
        this._translate = $filter('translate');
        this._companyProvider = companyProvider;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._projectApi = new ProjectsApi($apiClientService.client);
        this._projectProvider = projectProvider;
        this._$scope.regexPattern = '[a-zA-Z0-9. -]+';

        this.loading = true;
        this.submitLoading = false;

        this.companyId = $auth.getPayload().company;
        this.projectId = $stateParams.projectId || null;
        this.isDuplicate = $stateParams.duplicate || false;

        this.controlConfigurations = [];
        this.phaseList = $phaseService.getAll();
        this.geometryList = Array.from($geometryService.getAll(), ([key, value]) => ({
            key: key.toString(),
            value: value,
        }));

        this.projectForm = {};
        this.project = {
            controlConfigurations: [],
            isAutomaticNaming: true,
            assignments: [],
            description: null,
            endAt: null,
            name: null,
            phases: [],
            projection: null,
            startAt: null,
        };

        this.userList = [];

        this.newSubCompany = () => ({
            companyId: null,
            userList: [],
            assignments: [],
        });

        this.assignmentKeys = [];
        this.companyAssignments = [];
        this.selectedSubCompanies = [this.newSubCompany()];
        this.toggleSubCompaniesSelection = false;
    }

    async $onInit() {
        let newPage;

        await this.getData();
        await this.setCompany();
        await this.getProject();

        if (this.project.id && this.isDuplicate) {
            this.title = `${this._translate('shared.duplicateProject')} ${this.project.name}`;
            newPage = [
                {
                    key: 'duplicateProject',
                    title: this.title,
                    href: this._$state.href('app.projectDuplicate'),
                },
            ];

            this.textButton = this._translate('shared.duplicate');
        } else if (this.project.id) {
            this.title = `${this._translate('shared.modify')} ${this.project.name}`;
            newPage = [
                {
                    key: 'editProject',
                    title: this.title,
                    href: this._$state.href('app.projectEdit'),
                },
            ];
            this.textButton = this._translate('shared.modify');
        } else {
            this.title = this._translate('shared.createProject');
            newPage = [
                {
                    key: 'createProject',
                    title: this.title,
                    href: this._$state.href('app.projectCreate'),
                },
            ];
            this.textButton = this._translate('shared.create');
        }

        // Navigation default
        this._$scope.$emit('keepPreviousNavigation', {
            newPage,
            defaultPrevious: {
                title: this._translate('shared.projects'),
                href: this._$state.href('app.projectList'),
                key: 'projects',
            },
            allowedPreviousKeys: ['projects'],
        });

        this._$timeout(() => (this.loading = false));
    }

    async fetchCompany(companyId) {
        try {
            return await this._companyProvider.get(companyId);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async getProject() {
        if (!this.projectId) {
            return;
        }

        const project = await this._projectProvider.getEdit(this.projectId);
        const projection = project.projection.toString();
        const controlConfigurations = project.controlConfigurations.map((configuration) => configuration._id);
        const startAt = moment(project.startAt).toDate();
        const endAt = moment(project.endAt).toDate();

        // Split assignments by company | subcompanies
        this.companyAssignments = project.assignments
            .filter(({ type, user }) => user && type === 'user')
            .map(({ user }) => user.id);

        this.companyAssignments = this.companyAssignments.filter((value) => {
            return this.userList[value];
        });

        // *
        // Map subCompanies selection array
        this.selectedSubCompanies = this.company.subCompanies
            .map((subCompany) => {
                const projectAssignments = project.assignments.filter(({ company }) => company === subCompany.id);
                if (projectAssignments.length > 0) {
                    const assignments = projectAssignments.map((assignment) => ({
                        ...assignment,
                        user: assignment.user.id,
                    }));

                    return {
                        assignments,
                        companyId: subCompany._id,
                        userList: this.getSelectableUsers(subCompany.employees),
                    };
                }
            })
            .filter((item) => item);

        // Toggle subCompanies boxes
        if (this.selectedSubCompanies.length > 0) {
            this.toggleSubCompaniesSelection = true;
        }

        this.project = {
            ...project,
            projection,
            startAt,
            endAt,
            controlConfigurations,
        };
    }

    async getData() {
        try {
            const configurations = await this._controlConfigurationProvider.getAll();
            const filteredConfigurations = this._orderBy(configurations, 'name');
            this.controlConfigurations = filteredConfigurations.map((configuration) => ({
                key: configuration._id,
                value: configuration.name,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    getSelectableUsers(employees) {
        return (
            employees?.filter((employee) => {
                const isValidDate =
                    !Date.parse(employee.expirationAt) || Date.now() <= Date.parse(employee.expirationAt);

                return employee.role === 'user' && isValidDate;
            }) ?? []
        );
    }

    isFormValid() {
        return this.project.name && this.project.description && this.project.phases.length && this.project.projection;
    }

    async onSubmit() {
        if (this.projectForm.$error.date && Object.keys(this.projectForm.$error).length === 1) {
            if (!this.projectForm.projectStartAt.$viewValue) {
                this.project.startAt = null;
            }

            if (!this.projectForm.projectEndAt.$viewValue) {
                this.project.endAt = null;
            }

            this.projectForm.$valid = true;
        }

        if (!this.projectForm.$valid || !this.isFormValid()) {
            if (this.projectForm.$error.pattern) {
                return this._$toasterService.error({
                    body: this._translate('shared.wrongCharacters'),
                });
            }

            return this._$toasterService.error({
                body: this._translate('shared.missingFields'),
            });
        }

        this.submitLoading = true;
        const projectData = this.prepareProjectData();

        try {
            if (this.projectId && !this.isDuplicate) {
                await this._projectApi.update(this.projectId, projectData);
                this._$toasterService.success({
                    body: this._translate('projectList.projectActionSuccess', { TYPE: 'edit' }),
                });

                return this._$state.go('app.projectDetail', {
                    projectId: this.projectId,
                });
            }

            const projectId = (await this._projectApi.createWithHttpInfo(projectData)).response.body.id;
            this._$toasterService.success({
                body: this._translate('projectList.projectActionSuccess', { TYPE: 'add' }),
            });

            return this._$state.go('app.projectDetail', {
                projectId: projectId,
            });
        } catch (error) {
            if (error.body?.code === 'NAME_DUPLICATE') {
                this.projectForm.name.$setValidity('duplicate', false);
                this.projectForm.name.$touched = true;

                this._$toasterService.error({ body: error.body.message });

                return;
            }

            this._$toasterService.error(error);
        } finally {
            this.submitLoading = false;
        }
    }

    prepareProjectData() {
        // Map company user assignments
        const userAssignments = this.companyAssignments.map((userId) => ({
            type: 'user',
            user: userId,
        }));

        // Map subCompanies assignments
        // and filter empty/invalid values
        let subAssignments = [];
        if (this.toggleSubCompaniesSelection) {
            subAssignments = this.selectedSubCompanies
                .reduce((acc, subCompany) => {
                    acc.push(...subCompany.assignments);

                    return acc;
                }, [])
                .filter((assignment) => assignment.phases?.length)
                .map((item) => ({
                    ...item,
                    type: 'subcontractor',
                }));
        }

        return {
            ...radashOmit(this.project, ['_id', 'id']),
            assignments: [...userAssignments, ...subAssignments],
        };
    }

    async setCompany() {
        this.company = await this.fetchCompany(this.companyId);
        if (!this.company) {
            return;
        }

        const userRole = this.company.employees.filter((item) => item.role === 'user');
        const sortUserList = this._orderBy(userRole, 'fullname');
        this.assignmentKeys = sortUserList.map((user) => ({ key: user.id, value: user.fullname }));
        this.userList = sortUserList.reduce(
            (acc, user) => ({
                ...acc,
                [user.id]: user.fullname,
            }),
            {},
        );

        this.company.subCompanies = await radashParallel(10, this.company.subCompanies, async (subCompany) => {
            const fullSubCompany = await this.fetchCompany(subCompany.id);
            if (!fullSubCompany) {
                return subCompany;
            }

            return fullSubCompany;
        });
    }

    setPhase(phase) {
        const phases = new Set(this.project.phases);

        if (phases.has(phase)) {
            phases.delete(phase);
        } else {
            phases.add(phase);
        }

        // Update root phases
        this.project.phases = Array.from(phases);

        // filter subCompanies phases
        this.selectedSubCompanies.forEach((selected) => {
            selected.assignments.forEach((assignment) => {
                assignment.phases = assignment.phases.filter((assignedPhase) => assignedPhase !== phase);
            });
        });
    }

    /**
     * SubCompanies toggle and add buttons
     */
    toggleAddSubCompany() {
        this.toggleSubCompaniesSelection = !this.toggleSubCompaniesSelection;
    }

    addNewSubCompany() {
        this.selectedSubCompanies.push(this.newSubCompany());
    }

    canAddSubCompany() {
        const filtered = this.filteredSubCompanies();
        const filled = this.selectedSubCompanies.filter((selected) => selected.companyId);

        return filled.length === this.selectedSubCompanies.length && filtered.length > 0;
    }

    /**
     * Filter sub companies option items
     */
    filteredSubCompanies(companyId) {
        if (!this.company?.subCompanies) {
            return;
        }

        const selectedIds = this.selectedSubCompanies.map((selected) => selected.companyId);

        return this.company.subCompanies.filter((item) => selectedIds.indexOf(item.id) === -1 || companyId === item.id);
    }

    /**
     * fill user list of the selected company
     * @param companyId
     */
    onSubCompanySelect(companyId) {
        const selected = this.selectedSubCompanies.find((selected) => selected.companyId === companyId);
        const company = this.company.subCompanies.find((subCompany) => subCompany._id === companyId);
        selected.userList = this.getSelectableUsers(company.employees);
    }

    onSubCompanyUnSelect(companyId) {
        this.selectedSubCompanies = this.selectedSubCompanies.filter((item) => item.companyId !== companyId);
    }

    isAssigned(companyId, userId, phase) {
        const selected = this.selectedSubCompanies.find((selected) => selected.companyId === companyId);

        return (
            selected &&
            !!selected.assignments.find((a) => a.user === userId && a.phases && a.phases.indexOf(phase) !== -1)
        );
    }

    setAssignment(companyId, userId, phase) {
        if (this.project.phases.indexOf(phase) === -1) {
            return;
        }

        const selected = this.selectedSubCompanies.find((selected) => selected.companyId === companyId);
        const assignment = selected.assignments.find((a) => a.user === userId);

        if (!assignment) {
            // Create assignment
            selected.assignments.push({
                user: userId,
                phases: [phase],
            });
        } else {
            // Add, remove phase from array
            const phaseIndex = assignment.phases.findIndex((p) => p === phase);
            if (phaseIndex !== -1) {
                assignment.phases.splice(phaseIndex, 1);
            } else {
                assignment.phases.push(phase);
            }
        }
    }

    unsetNameError() {
        if (this.projectForm.name) {
            this.projectForm.name.$setValidity('duplicate', true);
        }
    }
}

angular.module('dotic').component('projectFormPage', {
    templateUrl: template,
    controller: ProjectFormController,
});
