angular.module('dotic').factory('controlConfigurationProvider', controlConfigurationProvider);

function controlConfigurationProvider(ApiProvider, URI_KEYS) {
    return {
        create: createControlConfiguration,
        delete: deleteControlConfiguration,
        get: getControlConfiguration,
        getAll: getAllControlConfiguration,
        getFillGridLinks,
        getControlGridLinks,
        getDataToCreate,
        update: updateControlConfiguration,
    };

    /**
     * POST /controlConfigurations
     *
     * @returns {*} Promise
     */
    function createControlConfiguration(controlConfiguration) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION).customPOST(controlConfiguration);
    }

    /**
     * DELETE /controlConfigurations/:controlConfigurationId
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function deleteControlConfiguration(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).remove();
    }

    /**
     * GET /controlConfigurations/:controlConfigurationId
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getControlConfiguration(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).get();
    }

    /**
     * GET /controlConfigurations/:controlConfigurationId/fill-grid-links
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getFillGridLinks(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).one('fill-grid-links').get();
    }

    /**
     * GET /controlConfigurations/:controlConfigurationId/control-grid-links
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getControlGridLinks(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).one('control-grid-links').get();
    }

    /**
     * GET /controlConfigurations
     *
     * @returns {*} Promise
     */
    function getAllControlConfiguration() {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION).getList();
    }

    /**
     * PUT /controlConfigurations/:controlConfigurationId
     *
     * @param {string} controlConfigurationId
     * @param {object} controlConfiguration
     *
     * @returns {*} Promise
     */
    function updateControlConfiguration(controlConfigurationId, controlConfiguration) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).customPUT(controlConfiguration);
    }

    function getDataToCreate(dataModelId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, dataModelId).one('data-to-create').get();
    }
}
