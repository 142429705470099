import * as radash from 'radash';

import { ProjectsApi } from '../../../sdk/connect-control-api-v1/src';

class ProjectListController {
    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $deleteService,
        $excelService,
        $filter,
        $filterService,
        $geometryService,
        $modalService,
        $projectService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        $userService,
        LoaderService,
        companyProvider,
        controlConfigurationProvider,
        projectProvider,
        userMetricsProvider,
        userProvider,
    ) {
        this._$auth = $auth;
        this._$deleteService = $deleteService;
        this._$excelService = $excelService;
        this._$uibModal = $uibModal;
        this._$projectService = $projectService;
        this._$scope = $scope;
        this._$state = $state;
        this._$filterService = $filterService;
        this._$toasterService = $toasterService;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._companyProvider = companyProvider;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._LoaderService = LoaderService;
        this._projectApi = new ProjectsApi($apiClientService.client);
        this._projectProvider = projectProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._userProvider = userProvider;
        this._$geometryService = $geometryService;
        this._$modalService = $modalService;
        this._translate = $filter('translate');

        this.isAllowed = $authorizationService.isAllowed;
        this.company = {};
        this.companyId = $auth.getPayload().company;
        this.connectedCompany = $userService.getConnectedCompany();
        this.loading = true;

        this.filterOptions = [
            {
                allowed: true,
                name: 'status',
                value: 'active',
                placeholder: 'filter.all.status',
                saveValue: true,
                translateKeyLabel: 'shared.status',
            },
            {
                allowed: true,
                name: 'controlConfigurationList',
                selected: 'controlConfiguration',
                hasSearch: true,
                placeholder: 'filter.all.controlConfigurations',
                translateKeyLabel: 'shared.controlConfiguration',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'deliveryZoneList',
                selected: 'deliveryZone',
                hasSearch: true,
                placeholder: 'filter.all.deliveryZones',
                translateKeyLabel: 'shared.deliveryZone',
                type: 'multi-select',
            },
            {
                allowed: this.isAllowed(['admin']),
                name: 'subCompaniesList',
                value: 'subCompany',
                hasSearch: true,
                placeholder: 'filter.all.subCompanies',
                translateKeyLabel: 'shared.subcontractor',
            },
        ];

        this.subCompanies = [];
        this.projectList = [];
        this.filteredProjectList = [];

        this.filterValues = {
            controlConfigurationList: [],
            deliveryZoneList: [],
            subCompaniesList: [],
            status: [
                {
                    key: 'true',
                    value: 'Actif',
                },
                {
                    key: 'false',
                    value: 'Inactif',
                },
            ],
        };

        this.filter = {
            active: $state.params.active || '',
            controlConfiguration: this._$filterService.getFilterValue($state.params.controlConfiguration),
            deliveryZone: this._$filterService.getFilterValue($state.params.deliveryZone),
            subCompany: $state.params.subCompany || '',
            search: $state.params.search || '',
        };

        this.noControlConfiguration = { value: this._translate('shared.none', { GENDER: 'female' }), key: '-1' };
        this.noDeliveryZone = { value: this._translate('shared.none', { GENDER: 'female' }), key: '-1' };
        this.noSubCompany = { value: this._translate('shared.none'), key: '-1' };

        $scope.$watchGroup(
            [
                '$ctrl.filter.active',
                '$ctrl.filter.controlConfiguration',
                '$ctrl.filter.deliveryZone',
                '$ctrl.filter.subCompany',
                '$ctrl.filter.search',
                '$ctrl.projectList',
            ],
            () => this.setScopeProjectWithFilters(),
        );

        $scope.$on('$locationChangeSuccess', () => {
            $scope.$emit('updateNavigationUrl');
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'projects',
                    title: this._translate('shared.projects'),
                    href: $state.href('app.projectList'),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });

        userMetricsProvider.listProjects();
    }

    selectAllProjects() {
        this._$scope.$broadcast('getVisibleRows');
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        try {
            this.company = await this._companyProvider.get(this.companyId);

            const subCompaniesId = this.company.subCompanies.map((subCompany) => {
                return subCompany.id;
            });

            this.subCompanies = await radash.map(subCompaniesId, async (id) => {
                const findSubCompany = await this._companyProvider.get(id);

                return {
                    id: findSubCompany.id,
                    employees: findSubCompany.employees,
                };
            });
            const projects = await this.getAllProjects();

            this.prepareFilters(projects);
            this.tableSelect = this._$tableService.select();
            this.projectList = projects.map((project) => ({
                ...project,
                isUsed: project.countDeposits > 0,
                hasSubCompanies: project.assignments.findIndex((assignment) => assignment.type !== 'user') !== -1,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    getUserFromAssignment(assignment, users) {
        const user = users.find((person) => person.email === assignment.email);

        if (!user?.id || !user?.creditCompany) {
            this._$toasterService.error(this._translate('shared.userNotExist', { VALUE: assignment.email }));

            return null;
        }

        return {
            ...assignment,
            ...user,
        };
    }

    async importProjectFromExcel(projectToImport) {
        if (!this._$geometryService.isKeyAssociatedToGeometry(projectToImport.projection)) {
            this._$toasterService.error(
                `${this._translate('projectList.notGeometry', {
                    VALUE: projectToImport.projection,
                })}`,
            );

            return;
        }

        projectToImport.companyId = this.companyId;

        const configurations = await this._controlConfigurationProvider.getAll();
        const subCompanies = await Promise.all(
            this.company.subCompanies.map((subCompany) => this._companyProvider.get(subCompany._id)),
        );
        const subCompaniesEmployees = subCompanies.flatMap((subCompany) => subCompany.employees);

        const users = radash.sift(
            projectToImport.assignments.map((assignment) =>
                this.getUserFromAssignment(assignment, [...this.company.employees, ...subCompaniesEmployees]),
            ),
        );

        projectToImport.controlConfigurations = radash.sift(
            projectToImport.controlConfigurations.map(
                (controlName) => configurations.find((configuration) => configuration.name === controlName)?.id,
            ),
        );

        projectToImport.assignments = users.map((user) => ({
            phases: user.phases,
            type: user.type,
            user: user.id,
        }));

        const originalProject = this.projectList.find((project) => project.name === projectToImport.name);

        try {
            if (!originalProject) {
                const newProject = await this._projectProvider.create(projectToImport);
                this._$toasterService.success({
                    body: this._translate('projectList.projectsImported'),
                });

                return newProject;
            }

            const updatedProject = await this._projectProvider.update(
                originalProject?.id ?? this.companyId,
                projectToImport,
            );
            this._$toasterService.success({
                body: this._translate('projectList.projectActionSuccess', { TYPE: 'edit' }),
            });

            return updatedProject;
        } catch (error) {
            this._$toasterService.error(error);

            return null;
        }
    }

    async openImportExcelModal() {
        const data = await this._$modalService.triggerImportExcelModal(false);
        if (!data) {
            return;
        }

        const projectsToImport = this._$projectService.excelToJson(data);

        this._LoaderService.open(`
                    <p>${this._translate('shared.downloadingFile')}</p>
                    <p>${this._translate('shared.timeConsuming')}</p>
                `);

        const importedProjectPromises = projectsToImport.map((projectToImport) =>
            this.importProjectFromExcel(projectToImport),
        );

        await Promise.all(importedProjectPromises);
        this._LoaderService.dismiss();
        this._$state.reload();
    }

    filterSearch(search) {
        return (project) =>
            project.name.toLocaleLowerCase().indexOf(search) > -1 ||
            (angular.isString(project.description) && project.description.toLocaleLowerCase().indexOf(search) > -1);
    }

    filterControlConfiguration(controlConfiguration) {
        return (project) => {
            const confProject = project.controlConfigurations.map((conf) => conf._id);
            if (controlConfiguration.some((c) => c === this.noControlConfiguration.key)) {
                return (
                    angular.isUndefined(project.controlConfigurations) ||
                    project.controlConfigurations === null ||
                    (angular.isArray(project.controlConfigurations) && project.controlConfigurations.length === 0)
                );
            }

            return (
                controlConfiguration.length === 0 ||
                (project.controlConfigurations && controlConfiguration.some((conf) => confProject.includes(conf)))
            );
        };
    }

    filterSubCompany(subCompany) {
        return (project) => {
            if (subCompany === this.noSubCompany.key) {
                return project.hasSubCompanies === false || project.hasSubCompanies === null;
            }

            return subCompany === '' || project.assignments?.some(({ company }) => subCompany === company);
        };
    }

    filterDeliveryZone(deliveryZone) {
        return (project) => {
            const deliveryProject = project.deliveryZones.map((zone) => zone._id);

            if (deliveryZone.some((z) => z === this.noDeliveryZone.key)) {
                return (
                    angular.isUndefined(project.deliveryZones) ||
                    project.deliveryZones === null ||
                    (angular.isArray(project.deliveryZones) && project.deliveryZones.length === 0)
                );
            }

            return (
                deliveryZone.length === 0 ||
                (project.deliveryZones && deliveryZone.some((zone) => deliveryProject.includes(zone)))
            );
        };
    }

    filterActive(active) {
        return (project) =>
            active === '' ||
            (active === 'false' && project.isActive === false) ||
            (active === 'true' && project.isActive === true);
    }

    setScopeProjectWithFilters() {
        if (this.tableSelect) {
            this.tableSelect.empty();
        }

        const { active, controlConfiguration, deliveryZone, subCompany, search } = this.filter;

        this._$state.go('.', {
            search,
            controlConfiguration: controlConfiguration.join(','),
            deliveryZone: deliveryZone.join(','),
            subCompany,
            active,
        });

        this.filteredProjectList = this.projectList
            .filter(this.filterSearch(search.toLocaleLowerCase()))
            .filter(this.filterControlConfiguration(controlConfiguration))
            .filter(this.filterDeliveryZone(deliveryZone))
            .filter(this.filterSubCompany(subCompany))
            .filter(this.filterActive(active));

        this.prepareFilters(this.filteredProjectList);
    }

    cancelFilters() {
        this.filter.active = '';
        this.filter.controlConfiguration = [];
        this.filter.deliveryZone = [];
        this.filter.subCompany = '';
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    selectedProjectHasDependencies() {
        const selectProject = this.projectList.filter(this.tableSelect.isActive);

        return selectProject.some((project) => project.countDeposits > 0);
    }

    /**
     * @return {Promise<any[]>}
     */
    async getAllProjects() {
        try {
            return (await this._projectApi.listWithHttpInfo()).response.body;
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    prepareFilters(projectList) {
        const controlConfigurationList = new Map();
        const deliveryZoneList = new Map();
        const subCompanyIds = [];

        projectList.forEach((project) => {
            const { controlConfigurations, deliveryZones, assignments } = project;

            controlConfigurations.forEach((controlConfiguration) => {
                if (controlConfiguration?.id && controlConfiguration.name) {
                    controlConfigurationList.set(controlConfiguration.id, controlConfiguration.name);
                }
            });
            deliveryZones.forEach((deliveryZone) => {
                if (deliveryZone?.id && deliveryZone.name) {
                    deliveryZoneList.set(deliveryZone.id, deliveryZone.name);
                }
            });
            assignments.forEach(({ company }) => {
                if (company && company !== this.companyId && subCompanyIds.indexOf(company) === -1) {
                    subCompanyIds.push(company);
                }
            });
        });

        this.filterValues.controlConfigurationList = Array.from(controlConfigurationList, ([key, value]) => ({
            key,
            value,
        }));

        this.filterValues.deliveryZoneList = Array.from(deliveryZoneList, ([key, value]) => ({
            key,
            value,
        }));

        this.filterValues.subCompaniesList = subCompanyIds
            .map((subCompanyId) => {
                const subCompanyData = this.subCompanies.find((subCompany) => subCompany.id === subCompanyId);
                if (!subCompanyData) {
                    return null;
                }

                return {
                    key: subCompanyData.id,
                    value: subCompanyData.name,
                };
            })
            .filter((item) => item);
    }

    removeTooltip() {
        if (!this.tableSelect.hasSelected()) {
            return '';
        } else if (this.selectedProjectHasDependencies()) {
            return this._translate('projectList.projectsAttachedToDeliverable');
        } else {
            return this._translate('shared.deleteSelection');
        }
    }

    async exportProjectToExcel(projectId) {
        try {
            const project = await this._projectProvider.get(projectId);

            const fileName = project.name;
            const data = this._$projectService.jsonToExcel(project);

            return { fileName, data };
        } catch (error) {
            throw new Error(error);
        }
    }

    onExportProjects() {
        const projects = this.projectList.filter(this.tableSelect.isActive);
        if (projects.length === 0) {
            return;
        }

        return Promise.all(projects.map((project) => this.exportProjectToExcel(project.id))).then((results) => {
            results.sort((a, b) => Object.keys(b.data).length - Object.keys(a.data).length);

            return this._$excelService.downloadXLSX(
                { headers: true },
                results.map((result) => result.data),
                'projets',
                'projectList.projectsExported',
            );
        });
    }

    async removeProjects() {
        const selectedProjects = this.projectList.filter(this.tableSelect.isActive);
        if (selectedProjects.length < 1) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.project', {
                COUNT: selectedProjects.length,
                NAME: selectedProjects[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedProjectIds = selectedProjects.map((selectedProject) => selectedProject.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedProjectIds,
            (projectId) => this._projectProvider.delete(projectId),
            'removeModal.successProject',
        );

        await this.initData();
    }
}

angular.module('dotic').controller('ProjectListController', ProjectListController);
