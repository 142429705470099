import { group as radashGroup, sum as radashSum } from 'radash';

import { ChartService } from '../../../services/chart.service';
import { GeneralBarChartColorEnum } from '../../../services/color.service';
import { GraceKeyEnum } from '../../../models/grace.model';
import template from './cable-length-by-type-and-provider-indicator.html';

class CableLengthByTypeAndProviderIndicatorComponent {
    chartData = { datasets: [], labels: [] };
    data = [];
    designColumnName = GraceKeyEnum.DESIGN;
    loading = false;
    parentRef = null;

    constructor($chartService, $filter) {
        this.stackedBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
    }

    getChartData(data) {
        const valuesGroupByType = radashGroup(data, (value) => value.cbFoType);

        return { datasets: this.getDatasets(valuesGroupByType), labels: Object.keys(valuesGroupByType) };
    }

    getDatasets(values) {
        return [
            ChartService.getTableChartDataset(
                Object.values(values).map((items) => radashSum(items, (item) => item.longueurCable || 0).toFixed(2)),
                this._translate('shared.cable'),
                GeneralBarChartColorEnum.LENGTH,
            ),
        ];
    }
}

angular.module('dotic').component('cableLengthByTypeAndProviderIndicator', {
    controller: CableLengthByTypeAndProviderIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        loading: '<',
        parentRef: '<',
    },
});
