import EnvironmentSettingsService from '../../services/environment-settings.service';
import orangePdf from '../../../assets/resources/Guide_utilisation_Admin-user_V1.72.0_orange.pdf';
import saasPdf from '../../../assets/resources/Guide_utilisation_Admin-user_V1.74.0_21032024.pdf';

class HelpController {
    constructor($state, $scope, $toasterService, $transitions, $timeout, $filter) {
        this._$state = $state;
        this._$scope = $scope;
        this._$toasterService = $toasterService;
        this._$transitions = $transitions;
        this._$timeout = $timeout;
        this._translate = $filter('translate');
        this.isOrange = EnvironmentSettingsService.isOrange;

        this.navigation = [];
        this.title = '';
        this.saasPdf = saasPdf;
        this.orangePdf = orangePdf;
    }

    $onInit() {
        this.setNavigation();
        this._$transitions.onSuccess({}, () => {
            this.setNavigation();
        });
    }

    setNavigation() {
        let title = this._translate('shared.help');
        let newNavigation = [];

        if (this._$state.current.data.title) {
            title = this._$state.current.data.title;
            this._$scope.$emit('updateNavigation', {
                newPage: [{ title: `${this._translate('shared.help')} - ${title}` }],
            });
        }

        if (angular.isDefined(this._$state.current.data.navigation)) {
            const navigation = angular.copy(this._$state.current.data.navigation);
            navigation.push({
                title: this._$state.current.data.title,
                sref: this._$state.current.name,
            });
            newNavigation = navigation;
        }

        this._$timeout(() => {
            this.navigation = newNavigation;
            this.title = title;
        });
    }

    isPreviousDisable() {
        return angular.isUndefined(this._$state.current.data.previous);
    }

    getPrevious() {
        return this._$state.href(this._$state.current.data.previous);
    }

    isNextDisable() {
        return angular.isUndefined(this._$state.current.data.next);
    }

    getNext() {
        return this._$state.href(this._$state.current.data.next);
    }
}

angular.module('dotic').controller('HelpController', HelpController);
