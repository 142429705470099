angular.module('dotic').factory('aggregationProvider', aggregationProvider);

function aggregationProvider(ApiProvider, RestProvider, URI_KEYS) {
    return {
        delete: deleteAggregation,
        getAll: getAllAggregations,
        generate: generateAggregation,
        download: downloadAggregation,
    };

    /**
     * DELETE /aggregations/:aggregationId
     *
     * @param {string} aggregationId
     *
     * @returns {*} Promise
     */
    function deleteAggregation(aggregationId) {
        return ApiProvider.one(URI_KEYS.AGGREGATION, aggregationId).remove();
    }

    /**
     * GET /aggregations/
     *
     * @returns {*} Promise
     */
    function getAllAggregations() {
        return ApiProvider.one(URI_KEYS.AGGREGATION).get();
    }

    /**
     * POST /aggregations/
     *
     * @return {*}
     */
    function generateAggregation(params) {
        return ApiProvider.one(URI_KEYS.AGGREGATION).customPOST(params);
    }

    /**
     * GET /aggregations/:aggregationId/download
     *
     * @param id
     * @return {*}
     */
    function downloadAggregation(id) {
        const url = `${URI_KEYS.AGGREGATION}/${id}/download`;

        return RestProvider.downloadFromPath(url);
    }
}
