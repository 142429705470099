function fillingStatisticsService($chartService, $filter) {
    const humanizeSeverity = $filter('humanizeSeverity');
    const translate = $filter('translate');

    return {
        prepareActiveTableByDeposit,
        preparedChartPercentByPart,
        preparedHistoryChart,
        getHorizontalBarChartFilledOptions,
        getHorizontalBarChartWithLabels,
        preparedSynthesisControl,
        preparationDataForExcel,
        makeDateFileName,
        makeNameStatistics,
    };

    function preparedChartPercentByPart(depositSynthesis) {
        if (!angular.isArray(depositSynthesis)) {
            throw new Error("depositSynthesis isn't a array");
        }

        const depositsRequired = initPercentPart();
        const depositsOptionalTestable = initPercentPart();
        const depositsOptionalNoTestable = initPercentPart();

        depositSynthesis.forEach(({ percentageFill, required, testableCondition }) => {
            if (angular.equals(required, 'optional') && angular.equals(testableCondition, true)) {
                aggregatePercentByPart(depositsOptionalTestable, percentageFill);
            }

            if (
                angular.equals(required, 'optional') &&
                (angular.equals(testableCondition, false) ||
                    angular.equals(testableCondition, null) ||
                    angular.equals(testableCondition, undefined))
            ) {
                aggregatePercentByPart(depositsOptionalNoTestable, percentageFill);
            }

            if (angular.equals(required, 'required')) {
                aggregatePercentByPart(depositsRequired, percentageFill);
            }
        });

        const dataSetCommon = {
            categoryPercentage: 0.9,
            barPercentage: 0.9,
            borderWidth: 0.5,
            maxBarThickness: 18,
            borderColor: '#fff',
        };

        return {
            labels: [
                translate('status.required', { COUNT: 1 }),
                translate('shared.testableConditional'),
                translate('shared.conditionalUntestable'),
            ],
            datasets: [
                {
                    ...dataSetCommon,
                    label: '0-25%',
                    backgroundColor: '#e971b1',
                    data: [
                        depositsRequired.zeroToTwentyFive,
                        depositsOptionalTestable.zeroToTwentyFive,
                        depositsOptionalNoTestable.zeroToTwentyFive,
                    ].map((el) => (el ? el.toFixed(0) : null)),
                    datalabels: {
                        align: 'top',
                        anchor: 'center',
                        padding: '24',
                        color: '#e971b1',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    ...dataSetCommon,
                    label: '25-50%',
                    backgroundColor: '#fdd41e',
                    hoverBackgroundColor: '#ffe602',
                    data: [
                        depositsRequired.twentyFiveToFifty,
                        depositsOptionalTestable.twentyFiveToFifty,
                        depositsOptionalNoTestable.twentyFiveToFifty,
                    ].map((el) => (el ? el.toFixed(0) : null)),
                    datalabels: {
                        align: 'top',
                        anchor: 'center',
                        padding: '33',
                        color: '#edc600',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    ...dataSetCommon,
                    label: '50-75%',
                    backgroundColor: '#5fb9b9',
                    hoverBackgroundColor: '#54c4c4',
                    data: [
                        depositsRequired.fiftyToSeventyFive,
                        depositsOptionalTestable.fiftyToSeventyFive,
                        depositsOptionalNoTestable.fiftyToSeventyFive,
                    ].map((el) => (el ? el.toFixed(0) : null)),
                    datalabels: {
                        align: 'top',
                        anchor: 'center',
                        padding: '24',
                        color: '#5fb9b9',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    ...dataSetCommon,
                    label: '75-100%',
                    backgroundColor: '#a0d468',
                    data: [
                        depositsRequired.seventyFiveToHundred,
                        depositsOptionalTestable.seventyFiveToHundred,
                        depositsOptionalNoTestable.seventyFiveToHundred,
                    ].map((el) => (el ? el.toFixed(0) : null)),
                    datalabels: {
                        align: 'top',
                        anchor: 'center',
                        padding: '33',
                        color: '#a0c973',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };
    }

    function initPercentPart() {
        return {
            zeroToTwentyFive: 0,
            twentyFiveToFifty: 0,
            fiftyToSeventyFive: 0,
            seventyFiveToHundred: 0,
        };
    }

    function aggregatePercentByPart(synthesisPercent, percentageFill) {
        if (!angular.isObject(synthesisPercent) || !angular.isNumber(percentageFill)) {
            throw new Error(
                `${angular.isObject(synthesisPercent) ? "synthesisPercent isn't a object" : ''} ${
                    angular.isNumber(percentageFill) ? "synthesisPercent isn't a number" : ''
                }`,
            );
        }

        if (percentageFill >= 0 && percentageFill <= 25) {
            synthesisPercent.zeroToTwentyFive++;
        }

        if (percentageFill > 25 && percentageFill <= 50) {
            synthesisPercent.twentyFiveToFifty++;
        }

        if (percentageFill > 50 && percentageFill <= 75) {
            synthesisPercent.fiftyToSeventyFive++;
        }

        if (percentageFill > 75 && percentageFill <= 100) {
            synthesisPercent.seventyFiveToHundred++;
        }
    }

    function prepareActiveTableByDeposit(deposits) {
        if (!angular.isArray(deposits)) {
            throw new Error("Deposit isn't a array");
        }

        const labels = [];
        const disables = [];
        const enable = [];
        deposits.forEach(({ zipName, controls }) => {
            const disable = controls.filter(({ totalCount }) => totalCount === 0).length;
            const total = controls.length;
            labels.push(zipName);
            disables.push(disable);
            enable.push(total - disable);
        });

        const dataSetCommon = { categoryPercentage: 0.7, barPercentage: 0.4 };

        return {
            labels,
            datasets: [
                {
                    ...dataSetCommon,
                    backgroundColor: '#a0d468',
                    data: enable.map((el) => (el ? el.toFixed(0) : null)),
                    label: translate('shared.filled'),
                    datalabels: {
                        align: 'center',
                        anchor: 'center',
                        padding: '0',
                        color: '$darkest-gray',
                        clamp: true,
                    },
                },
                {
                    ...dataSetCommon,
                    backgroundColor: '#e971b1',
                    data: disables.map((el) => (el ? el.toFixed(0) : null)),
                    label: translate('shared.empty'),
                    datalabels: {
                        align: 'center',
                        anchor: 'center',
                        padding: '0',
                        color: '$darkest-gray',
                        clamp: true,
                    },
                },
            ],
        };
    }

    function preparedHistoryChart(deposit) {
        if (!deposit) {
            throw new Error('Deposit is undefined');
        }

        const oldVersions = deposit.oldVersions || [];
        const history = [deposit, ...oldVersions].sort((a, b) => a.version - b.version);

        const title = `${translate('shared.historyFor', {
            NAME: deposit.depositName,
        })}`;

        const percentageFill = history.map(({ percentageFill }) => percentageFill);
        const emptyPercentage = history.map(({ emptyPercentage }) => emptyPercentage);

        const dataSetCommon = {
            borderWidth: 2,
            lineTension: 0,
            fill: true,
            pointHitRadius: 30,
        };

        return {
            type: 'line',
            options: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    position: 'top',
                    text: title,
                },
                tooltips: { enabled: true, mode: 'label' },
                scales: {
                    y: { ticks: { beginAtZero: true, maxTicksLimit: 6 } },
                },
            },
            data: {
                labels: history.map(({ version, createdAt }) => {
                    if (moment(createdAt).isValid()) {
                        return `V${version} - ${moment(createdAt).format('DD/MM/YYYY')}`;
                    }

                    return `${translate('shared.version')} ${version}`;
                }),
                datasets: [
                    {
                        ...dataSetCommon,
                        backgroundColor: '#b5f076',
                        borderColor: '#a0d468',
                        data: percentageFill,
                        label: `%${translate('shared.successful')}`,
                    },
                    {
                        ...dataSetCommon,
                        backgroundColor: '#e971b1',
                        borderColor: '#ff508c',
                        data: emptyPercentage,
                        hidden: true,
                        label: `%${translate('shared.error', { COUNT: 1 }).toLowerCase()}`,
                    },
                ],
            },
        };
    }

    function preparedSynthesisControl(fillingStatistics) {
        if (!fillingStatistics) {
            throw new Error('fillingStatistics is undefined');
        }

        return fillingStatistics.synthesisControl.map((elementFiltered) => {
            const depositFiltered = [];
            fillingStatistics.deposits.forEach((deposit) => {
                if (deposit.controls) {
                    depositFiltered.push({
                        ...deposit.controls.find((elementCompared) => {
                            return elementCompared.attributeName === elementFiltered.attributeName;
                        }),
                        reportId: deposit.report,
                        version: deposit.version,
                        depositName: deposit.name,
                        depositId: deposit.deposit,
                        depositZip: deposit.zipName,
                        createdAt: deposit.createdAt,
                    });
                }
            });

            return {
                ...elementFiltered,
                totalCount: elementFiltered.totalCount * elementFiltered.numberFoundValue,
                anomalyCount: elementFiltered.anomalyCount * elementFiltered.numberFoundValue,
                successCount: elementFiltered.successCount * elementFiltered.numberFoundValue,
                depositSynthesis: depositFiltered,
            };
        });
    }
    function getHorizontalBarChartFilledOptions() {
        return {
            responsive: true,
            tooltips: {
                enabled: false,
            },
            scales: $chartService.getDefaultScaleConfiguration(true, { fontSize: 12, minRotation: 8 }),
        };
    }

    function getHorizontalBarChartWithLabels() {
        return {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(true, { fontSize: 12, minRotation: 8 }),
        };
    }

    function mapKeyToUpperCase(obj) {
        if (!angular.isObject(obj)) {
            throw new Error("obj it isn't a object");
        }

        const objConvertedToLowerCase = {};
        for (const [key, value] of Object.entries(obj)) {
            objConvertedToLowerCase[key.toUpperCase()] = value;
        }

        return objConvertedToLowerCase;
    }

    function preparationDataForExcel(controlsSynthesis, names, keySelected) {
        return controlsSynthesis.map(({ attributeName, tableName, ...rest }) => {
            return mapKeyToUpperCase({
                [translate('shared.attribute', { COUNT: 1 })]: attributeName,
                [translate('shared.table')]: tableName,
                [translate('statisticsPage.differenceBetweenStatistics')]: getDifferenceType({
                    ...names,
                    ...rest,
                }),
                ...prepareDataTwoStatistics(rest, names, keySelected),
            });
        });
    }

    function prepareDataTwoStatistics(statistics, names, keySelected) {
        let result = {};
        const attributesInTable = preparedListAttributes(keySelected);

        for (const attribute of attributesInTable) {
            result = {
                ...result,
                ...getValueOfTwoStatistics(statistics, names, attribute),
            };
        }

        return result;
    }

    function preparedListAttributes(keySelected = '') {
        const listDefaultAttributes = ['severity', 'successCount', 'anomalyCount'];
        const listAllAttributes = [
            'description',
            'severity',
            'required',
            'totalCount',
            'successCount',
            'anomalyCount',
            'percentageFill',
            'minPercentageFill',
            'maxPercentageFill',
            'emptyPercentage',
        ];

        return keySelected !== '' ? Array.from(new Set([...listDefaultAttributes, keySelected])) : listAllAttributes;
    }

    function getValueOfTwoStatistics({ statisticsOrigin, statisticsSelected }, { nameOrigin, nameSelected }, key) {
        let translation;
        switch (key) {
            case 'percentageFill':
                translation = translate('shared.fillingRate', { SUFFIX: 'average' });
                break;
            case 'minPercentageFill':
                translation = translate('shared.fillingRate', { SUFFIX: 'min' });
                break;
            case 'maxPercentageFill':
                translation = translate('shared.fillingRate', { SUFFIX: 'max' });
                break;
            default:
                translation = translate(getKeyI18n(key));
                break;
        }

        return {
            [`${translation}_${nameOrigin}`]: translateValue(key, statisticsOrigin[key]),
            [`${translation}_${nameSelected}`]: translateValue(key, statisticsSelected[key]),
        };
    }

    function translateValue(key, value) {
        switch (key) {
            case 'severity':
                return angular.isDefined(value) ? humanizeSeverity(value) : '-';
            case 'required':
                return angular.isDefined(value) ? translate(`status.${value}`) : '-';
            case 'percentageFill':
            case 'minPercentageFill':
            case 'maxPercentageFill':
            case 'emptyPercentage':
                return angular.isUndefined(value) ? '' : `${value}%`;
            default:
                return value;
        }
    }

    function getKeyI18n(key) {
        switch (key) {
            case 'severity':
                return 'shared.severity';
            case 'description':
                return 'shared.description';
            case 'anomalyCount':
                return 'shared.nbAnomalies';
            case 'successCount':
                return 'shared.numberOfSuccesses';
            case 'emptyPercentage':
                return 'shared.fillingErrorRate';
            case 'required':
                return 'shared.status';
            case 'totalCount':
                return 'shared.registrationNumber';
            default:
                throw new Error('Not available i18n key');
        }
    }

    function makeNameStatistics(statisticsDate) {
        return moment(statisticsDate).format('DD/MM/YYYY HH:mm');
    }

    function makeDateFileName(statisticsDate) {
        return moment(statisticsDate).format('DD-MM-YYYY-HH-mm');
    }

    function getDifferenceType({
        nameOrigin,
        nameSelected,
        isDifferent,
        isSame,
        numberOfDifferent,
        onlyOrigin,
        onlySelected,
    }) {
        if (isSame) {
            return translate('shared.same', { COUNT: 1 });
        }

        if (isDifferent) {
            return numberOfDifferent;
        }

        if (onlyOrigin) {
            return `${translate('shared.exclusivelyIn')} ${nameOrigin}`;
        }

        if (onlySelected) {
            return `${translate('shared.exclusivelyIn')} ${nameSelected}`;
        }

        throw new Error('Difference not found');
    }
}

angular.module('dotic').factory('$fillingStatisticsService', fillingStatisticsService);
