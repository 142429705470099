angular.module('dotic').factory('historyProvider', historyProvider);

function historyProvider(ApiProvider, URI_KEYS) {
    return {
        getAll,
    };

    /**
     * POST /history
     * @param {{page: number, limit: number}} queryParams
     * @returns {*}
     */
    function getAll(queryParams) {
        return ApiProvider.one(URI_KEYS.HISTORY).customGET(null, queryParams);
    }
}
