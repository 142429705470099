import template from './select.html';

class CcSelectController {
    shouldShowError() {
        return this.form[this.name].$touched && this.form[this.name].$invalid;
    }
}

angular.module('dotic').component('ccSelect', {
    templateUrl: template,
    controller: CcSelectController,
    bindings: {
        form: '<',
        label: '@',
        model: '=',
        name: '@',
        required: '=',
        options: '<',
        placeholder: '@',
        readonly: '<',
    },
});
