import template from './gridDetails.html';

class ToggleController {
    opened = false;

    constructor($gridService) {
        this._$gridService = $gridService;
    }

    isOpen() {
        return this.opened;
    }
    toggle() {
        this.opened = !this.opened;
    }
}

angular.module('dotic').component('gridDetails', {
    controller: ToggleController,
    templateUrl: template,
    transclude: true,
    bindings: {
        dataLength: '<',
        dataModels: '<',
        description: '<',
        isAdvanced: '<',
        isControlGrid: '<',
        isTemplate: '<',
        name: '<',
        phases: '<',
        possibleActions: '<',
        templateParentName: '<',
    },
});
