import { group as radashGroup, max as radashMax, sum as radashSum } from 'radash';

export const FieldOccurrenceFieldEnum = {
    AD_IMNEUF: 'ad_imneuf',
    AD_ISOLE: 'ad_isole',
    AD_PRIO: 'ad_prio',
};

// This field doesn't come from backend
export const FieldOccurrenceAdditionalFieldEnum = {
    NON_SPECIFIC: 'nonSpecific',
    ADDRESS_COUNT: 'addressCount',
};

export const FieldOccurrenceKeyEnum = {
    FIELD: 'field',
    OCCURRENCE_COUNT: 'occurrenceCount',
    VALUE: 'value',
};

class IndicatorFieldOccurrenceService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    getElementsGroupedByField(elements) {
        return radashGroup(elements, (currentData) => currentData[FieldOccurrenceKeyEnum.FIELD]);
    }

    getFieldTranslation(field, value) {
        switch (field) {
            case FieldOccurrenceFieldEnum.AD_IMNEUF:
                return this._translate('shared.newBuilding');
            case FieldOccurrenceFieldEnum.AD_ISOLE:
                return this._translate('shared.isolatedLocals');
            case FieldOccurrenceFieldEnum.AD_PRIO:
                return this._translate('shared.prioritySiteConnection');
            case FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC:
                return this._translate('shared.addressWithoutSpecificities', { COUNT: value });
            case FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT:
                return this._translate('shared.addressCount');
            default:
                return field;
        }
    }

    getOccurrenceCount(elementsGroupedByField) {
        return (
            radashMax(
                Object.values(elementsGroupedByField).map((currentValues) =>
                    radashSum(currentValues, (value) => value[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]),
                ),
            ) ?? 0
        );
    }

    prepareFieldOccurrenceIndicator(indicator) {
        const valuesGroupedByField = this.getElementsGroupedByField(indicator);

        const occurrenceCount = this.getOccurrenceCount(valuesGroupedByField);

        const formattedValues = Object.values(valuesGroupedByField).map((values) => {
            if (!values.length) {
                return {};
            }

            return {
                [FieldOccurrenceKeyEnum.FIELD]: this.getFieldTranslation(values[0][FieldOccurrenceKeyEnum.FIELD]),
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]: radashSum(
                    values.filter((value) => value[FieldOccurrenceKeyEnum.VALUE]),
                    (currentData) => currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            };
        });

        return [
            ...formattedValues,
            {
                [FieldOccurrenceKeyEnum.FIELD]: this.getFieldTranslation(
                    FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
                ),
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]: occurrenceCount,
            },
            {
                [FieldOccurrenceKeyEnum.FIELD]: this.getFieldTranslation(
                    FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC,
                ),
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]:
                    occurrenceCount -
                    radashSum(
                        formattedValues,
                        (formattedValue) => formattedValue[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                    ),
            },
        ];
    }
}

angular
    .module('dotic')
    .factory('$indicatorFieldOccurrenceService', ($filter) => new IndicatorFieldOccurrenceService($filter));
