import { HelpersService } from '../../services/helpers.service';
import template from './fillingStatistics.html';

class FillingStatisticsPage {
    controlConfigurationFilterValues = [];
    controlConfigurations = [];
    deliveryZoneFilterValues = [];
    deliveryZones = [];
    fillGrid = [];
    fillGridFilterValues = [];
    fillingStatistics = [];
    generateFilter = {
        deliveryZones: [],
        fillGrid: null,
        from: null,
        networkSegments: [],
        phases: [],
        projectIds: [],
        projects: [],
        subCompanies: [],
        to: null,
        users: [],
        zipNames: [],
    };
    generateLoading = true;
    loading = true;
    page = 0;
    pageSize = 10;
    projectFilterValues = [];
    projectIdFilterValues = [];
    sort = { column: 'createdAt', order: -1 };
    subCompanyFilterValues = [];
    totalCount = 0;
    userFilterValues = [];

    constructor(
        $auth,
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $phaseService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        companyProvider,
        controlConfigurationProvider,
        deliveryZoneProvider,
        fillingStatisticsProvider,
        projectProvider,
        statisticsProvider,
        userMetricsProvider,
    ) {
        this._companyProvider = companyProvider;
        this._$deleteService = $deleteService;
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._fillingStatisticsProvider = fillingStatisticsProvider;
        this._projectProvider = projectProvider;
        this._statisticsProvider = statisticsProvider;
        this._userMetricsProvider = userMetricsProvider;
        this.isAllowed = $authorizationService.isAllowed;
        this._$modalService = $modalService;

        this.addNavigationPage($scope, $location);
        this.phaseFilterValues = $phaseService.getAll().map((value) => ({ key: value, value }));
        this.companyId = $auth.getPayload().company;

        this.tableDetail = $tableService.detail();
        this.tableSelect = $tableService.select();
    }

    async $onInit() {
        try {
            this._userMetricsProvider.listFillingStatistics();
            this.fillingStatistics = await this.getFillingStatistics();

            await Promise.all([
                this.setProjects(),
                this.setFileNames(),
                this.setProjectIds(),
                this.setControlConfigurations(),
                this.setUsers(),
            ]);
            await this.getFillGrids();
        } catch (error) {
            this._$toasterService.error(error);
        }
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
            this.generateLoading = false;
        });
    }

    addNavigationPage($scope, $location) {
        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'fillingStatistics',
                    title: this._translate('shared.fillingStatistic'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async getFillingStatistics() {
        try {
            const results = await this._fillingStatisticsProvider.list({
                limit: this.pageSize,
                page: this.page - 1,
                order: this.sort.order,
                orderBy: this.sort.column,
            });
            this.totalCount = results.totalCount;

            return results.data;
        } catch (error) {
            this._$toasterService.error(error);
            this.totalCount = 0;
        }
    }

    async onPaginationChange(page, pageSize, sort) {
        this.pageSize = pageSize;
        this.page = page;
        this.sort = sort;

        this.loading = true;
        this.fillingStatistics = await this.getFillingStatistics();

        this._$timeout(() => (this.loading = false));
    }

    async setProjectIds() {
        const deliveryZones = await this._deliveryZoneProvider.list();
        this.deliveryZones = deliveryZones;
        this.projectIdFilterValues = deliveryZones.reduce((acc, { projectId }) => {
            if (projectId && !acc.find((a) => a.key === projectId)) {
                acc.push({ key: projectId, value: projectId });
            }

            return acc;
        }, []);
    }

    async setProjects() {
        this.projects = await this._projectProvider.getAll();
        this.projectFilterValues = HelpersService.toKeyValue(this.projects);
    }

    async setFileNames() {
        const fileNames = await this._statisticsProvider.listFileNames();
        this.zipNameFilterValues = fileNames.map((name) => ({
            key: name,
            value: name,
        }));
    }

    async setControlConfigurations() {
        const configurations = await this._controlConfigurationProvider.getAll();
        this.controlConfigurations = configurations.filter(
            (configuration) => configuration.fillGrid && configuration.controlGrid,
        );
    }

    async setUsers() {
        try {
            const users = await this._companyProvider.getEmployeeDetailsForCompany(this.companyId);
            this.userFilterValues = users.map((user) => ({
                key: user.id,
                value: user.fullname,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async getFillGrids() {
        const allFillGrid = await this._companyProvider.getAllGridFillName(this.companyId);
        this.fillGrids = this.controlConfigurations.reduce((acc, { _id, fillGrid }) => {
            const fillGridFound = allFillGrid.find(({ id }) => id === fillGrid._id);
            if (fillGridFound && fillGrid && !acc.some(({ key }) => key === fillGrid._id)) {
                acc.push({
                    key: fillGridFound.id,
                    value: fillGridFound.fullName,
                    idControlConfiguration: _id,
                });
            }

            return acc;
        }, []);
        this.fillGridFilterValues = angular.copy(this.fillGrids);
    }

    // Select rows
    selectAllHistories() {
        this._$scope.$broadcast('getVisibleRows');
    }

    getSelectionType() {
        const size = this.tableSelect.selectionSize();
        if (size === this.fillingStatistics.length) {
            return 'all';
        }

        if (size === 0) {
            return 'none';
        }

        if (size === 10) {
            return 'visible';
        }

        return 'few';
    }

    prepareParams(params) {
        return {
            ...params,
            controlGrid: this.controlConfigurations.find(
                (controlConfiguration) => controlConfiguration.fillGrid.id === params.fillGrid,
            ).controlGrid.id,
            from: moment(params.from).utc().format(),
            to: (params.to === '' ? moment() : moment(params.to)).utc().format(),
        };
    }

    async generateStatistics() {
        const modal = this._$uibModal.open({
            component: 'statisticsFilterModal',
            backdrop: 'static',
            size: 'xl',
            resolve: {
                projectList: () => this.projectFilterValues,
                projectIdList: () => this.projectIdFilterValues,
                deliveryZones: () => this.deliveryZones,
                deliveryZoneList: () => this.deliveryZoneFilterValues,
                phaseList: () => this.phaseFilterValues,
                subCompaniesList: () => this.subCompanyFilterValues,
                controlConfigurations: () => this.controlConfigurations,
                controlConfigurationList: () => this.controlConfigurationFilterValues,
                zipNameList: () => this.zipNameFilterValues,
                fillGridList: () => this.fillGridFilterValues,
                userList: () => this.userFilterValues,
                generateFilter: () => this.generateFilter,
            },
        });
        const modalData = await modal.result.then(
            (_modalData) => _modalData,
            () => {},
        );

        if (!modalData) {
            return;
        }

        this.generateFilter = modalData.filters;

        if (!modalData.params) {
            return;
        }

        try {
            this.generateLoading = true;
            this._userMetricsProvider.generateFillingStatistics();
            const from = modalData.params.from;
            const to = modalData.params.to;

            const endAt = moment(from);
            const startAt = moment(to);

            if (!startAt.isValid() || !endAt.isValid() || endAt.isAfter(startAt)) {
                this._$toasterService.error(this._translate('statisticsPage.incorrectDate'));

                return;
            }

            const data = await this._fillingStatisticsProvider.generate(this.prepareParams(modalData.params));
            if (data?.exists && !data.i18n) {
                this._$toasterService.success({
                    title: this._translate('statisticsPage.statisticsExisting'),
                    body: 'history-exists-toaster',
                    bodyOutputType: 'directive',
                    directiveData: { existingId: data.id },
                });
            }

            if (data.i18n) {
                this._$toasterService.info({
                    body: this._translate(`toaster.${data.i18n}`),
                });
            }

            this.fillingStatistics = await this.getFillingStatistics();

            this.loading = false;
            this.generateLoading = false;
        } catch (error) {
            this.generateLoading = false;
            if (error.data?.i18n) {
                this._$toasterService.error({
                    body: this._translate(`toaster.${error.data.i18n}`),
                });

                return;
            }

            this._$toasterService.error(this._translate('toaster.error'));
        }
    }
    async removeStatistics() {
        const selectedFillingStatistics = this.fillingStatistics.filter(this.tableSelect.isActive);

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.fillingStatistic', {
                COUNT: selectedFillingStatistics.length,
                NAME: selectedFillingStatistics[0].statisticName,
            }),
        );
        if (!isAccepted) {
            return;
        }

        this.loading = true;

        const selectedFillingStatisticIds = selectedFillingStatistics.map(
            (selectedFillingStatistic) => selectedFillingStatistic._id,
        );
        await this._$deleteService.deleteArrayOfIds(
            selectedFillingStatisticIds,
            (fillingStatisticId) => this._fillingStatisticsProvider.remove(fillingStatisticId),
            'removeModal.successStatistic',
        );

        this.fillingStatistics = await this.getFillingStatistics();

        this.loading = false;
    }

    compareWith(fillingStatistic) {
        this._$state.go('app.comparisonPage', {
            fillStat: fillingStatistic._id,
        });
    }

    showDetails(statistics) {
        this.tableDetail.toggle(statistics);
    }
}

angular
    .module('dotic')
    .component('fillingStatisticsPage', {
        controller: FillingStatisticsPage,
        templateUrl: template,
    })
    .directive('historyExistsToaster', () => ({
        link: (scope) => {
            const { existingId } = scope.directiveData;
            scope.id = existingId;
        },
        template:
            '<a data-ui-sref="app.fillingStatisticDetail({ fillingStatisticId: id })"><i class="icofont icofont-eye"></i> Voir la statistique</a>',
    }));
