import { HelpersService } from '../../../services/helpers.service';
import template from './generateExportGeoPackageModal.html';

class GenerateExportGeoPackageModalComponent {
    companyId = null;
    dataModels = [];
    filterValues = {
        dataModels: [],
        networkSegments: [],
        phases: [],
        projects: [],
        users: [],
    };
    loading = true;
    phases = [];
    projects = [];
    selectedValues = {
        hasIdLivrable: true,
        dataModel: null,
        from: null,
        isSegmentFilterStrict: true,
        name: null,
        networkSegments: [],
        phases: [],
        projects: [],
        to: null,
        users: [],
    };
    users = [];

    constructor(
        $auth,
        $filter,
        $phaseService,
        $segmentsService,
        $scope,
        $timeout,
        $toasterService,
        companyProvider,
        dataModelProvider,
        projectProvider,
    ) {
        this._companyProvider = companyProvider;
        this._dataModelProvider = dataModelProvider;
        this._projectProvider = projectProvider;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this.companyId = $auth.getPayload().company;

        this.filterValues.networkSegments = $segmentsService.getAll().map((value) => ({ key: value, value: value }));
        this.filterValues.phases = $phaseService.getAll().map((value) => ({ key: value, value: value }));

        // Needed to trigger call to $onChanges in deposits-preview component
        $scope.$watchGroup(
            [
                '$ctrl.selectedValues.dataModel',
                '$ctrl.selectedValues.from',
                '$ctrl.selectedValues.networkSegments',
                '$ctrl.selectedValues.phases',
                '$ctrl.selectedValues.projects',
                '$ctrl.selectedValues.to',
                '$ctrl.selectedValues.users',
            ],
            () => {
                this.selectedValues = { ...this.selectedValues };
            },
        );
    }

    async $onInit() {
        this.loading = true;
        try {
            this.dataModels = await this._dataModelProvider.getAll();
            this.projects = await this._projectProvider.getAll();
            this.users = await this._companyProvider.getEmployeeDetailsForCompany(this.companyId);

            this.filterValues.dataModels = HelpersService.toKeyValue(
                this.dataModels.filter((dataModel) => dataModel.settings.hasGeoPackageExportEnabled),
            );
            this.filterValues.projects = HelpersService.toKeyValue(this.projects);
            this.filterValues.users = HelpersService.toKeyValue(this.users, {
                keyField: 'id',
                valueField: 'fullname',
            });
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        } finally {
            this._$timeout(() => {
                this.loading = false;
            });
        }
    }

    cancel() {
        return this.close();
    }

    isFormValid() {
        if (!this.selectedValues.from || !this.selectedValues.to) {
            return !!this.selectedValues.dataModel && !!this.selectedValues.name;
        }

        const fromDate = moment(this.selectedValues.from);
        const toDate = moment(this.selectedValues.to);
        const isValidDate = toDate.isValid() && fromDate.isValid() && !fromDate.isAfter(toDate);

        return !!this.selectedValues.dataModel && !!this.selectedValues.name && isValidDate;
    }

    submit() {
        if (!this.isFormValid()) {
            return this._$toasterService.error(this._translate('toaster.invalidForm'));
        }

        return this.close({
            $value: this.selectedValues,
        });
    }
}

angular.module('dotic').component('generateExportGeoPackageModal', {
    templateUrl: template,
    controller: GenerateExportGeoPackageModalComponent,
    bindings: {
        close: '&',
    },
});
