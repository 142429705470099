angular.module('dotic').factory('statisticsProvider', statisticsProvider);

function statisticsProvider(ApiProvider, RestProvider, URI_KEYS) {
    return {
        check,
        download,
        downloadByControlPoints,
        generate,
        generateFile,
        generateResults,
        get,
        getGroupedByDeposits,
        list,
        listFileNames,
        remove,
        status,
    };

    /**
     * POST /aggregator/generate
     */
    async function generate(params) {
        const response = await ApiProvider.withConfig((RestangularConfigurer) => {
            RestangularConfigurer.setFullResponse(true);
        })
            .one(URI_KEYS.AGGREGATOR)
            .one('generate')
            .customPOST(params);

        const { status, data } = response;

        if (status === 202) {
            return {
                ...data,
                exists: true,
            };
        }

        return data;
    }

    /**
     * POST /aggregator/:aggregatorId/generate-results
     * @param aggregatorId
     * @return {*}
     */
    function generateResults(aggregatorId) {
        return ApiProvider.one(URI_KEYS.AGGREGATOR, aggregatorId).one('generate-results').post();
    }

    /**
     * GET /aggregator/
     * @param {object} queryParams
     */
    function list(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.AGGREGATOR).one('list').customGET(null, queryParams);
    }

    function listFileNames() {
        return ApiProvider.one(URI_KEYS.AGGREGATOR).one('list-file-names').getList();
    }

    /**
     * GET /aggregator/:aggregatorId
     */
    function get(aggregatorId) {
        return ApiProvider.one(URI_KEYS.AGGREGATOR, aggregatorId).get();
    }

    /**
     * GET /aggregator/:aggregatorId/Status
     */
    function status(aggregatorId) {
        return ApiProvider.one(URI_KEYS.AGGREGATOR, aggregatorId).one('status').get();
    }

    /**
     * Download statistic specific control point by id / params / control point name
     * @param {int} aggregatorId
     * @param {string[]} controlPoints     filter by control point (log|matrix)
     * @return {Promise<*>}
     */
    function downloadByControlPoints(aggregatorId, controlPoints) {
        if (!angular.isArray(controlPoints) || controlPoints.length === 0) {
            throw new Error('Missing controlPoints');
        }

        const path = [URI_KEYS.AGGREGATOR, aggregatorId, 'download-control-points'];

        return RestProvider.downloadFromPath(path, { controlPoints });
    }

    /**
     * Download statistic by id / params
     * @param {int} aggregatorId
     * @param {string} type     get particular export (log|matrix)
     * @return {Promise<*>}
     */
    function download(aggregatorId, type = 'aggregated') {
        const path = [URI_KEYS.AGGREGATOR, aggregatorId, 'download', type];

        return RestProvider.downloadFromPath(path);
    }

    /**
     * Generate statistic file by id / params
     * @param {int} aggregatorId
     * @param {string} type     get particular export (log|matrix)
     * @return {Promise<*>}
     */
    function generateFile(aggregatorId, type = 'aggregated') {
        const path = [URI_KEYS.AGGREGATOR, aggregatorId, 'generate-download', type];

        return RestProvider.get(path);
    }

    function check(aggregatorId) {
        const path = [URI_KEYS.AGGREGATOR, aggregatorId, 'check'];

        return RestProvider.get(path);
    }

    function getGroupedByDeposits(aggregatorId) {
        const path = [URI_KEYS.AGGREGATOR, aggregatorId, 'deposits'];

        return RestProvider.get(path);
    }

    function remove(aggregatorId) {
        return ApiProvider.one(URI_KEYS.AGGREGATOR, aggregatorId).remove();
    }
}
