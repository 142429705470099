import template from './fillGridFromDeposit.html';

class FillGridFromDepositController {
    constructor($scope, $state, $location, $stateParams, $filter, depositProvider, userMetricsProvider) {
        this._$location = $location;
        this._$scope = $scope;
        this._$state = $state;
        this._depositProvider = depositProvider;
        this._translate = $filter('translate');
        this._userMetricsProvider = userMetricsProvider;

        this.depositId = $stateParams.depositId;
        this.deposit = null;
        this.loading = true;
    }

    $onInit() {
        this.loading = true;

        if (!this.depositId) {
            throw new Error('invalid deposit id');
        }

        this._depositProvider
            .get(this.depositId)
            .then((data) => {
                this._userMetricsProvider.openFillingGridDeposit(this.depositId);
                this.deposit = data;

                this.fillGridEnabled = this.deposit.gridFill.filter((item) => item.enable);

                this.fillGridName = `${this.deposit.fillGridName} ${moment(this.deposit.createdAt).format(
                    'DD-MM-YYYY',
                )}`;
            })
            .then(() => {
                this.loading = false;

                this._$scope.$emit('keepPreviousNavigation', {
                    newPage: [
                        {
                            key: 'gridFillFromDeposit',
                            title: `${this._translate('shared.fillGrid', { COUNT: 1 })} ${this.deposit.fillGridName}`,
                            href: this._$location.path(),
                        },
                    ],
                    defaultPrevious: {
                        key: 'report',
                        title: `${this._translate('shared.report', { COUNT: 1 })} ${this.deposit.name}`,
                        href: this._$state.href('app.reportDetail', {
                            reportId: this.deposit.report._id,
                        }),
                    },
                    allowedPreviousKeys: ['report', 'comparaison', 'deposits'],
                });
            });
    }
}

angular.module('dotic').component('fillGridFromDepositPage', {
    controller: FillGridFromDepositController,
    templateUrl: template,
});
