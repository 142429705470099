class ReportFooterComponent {
    constructor() {
        this.loaded = false;
        this.report = null;
    }

    $onInit() {
        this.loaded = false;
        if (!(this.report?.project && this.report.deposit && this.report.deposit.deliveryZone)) {
            return;
        }

        this.loaded = true;
    }
}

angular.module('dotic').component('reportFooterPdf', {
    controller: ReportFooterComponent,
    template: `<div class="report-info">
    <span class="report-name">Rapport de contrôle</span>
    {{$ctrl.report.project.name}} {{$ctrl.report.deposit.deliveryZone.name}} {{$ctrl.report.deposit.phase}}
    v{{$ctrl.report.deposit.version}} | {{$ctrl.report.date | date:"d MMMM yyyy 'à' HH:mm:ss"}}
</div>
<div class="page__footer-number"></div>
`,
    bindings: {
        report: '<',
    },
});
