angular
    .module('dotic', [
        'ngAnimate',
        'ngAria',
        'ngCookies',
        'ngFileUpload',
        'ngMessages',
        'ngResource',
        'ngSanitize',
        'ngTouch',
        'pascalprecht.translate',
        'restangular',
        'rzSlider',
        'satellizer',
        'toaster',
        'ui.bootstrap',
        'ui.router',
        'ui.select',
        'daterangepicker',
    ])
    .constant('LOCALES', {
        locales: {
            fr_FR: 'Français',
            en_US: 'English',
        },
        preferredLocale: 'fr_FR',
    })
    .config(($translateProvider) => {
        $translateProvider.useMissingTranslationHandlerLog();
        $translateProvider.useStaticFilesLoader({
            $http: {
                skipAuthorization: true,
            },
            prefix: '/i18n/locale-', // path to translations files: relative to the build path !
            suffix: '.json', // suffix, currently extension of the translations
        });
        $translateProvider.useMessageFormatInterpolation();
        $translateProvider.preferredLanguage('fr_FR'); // is applied on first load
        $translateProvider.useLocalStorage(); // saves selected language to localStorage
    })
    .config((uibDatepickerPopupConfig) => {
        uibDatepickerPopupConfig.closeText = 'Fermer';
        uibDatepickerPopupConfig.currentText = "Aujourd'hui";
        uibDatepickerPopupConfig.clearText = 'Effacer';
    })
    .config(($locationProvider) => {
        $locationProvider.html5Mode(true);
    });
// .config((tmhDynamicLocaleProvider) => {
//     tmhDynamicLocaleProvider.localeLocationPattern('node_modules/angular-i18n/angular-locale_{{locale}}.js');
// });
