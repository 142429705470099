/**
 * @class TableService
 */
class TableService {
    constructor($filter) {
        this.humanizeSeverity = $filter('humanizeSeverity');
    }
    select() {
        let selectedList = [];

        return (function () {
            const hasSelected = () => {
                return selectedList.length > 0;
            };

            const selectionSize = () => {
                return selectedList.length;
            };

            const isActive = (item) => {
                const id = getId(item);

                return selectedList.includes(id);
            };

            const empty = () => {
                selectedList = [];
            };

            const getId = (item) => {
                if (angular.isObject(item)) {
                    let key = 'id';
                    if (!item[key]) {
                        key = '_id';
                    }

                    return item[key];
                }

                return item;
            };

            const select = (item, autoSelect = true) => {
                const items = angular.isArray(item) ? item : [item];

                // Event related tracking arrays
                const add = [];
                const remove = [];

                items.forEach((item) => {
                    const id = getId(item);
                    const index = selectedList.indexOf(id);
                    if (index === -1) {
                        add.push(id);
                        if (!autoSelect) {
                            selectedList.push(id);
                        }
                    } else {
                        remove.push(id);
                        if (!autoSelect) {
                            selectedList.splice(index, 1);
                        }
                    }
                });

                // Auto fill/unfill selection
                if (autoSelect) {
                    if (add.length === items.length) {
                        // UnSelect all of current list
                        selectedList.push(...add);
                    } else if (remove.length < items.length) {
                        // Select all missing element from current list
                        selectedList.push(...add);
                    } else if (remove.length === items.length) {
                        // UnSelect all of current list
                        selectedList = selectedList.filter((s) => remove.indexOf(s) === -1);
                    }
                }
            };

            const getSelectionSize = (fromItems) => {
                const size = selectionSize();
                let items = fromItems;

                if (!angular.isArray(items)) {
                    items = [];
                }

                if (items.length === 0) {
                    return 'none';
                }

                if (size === items.length) {
                    return 'all';
                }

                if (size === 0) {
                    return 'none';
                }

                if (size >= 1) {
                    return 'visible';
                }

                return 'few';
            };

            const getSelection = () => {
                return selectedList;
            };

            const selectionTemplate = () => {
                return `
                    <div>
                        {{selectionSize()}}
                    </div>
                `;
            };

            return {
                hasSelected,
                isActive,
                select,
                selectionSize,
                empty,
                getSelection,
                selectionTemplate,
                getSelectionSize,
            };
        })();
    }

    detail() {
        return (() => {
            let detailsShown = null;

            const toggle = (item) => {
                if (item?.id) {
                    detailsShown = detailsShown === item.id ? null : item.id;
                } else if (item?._id) {
                    detailsShown = detailsShown === item._id ? null : item._id;
                } else {
                    // In case you need to do without id (ex. tableDetail.toggle(item.attributName))
                    detailsShown = detailsShown === item ? null : item;
                }
            };

            const isToggled = (item) => {
                if (item?.id) {
                    return detailsShown === item.id;
                } else if (item?._id) {
                    return detailsShown === item._id;
                } else {
                    // In case you need to do without id (ex. tableDetail.isToggled(item.attributName))
                    return detailsShown === item;
                }
            };

            const getToggled = () => {
                return detailsShown;
            };

            return { toggle, isToggled, getToggled };
        })();
    }

    createHTMLAttributes(item, attributeKey) {
        if (angular.isUndefined(item)) {
            return;
        }

        if (
            angular.isUndefined(item[attributeKey]) ||
            item[attributeKey] === '' ||
            item[attributeKey] === null ||
            item[attributeKey].length === 0
        ) {
            return '<span>-</span>';
        }

        switch (attributeKey) {
            case 'severity':
                return `<div class="severity-wrap">
                <span class="severity--${item.severity}"></span>
                <span class="severity-selector__label"> ${this.humanizeSeverity(item.severity)}</span>
            </div>`;
            case 'required':
                return `<span class="status__${item.required}"></span>`;
            case 'description':
                return `<div class="text-left">${item[attributeKey]}</div>`;
            case 'phases':
                return `<span>${item[attributeKey]
                    .map(
                        ({ phaseName, isEnabled }) =>
                            `<span class="cc-phase ${isEnabled ? 'c-blue' : ''}">${phaseName}</span>`,
                    )
                    .join('')}</span>`;
            case 'percentageFill':
            case 'minPercentageFill':
            case 'maxPercentageFill':
            case 'emptyPercentage':
                return `<span >${item[attributeKey]}%</span>`;
            default:
                return `<span>${item[attributeKey]}</span>`;
        }
    }

    foundValueAttributeDifferent(listAttributes, attribute) {
        if (!angular.isArray(listAttributes) || !angular.isString(attribute)) {
            throw new Error('Error with listAttributes or attribute');
        }

        const attributeSelected = listAttributes.find(({ key }) => key === attribute);

        return attributeSelected?.key ?? undefined;
    }
}

angular.module('dotic').factory('$tableService', ($filter) => new TableService($filter));

const ccTableSelectTemplate = `
    <div
        class="cc-table-select-container"
        ng-show="$ctrl.tableSelect.hasSelected() && $ctrl.tableSelect.selectionSize() > 0"
    >
        <span><b>{{$ctrl.tableSelect.selectionSize()}}</b> {{ 'shared.selectedCount' | translate: {COUNT: $ctrl.tableSelect.selectionSize()} }}</span>
    </div>
`;

angular.module('dotic').component('ccTableSelect', {
    template: ccTableSelectTemplate,
    bindings: { tableSelect: '=' },
});
