import controlGridAdminFormControlTemplate from '../adminFormControlModal/controlGridAdminFormControl.html';
import controlGridUnsavedTemplate from '../unsavedModal/controlGridUnsaved.html';
import { HelpersService } from '../../../services/helpers.service';
import template from '../adminForm/controlGridAdminForm.html';

class ControlGridAdminFormController {
    constructor(
        $auth,
        $filter,
        $filterService,
        $gridControlService,
        $modalService,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        dataModelProvider,
        gridControlProvider,
    ) {
        this._$auth = $auth;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._$gridControlService = $gridControlService;
        this._$filterService = $filterService;
        this._$phasesService = $phaseService;
        this._$scope = $scope;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$segmentsService = $segmentsService;
        this._$uibModal = $uibModal;
        this._dataModelProvider = dataModelProvider;
        this._gridControlProvider = gridControlProvider;
        this._helpersService = HelpersService;
        this._$modalService = $modalService;

        this.isButtonWaiting = false;
        this.showConditions = true;
        this.dataModels = [];
        this.controlGridForm = {};
        this.controlGrid = {
            dataModel: [],
        };
        this.controlGridList = [];
        this.filteredControlGridList = [];
        this.segmentsList = this._$segmentsService.getAll();
        this.allPhases = this._$phasesService.getAll();

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'status',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseList',
                selected: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'segmentsList',
                selected: 'segment',
                placeholder: 'filter.all.segments',
                translateKeyLabel: 'shared.networkSegment',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseActivatedList',
                value: 'isActivated',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.state',
            },
            {
                allowed: true,
                name: 'internalIdsList',
                selected: 'internalId',
                placeholder: 'filter.all.internalId',
                hasSearch: true,
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'objectList',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematicList',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
            {
                allowed: this.controlGrid.isAdvanced,
                name: 'typeList',
                value: 'type',
                placeholder: 'filter.all.type',
                translateKeyLabel: 'shared.type',
            },
            {
                allowed: true,
                name: 'lastModificationDate',
                value: 'lastModificationDate',
                placeholder: 'shared.lastModificationDate',
                translateKeyLabel: 'shared.lastModificationDate',
                type: 'date',
            },
        ];

        this.lastModificationDateStart = $stateParams.lastModificationDateStart;
        this.lastModificationDateEnd = $stateParams.lastModificationDateEnd;

        this.filterValues = {
            severityList: [
                { key: 'minor', value: this._humanizeSeverity('minor') },
                { key: 'major', value: this._humanizeSeverity('major') },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            phaseList: [],
            segmentsList: this.segmentsList.map((segment) => ({
                key: segment,
                value: segment,
            })),
            phaseActivatedList: [
                {
                    key: 'true',
                    value: this._translate('shared.activated', { GENDER: 'male' }),
                },
                {
                    key: 'false',
                    value: this._translate('shared.disabled'),
                },
            ],
            internalIdsList: [],
            thematicList: [],
            objectList: [],
            typeList: [
                {
                    key: 'basic',
                    value: this._translate('shared.basic'),
                },
                {
                    key: 'advanced',
                    value: this._translate('shared.advanced', { GENDER: 'male' }),
                },
            ],
        };

        this.controlGridDetailsShown = null;

        this.initFilterIntoUrl($stateParams);

        this.controlGridId = $stateParams.gridControlId;

        this._$scope.$watch(
            '$ctrl.controlGridList',
            () => {
                if (
                    angular.isDefined(this.controlGridList) &&
                    angular.isArray(this.controlGridList) &&
                    this.controlGridList.length > 0
                ) {
                    this.controlGrid.countCtrl = this.controlGridList.filter((control) =>
                        control.phases.some((phase) => phase.isEnabled),
                    ).length;
                    this.controlGrid.countCtrlAdvanced = this.controlGridList.filter(
                        (control) => control.offer === 'advanced' && control.phases.some((phase) => phase.isEnabled),
                    ).length;
                    if (angular.isDefined(this.controlGrid.phases)) {
                        this.countPhasesEnabled();
                    }
                }
            },
            true,
        );

        this._$scope.$watch('$ctrl.controlGrid.phases', () => {
            if (angular.isDefined(this.controlGrid.phases)) {
                this.countPhasesEnabled();
            }
        });

        this._$scope.$watchGroup(
            [
                '$ctrl.lastModificationDateStart',
                '$ctrl.lastModificationDateEnd',
                '$ctrl.filter.thematic',
                '$ctrl.filter.type',
                '$ctrl.filter.internalId',
                '$ctrl.filter.isActivated',
                '$ctrl.filter.status',
                '$ctrl.filter.object',
                '$ctrl.filter.search',
                '$ctrl.controlGridList',
                '$ctrl.filter.phase',
                '$ctrl.filter.segment',
                '$ctrl.filter.lastModificationDate',
            ],
            () => {
                this.setScopeGridControlViewWithFilters();
            },
        );
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
            data.rows.forEach((el) => {
                this.toggleControlPoint(el);
            });
        });
    }

    initFilterIntoUrl(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            search: params.search || '',
            internalId: this._$filterService.getFilterValue(params.internalId),
            status: this._$filterService.getFilterValue(params.status),
            thematic: this._$filterService.getFilterValue(params.thematic),
            type: params.type || '',
            object: this._$filterService.getFilterValue(params.object),
            isActivated: params.isActivated || '',
            phase: this._$filterService.getFilterValue(params.phase),
            segment: this._$filterService.getFilterValue(params.segment),
        };

        this.setDateParams();
    }

    setDateParams() {
        if (!this.lastModificationDateStart || !this.lastModificationDateEnd) {
            this.filter.lastModificationDate = '';

            return;
        }

        this.filter.lastModificationDate = {
            startDate: moment(this.lastModificationDateStart),
            endDate: moment(this.lastModificationDateEnd),
        };
    }

    async $onInit() {
        this.tableDetail = this._$tableService.detail();
        this.tableSelect = this._$tableService.select();
        await this.getControlGrid(this.controlGridId);
        await this.getDataModels();
    }

    static filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    static filterPhases(phases) {
        return (control) =>
            phases.length === 0 ||
            phases.some((phase) =>
                control.phases.find(
                    (controlPointPhase) => controlPointPhase.phaseName === phase && controlPointPhase.isEnabled,
                ),
            );
    }

    updateURLWithFilter(filter) {
        this._$state.go('.', {
            status: filter.status.join(','),
            thematic: filter.thematic.join(','),
            type: filter.type,
            object: filter.object.join(','),
            phase: filter.phase.join(','),
            segment: filter.segment.join(','),
            isActivated: filter.isActivated,
            internalId: filter.internalId.join(','),
            lastModificationDateStart: filter.lastModificationDate?.startDate,
            lastModificationDateEnd: filter.lastModificationDate?.endDate,
        });
    }

    setScopeGridControlViewWithFilters() {
        this.updateURLWithFilter(this.filter);

        this._$timeout(() => {
            this.filteredControlGridList = this.controlGridList
                .filter(ControlGridAdminFormController.filterPhases(this.filter.phase))
                .filter(this._$filterService.filterSegments(this.filter.segment))
                .filter(this._$gridControlService.filterByPhasesAndSegments(this.filter.isActivated))
                .filter(ControlGridAdminFormController.filterSearch(this.filter.search.toLowerCase()))
                .filter(this._$filterService.genericMultiFilter(this.filter.status, 'severity'))
                .filter(this._$filterService.genericMultiFilter(this.filter.object, 'object'))
                .filter(this._$filterService.filterControlPoint(this.filter.internalId))
                .filter(this._$filterService.genericMultiFilter(this.filter.thematic, 'thematicName'))
                .filter(this._$filterService.genericFilter(this.filter.type, 'offer'))
                .filter(this._$filterService.filterRangeDate(this.filter.lastModificationDate, 'lastModificationDate'));
        });
    }

    refreshFiltersList() {
        const typeList = this.filterOptions.filter((obj) => obj.name === 'typeList')[0];
        typeList.allowed = this.controlGrid.isAdvanced;
    }

    refreshData() {
        this.controlGrid.data = this.controlGrid.data.map((control, index) => {
            return { ...control, index };
        });
        this.prepareControlGrid(this.controlGrid);
    }

    async getControlGrid(controlGridId) {
        try {
            const gridControl = await this._gridControlProvider.get(controlGridId);
            this.controlGrid = gridControl;
            this.refreshFiltersList();

            this.loadedControlGrid = gridControl;

            const controlPointActivated = [];
            const uniquePhases = [];

            this.controlGrid.data.forEach((controlPoint) => {
                controlPoint.phases.forEach((p) => {
                    if (
                        controlPoint &&
                        controlPoint.phases &&
                        controlPoint.phases.some((phase) => phase.isEnabled) &&
                        uniquePhases.indexOf(p.phaseName) === -1
                    ) {
                        controlPointActivated.push(controlPoint);
                        if (p.isEnabled) {
                            uniquePhases.push(p.phaseName);
                        }
                    }
                });

                return controlPointActivated.length;
            });

            this.controlGrid.phases = uniquePhases;
            this.filterValues.phaseList = this.controlGrid.phases.map((phase) => ({
                key: phase,
                value: phase,
            }));

            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'controlGridForm',
                        title: `${this._translate('shared.editGrid')} ${this.controlGrid.fullname}`,
                    },
                ],
                allowedPreviousKeys: ['controlGrids', 'controlGrid'],
            });

            this.refreshData();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async getDataModels() {
        try {
            const dataModels = await this._dataModelProvider.getAll();

            this.dataModels = dataModels;
            this.listDataModels(dataModels);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async updateControlGrid(controlGrid, controlGrids) {
        if (this.controlGridForm.$invalid) {
            this.controlGridForm.description.$touched = true;
            this.controlGridForm.name.$touched = true;
            this.controlGridForm.version.$touched = true;

            return;
        }

        this.isButtonWaiting = true;
        controlGrid.data = controlGrids;

        try {
            await this._gridControlProvider.update(this.controlGridId, controlGrid);
            this._$state.go('app.controlGridView', {
                gridControlId: this.controlGridId,
            });
            this._$toasterService.success(this._translate('shared.savedGrid'));
        } catch (error) {
            this.isButtonWaiting = false;
            if (error.body?.code === 'NAME_DUPLICATE') {
                this._$timeout(() => {
                    this.controlGridForm.name.$setValidity('duplicate', false);
                    this.controlGridForm.name.$touched = true;
                });

                return;
            }

            this._$toasterService.error(error);
        }
    }

    unsetError() {
        this.controlGridForm.name.$setValidity('duplicate', true);
    }

    async removeControlGrid() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlGrid', {
                COUNT: 1,
                NAME: this.controlGrid.name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._gridControlProvider.delete(this.controlGridId);
            this._$toasterService.info(
                this._translate('removeModal.successGrid', {
                    COUNT: 1,
                }),
            );
            await this._$state.go('app.gridControlAdminList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    goBack() {
        const newControlGrid = {
            ...this.controlGrid,
            data: this.controlGridList,
        };
        const oldControlGrid = this.loadedControlGrid;

        if (angular.toJson(newControlGrid) !== angular.toJson(oldControlGrid)) {
            const modal = this._$uibModal.open({
                controller: 'ControlGridUnsavedController as $ctrl',
                size: 'sm',
                templateUrl: controlGridUnsavedTemplate,
            });

            modal.result.then(
                () => {
                    this._$state.go('app.controlGridView', {
                        gridControlId: this.controlGridId,
                    });
                },
                () => {},
            );
        } else {
            this._$state.go('app.controlGridView', {
                gridControlId: this.controlGridId,
            });
        }
    }

    prepareControlGrid(controlGrid) {
        const thematicList = new Map();
        const objectList = new Map();
        const internalIdsList = new Map();

        controlGrid.data.forEach(({ thematicName, object, controlPoint }) => {
            thematicList.set(thematicName, this._humanizeThematic(thematicName));
            internalIdsList.set(controlPoint, controlPoint);
            if (angular.isString(object)) {
                objectList.set(object, object);
            }
        });

        this.filterValues.thematicList = Array.from(thematicList, ([key, value]) => ({
            key,
            value,
        }));
        this.filterValues.objectList = Array.from(objectList, ([key, value]) => ({ key, value }));
        this.filterValues.internalIdsList = Array.from(internalIdsList, ([key, value]) => ({ key, value }));
        this.controlGridList = controlGrid.data;

        this.allEnabledPhase = HelpersService.getPhasesListWithCountEnabled(this.controlGridList);
        this.networkSegmentEnabledList = HelpersService.getNetworkSegmentListWithCountEnabled(this.controlGridList);

        this.setScopeGridControlViewWithFilters();
    }

    onToggleSubLine(controlGrid) {
        if (this.controlGridDetailsShown === controlGrid.controlPoint) {
            this.controlGridDetailsShown = null;
        } else {
            this.controlGridDetailsShown = controlGrid.controlPoint;
        }
    }

    setPhaseStatus(isEnabled) {
        return (phase) => ({ ...phase, isEnabled });
    }

    setSegmentStatus(isEnabled) {
        return (segment) => ({ ...segment, isEnabled });
    }

    selectAllControls() {
        this._$scope.$broadcast('getVisibleRows');
    }

    toggleControlPoint(item) {
        const isControlPointEnabled = !HelpersService.isControlPointEnabled(item);

        this.controlGridList.some((controlGrid, index, self) => {
            if (item === controlGrid) {
                self[index].phases = item.phases.map(this.setPhaseStatus(isControlPointEnabled));
                self[index].networkSegments = item.networkSegments.map(this.setSegmentStatus(isControlPointEnabled));

                return true;
            }

            return false;
        });
    }

    setSeverity(item, severity) {
        item.severity = severity;
    }

    setDataModel(value) {
        const { dataModel } = this.controlGrid;
        if (dataModel.indexOf(value) > -1) {
            this.controlGrid.dataModel = dataModel.filter((dataModel) => dataModel !== value);
        } else {
            this.controlGrid.dataModel = [...dataModel, value];
        }
    }

    toggleCondition(condition, $event) {
        if ($event.target.className.indexOf('checkbox') > -1 || $event.target.tagName === 'INPUT') {
            return;
        }

        if (this.selectedCondition === condition) {
            this.selectedCondition = null;
        } else {
            this.selectedCondition = condition;
        }
    }

    listDataModels(dataModels) {
        if (dataModels.length === 0) {
            return '';
        }

        const list = this.controlGrid.dataModel
            .map((dataModelId) => this.dataModels.find((d) => d.id === dataModelId))
            .filter((dataModel) => dataModel)
            .map((dataModel) => dataModel.name);

        this._$timeout(() => {
            this.listDataModel = list;
        });
    }

    countPhasesEnabled() {
        this.phasesEnabledList = [];
        this.controlGrid.phases.forEach((phaseName) => {
            this.phasesEnabledList.push({ name: phaseName, count: 0 });
        });
        this.controlGridList.forEach(({ phases }) =>
            phases.forEach((phase) => {
                if (phase.isEnabled) {
                    return this.phasesEnabledList.map((phaseEnabled) =>
                        phase.phaseName === phaseEnabled.name ? phaseEnabled.count++ : false,
                    );
                }
            }),
        );
    }

    getThematicList() {
        const thematicList = [];
        this.controlGrid.data.forEach(({ thematicName }) => {
            if (!thematicList.includes(thematicName)) {
                thematicList.push(thematicName);
            }
        });

        return thematicList;
    }

    getObjectList() {
        const objectList = [];
        this.controlGrid.data.forEach(({ object }) => {
            if (!objectList.includes(object)) {
                objectList.push(object);
            }
        });

        return objectList;
    }

    openEditAdminControlPopup(item) {
        const itemCopy = angular.copy(item);
        const modal = this._$uibModal.open({
            controller: 'ControlGridAdminFormControlController as $ctrl',
            size: 'lg',
            templateUrl: controlGridAdminFormControlTemplate,
            backdrop: 'static',
            resolve: {
                index: () => item.index,
                controlData: () => item,
                thematicList: () => this.getThematicList(),
                objectList: () => this.getObjectList(),
            },
        });

        modal.result.then(
            (data) => {
                if (data) {
                    const dataIsEqualToItem = this.itemSendAndDataReceivedAreEquals(itemCopy, data);
                    if (!dataIsEqualToItem) {
                        data.isAdminEdited = true;
                    }

                    this.controlGrid.data[data.index] = data;
                    this.refreshData();
                }
            },
            () => {},
        );
    }

    itemSendAndDataReceivedAreEquals(item, data) {
        return (
            angular.equals(item.severity, data.severity) &&
            angular.equals(item.variable1Type, data.variable1Type) &&
            angular.equals(item.variable, data.variable) &&
            angular.equals(item.variableUnit, data.variableUnit) &&
            angular.equals(item.variable2Type, data.variable2Type) &&
            angular.equals(item.inputVariable2, data.inputVariable2) &&
            angular.toJson(item.phases).localeCompare(angular.toJson(data.phases)) === 0 &&
            angular.toJson(item.networkSegments).localeCompare(angular.toJson(data.networkSegments)) === 0
        );
    }

    cancelFilters() {
        this.initFilterIntoUrl({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('controlGridAdminFormPage', {
    controller: ControlGridAdminFormController,
    templateUrl: template,
});
