import { pick as radashPick } from 'radash';

import template from './generateMergeModal.html';

class GenerateMergeModalComponent {
    businessCodesList = [];
    deliveryZones = [];
    deliveryZonesList = [];
    generateFilter = {
        from: null,
        to: null,
        businessCodes: [],
        projects: [],
        deliveryZones: [],
        phases: [],
        server: null,
        users: [],
        version: 'last',
    };
    phasesList = [];
    projectsList = [];

    constructor(
        $auth,
        $filter,
        $phaseService,
        $scope,
        $timeout,
        $toasterService,
        companyProvider,
        deliveryZoneProvider,
        projectProvider,
    ) {
        this._$toasterService = $toasterService;
        this._$scope = $scope;
        this._$timeout = $timeout;
        this._translate = $filter('translate');

        this.companyId = $auth.getPayload().company;
        this._companyProvider = companyProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._projectProvider = projectProvider;

        this.phasesList = $phaseService.getAll().map((phase) => ({
            key: phase,
            value: phase,
        }));
        this.versions = [
            {
                key: 'last',
                value: this._translate('shared.lastVersion'),
            },
            { key: 'n-1', value: 'N-1' },
        ];

        $scope.$watch('$ctrl.generateFilter.server', async () => await this.fetchDeliveryZones());
        $scope.$watchGroup(['$ctrl.generateFilter.projects'], () => {
            this.filterDeliveryZones();
        });
    }

    async $onInit() {
        this.serversList = this.resolve.serversList;

        await this.setIndependentFilterValues();
        await this.fetchDeliveryZones();

        if (!this.resolve.generateFilter) {
            return;
        }
        this._$timeout(() => {
            this.generateFilter = { ...this.resolve.generateFilter };
            if (this.generateFilter.from) {
                this._$scope.$broadcast('updateDateTime', 'startDate', this.generateFilter.from);
            }

            if (this.generateFilter.to) {
                this._$scope.$broadcast('updateDateTime', 'endDate', this.generateFilter.to);
            }

            // Avoid focus on date inputs where they're empty
            if (!this.generateFilter.from && !this.generateFilter.to) {
                this._$scope.$broadcast('clearFilter');
            }
        }, 0);
    }

    async fetchDeliveryZones() {
        if (!this.generateFilter.server) {
            this.deliveryZonesList = [];
            this.businessCodesList = [];
            this.generateFilter.businessCodes = [];
            this.generateFilter.deliveryZones = [];

            return;
        }

        try {
            this.deliveryZones = await this._deliveryZoneProvider.list({ serverId: this.generateFilter.server });
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }

        if (this.generateFilter.projects.length) {
            this.filterDeliveryZones();

            return;
        }

        this.deliveryZonesList = this.deliveryZones.map((deliveryZone) => ({
            key: deliveryZone._id,
            value: `${deliveryZone.name} | ${deliveryZone.project.name}`,
        }));
        this.businessCodesList = this.deliveryZones
            .filter((deliveryZone) => deliveryZone.projectId)
            .map((deliveryZone) => ({
                key: deliveryZone.projectId,
                value: deliveryZone.projectId,
            }));
    }

    filterDeliveryZones() {
        const filteredDeliveryZones = this.deliveryZones.filter((deliveryZone) =>
            this.generateFilter.projects.includes(deliveryZone.project.id),
        );

        if (!filteredDeliveryZones.length) {
            this.generateFilter.businessCodes = [];
            this.generateFilter.deliveryZones = [];
        }

        this.deliveryZonesList = filteredDeliveryZones.map((deliveryZone) => ({
            key: deliveryZone._id,
            value: `${deliveryZone.name} | ${deliveryZone.project.name}`,
        }));
        this.businessCodesList = filteredDeliveryZones
            .filter((deliveryZone) => deliveryZone.projectId)
            .map((deliveryZone) => ({
                key: deliveryZone.projectId,
                value: deliveryZone.projectId,
            }));
    }

    async setIndependentFilterValues() {
        try {
            const projects = await this._projectProvider.getAll();
            const users = await this._companyProvider.getEmployeeDetailsForCompany(this.companyId);

            this.projectsList = projects.map((project) => ({
                key: project._id,
                value: project.name,
            }));
            this.usersList = users.map((user) => ({
                key: user._id,
                value: `${user.fullname} - ${user.email}`,
            }));
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
    }

    submit() {
        return this.close({
            $value: {
                params: {
                    ...radashPick(this.generateFilter, [
                        'businessCodes',
                        'deliveryZones',
                        'phases',
                        'projects',
                        'server',
                        'users',
                        'version',
                    ]),
                    from: this.generateFilter.from ? moment(this.generateFilter.from).utc().format() : null,
                    to: this.generateFilter.to ? moment(this.generateFilter.to).utc().format() : null,
                },
                filters: this.generateFilter,
            },
        });
    }

    cancel() {
        return this.close({
            $value: { filters: this.generateFilter },
        });
    }
}

angular.module('dotic').component('generateMergeModal', {
    templateUrl: template,
    controller: GenerateMergeModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
