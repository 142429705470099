import template from './button-dropdown.html';

const COLORS = ['alert', 'flat', 'reverse', 'reverse-alert', 'reverse-success', 'success', 'textual'];
const SIZES = ['big', 'huge', 'small', 'xsmall'];

class CcButtonDropdownController {
    constructor($log, $transclude) {
        this.hasTranscluded = false;
        this._$log = $log;

        $transclude((clone) => {
            if (clone.length > 0) {
                this.hasTranscluded = true;
            }
        });
    }

    $onChanges() {
        if (angular.isUndefined(this.type)) {
            this.type = 'button';
        }

        if (angular.isDefined(this.color) && COLORS.indexOf(this.color) === -1) {
            this._$log.warn(`This button color (${this.color}) is not supported yet`);
        }

        if (angular.isDefined(this.size) && SIZES.indexOf(this.size) === -1) {
            this._$log.warn(`This button size (${this.size}) is not supported yet`);
        }
    }
}

/**
 * @ngdoc component
 * @name ccButton
 *
 * @description
 * A component to show buttons on an action list.
 * It supports child elements that would be considered as a label
 *
 * Example:
 *
 * <cc-button icon="eye" ng-click="clickCallback()"></cc-button>
 * <cc-button icon="trash" color="alert" ng-click="clickCallback()"></cc-button>
 * <cc-button icon="download-alt" ng-click="clickCallback()">Download as an excel file</cc-button>
 *
 * Supported colors: (none), alert, flat, reverse, reverse-alert, reverse-success, success, textual
 * Supported sizes: (none), big, huge, small
 *
 * @param {boolean} active   If the button should appeat active
 * @param {string}  color    A color code for the button
 * @param {boolean} disabled If the button should appear disabled
 * @param {string}  icon     A icon from the icofont library
 * @param {string}  size     A button size. Could be default, big or huge
 * @param {string}  tooltip  A text to show in a tooltip when hovering the button
 * @param {string}  type  The HTML5 input type : submit/button/reset/menu. Button by default
 */
angular.module('dotic').component('ccButtonDropdown', {
    templateUrl: template,
    transclude: true,
    controller: CcButtonDropdownController,
    bindings: {
        color: '@',
        active: '<',
        disabled: '<',
        title: '@',
        icon: '@',
        size: '@',
        tooltip: '@',
        waiting: '<',
        onClick: '&',
        padding: '<',
    },
});
