const statusOpticalTable = [
    {
        avancement: 'A Creer',
        title: 'À créer',
        icon: 'architecture-alt',
        colorSaisie: '#0b99e0',
        colorCarto: '#bbd2e1',
    },
    {
        avancement: 'Existant',
        title: 'Existants',
        icon: 'search-property',
        colorSaisie: '#009090 ',
        colorCarto: '#50d0c3',
    },
    {
        avancement: 'Travaux',
        title: 'En travaux',
        icon: 'under-construction-alt',
        colorSaisie: '#dd3073',
        colorCarto: '#f2b2cb',
    },
    {
        avancement: 'En Service',
        title: 'En service',
        icon: 'checked',
        colorSaisie: '#049f00',
        colorCarto: '#a5d955',
    },
    {
        avancement: 'Hors Service',
        title: 'Hors service',
        icon: 'barricade',
        colorSaisie: '#e971b1',
        colorCarto: '#f0e5f6',
    },
    {
        avancement: 'Abandonne',
        title: 'Abandonnés',
        icon: 'recycling-man',
        colorSaisie: '#5069a9',
        colorCarto: '#607099',
    },
    {
        avancement: 'Inconnu',
        title: 'Inconnus',
        icon: 'question',
        colorSaisie: '#bababa',
        colorCarto: 'e9e9e9',
    },
    {
        avancement: 'Autre',
        title: 'Autres',
        icon: 'infinite',
        colorSaisie: '#edb349',
        colorCarto: '#f88e55',
    },
];

class OpticalCableSupportService {
    getData(avancement) {
        return statusOpticalTable.find((status) => status.avancement.toLowerCase() === avancement.toLowerCase());
    }

    sumOpticalByType(data) {
        if (!data) {
            return;
        }

        const avancements = {};

        data.table.forEach((entry) => {
            const { avancement, data_geom, data_saisie } = entry;

            if (!avancements[avancement]) {
                const status = this.getData(avancement);
                const title = status?.title || '';
                const colorSaisie = status?.colorSaisie || 'GREY';
                const colorCarto = status?.colorCarto || '';
                const icon = status?.icon || '';

                avancements[avancement] = {
                    data_geom: 0,
                    data_saisie: 0,
                    title,
                    icon,
                    colorCarto,
                    colorSaisie,
                };
            }

            avancements[avancement].data_geom += data_geom;
            avancements[avancement].data_saisie += data_saisie;
        });

        return avancements;
    }
}

angular.module('dotic').factory('$indicatorOpticalCableSupportService', () => new OpticalCableSupportService());
