import template from './cursorchart.html';

class CcCursorChartController {
    constructor() {
        this.max = 0;
        this.min = 0;
        this.value = 0;
        this.value1 = 0;
        this.value2 = 0;
        this.ideal = 0;
    }

    static colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
        let color1 = rgbColor1;
        let color2 = rgbColor2;
        let fade = fadeFraction;

        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
            fade = fade * 2;

            // Find which interval to use and adjust the fade percentage
            if (fade >= 1) {
                fade -= 1;
                color1 = rgbColor2;
                color2 = rgbColor3;
            }
        }

        const diffRed = color2.red - color1.red;
        const diffGreen = color2.green - color1.green;
        const diffBlue = color2.blue - color1.blue;

        const gradient = {
            red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
            green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
            blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
        };

        return `rgb(${gradient.red},${gradient.green},${gradient.blue})`;
    }

    $onChanges() {
        this.max = parseInt(this.max, 10);
        this.min = parseInt(this.min, 10);
        this.value = parseFloat(this.value);
        this.value1 = parseFloat(this.value1);
        this.value2 = parseFloat(this.value2);
        this.ideal = parseInt(this.ideal, 10);

        this.left = (100 * (0.5 + this.value - this.min)) / this.max;

        this.range = [];
        for (let i = this.min; i <= this.max; i += 1) {
            this.range.push({
                left: (100 * (0.5 + i - this.min)) / this.max,
                index: i,
            });
        }

        if (this.value1 || this.value2) {
            this.value = Math.abs(this.value1 - this.value2);

            if (this.value === 0) {
                this.value = this.value + 0.5;
            }

            if (this.value < 0) {
                this.value = -this.value;
            }
        }

        if (this.value < this.ideal) {
            const fraction = (this.value - this.min) / this.ideal;
            this.color = CcCursorChartController.colorGradient(
                fraction,
                { red: 255, green: 119, blue: 97 },
                { red: 254, green: 202, blue: 11 },
                { red: 160, green: 212, blue: 104 },
            );

            return;
        }

        const fraction = (this.value - this.ideal) / (this.max - this.ideal);
        this.color = CcCursorChartController.colorGradient(
            fraction,
            { red: 160, green: 212, blue: 104 },
            { red: 254, green: 202, blue: 11 },
            { red: 255, green: 119, blue: 97 },
        );
    }
}

angular.module('dotic').component('ccCursorChart', {
    controller: CcCursorChartController,
    bindings: {
        value: '<',
        value1: '<',
        value2: '<',
        min: '@',
        max: '@',
        ideal: '@',
    },
    templateUrl: template,
});
