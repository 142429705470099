import userManualPostProcessingPostProcessingDetailPostProcessingDetailListTemplate from './postProcessingDetail/postProcessingDetailList.html';
import userManualPostProcessingPostProcessingParamsPostProcessingParamsManageTemplate from './postProcessingParams/postProcessingParamsManage.html';
import userManualPostProcessingPostProcessingTemplate from './postProcessing.html';

/* Only for Orange */
angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.postProcessing', {
            url: '/gestion-post-traitements/post-traitements',
            templateUrl: userManualPostProcessingPostProcessingTemplate,
            data: {
                title: 'Gestion des post-traitements',
                navigation: [
                    {
                        title: 'Gestion des post-traitements',
                        sref: 'app.help.postProcessing',
                    },
                ],
                previous: 'app.help.interventionList',
                next: 'app.help.postProcessingDetailList',
            },
        })
        .state('app.help.postProcessingDetailList', {
            url: '/gestion-post-traitements/Liste',
            templateUrl: userManualPostProcessingPostProcessingDetailPostProcessingDetailListTemplate,
            data: {
                title: 'Liste des post-traitements',
                navigation: [
                    {
                        title: 'Gestion des post-traitements',
                        sref: 'app.help.postProcessing',
                    },
                ],
                previous: 'app.help.postProcessingDetail',
                next: 'app.help.postProcessingParamsManage',
            },
        })
        .state('app.help.postProcessingParamsManage', {
            url: '/gestion-post-traitements/setup',
            templateUrl: userManualPostProcessingPostProcessingParamsPostProcessingParamsManageTemplate,
            data: {
                title: 'Paramétrage',
                navigation: [
                    {
                        title: 'Gestion des post-traitements',
                        sref: 'app.help.postProcessing',
                    },
                ],
                previous: 'app.help.postProcessingParamsManage',
                next: 'app.help.gestionDepot',
            },
        });
});
