import template from './filter-dropdown.component.html';

const valueSorter = (a, b) => a?.value?.toString()?.localeCompare(b?.value?.toString());

class FilterDropdownComponent {
    constructor($scope, $element, $filter, $timeout) {
        this._$element = $element;
        this._$timeout = $timeout;
        this._translate = $filter('translate');

        this.searchValue = null;
        this.originalValues = [];
        this.disabled = false;
        this.isDisabled = false;
        this.insideModal = false;
        this.insideFilters = false;
        this.noAutoClose = false;
        this.large = false;
        this.hasSearch = false;
        this.required = false;
        this.placeholder = '';
        this.noSort = false;
        this.nullValue = undefined;
        this.appendBody = true;
        this.ngChange = null;

        $scope.$watch('$ctrl.searchValue', () => this.onSearchChange());
        $scope.$watch('$ctrl.value', () => this.ngChange());
    }

    $onChanges() {
        this.originalValues = (this.values || []).filter((v) => angular.isDefined(v.key) && angular.isDefined(v.value));

        this.values = this.getSortedValues();
        this.getDropdownStyle();
        this.getButtonStyle();

        // Avoid opening the dropdown when there is no available options
        this.isDisabled = this.disabled || this.originalValues.length === 0;
    }

    getDropdownStyle() {
        const style = {};
        if (this.insideFilters) {
            style['min-width'] = 310;
        }

        if (this.insideModal) {
            style['z-index'] = 2201;
        }

        this.dropdownStyle = style;
    }

    getButtonStyle() {
        const style = {};
        if (this.required) {
            style['border-color'] = '#e55c46';
            style.color = '#e55c46';
        }

        this.buttonStyle = style;
    }

    getSortedValues() {
        this.values = this.originalValues;
        if (this.noSort) {
            return this.getValues();
        }

        return this.getValues().sort(valueSorter);
    }

    getValue() {
        const current = this.getAllValues().find((item) => item.key === this.value);
        if (!current) {
            return this.placeholder;
        }

        if (current.value === 'N/A') {
            return this._translate('deposit.withoutIdLivrable');
        }

        return current.value;
    }

    getValues() {
        return this.values || [];
    }

    getAllValues() {
        const values = angular.copy(this.values) || [];

        if (this.nullValue) {
            values.push(this.nullValue);
        }

        return values;
    }

    setValue(item) {
        if (item.separator) {
            return false;
        }

        if (this.value === item.key) {
            this.value = '';
        } else {
            this.value = item.key;
        }

        if (!this.noAutoClose) {
            const elements = this._$element.find('.cc-filter-dropdown');
            if (elements[0]) {
                this._$timeout(() => {
                    elements[0].click();
                }, 100);
                // Mandatory to avoid Angular apply error
                // 100ms to avoid instant close, it can be weird for the user
            }
        }
    }

    onSearchChange() {
        if (this.searchValue) {
            const searchValue = this.searchValue.toLowerCase();
            this.values = this.originalValues.filter(
                ({ value, separator }) => separator || value.toString().toLowerCase().indexOf(searchValue) > -1,
            );
        } else {
            this.values = this.originalValues;
        }
    }
}

/**
 * @ngdoc component
 * @name ccFilterDropdown
 *
 * @description
 * A component to show a filter of a list
 *
 * Example:
 *
 * <cc-filter-dropdown values="[{key:'key',value:'value'}]" value="" placeholder="My field"></cc-filter-dropdown>
 *
 * @param {Array}  values      The list of { key: "key", value: "value" } objects
 * @param {string} value       The selected key
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterDropdown', {
    bindings: {
        appendBody: '<',
        disabled: '<',
        hasSearch: '<',
        insideModal: '<',
        insideFilters: '<',
        large: '<',
        nullValue: '<',
        noAutoClose: '<',
        noSort: '<',
        ngChange: '&',
        numberSort: '<',
        placeholder: '@',
        required: '<',
        values: '<',
        value: '=',
    },
    controller: FilterDropdownComponent,
    templateUrl: template,
});
