angular.module('dotic').factory('synopticProvider', synopticProvider);

function synopticProvider(RestProvider, URI_KEYS) {
    return {
        get,
    };

    function get(query = {}) {
        return RestProvider.get(URI_KEYS.SYNOPTIC, query);
    }
}
