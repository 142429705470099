import template from './depositSynthesisDetail.html';
class DepositSynthesisDetail {
    constructor($fillingStatisticsService) {
        this.$fillingStatisticsService = $fillingStatisticsService;

        this.createdAt = '';
        this.depositSynthesis = [];
        this.loading = true;
    }

    $onInit() {
        if (this.depositSynthesis.length > 0) {
            this.showDepositHistoryChart(this.depositSynthesis[0], true);
        }
    }

    showDepositHistoryChart(deposit, firstOpenDetail) {
        this.firstOpenDetail = firstOpenDetail;
        this.chartOldVersions = this.$fillingStatisticsService.preparedHistoryChart(deposit);
    }
}

angular.module('dotic').component('depositSynthesisDetail', {
    templateUrl: template,
    controller: DepositSynthesisDetail,
    bindings: {
        createdAt: '@',
        depositSynthesis: '<',
        statisticsId: '@',
    },
});
