class FindMaxValue {
    findRoundedMaxValue(maxValue) {
        if (maxValue > 0 && maxValue <= 1.9) {
            this.roundedMaxValue = Math.ceil(maxValue + 0.2);
        } else if (maxValue <= 10) {
            this.roundedMaxValue = Math.ceil(maxValue + 1);
        } else if (maxValue <= 20) {
            this.roundedMaxValue = Math.ceil(maxValue + 2);
        } else if (maxValue <= 100) {
            this.roundedMaxValue = Math.ceil(maxValue + 5);
        } else if (maxValue <= 2000) {
            this.roundedMaxValue = Math.ceil(maxValue + 25);
        } else {
            this.roundedMaxValue = Math.ceil(maxValue + 155);
        }

        return this.roundedMaxValue;
    }

    findMaxValue(data) {
        const recoverDatasChart = data.map((chart) => chart.chartData);
        const getDatasets = recoverDatasChart.map((el) => el.datasets).flat();
        const getDatas = getDatasets
            .map((d) => d)
            .map((el) => el.data)
            .flat();

        const maxValue = Math.max(...getDatas);

        return this.findRoundedMaxValue(maxValue);
    }

    findMaxValueFlatten(data) {
        const findDatasets = data.datasets;
        const getDatas = findDatasets.map((data) => data.data).flat();
        const maxValue = Math.max(...getDatas);

        return this.findRoundedMaxValue(maxValue);
    }
}

angular.module('dotic').factory('$indicatorMaxValueService', () => new FindMaxValue());
