import template from './controlErrorsFillingStatModal.html';

class ControlErrorsFillingStatModalComponent {
    constructor($scope, $timeout) {
        this._$scope = $scope;
        this._$timeout = $timeout;

        this.controlErrors = [];
        this.fillingStatistics = {};
        this.resultsData = [];

        this.error = false;
        this.loading = true;
    }

    $onInit() {
        this.resultsData = [];

        const { data } = this.resolve;

        if (!data) {
            return;
        }

        this.fillingStatistics = data[0].dataControlPoint002;

        this.loadData(data).then((results) => {
            this.resultsData = results;
            this._$timeout(() => {
                this.loading = false;
            });
        });
    }
    async loadData(data) {
        const filterErrorsPromises = data.map(async (deposit) => {
            const errors = await deposit.controlErrors();
            if (errors) {
                const filteredErrors = this.findError(errors);

                return { ...filteredErrors, name: deposit.depositName };
            } else {
                this.error = true;
            }
        });

        return await Promise.all(filterErrorsPromises);
    }

    findError(items) {
        this.controlErrors = this.consolidationByError(angular.copy(items));

        return this.controlErrors.find((control) => control.attribute === this.fillingStatistics.attributName);
    }

    consolidationByError(items) {
        if (!items) {
            return;
        }

        const reduceError = items.errors.reduce((acc, item) => {
            const table = item.table;
            const attribute = item.attribute || '';
            const externalName = item.externalName || '';
            const externalValue = item.externalValue || '';
            const message = item.message || '';
            const value = item.value || '';
            const count = item.count;

            if (!acc[table]) {
                acc[table] = {};
            }

            if (!acc[table][attribute]) {
                acc[table][attribute] = {};
            }

            if (!acc[table][attribute][externalName]) {
                acc[table][attribute][externalName] = {};
            }

            if (!acc[table][attribute][externalName][externalValue]) {
                acc[table][attribute][externalName][externalValue] = {};
            }

            if (!acc[table][attribute][externalName][externalValue][message]) {
                acc[table][attribute][externalName][externalValue][message] = {};
            }

            if (!acc[table][attribute][externalName][externalValue][message][value]) {
                acc[table][attribute][externalName][externalValue][message][value] = 0;
            }

            acc[table][attribute][externalName][externalValue][message][value] += Number.parseInt(count) || 1;

            return acc;
        }, {});

        const consolidationArray = [];
        for (const [table, depth1] of Object.entries(reduceError)) {
            for (const [attribute, depth2] of Object.entries(depth1)) {
                for (const [externalName, depth3] of Object.entries(depth2)) {
                    for (const [externalValue, depth4] of Object.entries(depth3)) {
                        for (const [message, depth5] of Object.entries(depth4)) {
                            for (const [value, count] of Object.entries(depth5)) {
                                consolidationArray.push({
                                    table,
                                    attribute,
                                    externalName,
                                    externalValue,
                                    message,
                                    value,
                                    count,
                                });
                            }
                        }
                    }
                }
            }
        }

        return consolidationArray;
    }
    onClickclosePopup() {
        this.close();
    }
}
angular.module('dotic').component('controlErrorsFillingStatModal', {
    templateUrl: template,
    controller: ControlErrorsFillingStatModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
