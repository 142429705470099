import template from './filterMultiDropdown.html';

const valueSorter = (a, b) => a.value.toString().localeCompare(b.value.toString());

class CcFilterMultiDropdownController {
    constructor($scope) {
        this.selected = [];
        this.originalValues = [];
        this.values = [];
        this.placeholder = null;
        this.searchValue = null;
        this.insideFilters = false;
        this.isDisabled = false;
        this.disabled = false;
        this.big = false;
        this.appendToBody = false;

        $scope.$watch('$ctrl.searchValue', () => this.onSearchChange());
    }

    $onChanges() {
        this.originalValues = (this.values || []).filter((v) => v.key && v.value);
        this.values = this.originalValues;
        this.appendToBody = this.isAppendToBody ?? false;
        this.isDisabled = this.disabled ?? this.originalValues.length === 0;

        if (this.insideFilters) {
            this.dropdownStyle = { 'min-width': 310 };
        }

        this.getButtonStyle();
    }

    getSortedValues() {
        if (this.noSort) {
            return this.values;
        }

        return this.values?.sort(valueSorter);
    }

    getButtonStyle() {
        const style = {};
        if (this.required) {
            style['border-color'] = '#e55c46';
            style.color = '#e55c46';
        }

        this.buttonStyle = style;
    }

    onSearchChange() {
        if (this.searchValue) {
            const searchValue = this.searchValue.toLowerCase();
            this.values = this.originalValues.filter(({ value }) => value.toLowerCase().indexOf(searchValue) > -1);

            return;
        }

        this.values = this.originalValues;
    }

    getLabel() {
        this.validateSelection();

        if ((!this.selected.length && this.placeholder) || !this.values) {
            return this.placeholder;
        }

        return this.selected
            .map((key) => this.values.find((item) => item.key === key))
            .filter((item) => angular.isDefined(item))
            .map((item) => item.value)
            .join(', ');
    }

    listAllValues() {
        this.validateSelection();
        if (!this.selected.length || !this.values) {
            return;
        }

        return this.selected
            .map((key) => this.values.find((item) => item.key === key))
            .filter((item) => angular.isDefined(item))
            .map((item) => item.value)
            .join(', ');
    }

    getCount() {
        if (this.selected.length > 1) {
            return `(${this.selected.length})`;
        }

        return null;
    }

    select({ key }) {
        if (!key) {
            return;
        }

        const index = this.getIndex(key);
        if (index > -1) {
            this.selected = this.selected.filter((value) => value !== key);

            return;
        }

        this.selected.push(key);
    }

    selectAll() {
        if (this.selected.length) {
            this.selected = [];

            return;
        }

        this.getSortedValues().forEach((item) => {
            this.selected.push(item.key);
        });
    }

    isAllSelected() {
        return this.getSortedValues().every((item) => this.selected.includes(item.key));
    }

    isSelected({ key }) {
        return this.getIndex(key) > -1;
    }

    getIndex(key) {
        return this.selected.indexOf(key);
    }

    validateSelection() {
        if (angular.isArray(this.selected)) {
            return;
        }

        this.selected = this.selected ? [this.selected] : [];
    }
}

/**
 * @ngdoc component
 * @name ccFilterMultiDropdown
 *
 * @description
 * A component to show a filter of a list
 *
 * Example:
 *
 * <cc-filter-dropdown values="{key:'key',value:'value'}" value="" placeholder="My field"></cc-filter-dropdown>
 *
 * @param {Array}  values      The list of { key: "key", value: "value" } objects
 * @param {string} value       The selected key
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterMultiDropdown', {
    templateUrl: template,
    controller: CcFilterMultiDropdownController,
    bindings: {
        big: '<',
        disabled: '<',
        hasSearch: '<',
        hasAllSelected: '<',
        insideFilters: '<',
        isAppendToBody: '<',
        noSort: '<',
        placeholder: '@',
        required: '<',
        selected: '=',
        values: '<',
    },
});
