/**
 * statisticDetailsLine
 *
 * link usage :
 * 'link': map to the app state via ui-sref without params
 * 'link-params': allow passing parameter to the state
 *
 * #### Usage Example:
 * <deposit-details-line
 *      title="Label title"
 *      value="item.title"
 *      link="app.detailPage"
 *      link-params="{ id: item.id }"
 * />
 *
 * resolve to:
 * <a data-ui-sref="app.detailPage({'id': 12})" href="/detail/12">
 *     Item Title
 * </a>
 *
 */
angular.module('dotic').component('statisticDetailsLine', {
    template: `<div class="statistic__detail-item" ng-if="$ctrl.value">
    <div class="statistic__detail-label">{{$ctrl.title}} :</div>
    <span ng-if="!$ctrl.link">{{ $ctrl.value }}</span>
    <a ng-if="$ctrl.link" data-ui-sref="{{$ctrl.link}}({{$ctrl.linkParams}})">{{ $ctrl.value }}</a>
</div>`,
    bindings: {
        title: '@',
        value: '<',
        link: '@',
        linkParams: '<',
    },
});
