angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.exportGeoPackage', {
        url: '/export/geopackage',
        component: 'exportGeoPackagePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
