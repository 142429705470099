import { isEqual } from 'radash';

import { HelpersService } from '../../../services/helpers.service';
import template from './fillingStatisticsCompare.html';

class FillingStatisticsCompare {
    constructor(
        $excelService,
        $fillingStatisticsService,
        $filter,
        $location,
        $scope,
        $sliderService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        fillingStatisticsProvider,
    ) {
        this._$excelService = $excelService;
        this.$fillingStatisticsService = $fillingStatisticsService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$location = $location;
        this._$scope = $scope;
        this._$sliderService = $sliderService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._fillingStatisticsProvider = fillingStatisticsProvider;
        this._helpersService = HelpersService;

        this.idStatisticsOrigin = this._$stateParams.idStatisticsOrigin;
        this.idStatisticsSelected = this._$stateParams.idStatisticsSelected;

        this.controlsSynthesis = [];
        this.metadataHeader = [];
        this.statisticsOrigin = [];
        this.statisticsSelected = [];

        this.chartsHeader = {
            chartsActiveTable: [],
            fillingRateStatusControls: [],
        };

        this.totalDifferenceBetweenStatistics = 0;
        this.totalIdenticalBetweenStatistics = 0;

        this.hasFillComparisonOpen = false;
        this.hasFillingOpen = false;
        this.loading = true;

        this.GRID_TYPE = {
            isDifferent: 'isDifferent',
            isSame: 'isSame',
            onlyOrigin: 'onlyOrigin',
            onlySelected: 'onlySelected',
        };
    }

    async $onInit() {
        this.getHorizontalBarChartFilledOptions = this.$fillingStatisticsService.getHorizontalBarChartFilledOptions();
        this.getHorizontalBarChartWithLabels = this.$fillingStatisticsService.getHorizontalBarChartWithLabels();
        this.tableDetail = this._$tableService.detail();
        this.addNavigationPage();

        await this.getAllFillingStatistics();
        await Promise.all([this.preparedMetadataHeader(), this.preparedDataSynthesis()]);
        await Promise.all([this.preparedChartsHeader(), this.aggregateControls()]);

        this.totalDifferenceBetweenStatistics = this.controlsSynthesis.filter(({ isDifferent }) => isDifferent).length;
        this.totalIdenticalBetweenStatistics = this.controlsSynthesis.filter(({ isSame }) => isSame).length;
        this.initValueFilter();
        this.initListFilter();
        this.initWatchFilters();
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
        });
    }

    addNavigationPage() {
        const newPage = [
            {
                key: 'fillingStatisticsCompare',
                title: this._translate('statisticsPage.comparison', { TYPE: 'fill' }),
                href: this._$location.path(),
            },
        ];
        this._$scope.$emit('updateNavigation', {
            newPage,
        });
        this._$scope.$emit('keepPreviousNavigation', {
            newPage,
            defaultPrevious: {
                key: 'fillingStatistics',
                title: this._translate('shared.fillingStatistic'),
                href: this._$state.href('app.statisticsFillHome'),
            },
            allowedPreviousKeys: ['fillingStatitics'],
        });
    }

    initSlider() {
        this.slider = {
            ...this.slider,
            options: this._$sliderService.optionPercent(),
        };
    }

    initWatchFilters() {
        this._$scope.$watchGroup(
            [
                '$ctrl.comparisonFilters.controlsSynthesisDifferentList',
                '$ctrl.comparisonFilters.differentType',
                '$ctrl.filters.severity',
                '$ctrl.filters.required',
                '$ctrl.filters.search',
                '$ctrl.filters.table',
                '$ctrl.slider.maxPercentageAverage',
                '$ctrl.slider.minPercentageAverage',
            ],
            () => this.filterFillingStatics(),
        );
    }

    filterFillingStatics() {
        const { maxPercentageAverage, minPercentageAverage } = this.slider;
        const { controlsSynthesisDifferentList } = this.comparisonFilters;

        this.updateUrlFilters();
        this.openBoxIfFilterNoEmpty();

        this.controlsSynthesisFiltered = this.controlsSynthesis
            .filter(this.filterCritical())
            .filter(this.filterKeyDifference())
            .filter(this.filterMax())
            .filter(this.filterMin())
            .filter(this.filterRequired())
            .filter(this.filterSearch())
            .filter(this.filterTables())
            .filter(this.filterTypeComparison());

        this.keyFilterAttributeSelected = this._$tableService.foundValueAttributeDifferent(
            this.controlsSynthesisDifferentList,
            controlsSynthesisDifferentList,
        );

        this.showButtonCancelComparisonFilter = HelpersService.isFilterEmpty(this.comparisonFilters);
        this.showButtonCancelFilter =
            HelpersService.isFilterEmpty(this.filters) || minPercentageAverage !== 0 || maxPercentageAverage !== 100;
    }

    openBoxIfFilterNoEmpty() {
        const { severity, required, search, table } = this.filters;
        const { differentType, controlsSynthesisDifferentList } = this.comparisonFilters;
        const { maxPercentageAverage, minPercentageAverage } = this.slider;
        if (
            controlsSynthesisDifferentList !== '' ||
            severity !== '' ||
            differentType !== '' ||
            maxPercentageAverage !== 100 ||
            minPercentageAverage !== 0 ||
            required !== '' ||
            search !== '' ||
            table !== ''
        ) {
            this.hasFillingOpen = true;
        }

        if (differentType !== '' || controlsSynthesisDifferentList !== '') {
            this.hasFillComparisonOpen = true;
        }
    }

    filterKeyDifference() {
        const { controlsSynthesisDifferentList } = this.comparisonFilters;

        return ({ statisticsOrigin, statisticsSelected }) =>
            controlsSynthesisDifferentList === '' ||
            !statisticsOrigin ||
            !statisticsSelected ||
            !angular.equals(
                statisticsOrigin[controlsSynthesisDifferentList],
                statisticsSelected[controlsSynthesisDifferentList],
            );
    }

    updateUrlFilters() {
        const { severity, required, search, table } = this.filters;
        const { differentType, controlsSynthesisDifferentList } = this.comparisonFilters;
        const { maxPercentageAverage, minPercentageAverage } = this.slider;

        this._$state.go('.', {
            controlsSynthesisDifferentList,
            severity,
            differentType,
            maxPercentageAverage,
            minPercentageAverage,
            required,
            search,
            table,
        });
    }

    filterTypeComparison() {
        const { differentType } = this.comparisonFilters;
        const { onlySelected, onlyOrigin, isSame, isDifferent } = this.GRID_TYPE;
        switch (differentType) {
            case isSame:
                return ({ isSame }) => isSame;
            case isDifferent:
                return ({ isDifferent }) => isDifferent;
            case onlyOrigin:
                return ({ onlyOrigin }) => onlyOrigin;
            case onlySelected:
                return ({ onlySelected }) => onlySelected;
            default:
                return () => true;
        }
    }

    cleanFilter(filters) {
        this.slider.minPercentageAverage = 0;
        this.slider.maxPercentageAverage = 100;
        HelpersService.cleanFilter(filters);
    }

    filterMin() {
        const { minPercentageAverage } = this.slider;

        return ({ statisticsOrigin, statisticsSelected }) =>
            minPercentageAverage === 0 ||
            statisticsOrigin.percentageFill >= minPercentageAverage ||
            statisticsSelected.percentageFill >= minPercentageAverage;
    }

    filterMax() {
        const { maxPercentageAverage } = this.slider;

        return ({ statisticsOrigin, statisticsSelected }) =>
            maxPercentageAverage === 100 ||
            statisticsOrigin.percentageFill < maxPercentageAverage + 1 ||
            statisticsSelected.percentageFill < maxPercentageAverage + 1;
    }

    filterCritical() {
        const { severity } = this.filters;

        return ({ statisticsOrigin, statisticsSelected }) =>
            severity === '' || statisticsOrigin.severity === severity || statisticsSelected.severity === severity;
    }

    filterTables() {
        const { table } = this.filters;

        return (attr) => table === '' || attr.tableName === table;
    }

    filterSearch() {
        const { search } = this.filters;
        const wordSearch = search.toLowerCase();

        return ({ attributeName, tableName, statisticsOrigin, statisticsSelected }) =>
            wordSearch === '' ||
            attributeName.toLowerCase().includes(wordSearch) ||
            tableName.toLowerCase().includes(wordSearch) ||
            (statisticsOrigin.description ? statisticsOrigin.description.toLowerCase().includes(wordSearch) : false) ||
            (statisticsSelected.description
                ? statisticsSelected.description.toLowerCase().includes(wordSearch)
                : false);
    }

    filterRequired() {
        const { required } = this.filters;

        return ({ statisticsOrigin, statisticsSelected }) =>
            required === '' || statisticsOrigin.required === required || statisticsSelected.required === required;
    }

    initValueFilter() {
        this.filters = {
            severity: this._$state.params.severity || '',
            required: this._$state.params.required || '',
            search: this._$state.params.search || '',
            table: this._$state.params.table || '',
        };
        this.comparisonFilters = {
            controlsSynthesisDifferentList: this._$state.params.controlsSynthesisDifferentList || '',
            differentType: this._$state.params.differentType || '',
        };
        this.slider = {
            ...this.slider,
            maxPercentageAverage: this._$state.params.maxPercentageAverage
                ? parseInt(this._$state.params.maxPercentageAverage)
                : 100,
            minPercentageAverage: this._$state.params.minPercentageAverage
                ? parseInt(this._$state.params.minPercentageAverage)
                : 0,
        };
    }

    initListFilter() {
        this.initFilterCritical();
        this.initFilterFillTable();
        this.initFilterRequired();
        this.initFilterSelectListData();
        this.initInputDifferentBetweenKey();
        this.initSlider();
        this.showButtonCancelComparisonFilter = HelpersService.isFilterEmpty(this.comparisonFilters);
        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
    }

    initInputDifferentBetweenKey() {
        this.controlsSynthesisDifferentList = [
            {
                key: 'description',
                value: this._translate('shared.description'),
            },
            {
                key: 'severity',
                value: this._translate('shared.severity'),
            },
            { key: 'required', value: this._translate('shared.status') },
            {
                key: 'productionMode',
                value: this._translate('shared.productionMode'),
            },
            {
                key: 'totalCount',
                value: this._translate('shared.registrationNumber'),
            },
            {
                key: 'successCount',
                value: this._translate('shared.numberOfSuccesses'),
            },
            {
                key: 'anomalyCount',
                value: this._translate('statisticsPage.nbAnomalies2lines'),
            },
            {
                key: 'percentageFill',
                value: this._translate('shared.fillingRate', { SUFFIX: 'average' }),
            },
            {
                key: 'minPercentageFill',
                value: this._translate('shared.fillingRate', { SUFFIX: 'min' }),
            },
            {
                key: 'maxPercentageFill',
                value: this._translate('shared.fillingRate', { SUFFIX: 'max' }),
            },
            {
                key: 'emptyPercentage',
                value: this._translate('shared.fillingErrorRate'),
            },
        ];
    }

    initFilterSelectListData() {
        const { onlySelected, onlyOrigin, isSame, isDifferent } = this.GRID_TYPE;
        const { createdAt: createdAtOrigin } = this.statisticsOrigin;
        const { createdAt: createdAtSelected } = this.statisticsSelected;

        this.selectListData = [
            { key: isSame, value: this._translate('shared.same') },
            {
                key: isDifferent,
                value: this._translate('shared.different'),
            },
            {
                key: onlyOrigin,
                value: this._translate('shared.onlyIn', {
                    NAME: moment(createdAtOrigin).format('DD/MM/YYYY [à] HH:mm'),
                }),
            },
            {
                key: onlySelected,
                value: this._translate('shared.onlyIn', {
                    NAME: moment(createdAtSelected).format('DD/MM/YYYY [à] HH:mm'),
                }),
            },
        ];
    }

    initFilterCritical() {
        this.severityList = [
            {
                key: 'blocking',
                value: this._humanizeSeverity('blocking'),
            },
            { key: 'major', value: this._humanizeSeverity('major') },
            { key: 'minor', value: this._humanizeSeverity('minor') },
        ];
    }

    initFilterFillTable() {
        const tableList = new Map();
        this.statisticsOrigin.synthesisControl.forEach(({ tableName }) => {
            tableList.set(tableName, tableName);
        });
        this.statisticsSelected.synthesisControl.forEach(({ tableName }) => {
            tableList.set(tableName, tableName);
        });
        this.tableList = Array.from(tableList, this.mapToObject);
    }

    mapToObject([key, value]) {
        return { key, value };
    }

    initFilterRequired() {
        this.requiredList = [
            {
                key: 'optional',
                value: this._translate('status.optional', { COUNT: 1 }),
            },
            {
                key: 'required',
                value: this._translate('status.required', { COUNT: 1 }),
            },
        ];
    }

    async getAllFillingStatistics() {
        const [statisticsOrigin, statisticsSelected] = await Promise.all([
            this._fillingStatisticsProvider.getOne(this.idStatisticsOrigin),
            this._fillingStatisticsProvider.getOne(this.idStatisticsSelected),
        ]);
        this.statisticsOrigin = statisticsOrigin;
        this.statisticsSelected = statisticsSelected;
    }

    async preparedDataSynthesis() {
        const [statisticsOrigin, statisticsSelected] = await Promise.all([
            this.$fillingStatisticsService.preparedSynthesisControl(this.statisticsOrigin),
            this.$fillingStatisticsService.preparedSynthesisControl(this.statisticsSelected),
        ]);

        this.statisticsOrigin.depositSynthesis = this.preparedControls(statisticsOrigin);
        this.statisticsSelected.depositSynthesis = this.preparedControls(statisticsSelected);
    }

    preparedControls(controls) {
        return controls.map(
            ({
                depositSynthesis,
                tableName,
                attributeName,
                description,
                anomalyCount,
                totalCount,
                successCount,
                ...rest
            }) => {
                if (depositSynthesis) {
                    rest.depositSynthesis = this.preparedControls(depositSynthesis).sort((a, b) =>
                        a.depositName.localeCompare(b.depositName),
                    );
                    rest.tempId = tableName + attributeName;
                    rest.description = description?.replace(/(\r\n|\n|\r)/gm, '');
                }

                rest.anomalyCount = Math.round(anomalyCount);
                rest.totalCount = Math.round(totalCount);
                rest.successCount = Math.round(successCount);

                return {
                    ...rest,
                    tableName,
                    attributeName,
                };
            },
        );
    }

    preparedMetadataHeader() {
        this.metadataHeader = [this.statisticsOrigin, this.statisticsSelected];
    }

    async preparedChartsHeader() {
        await Promise.all([this.preparedChartsActiveTable(), this.preparedFillingRateStatusControls()]);
    }

    async preparedChartsActiveTable() {
        this.chartsHeader.chartsActiveTable = await this.preparedTwoStatistics(
            this.$fillingStatisticsService.preparedChartPercentByPart,
            'depositSynthesis',
        );
    }

    async preparedFillingRateStatusControls() {
        this.chartsHeader.fillingRateStatusControls = await this.preparedTwoStatistics(
            this.$fillingStatisticsService.prepareActiveTableByDeposit,
            'deposits',
        );
    }

    async preparedTwoStatistics(func, key) {
        if (!angular.isFunction(func) || !angular.isString(key)) {
            return [];
        }

        const { statisticsOrigin, statisticsSelected } = this;
        const [statisticsOriginRate, statisticsSelectedRate] = await Promise.all([
            func(statisticsOrigin[key]),
            func(statisticsSelected[key]),
        ]);

        return [
            {
                ...statisticsOriginRate,
                createdAt: statisticsOrigin.createdAt,
                idStatistics: this.idStatisticsOrigin,
            },
            {
                ...statisticsSelectedRate,
                createdAt: statisticsSelected.createdAt,
                idStatistics: this.idStatisticsSelected,
            },
        ];
    }

    openListControls() {
        this.hasFillingOpen = !this.hasFillingOpen;
        if (this.hasFillingOpen) {
            this.effectOpenListControls();
        }
    }

    showDiff() {
        const { isDifferent } = this.GRID_TYPE;
        this.hasFillingOpen = true;
        this.comparisonFilters.differentType = isDifferent;
        this.effectOpenListControls();
    }

    showSame() {
        const { isSame } = this.GRID_TYPE;
        this.hasFillingOpen = true;
        this.comparisonFilters.differentType = isSame;
        this.effectOpenListControls();
    }

    effectOpenListControls() {
        this._$timeout(() => {
            const element = angular.element('#anomalies');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    aggregateControls() {
        this.controlsSynthesis = this.getSynthesisControlsWithoutDifference();
    }

    getSynthesisControlsWithoutDifference() {
        const createdAtOrigin = this.statisticsOrigin.createdAt;
        const createdAtSelected = this.statisticsSelected.createdAt;

        // Fix problem spread and babel
        const synthesisOrigin = this.statisticsOrigin.depositSynthesis
            .map((element) => {
                delete element.criticity;
                delete element.id;
                delete element._id;

                return {
                    ...element,
                    attributeName: element.attributeName ?? '',
                    commentSI: element.commentSI?.length || '',
                    correspondenceSI: element.correspondenceSI?.length || '',
                    objectSI: element.objectSI?.length || '',
                    percentageError: element.percentageError ?? 0,
                    tableName: element.tableName ?? '',
                };
            })
            .filter((deposit) => deposit.required === 'required' || deposit.required === 'optional');

        const synthesisSelected = this.statisticsSelected.depositSynthesis
            .map((element) => {
                delete element.criticity;
                delete element.id;
                delete element._id;

                return {
                    ...element,
                    attributeName: element.attributeName ?? '',
                    commentSI: element.commentSI?.length || '',
                    correspondenceSI: element.correspondenceSI?.length || '',
                    objectSI: element.objectSI?.length || '',
                    percentageError: element.percentageError ?? 0,
                    tableName: element.tableName ?? '',
                };
            })
            .filter((deposit) => deposit.required === 'required' || deposit.required === 'optional');

        const controlsSyntheses = [
            this.addInfo(this.foundSameControls(synthesisOrigin, synthesisSelected), {
                createdAtSelected,
                createdAtOrigin,
            }),
            this.addInfo(this.foundOnly(synthesisOrigin, synthesisSelected, 'statisticsOrigin'), {
                createdAtOrigin,
            }),
            this.addInfo(this.foundOnly(synthesisSelected, synthesisOrigin, 'statisticsSelected'), {
                createdAtSelected,
            }),
            this.addInfo(this.foundDifference(synthesisOrigin, synthesisSelected), {
                createdAtSelected,
                createdAtOrigin,
            }),
        ];

        return controlsSyntheses.flat(1).map(({ statisticsOrigin, statisticsSelected, ...rest }) => {
            if (angular.isUndefined(statisticsOrigin.productionMode)) {
                statisticsOrigin.productionMode = null;
            }

            if (angular.isUndefined(statisticsSelected.productionMode)) {
                statisticsSelected.productionMode = null;
            }

            return {
                ...rest,
                statisticsOrigin,
                statisticsSelected,
                haveDifferenceInDepositSynthesis: this.foundHaveDifferenceSynthesisDeposits(
                    statisticsOrigin.depositSynthesis,
                    statisticsSelected.depositSynthesis,
                ),
            };
        });
    }

    foundDifference(originAttributes, selectedAttributes) {
        return originAttributes.reduce((acc, { tempId, tableName, attributeName, ...restOfOrigin }) => {
            // fix bug babel !
            delete restOfOrigin.tempId;
            delete restOfOrigin.tableName;
            delete restOfOrigin.attributeName;

            const controlSelected = this.foundDepositSynthesisWithDifference(selectedAttributes, tempId, restOfOrigin);
            if (controlSelected) {
                const { ...restSelected } = controlSelected;
                const numberOfDifferent = this.countDifference(restOfOrigin, restSelected);
                acc.push({
                    tableName,
                    attributeName,
                    statisticsOrigin: restOfOrigin,
                    statisticsSelected: restSelected,
                    isDifferent: true,
                    numberOfDifferent,
                });
            }

            return acc;
        }, []);
    }

    foundDepositSynthesisWithDifference(selectedAttributes, tempId, { ...restOfOrigin }) {
        // fix bug babel !
        delete restOfOrigin.depositSynthesis;

        return selectedAttributes
            .filter((el) => el.tempId === tempId)
            .find(({ ...rest }) => {
                // fix bug babel !
                delete rest.depositSynthesis;
                delete rest.tempId;
                delete rest.tableName;
                delete rest.attributeName;
                delete rest.statisticsSelected;
                delete rest.statisticsOrigin;

                return !angular.equals(restOfOrigin, rest);
            });
    }

    foundHaveDifferenceSynthesisDeposits(depositSynthesisOrigin, depositSynthesisSelected) {
        if (!depositSynthesisOrigin || !depositSynthesisSelected) {
            return depositSynthesisOrigin !== depositSynthesisSelected;
        }

        if (depositSynthesisOrigin.length !== depositSynthesisSelected.length) {
            return true;
        }

        return !angular.equals(
            depositSynthesisOrigin.map(({ depositId }) => depositId),
            depositSynthesisSelected.map(({ depositId }) => depositId),
        );
    }

    defaultAttribute() {
        return {
            anomalyCount: undefined,
            depositSynthesis: undefined,
            description: undefined,
            emptyPercentage: undefined,
            maxPercentageFill: undefined,
            minPercentageFill: undefined,
            oldVersions: undefined,
            percentageFill: undefined,
            required: undefined,
            severity: undefined,
            successCount: undefined,
            totalCount: undefined,
        };
    }

    countDifference(controlCompared, controlSelected) {
        const KEYS_SELECTED_FOR_COUNT = [
            'severity',
            'description',
            'anomalyCount',
            'successCount',
            'maxPercentageFill',
            'minPercentageFill',
            'emptyPercentage',
            'percentageFill',
            'required',
            'totalCount',
        ];
        let counter = 0;
        KEYS_SELECTED_FOR_COUNT.forEach((key) => {
            if (!angular.equals(controlCompared[key], controlSelected[key])) {
                counter++;
            }
        });

        return counter;
    }

    foundOnly(attributes1, attributes2, type) {
        return attributes1.reduce(
            (acc, { tempId, tableName, attributeName, statisticsSelected, statisticsOrigin, ...rest }) => {
                if (!attributes2.some((control) => tempId === control.tempId)) {
                    switch (type) {
                        case 'statisticsOrigin':
                            acc.push({
                                attributeName,
                                onlyOrigin: true,
                                statisticsOrigin: rest,
                                statisticsSelected: this.defaultAttribute(),
                                tableName,
                            });
                            break;
                        case 'statisticsSelected':
                            acc.push({
                                attributeName,
                                onlySelected: true,
                                statisticsOrigin: this.defaultAttribute(),
                                statisticsSelected: rest,
                                tableName,
                            });
                            break;
                    }
                }

                return acc;
            },
            [],
        );
    }

    addInfo(attributes, createdAt) {
        return attributes.map((control) => ({ ...control, ...createdAt }));
    }

    foundSameControls(depositSynthesisFromOrigin, depositSynthesisFromSelected) {
        return depositSynthesisFromOrigin.reduce((acc, controlOrigin) => {
            const synthesisSelected = this.foundSameSyntheses(controlOrigin, depositSynthesisFromSelected);

            if (synthesisSelected) {
                const { tableName, attributeName, ...restOrigin } = controlOrigin;
                acc.push({
                    attributeName,
                    isSame: true,
                    statisticsOrigin: restOrigin,
                    statisticsSelected: synthesisSelected,
                    tableName,
                });
            }

            return acc;
        }, []);
    }

    foundSameSyntheses({ ...depositSynthesisFromOrigin }, depositSynthesisFromSelected) {
        depositSynthesisFromOrigin.depositSynthesis = [];

        return depositSynthesisFromSelected
            .filter((el) => el.tempId === depositSynthesisFromOrigin.tempId)
            .find((same) => {
                same.depositSynthesis = [];

                return Object.keys(same).every((key) => isEqual(same[key], depositSynthesisFromOrigin[key]));
            });
    }

    download() {
        try {
            const nameOrigin = this.$fillingStatisticsService.makeNameStatistics(this.statisticsOrigin.createdAt);
            const nameSelected = this.$fillingStatisticsService.makeNameStatistics(this.statisticsSelected.createdAt);

            const table = this.$fillingStatisticsService.preparationDataForExcel(
                this.controlsSynthesisFiltered,
                { nameOrigin, nameSelected },
                this.comparisonFilters.controlsSynthesisDifferentList,
            );

            const fileName = [
                this._translate('shared.fillingStatistic'),
                this.$fillingStatisticsService.makeDateFileName(this.statisticsOrigin.createdAt),
                this.$fillingStatisticsService.makeDateFileName(this.statisticsSelected.createdAt),
            ].join('_');

            const opts = [
                {
                    sheetid: `${this._translate('shared.comparison')} ${this._translate('shared.fillingRate')}`,
                    header: true,
                },
            ];

            return this._$excelService.downloadXLSX(opts, [table], fileName, 'controlGridAdminView.downloadGrid');
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        }
    }
}

angular.module('dotic').component('fillingStatisticsCompare', {
    controller: FillingStatisticsCompare,
    templateUrl: template,
});
