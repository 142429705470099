import EnvironmentSettingsService from '../../../services/environment-settings.service';
import { HelpersService } from '../../../services/helpers.service';
import template from './interventionGridForm.html';

class InterventionGridFormPage {
    companiesList = [];
    loading = true;
    grid = { name: '', data: [], companies: [] };

    constructor(
        $authorizationService,
        $excelService,
        $filter,
        $interventionGridService,
        $log,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        companyProvider,
        interventionGridProvider,
    ) {
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._$excelService = $excelService;
        this._$interventionGridService = $interventionGridService;
        this._$log = $log;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._interventionGridProvider = interventionGridProvider;
        this._companyProvider = companyProvider;

        this.isAllowed = $authorizationService.isAllowed;

        this.gridId = $stateParams.gridId || null;
        this.isSfr = EnvironmentSettingsService.isSfr();

        const navigation = {
            defaultPrevious: {
                key: 'interventionGridsList',
                title: this._translate('shared.interventionGrid'),
                href: $state.href('app.interventionGridsList'),
            },
            allowedPreviousKeys: ['interventionGridsList'],
        };

        if (this.gridId) {
            navigation.newPage = [
                {
                    key: 'interventionGridEdit',
                    title: this._translate('shared.edition'),
                    href: $state.href('app.interventionGridEdit'),
                },
            ];
        } else {
            navigation.newPage = [
                {
                    key: 'interventionGridCreate',
                    title: this._translate('interventionGrid.create'),
                    href: $state.href('app.interventionGridCreate'),
                },
            ];
        }

        $scope.$emit('keepPreviousNavigation', navigation);
    }

    async $onInit() {
        this.loading = true;

        try {
            this.companiesList = this._orderBy(await this._companyProvider.getCompaniesList(), 'name');
            this.companiesList = HelpersService.toKeyValue(this.companiesList, {
                keyField: 'id',
                valueField: 'name',
            });

            if (!this.gridId) {
                this.loading = false;

                return;
            }

            this.grid = await this._interventionGridProvider.getGrid(this.gridId);
            this.grid.companies = this.grid.companies.map((company) => company._id);
        } catch {
            this._$toasterService.error({
                body: this._translate('toaster.error'),
            });
        }

        this.loading = false;
    }

    async createGrid(grid) {
        try {
            await this._interventionGridProvider.createGrid(grid);
            this._$toasterService.success({
                body: this._translate('shared.savedGrid'),
            });
            this._$state.go('app.interventionGridsList');
        } catch {
            this._$toasterService.error({
                body: this._translate('shared.errorCreation'),
            });
        }
    }

    async importFile(file) {
        if (!file) {
            return;
        }

        try {
            this.loading = true;
            const data = await this._$excelService.importXLSX(URL.createObjectURL(file));

            this.grid.data = this._$interventionGridService.excelToJson(data);
        } catch {
            this._$toasterService.error({
                body: this._translate('shared.errorUploadFile'),
            });
        } finally {
            this.loading = false;
        }
    }

    saveGrid() {
        if (this.gridId) {
            return this.updateGrid(this.gridId, this.grid);
        }

        return this.createGrid(this.grid);
    }

    async updateGrid(gridId, grid) {
        try {
            await this._interventionGridProvider.updateGrid(gridId, grid);
            this._$toasterService.success({
                body: this._translate('shared.savedGrid'),
            });

            this._$state.go('app.interventionGridView', {
                gridId: this.gridId,
            });
        } catch {
            this._$toasterService.error({
                body: this._translate('toaster.error'),
            });
        }
    }
}

angular.module('dotic').component('interventionGridFormPage', {
    templateUrl: template,
    controller: InterventionGridFormPage,
});
