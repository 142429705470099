// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/livraison/livraison-parametrer.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/livraison/livraison-parametrer-modale.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 3.5.5 / {{ 'helpConfig.setupDeliveryZone' | translate }} </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.duplicateOrEditDeliveryZone' | translate }} <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpConfig.duplicateOrEditDeliveryZone' | translate }}\"/> </li> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'helpConfig.duplicateOrEditDeliveryZone' | translate }}\"/> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionConfigurations/zonesLivraison/parametrerZoneLivraison.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;