angular.module('dotic').factory('gridControlProvider', gridControlProvider);

function gridControlProvider(ApiProvider, URI_KEYS) {
    return {
        create: createGridControl,
        delete: deleteGridControl,
        get: getGridControl,
        getChildren: getChildrenGridControl,
        update: updateGridControl,
        listAllControlGrids: listAllControlGrids,
        getNew,
        getGridsControlToCompare: getGridsControlToCompare,
        getDetails,
    };

    /**
     * POST /gridControls
     *
     * @returns {*} Promise
     */
    function createGridControl(gridControl) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL).customPOST(gridControl);
    }

    /**
     * DELETE /gridControls/:gridControlId
     *
     * @param {string} gridControlId
     *
     * @returns {*} Promise
     */
    function deleteGridControl(gridControlId) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL, gridControlId).remove();
    }

    /**
     * GET /gridControls/:gridControlId
     *
     * @param {string} gridControlId
     *
     * @returns {*} Promise
     */
    function getGridControl(gridControlId) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL, gridControlId).get();
    }

    /**
     * GET /gridControls/:gridControlId/details
     *
     * @param {string} gridControlId
     * @returns {*} Promise
     */
    function getDetails(gridControlId) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL, gridControlId).one('details').get();
    }

    /**
     * GET /gridControls/children/:gridControlId
     * Get all children of the parent gridControl passed in argument.
     *
     * @param {string} gridControlId The parent gridControl id
     * @returns {*} Promise
     */
    function getChildrenGridControl(gridControlId) {
        return ApiProvider.one(`${URI_KEYS.GRID_CONTROL}/children/${gridControlId}`).get();
    }

    /**
     * PUT /gridControls/:gridControlId
     *
     * @param {string} gridControlId
     * @param {object} gridControl
     *
     * @returns {*} Promise
     */
    function updateGridControl(gridControlId, gridControl) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL, gridControlId).customPUT(gridControl);
    }

    /**
     * GET /gridControls/new
     * Get a blank grid with default values from api
     */
    function getNew() {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL).one('new').get();
    }

    /**
     * GET /gridControls/:gridControlId1/:gridControlId2/compare
     *
     * @param {string} gridControlId1
     * @param {string} gridControlId2
     *
     * @returns {*} Promise
     */
    function getGridsControlToCompare(gridControlId1, gridControlId2) {
        return ApiProvider.one(`${URI_KEYS.GRID_CONTROL}/${gridControlId1}/${gridControlId2}/compare`).get();
    }

    /**
     * GET /gridControls
     * @param {object} queryParams
     * @param {number} queryParams.order
     * @param {string} queryParams.column
     * @param {number} queryParams.limit
     * @param {number} queryParams.page
     */
    function listAllControlGrids(queryParams) {
        return ApiProvider.one(URI_KEYS.GRID_CONTROL).customGET(null, queryParams);
    }
}
