import template from './deliveryZoneListModal.html';

class DeliveryZoneListModal {
    constructor($timeout) {
        this._$timeout = $timeout;

        this.deliveryZones = [];
        this.loading = true;
    }

    $onInit() {
        const { deliveryZones } = this.resolve;

        const { deliveryZonesDeposits } = this.resolve;

        if (angular.isArray(deliveryZones)) {
            this.setDeliveryZones(deliveryZones);
        } else if (angular.isArray(deliveryZonesDeposits)) {
            this.setDeliveryZonesDeposit(deliveryZonesDeposits);
        } else if (angular.isFunction(deliveryZones)) {
            deliveryZones().then((results) => this.setDeliveryZones(results));
        } else if (angular.isFunction(deliveryZonesDeposits)) {
            deliveryZonesDeposits().then((results) => this.setDeliveryZonesDeposit(results));
        } else {
            this.loading = false;
        }
    }

    setDeliveryZones(deliveryZones) {
        this._$timeout(() => {
            this.deliveryZones = deliveryZones;
            this.loading = false;
        });
    }

    setDeliveryZonesDeposit(deliveryZonesDeposits) {
        this._$timeout(() => {
            this.deliveryZones = deliveryZonesDeposits;
            this.loading = false;
        });
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('deliveryZoneListModal', {
    templateUrl: template,
    controller: DeliveryZoneListModal,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
