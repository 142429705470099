import userManualGestionStatisticControlStatisticControlStatisticTemplate from './controlStatistic/controlStatistic.html';
import userManualGestionStatisticControlStatisticStatCompareTemplate from './controlStatistic/statCompare.html';
import userManualGestionStatisticControlStatisticStatDeleteTemplate from './controlStatistic/statDelete.html';
import userManualGestionStatisticControlStatisticStatDetailTemplate from './controlStatistic/statDetail.html';
import userManualGestionStatisticControlStatisticStatExportTemplate from './controlStatistic/statExport.html';
import userManualGestionStatisticControlStatisticStatGeneratedTemplate from './controlStatistic/statGenerated.html';
import userManualGestionStatisticControlStatisticStatisticInfoTemplate from './controlStatistic/statisticInfo.html';
import userManualGestionStatisticControlStatisticStatListTemplate from './controlStatistic/statList.html';
import userManualGestionStatisticControlStatisticStatRapportTemplate from './controlStatistic/statRapport.html';
import userManualGestionStatisticControlStatisticStatSeeTemplate from './controlStatistic/statSee.html';
import userManualGestionStatisticFillingStatisticFillingCompareTemplate from './fillingStatistic/fillingCompare.html';
import userManualGestionStatisticFillingStatisticFillingDeleteTemplate from './fillingStatistic/fillingDelete.html';
import userManualGestionStatisticFillingStatisticFillingExportTemplate from './fillingStatistic/fillingExport.html';
import userManualGestionStatisticFillingStatisticFillingGeneratedTemplate from './fillingStatistic/fillingGenerated.html';
import userManualGestionStatisticFillingStatisticFillingInfoTemplate from './fillingStatistic/fillingInfo.html';
import userManualGestionStatisticFillingStatisticFillingSeeTemplate from './fillingStatistic/fillingSee.html';
import userManualGestionStatisticFillingStatisticFillingStatisticTemplate from './fillingStatistic/fillingStatistic.html';
import userManualGestionStatisticGestionStatisticTemplate from './gestionStatistic.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.gestionStatistic', {
            url: '/gestion-statistique',
            templateUrl: userManualGestionStatisticGestionStatisticTemplate,
            data: {
                title: 'Gestion des statistiques',
                navigation: [],
                previous: 'app.help.supprimerLStocke',
                next: 'app.help.controlStatistic',
            },
        })
        /* Control statistic */
        .state('app.help.controlStatistic', {
            url: '/gestion-statistique/statistic-control',
            templateUrl: userManualGestionStatisticControlStatisticControlStatisticTemplate,
            data: {
                title: 'Statistique de contrôle',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.gestionStatistic',
                next: 'app.help.statisticInfo',
            },
        })
        .state('app.help.statisticInfo', {
            url: '/gestion-statistique/statistic-control/informations',
            templateUrl: userManualGestionStatisticControlStatisticStatisticInfoTemplate,
            data: {
                title: 'Informations sur les statistiques de contrôle',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.controlStatistic',
                next: 'app.help.statGenerated',
            },
        })
        .state('app.help.statGenerated', {
            url: '/gestion-statistique/statistic-control/generer',
            templateUrl: userManualGestionStatisticControlStatisticStatGeneratedTemplate,
            data: {
                title: 'Générer statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statisticInfo',
                next: 'app.help.statSee',
            },
        })
        .state('app.help.statSee', {
            url: '/gestion-statistique/statistic-control/voir',
            templateUrl: userManualGestionStatisticControlStatisticStatSeeTemplate,
            data: {
                title: 'Voir statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statGenerated',
                next: 'app.help.statDetail',
            },
        })
        .state('app.help.statDetail', {
            url: '/gestion-statistique/statistic-control/indicateurs',
            templateUrl: userManualGestionStatisticControlStatisticStatDetailTemplate,
            data: {
                title: 'Indicateurs analytiques',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statSee',
                next: 'app.help.statList',
            },
        })
        .state('app.help.statList', {
            url: '/gestion-statistique/statistic-control/liste',
            templateUrl: userManualGestionStatisticControlStatisticStatListTemplate,
            data: {
                title: 'Liste des livrables',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statDetail',
                next: 'app.help.statRapport',
            },
        })
        .state('app.help.statRapport', {
            url: '/gestion-statistique/statistic-control/rapports',
            templateUrl: userManualGestionStatisticControlStatisticStatRapportTemplate,
            data: {
                title: 'Rapports consolidés',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statList',
                next: 'app.help.statExport',
            },
        })
        .state('app.help.statExport', {
            url: '/gestion-statistique/statistic-control/export',
            templateUrl: userManualGestionStatisticControlStatisticStatExportTemplate,
            data: {
                title: 'Export statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statRapport',
                next: 'app.help.statCompare',
            },
        })
        .state('app.help.statCompare', {
            url: '/gestion-statistique/statistic-control/comparaison',
            templateUrl: userManualGestionStatisticControlStatisticStatCompareTemplate,
            data: {
                title: 'Comparer des statistiques',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statExport',
                next: 'app.help.statDelete',
            },
        })
        .state('app.help.statDelete', {
            url: '/gestion-statistique/statistic-control/supprimer',
            templateUrl: userManualGestionStatisticControlStatisticStatDeleteTemplate,
            data: {
                title: 'Supprimer statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de contrôle',
                        sref: 'app.help.controlStatistic',
                    },
                ],
                previous: 'app.help.statCompare',
                next: 'app.help.fillingStatistic',
            },
        })
        /* Filling statistic */
        .state('app.help.fillingStatistic', {
            url: '/gestion-statistique/statistic-remplissage',
            templateUrl: userManualGestionStatisticFillingStatisticFillingStatisticTemplate,
            data: {
                title: 'Statistique de remplissage',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.statDelete',
                next: 'app.help.fillingInfo',
            },
        })
        .state('app.help.fillingInfo', {
            url: '/gestion-statistique/statistic-remplissage/information',
            templateUrl: userManualGestionStatisticFillingStatisticFillingInfoTemplate,
            data: {
                title: 'Information',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingStatistic',
                next: 'app.help.fillingGenerated',
            },
        })
        .state('app.help.fillingGenerated', {
            url: '/gestion-statistique/statistic-remplissage/generation',
            templateUrl: userManualGestionStatisticFillingStatisticFillingGeneratedTemplate,
            data: {
                title: 'Génération statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingInfo',
                next: 'app.help.fillingSee',
            },
        })
        .state('app.help.fillingSee', {
            url: '/gestion-statistique/statistic-remplissage/detail',
            templateUrl: userManualGestionStatisticFillingStatisticFillingSeeTemplate,
            data: {
                title: 'Détail statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingGenerated',
                next: 'app.help.fillingCompare',
            },
        })
        .state('app.help.fillingCompare', {
            url: '/gestion-statistique/statistic-remplissage/comparaison',
            templateUrl: userManualGestionStatisticFillingStatisticFillingCompareTemplate,
            data: {
                title: 'Comparaison de statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingSee',
                next: 'app.help.fillingExport',
            },
        })
        .state('app.help.fillingExport', {
            url: '/gestion-statistique/statistic-remplissage/telechargement',
            templateUrl: userManualGestionStatisticFillingStatisticFillingExportTemplate,
            data: {
                title: 'Télécharger statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingCompare',
                next: 'app.help.fillingDelete',
            },
        })
        .state('app.help.fillingDelete', {
            url: '/gestion-statistique/statistic-remplissage/suppression',
            templateUrl: userManualGestionStatisticFillingStatisticFillingDeleteTemplate,
            data: {
                title: 'Supprimer statistique',
                navigation: [
                    {
                        title: 'Gestion des statistiques',
                        sref: 'app.help.gestionStatistic',
                    },
                    {
                        title: 'Statistique de remplissage',
                        sref: 'app.help.fillingStatistic',
                    },
                ],
                previous: 'app.help.fillingExport',
                next: 'app.help.gestionGeoPackage',
            },
        });
});
