angular.module('dotic').factory('$searchService', searchService);

function searchService() {
    return {
        match: match,
    };

    function match(needle) {
        return (haystack) => haystack.some((hay) => hay && hay.toLowerCase().indexOf(needle.toLowerCase()) > -1);
    }
}
