import template from './siteDetail.html';
class SiteDetailPage {
    constructor($filter, $location, $scope, $state, $stateParams, $timeout, siteProvider) {
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._siteProvider = siteProvider;

        this.loading = true;
        this.site = null;
        this.pmregs = [];
        this.nros = [];
        this.codes = [];
        this.operators = [];

        this.filters = {
            pmregs: [],
            nros: [],
            codes: [],
            operators: [],
        };
        this.siteId = this._$stateParams.siteId;
        this.filteredIndicators = [];

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'siteDetail',
                    title: this._translate('shared.indicators'),
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.orangeSites'),
                href: this._$state.href('app.sitesList'),
                key: 'sitesList',
            },
            allowedPreviousKeys: ['sitesList'],
        });

        $scope.$watchGroup(
            ['$ctrl.filters.pmregs', '$ctrl.filters.nros', '$ctrl.filters.codes', '$ctrl.filters.operators'],
            () => this.filterIndicators(),
        );
    }

    $onInit() {
        this.loading = true;
        this.indicatorsLoading = true;

        return this.loadIndicators().then((data) => {
            this.loading = false;

            const pmregs = {};
            const nros = {};
            const codes = {};
            const operators = {};
            data.indicators.forEach(({ code_dep, nom_nro, nom_pmreg, operateur }) => {
                if (!pmregs[nom_pmreg]) {
                    pmregs[nom_pmreg] = true;
                }

                if (!codes[code_dep]) {
                    codes[code_dep] = true;
                }

                if (!nros[nom_nro]) {
                    nros[nom_nro] = true;
                }

                if (!operators[operateur]) {
                    operators[operateur] = true;
                }
            });

            this.nros = Object.keys(nros).map((a) => ({ key: a, value: a }));
            this.pmregs = Object.keys(pmregs).map((a) => ({
                key: a,
                value: a,
            }));
            this.operators = Object.keys(operators).map((a) => ({
                key: a,
                value: a,
            }));
            this.codes = Object.keys(codes).map((a) => ({ key: a, value: a }));
            this.site = data;
            this.filteredIndicators = data.indicators;

            this.indicatorsLoading = false;
        });
    }

    loadIndicators() {
        return this._siteProvider.getIndicators(this.siteId);
    }

    filterIndicators() {
        if (this.indicatorsLoading) {
            return;
        }

        const filteredIndicators = this.site.indicators.filter(({ code_dep, nom_nro, nom_pmreg, operateur }) => {
            if (this.filters.pmregs.length > 0) {
                if (!this.filters.pmregs.includes(nom_pmreg)) {
                    return false;
                }
            }

            if (this.filters.nros.length > 0) {
                if (!this.filters.nros.includes(nom_nro)) {
                    return false;
                }
            }

            if (this.filters.operators.length > 0) {
                if (!this.filters.operators.includes(operateur)) {
                    return false;
                }
            }

            if (this.filters.codes.length > 0) {
                if (!this.filters.codes.includes(code_dep)) {
                    return false;
                }
            }

            return true;
        });

        this._$timeout(() => {
            this.filteredIndicators = filteredIndicators;
        });
    }
}

angular.module('dotic').component('siteDetailPage', {
    templateUrl: template,
    controller: SiteDetailPage,
});
