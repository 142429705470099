import userManualCartographyCartographyTemplate from './cartography.html';
import userManualCartographyCartoSeeCartoSeeAssociateTemplate from './cartoSee/cartoSeeAssociate.html';
import userManualCartographyCartoSeeCartoSeeOnlyTemplate from './cartoSee/cartoSeeOnly.html';
import userManualCartographyCartoSeeCartoSeeTemplate from './cartoSee/cartoSee.html';
import userManualCartographyCartoUseCartoUseCompareTemplate from './cartoUse/cartoUseCompare.html';
import userManualCartographyCartoUseCartoUseInfoTemplate from './cartoUse/cartoUseInfo.html';
import userManualCartographyCartoUseCartoUseInteractiveTemplate from './cartoUse/cartoUseInteractive.html';
import userManualCartographyCartoUseCartoUseLinkSynoTemplate from './cartoUse/cartoUseLinkSyno.html';
import userManualCartographyCartoUseCartoUseSearchTemplate from './cartoUse/cartoUseSearch.html';
import userManualCartographyCartoUseCartoUseShapefileTemplate from './cartoUse/cartoUseShapefile.html';
import userManualCartographyCartoUseCartoUseShowTemplate from './cartoUse/cartoUseShow.html';
import userManualCartographyCartoUseCartoUseTemplate from './cartoUse/cartoUse.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.carto', {
            url: '/cartography',
            templateUrl: userManualCartographyCartographyTemplate,
            data: {
                title: 'Cartographie',
                navigation: [{ title: 'Mode cartographique', sref: 'app.help.carto' }],
                previous: 'app.help.geoPackageGenerate',
                next: 'app.help.cartoSee',
            },
        })
        .state('app.help.cartoSee', {
            url: '/cartography/visualiser',
            templateUrl: userManualCartographyCartoSeeCartoSeeTemplate,
            data: {
                title: 'Visualiser mode cartographique',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Visualiser', sref: 'app.help.cartoSee' },
                ],
                previous: 'app.help.carto',
                next: 'app.help.cartoSeeOnly',
            },
        })
        .state('app.help.cartoSeeOnly', {
            url: '/cartography/visualiser-simplement',
            templateUrl: userManualCartographyCartoSeeCartoSeeOnlyTemplate,
            data: {
                title: 'Visualiser',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Visualiser', sref: 'app.help.cartoSee' },
                ],
                previous: 'app.help.cartoSee',
                next: 'app.help.cartoSeeAssociate',
            },
        })
        .state('app.help.cartoSeeAssociate', {
            url: '/cartography/visualiser-association',
            templateUrl: userManualCartographyCartoSeeCartoSeeAssociateTemplate,
            data: {
                title: 'Visualiser en associant ',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Visualiser', sref: 'app.help.cartoSee' },
                ],
                previous: 'app.help.cartoSeeOnly',
                next: 'app.help.cartoUse',
            },
        })
        .state('app.help.cartoUse', {
            url: '/cartography/utilisation',
            templateUrl: userManualCartographyCartoUseCartoUseTemplate,
            data: {
                title: 'Utiliser mode cartographique',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoSeeAssociate',
                next: 'app.help.cartoUseInfo',
            },
        })
        .state('app.help.cartoUseInfo', {
            url: '/cartography/informations',
            templateUrl: userManualCartographyCartoUseCartoUseInfoTemplate,
            data: {
                title: 'Informations générales',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUse',
                next: 'app.help.cartoUseInteractive',
            },
        })
        .state('app.help.cartoUseInteractive', {
            url: '/cartography/barre-outil-interactive',
            templateUrl: userManualCartographyCartoUseCartoUseInteractiveTemplate,
            data: {
                title: 'Barre outil interactive',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseInfo',
                next: 'app.help.cartoUseShow',
            },
        })
        .state('app.help.cartoUseShow', {
            url: '/cartography/barre-outil-affichage',
            templateUrl: userManualCartographyCartoUseCartoUseShowTemplate,
            data: {
                title: 'Barre outil affichage',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseInteractive',
                next: 'app.help.cartoUseSearch',
            },
        })
        .state('app.help.cartoUseSearch', {
            url: '/cartography/barre-outil-recherche',
            templateUrl: userManualCartographyCartoUseCartoUseSearchTemplate,
            data: {
                title: 'Moteur de recherche',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseShow',
                next: 'app.help.cartoUseShapefile',
            },
        })
        .state('app.help.cartoUseShapefile', {
            url: '/cartography/import-shapefile',
            templateUrl: userManualCartographyCartoUseCartoUseShapefileTemplate,
            data: {
                title: 'Importer un shapefile',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseSearch',
                next: 'app.help.cartoUseCompare',
            },
        })
        .state('app.help.cartoUseCompare', {
            url: '/cartography/comparer',
            templateUrl: userManualCartographyCartoUseCartoUseCompareTemplate,
            data: {
                title: 'Comparer des couches',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseShapefile',
                next: 'app.help.cartoUseLinkSyno',
            },
        })
        .state('app.help.cartoUseLinkSyno', {
            url: '/cartography/synoptique',
            templateUrl: userManualCartographyCartoUseCartoUseLinkSynoTemplate,
            data: {
                title: 'Relier avec le Synoptique',
                navigation: [
                    { title: 'Mode cartographique', sref: 'app.help.carto' },
                    { title: 'Utiliser', sref: 'app.help.cartoUse' },
                ],
                previous: 'app.help.cartoUseCompare',
                next: 'app.help.synoptic',
            },
        });
});
