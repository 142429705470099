class ChartService {
    getDefaultScaleConfiguration(isStacked = true, { minRotation, fontSize } = { minRotation: 0, fontSize: 10 }) {
        return {
            x: {
                stacked: isStacked,
                ticks: {
                    beginAtZero: true,
                },
            },
            y: {
                stacked: isStacked,
                display: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: fontSize,
                    },
                    minRotation: minRotation,
                },
            },
        };
    }
}

angular.module('dotic').factory('$chartService', () => new ChartService());
