export const GeoserverConnectionStateEnum = {
    ALERT: 'alert',
    INFO: 'info',
    SUCCESS: 'success',
};

export const SubscriptionOptionEnum = {
    ADVANCED: 'advanced',
    BASIC: 'basic',
};
