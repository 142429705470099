import { defaultPagination } from '../../models/common.model';
import { GridActionEnum } from '../../models/grid.model';
import { HelpersService } from '../../services/helpers.service';
import template from './controlGridList.html';

class ControlGridListController {
    companies = [];
    controlGrids = { ...defaultPagination };
    dataModels = [];
    loading = true;
    page = 0;
    pageSize = 10;
    sort = { column: 'createdAt', order: -1 };

    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $filterService,
        $gridService,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        companyProvider,
        dataModelProvider,
        gridControlProvider,
    ) {
        this._$deleteService = $deleteService;
        this._$filterService = $filterService;
        this._$gridService = $gridService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._dataModelProvider = dataModelProvider;
        this._gridControlProvider = gridControlProvider;
        this._translate = $filter('translate');

        this.isTemplate = $state.params.isTemplate;

        this.isAllowed = $authorizationService.isAllowed;
        this.filter = {
            company: $state.params.company || '',
            dataModel: $state.params.dataModel || '',
            isTemplate: $state.params.isTemplate || false,
            search: $state.params.search || '',
        };
        this.tableSelect = this._$tableService.select();

        $scope.$watchGroup(
            ['$ctrl.filter.company', '$ctrl.filter.dataModel', '$ctrl.filter.search', '$ctrl.gridControlList'],
            async () => {
                this.page = 1;
                $state.go('.', {
                    ...this.filter,
                });
                await this.loadData();
            },
        );

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'controlGrids',
                    title: this._translate('shared.controlGrid', { TYPE: this.isTemplate ? 'template' : 'working' }),
                    href: $location.path(),
                },
            ],
        });

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.company = $state.params.company || '';
            this.filter.dataModel = $state.params.dataModel || '';
            this.filter.search = $state.params.search || '';

            $scope.$emit('updateNavigationUrl');
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        await this.loadData();
    }

    async loadData() {
        this.loading = true;

        try {
            this.controlGrids = await this._$gridService.search(
                this.page,
                this.pageSize,
                this.sort,
                { ...this.filter, template: this.filter.isTemplate },
                false,
            );

            if (!this.dataModels.length) {
                this.dataModels = HelpersService.toKeyValue(await this._dataModelProvider.getAll());
            }

            if (!this.companies.length) {
                this.companies = HelpersService.toKeyValue(await this._companyProvider.getAll());
            }
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async onPaginationChange(page, perPage, sort) {
        this.pageSize = perPage;
        this.page = page;
        this.sort = sort;

        await this.loadData();
    }

    getPossibleActions(controlGrid) {
        return this._$gridService.getPossibleActions(controlGrid, [
            GridActionEnum.DUPLICATION,
            GridActionEnum.EDITION,
            GridActionEnum.ETL_SYNCHRONIZE,
        ]);
    }

    getSelectionType() {
        const removable = this.controlGrids.data.filter((item) => this.isAllowedToRemove(item));

        switch (this.tableSelect.selectionSize()) {
            case removable.length:
                return 'all';
            case 0:
                return 'none';
            case this.pageSize:
                return 'visible';
            default:
                return 'few';
        }
    }

    isAllowedToRemove(item) {
        return item && this.isAllowed(['superAdmin']);
    }

    async removeControlGrids() {
        // Get selected rows
        const selectedGrids = this._$gridService.selectDataToRemove(
            this.controlGrids.data,
            this.tableSelect.isActive,
            this.isAllowed,
        );

        if (!selectedGrids.length) {
            return;
        }

        const infoText = await this._$gridService.removeMessage(selectedGrids, this.isTemplate, false);

        const isAccepted = await this._$modalService.triggerRemoveModal(infoText);
        if (!isAccepted) {
            return;
        }

        const selectedGridIds = selectedGrids.map((selectedGrid) => selectedGrid.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedGridIds,
            (gridId) => this._gridControlProvider.delete(gridId),
            'removeModal.successGrid',
            'removeModal.failureGrid',
        );

        await this.loadData();
    }

    async onExportGridControlClick(gridControlId) {
        return await this._$gridService.onExportGrid(gridControlId, false);
    }
}

angular.module('dotic').component('controlGridListPage', {
    controller: ControlGridListController,
    templateUrl: template,
});
