import template from './site.html';
class SitePage {
    constructor($filter, $location, $scope, siteProvider) {
        this._$scope = $scope;
        this._siteProvider = siteProvider;
        this._translate = $filter('translate');

        this.loading = true;
        this.sites = [];

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'sitesList',
                    title: this._translate('shared.orangeSites'),
                    href: $location.path(),
                },
            ],
        });
    }

    $onInit() {
        this.loading = true;

        return this.loadSites().then((data) => {
            this.sites = data;

            this.loading = false;
        });
    }

    loadSites() {
        return this._siteProvider.getSites();
    }
}

angular.module('dotic').component('sitePage', {
    templateUrl: template,
    controller: SitePage,
});
