import template from './companiesListModal.html';

class CompaniesListModalController {
    constructor($scope) {
        this._$scope = $scope;

        this.companies = [];
        this.loading = true;
    }

    $onInit() {
        const { companies } = this.resolve;

        if (angular.isArray(companies)) {
            this.setCompanies(companies);
        } else if (angular.isFunction(companies)) {
            companies()
                .then((results) => this.setCompanies(results))
                .then(() => {
                    this._$scope.$apply();
                });
        } else {
            this.loading = false;
        }
    }

    setCompanies(companies) {
        this.loading = false;
        this.companies = companies;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('companiesListModal', {
    templateUrl: template,
    controller: CompaniesListModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
