/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* Reports service.
* @module api/ReportsApi
* @version 1.0.0
*/
export default class ReportsApi {

    /**
    * Constructs a new ReportsApi. 
    * @alias module:api/ReportsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Download a pdf file comparing two deposits report
     * @param {String} depositId1 
     * @param {String} depositId2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    comparePdfWithHttpInfo(depositId1, depositId2) {
      let postBody = null;
      // verify the required parameter 'depositId1' is set
      if (depositId1 === undefined || depositId1 === null) {
        throw new Error("Missing the required parameter 'depositId1' when calling comparePdf");
      }
      // verify the required parameter 'depositId2' is set
      if (depositId2 === undefined || depositId2 === null) {
        throw new Error("Missing the required parameter 'depositId2' when calling comparePdf");
      }

      let pathParams = {
        'depositId1': depositId1,
        'depositId2': depositId2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{depositId1}/{depositId2}/compare/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download a pdf file comparing two deposits report
     * @param {String} depositId1 
     * @param {String} depositId2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    comparePdf(depositId1, depositId2) {
      return this.comparePdfWithHttpInfo(depositId1, depositId2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate and download control point comparison file for a report
     * @param {String} depositId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    compareVersionControlJsonWithHttpInfo(depositId, controlPoint, severity) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling compareVersionControlJson");
      }
      // verify the required parameter 'controlPoint' is set
      if (controlPoint === undefined || controlPoint === null) {
        throw new Error("Missing the required parameter 'controlPoint' when calling compareVersionControlJson");
      }
      // verify the required parameter 'severity' is set
      if (severity === undefined || severity === null) {
        throw new Error("Missing the required parameter 'severity' when calling compareVersionControlJson");
      }

      let pathParams = {
        'depositId': depositId,
        'controlPoint': controlPoint
      };
      let queryParams = {
        'severity': severity
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/deposit/{depositId}/controls/{controlPoint}/json', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate and download control point comparison file for a report
     * @param {String} depositId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    compareVersionControlJson(depositId, controlPoint, severity) {
      return this.compareVersionControlJsonWithHttpInfo(depositId, controlPoint, severity)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download customer version of a report pdf file
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    customerPDFReportWithHttpInfo(reportId) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling customerPDFReport");
      }

      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/pdf-customer', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download customer version of a report pdf file
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    customerPDFReport(reportId) {
      return this.customerPDFReportWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate and download control point json file for a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadControlJsonWithHttpInfo(reportId, controlPoint, severity) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadControlJson");
      }
      // verify the required parameter 'controlPoint' is set
      if (controlPoint === undefined || controlPoint === null) {
        throw new Error("Missing the required parameter 'controlPoint' when calling downloadControlJson");
      }
      // verify the required parameter 'severity' is set
      if (severity === undefined || severity === null) {
        throw new Error("Missing the required parameter 'severity' when calling downloadControlJson");
      }

      let pathParams = {
        'reportId': reportId,
        'controlPoint': controlPoint
      };
      let queryParams = {
        'severity': severity
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/controls/{controlPoint}/json', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate and download control point json file for a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadControlJson(reportId, controlPoint, severity) {
      return this.downloadControlJsonWithHttpInfo(reportId, controlPoint, severity)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate and download control point shape file from a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadControlShapeWithHttpInfo(reportId, controlPoint, severity) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadControlShape");
      }
      // verify the required parameter 'controlPoint' is set
      if (controlPoint === undefined || controlPoint === null) {
        throw new Error("Missing the required parameter 'controlPoint' when calling downloadControlShape");
      }
      // verify the required parameter 'severity' is set
      if (severity === undefined || severity === null) {
        throw new Error("Missing the required parameter 'severity' when calling downloadControlShape");
      }

      let pathParams = {
        'reportId': reportId,
        'controlPoint': controlPoint
      };
      let queryParams = {
        'severity': severity
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/controls/{controlPoint}/shape', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate and download control point shape file from a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadControlShape(reportId, controlPoint, severity) {
      return this.downloadControlShapeWithHttpInfo(reportId, controlPoint, severity)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate and download control point xlsx file for a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadControlXlsxWithHttpInfo(reportId, controlPoint, severity) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadControlXlsx");
      }
      // verify the required parameter 'controlPoint' is set
      if (controlPoint === undefined || controlPoint === null) {
        throw new Error("Missing the required parameter 'controlPoint' when calling downloadControlXlsx");
      }
      // verify the required parameter 'severity' is set
      if (severity === undefined || severity === null) {
        throw new Error("Missing the required parameter 'severity' when calling downloadControlXlsx");
      }

      let pathParams = {
        'reportId': reportId,
        'controlPoint': controlPoint
      };
      let queryParams = {
        'severity': severity
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/controls/{controlPoint}/xlsx', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate and download control point xlsx file for a report
     * @param {String} reportId 
     * @param {String} controlPoint 
     * @param {String} severity 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadControlXlsx(reportId, controlPoint, severity) {
      return this.downloadControlXlsxWithHttpInfo(reportId, controlPoint, severity)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate and download fiber error file for a report
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadErrorsFiberXlsxWithHttpInfo(reportId) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadErrorsFiberXlsx");
      }

      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/errors/fiber/xlsx', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate and download fiber error file for a report
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadErrorsFiberXlsx(reportId) {
      return this.downloadErrorsFiberXlsxWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download zip file of shapes for a report
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadErrorsZipWithHttpInfo(reportId) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadErrorsZip");
      }

      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/errors/zip', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download zip file of shapes for a report
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadErrorsZip(reportId) {
      return this.downloadErrorsZipWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download report pdf file
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadPdfWithHttpInfo(reportId) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling downloadPdf");
      }

      let pathParams = {
        'reportId': reportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{reportId}/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download report pdf file
     * @param {String} reportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadPdf(reportId) {
      return this.downloadPdfWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a report by id
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getByIdWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a report by id
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getById(id) {
      return this.getByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all report details
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{id}/details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all report details
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getDetails(id) {
      return this.getDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a report from a deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getFromDepositWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getFromDeposit");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/deposit/{depositId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a report from a deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getFromDeposit(depositId) {
      return this.getFromDepositWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get report statistics
     * @param {String} statsReportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getReportStatisticsWithHttpInfo(statsReportId) {
      let postBody = null;
      // verify the required parameter 'statsReportId' is set
      if (statsReportId === undefined || statsReportId === null) {
        throw new Error("Missing the required parameter 'statsReportId' when calling getReportStatistics");
      }

      let pathParams = {
        'statsReportId': statsReportId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/reports/{statsReportId}/statistics', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get report statistics
     * @param {String} statsReportId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getReportStatistics(statsReportId) {
      return this.getReportStatisticsWithHttpInfo(statsReportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
