angular.module('dotic').factory('serverProvider', serverProvider);

function serverProvider(ApiProvider, URI_KEYS) {
    return {
        getAll: getAll,
        getDetail: getDetail,
        removeServer: removeServer,
        createServer: createServer,
        updateServer: updateServer,
    };

    /**
     *
     */
    function getAll(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.SERVER).customGET(null, queryParams);
    }

    /**
     * GET /servers/:serverId
     *
     * @param {object} serverId
     *
     * @returns {*} Promise
     */
    function getDetail(serverId) {
        return ApiProvider.one(URI_KEYS.SERVER, serverId).one('detail').get();
    }

    /**
     * DELETE /servers/:serverId
     *
     * @param {object} serverId
     *
     * @returns {*} Promise
     */
    function removeServer(serverId) {
        return ApiProvider.one(URI_KEYS.SERVER, serverId).remove();
    }

    /**
     * POST /servers/
     *
     * @param {object} serverData
     *
     * @returns {*} Promise
     */
    function createServer(serverData) {
        return ApiProvider.one(URI_KEYS.SERVER).customPOST(serverData);
    }

    /**
     * PUT /servers/:serverId
     *
     * @param {string} serverId
     * @param {object} serverData
     *
     * @returns {*} Promise
     */
    function updateServer(serverId, serverData) {
        return ApiProvider.one(URI_KEYS.SERVER, serverId).customPUT(serverData);
    }
}
