const segmentsOrder = ['TR', 'DI', 'RA'];
class SegmentsService {
    getAll() {
        return segmentsOrder;
    }

    getNetworkSegmentsFromRegexValue(regexValue, deliveryZoneName) {
        if (regexValue) {
            const networkSegments = regexValue.toLocaleUpperCase().split('-');

            return networkSegments.length > 1 ? this.sortSegments(networkSegments) : networkSegments;
        }

        if (deliveryZoneName.toLocaleUpperCase().indexOf('TRANSPORT') !== -1) {
            return ['TR'];
        }

        if (deliveryZoneName.toLocaleUpperCase().startsWith('FI-')) {
            return ['DI'];
        }

        return ['TR', 'DI'];
    }

    isDataSegmentEnabled(segmentName, item) {
        const currentSegment = item.networkSegments.find((e) => e.segmentName === segmentName);

        return currentSegment ? currentSegment.isEnabled : false;
    }

    setNetworkSegments(networkSegments) {
        if (networkSegments && angular.isString(networkSegments[0])) {
            return networkSegments;
        }

        // get only segments enabled
        let segmentSelected = [];

        if (networkSegments?.length) {
            segmentSelected = networkSegments
                .filter((p) => this.getAll().includes(p.segmentName) && p.isEnabled)
                .map((el) => el.segmentName);
        }

        return segmentSelected;
    }

    sortSegments(segments) {
        return segmentsOrder.filter((segment) => segments.includes(segment));
    }
}

angular.module('dotic').factory('$segmentsService', () => new SegmentsService());
