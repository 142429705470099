import template from './change-password.component.html';

class ChangePasswordComponent {
    changePasswordForm = {};
    changePasswordFormValue = {};
    form = {};
    isCenterMode = false;
    isConfirmModeActivated = false;
    isConfirmPasswordVisible = false;
    isHintDisplayed = false;
    isNewPasswordVisible = false;
    isTextWhite = false;
    onSubmit = null;
    passwordStrengthLabel = '';
    passwordStrengthScore = -1;

    constructor($filter, $scope, $toasterService) {
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        $scope.$watch('$ctrl.changePasswordFormValue.newPassword', () => this.onNewPasswordChange());
    }

    getPasswordStrength(password) {
        if (!password) {
            return -1;
        }

        const isPasswordRespectingRegex =
            /\d/.test(password) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password); // eslint-disable-line no-useless-escape

        if (password.length > 31 && isPasswordRespectingRegex) {
            return 3;
        }

        if (password.length > 7 && isPasswordRespectingRegex) {
            return 2;
        }

        if (password.length > 7 || isPasswordRespectingRegex) {
            return 1;
        }

        return 0;
    }

    getPasswordStrengthLabel(passwordStrength) {
        switch (passwordStrength) {
            case 0:
                return this._translate('shared.weak');
            case 1:
                return this._translate('shared.deficient');
            case 2:
                return this._translate('shared.acceptable');
            case 3:
                return this._translate('shared.excellent');
            default:
                return this._translate('shared.noPassword');
        }
    }

    onNewPasswordChange() {
        this.passwordStrengthScore = this.getPasswordStrength(this.changePasswordFormValue.newPassword);
        this.passwordStrengthLabel = this.getPasswordStrengthLabel(this.passwordStrengthScore);
    }

    submitForm() {
        if (
            this.isConfirmModeActivated &&
            this.changePasswordFormValue.newPassword !== this.changePasswordFormValue.confirmPassword
        ) {
            this._$toasterService.error(this._translate('shared.errorDifferentPasswords'));

            return;
        }

        this.onSubmit({ value: this.changePasswordFormValue.newPassword });
    }

    toggleConfirmPasswordVisibility() {
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    }

    toggleNewPasswordVisibility() {
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
    }
}

angular.module('dotic').component('changePassword', {
    controller: ChangePasswordComponent,
    bindings: {
        isCenterMode: '<',
        isConfirmModeActivated: '<',
        isHintDisplayed: '<',
        isTextWhite: '<',
        onSubmit: '&',
    },
    templateUrl: template,
});
