import template from './depositListVersionModal.html';

class DepositListVersionModalController {
    constructor($state) {
        this._$state = $state;
    }

    $onInit() {
        this.company = this.resolve.company;
        this.prepareDepositList();
    }

    prepareDepositList() {
        this.deposits = this.company;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('depositListVersionModal', {
    templateUrl: template,
    controller: DepositListVersionModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
