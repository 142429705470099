const geometryMap = new Map([
    [2154, 'Lambert 93'],
    [3943, 'CC 43'],
    [3944, 'CC 44'],
    [3945, 'CC 45'],
    [3946, 'CC 46'],
    [3947, 'CC 47'],
    [3948, 'CC 48'],
    [3949, 'CC 49'],
    [3950, 'CC 50'],
    [32620, 'UTM 20 N'],
    [2972, 'UTM 22 N'],
    [4471, 'UTM 38 S'],
    [2975, 'UTM 40 S'],
]);

class GeometryService {
    /**
     * @see https://geodesie.ign.fr/contenu/fichiers/SRCfrance.pdf
     */
    getAll() {
        return geometryMap;
    }
    get(key) {
        return geometryMap.get(key);
    }

    isKeyAssociatedToGeometry(key) {
        return !!this.get(key);
    }
}

angular.module('dotic').factory('$geometryService', () => new GeometryService());
