import EnvironmentSettingsService from '../../services/environment-settings.service';

class VersionComponent {
    constructor($timeout, $toasterService, healthProvider) {
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._healthProvider = healthProvider;
    }

    async $onInit() {
        const version = APP_VERSION;
        const getBackVersion = await this.getBackendVersion();
        const orangeVersion = this.getOrangeVersion(version);
        const orangeBackendVersion = this.getOrangeVersion(getBackVersion);

        // This timeout prevent a lifecycle issue where the component load, the $onInit trigger, but the new data are not forwarded to the scope
        this._$timeout(() => {
            this.displayVersion = {
                client: EnvironmentSettingsService.isOrange() ? orangeVersion : version,
                api: EnvironmentSettingsService.isOrange() ? orangeBackendVersion : getBackVersion,
            };

            this.year = moment().format('YYYY');
        });
    }

    async getBackendVersion() {
        try {
            const status = await this._healthProvider.getStatus();

            return status.version;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    getOrangeVersion(version) {
        if (!version || version.split('.').length < 3) {
            return 'G-R-C-';
        }

        const [major, minor, patch] = version.split('.');

        return `G${major}R${minor}C${patch}`;
    }
}

angular.module('dotic').component('ccVersion', {
    controller: VersionComponent,
    template: `<div>
    <span class="m-b-15">&copy; Dotic, 2018 / {{$ctrl.year}}</span>
    <div><i class="icofont-flag"></i> Version Client: {{$ctrl.displayVersion.client}}</div>
    <div><i class="icofont-flag"></i> Version Api: {{$ctrl.displayVersion.api}}</div>
        </div>`,
});
