angular.module('dotic').filter('secondsToTime', () => {
    function padTime(time) {
        return time < 10 ? `0${time}` : time;
    }

    return function (_seconds) {
        if (!_seconds) {
            return '00:00:00';
        }

        const hours = Math.floor(_seconds / 3600),
            minutes = Math.floor((_seconds % 3600) / 60),
            seconds = Math.floor(_seconds % 60);

        return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;
    };
});
