import { CompaniesApi } from '../../sdk/connect-control-api-v1';

export const FeatureEnum = {
    AGGREGATION: 'aggregation',
    ANCT: 'anct',
    FTTX_DATA_EVOLUTION: 'fttxDataEvolution',
    GEO_PACKAGE_EXPORT: 'geoPackageExport',
    STATISTICS: 'statistics',
};

function buildFeatureGuard($apiClientService, $auth, $filter, $state, $toasterService) {
    return async (feature) => {
        const companiesApi = new CompaniesApi($apiClientService.client);
        const currentCompany = (await companiesApi.getDetailsWithHttpInfo($auth.getPayload().company)).response.body;

        switch (feature) {
            case FeatureEnum.AGGREGATION:
                if (currentCompany?.settings?.feature?.hasAggregationsEnabled) {
                    return;
                }
                break;
            case FeatureEnum.ANCT:
                if (currentCompany?.settings?.feature?.hasANCTVerification) {
                    return;
                }
                break;
            case FeatureEnum.FTTX_DATA_EVOLUTION:
                if (currentCompany?.settings?.feature?.hasFttxDataEvolutionEnabled) {
                    return;
                }
                break;
            case FeatureEnum.GEO_PACKAGE_EXPORT:
                if (currentCompany?.settings?.feature?.hasGeoPackageExportEnabled) {
                    return;
                }
                break;
            case FeatureEnum.STATISTICS:
                if (currentCompany?.settings?.feature?.hasStatisticsEnabled) {
                    return;
                }
                break;
            default:
                return;
        }

        $toasterService.error($filter('translate')('shared.unavailableFeature'));
        $state.go('app.dashboard');
    };
}

angular
    .module('dotic')
    .factory('hasFeatureGuard', ($apiClientService, $auth, $filter, $state, $toasterService) =>
        buildFeatureGuard($apiClientService, $auth, $filter, $state, $toasterService),
    );
