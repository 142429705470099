angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.supervisionList', {
        url: '/supervision',
        component: 'supervisionPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.supervisionCreate', {
        url: '/supervision/create',
        component: 'supervisionFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.supervisionEdit', {
        url: '/supervision/{indicatorAlertId:[a-f0-9]+}/edit',
        component: 'supervisionFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.supervisionView', {
        url: '/supervision/{indicatorAlertId:[a-f0-9]+}/view',
        component: 'supervisionViewPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
});
