class LogoutPage {
    constructor($auth, $state) {
        this._$auth = $auth;
        this._$state = $state;
    }

    $onInit() {
        if (this._$auth?.isAuthenticated()) {
            this._$auth.logout().then(() => this._$state.go('login', { reload: true }));
        } else {
            this._$state.go('login', { reload: true });
        }
    }
}

angular.module('dotic').component('logoutPage', {
    controller: LogoutPage,
    template: '',
});
