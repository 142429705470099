import { CompaniesApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './userFormModal.html';

class UserFormModalComponent {
    constructor($apiClientService, $authorizationService, $filter, $toasterService, $userService) {
        this.isAllowed = $authorizationService.isAllowed;
        this._companiesApi = new CompaniesApi($apiClientService.client);
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this.close = undefined;
        this.connectedCompany = $userService.getConnectedCompany();
        this.connectedUser = $userService.getConnectedUser();
        this.isSubCompany = false;
        this.loading = false;
        this.resolve = {
            company: {},
            user: undefined,
        };
        this.userForm = {};
    }

    $onInit() {
        this.company = this.resolve.company;
        this.user = this.resolve.user;

        this.isActiveUser = this.connectedUser._id === this.user?._id;
        this.isDebugModeTrigger = this.connectedCompany?.settings.feature.hasDebugMode;
        this.isSubCompany = !!this.company?.settings.feature.isSubCompany;
    }

    cancel() {
        this.close({ $value: false });
    }

    async setUser() {
        if (!this.userForm.$valid) {
            return;
        }

        this.loading = true;
        try {
            if (!this.user._id) {
                await this._companiesApi.addUser(this.company._id, this.user);

                return this._$toasterService.success({
                    body: this._translate('userFormModal.userAdded'),
                });
            }

            await this._companiesApi.updateUser(this.company._id, this.user._id, this.user);

            return this._$toasterService.success({
                body: this._translate('userFormModal.userEdited'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this.loading = false;
            this.close({ $value: true });
        }
    }
}

angular.module('dotic').component('userFormModal', {
    bindings: {
        close: '&',
        resolve: '<',
    },
    controller: UserFormModalComponent,
    templateUrl: template,
});
