import { BtiPositionEnum } from '../models/indicator.model';

export class IndicatorCableBtiService {
    static cableBtiDefaultValues = {
        inPosition: 0,
        isOld: false,
        outPosition: 0,
    };

    initCableBti(cableBti) {
        const indicator = {
            ...IndicatorCableBtiService.cableBtiDefaultValues,
            isOld: angular.isUndefined(cableBti?.[0]?.position),
        };

        if (indicator.isOld) {
            return indicator;
        }

        return {
            ...indicator,
            inPosition: cableBti.filter((item) => item.position === BtiPositionEnum.IN).length,
            outPosition: cableBti.filter((item) => item.position === BtiPositionEnum.OUT).length,
        };
    }
}

angular.module('dotic').factory('$indicatorCableBtiService', () => new IndicatorCableBtiService());
