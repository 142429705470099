import template from './ibox.html';
class ToggleController {
    constructor() {
        this.opened = false;
    }

    isOpen() {
        return this.opened;
    }
    toggle() {
        this.opened = !this.opened;
    }
}
angular.module('dotic').component('ccIbox', {
    controller: ToggleController,
    templateUrl: template,
    transclude: true,
    bindings: {
        titleBorder: '@',
        align: '@',
        title: '@',
        color: '@',
        minHeight: '@',
        border: '@',
        loading: '<',
        center: '<',
        icon: '@',
        tooltip: '@',
    },
});
