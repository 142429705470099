import template from './opticalCableSupport.html';
class OpticalCableSupportController {
    constructor($timeout, $indicatorMaxValueService, $indicatorOpticalCableSupportService) {
        this._$timeout = $timeout;
        this._$indicatorMaxValueService = $indicatorMaxValueService;
        this._$indicatorOpticalCableSupportService = $indicatorOpticalCableSupportService;

        this.loaded = false;
        this.isOld = false;
        this.chartData = null;
        this.data = null;

        this.options = {
            responsive: true,
            scales: {},
        };
    }

    $onInit() {
        if (!this.data?.table?.length) {
            this.loaded = true;
            this.isOld = true;

            return false;
        }

        const { opticalSupports, table, status } = this.data;
        if (
            !angular.isObject(opticalSupports) ||
            angular.isUndefined(status) ||
            !table[0] ||
            angular.isUndefined(table[0].avancement)
        ) {
            this.isOld = true;

            return false;
        }

        const labels = Object.keys(opticalSupports);

        const datasets = [];

        status.map((state) => {
            const status = this._$indicatorOpticalCableSupportService.getData(state);

            const colorReal = status?.colorSaisie || '';
            const ColorGeom = status?.colorCarto || '';
            const geom = Object.values(opticalSupports).map((opticalSupport) => {
                if (opticalSupport[state]?.data_geom) {
                    return opticalSupport[state].data_geom;
                }

                return 0;
            });

            const saisie = Object.values(opticalSupports).map((opticalSupport) => {
                if (opticalSupport[state]?.data_saisie) {
                    return opticalSupport[state].data_saisie;
                }

                return 0;
            });

            datasets.push(
                {
                    backgroundColor: ColorGeom,
                    label: `Lg Carto : ${state}`,
                    data: geom.map((element) => element?.toFixed(1) ?? null),
                    // stack: 'geom',
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.75,
                    barPercentage: 0.75,
                    maxBarThickness: 15,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        color: ColorGeom,
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    backgroundColor: colorReal,
                    label: `Lg Réelle : ${state}`,
                    data: saisie.map((element) => element?.toFixed(1) ?? null),
                    // stack: 'saisie',
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.75,
                    barPercentage: 0.75,
                    maxBarThickness: 15,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        color: colorReal,
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            );
        });

        this._$timeout(() => {
            this.avancements = this._$indicatorOpticalCableSupportService.sumOpticalByType(this.data);
            this.table = table;
            this.chartData = { labels, datasets };
            this.opticalSupportMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(this.chartData);
            this.loaded = true;
        });
    }
}

angular.module('dotic').component('opticalCableSupportIndicator', {
    controller: OpticalCableSupportController,
    templateUrl: template,
    bindings: {
        parentRef: '<',
        data: '<',
    },
});
