import { DeliveryZonesApi } from '../../../sdk/connect-control-api-v1/src';

class DeliveryZoneListController {
    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $deleteService,
        $deliveryZoneService,
        $excelService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        companyProvider,
        deliveryZoneProvider,
        projectProvider,
        serverProvider,
        LoaderService,
    ) {
        this._$auth = $auth;
        this._$deleteService = $deleteService;
        this._$excelService = $excelService;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._$tableService = $tableService;
        this._$deliveryZoneService = $deliveryZoneService;
        this._$state = $state;
        this._$scope = $scope;
        this._companyProvider = companyProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._deliveryZoneApi = new DeliveryZonesApi($apiClientService.client);
        this._projectProvider = projectProvider;
        this._serverProvider = serverProvider;
        this._$timeout = $timeout;
        this._LoaderService = LoaderService;
        this._$modalService = $modalService;
        this._translate = $filter('translate');

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;

        this.deliveryZoneList = [];
        this.filterOptions = [
            {
                allowed: true,
                name: 'projectList',
                value: 'project',
                placeholder: 'filter.all.projects',
                hasSearch: true,
                translateKeyLabel: 'shared.project',
            },
            {
                allowed: true,
                name: 'projectIdList',
                value: 'projectId',
                placeholder: 'filter.all.projectIds',
                hasSearch: true,
                translateParamsLabel: { COUNT: 1 },
                translateKeyLabel: 'shared.businessCode',
            },
            {
                allowed: true,
                name: 'serverList',
                value: 'server',
                placeholder: 'filter.all.servers',
                translateKeyLabel: 'shared.storageBase',
                translateParamsLabel: { COUNT: 1 },
                hasSearch: true,
            },
            {
                allowed: true,
                name: 'subCompanyList',
                value: 'subCompany',
                placeholder: 'filter.all.subCompanies',
                hasSearch: true,
                translateKeyLabel: 'shared.subcontractor',
            },
        ];

        this.filterValues = {
            projectList: [],
            projectIdList: [],
            serverList: [],
            subCompanyList: [],
        };

        this.subCompanies = [];
        this.filteredDeliveryZoneList = [];
        this.deliveryZoneDetail = [];
        this.setDefaultFilter($state.params);

        this.loading = true;

        this.noDatabase = { value: this._translate('shared.none', { GENDER: 'female' }), key: '-1' };
        this.noProject = { value: this._translate('shared.none'), key: '-1' };
        this.noProjectId = { value: this._translate('shared.none'), key: '-1' };
        this.noSubCompany = { value: this._translate('shared.none'), key: '-1' };

        $scope.$watchGroup(
            [
                '$ctrl.filter.project',
                '$ctrl.filter.projectId',
                '$ctrl.filter.server',
                '$ctrl.filter.search',
                '$ctrl.filter.subCompany',
            ],
            () => this.filterByFilters(),
        );

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';
            this.filter.server = $state.params.server || '';
            this.filter.project = $state.params.project || '';
            this.filter.projectId = $state.params.projectId || '';
            this.filter.subCompany = $state.params.subCompany || '';

            $scope.$emit('updateNavigationUrl');
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'deliveryZones',
                    title: this._translate('shared.deliveryZone'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    selectAllDeliveryZones() {
        this._$scope.$broadcast('getVisibleRows');
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setDefaultFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            project: params.project || '',
            projectId: params.projectId || '',
            server: params.server || '',
            search: params.search || '',
            subCompany: params.subCompany || '',
        };
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();
        this.subCompanies = await this.getSubCompanies();

        await this.fetchData();
        this.prepareFilters(this.deliveryZoneList);
        this.filterByFilters();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    findDeliveryZone(deliveryZone) {
        return this.deliveryZoneList.find(
            (zone) => zone.name === deliveryZone.name && zone.project.id === deliveryZone.project,
        );
    }

    async importDeliveryZonesFromExcel() {
        const data = await this._$modalService.triggerImportExcelModal(true);
        if (!data) {
            return;
        }

        try {
            const [paginatedServers, projects] = await Promise.all([
                this._serverProvider.getAll(),
                this._projectProvider.getAll(),
            ]);

            const deliveryZones = this._$deliveryZoneService.excelToJson(
                data,
                projects,
                paginatedServers.data,
                this.subCompanies,
            );

            this._LoaderService.open(`
                <p>${this._translate('shared.downloadingFile')}</p>
                <p>${this._translate('deliveryZoneList.wait')}</p>
            `);
            const promises = deliveryZones.map(async (deliveryZone) => {
                const originalZone = this.findDeliveryZone(deliveryZone);

                let request = () => this._deliveryZoneApi.creatDeliveryZone(deliveryZone);
                let labelSuccess = deliveryZone.name;
                if (originalZone) {
                    request = () => this._deliveryZoneProvider.update(originalZone.id, deliveryZone);
                    labelSuccess = originalZone.name;
                }

                await request();

                this._$toasterService.success({
                    body: `${this._translate('deliveryZoneList.deliveryZone')} ${labelSuccess} ${this._translate(
                        `deliveryZoneList.${originalZone ? 'modified' : 'created'}`,
                    )}`,
                });
            });

            await Promise.all(promises);

            this._LoaderService.dismiss();
            await this.initData();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    exportDeliveryZonesToExcel(zoneId) {
        return this._deliveryZoneProvider
            .get(zoneId)
            .then((zone) => {
                const fileName = zone.name;
                const data = this._$deliveryZoneService.jsonToExcel(zone);

                return { fileName, data };
            })
            .catch((e) => {
                throw new Error(e);
            });
    }

    getSelectedZones() {
        const ids = this.tableSelect.getSelection();

        return this.deliveryZoneList.filter((zone) => ids.includes(zone.id));
    }

    async onExportSelectedDeliveryZones() {
        const zones = this.getSelectedZones();
        if (!zones.length) {
            return;
        }

        const promises = zones.map((zone) => this.exportDeliveryZonesToExcel(zone.id));

        try {
            const results = await Promise.all(promises);

            return this._$excelService.downloadXLSX(
                { headers: true },
                results.map((result) => result.data),
                'zones-de-livraison',
                'deliveryZoneList.exported',
            );
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    filterByFilters() {
        if (this.tableSelect) {
            this.tableSelect.empty();
        }

        const search = this.filter.search.toLocaleLowerCase();
        const { project, projectId, server, subCompany } = this.filter;
        this._$state.go('.', {
            server,
            search,
            project,
            projectId,
            subCompany,
        });

        this.filteredDeliveryZoneList = this.deliveryZoneList
            .filter((item) => search === '' || item.name.toLocaleLowerCase().indexOf(search) > -1)
            .filter((item) => this.filterProject(item, project))
            .filter((item) => this.filterProjectId(item, projectId))
            .filter((item) => this.filterServer(item, server))
            .filter((item) => this.filterSubCompany(item, subCompany));

        this.prepareFilters(this.filteredDeliveryZoneList);
    }

    filterProject(item, selectedProject) {
        if (selectedProject === '') {
            return true;
        }

        if (selectedProject === this.noProject.key) {
            return angular.isUndefined(item.project) || item.project === null;
        }

        return item.project && item.project.name === selectedProject;
    }

    filterProjectId(item, selectedProjectId) {
        if (selectedProjectId === '') {
            return true;
        }

        if (selectedProjectId === this.noProjectId.key) {
            return angular.isUndefined(item.projectId) || item.projectId === null || item.projectId === '';
        }

        return item.projectId === selectedProjectId;
    }

    filterServer(item, selectedServer) {
        if (selectedServer === '') {
            return true;
        }

        if (selectedServer === this.noDatabase.key) {
            return angular.isUndefined(item.server) || item.server === null;
        }

        return item.server && item.server.name === selectedServer;
    }

    filterSubCompany(item, selectedAssignmentId) {
        const { hasSubCompanies } = item;

        if (selectedAssignmentId === '') {
            return true;
        }

        if (selectedAssignmentId === this.noSubCompany.key) {
            return hasSubCompanies === false;
        }

        return item.assignments?.find((assignment) => assignment.name === selectedAssignmentId);
    }

    selectedDeliveryZoneHasDependencies() {
        const selectedDeliveryZone = this.getSelectedZones();

        return selectedDeliveryZone.some((deliveryZone) => deliveryZone.countDeposits > 0);
    }

    removeTooltip() {
        if (!this.tableSelect.hasSelected()) {
            return '';
        } else if (this.selectedDeliveryZoneHasDependencies()) {
            return this._translate('deliveryZoneList.deliverables');
        } else {
            return this._translate('shared.deleteSelection');
        }
    }

    /**
     * @return Promise<*>
     */
    getSubCompanies() {
        return this._companyProvider.getCompanySubCompanies(this.companyId);
    }

    async fetchData() {
        try {
            const deliveryZones = await this._deliveryZoneProvider.list();
            this.deliveryZoneList = deliveryZones.map((deliveryZone) => {
                const { startDate, countDeposits, assignments, project } = deliveryZone;

                const isUsed = countDeposits > 0;
                const localeStartDate = startDate ? moment(startDate).format('DD/MM/YYYY') : undefined;

                if (!assignments?.length) {
                    return {
                        ...deliveryZone,
                        hasSubCompanies: false,
                        subContractors: [],
                        localeStartDate: localeStartDate,
                        isUsed: isUsed,
                    };
                }

                let subContractors = assignments;
                if (project?.assignments) {
                    const assignedToProject = project.assignments
                        .filter((assignment) => assignment.type === 'subcontractor')
                        .map((assignment) => assignment.company.id);
                    subContractors = subContractors.filter(
                        (subContractor) => assignedToProject.indexOf(subContractor.id) > -1,
                    );
                }

                const hasSubCompanies = subContractors.length > 0;

                return {
                    ...deliveryZone,
                    hasSubCompanies: hasSubCompanies,
                    subContractors: subContractors,
                    localeStartDate: localeStartDate,
                    isUsed: isUsed,
                };
            });
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    prepareFilters(deliveryZoneList) {
        const projectList = new Map();
        const projectIdList = new Map();
        const serverList = new Map();
        let assignmentList = [];

        deliveryZoneList.forEach((deliveryZone) => {
            const { project, projectId, server, assignments } = deliveryZone;

            if (project?.name) {
                projectList.set(project.name, project.name);
            }

            if (projectId) {
                projectIdList.set(String(projectId), String(projectId));
            }

            if (server?.name) {
                serverList.set(server.name, server.name);
            }

            if (assignments) {
                assignmentList.push(...assignments);
            }
        });

        this.filterValues.projectList = Array.from(projectList, ([key, value]) => ({
            key,
            value,
        }));
        this.filterValues.projectIdList = Array.from(projectIdList, ([key, value]) => ({
            key,
            value,
        }));
        this.filterValues.serverList = Array.from(serverList, ([key, value]) => ({
            key,
            value,
        }));

        // Reduce duplicated assignments
        assignmentList = assignmentList.reduce((acc, item) => {
            if (!acc.find((a) => a.id === item.id)) {
                acc.push(item);
            }

            return acc;
        }, []);

        this.filterValues.subCompanyList = assignmentList.map((company) => ({
            key: company.name,
            value: company.name,
        }));
    }

    subCompanyListNames(item) {
        return item.assignments.map((company) => company.name).join(', ');
    }

    async removeSelectedDeliveryZones() {
        const selectedDeliveryZones = this.getSelectedZones();
        if (!selectedDeliveryZones.length || this.selectedDeliveryZoneHasDependencies()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.deliveryZone', {
                COUNT: selectedDeliveryZones.length,
                NAME: selectedDeliveryZones[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        const selectedDeliveryZoneIds = selectedDeliveryZones.map((selectedDeliveryZone) => selectedDeliveryZone.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedDeliveryZoneIds,
            (deliveryZoneId) => this._deliveryZoneProvider.remove(deliveryZoneId),
            'removeModal.successDeliveryZone',
        );

        await this.initData();
    }

    showDetails(deliveryZone) {
        this.tableDetail.toggle(deliveryZone);
    }

    cancelFilters() {
        this.setDefaultFilter({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').controller('DeliveryZoneListController', DeliveryZoneListController);
