import { pick as radashPick } from 'radash';

import { DeliveryZonesApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './deliveryZoneForm.html';

class DeliveryZoneFormController {
    constructor(
        $apiClientService,
        $auth,
        $filter,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        companyProvider,
        deliveryZoneProvider,
        projectProvider,
        serverProvider,
    ) {
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._projectProvider = projectProvider;
        this._serverProvider = serverProvider;
        this._deliveryZoneApi = new DeliveryZonesApi($apiClientService.client);
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._translate = $filter('translate');
        this._$scope.regexPattern = '[a-zA-Z0-9. -]+';
        this.deliveryZoneId = $stateParams.deliveryZoneId;
        this.isDuplicate = $stateParams.duplicate || false;
        this.companyId = $auth.getPayload().company;

        this.assignmentList = [];
        this.hasSubContractor = false;
        this.projectsKeys = [];
        this.serversKeys = [];

        this.deliveryZoneForm = {};
        this.deliveryZoneData = {
            name: '',
            description: '',
            project: null,
            server: null,
            city: '',
            INSEE: '',
            projectId: '',
            startDate: '',
            nature: '',
            department: '',
            assignments: [],
            idSroRegulatory: '',
            refTransportMoa: '',
            trigramNroMoa: '',
        };
    }

    $onInit() {
        this.loading = true;

        let newPage = [];

        // *
        // get data
        this.getDeliveryZone()
            .then(() => {
                return Promise.all([this.getProjectList(), this.getServerList()]);
            })
            .then(() => {
                if (this.deliveryZoneId && this.isDuplicate) {
                    // Duplicate
                    newPage = [
                        {
                            key: 'deliveryZoneDuplicate',
                            title: `Dupliquer la zone de livraison ${this.deliveryZoneData.name}`,
                            href: this._$state.href('app.deliveryZoneDuplicate'),
                        },
                    ];

                    this.title = 'Dupliquer la zone de livraison';
                    this.textButton = this._translate('shared.create');
                } else if (this.deliveryZoneId) {
                    // Edit
                    newPage = [
                        {
                            key: 'deliveryZoneEdit',
                            title: `${this._translate('deliveryZoneList.editDeliveryZone')} ${
                                this.deliveryZoneData.name
                            }`,
                            href: this._$state.href('app.deliveryZoneEdit'),
                        },
                    ];

                    this.title = `${this._translate('deliveryZoneList.editDeliveryZone')}`;
                    this.textButton = 'Modifier';
                } else {
                    // Create
                    newPage = [
                        {
                            key: 'deliveryZoneCreate',
                            title: this._translate('deliveryZoneList.createZone'),
                            href: this._$state.href('app.deliveryZoneCreate'),
                        },
                    ];

                    this.title = this._translate('deliveryZoneList.newDeliveryZone');
                    this.textButton = this._translate('shared.create');
                }

                // *
                // Update pagination
                this._$scope.$emit('keepPreviousNavigation', {
                    newPage,
                    defaultPrevious: {
                        title: 'Zones de livraison',
                        href: this._$state.href('app.deliveryZoneList'),
                        key: 'deliveryZones',
                    },
                    allowedPreviousKeys: ['deliveryZones'],
                });

                this.loading = false;
            })
            .catch(() => {
                this._$toasterService.error({
                    body: this._translate('toaster.error'),
                });
                this.loading = false;
            });
    }

    async createDeliveryZone(data) {
        try {
            const result = (await this._deliveryZoneApi.creatDeliveryZoneWithHttpInfo(data)).response.body;
            this._$toasterService.success(
                this._translate(this.isDuplicate ? 'shared.deliveryZoneDuplicated' : 'shared.deliveryZoneCreated'),
            );
            this._$state.go('app.deliveryZoneDetail', {
                deliveryZoneId: result.id,
            });
        } catch (error) {
            if (error.body?.code === 'NAME_DUPLICATE') {
                this._$toasterService.error({ body: error.body.message });

                return;
            }

            this._$toasterService.error({ body: this._translate('shared.errorCreation') });
        }
    }

    async getProjectList() {
        try {
            const projects = await this._projectProvider.getAll();
            this.projects = projects;
            this.projectsKeys = projects.map((project) => ({ key: project.id, value: project.name }));

            // Trigger the form update with delivery zone data
            this.onProjectSelect();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    onProjectSelect() {
        // Reset form input on select
        this.assignmentList = null;

        if (!this.deliveryZoneData.project) {
            return;
        }

        const project = this.projects.find((project) => project.id === this.deliveryZoneData.project.id);

        if (!project) {
            return;
        }

        // Get project assigned sub companies
        this._projectProvider.getAssignments(project.id).then((results) => {
            const assignments = results.filter(({ type }) => type === 'subcontractor');

            this.hasSubContractor = assignments.length > 0;

            this.assignmentList = assignments.map((subcontractor) => ({
                key: subcontractor.company.id,
                value: subcontractor.company.name,
            }));

            // Remove unavailable values from selected ones
            if (this.deliveryZoneData?.assignments) {
                this.deliveryZoneData.assignments = this.deliveryZoneData.assignments.filter((companyId) =>
                    this.assignmentList.find((company) => company.key === companyId),
                );
            }
        });
    }

    async getServerList() {
        try {
            const servers = await this._serverProvider.getAll();
            this.servers = servers.data;
            this.serversKeys = servers.data.map((server) => ({ key: server.id, value: server.name, ...server }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    getDeliveryZone() {
        if (!this.deliveryZoneId) {
            return Promise.resolve();
        }

        return this._deliveryZoneProvider.getEdit(this.deliveryZoneId).then((deliveryZone) => {
            let startDate = null;

            if (deliveryZone.startDate) {
                startDate = moment(deliveryZone.startDate).toDate();
            }

            this.deliveryZoneData = {
                ...deliveryZone,
                startDate,
            };
        });
    }

    async onSubmit() {
        if (!this.deliveryZoneForm.$valid) {
            return this._$toasterService.error({
                body: this._translate(
                    this.deliveryZoneForm.$error.pattern ? 'shared.wrongCharacters' : 'shared.missingFields',
                ),
            });
        }

        this.creating = true;

        const data = {
            ...radashPick(this.deliveryZoneData, [
                'area',
                'assignments',
                'city',
                'department',
                'description',
                'idSroRegulatory',
                'INSEE',
                'name',
                'nature',
                'progress',
                'projectId',
                'refTransportMoa',
                'startDate',
                'trigramNroMoa',
            ]),
            server: this.deliveryZoneData.server.id,
            project: this.deliveryZoneData.project.id,
            numberOfPlugs: Number.parseInt(this.deliveryZoneData.numberOfPlugs, 10),
        };

        if (!this.deliveryZoneId || this.isDuplicate) {
            await this.createDeliveryZone(data);
        } else {
            await this.updateDeliveryZone(data);
        }

        this.creating = false;
    }

    unsetNameError() {
        if (this.deliveryZoneForm.name) {
            this.deliveryZoneForm.name.$setValidity('duplicate', true);
        }
    }

    async updateDeliveryZone(data) {
        try {
            const result = await this._deliveryZoneProvider.update(this.deliveryZoneData.id, data);
            this._$toasterService.success(this._translate('shared.deliveryZoneModified'));
            this._$state.go('app.deliveryZoneDetail', {
                deliveryZoneId: this.deliveryZoneId || result.id,
            });
        } catch (error) {
            if (error.body?.code === 'NAME_DUPLICATE') {
                this._$toasterService.error({ body: error.body.message });

                return;
            }

            this._$toasterService.error({ body: this._translate('shared.errorUpdate') });
        }
    }
}

angular.module('dotic').component('deliveryZoneFormPage', {
    templateUrl: template,
    controller: DeliveryZoneFormController,
});
