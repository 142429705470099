angular.module('dotic').filter('uniq', () => {
    return (items, filterOn) => {
        if (!filterOn || !angular.isArray(items)) {
            return items;
        }

        const newItems = [];

        const extractValueToCompare = function (item) {
            if (angular.isObject(item) && angular.isString(filterOn)) {
                return item[filterOn];
            }

            return item;
        };

        angular.forEach(items, (item) => {
            const isDuplicate = newItems.some((newItem) =>
                angular.equals(extractValueToCompare(newItem), extractValueToCompare(item)),
            );
            if (isDuplicate) {
                return;
            }

            newItems.push(item);
        });

        return newItems;
    };
});
