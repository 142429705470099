// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-liste-1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-liste-new-orange.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-boutons-detail.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-dataModel-Plug.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-visualisation-plug.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 5.2.1 / {{ 'helpDeliverable.seeControlDeposits' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\" ng-if=\"!$ctrl.isOrange()\"> {{ 'helpDeliverable.statusDeliverable' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpDeliverable.statusDeliverable' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\" ng-if=\"$ctrl.isOrange()\"> {{ 'helpDeliverable.statusDeliverable' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'helpDeliverable.statusDeliverable' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.detailControlled' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"{{ 'helpDeliverable.detailControlled' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.calculationNumberPlugs' | translate }} : <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span>! {{ 'helpDeliverable.warningCountPlug1' | translate }}.</span> </p> <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span>! {{ 'helpDeliverable.warningCountPlug2' | translate }}.</span> </p> <img class=\"help-Doc__img help-Doc__W90\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"{{ 'shared.calculationNumberPlugs' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.visualiseCalculationPlugsAttributes' | translate }} : <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"{{ 'helpDeliverable.visualiseCalculationPlugsAttributes' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionDepot/livrableControle/voirLControle.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;