import template from './supervisionView.html';

class SupervisionViewPage {
    constructor($authorizationService, $filter, $modalService, $scope, $state, $stateParams, supervisionProvider) {
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._supervisionProvider = supervisionProvider;
        this._translate = $filter('translate');
        this.isAllowed = $authorizationService.isAllowed;

        this.indicatorAlert = {
            name: '',
            description: '',
            thresholds: [],
            selectedUsers: [],
            alertHistories: [],
        };
        this.indicatorAlertId = $stateParams.indicatorAlertId;
    }

    $onInit() {
        return this.loadAlert().then((indicatorAlert) => {
            this.indicatorAlert = indicatorAlert;

            this.indicatorAlertList = [this.indicatorAlert].map((indic) => {
                const companies = indic.selectedUsers.reduce((acc, selectedUser) => {
                    if (acc.includes(selectedUser.companyName)) {
                        return acc;
                    }

                    return [...acc, selectedUser.companyName];
                }, []);

                return {
                    ...indic,
                    companiesList: companies,
                };
            });

            this.companies = this.indicatorAlertList[0].selectedUsers
                .map(({ companyName }) => companyName)
                .filter((item, index, companies) => companies.indexOf(item) === index);

            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'supervisionView',
                        title: `${this._translate('supervision.indicatorAlert')}  ${this.indicatorAlert.name}`,
                        href: this._$state.href('app.supervisionView'),
                    },
                ],
                defaultPrevious: {
                    key: 'supervisionList',
                    title: this._translate('supervision.supervisionList'),
                    href: this._$state.href('app.supervisionList'),
                },
                allowedPreviousKeys: ['supervisionList'],
            });
        });
    }

    loadAlert() {
        return this._supervisionProvider.getAlert(this.indicatorAlertId);
    }

    async openCompanyModal(companies) {
        await this._$modalService.triggerListModal(
            companies.map((company) => ({ name: company })),
            this._translate('shared.company'),
        );
    }
}

angular.module('dotic').component('supervisionViewPage', {
    templateUrl: template,
    controller: SupervisionViewPage,
});
