import template from './search-select-multiple.html';

class CcFormSearchSelectMultiple {
    /** @ngInject */
    constructor($timeout, $document, $scope) {
        this._$timeout = $timeout;
        this._$document = $document;

        $scope.$watch('$ctrl.model', () => {
            this.sortModel();
        });
    }

    $onInit() {
        this.filteredValues = [];
        this.localModel = '';
        this.sortedModel = [];

        this.prepareValues();

        this._$document.on('click', this.onBlur);
    }

    $onDestroy() {
        this._$document.off('click', (element) => this.onBlur(element));
    }

    prepareValues() {
        if (this.values && angular.isObject(this.values)) {
            const values = { ...this.values };

            // Remove duplicates
            this.values = Object.keys(values).reduce((acc, key) => {
                if (!acc[key]) {
                    acc[key] = values[key];
                }

                return acc;
            }, {});
        }
    }

    addItem(item) {
        const newItems = new Set(this.getModel());
        if (newItems.has(item)) {
            newItems.delete(item);
        } else {
            newItems.add(item);
        }

        this.model = Array.from(newItems);
        this.sortModel();
    }

    close() {
        this._$timeout(() => {
            this.filteredValues = [];
        }, 16);
    }

    getModel() {
        return this.model || [];
    }

    isEmpty(object) {
        return Object.keys(object).length === 0;
    }

    isNotEmpty(object) {
        return this.isEmpty(object) === false;
    }

    onBlur(element) {
        const isSelect =
            angular.isUndefined(element) ||
            angular.element(element.target).closest('.search-select__chevron, .search-select__wrapper--click-container')
                .length > 0;

        if (isSelect === false) {
            this.close();
        }
    }

    onChange() {
        this.filteredValues = Object.keys(this.values)
            .filter((key) => this.values[key].toLowerCase().indexOf(this.localModel.toLowerCase()) > -1)
            .filter((key) => this.getModel().indexOf(key) === -1)
            .sort((a, b) => this.values[a] > this.values[b])
            .reduce((acc, key) => ({ ...acc, [key]: this.values[key] }), {});
    }

    removeItem(item) {
        const newItems = new Set(this.getModel());
        newItems.delete(item);
        this.model = Array.from(newItems);
        this.sortModel();
    }

    sortModel() {
        // Remove duplicates and sort
        this.sortedModel = this.getModel()
            .reduce((acc, item) => {
                if (!acc.find((a) => a === item)) {
                    acc.push(item);
                }

                return acc;
            }, [])
            .sort((a, b) => this.values[a] > this.values[b]);
    }
}

angular.module('dotic').component('ccFormSearchSelectMultiple', {
    templateUrl: template,
    controller: CcFormSearchSelectMultiple,
    transclude: true,
    bindings: {
        model: '=',
        placeholder: '@',
        values: '<',
    },
});
