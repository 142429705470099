import { unique as radashUnique } from 'radash';

import { GeneralIconColorEnum } from '../../../../services/color.service';
import { GraceKeyEnum } from '../../../../models/grace.model';
import { IndicatorService } from '../../../../services/indicator.service';
import template from './cable-length-by-organization-and-design.component.html';

const KeyEnum = {
    CABLE_CAPACITY: 'cableCapacity',
    CABLE_LENGTH: 'cableLength',
    DESIGN: 'design',
    ORGANIZATION: 'organization',
};

class CableLengthByOrganizationAndDesignComponent {
    columns = [];
    data = [];
    formattedData = [];
    iconOptions = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.formattedData = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.CABLE_LENGTH]);
        this.iconOptions = this.getIconOptions(this.formattedData);
        this.columns = this.getColumns();
        this.tableTitle = this._translate('reportCompare.detailLengthCable');
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ORGANIZATION,
                translation: this._translate('shared.organization', { COUNT: 1 }),
            },
            {
                key: KeyEnum.DESIGN,
                translation: GraceKeyEnum.DESIGN,
            },
            {
                key: KeyEnum.CABLE_CAPACITY,
                translation: this._translate('shared.cableCapacity'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const designCount = radashUnique(data.map((currentData) => currentData[KeyEnum.DESIGN])).length;
        const organizationCount = radashUnique(data.map((currentData) => currentData[KeyEnum.ORGANIZATION])).length;

        return [
            {
                color: GeneralIconColorEnum.ORGANIZATION,
                icon: 'building-alt',
                label: `${organizationCount} ${this._translate('shared.organization', { COUNT: organizationCount })}`,
            },
            {
                color: GeneralIconColorEnum.DESIGN,
                icon: 'designbump',
                label: `${designCount} ${this._translate('shared.design', { COUNT: designCount })}`,
            },
        ];
    }
}

angular.module('dotic').component('cableLengthByOrganizationAndDesignIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByOrganizationAndDesignComponent,
    templateUrl: template,
});
