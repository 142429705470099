import template from './checkbox-on-off.html';

class CcCheckboxController {}

angular.module('dotic').component('ccCheckboxOnOff', {
    templateUrl: template,
    controller: CcCheckboxController,
    bindings: {
        disabled: '<',
        disabledTooltip: '@',
        label: '@',
        model: '=',
    },
});
