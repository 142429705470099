import template from './dataModelValue.html';

class DataModelEditValueModal {
    constructor() {
        this.addValue = true;
    }

    $onInit() {
        this._id = this.resolve.value._id;
        this.name = this.resolve.value.name;
        this.code = this.resolve.value.code;
        this.label = this.resolve.value.label;
        this.description = this.resolve.value.description;

        if (this._id) {
            this.addValue = false;
        }
    }

    updateValue() {
        if (this.valueForm.$invalid) {
            return false;
        }

        return this.close({
            $value: {
                _id: this._id,
                name: this.name,
                code: this.code,
                label: this.label,
                description: this.description,
            },
        });
    }

    closePopup() {
        return this.dismiss();
    }
}

angular.module('dotic').component('ccDataModelValue', {
    templateUrl: template,
    controller: DataModelEditValueModal,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});
