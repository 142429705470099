import template from './fillGridLinked.html';

class FillGridLinkedController {
    constructor($scope, $state, $uibModal) {
        this._$state = $state;
        this._$uibModal = $uibModal;

        this.fillGridData = [];
        this.filteredFillGridList = [];

        this.filter = {
            searchFillGrid: $state.params.searchFillGrid || '',
        };

        // *
        // Set filters from urls
        $scope.$on('$locationChangeSuccess', () => {
            this.filter.searchFillGrid = $state.params.searchFillGrid || '';
            $scope.$emit('updateNavigationUrl');
        });

        // *
        // Watch view changes
        $scope.$watchGroup(['$ctrl.filter.searchFillGrid', '$ctrl.fillGridData'], () => {
            this.setScopeFillGridWithFilters();
        });
    }

    static filterSearchFillGrid(searchFillGrid) {
        return (attribute) =>
            searchFillGrid === '' ||
            attribute.attributName.toLowerCase().indexOf(searchFillGrid.toLowerCase()) > -1 ||
            attribute.tableName.toLowerCase().indexOf(searchFillGrid.toLowerCase()) > -1;
    }

    cancelFilter() {
        this.filter.searchFillGrid = '';
    }

    setScopeFillGridWithFilters() {
        const searchFillGrid = this.filter.searchFillGrid.toLocaleLowerCase();

        this.filteredFillGridList = this.fillGridData.filter(
            FillGridLinkedController.filterSearchFillGrid(searchFillGrid.toLowerCase()),
        );
    }

    showControlPoints(attribute) {
        this._$uibModal.open({
            size: 'xl',
            component: 'controlPointsListModal',
            resolve: {
                attribute: () => attribute,
            },
        });
    }
}

angular.module('dotic').component('fillGridLinked', {
    templateUrl: template,
    controller: FillGridLinkedController,
    bindings: {
        fillGridData: '<',
    },
});
