angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.gridFillList', {
        url: '/gridFills/listTemplates?company&dataModel&search&p',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            dataModel: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isTemplate: {
                value: true,
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridList',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.gridFillListCompanies', {
        url: '/gridFills/list?company&dataModel&search&p',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            dataModel: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isTemplate: {
                value: false,
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridList',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    // List
    $stateProvider.state('app.gridFillAdminList', {
        url: '/gridFills/admin/list?search&p',
        params: {
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridAdminListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    // Create
    $stateProvider.state('app.gridFillCreate', {
        url: '/gridFills/create',
        component: 'gridFillFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    //Edit
    $stateProvider.state('app.gridFillEdit', {
        url: '/gridFills/:gridFillId/edit/superAdmin?phase&phaseActivated&relation&severity&table&tableType&search&p',
        params: {
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phaseActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
            relation: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'gridFillFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.gridFillAdminEdit', {
        url: '/gridFills/:gridFillId/admin/edit?phase&phaseActivated&relation&severity&table&tableType&search&p',
        params: {
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phaseActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
            relation: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridAdminFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'superAdmin'],
        },
    });
    // View
    $stateProvider.state('app.fillGridView', {
        url: '/fillGrids/:gridFillId/view?phase&phaseActivated&relation&severity&table&tableType&required&search&p',
        params: {
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phaseActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
            relation: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            required: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridViewPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'superAdmin'],
        },
    });

    // Deposit grid fill details
    $stateProvider.state('app.gridFillFromDeposit', {
        url: '/gridFill/deposit/:depositId?attribute&severity&relation&tableType&phase&status&search&table&p',
        params: {
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            attribute: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            relation: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            status: {
                value: null,
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'fillGridFromDepositPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    // Compare
    $stateProvider.state('app.gridFillAdminCompare', {
        url: '/gridFills/admin/{idFillGridCompared:[A-Za-z0-9]+}/{idGridSelected:[A-Za-z0-9]+}/compare?company&phase&phaseActivated&relation&severity&table&tableType&required&search&selectListData&phaseDifference&attributeDifferentList',
        component: 'fillGridComparePage',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phaseActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
            relation: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            table: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableType: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            selectListData: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phaseDifference: {
                value: '',
                squash: true,
                dynamic: true,
            },
            attributeDifferentList: {
                value: '',
                squash: true,
                dynamic: true,
            },
            required: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
