import template from './ibox-list-item.html';

angular.module('dotic').component('ccIboxListItem', {
    templateUrl: template,
    transclude: true,
    bindings: {
        title: '@',
        size: '@',
    },
});
