import template from './statisticDetails.html';

class StatisticDetailsController {
    loading = true;

    constructor($authorizationService, $filter, $modalService) {
        this._$modalService = $modalService;
        this._translate = $filter('translate');

        this.isAllowed = $authorizationService.isAllowed;
    }

    async openZipModal(zipNames) {
        await this._$modalService.triggerListModal(
            zipNames.map((name) => ({ name: name })),
            this._translate('shared.file'),
        );
    }

    async openProjectIdsModal(projectIds) {
        await this._$modalService.triggerListModal(
            projectIds.map((name) => ({ name: name })),
            this._translate('shared.businessCode'),
        );
    }

    async openUsersModal(users) {
        await this._$modalService.triggerListModal(
            users.map((user) => ({ name: user.fullname })),
            this._translate('role.user'),
        );
    }
}

angular.module('dotic').component('statisticDetails', {
    templateUrl: template,
    controller: StatisticDetailsController,
    bindings: {
        statistic: '<',
        jobName: '@',
    },
});
