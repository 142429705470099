import template from './projectListModal.html';
class ProjectListModalController {
    constructor($timeout) {
        this._$timeout = $timeout;

        this.projects = [];
        this.loading = true;
    }

    $onInit() {
        const { projects } = this.resolve;
        const { projectsDeposits } = this.resolve;

        if (angular.isArray(projects)) {
            this.setProjects(projects);
        } else if (angular.isArray(projectsDeposits)) {
            this.setProjectsDeposit(projectsDeposits);
        } else if (angular.isFunction(projects)) {
            projects().then((results) => this.setProjects(results));
        } else if (angular.isFunction(projectsDeposits)) {
            projectsDeposits().then((results) => this.setProjectsDeposit(results));
        } else {
            this.loading = false;
        }
    }

    setProjects(projects) {
        this._$timeout(() => {
            this.projects = projects;
            this.loading = false;
        });
    }

    setProjectsDeposit(projectsDeposits) {
        this._$timeout(() => {
            this.projects = projectsDeposits;
            this.loading = false;
        });
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('projectListModal', {
    templateUrl: template,
    controller: ProjectListModalController,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
