import template from './statisticsFilterModal.html';

class StatisticsFilterModalComponent {
    controlConfigurations = [];
    deliveryZones = [];

    // Filters options
    controlConfigurationList = [];
    deliveryZoneList = [];
    fillGridList = null;
    phaseList = [];
    projectIdList = [];
    projectList = [];
    segmentsList = [];
    subCompaniesList = [];
    userList = [];
    zipNameList = [];

    // register filters
    generateFilter = {
        from: null,
        to: null,
        projectIds: [],
        projects: [],
        deliveryZones: [],
        phases: [],
        networkSegments: [],
        subCompanies: [],
        zipNames: [],
        controlConfigurations: [],
        fillGrid: null,
        users: [],
    };

    /** @ngInject */
    constructor($scope, $toasterService, $filter, $timeout, depositProvider) {
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$timeout = $timeout;

        this._depositProvider = depositProvider;

        $scope.$watchGroup(
            ['$ctrl.generateFilter.projects', '$ctrl.generateFilter.subCompanies', '$ctrl.generateFilter.zipNames'],
            () => this.prepareFilters(),
        );

        // Needed to trigger call to $onChanges in deposits-preview component
        $scope.$watchGroup(
            [
                '$ctrl.generateFilter.controlConfigurations',
                '$ctrl.generateFilter.deliveryZones',
                '$ctrl.generateFilter.fillGrid',
                '$ctrl.generateFilter.from',
                '$ctrl.generateFilter.projectIds',
                '$ctrl.generateFilter.projects',
                '$ctrl.generateFilter.phases',
                '$ctrl.generateFilter.networkSegments',
                '$ctrl.generateFilter.subCompanies',
                '$ctrl.generateFilter.to',
                '$ctrl.generateFilter.users',
                '$ctrl.generateFilter.zipNames',
            ],
            () => {
                this.generateFilter = { ...this.generateFilter };
            },
        );
    }

    $onInit() {
        this.controlConfigurations = this.resolve.controlConfigurations;
        this.deliveryZones = this.resolve.deliveryZones;
        this.projectList = this.resolve.projectList;
        this.projectIdList = this.resolve.projectIdList;
        this.deliveryZoneList = this.resolve.deliveryZoneList;
        this.phaseList = this.resolve.phaseList;
        this.segmentsList = this.resolve.segmentsList ?? [];
        this.controlConfigurationList = this.resolve.controlConfigurationList;
        this.userList = this.resolve.userList;
        this.zipNameList = this.resolve.zipNameList;

        const subCompaniesList = this.resolve.subCompaniesList;
        this.deliveryZones.forEach((deliveryZone) => {
            if (deliveryZone.assignments) {
                subCompaniesList.push(...deliveryZone.assignments);
            }
        });

        this.subCompaniesList = subCompaniesList
            .reduce((acc, subCompany) => {
                if (!acc.find((company) => company.id === subCompany.id)) {
                    return [...acc, subCompany];
                }

                return acc;
            }, [])
            .map((company) => ({
                key: company.id,
                value: company.name,
            }));

        this.fillGridList = this.resolve.fillGridList ?? null;

        if (!this.resolve.generateFilter) {
            return;
        }

        // Autofill form with previous values
        this._$timeout(() => {
            this.generateFilter = { ...this.resolve.generateFilter };
            if (this.generateFilter.from) {
                this._$scope.$broadcast('updateDateTime', 'startDate', this.generateFilter.from);
            }

            if (this.generateFilter.to) {
                this._$scope.$broadcast('updateDateTime', 'endDate', this.generateFilter.to);
            }

            // Avoid focus on date inputs where they're empty
            if (!this.generateFilter.from && !this.generateFilter.to) {
                this._$scope.$broadcast('clearFilter');
            }
        }, 0);
    }

    isFormValid() {
        const from = moment(this.generateFilter.from);
        const to = moment(this.generateFilter.to);
        const isValidDate = to.isValid() && from.isValid() && !from.isAfter(to);

        const isValidFillGrid = !this.fillGridList?.length || this.generateFilter.fillGrid?.length;
        const isValidSegments = !this.segmentsList.length || this.generateFilter.networkSegments.length;

        return !!(this.generateFilter.phases.length && isValidSegments && isValidDate && isValidFillGrid);
    }

    prepareDeliveryZonesList() {
        if (!this.generateFilter.projects) {
            this.deliveryZoneList = [];
            this.generateFilter.deliveryZones = [];

            return;
        }

        this.deliveryZoneList = this.deliveryZones
            .filter((item) => {
                return this.generateFilter.projects.some((project) => {
                    return project === item.project.id;
                });
            })
            .map((deliveryZone) => {
                const prefixedName = deliveryZone.project?.name
                    ? `${deliveryZone.project.name} ${deliveryZone.name}`
                    : deliveryZone.name;

                return {
                    key: deliveryZone.id,
                    value: prefixedName,
                };
            });

        if (!this.generateFilter.subCompanies?.length) {
            return;
        }

        const preparedDeliveryZones = this.deliveryZones.reduce((acc, deliveryZone) => {
            if (
                !deliveryZone.assignments.some((assignment) => this.generateFilter.subCompanies.includes(assignment.id))
            ) {
                return acc;
            }

            return [...acc, { key: deliveryZone.id, value: deliveryZone.name }];
        }, []);

        if (!this.deliveryZoneList.length) {
            this.deliveryZoneList = preparedDeliveryZones;

            return;
        }

        this.deliveryZoneList = this.deliveryZoneList.filter((deliveryZone) =>
            preparedDeliveryZones.map((preparedDeliveryZone) => preparedDeliveryZone.key).includes(deliveryZone.key),
        );
    }

    prepareFilters() {
        let configurations = [];

        this.prepareDeliveryZonesList();

        if (this.generateFilter.zipNames?.length) {
            this.generateFilter.projectIds = [];
            this.generateFilter.controlConfigurations = [];
            this.generateFilter.users = [];
        }

        this.controlConfigurationList = [];

        this.generateFilter.projects.forEach((project) => {
            this.controlConfigurations.forEach((controlConf) => {
                controlConf.projects.forEach((ctrlProject) => {
                    if (ctrlProject.id === project) {
                        configurations.push(controlConf);
                    }
                });
            });
        });

        configurations = configurations.reduce((acc, configuration) => {
            if (acc.find((accumulatedItem) => accumulatedItem.id === configuration.id)) {
                return acc;
            }

            return [...acc, configuration];
        }, []);

        this.controlConfigurationList = configurations.map((configuration) => ({
            key: configuration.id,
            value: configuration.name,
        }));

        if (!this.fillGridList?.length) {
            return;
        }

        this.fillGridList = this.resolve.fillGridList;
        if (!this.generateFilter.projects.length) {
            return;
        }

        const projectsConfigurations = this.controlConfigurations.filter((configuration) => {
            return configuration.projects.some((project) => {
                return this.generateFilter.projects.includes(project.id);
            });
        });

        this.fillGridList = this.fillGridList.filter((fillGrid) => {
            return projectsConfigurations.some((configuration) => configuration.fillGrid.id === fillGrid.key);
        });
    }

    submit() {
        if (!this.isFormValid()) {
            return this._$toasterService.error(this._translate('toaster.invalidForm'));
        }

        const params = {
            ...this.generateFilter,
            from: moment(this.generateFilter.from).utc().format(),
            networkSegments: this.segmentsList.map((segment) => {
                return {
                    segmentName: segment.value,
                    isEnabled: this.generateFilter.networkSegments.includes(segment.value),
                };
            }),
            subCompanies: this.generateFilter.zipNames.length ? [] : this.generateFilter.subCompanies,
            to: moment(this.generateFilter.to).utc().format(),
        };

        return this.close({
            $value: {
                filters: this.generateFilter,
                params: params,
            },
        });
    }

    cancel() {
        // Return current filters for memorize
        return this.close({
            $value: { filters: this.generateFilter },
        });
    }
}

angular.module('dotic').component('statisticsFilterModal', {
    templateUrl: template,
    controller: StatisticsFilterModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
