import { IndicatorCableBtiService } from '../../services/indicatorCableBti.service';
import template from './cableBtiIndicator.html';

class CableBtiIndicator {
    cableBti = [];
    indicator = IndicatorCableBtiService.cableBtiDefaultValues;
    loading = true;

    constructor($indicatorCableBtiService, $timeout) {
        this._$indicatorCableBtiService = $indicatorCableBtiService;
        this._$timeout = $timeout;
    }

    $onInit() {
        this.loading = true;

        this._$timeout(() => {
            this.indicator = this._$indicatorCableBtiService.initCableBti(this.cableBti);
            this.loading = false;
        });
    }
}

angular.module('dotic').component('cableBtiIndicator', {
    controller: CableBtiIndicator,
    templateUrl: template,
    bindings: {
        cableBti: '<',
        parentRef: '<',
    },
});
