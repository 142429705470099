angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.reportDetailRedirect', {
            url: '/reports/{reportId:[A-Za-z0-9]+}',
            redirectTo: 'app.reportDetail',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.reportDetail', {
            url: '/reports/{reportId:[A-Za-z0-9]+}/view?internalId&status&thematic&object&result&search&showIndic&showAnomaliesType',
            component: 'reportDetailPage',
            params: {
                status: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                internalId: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                object: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                result: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                thematic: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                showIndic: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                showAnomaliesType: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.reportPDF', {
            url: '/reports/{reportId:[A-Za-z0-9]+}/pdf',
            component: 'reportPdfPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.customerPDFReport', {
            url: '/reports/{reportId:[A-Za-z0-9]+}/pdf-customer',
            component: 'customerPDFReportPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.reportCompare', {
            url: '/reports/{deposit1:[A-Za-z0-9]+}/{deposit2:[A-Za-z0-9]+}/view?internalId&thematic&object&severity&result&activity&search',
            component: 'reportComparePage',
            params: {
                internalId: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                thematic: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                object: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                severity: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                result: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                activity: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.reportPDFCompare', {
            url: '/reports/{deposit1:[A-Za-z0-9]+}/{deposit2:[A-Za-z0-9]+}/pdf',
            component: 'reportComparePdfPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        });
});
