import angular from 'angular';

import { GridActionEnum } from '../../../models/grid.model';
import { HelpersService } from '../../../services/helpers.service';
import template from './controlGridView.html';

class ControlGridViewController {
    possibleActions = '';

    constructor(
        $auth,
        $authorizationService,
        $filter,
        $filterService,
        $gridControlService,
        $gridService,
        $location,
        $modalService,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $toasterService,
        $uibModal,
        gridControlProvider,
        userMetricsProvider,
    ) {
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._$gridControlService = $gridControlService;
        this._$gridService = $gridService;
        this._$location = $location;
        this._$modalService = $modalService;
        this._$phaseService = $phaseService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._gridControlProvider = gridControlProvider;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;
        this.controlGrid = {
            dataModel: [],
            name: '',
        };
        this.segmentsList = this._$segmentsService.getAll();
        this.phaseList = this._$phaseService.getAll();
        this.segments = this.segmentsList.map((s) => {
            return { segmentName: s, isEnabled: false };
        });
        this.controlGridId = $stateParams.gridControlId;
        this.controlGridList = [];

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'status',
                placeholder: 'filter.all.severity',
                saveValue: true,
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseList',
                selected: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'segmentsList',
                selected: 'segment',
                placeholder: 'filter.all.segments',
                translateKeyLabel: 'shared.networkSegment',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'isActivatedList',
                value: 'isActivated',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.state',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'internalIdsList',
                selected: 'internalId',
                placeholder: 'filter.all.internalId',
                hasSearch: true,
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'objectList',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematicList',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'lastModificationDate',
                value: 'lastModificationDate',
                placeholder: 'shared.lastModificationDate',
                translateKeyLabel: 'shared.lastModificationDate',
                type: 'date',
            },
        ];

        this.lastModificationDateStart = this._$stateParams.lastModificationDateStart;
        this.lastModificationDateEnd = this._$stateParams.lastModificationDateEnd;

        this.filterValues = {
            severityList: [
                {
                    key: 'minor',
                    value: this._humanizeSeverity('minor'),
                },
                {
                    key: 'major',
                    value: this._humanizeSeverity('major'),
                },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            internalIdsList: [],
            objectList: [],
            phaseList: [],
            segmentsList: this.segmentsList.map((segment) => ({
                key: segment,
                value: segment,
            })),
            isActivatedList: [
                {
                    key: 'true',
                    value: this._translate('shared.activated', { GENDER: 'male' }),
                },
                {
                    key: 'false',
                    value: this._translate('shared.disabled'),
                },
            ],
            thematicList: [],
        };

        this.dataModels = [];
        this.filteredControlGridList = [];
        this.isTemplate = false;
        this.showConditions = true;
        this.loading = true;

        this.typeList = [
            {
                key: 'basic',
                value: this._translate('shared.basic'),
            },
            {
                key: 'advanced',
                value: this._translate('shared.advanced', { GENDER: 'male' }),
            },
        ];
        this.initFilterIntoUrl($stateParams);

        $scope.$watchGroup(
            [
                '$ctrl.lastModificationDateStart',
                '$ctrl.lastModificationDateEnd',
                '$ctrl.filter.thematic',
                '$ctrl.filter.internalId',
                '$ctrl.filter.object',
                '$ctrl.filter.status',
                '$ctrl.filter.search',
                '$ctrl.filter.phase',
                '$ctrl.filter.segment',
                '$ctrl.filter.isActivated',
                '$ctrl.filter.lastModificationDate',
                '$ctrl.controlGridList',
            ],
            () => {
                this.setScopeGridControlViewWithFilters();
            },
        );

        $scope.$on('$locationChangeSuccess', () => this.initFilterIntoUrl($stateParams));

        userMetricsProvider.openControlGrid(this.controlGridId);
    }

    initFilterIntoUrl(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            search: params.search || '',
            internalId: this._$filterService.getFilterValue(params.internalId),
            status: this._$filterService.getFilterValue(params.status),
            thematic: this._$filterService.getFilterValue(params.thematic),
            object: this._$filterService.getFilterValue(params.object),
            isActivated: params.isActivated || '',
            phase: this._$filterService.getFilterValue(params.phase),
            segment: this._$filterService.getFilterValue(params.segment),
        };

        this.setDateParams();
    }

    setDateParams() {
        if (!this.lastModificationDateStart || !this.lastModificationDateEnd) {
            this.filter.lastModificationDate = '';

            return;
        }

        this.filter.lastModificationDate = {
            startDate: moment(this.lastModificationDateStart),
            endDate: moment(this.lastModificationDateEnd),
        };
    }

    $onInit() {
        this.loading = true;

        this.getControlGrid(this.controlGridId).then(() => {
            if (this.controlGrid !== null) {
                this.tableDetail = this._$tableService.detail();

                this.phasesEnabledList = HelpersService.getPhasesListWithCountEnabled(this.controlGridList);
                this.networkSegmentEnabledList = HelpersService.getNetworkSegmentListWithCountEnabled(
                    this.controlGridList,
                );

                this.allPhasesList = this.phasesEnabledList.map((item) => item.name);
                this.possibleActions = this._$gridService.getPossibleActions(this.controlGrid, [
                    GridActionEnum.DUPLICATION,
                    GridActionEnum.EDITION,
                    GridActionEnum.ETL_SYNCHRONIZE,
                ]);

                this.loading = false;

                this._$scope.$emit('keepPreviousNavigation', {
                    newPage: [
                        {
                            key: 'controlGrid',
                            title: `${this._translate('shared.controlGrid', { COUNT: 1 })} ${
                                this.isTemplate ? 'de reference' : ''
                            } ${this.controlGrid.fullname}`,
                            href: this._$location.path(),
                        },
                    ],
                    defaultPrevious: {
                        title: this._translate('shared.controlGrid'),
                        href: this._$state.href('app.gridControlAdminList'),
                        key: 'controlGrids',
                    },
                    allowedPreviousKeys: [
                        'comparaison',
                        'controlConfigurationDetail',
                        'controlConfigurationGridsLink',
                        'controlConfigurations',
                        'controlGrids',
                        'gridControlFromDeposit',
                        'projectDetail',
                        'report',
                    ],
                });
            }
        });
    }

    static filterPhases(phases) {
        return (control) =>
            !phases.length ||
            phases.some((phase) =>
                control.phases.find(
                    (controlPointPhase) => controlPointPhase.phaseName === phase && controlPointPhase.isEnabled,
                ),
            );
    }

    static filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.model || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    setScopeGridControlViewWithFilters() {
        this._$state.go('.', {
            search: this.filter.search,
            status: this.filter.status.join(','),
            thematic: this.filter.thematic.join(','),
            object: this.filter.object.join(','),
            phase: this.filter.phase.join(','),
            segment: this.filter.segment.join(','),
            isActivated: this.filter.isActivated,
            internalId: this.filter.internalId.join(','),
            lastModificationDateStart: this.filter.lastModificationDate?.startDate,
            lastModificationDateEnd: this.filter.lastModificationDate?.endDate,
        });

        this.filteredControlGridList = this.controlGridList
            .filter(ControlGridViewController.filterPhases(this.filter.phase))
            .filter(this._$gridControlService.filterByPhasesAndSegments(this.filter.isActivated))
            .filter(this._$filterService.filterSegments(this.filter.segment))
            .filter(ControlGridViewController.filterSearch(this.filter.search.toLowerCase()))
            .filter(this._$filterService.genericMultiFilter(this.filter.status, 'severity'))
            .filter(this._$filterService.filterControlPoint(this.filter.internalId))
            .filter(this._$filterService.genericMultiFilter(this.filter.object, 'object'))
            .filter(this._$filterService.genericMultiFilter(this.filter.thematic, 'thematicName'))
            .filter(this._$filterService.filterRangeDate(this.filter.lastModificationDate, 'lastModificationDate'));
    }

    getStateParams() {
        return {
            gridControlId: this.controlGrid.id,
            search: this.filter.search,
            status: this.filter.status.join(','),
            thematic: this.filter.thematic.join(','),
            object: this.filter.object.join(','),
            phase: this.filter.phase.join(','),
            segment: this.filter.segment.join(','),
            isActivated: this.filter.isActivated,
            internalId: this.filter.internalId.join(','),
        };
    }

    getControlGrid() {
        return this._gridControlProvider
            .getDetails(this.controlGridId)
            .then((controlGrid) => {
                this.controlGrid = controlGrid;

                this.isTemplate = this.controlGrid.isTemplate;

                this.controlGrid.countCtrl = this.controlGrid.data.filter(
                    (control) =>
                        control.phases.some((phase) => phase.isEnabled) &&
                        control.networkSegments.some((networkSegment) => networkSegment.isEnabled),
                ).length;

                this.dataModels = controlGrid.dataModel;
                this.controlGrid.data.map((item, index) => {
                    item.id = index;
                });

                this.controlGrid.networkSegments = this.segmentsList;

                // Add step phases in filters
                const controlPointActivated = [];
                const uniquePhases = [];
                this.controlGrid.data.forEach((controlPoint) => {
                    controlPoint.phases.forEach((p) => {
                        if (
                            controlPoint?.phases?.some((phase) => phase.isEnabled) &&
                            uniquePhases.indexOf(p.phaseName) === -1
                        ) {
                            controlPointActivated.push(controlPoint);
                            if (p.isEnabled) {
                                uniquePhases.push(p.phaseName);
                            }
                        }
                    });

                    return controlPointActivated.length;
                });
                this.controlGrid.phases = uniquePhases;

                this.filterValues.phaseList = this.controlGrid.phases.map((phase) => ({
                    key: phase,
                    value: phase,
                }));
                this.prepareControlGrid(this.controlGrid);
            })
            .catch(() => {
                this._$toasterService.warning("La grille de contrôle demandée n'existe pas");
                this._$state.go('app.gridControlAdminList');
            });
    }

    prepareControlGrid(controlGrid) {
        this.controlGridList = controlGrid.data;

        const thematicList = new Map();
        const objectList = new Map();
        const internalIdsList = new Map();

        this.controlGridList.forEach(({ thematicName, object, controlPoint }) => {
            thematicList.set(thematicName, this._humanizeThematic(thematicName));
            internalIdsList.set(controlPoint, controlPoint);
            if (angular.isString(object)) {
                objectList.set(object, object);
            }
        });

        this.filterValues.thematicList = Array.from(thematicList, ControlGridViewController.mapToObject);
        this.filterValues.objectList = Array.from(objectList, ControlGridViewController.mapToObject);
        this.filterValues.internalIdsList = Array.from(internalIdsList, ControlGridViewController.mapToObject);
    }

    static mapToObject([key, value]) {
        return {
            key,
            value,
        };
    }

    async onExportGridControl(gridControlId) {
        return await this._$gridService.onExportGrid(gridControlId, false);
    }

    async duplicateControlGrid(companyId, controlGridId) {
        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'shared.confirmDuplicateGrid',
            'shared.continue',
        );
        if (!isAccepted) {
            return;
        }

        this.duplicating = true;

        try {
            const controlGridCreated = await this._$gridControlService.duplicate(companyId, controlGridId);
            this._$state.go('app.controlGridView', {
                gridControlId: controlGridCreated.id,
            });
        } catch (error) {
            if (!error.data || !angular.isArray(error.data)) {
                this._$toasterService.error(error);

                return;
            }

            this._$gridService.displayDuplicatedError(error.data, 10);
        } finally {
            this.duplicating = false;
        }
    }

    listDataModels(dataModels) {
        if (dataModels.length === 0) {
            return '';
        }

        return dataModels
            .map((dataModel) => this.dataModels.find((d) => d.id === dataModel.id))
            .filter((dataModel) => dataModel)
            .map((dataModel) => dataModel.name)
            .join(', ');
    }

    toggleCondition(condition) {
        if (this.selectedCondition === condition) {
            this.selectedCondition = null;
        } else {
            this.selectedCondition = condition;
        }
    }

    removeTooltip() {
        if (this.controlGrid.isTemplate && !this.isAllowed(['superAdmin'])) {
            return this._translate('shared.templateGrid');
        }

        if (this.controlGrid.countDeposits > 0) {
            return this._translate('shared.attachedGrid', { COUNT: 1 });
        }

        return this._translate('shared.deleteGrid');
    }

    async removeControlGrid() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlGrid', {
                COUNT: 1,
                NAME: this.controlGrid.fullname,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._gridControlProvider.delete(this.controlGrid.id);
            this._$toasterService.info(
                this._translate('removeModal.successGrid', {
                    COUNT: 1,
                }),
            );

            if (this.isAllowed(['superAdmin'])) {
                return await this._$state.go('app.gridControlList');
            }

            await this._$state.go('app.gridControlAdminList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    compareGrid(controlGrid) {
        this._$state.go('app.comparisonGridPage', {
            controlGrid: controlGrid.id,
        });
    }

    // Modal for corrective action
    viewCorrectiveAction(control) {
        this._$uibModal.open({
            size: 'xl',
            component: 'correctiveActionModal',
            resolve: {
                control: () => control,
            },
        });
    }

    cancelFilters() {
        this.initFilterIntoUrl({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('controlGridViewPage', {
    controller: ControlGridViewController,
    templateUrl: template,
});
