import { CompaniesApi } from '../../sdk/connect-control-api-v1/src';

class CompanySettings {
    constructor($apiClientService, $toasterService) {
        this._$toasterService = $toasterService;
        this._companiesApi = new CompaniesApi($apiClientService.client);
    }

    async getCompanyHasLinkBetweenGridsEnabled(companyId) {
        return (await this.getCompanySetting(companyId))?.hasLinkBetweenGridsEnabled ?? false;
    }

    async getCompanySetting(companyId) {
        try {
            return (await this._companiesApi.hasLinkBetweenGridsEnabledWithHttpInfo(companyId)).response.body;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular
    .module('dotic')
    .factory(
        '$companySettings',
        ($apiClientService, $toasterService) => new CompanySettings($apiClientService, $toasterService),
    );
