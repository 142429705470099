import template from './checkbox.html';
class CcCheckboxController {
    shouldShowError() {
        return this.form[this.name].$touched && this.form[this.name].$invalid;
    }
}

angular.module('dotic').component('ccCheckbox', {
    templateUrl: template,
    controller: CcCheckboxController,
    bindings: {
        form: '<',
        label: '@',
        model: '=',
        name: '@',
        required: '<',
        readonly: '<',
    },
});
