function transform(text) {
    switch (text) {
        case 'coherence':
            return 'Cohérence';
        case 'coherence metier':
            return 'Cohérence métier';
        case 'donnees et modele':
            return 'Données et modèle';
        case 'geometrie':
            return 'Géométrie';
        case 'geometrie et topologie':
            return 'Géométrie et topologie';
        case 'metier':
            return 'Métier';
        case 'reglementaire':
            return 'Réglementaire';
        default:
            return text;
    }
}

angular.module('dotic').filter('humanizeThematic', () => {
    return (value) => {
        const res = transform(value.toLowerCase());
        const firstLetter = res[0].toUpperCase();

        return firstLetter + res.substring(1);
    };
});
