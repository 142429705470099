// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../assets/images/help/depot/depot-name2.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-header modal-header--empty\"> <i class=\"icofont icofont-close\" ng-click=\"$ctrl.cancel()\"></i> </div> <div class=\"modal-body\"> <h4 class=\"help-Doc__subTitre1 text-center help_Doc__paddingBottom-paddingTop\"> {{ 'helpDeliverable.zipNaming' | translate }} : </h4> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpDeliverable.warningNamingZip' | translate }} .</span> </p> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpDeliverable.warningNamingProjectZL' | translate }} .</span> </p> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpDeliverable.warningSegmentSelection' | translate }} .</span> </p> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpDeliverable.warningFaculty' | translate }} .</span> </p> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"nomanclature zip\"/> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/components/modals/helpZipModal/helpZipModal.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;