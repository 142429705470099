import template from './imageSelector.html';

class ImageSelectorController {
    onChange;

    onImageSelected(image) {
        this.onChange({ $image: image });
    }
}

angular.module('dotic').component('imageSelector', {
    bindings: {
        defaultBase64OrUrlImage: '<',
        deleteTooltip: '@',
        isRounded: '<',
        legendMessage: '@',
        onChange: '&',
        selectedBase64OrUrlImage: '<',
    },
    controller: ImageSelectorController,
    templateUrl: template,
});
