export const DataModelMongoRegexEnum = {
    V200: 'V200',
    V201: 'V201',
    V201P: 'V201patch',
    V201PI: 'V201patchImport',
    V201O: 'V201_old',
    V202: 'V202',
    V202RC1IMPORT: 'V202RC1Import',
    V300: 'V300',
    V301: 'V301',
};

export const DataModelNameEnum = {
    GRACETHDV200: 'Grace THD 2.0.0',
    GRACETHDV201: 'Grace THD 2.0.1',
    GRACETHDV201patch: 'Grace THD 2.0.1 patch',
    GRACETHDV202RC1: 'Grace THD 2.0.2 RC1',
    GRACETHDV202RC1IMPORT: 'Grace THD 2.0.2 RC1 import',
    GRACETHDV300: 'grace thd 3.0.0',
    GRACETHDV301: 'Grace THD 3.0.1',
};

export const DataModelSlugNameEnum = {
    GRACETHDV200: 'grace-thd-2-0-0',
    GRACETHDV201: 'grace-thd-2-0-1',
    GRACETHDV201patch: 'grace-thd-2-0-1-patch',
    GRACETHDV202RC1: 'grace-thd-2-0-2-rc1',
    GRACETHDV300: 'grace-thd-3-0-0',
    GRACETHDV301: 'grace-thd-3-0-1',
};
