import template from './filterDateRangePicker.html';

class CcFilterDateRangePickerController {
    constructor() {
        this.setInitDate();
        this.datePicker = {
            picker: null,
            maxDate: moment().add(10, 'years').endOf('day'),
            options: {
                timePicker: true,
                timePicker24Hour: true,
                showDropdowns: true,
                pickerClasses: 'custom-display',
                buttonClasses: 'btn',
                applyButtonClasses: 'btn-primary',
                cancelButtonClasses: 'btn-danger',
                locale: {
                    applyLabel: 'Appliquer',
                    cancelLabel: 'Effacer',
                    customRangeLabel: 'Choisir les dates',
                    separator: ' ⮕ ',
                    format: 'DD-MM-YYYY HH:mm',
                    applyClass: 'btn-green',
                    fromLabel: 'De',
                    toLabel: 'A',
                    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                    firstDay: 1,
                    monthNames: [
                        'Janvier',
                        'Fevrier',
                        'Mars',
                        'Avril',
                        'Mai',
                        'Juin',
                        'Juillet',
                        'Août',
                        'Septembre',
                        'Octobre',
                        'Novembre',
                        'Décembre',
                    ],
                },
                eventHandlers: {
                    'apply.daterangepicker': () => {
                        this.updateDate();
                    },
                    'cancel.daterangepicker': () => {
                        this.resetDate();
                    },
                },
            },
        };
    }

    updateDate() {
        if (!this.date) {
            this.setInitDate();
        } else {
            this.value = this.date;
        }
    }

    resetDate() {
        this.date = '';
        this.value = '';
    }

    updateDateValue() {
        this.date = this.value;
    }

    setInitDate() {
        this.date = { startDate: null, endDate: null };
    }

    $onChanges() {
        this.updateDateValue();
    }
}

/**
 * @ngdoc component
 * @name ccFilterDateRangePicker
 *
 * @description
 * A Date and time range picker
 *
 *
 * @param {string} value       The model
 * @param {string} placeholder The placeholder text
 */
angular.module('dotic').component('ccFilterDateRangePicker', {
    templateUrl: template,
    controller: CcFilterDateRangePickerController,
    bindings: {
        value: '=',
        placeholder: '@',
    },
});
