import template from './housing-types.html';

class HousingTypes {
    data;
    isGraceV3;
    isRequestOptionActivated;

    constructor() {
        this.collectifRequest = '';
        this.individualRequest = '';
        this.isDisplayingRequest = false;
    }

    $onInit() {
        if (this.isGraceV3) {
            this.collectifRequest =
                "SELECT COUNT(*) as lgt FROM t_adresse WHERE (Case when ad_nblres ='' THEN 0 else ad_nblres::integer end + Case when ad_nblpro ='' THEN 0 else ad_nblpro::integer end + Case when ad_nblent ='' THEN 0 else ad_nblent::integer end + Case when ad_nblpub ='' THEN 0 else ad_nblpub::integer end + Case when ad_nblobj ='' THEN 0 else ad_nblobj::integer end + Case when ad_nblope ='' THEN 0 else ad_nblope::integer end) > 3";
            this.individualRequest =
                "SELECT COUNT(*) as lgt FROM t_adresse WHERE (Case when ad_nblres ='' THEN 0 else ad_nblres::integer end + Case when ad_nblpro ='' THEN 0 else ad_nblpro::integer end + Case when ad_nblent ='' THEN 0 else ad_nblent::integer end + Case when ad_nblpub ='' THEN 0 else ad_nblpub::integer end + Case when ad_nblobj ='' THEN 0 else ad_nblobj::integer end + Case when ad_nblope ='' THEN 0 else ad_nblope::integer end) < 3";

            return;
        }

        this.collectifRequest = "SELECT COUNT(*) as lgt FROM t_adresse WHERE ad_itypeim = 'I'";
        this.individualRequest = "SELECT COUNT(*) as lgt FROM t_adresse WHERE ad_itypeim = 'P'";
    }

    toggleDisplayRequestButton() {
        this.isDisplayingRequest = !this.isDisplayingRequest;
    }
}

angular.module('dotic').component('housingTypes', {
    bindings: {
        data: '<',
        isGraceV3: '<',
        isRequestOptionActivated: '<',
    },
    controller: HousingTypes,
    templateUrl: template,
});
