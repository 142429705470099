angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.change-password', {
        url: '/security/change-password',
        component: 'changeUserPasswordPage',
        data: {
            authenticationRequired: false,
            roleRequired: [],
        },
    });
});
