angular.module('dotic').factory('$environmentSettingsService', () => new EnvironmentSettingsService());

export default class EnvironmentSettingsService {
    static isOrange() {
        return APP_SETTINGS.FRONTEND_ID?.includes('orange.') ?? false;
    }

    static isSfr() {
        return APP_SETTINGS.FRONTEND_ID?.includes('sfr.') ?? false;
    }
}
