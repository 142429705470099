angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.synoptic', {
        url: '/synoptic/{zipId:[A-Za-z0-9]+}/download',
        component: 'synopticDownloadZipComponent',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'user', 'extendedUser'],
        },
    });
});
