/**
 * @ngdoc directive
 * @name ccTable
 *
 * @description
 * A directive to paginate a table. It should be used as an attribute to a table. If there are
 * more than one row, the first one is used as a header. The last row is used as the item row and
 * is repeated as many times as necessary. The current item in a row is available in the "item"
 * variable.
 *
 * The data are provided using the "rows" attribute.
 *
 * To allow sorting the table you need to use the "cc-table-sort" directive on a "th" in the
 * header and set the field to use for sorting. The field name can be qualified
 * (e.g., "field.subField").
 *
 * Example:
 *
 * <table cc-table rows="data">
 *   <tr>
 *     <th cc-table-sort="attr1" >Header 1</th>
 *     <th cc-table-sort="attr2">Header 2</th>
 *   </tr>
 *   <tr>
 *     <td>{{ item.attr1 }}</td>
 *     <td>{{ item.attr2 }}</td>
 *   </tr>
 * </table>
 *
 * @param {expression} rows an array of items to display in the table
 * @param {string} defaultSort a field to use by default for sorting. It can start by "+" to
 * specify an ascending order or by a "-" to specify a descendind order (ascending by default).
 */
angular.module('dotic').directive('ccTable', ccTableDirective);

function ccTableDirective() {
    return {
        restrict: 'A',
        controller: 'ccTableController as $ctrl',
        scope: {
            rows: '<',
            paginationWidth: '<',
            pageSize: '<',
            defaultSort: '@',
            infinite: '<',
        },
        compile: (element, attributes) => {
            const newElement = angular.element(containerTemplate);
            element.replaceWith(newElement);
            newElement.append(element);
            if (element.attr('ng-if')) {
                newElement.attr('ng-if', element.attr('ng-if'));
                element.removeAttr('ng-if');
            }

            element.removeAttr('cc-table');
            element.addClass('table__content-table');

            const headerRow = findHeaderRow(element);
            headerRow.addClass('table__content-row table__content-row--header');
            headerRow.find('th').addClass('table__content-header-cell');

            const itemRows = findItemRows(element);
            const currentPage = attributes.currentPage || 'page';

            itemRows.first().attr('ng-repeat-start', `item in ${currentPage}`);
            itemRows.last().attr('ng-repeat-end', '');

            itemRows.each((index, row) => {
                const element = angular.element(row);
                element.addClass('table__content-row');
                element.find('td').addClass('table__content-cell');
            });

            return (scope, element, attributes, controller) => {
                controller.currentPage = currentPage;
                setSort(findSortColumns(element), scope, controller, attributes['defaultSort']);
            };
        },
    };
}

const containerTemplate = '<div class="table__content"></div>';

function findHeaderRow(table) {
    const header = table.find('thead');
    if (header.length) {
        return header.find('tr');
    }

    const rows = table.find('tr');

    return rows.length > 1 ? rows.first() : angular.element();
}

function findItemRows(table) {
    let root = table.find('[cc-table-repeat]').last();
    if (root.length === 0) {
        root = table.find('tbody').last();
    }

    return root.children('tr');
}

function setSort(sortColumns, scope, controller, defaultSort) {
    controller.sortColumns = sortColumns;

    sortColumns.forEach((sortColumn) => {
        sortColumn.on('click', () => {
            scope.$apply(() => {
                controller.toggleSort(sortColumn.attr('cc-table-sort'));
            });
        });
    });

    let asc = true;
    if (defaultSort && (defaultSort[0] === '+' || defaultSort[0] === '-')) {
        asc = defaultSort[0] !== '-';
        // eslint-disable-next-line no-param-reassign
        defaultSort = defaultSort.slice(1);
    }

    controller.sort = {
        column: defaultSort || (sortColumns.length && sortColumns[0].attr('cc-table-sort')) || '',
        asc: asc,
    };
    controller.sortItems();
}

function findSortColumns(element) {
    const sortColumns = [];

    angular.forEach(element.find('th'), (th) => {
        const sortColumn = th.attributes['cc-table-sort'];
        if (sortColumn?.value) {
            sortColumns.push(angular.element(th));
        }
    });

    return sortColumns;
}
