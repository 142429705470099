import { omit as radashOmit } from 'radash';

import { FMEJobEnum } from '../../../models/deposit.model';
import template from './statisticDetail.html';

class StatisticDetailPage {
    constructor(
        $auth,
        $authorizationService,
        $companySettings,
        $controlStatisticsService,
        $dataModelService,
        $filter,
        $filterService,
        $indicatorNewPlugService,
        $location,
        $log,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $sliderService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        $window,
        RestProvider,
        reportProvider,
        statisticsProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$controlStatisticsService = $controlStatisticsService;
        this._$dataModelService = $dataModelService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$log = $log;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$sliderService = $sliderService;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._$window = $window;
        this._RestProvider = RestProvider;
        this._reportProvider = reportProvider;
        this._statisticsProvider = statisticsProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;

        this.isAllowed = $authorizationService.isAllowed;
        this.statisticId = $stateParams.statisticId;
        this.companyId = $auth.getPayload().company;
        this.loading = true;

        // Indicators
        this.hasReportsOpen = true;
        this.hasDepositsOpen = false;
        this.hasIndicatorsOpen = false;
        this.plugTree = null;
        this.housingTree = null;
        this.newTable = [];
        // Data
        this.aggregatedReport = false;
        this.checkStatusTimeout = null;
        this.errorsLog = false;
        this.filteredStatistics = [];
        this.hasData = false;
        this.hasIDClientData = false;
        this.showStatus = false;
        this.statistic = null;
        this.statistics = [];
        this.waitingLogs = false;
        this.waitingReports = false;

        // Charts
        this.chartSettings = {
            legends: {
                errors: {
                    show: true,
                    text: this._translate('shared.anomaly'),
                },
                controls: {
                    show: true,
                    text: this._translate('shared.control'),
                },
            },
            colors: {
                total: { main: '#1c84c6', light: '#539dc6' },
                major: { main: '#FF7761', light: '#ff8f87' },
                minor: { main: '#FECA0B', light: '#fee940' },
                blocking: { main: '#c72c48', light: '#c7455d' },
            },
        };

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'attributeNames',
                value: 'attributeName',
                placeholder: 'filter.all.attributes',
                hasSearch: true,
                translateKeyLabel: 'shared.attribute',
                translateParamsLabel: { COUNT: 1 },
            },
            {
                allowed: true,
                name: 'severities',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'deposits',
                value: 'deposit',
                placeholder: 'filter.all.deposits',
                hasSearch: true,
                translateKeyLabel: 'shared.deposit',
            },
            {
                allowed: true,
                name: 'diffs',
                value: 'diff',
                placeholder: 'filter.all.evolutions',
                translateKeyLabel: 'shared.evolution',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'results',
                value: 'result',
                placeholder: 'filter.all.result',
                saveValue: true,
                translateParamsLabel: { COUNT: 1 },
                translateKeyLabel: 'shared.result',
            },
            {
                allowed: true,
                name: 'objects',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'projects',
                value: 'project',
                placeholder: 'filter.all.projects',
                hasSearch: true,
                translateKeyLabel: 'shared.project',
            },
            {
                allowed: true,
                name: 'tableNames',
                value: 'tableName',
                hasSearch: true,
                placeholder: 'filter.all.table',
                translateKeyLabel: 'shared.table',
            },
            {
                allowed: true,
                name: 'thematics',
                value: 'thematic',
                placeholder: 'filter.all.thematic',
                translateKeyLabel: 'shared.thematic',
                hasSearch: true,
            },
            {
                allowed: true,
                name: 'successRate',
                min: 'ratioMin',
                max: 'ratioMax',
                options: 'options',
                placeholder: 'shared.successful',
                type: 'slider',
                translateKeyLabel: 'shared.successRate',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'deliveryZones',
                value: 'deliveryZone',
                placeholder: 'filter.all.deliveryZones',
                hasSearch: true,
                translateKeyLabel: 'shared.deliveryZone',
            },
        ];

        this.filter = this.getFilters($stateParams);
        this.filterValues = {
            severities: [],
            controlPoints: [],
            projects: [],
            deliveryZones: [],
            tableNames: [],
            attributeNames: [],
            objects: [],
            thematics: [],
            deposits: [],
            results: [],
            diffs: [],
        };

        $scope.$watch('$ctrl.filter.tableName', (next, prev) => {
            if (next !== prev) {
                this.filter.attributeName = '';
            }
        });

        $scope.$watchGroup(
            [
                '$ctrl.filter.severity',
                '$ctrl.filter.controlPoint',
                '$ctrl.filter.project',
                '$ctrl.filter.deposit',
                '$ctrl.filter.deliveryZone',
                '$ctrl.filter.tableName',
                '$ctrl.filter.attributeName',
                '$ctrl.filter.object',
                '$ctrl.filter.diff',
                '$ctrl.filter.result',
                '$ctrl.filter.thematic',
                '$ctrl.filter.search',
                '$ctrl.filter.successRate.ratioMax',
                '$ctrl.filter.successRate.ratioMin',
            ],
            () => {
                this.setScopeWithFilters();
            },
        );

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'statistic',
                    title: this._translate('statisticsPage.details', { TYPE: FMEJobEnum.CONTROL }),
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlStatistic'),
                href: $state.href('app.statisticsHome'),
                key: 'statistics',
            },
            allowedPreviousKeys: ['statistics', 'comparaison'],
        });
    }

    $onDestroy() {
        this.cancelStatusTimeout();
    }

    async $onInit() {
        await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(this.companyId).then((result) => {
            this.hasLinkBetweenGridsEnabled = result;
        });

        this._statisticsProvider
            .get(this.statisticId)
            .then((data) => {
                this._userMetricsProvider.openControlStatistics(this.statisticId);

                // Assuming that data models of the statistical deliverables are the same, we get the first deliverable data model slug.
                this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(data.deposits[0]?.deposit);

                const { aggregated, indicators, generationErrors, status } = data;

                if (status === 'PENDING') {
                    this._$toasterService.info({
                        title: this._translate('shared.generating'),
                        body: this._translate('shared.depositsCanTakeTime'),
                    });

                    return this.checkStatus();
                } else if (['FAIL', 'DEPRECATED'].indexOf(status) !== -1) {
                    if (status === 'FAIL') {
                        this._$toasterService.error(this._translate('shared.generationFailed'));
                    } else {
                        this.statistic = data;
                    }

                    this.hasData = false;

                    return;
                }

                if (generationErrors && generationErrors.length > 0) {
                    generationErrors.forEach((error) => this._$log.error(error));
                }

                if (!indicators || !aggregated) {
                    this.hasData = false;

                    return;
                }

                try {
                    this.hasData = true;
                    data.networkSegments = this._$segmentsService.setNetworkSegments(data.networkSegments);
                    data.hasSegmentTrDi = data.networkSegments.includes('TR') && data.networkSegments.includes('DI');
                    data.hasSegmentDi = data.networkSegments.includes('DI') && !data.networkSegments.includes('TR');

                    this.statistic = data;
                    this.statistics = aggregated;
                    this.indicators = this.fetchIndicators(data);

                    // Fetch data for the generic control chart component
                    this.controlSummary = this.statistics.map((stat) => {
                        const { total, severity } = this.fetchControlData(stat);

                        return {
                            severity,
                            faults: total.errors,
                            success: total.success,
                            total: total.total,
                            warning: total.warning,
                        };
                    });

                    this.prepareIndicators();
                } catch (e) {
                    this._$log.error(e.message);
                    this.hasData = false;
                    this.loading = false;
                }
            })
            .then(() => this.checkFilesStatus())
            .then(() => {
                this.setScopeWithFilters();
                this.tableDetail = this._$tableService.detail();
                this.loading = false;
            })
            .catch(() => {
                this.hasData = false;
                this.loading = false;
            });

        const filterDiffs = {
            gt: this._translate('statisticComparisonEvolution.filterImprovement'),
            eq: this._translate('statisticComparisonEvolution.filterEqual'),
            lt: this._translate('statisticComparisonEvolution.filterRegression'),
        };

        const basicResultList = {
            unused: this._translate('shared.nonExistent'),
            error: this._translate('shared.inoperative'),
            fail: this._translate('shared.anomaly'),
            success: this._translate('shared.succeeded'),
        };

        const resultList = this.hasLinkBetweenGridsEnabled
            ? {
                  ...basicResultList,
                  warning: this._translate('shared.notMet'),
              }
            : basicResultList;

        this.filterValues = {
            ...this.filterValues,
            results: Object.keys(resultList).map((key) => ({
                key,
                value: resultList[key],
            })),
            diffs: Object.keys(filterDiffs).map((key) => ({
                key,
                value: filterDiffs[key],
            })),
        };
    }

    prepareIndicators() {
        this.isOldNewPlug = true;

        if (this.indicators.newPlug) {
            const plugsData =
                this.indicators.newPlug ?? this._$indicatorNewPlugService.initIndicNewPlug(this.indicators);

            this.housingTree = this._$indicatorNewPlugService.initHousingTree(plugsData, null, false);
            this.plugTree = this._$indicatorNewPlugService.initPlugTree(plugsData, null, false);

            this.isOldNewPlug = false;
        }

        this.newTable = {
            recordsTable: this.indicators?.tables ?? [],
            abandonedObjectTable: this.indicators.abandonedObject === 0 ? [] : this.indicators.abandonedObject,
        };

        if (this.indicators.distanceBetweenNroPbo?.length) {
            this.distanceBetweenNroPbo = this.statistic.hasSegmentTrDi ? this.indicators.distanceBetweenNroPbo : 0;
        }

        if (this.indicators.distanceBetweenNroSro?.length) {
            this.distanceBetweenNroSro = this.statistic.hasSegmentTrDi ? this.indicators.distanceBetweenNroSro : 0;
        }

        if (this.indicators.distanceBetweenSroPbo?.length) {
            this.distanceBetweenSroPbo = this.statistic.hasSegmentDi ? this.indicators.distanceBetweenSroPbo : 0;
        }
    }

    prepareFilters(stats) {
        const severities = this.filterValues.severities;
        const objects = this.filterValues.objects;
        const controlPoints = [];
        const projects = [];
        const deliveryZones = [];
        const thematics = [];
        const deposits = [];
        let tableNames = [];
        let attributeNames = [];

        stats.forEach((control) => {
            if (!severities.find(({ key }) => key === control.severity)) {
                severities.push({
                    key: control.severity,
                    value: this._humanizeSeverity(control.severity),
                });
            }

            if (!controlPoints.find(({ key }) => key === control.idClient || key === control.controlPoint)) {
                controlPoints.push({ key: control.idClient, value: control.idClient });
            }

            if (!projects.find(({ key }) => key === control.project.id)) {
                projects.push({
                    key: control.project.id,
                    value: control.project.name,
                });
            }

            if (!deliveryZones.find(({ key }) => key === control.deliveryZone.id)) {
                deliveryZones.push({
                    key: control.deliveryZone.id,
                    value: control.deliveryZone.name,
                });
            }

            if (!objects.find(({ key }) => key === control.object)) {
                objects.push({ key: control.object, value: control.object });
            }

            if (!thematics.find(({ key }) => key === control.thematic)) {
                thematics.push({
                    key: control.thematic,
                    value: control.thematic,
                });
            }

            if (control.deposit && control.deposit.id) {
                if (!deposits.find(({ key }) => key === control.deposit.id)) {
                    deposits.push({
                        key: control.deposit.id,
                        value: control.deposit.name,
                    });
                }
            }

            // Show options only when filter is active
            if (control.tables) {
                const tables = control.tables.map(({ tableName }) => tableName);
                tableNames.push(...tables);
            }

            if (this.filter.tableName && control.tables) {
                const table = control.tables.find((table) => table.tableName === this.filter.tableName);
                attributeNames = table.attributes;
            }
        });

        tableNames = tableNames.reduce((acc, name) => {
            if (!acc.find(({ key }) => key === name)) {
                acc.push({ key: name, value: name });
            }

            return acc;
        }, []);

        attributeNames = attributeNames.reduce((acc, attribute) => {
            let name = attribute;
            if (angular.isArray(name)) {
                name = attribute[0];
            }

            if (!acc.find(({ key }) => key === name) && name) {
                acc.push({ key: name, value: name });
            }

            return acc;
        }, []);

        this.filterValues = {
            ...this.filterValues,
            controlPoints,
            severities,
            projects,
            deliveryZones,
            tableNames,
            attributeNames,
            objects,
            thematics,
            deposits,
        };
    }

    setScopeWithFilters() {
        this._$state.go('.', {
            ...radashOmit(this.filter, ['object', 'successRate']),
            object: this.filter.object.join(','),
            ratioMin: this.filter.successRate.ratioMin,
            ratioMax: this.filter.successRate.ratioMax,
        });

        if (this.statistics) {
            try {
                // flatten control point to filter them
                const stats = this.flattenControls(this.statistics)
                    // Pre filter flattened stats
                    .filter(this.filterControlPoint(this.filter.controlPoint))
                    .filter(this._$filterService.genericMultiFilter(this.filter.severity, 'severity'))
                    .filter(this.filterProject(this.filter.project))
                    .filter(this.filterDeposit(this.filter.deposit))
                    .filter(this.filterDeliveryZone(this.filter.deliveryZone))
                    .filter(this.filterTableName(this.filter.tableName))
                    .filter(this.filterAttributeName(this.filter.tableName, this.filter.attributeName))
                    .filter(this.filterThematic(this.filter.thematic))
                    .filter(this._$filterService.genericMultiFilter(this.filter.object, 'object'));

                // Prepare filter options with flattened stats!
                this.prepareFilters(stats);

                // Group by control point and post filters
                this.filteredStatistics = this._$controlStatisticsService
                    .groupByControlPoint(stats)
                    .map((control) => this.fetchControlData(control))
                    .filter(this.filterRatioLte(this.filter.successRate.ratioMax))
                    .filter(this.filterRatioGte(this.filter.successRate.ratioMin))
                    .filter(this.filterSearch(this.filter.search.toLowerCase()))
                    .filter(this.filterDiff(this.filter.diff))
                    .filter(this.filterResults(this.filter.result, this.hasLinkBetweenGridsEnabled));

                this.hasIDClientData = this.filteredStatistics.some(
                    (filteredStatistic) => !!filteredStatistic.idClient,
                );
                // Show previously opened item if possible, and update the content
                if (this.tableDetail) {
                    const toggledId = this.tableDetail.getToggled();
                    if (toggledId) {
                        const item = this.filteredStatistics.find(({ id }) => id === toggledId);
                        this.tableDetail.toggle(null);
                        this.toggleDetails(item);
                    }
                }
            } catch (e) {
                this._$log.error(e.message);
                this.hasData = false;
                this.loading = false;
            }
        }
    }

    getReport(control) {
        const { deposits } = this.statistic;

        // Deposit may not exist if it was deleted after the statistic generation,
        // so we must check that to make sure we can provide the report link
        const item = deposits.find((d) => d.deposit && d.deposit.id === control.deposit.id);
        if (!item || !item.deposit || !item.deposit.report) {
            return null;
        }

        return item.deposit.report;
    }

    getDiff(control) {
        const depositHistory = control.history || [];
        const versions = [control, ...depositHistory]
            .filter((v) => v.total && v.total.errors !== null)
            .sort((a, b) => b.deposit.version - a.deposit.version);

        let diff = null;
        const lastDeposit = versions[0];
        const previousDeposit = versions[1];

        if (versions.length > 1 && previousDeposit && lastDeposit) {
            diff = previousDeposit.total.errors - lastDeposit.total.errors;
        }

        return diff;
    }

    getDiffCount({ controls }) {
        return controls.reduce(
            (acc, c) => {
                const d = this.getDiff(c);
                if (!d) {
                    acc.eq += 1;
                } else if (d > 0) {
                    acc.gt += 1;
                } else {
                    acc.lt += 1;
                }

                return acc;
            },
            { gt: 0, lt: 0, eq: 0 },
        );
    }

    flattenControls(controls) {
        return controls.reduce((acc, item) => {
            const { controls, ...control } = item;
            const spread = controls.map((c) => {
                return { ...c, ...control };
            });

            return [...acc, ...spread];
        }, []);
    }

    fetchControlData(control) {
        const { controls } = control;
        const total = {
            total: controls.reduce((acc, c) => acc + c.total.total, 0),
            errors: controls.reduce((acc, c) => acc + c.total.errors, 0),
            success: controls.reduce((acc, c) => acc + c.total.success, 0),
            warning: controls.reduce((acc, c) => acc + c.total.warning, 0),

            A: controls.reduce((acc, c) => acc + c.total.A, 0),
            C: controls.reduce((acc, c) => acc + c.total.C, 0),
            E: controls.reduce((acc, c) => acc + c.total.E, 0),
            H: controls.reduce((acc, c) => acc + c.total.H, 0),
            S: controls.reduce((acc, c) => acc + c.total.S, 0),
            T: controls.reduce((acc, c) => acc + c.total.T, 0),
            diff: controls.reduce((acc, c) => acc + this.getDiff(c), 0),
        };

        total.ratio = this.getRatio(total);
        total.diffs = this.getDiffCount(control);

        return {
            id: control.name,
            ...control,
            controls,
            total,
        };
    }

    filterControlPoint(value) {
        return ({ idClient, controlPoint }) => !value || idClient === value || controlPoint === value;
    }

    filterProject(value) {
        return ({ project }) => !value || project.id === value;
    }

    filterDeposit(value) {
        return ({ deposit }) => !value || (deposit && deposit.id === value);
    }

    filterDeliveryZone(value) {
        return ({ deliveryZone }) => !value || deliveryZone.id === value;
    }

    filterRatioGte(value) {
        return ({ total }) => total.ratio >= value;
    }

    filterRatioLte(value) {
        return ({ total }) => total.ratio < value + 1;
    }

    filterTableName(value) {
        return ({ tables }) => !value || tables.find(({ tableName }) => tableName === value);
    }

    filterAttributeName(tableName, attributeName) {
        return ({ tables }) => {
            const table = tables.find((table) => table.tableName === tableName);

            return (
                !tableName ||
                !attributeName ||
                (table && table.attributes && table.attributes.find((a) => a[0] === attributeName))
            );
        };
    }

    filterThematic(value) {
        return ({ thematic }) => !value || thematic === value;
    }

    filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search) > -1;
    }

    filterDiff(operator) {
        return ({ total }) => {
            switch (operator) {
                case 'lt':
                    return total.diff < 0;
                case 'eq':
                    return total.diff === 0;
                case 'gt':
                    return total.diff > 0;
                default:
                    return true;
            }
        };
    }

    // For 'error', we don't remember what -1, -2 stand for, so the filter for inoperative control is made with the ratio
    // For 'unused', same, but this value doesn't seem to be used anymore
    filterResults(value, hasLinkBetweenGridsEnabled) {
        return (control) => {
            if (value === 'success') {
                return control.total.errors === 0 && control.total.ratio !== null;
            } else if (value === 'error') {
                return (
                    (control.total.errors === -2 || control.total.errors === -1 || control.total.ratio === null) &&
                    (!control.total.warning || control.total.warning === 0)
                );
            } else if (value === 'warning' && hasLinkBetweenGridsEnabled) {
                return (
                    (control.total.errors === -2 || control.total.errors === -1 || control.total.ratio === null) &&
                    control.total.warning > 0
                );
            } else if (value === 'unused') {
                return control.total.errors === -3;
            } else if (value === 'fail') {
                return control.total.errors > 0;
            }

            return true;
        };
    }

    getRatio({ total, errors }) {
        if (total === 0) {
            return null;
        }

        const valid = total - errors;

        return (valid / (valid + errors)) * 100;
    }

    getRatioText(total) {
        const value = this.getRatio(total);
        if (value === null) {
            return '-';
        }

        return [`${value.toFixed(2)}%`].join('');
    }

    fetchIndicators(data) {
        const { indicators, createdAt, startDate, endDate, projectIds } = data;

        return {
            ...indicators,
            createdAt,
            startDate,
            endDate,
            projectIds,
            lgCable:
                indicators.lgCable &&
                indicators.lgCable.map((cable) => {
                    if (cable.capacity !== '') {
                        cable.capacity = parseInt(cable.capacity, 10);
                    } else {
                        cable.capacity = '-';
                    }

                    return cable;
                }),
            pbo: [
                { value: indicators.pbo, id: 'pbo' },
                { value: indicators.bpe, id: 'bpe' },
                { value: indicators.type_ebp_pto, id: 'pto' },
            ],
            ftth: indicators.plug && indicators.plug.ftth,
            countPlugFTTE: indicators.plug && indicators.plug.ftte,
            plugSum: indicators.plug ? indicators.plug.ftte + indicators.plug.ftth : null,
            newHousing: indicators.newPlug,
            connect: indicators.connectivity,
            abandonedObject: indicators.abandonedObject,
            nbBti: indicators.nbBti,
            cableBti: indicators.cableBti,
            junctionBox: indicators.junctionBox ?? [],
        };
    }

    generateStatistic() {
        if (this.loading) {
            return;
        }

        this.loading = true;

        this._statisticsProvider
            .generateResults(this.statisticId)
            .then((res) => {
                if (res.status === 'PENDING') {
                    return this.checkStatus();
                }

                this._$toasterService.success(this._translate('shared.statisticsInGeneration'));
                this.$onInit();
            })
            .catch((e) => {
                this._$toasterService.error(e);
                this.$onInit();
            });
    }

    checkStatus() {
        this._statisticsProvider.status(this.statisticId).then(({ status }) => {
            if (status === 'PENDING') {
                this.loading = true;
                this.cancelStatusTimeout();
                this.checkStatusTimeout = this._$timeout(() => this.checkStatus(), 6000);
            } else if (status === 'SUCCESS') {
                this.cancelStatusTimeout();
                this._$toasterService.success(this._translate('shared.generationSucceeded'));
                this.$onInit();
            } else if (status === 'FAIL') {
                this.cancelStatusTimeout();
                this._$toasterService.error(this._translate('shared.generationFailed'));
                this.$onInit();
            }
        });
    }

    hasActiveFilters() {
        return (
            this.filter.search ||
            this.filter.severity.length ||
            this.filter.controlPoint ||
            this.filter.successRate.ratioMin !== 0 ||
            this.filter.successRate.ratioMax !== 100 ||
            this.filter.project ||
            this.filter.deliveryZone ||
            this.filter.tableName ||
            this.filter.attributeName ||
            this.filter.object.length ||
            this.filter.diff ||
            this.filter.thematic
        );
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    getFilters(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        const successRate = {
            isSlider: true,
            options: this._$sliderService.optionPercent(),
            ratioMin: params.ratioMin ? parseInt(this._$state.params.ratioMin) : 0,
            ratioMax: params.ratioMax ? parseInt(this._$state.params.ratioMax) : 100,
        };

        return {
            attributeName: params.attributeName || '',
            controlPoint: params.controlPoint || '',
            deliveryZone: params.deliveryZone || '',
            deposit: params.deposit || '',
            diff: params.diff || '',
            object: this._$filterService.getFilterValue(params.object),
            project: params.project || '',
            result: params.result || '',
            search: params.search || '',
            severity: this._$filterService.getFilterValue(params.severity),
            successRate: successRate,
            tableName: params.tableName || '',
            thematic: params.thematic || '',
        };
    }

    cancelStatusTimeout() {
        if (this.checkStatusTimeout) {
            this._$timeout.cancel(this.checkStatusTimeout);
        }
    }

    toggleDetails(control) {
        this.tableDetail.toggle(control);
        if (control) {
            this.showSummary(control);
        }
    }

    showFilteredErrors(depositId, severity) {
        this.filter.deposit = depositId;
        this.filter.severity = severity;

        this.hasReportsOpen = true;
        const element = angular.element('#errors');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    showSummary(control) {
        // Group by deposit
        const deposits = control.controls.reduce((acc, control) => {
            if (acc.indexOf(control.deposit.id) === -1) {
                const diff = this.getDiff(control);
                const report = this.getReport(control);
                acc.push({ ...control, ...control.deposit, diff, report });
            }

            return acc;
        }, []);

        this.details = {
            tab: 'summary',
            id: control.id,
            deposits,
        };

        if (deposits && deposits.length > 0) {
            this.showDepositHistoryChart(control, deposits[0]);
        }
    }

    showDepositHistoryChart(control, deposit) {
        const { colors, legends } = this.chartSettings;
        // Reduce main deposits with histories and sort by version
        const depositHistory = deposit.history || [];
        const history = [deposit, ...depositHistory].sort((a, b) => a.deposit.version - b.deposit.version);

        this.details.depositId = deposit.id;

        const title = `Historique pour ${deposit.name.replace(/_cv./, '')}`;

        const errors = history.map(({ total }) => total.errors);
        const totals = history.map(({ total }) => total.total);

        const onLegendClick = function (e, legendItem) {
            const defaultHandler = Chart.defaults.global.legend.onClick.bind(this);
            const key = Object.keys(legends).find((key) => legends[key].text === legendItem.text);
            if (key && legends[key]) {
                legends[key].show = !legends[key].show;
            }

            return defaultHandler(e, legendItem);
        };

        this.details.chart = {
            type: 'line',
            options: {
                legend: {
                    position: 'bottom',
                    onClick: onLegendClick,
                },
                title: {
                    text: title,
                    display: true,
                    position: 'top',
                },
                tooltips: { enabled: true, mode: 'label' },
                scales: {
                    y: { ticks: { beginAtZero: true, maxTicksLimit: 6 } },
                },
            },
            data: {
                labels: history.map(({ deposit }) => {
                    const createdAt = moment(deposit.createdAt);
                    if (createdAt.isValid()) {
                        return `V${deposit.version} - ${createdAt.format('DD/MM/YYYY')}`;
                    }

                    return `Version ${deposit.version}`;
                }),
                datasets: [
                    {
                        pointHitRadius: 30,
                        label: legends.errors.text,
                        data: errors,
                        fill: true,
                        backgroundColor: colors[control.severity].light,
                        borderColor: colors[control.severity].main,
                        borderWidth: 2,
                        hidden: !legends.errors.show,
                        lineTension: 0,
                    },
                    {
                        pointHitRadius: 30,
                        label: legends.controls.text,
                        data: totals,
                        borderColor: colors.total.main,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 2,
                        hidden: !legends.controls.show,
                        lineTension: 0,
                    },
                ],
            },
        };
    }

    toggleIndicatorsVisibility() {
        this.hasIndicatorsOpen = !this.hasIndicatorsOpen;
    }

    toggleReportsVisibility() {
        this.hasReportsOpen = !this.hasReportsOpen;
    }

    toggleDepositsVisibility(open, scrollTo = false) {
        let toggled = open;
        if (typeof toggled !== 'boolean') {
            toggled = !this.hasDepositsOpen;
        }

        if (scrollTo) {
            this._$timeout(() => {
                const element = angular.element('#deposits');
                angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
            }, 0);
        }

        this.hasDepositsOpen = toggled;
    }

    /**
     * Update files buttons status
     * TODO: refact in v3, too much booleans
     * @param recursive
     */
    async checkFilesStatus(recursive = false) {
        const aggregatorStatuses = await this._statisticsProvider.status(this.statisticId);

        this.waitingReports = ['PENDING', 'LOADING'].includes(aggregatorStatuses.aggregatedReportFileStatus);
        this.aggregatedReport = aggregatorStatuses.aggregatedReportFileStatus === 'SUCCESS';
        this.waitingLogs = ['PENDING', 'LOADING'].includes(aggregatorStatuses.errorsLogFileStatus);
        this.errorsLog = aggregatorStatuses.errorsLogFileStatus === 'SUCCESS';

        if (!recursive) {
            return;
        }

        if (this.waitingReports || this.waitingLogs) {
            this._$timeout(() => this.checkFilesStatus(true), 7000);

            return;
        }

        this._$timeout(() => {
            this._$toasterService.success(this._translate('statisticsPage.aggregatorFileGenerated'));
        });
    }

    async generateFile(type = 'aggregated') {
        this._$toasterService.info(this._translate('shared.generating'));
        try {
            await this._statisticsProvider.generateFile(this.statisticId, type);
            this.checkFilesStatus(true);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async downloadStatistic(type = 'aggregated') {
        this._$toasterService.info(this._translate('shared.downloadingFile'));

        try {
            this._userMetricsProvider.downloadControlStatistics(this.statisticId);
            await this._statisticsProvider.download(this.statisticId, type);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async downloadControlErrors(control) {
        if (!control) {
            return;
        }

        try {
            await this._statisticsProvider.downloadByControlPoints(this.statisticId, [control.controlPoint]);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async downloadFilteredErrors() {
        const controlPoints = this.filteredStatistics.map(({ controlPoint }) => controlPoint);
        if (controlPoints.length < 1) {
            return;
        }

        try {
            await this._statisticsProvider.downloadByControlPoints(this.statisticId, controlPoints);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    getVersion(control) {
        const depositHistory = control.history || [];
        const versions = [control, ...depositHistory]
            .filter((v) => v.total)
            .sort((a, b) => b.deposit.version - a.deposit.version);

        let previousVersion = null;
        const previousDeposit = versions[1];

        if (versions.length > 1 && previousDeposit) {
            previousVersion = previousDeposit;
        }

        return previousVersion;
    }

    showErrorsModal(control, deposit) {
        if (!control || !deposit.report || !deposit.report.id) {
            return;
        }

        const versionOrigin = deposit.version;
        const idDeposit = deposit.id;
        const { id } = deposit.report;
        const {
            total: { errors },
            controlPoint,
            severity,
        } = control;

        const getBottomTemplate = () => {
            const reportText = this._translate('shared.toggleReport');
            const reportUrl = this._$state.href('app.reportDetail', {
                reportId: id,
            });
            const errorText = this._translate('shared.downloadAnomalies');
            const errorsUrl = this._RestProvider.resolveURL(`reports/${id}/controls/${controlPoint}/xlsx`, {
                severity,
            });

            return `
                <a class="d-block m-b-5 m-t-5" target="_blank" href="${reportUrl}">
                    <i class="icofont icofont-eye"></i> ${reportText}
                </a>
                <a class="d-block m-b-5 m-t-5" ng-if="${errors}" target="_blank" href="${errorsUrl}">
                    <i class="icofont icofont-download"></i> ${errorText}
                </a>
            `;
        };

        if (versionOrigin === 1 || deposit.history.length === 0) {
            return this._$modalService.triggerControlErrorModal(
                control,
                this._$reportService.getErrors(id, controlPoint, severity),
                getBottomTemplate,
            );
        }

        const previousVersion = this.getVersion(deposit);
        const previousVersionNb = previousVersion.deposit.version;
        const idDepositToCompare = previousVersion.deposit.id;

        Promise.all([
            this._reportProvider.getVersionControlJson(idDeposit, controlPoint, severity),
            this._reportProvider.getVersionControlJson(idDepositToCompare, controlPoint, severity),
        ])
            .then((deposits) => {
                const depositLastVersion = deposits[0];
                const depositPreviousToCompare = deposits[1];

                const controlErrors = [depositLastVersion, depositPreviousToCompare];

                return this._$modalService.triggerControlErrorModal(
                    {
                        ...control,
                        version1: versionOrigin,
                        version2: previousVersionNb,
                    },
                    controlErrors,
                    getBottomTemplate,
                    false,
                );
            })

            .catch((err) => {
                this._$toasterService.error(err);
            });
    }

    async removeStat() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.statistic', {
                COUNT: 1,
                NAME: this.statistic.statisticName,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._statisticsProvider.remove(this.statisticId);
            this._$toasterService.info(
                this._translate('removeModal.successStatistic', {
                    COUNT: 1,
                }),
            );
            await this._$state.go('app.statisticsHome');
        } catch {
            this._$toasterService.error(this._translate('removeModal.failure', { COUNT: 1 }));
        }
    }

    isDebug() {
        return this._$window.localStorage && this._$window.localStorage.debug;
    }

    refreshUrl() {
        const {
            project,
            deliveryZone,
            severity,
            tableName,
            attributeName,
            object,
            thematic,
            deposit,
            diff,
            search,
            successRate,
        } = this.filter;

        this._$state.go('.', {
            deposit,
            project,
            deliveryZone,
            severity: severity.join(','),
            tableName,
            object: object.join(','),
            thematic,
            ratioMin: successRate.ratioMin,
            ratioMax: successRate.ratioMax,
            search,
            attributeName,
            diff,
        });
    }

    cancelFilters() {
        this.filter = this.getFilters({}, false);
        this.refreshUrl();
    }

    removeFilter(filterName, initialValue) {
        if (filterName === 'slider') {
            this.filter.successRate.ratioMin = initialValue[0];
            this.filter.successRate.ratioMax = initialValue[1];
        } else {
            this.filter[filterName] = initialValue;
        }

        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    scrollElement(customClickEvent) {
        this.cancelFilters();

        if (customClickEvent.isDoughnut) {
            this.hasReportsOpen = true;

            this._$reportService.scrollDoughnut(customClickEvent, this.filter);
        }

        if (customClickEvent.isBar) {
            this.hasReportsOpen = true;

            if (customClickEvent.labelsIndexSeverity === 0) {
                this.filter.severity = ['blocking'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            } else if (customClickEvent.labelsIndexSeverity === 1) {
                this.filter.severity = ['major'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            } else if (customClickEvent.labelsIndexSeverity === 2) {
                this.filter.severity = ['minor'];
                this._$reportService.scrollBar(customClickEvent, this.filter);
            }
        }

        const statusFilter = this.filterOptions.find((item) => item.name === 'severities');
        this._$scope.$broadcast('forceFilterRender', {
            filter: statusFilter,
            saveChanges: false,
            openDropdown: false,
        });

        const filterResults = this.filterOptions.find((item) => item.name === 'results');
        this._$scope.$broadcast('forceFilterRender', {
            filter: filterResults,
            saveChanges: false,
            openDropdown: false,
        });

        const $element = angular.element('#showAnomaliesStatTables');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
        }, 0);
    }

    compareWith(statistic) {
        this._$state.go('app.comparisonPage', {
            controlStat: statistic._id,
        });
    }
}

angular.module('dotic').component('statisticDetailPage', {
    controller: StatisticDetailPage,
    templateUrl: template,
});
