import template from './controlPointsListModal.html';

class ControlPointsListModalComponent {
    constructor($scope) {
        this._$scope = $scope;

        this.attribute = {};
        this.controlPoints = [];
    }

    $onInit() {
        const attribute = this.resolve.attribute;

        this.attribute = attribute;
        this.controlPoints = this.attribute.controlPoints;
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('controlPointsListModal', {
    templateUrl: template,
    controller: ControlPointsListModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
