class DeleteService {
    constructor($filter, $toasterService) {
        this._$toasterService = $toasterService;
        this._$translate = $filter('translate');
    }

    async deleteArrayOfIds(
        idsToDelete,
        deleteMethod,
        i18nSuccessKey = 'removeModal.success',
        i18nFailureKey = 'removeModal.failure',
    ) {
        const promises = idsToDelete.map(async (idToDelete) => {
            try {
                await deleteMethod(idToDelete);

                return { success: true };
            } catch (error) {
                return { success: false, error: error.message };
            }
        });

        const results = await Promise.all(promises);
        const allSucceeded = results.every((result) => result.success);
        if (allSucceeded) {
            this._$toasterService.info(this._$translate(i18nSuccessKey, { COUNT: results.length }));

            return;
        }

        this._$toasterService.error(this._$translate(i18nFailureKey, { COUNT: results.length }));
    }
}

angular
    .module('dotic')
    .factory('$deleteService', ($filter, $toasterService) => new DeleteService($filter, $toasterService));
