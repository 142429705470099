import template from './supervisionForm.html';

class SupervisionFormPage {
    constructor(
        $authorizationService,
        $filter,
        $log,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        companyProvider,
        supervisionProvider,
    ) {
        this._$log = $log;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._supervisionProvider = supervisionProvider;
        this._companyProvider = companyProvider;

        this.isAllowed = $authorizationService.isAllowed;

        this.loading = true;
        this.toggleUsersAlertSelection = false;

        this.newCompany = () => ({
            id: '',
            selection: [],
            users: [],
            options: this.getAvailableCompanies(),
        });

        this.selections = [];
        this.indicatorAlertForm = {
            name: '',
            description: '',
            thresholds: {},
            selectedUsers: [],
            alertHistories: [],
        };

        this.indicatorAlertId = $stateParams.indicatorAlertId || null;

        this.companiesList = [];

        const navigation = {
            defaultPrevious: {
                key: 'supervisionList',
                title: this._translate('supervision.supervisionList'),
                href: $state.href('app.supervisionList'),
            },
            allowedPreviousKeys: ['supervisionList'],
        };

        if (this.indicatorAlertId) {
            navigation.newPage = [
                {
                    key: 'supervisionEdit',
                    title: this._translate('supervision.edit'),
                    href: $state.href('app.supervisionEdit'),
                },
            ];
        } else {
            navigation.newPage = [
                {
                    key: 'supervisionCreate',
                    title: this._translate('supervision.create'),
                    href: $state.href('app.supervisionCreate'),
                },
            ];
        }

        $scope.$emit('keepPreviousNavigation', navigation);
    }

    $onInit() {
        this.loading = true;

        this._companyProvider
            .getCompaniesUsersList()
            .then((companies) => {
                this.companiesList = this._orderBy(companies, 'name');
                this.loading = false;

                return this.loadAlert()
                    .then((indicatorAlertForm) => {
                        this._$timeout(() => {
                            if (indicatorAlertForm) {
                                this.indicatorAlertForm = indicatorAlertForm;
                                //update

                                this.loading = false;

                                const companyAlert = [];

                                this.indicatorAlertForm.selectedUsers.forEach((user) => {
                                    this.companiesList.forEach((company) => {
                                        if (company.id !== user.companyId) {
                                            return;
                                        }

                                        const assigned = {};
                                        company.employees.forEach((employee) => {
                                            if (!employee.id.includes(user.userId)) {
                                                return;
                                            }

                                            assigned.id = user.id;
                                            assigned.companyId = company.id;
                                            assigned.fullname = employee.fullname;
                                            assigned.userId = employee.id;
                                            assigned.role = employee.role;

                                            return assigned;
                                        });

                                        companyAlert.push({
                                            id: company.id,
                                            name: company.name,
                                            employees: assigned,
                                        });
                                    });
                                });

                                const reduceCompanies = companyAlert.reduce((acc, curr) => {
                                    const itemExists = acc.find(
                                        (item) => curr.id === item.id && curr.name === item.name,
                                    );
                                    if (itemExists) {
                                        itemExists.employees = [...itemExists.employees, curr.employees];
                                    } else {
                                        acc.push({
                                            id: curr.id,
                                            employees: [curr.employees],
                                            name: curr.name,
                                        });
                                    }

                                    return acc;
                                }, []);

                                this.selections = reduceCompanies.map((company) => {
                                    this.companiesList.forEach((companyToCompare) => {
                                        if (companyToCompare._id !== company.id) {
                                            return;
                                        }

                                        this.selectedUsers = company.employees;
                                        this.findAllUsers = companyToCompare.employees
                                            .filter((user) => user.role === 'admin')
                                            .map((allUsers) => {
                                                return {
                                                    id: allUsers.id,
                                                    companyId: companyToCompare.id,
                                                    fullname: allUsers.fullname,
                                                    userId: allUsers.id,
                                                    role: allUsers.role,
                                                };
                                            });
                                    });

                                    return {
                                        id: company.id,
                                        selection: this.selectedUsers,
                                        users: this.findAllUsers,
                                        options: this.companiesList,
                                    };
                                });

                                if (this.selections.length > 0) {
                                    this.toggleUsersAlertSelection = true;
                                }

                                this.indicatorAlertForm = {
                                    ...indicatorAlertForm,
                                };
                            }
                        });
                    })
                    .catch(this._$toasterService.error);
            })
            .catch(this._$toasterService.error);
    }

    loadAlert() {
        if (!this.indicatorAlertId) {
            return Promise.resolve(null);
        }

        return this._supervisionProvider.getAlert(this.indicatorAlertId);
    }

    saveAlert() {
        const indicatorAlertForm = angular.copy(this.indicatorAlertForm);

        if (this.indicatorAlertId) {
            return this._supervisionProvider
                .updateAlert(this.indicatorAlertId, indicatorAlertForm)
                .then(() => {
                    this._$toasterService.success({
                        body: this._translate('supervision.updated'),
                    });
                })
                .then(() =>
                    this._$state.go('app.supervisionView', {
                        indicatorAlertId: this.indicatorAlertId,
                    }),
                )
                .catch(() =>
                    this._$toasterService.error({
                        body: this._translate('shared.errorUpdate'),
                    }),
                );
        }

        return this._supervisionProvider
            .createAlert(indicatorAlertForm)
            .then(() => {
                this._$toasterService.success({
                    body: this._translate('supervision.created'),
                });
            })
            .then(() => this._$state.go('app.supervisionList'))
            .catch(() =>
                this._$toasterService.error({
                    body: this._translate('shared.errorCreation'),
                }),
            );
    }

    getSelection() {
        return this.companiesList.filter((c) => c.selected);
    }

    setCompany(index, companyId) {
        const company = this.companiesList.find((c) => c._id === companyId);

        this.filteredEmployees = company.employees
            .filter((employee) => employee.role === 'admin')
            .map((el) => {
                return {
                    id: el.id,
                    role: el.role,
                    fullname: el.fullname,
                };
            });

        this.selections[index] = {
            ...this.selections[index],
            id: company.id,
            users: this.filteredEmployees,
            selection: [],
        };
    }

    setUser(companyId, userId) {
        const filteredCompany = this.selections.find(({ id }) => id === companyId);
        const selectedCompany = this.companiesList.find(({ id }) => id === companyId);

        const listAllUsers = filteredCompany.users;

        const fullnameUser = listAllUsers
            .filter((user) => user.id === userId)
            .map(({ fullname }) => fullname)
            .join(', ');

        const exist = this.indicatorAlertForm.selectedUsers.some(
            (item) => item.userId === userId && item.companyId === companyId,
        );

        if (!exist) {
            this.indicatorAlertForm.selectedUsers.push({
                companyId,
                userId,
                fullname: fullnameUser,
                companyName: selectedCompany.name,
            });

            const lastSelection = this.selections.find((lastSelection) => lastSelection.id === companyId);

            lastSelection.selection.push({
                companyId,
                userId,
                fullname: fullnameUser,
                companyName: selectedCompany.name,
            });
        } else {
            // Delete from this.indicatorAlertForm.selectedUsers
            const indexToDelete = this.indicatorAlertForm.selectedUsers.findIndex((user) => user.userId === userId);
            if (indexToDelete < 0) {
                return;
            }

            this.indicatorAlertForm.selectedUsers.splice(indexToDelete, 1);

            // Delete from this.selections
            this.selections.forEach((obj) => {
                const selectedUsers = obj.selection;

                const indexToDeleteSelections = selectedUsers.findIndex(
                    (user) => user.userId === userId && user.companyId === companyId,
                );
                if (indexToDeleteSelections < 0) {
                    return;
                }

                selectedUsers.splice(indexToDeleteSelections, 1);
            });
        }
    }

    selectUsers(userId, companyId) {
        let result = '';
        let resultName = '';

        if (this.indicatorAlertForm.selectedUsers.length > 0) {
            const selectedUsers = this.indicatorAlertForm.selectedUsers.map((user) => user);
            const selectedUsersCompany = selectedUsers.filter((company) => company.companyId === companyId);

            result = selectedUsersCompany.find((user) => user.userId === userId);
        }

        if (result) {
            resultName =
                this.indicatorAlertForm.selectedUsers.length > 1 ? `${result.fullname}, ` : `${result.fullname}`;
        }

        return resultName;
    }

    isAssigned(companyId, userId) {
        if (!this.indicatorAlertId) {
            const selected = this.indicatorAlertForm.selectedUsers.find((selected) => selected.companyId === companyId);

            return (
                selected &&
                !!this.indicatorAlertForm.selectedUsers.find(
                    (user) => user.userId === userId && user.companyId === companyId,
                )
            );
        }

        const selected = this.selections.find((selected) => selected.id === companyId);

        if (selected?.selection.length > 0) {
            return !!selected.selection.find((a) => a.userId === userId);
        }

        return false;
    }

    // Remove last company assigned
    getAvailableCompanies() {
        return this.companiesList.filter((c) => {
            return !this.selections.find((s) => s.id === c.id);
        });
    }

    addCompanyEntry() {
        this.selections.push(this.newCompany());
        this.toggleUsersAlertSelection = true;
    }

    // Remove a company alerte
    onCompanyUnSelect(companyId) {
        // Mettre à jour le formaulaire
        this.selections = this.selections.filter((item) => item.id !== companyId);

        this.indicatorAlertForm.selectedUsers = this.indicatorAlertForm.selectedUsers.filter(
            (item) => item.companyId !== companyId,
        );
    }
}

angular.module('dotic').component('supervisionFormPage', {
    templateUrl: template,
    controller: SupervisionFormPage,
});
