angular.module('dotic').filter('humanizeGeometry', () => {
    return (value) => {
        switch (value) {
            case 'fme_point':
                return 'Point';
            case 'fme_line':
                return 'Ligne';
            case 'fme_area':
                return 'Aire';
            case 'fme_no_geom':
                return 'Non géométrique';
            default:
                return value;
        }
    };
});
