angular.module('dotic').factory('fillGridProvider', fillGridProvider);

function fillGridProvider(ApiProvider, URI_KEYS, $excelService, $filter) {
    const translate = $filter('translate');

    return {
        create: createFillGrid,
        delete: deleteFillGrid,
        get: getFillGrid,
        getChildren: getChildrenFillGrid,
        listAllFillGrids: listAllFillGrids,
        update: updateFillGrid,
        downloadComparison,
        getFillGridsToCompare,
    };

    /**
     * POST /fillGrids
     *
     * @returns {*} Promise
     */
    function createFillGrid(gridFill) {
        return ApiProvider.one(URI_KEYS.FILL_GRID).customPOST(gridFill);
    }

    /**
     * DELETE /fillGrids/:fillGridId
     *
     * @param {string} fillGridId
     *
     * @returns {*} Promise
     */
    function deleteFillGrid(fillGridId) {
        return ApiProvider.one(URI_KEYS.FILL_GRID, fillGridId).remove();
    }

    /**
     * GET /fillGrids
     * @param {object} queryParams
     * @param {number} queryParams.order
     * @param {string} queryParams.column
     * @param {number} queryParams.limit
     * @param {number} queryParams.page
     */
    function listAllFillGrids(queryParams) {
        return ApiProvider.one(URI_KEYS.FILL_GRID).customGET(null, queryParams);
    }

    /**
     * GET /fillGrids/:fillGridId
     *
     * @param {string} fillGridId
     *
     * @returns {*} Promise
     */
    function getFillGrid(fillGridId) {
        return ApiProvider.one(URI_KEYS.FILL_GRID, fillGridId).get();
    }

    /**
     * GET /fillGrids/children/:fillGridId
     * Get all children of the fillgrid parent passed in argument
     *
     * @param {string} fillGridId The fillgrid parent id
     * @returns {*} Promise
     */
    function getChildrenFillGrid(fillGridId) {
        return ApiProvider.one(`${URI_KEYS.FILL_GRID}/children/${fillGridId}`).get();
    }

    /**
     * PUT /fillGrids/:fillGridId
     *
     * @param {string} fillGridId
     * @param {object} fillGrid
     *
     * @returns {*} Promise
     */
    function updateFillGrid(fillGridId, fillGrid) {
        return ApiProvider.one(URI_KEYS.FILL_GRID, fillGridId).customPUT(fillGrid);
    }

    function downloadComparison(dataFillGrids, gridNames, phaseDifference) {
        const fillGridLight = dataFillGrids.map(({ elementCompared, elementSelected, attributName, tableName }) => {
            const phaseFromCompared = elementCompared
                ? getPhaseEnable(phaseDifference, elementCompared.phases)
                : undefined;
            const phaseFromSelected = elementSelected
                ? getPhaseEnable(phaseDifference, elementSelected.phases)
                : undefined;
            const severityFromCompared = elementCompared ? elementCompared.severity : undefined;
            const severityFromSelected = elementSelected ? elementSelected.severity : undefined;

            return {
                attributName,
                severityFromCompared,
                severityFromSelected,
                isSameSeverity: severityFromCompared === severityFromSelected,
                isSamePhasesEnable: phaseFromCompared === phaseFromSelected,
                phaseDifference,
                phaseFromCompared,
                phaseFromSelected,
                tableName,
            };
        });
        const fileName = `${translate('shared.comparison')}_${gridNames.nameGridCompared}_${
            gridNames.nameGridSelected
        }`;

        return $excelService.downloadXLSX(
            { headers: true },
            jsonToExcel(fillGridLight, phaseDifference, gridNames),
            fileName,
            'controlGridAdminView.downloadGrid',
        );
    }

    function jsonToExcel(fillGridCompared, phaseDifference, { nameGridCompared, nameGridSelected }) {
        const labelSeverityFromCompared = translate('shared.severityIn', {
            GRID_NAME: nameGridCompared,
        });
        const labelSeverityFromSelected = translate('shared.severityIn', {
            GRID_NAME: nameGridSelected,
        });
        const labelPhaseFromCompared = translate('shared.phaseIn', {
            GRID_NAME: nameGridCompared,
            PHASE: phaseDifference,
        });
        const labelPhaseFromSelected = translate('shared.phaseIn', {
            GRID_NAME: nameGridSelected,
            PHASE: phaseDifference,
        });
        const labelSamePhasesEnable = translate('shared.phaseDifferent', { PHASE: phaseDifference });
        const labelSameSeverity = translate('shared.severityDifferent');

        return fillGridCompared.map((attribut) => {
            const phaseFromComparedLabel =
                attribut.phaseFromCompared === false
                    ? translate('shared.disabled')
                    : translate('shared.activated', { GENDER: 'female' });
            const phaseFromSelectedLabel =
                attribut.phaseFromSelected === false
                    ? translate('shared.disabled')
                    : translate('shared.activated', { GENDER: 'female' });

            const valueForPhasesEnable = attribut.isSamePhasesEnable ? translate('shared.no') : translate('shared.yes');
            const valueForSeverity = attribut.isSameSeverity ? translate('shared.no') : translate('shared.yes');
            const valueForStatPhaseCompared = angular.isDefined(attribut.phaseFromCompared)
                ? phaseFromComparedLabel
                : '-';
            const valueForStatPhaseSelected = angular.isDefined(attribut.phaseFromSelected)
                ? phaseFromSelectedLabel
                : '-';
            const severityFromCompared = attribut.severityFromCompared
                ? translate(`severity.${attribut.severityFromCompared}`)
                : '-';
            const severityFromSelected = attribut.severityFromSelected
                ? translate(`severity.${attribut.severityFromSelected}`)
                : '-';

            return {
                TABLE: attribut.tableName,
                CHAMP: attribut.attributName,
                [labelSameSeverity]: valueForSeverity,
                [labelSeverityFromCompared]: severityFromCompared,
                [labelSeverityFromSelected]: severityFromSelected,
                [labelSamePhasesEnable]: valueForPhasesEnable,
                [labelPhaseFromCompared]: valueForStatPhaseCompared,
                [labelPhaseFromSelected]: valueForStatPhaseSelected,
            };
        });
    }

    function getPhaseEnable(phaseDifference, phases) {
        if (!phases || !phaseDifference) {
            return undefined;
        }

        const phase = phases.find(({ phaseName }) => phaseName === phaseDifference);

        return phase ? phase.isEnabled : undefined;
    }

    /**
     * GET /gridFills/:fillGridId1/:fillGridId2/compare
     *
     * @param {string} fillGridId1
     * @param {string} fillGridId2
     *
     * @returns {*} Promise
     */
    function getFillGridsToCompare(fillGridId1, fillGridId2) {
        return ApiProvider.one(`${URI_KEYS.FILL_GRID}/${fillGridId1}/${fillGridId2}/compare`).get();
    }
}
