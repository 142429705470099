const highOrderFilterEmpty = (fct) => (filter) => (filter === '' ? () => true : fct(filter));

class FilterService {
    constructor($filter) {
        this._$filter = $filter;

        this.companySearch = highOrderFilterEmpty((filter) => (company) => this.findText(company.name, filter));
        this.companyOffer = highOrderFilterEmpty((filter) => (company) => company.subscription === filter);
        this.gridControlCompany = highOrderFilterEmpty(
            (filter) => (grid) => grid.company.find((company) => company.id === filter),
        );
        this.gridControlName = highOrderFilterEmpty((filter) => (grid) => this.findText(grid.fullname, filter));
        this.gridControlDataModel = highOrderFilterEmpty(
            (filter) => (grid) => grid.dataModel.map((dataModel) => dataModel.name).includes(filter),
        );
        this.gridFillCompany = highOrderFilterEmpty(
            (filter) => (grid) => grid.company.find((company) => company.id === filter),
        );
        this.gridFillName = highOrderFilterEmpty((filter) => (grid) => this.findText(grid.fullname, filter));
        this.gridFillDataModel = highOrderFilterEmpty((filter) => (grid) => grid.dataModel.name === filter);
        this.userRole = highOrderFilterEmpty((filter) => (user) => user.role === filter);
        this.userSearch = highOrderFilterEmpty(
            (filter) => (user) => this.findText(user.fullname, filter) || this.findText(user.email, filter),
        );
    }

    filterControlPoint(values) {
        return (control) => values.length === 0 || values.indexOf(control.controlPoint) > -1;
    }

    /**
     * Get only elements with a dateField value between start & end date of rangeDate
     * Return all elements if rangeDate is not valid
     * @param {{endDate: Date, startDate: Date}} rangeDate
     * @param {string} dateField
     * @returns {function(*): boolean}
     */
    filterRangeDate(rangeDate, dateField) {
        const hasNoValidValue = !rangeDate || !rangeDate.startDate || !rangeDate.endDate;

        return (control) =>
            hasNoValidValue ||
            (moment(control[dateField]).isAfter(rangeDate.startDate) &&
                moment(control[dateField]).isBefore(rangeDate.endDate));
    }

    /**
     * Get only elements with at least one of enabled segments in parameters
     * Return all elements if segments is an empty array
     * @param {string[]} segments
     * @returns {function(*): boolean}
     */
    filterSegments(segments) {
        const hasFilterSegment = (control, segment) =>
            control.networkSegments.find(
                (controlPointSegment) => controlPointSegment.segmentName === segment && controlPointSegment.isEnabled,
            );

        return (control) => !segments.length || segments.every((segment) => hasFilterSegment(control, segment));
    }

    findText(haystack, needle) {
        const normalizedHaystack = haystack
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

        const normalizedNeedle = needle
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

        return normalizedHaystack.includes(normalizedNeedle);
    }

    formatForFilter(list, getKey = (item) => item, getValue = (item) => item, filter = null) {
        const map = new Map();

        list.forEach((item) => {
            const value = getValue(item);
            const filteredValue = filter ? this._$filter(filter)(value) : value;

            map.set(getKey(item), filteredValue);
        });

        return Array.from(map, ([key, value]) => ({ key, value }));
    }

    formatArrayForFilter(list, key, getKey = (item) => item, getValue = (item) => item, filter = null) {
        const map = new Map();

        list.forEach((item) => {
            item[key].forEach((subItem) => {
                const value = getValue(subItem);
                const filteredValue = filter ? this._$filter(filter)(value) : value;

                map.set(getKey(subItem), filteredValue);
            });
        });

        return Array.from(map, ([key, value]) => ({ key, value }));
    }

    genericFilter(value, fieldName) {
        return (control) => control[fieldName] === value || value === '';
    }

    //Get value for multi-select filters
    getFilterValue(filter) {
        if (filter && angular.isString(filter)) {
            return filter.split(',');
        }

        if (filter && angular.isArray(filter)) {
            return filter;
        }

        return [];
    }

    genericMultiFilter(values, fieldName) {
        return (item) => values.length === 0 || values.includes(item[fieldName]);
    }
}

angular.module('dotic').factory('$filterService', ($filter) => new FilterService($filter));
