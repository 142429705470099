import template from './superAdminStatistics.html';

class SuperAdminStatisticsPage {
    constructor($auth, $chartService, $excelService, $state, $scope, $filter, superAdminProvider) {
        this._$excelService = $excelService;
        this._$scope = $scope;
        this._$state = $state;
        this._translate = $filter('translate');
        this._superAdminProvider = superAdminProvider;

        this.loading = true;

        this.storedDepositsChartData = null;
        this.depositsOkNotOkChartData = null;
        this.plugsChartData = null;
        this.controlledDepositsChartData = null;
        this.lastMonthDepositsChartData = null;
        this.previousMonth = null;
        this.stats = null;

        this.horizontalBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(),
        };

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: this._translate('shared.statistics'),
                    href: $state.href('app.superAdminStatistics'),
                },
            ],
        });
    }

    $onInit() {
        return this.loadData();
    }

    loadData() {
        this.loading = true;

        return this._superAdminProvider
            .getDeposits()
            .then((stats) => {
                this.stats = stats;
                this.loadDepositChart(stats);
            })
            .then(() => {
                this.loading = false;
            });
    }

    loadDepositChart(stats) {
        this.depositsOkNotOkChartData = {
            labels: stats.months,
            datasets: [
                {
                    stack: 'ok',
                    label: `${stats.totalOfPassed} ${this._translate('shared.deliverableValidated').toLowerCase()}`,
                    backgroundColor: '#A0D468',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.passedDeposits,
                },
                {
                    stack: 'nok',
                    label: `${stats.totalOfFailed} ${this._translate('shared.deliverableError').toLowerCase()}`,
                    backgroundColor: '#FF7761',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.failedDeposits,
                },
            ],
        };

        this.storedDepositsChartData = {
            labels: stats.months,
            datasets: [
                {
                    stack: 'ok',
                    label: `${stats.totalOfPassedStored} ${this._translate(
                        'shared.deliverableValidated',
                    ).toLowerCase()}`,
                    backgroundColor: '#A0D468',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.passedStoredDeposits,
                },
                {
                    stack: 'nok',
                    label: `${stats.totalOfFailedStored} ${this._translate('shared.deliverableError').toLowerCase()}`,
                    backgroundColor: '#FF7761',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.failedStoredDeposits,
                },
            ],
        };

        this.controlledDepositsChartData = {
            labels: stats.months,
            datasets: [
                {
                    stack: 'ok',
                    label: `${stats.totalOfPassedControlled} ${this._translate(
                        'shared.deliverableValidated',
                    ).toLowerCase()}`,
                    backgroundColor: '#A0D468',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.passedControlledDeposits,
                },
                {
                    stack: 'nok',
                    label: `${stats.totalOfFailedControlled} ${this._translate(
                        'shared.deliverableError',
                    ).toLowerCase()}`,
                    backgroundColor: '#FF7761',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.failedControlledDeposits,
                },
            ],
        };

        this.plugsChartData = {
            labels: stats.months,
            datasets: [
                {
                    stack: 'plugs',
                    label: this._translate('shared.numberOfPlugs'),
                    backgroundColor: '#005980',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: stats.totalOfPlugs,
                },
            ],
        };

        const passedDeposits = Object.values(stats.lastMonthByCompanies).map(({ passed }) => passed);
        const failedDeposits = Object.values(stats.lastMonthByCompanies).map(({ failed }) => failed);
        const numberOfPassedDeposits = Object.values(stats.lastMonthByCompanies).reduce(
            (acc, company) => acc + company.passed,
            0,
        );
        const numberOfFailedDeposits = Object.values(stats.lastMonthByCompanies).reduce(
            (acc, company) => acc + company.failed,
            0,
        );

        this.previousMonth = moment(stats.previousMonth).format('MMMM YYYY');
        const labels = Object.keys(stats.lastMonthByCompanies);

        this.stats.byCompany = {
            labels,
            passedDeposits,
            failedDeposits,
        };

        this.lastMonthDepositsChartData = {
            labels: Object.keys(stats.lastMonthByCompanies),
            datasets: [
                {
                    stack: 'ok',
                    label: `${numberOfPassedDeposits} ${this._translate('shared.deliverableValidated').toLowerCase()}`,
                    backgroundColor: '#A0D468',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: passedDeposits,
                },
                {
                    stack: 'nok',
                    label: `${numberOfFailedDeposits} ${this._translate('shared.deliverableError').toLowerCase()}`,
                    backgroundColor: '#FF7761',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: failedDeposits,
                },
            ],
        };
    }

    downloadNumberOfPlugs() {
        const line = { MOIS: 'Prises' };
        this.stats.months.forEach((month, index) => {
            line[month] = this.stats.totalOfPlugs[index] ?? 0;
        });

        return this._$excelService.downloadXLSX({ headers: true }, [line], 'stats_nombre_de_prises');
    }

    downloadLastMonthDepositsByCompany() {
        if (!this.stats.byCompany) {
            return false;
        }

        const passed = { ENTREPRISE: 'Réussis' };
        const failed = { ENTREPRISE: 'Échoués' };
        this.stats.byCompany.labels.forEach((month, index) => {
            passed[month] = this.stats.byCompany.passedDeposits[index];
            failed[month] = this.stats.byCompany.failedDeposits[index];
        });

        const filename = `stats_livrables_par_entreprise_${this.previousMonth.replace(' ', '_')}`;

        return this._$excelService.downloadXLSX({ headers: true }, [passed, failed], filename);
    }

    computeExcel(keyPassed, keyFailed, filename) {
        const passed = { MOIS: 'Réussis' };
        const failed = { MOIS: 'Échoués' };
        this.stats.months.forEach((month, index) => {
            passed[month] = this.stats[keyPassed][index];
            failed[month] = this.stats[keyFailed][index];
        });

        return this._$excelService.downloadXLSX({ headers: true }, [passed, failed], filename);
    }
}

angular.module('dotic').component('superAdminStatisticsPage', {
    templateUrl: template,
    controller: SuperAdminStatisticsPage,
});
