import defaultAvatar from '../../../../assets/images/default/avatar.svg';
import { UsersApi } from '../../../../sdk/connect-control-api-v1/src';

class UserProfileController {
    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $fileService,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        $userService,
        companyProvider,
        userMetricsProvider,
        userProvider,
    ) {
        this._$auth = $auth;
        this._$fileService = $fileService;
        this._humanizeRole = $filter('humanizeRole');
        this._translate = $filter('translate');
        this._$location = $location;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._companyProvider = companyProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._userProvider = userProvider;

        this._usersApi = new UsersApi($apiClientService.client);

        this.base64OrUrlSelectedAvatar = null;
        this.selectedAvatar = undefined;

        this.avatar = defaultAvatar;
        this.isAllowed = $authorizationService.isAllowed;
        this.user = {};
        this.passwordType = 'password';
        this.loading = true;
        this.mappedCompanies = [];
        this.connectedCompany = $userService.getConnectedCompany();
        this.connectedUserId = $userService.getConnectedUser().id;
        this.roles = [
            { key: 'admin', value: this._humanizeRole('admin') },
            { key: 'user', value: this._humanizeRole('user') },
            {
                key: 'extendedUser',
                value: this._humanizeRole('extendedUser'),
            },
        ];

        this.creditCompany = '';
        this.userCompanies = [];
    }

    async $onInit() {
        if (!this._$stateParams.userId) {
            this._$toasterService.error({
                body: this._translate('userProfile.noIdError'),
            });
            this._$state.go('app.userList');
        }

        this._userMetricsProvider.openProfile(this._$stateParams.userId);

        if (this.isAllowed(['superAdmin'])) {
            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'userProfile',
                        title: 'Profil',
                        href: this._$location.path(),
                    },
                ],
                defaultPrevious: {
                    title: 'Liste des utilisateurs',
                    href: this._$state.href('app.userList'),
                    key: 'users',
                },
                allowedPreviousKeys: ['user'],
            });
        } else {
            this._$scope.$emit('updateNavigation', {
                newPage: [
                    {
                        key: 'userProfile',
                        title: 'Mon profil',
                        href: this._$location.path(),
                    },
                ],
            });
        }

        await this.getUser(this._$stateParams.userId);

        this.showPasswordStrenghState(-1);
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
        });
    }

    async openCompaniesListModal(companies) {
        await this._$modalService.triggerListModal(
            companies.map((company) => ({
                name: company.value,
            })),
            this._translate('userProfile.companyAttached'),
        );
    }

    async getUser(userId) {
        try {
            this.user = await this._userProvider.get(userId);

            this.creditCompany = this.user.creditCompany ? this.user.creditCompany.id : null;
            this.userCompanies = this.user.companies.map((company) => company.id);
            this.base64OrUrlSelectedAvatar = this.user.avatar?.locationDist;

            if (!this.isAllowed(['superAdmin'])) {
                this.mappedCompanies = this.user.companies.map((company) => ({
                    key: company.id,
                    value: company.name,
                }));

                return;
            }

            this.mappedCompanies = (await this._companyProvider.getAll()).map((company) => ({
                key: company.id,
                value: company.name,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async setSelectedAvatar(file) {
        this.selectedAvatar = file;
        this.base64OrUrlSelectedAvatar = await this._$fileService.convertToBase64(file);
    }

    setAvatar(file) {
        if (!file) {
            return;
        }

        return this._userProvider
            .submitAvatar(file, this.user)
            .then(({ data }) => {
                this.user.avatar = data.user.avatar;
            })
            .catch(this._$toasterService.error);
    }

    deleteAvatar() {
        return this._userProvider
            .removeAvatar(this.user.id)
            .then(() => {
                this.user.avatar = null;
                angular.element('#avatar_file').val(null);
            })
            .catch(this._$toasterService.error);
    }

    updateSetting(namespace, setting) {
        return this._userProvider
            .updateSetting(this.user, namespace, setting, this.user.settings[namespace][setting])
            .catch(this._$toasterService.error);
    }

    async updateUser() {
        const user = angular.copy(this.user);

        user.companies = this.userCompanies;
        user.creditCompany = this.creditCompany;

        if (user.creditCompany) {
            if (!user.companies.find((companyId) => companyId === user.creditCompany)) {
                user.companies.push(user.creditCompany);
            }
        }

        try {
            await this._userProvider.update(user);

            if (this.selectedAvatar === null) {
                this.deleteAvatar();
            }

            if (this.selectedAvatar) {
                this.setAvatar(this.selectedAvatar);
            }

            this._$toasterService.success({
                body: this._translate('userProfile.updatedUser'),
            });
            this._$state.go('app.userList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? '' : 'password';
    }

    async updatePassword(newPassword) {
        try {
            await this._usersApi.changePasswordWithHttpInfo(`Bearer ${this._$auth.getToken()}`, this.user.id, {
                newPassword: newPassword,
            });
            this._$toasterService.success(this._translate('userProfile.updatedPassword'));

            return this._$state.go('login');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    showPasswordStrenghState(passwordStrength) {
        switch (passwordStrength) {
            case 0:
                this.passwordStrengthLabel = 'Faible';

                return;
            case 1:
                this.passwordStrengthLabel = 'Insuffisant';

                return;
            case 2:
                this.passwordStrengthLabel = 'Acceptable';

                return;
            case 3:
                this.passwordStrengthLabel = 'Bon';

                return;
            case 4:
                this.passwordStrengthLabel = 'Excellent';

                return;
            default:
                this.passwordStrengthLabel = 'Saisissez un mot de passe';

                return;
        }
    }
}

angular.module('dotic').controller('UserProfileController', UserProfileController);
