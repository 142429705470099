import template from './fillGridItemList.html';

class FillGridItemListController {
    constructor(
        $state,
        $scope,
        $excelService,
        $filter,
        $fillGridService,
        $filterService,
        $tableService,
        $toasterService,
    ) {
        this._$state = $state;
        this._$excelService = $excelService;
        this._$fillGridService = $fillGridService;
        this._$filterService = $filterService;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');

        this.fillGrid = [];
        this.filteredGrid = [];
        this.fillGridName = '';

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'attributes',
                selected: 'attribute',
                placeholder: 'filter.all.attributes',
                hasSearch: true,
                translateKeyLabel: 'shared.attribute',
                translateParamsLabel: { COUNT: 1 },
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'severities',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'relations',
                selected: 'relation',
                placeholder: 'filter.all.relation',
                hasSearch: true,
                translateKeyLabel: 'shared.relationship',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'status',
                value: 'status',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.status',
            },
            {
                allowed: true,
                name: 'tableNames',
                selected: 'table',
                placeholder: 'filter.all.table',
                hasSearch: true,
                translateKeyLabel: 'shared.table',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableTypes',
                value: 'tableType',
                placeholder: 'filter.all.tableType',
                translateKeyLabel: 'shared.tableType',
                saveValue: true,
            },
        ];

        this.filter = [];
        this.filterValues = {
            tableNames: [],
            attributes: [],
            severities: [],
            relations: [],
            tableTypes: [],
            status: [],
        };

        // *
        // Set filters from urls
        const { params } = $state;
        this.setFilters(params);
        $scope.$on('$locationChangeSuccess', () => {
            this.setFilters(params);
        });

        // *
        // Watch view changes
        const watches = [
            '$ctrl.filter.table',
            '$ctrl.filter.attribute',
            '$ctrl.filter.severity',
            '$ctrl.filter.relation',
            '$ctrl.filter.tableType',
            '$ctrl.filter.status',
            '$ctrl.filter.search',
            '$ctrl.fillGrid',
        ];

        $scope.$watchGroup(watches, () => {
            this.updateFilters();
        });
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setFilters(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            table: this._$filterService.getFilterValue(params.table),
            attribute: this._$filterService.getFilterValue(params.attribute),
            severity: this._$filterService.getFilterValue(params.severity),
            relation: this._$filterService.getFilterValue(params.relation),
            tableType: params.tableType || '',
            status: params.status || '',
            search: params.search || '',
        };
    }

    $onChanges() {
        if (!this.fillGrid || !angular.isArray(this.fillGrid)) {
            return;
        }

        this.tableDetail = this._$tableService.detail();
        this.updateTable(this.fillGrid);
    }

    onExportFillGridArchive() {
        const data = this._$fillGridService.jsonToExcel(this.fillGrid, true);

        return this._$excelService.downloadXLSX(
            { headers: true },
            data,
            this.fillGridName,
            'controlGridAdminView.downloadGrid',
        );
    }

    updateFilters() {
        if (!this.fillGrid) {
            return;
        }

        const { attribute, severity, relation, tableType, status, search, table } = this.filter;

        let regexpTableType = null;
        if (tableType && tableType !== '') {
            regexpTableType = new RegExp(tableType, 'i');
        }

        // Update url
        this._$state.go('.', {
            attribute: attribute.join(','),
            severity: severity.join(','),
            relation: relation.join(','),
            tableType,
            status,
            search,
            table: table.join(','),
        });

        // Filter list
        this.filteredGrid = this.fillGrid
            .filter(this.filterSearch(search.toLowerCase()))
            .filter(this._$filterService.genericMultiFilter(table, 'tableName'))
            .filter(this._$filterService.genericMultiFilter(attribute, 'attributName'))
            .filter(this._$filterService.genericMultiFilter(severity, 'severity'))
            .filter(this._$filterService.genericMultiFilter(relation, 'relation'))
            .filter(this.filterTableTypes(regexpTableType))
            .filter(this.filterStatus(status));
    }

    updateTable(fillGrid = []) {
        this.filterValues = {
            tableNames: this.getTableList(fillGrid),
            relations: this.getRelationList(fillGrid),
            severities: this.getSeverityList(fillGrid),
            status: this.getStatusList(fillGrid),
            attributes: this.getAttributeList(fillGrid),
            tableTypes: this.getTableTypeList(fillGrid),
        };

        this.fillGrid = fillGrid;
        this.filteredGrid = fillGrid;
    }

    /**
     * On filter change
     */

    filterStatus(status) {
        if (status === '') {
            return () => true;
        }

        const enable = status === 'true';

        return (item) => item.enable === enable;
    }

    filterSearch(search) {
        return (attr) => search === '' || attr.attributName.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    filterTableTypes(regexpTableType) {
        return (attr) => {
            if (regexpTableType === null) {
                return true;
            } else if (attr.domains) {
                return attr.domains.find((domain) => regexpTableType.test(domain.name));
            } else {
                return regexpTableType.test(attr.relation);
            }
        };
    }

    /**
     * Prepare filters
     */

    getTableTypeList() {
        return [
            { key: '^t_', value: this._translate('fillGrid.linkedTable') },
            { key: '^l_', value: this._translate('fillGrid.listedTable') },
        ];
    }

    getTableList(fillGrid = []) {
        const tableList = new Map();
        fillGrid.forEach(({ tableName }) => {
            tableList.set(tableName, tableName);
        });

        return Array.from(tableList, ([key, value]) => ({
            key,
            value,
        }));
    }

    getRelationList(fillGrid = []) {
        return fillGrid.reduce((acc, { relation }) => {
            if (relation && !acc.find((item) => item.key === relation)) {
                acc.push({
                    key: relation,
                    value: relation,
                });
            }

            return acc;
        }, []);
    }

    getSeverityList(fillGrid = []) {
        return fillGrid.reduce((acc, { severity }) => {
            if (!acc.find((item) => item.key === severity)) {
                acc.push({
                    key: severity,
                    value: this._humanizeSeverity(severity),
                });
            }

            return acc;
        }, []);
    }

    getStatusList(fillGrid = []) {
        return fillGrid.reduce((acc, { enable }) => {
            const enableKey = enable.toString();
            if (!acc.find((item) => item.key === enableKey)) {
                acc.push({
                    key: enableKey,
                    value: enable
                        ? this._translate('shared.activated', { GENDER: 'male' })
                        : this._translate('shared.disabled'),
                });
            }

            return acc;
        }, []);
    }

    getAttributeList(fillGrid = []) {
        return fillGrid.reduce((acc, { attributName }) => {
            if (!acc.find((item) => item.key === attributName)) {
                acc.push({
                    key: attributName,
                    value: attributName,
                });
            }

            return acc;
        }, []);
    }

    cancelFilters() {
        this.setFilters({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('fillGridItemList', {
    templateUrl: template,
    controller: FillGridItemListController,
    bindings: {
        fillGrid: '<',
        fillGridName: '<',
    },
});
