import template from './selectDataModelModal.html';

class SelectDataModelModal {
    constructor($scope, depositProvider, $filter) {
        this._$scope = $scope;
        this._translate = $filter('translate');

        this.action = this._translate('shared.download');
        this.name = '';
        this.controlConfigurations = [];
        this.mappedDataModelList = [];
        this.dataModelId = null;
        this.loading = true;
    }

    $onInit() {
        const { project, action } = this.resolve;

        if (project.name) {
            this.name = project.name;
        }

        if (action) {
            this.action = action;
        }

        if (project.controlConfigurations) {
            this.setDataModel(project.controlConfigurations);
        }
    }

    setDataModel(controlConf) {
        this.controlConfigurations = controlConf;

        if (!this.controlConfigurations) {
            throw new Error(
                `${this._translate('shared.none', { GENDER: 'female' })} ${this._translate('shared.controlConfiguration', { COUNT: 1 }).toLowerCase()}`,
            );
        }

        const listDataModel = this.controlConfigurations
            .filter((el) => el.dataModel)
            .map((el) => el.dataModel)
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((acc, item) => {
                const index = acc.findIndex((x) => x.id === item.id);
                if (index === -1) {
                    acc.push(item);
                }

                return acc;
            }, []);

        this.mappedDataModelList = listDataModel.map(({ id, name }) => {
            return { key: id, value: name };
        });

        if (this.mappedDataModelList) {
            this.dataModelId = this.mappedDataModelList.key;
            this.loading = false;
        }
    }

    closePopup() {
        this.close();
    }

    submit() {
        if (!this.form.$invalid) {
            return this.close({
                $value: this.dataModelId,
            });
        }
    }
}

angular.module('dotic').component('selectDataModelModal', {
    templateUrl: template,
    controller: SelectDataModelModal,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
