angular.module('dotic').factory('$mViewerService', ['$auth', 'mViewerProvider', mViewerService]);

function mViewerService($auth, mViewerProvider) {
    return {
        getServerUrl,
    };

    async function getServerUrl({ project = null, dataModelId = null, deposit = null, control = null }) {
        const mViewer = await mViewerProvider.get();
        const params = [`access_token=${$auth.getToken()}`];

        if (project) {
            params.push(`projectId=${project._id}`);
            if (dataModelId) {
                params.push(`dataModelId=${dataModelId}`);
            }

            return `${mViewer.url}/?${params.join('&')}`;
        }

        if (deposit) {
            params.push(`depositId=${deposit.id}`);
            if (control) {
                params.push(`controlId=${control.id}`);
            }

            return `${mViewer.url}/?${params.join('&')}`;
        }

        return null;
    }
}
