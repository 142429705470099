import EnvironmentSettingsService from '../../../services/environment-settings.service';
import FileService from '../../../services/file.service';
import { HelpersService } from '../../../services/helpers.service';
import template from './controlGridForm.html';

class ControlGridFormController {
    constructor(
        $excelService,
        $filter,
        $filterService,
        $gridControlService,
        $gridService,
        $modalService,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        companyProvider,
        dataModelProvider,
        gridControlProvider,
    ) {
        this._$excelService = $excelService;
        this._$gridControlService = $gridControlService;
        this._helpersService = HelpersService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._orderBy = $filter('orderBy');
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._$tableService = $tableService;
        this._$phasesService = $phaseService;
        this._$segmentsService = $segmentsService;
        this._$filterService = $filterService;
        this._$timeout = $timeout;
        this._$gridService = $gridService;
        this._$modalService = $modalService;

        this._companyProvider = companyProvider;
        this._dataModelProvider = dataModelProvider;
        this._gridControlProvider = gridControlProvider;
        this.allPhases = this._$phasesService.getAll();

        this.isOrange = EnvironmentSettingsService.isOrange();

        this.companies = [];
        this.dataModels = [];
        this.gridControlList = [];
        this.gridControlForm = {};
        this.gridControl = {
            company: [],
            conditions: [],
            data: [],
            dataModel: [],
            description: '',
            isClonable: !this.isOrange,
            isEditable: !this.isOrange,
            isTemplate: true,
            name: '',
            networkSegments: [],
            phases: [],
        };
        this.segmentsList = this._$segmentsService.getAll();
        this.textButton = null;
        this.saveGridControl = null;
        this.gridControlId = this._$stateParams.gridControlId;
        this.isButtonWaiting = false;

        this.filterOptions = [
            {
                allowed: true,
                name: 'internalIdsList',
                selected: 'internalId',
                placeholder: 'filter.all.internalId',
                hasSearch: true,
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematicList',
                value: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
            },
            {
                allowed: true,
                name: 'severityList',
                value: 'status',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'shortDescriptionList',
                value: 'shortDescription',
                placeholder: 'filter.all.controlPoint',
                translateKeyLabel: 'shared.description',
            },
            {
                allowed: true,
                name: 'typeList',
                value: 'type',
                placeholder: 'filter.all.type',
                hasSearch: true,
                translateKeyLabel: 'shared.type',
            },
            {
                allowed: true,
                name: 'segmentsList',
                selected: 'segment',
                placeholder: 'filter.all.segments',
                translateKeyLabel: 'shared.networkSegment',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'lastModificationDate',
                value: 'lastModificationDate',
                placeholder: 'shared.lastModificationDate',
                translateKeyLabel: 'shared.lastModificationDate',
                type: 'date',
            },
        ];

        this.lastModificationDateStart = this._$stateParams.lastModificationDateStart;
        this.lastModificationDateEnd = this._$stateParams.lastModificationDateEnd;

        this.filterValues = {
            severityList: [
                {
                    key: 'minor',
                    value: this._humanizeSeverity('minor'),
                },
                {
                    key: 'major',
                    value: this._humanizeSeverity('major'),
                },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            shortDescriptionList: [],
            internalIdsList: [],
            thematicList: [],
            segmentsList: this.segmentsList.map((segment) => ({
                key: segment,
                value: segment,
            })),
            typeList: [
                {
                    key: 'basic',
                    value: this._translate('shared.basic'),
                },
                {
                    key: 'advanced',
                    value: this._translate('shared.advanced', { GENDER: 'male' }),
                },
            ],
        };
        this.filteredControlGridList = [];
        this.initFilterIntoUrl($stateParams);

        $scope.$watchGroup(
            [
                '$ctrl.lastModificationDateStart',
                '$ctrl.lastModificationDateEnd',
                '$ctrl.filter.internalId',
                '$ctrl.filter.thematic',
                '$ctrl.filter.shortDescription',
                '$ctrl.filter.type',
                '$ctrl.filter.status',
                '$ctrl.filter.search',
                '$ctrl.filter.segment',
                '$ctrl.filter.lastModificationDate',
                '$ctrl.gridControlList',
            ],
            () => {
                this.setScopeGridControlViewWithFilters();
            },
        );
        $scope.$on('$locationChangeSuccess', () => this.initFilterIntoUrl($stateParams));
    }

    initFilterIntoUrl(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            search: params.search || '',
            internalId: params.internalId ? params.internalId.split(',') : [],
            status: params.status || '',
            thematic: params.thematic || '',
            type: params.type || '',
            segment: this._$filterService.getFilterValue(params.segment),
            shortDescription: params.shortDescription || '',
        };

        this.setDateParams();
    }

    setDateParams() {
        if (!this.lastModificationDateStart || !this.lastModificationDateEnd) {
            this.filter.lastModificationDate = '';

            return;
        }

        const dateFilter = this.filterOptions.find((item) => item.name === 'lastModificationDate');

        this.filter.lastModificationDate = {
            startDate: moment(this.lastModificationDateStart),
            endDate: moment(this.lastModificationDateEnd),
        };
        this._$scope.$broadcast('forceFilterRender', {
            filter: dateFilter,
            saveChanges: false,
            openDropdown: false,
        });
    }

    $onInit() {
        Promise.all([this._dataModelProvider.getList(), this._companyProvider.getCompaniesList()]).then(
            ([dataModels, companies]) => {
                this._$timeout(() => {
                    this.dataModels = dataModels;
                    this.companies = this._orderBy(companies, 'name');
                    if (this.gridControlId) {
                        this.textButton = 'shared.save';
                        this.saveGridControl = this.updateGridControl;

                        return this.getGridControl(this.gridControlId);
                    } else {
                        this.textButton = 'shared.create';
                        this.saveGridControl = this.createGridControl;

                        this._$scope.$emit('keepPreviousNavigation', {
                            newPage: [
                                {
                                    key: 'controlGridFormCreate',
                                    title: `${this._translate('shared.create')} ${this._translate(
                                        'controlGridForm.controlGrid',
                                    )}`,
                                },
                            ],
                            allowedPreviousKeys: ['controlGrids'],
                        });
                    }
                });
            },
        );
    }

    async importFile(file) {
        if (file?.size < FileService.excelMaxSize) {
            return this.processFile(file);
        }

        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'shared.warning',
            'shared.canImportFile',
            {
                SIZE: file?.size,
            },
        );

        if (!isAccepted) {
            return;
        }

        await this.processFile(file);
    }

    async processFile(file) {
        this.loading = true;
        // Convert excel MS date to date js
        const query =
            'SELECT * , datetime(LAST_MODIFICATION_DATE) as LAST_MODIFICATION_DATE_converted FROM XLSX(?, {autoExt: false})';

        const data = await this._$excelService.importXLSX(URL.createObjectURL(file), query);
        if (!data?.length) {
            this.loading = false;

            return;
        }

        try {
            const json = this._$gridControlService.excelToJson(data);

            this.gridControlList = json.controls.filter((control) => control.thematicName !== 'condition');
            this.gridControl.conditions = json.controls
                .filter((control) => control.thematicName === 'condition')
                .map((control) => ({
                    conditionName: control.controlPoint,
                    defaultVariable: control.defaultVariable,
                    description: control.description,
                    enable: true,
                    shortDescription: control.shortDescription,
                    variable: control.variable,
                }));

            this.gridControlList.forEach((gridControl, index) => {
                this.gridControlList[index].index = index;
            });
            this.gridControl.data = this.gridControlList;
            this.tableDetail = this._$tableService.detail();
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this.loading = false;
        }
    }

    static filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    setScopeGridControlViewWithFilters() {
        const search = this.filter.search.toLowerCase();

        this._$state.go('.', {
            search: search,
            status: this.filter.status,
            thematic: this.filter.thematic,
            type: this.filter.type,
            shortDescription: this.filter.shortDescription,
            internalId: this.filter.internalId.join(','),
            segment: this.filter.segment.join(','),
            lastModificationDateStart: this.filter.lastModificationDate?.startDate,
            lastModificationDateEnd: this.filter.lastModificationDate?.endDate,
        });

        this.filteredControlGridList = this.gridControlList
            .filter(ControlGridFormController.filterSearch(search))
            .filter(this._$filterService.filterSegments(this.filter.segment))
            .filter(this._$filterService.genericFilter(this.filter.status, 'severity'))
            .filter(this._$filterService.genericFilter(this.filter.shortDescription, 'shortDescription'))
            .filter(this._$filterService.genericFilter(this.filter.thematic, 'thematicName'))
            .filter(this._$filterService.genericFilter(this.filter.type, 'offer'))
            .filter(this._$filterService.filterControlPoint(this.filter.internalId))
            .filter(this._$filterService.filterRangeDate(this.filter.lastModificationDate, 'lastModificationDate'));
    }

    async getGridControl(gridControlId) {
        try {
            this.gridControl = await this._gridControlProvider.get(gridControlId);

            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'controlGridForm',
                        title: `${this._translate('shared.edit')} ${this._translate('controlGridForm.controlGrid')}`,
                    },
                ],
                defaultPrevious: {
                    key: 'controlGrids',
                    title: this._translate('shared.controlGrid', {
                        TYPE: this.gridControl.isTemplate ? 'template' : 'working',
                    }),
                    href: this._$state.href(
                        this.gridControl.isTemplate ? 'app.gridControlList' : 'app.gridControlListCompanies',
                    ),
                },
                allowedPreviousKeys: ['controlGrids', 'controlGrid'],
            });

            this.refreshData();
        } catch (e) {
            this._$toasterService.error(e);
        }
    }

    refreshData() {
        this.gridControl.data = this.gridControl.data.map((control, index) => {
            return { ...control, index };
        });
        this.gridControlList = this.gridControl.data;
        this.tableDetail = this._$tableService.detail();
        this.prepareControlGrid(this.gridControl);
    }

    prepareControlGrid(gridControl) {
        this._$timeout(() => {
            this.gridControlList = gridControl.data;

            const internalIdsList = new Map();
            const thematicList = new Map();
            const shortDescriptionList = new Map();

            this.gridControlList.forEach(({ thematicName, shortDescription, controlPoint }) => {
                thematicList.set(thematicName, this._humanizeThematic(thematicName));
                internalIdsList.set(controlPoint, controlPoint);
                if (angular.isString(shortDescription)) {
                    shortDescriptionList.set(shortDescription, shortDescription);
                }
            });

            this.filterValues.thematicList = Array.from(thematicList, ControlGridFormController.mapToObject);
            this.filterValues.shortDescriptionList = Array.from(
                shortDescriptionList,
                ControlGridFormController.mapToObject,
            );
            this.filterValues.internalIdsList = Array.from(internalIdsList, ControlGridFormController.mapToObject);
        });
    }

    static mapToObject([key, value]) {
        return {
            key,
            value,
        };
    }

    async createGridControl() {
        if (!(await this.isSaveAccepted())) {
            return;
        }

        if (!this.gridControlList.length) {
            return this._$toasterService.error({
                body: this._translate('controlGridForm.importRequired'),
            });
        }

        this.isButtonWaiting = true;
        const gridControl = angular.copy(this.gridControl);
        gridControl.data = angular.copy(this.gridControlList);

        try {
            await this._gridControlProvider.create(gridControl);
            this._$toasterService.success(this._translate('shared.savedGrid'));
            this._$state.go('app.gridControlList');
        } catch (error) {
            if (!error.data) {
                this._$toasterService.error(error);

                return;
            }

            if (error.data.code === 'NAME_DUPLICATE') {
                this._$toasterService.error({ body: error.data.message });

                return;
            }

            this._$gridService.displayDuplicatedError(error.data, 10);
        } finally {
            this.isButtonWaiting = false;
        }
    }

    isSaveAccepted() {
        if (this.gridControl.isClonable === !this.isOrange && this.gridControl.isEditable === !this.isOrange) {
            return true;
        }

        return this._$modalService.triggerConfirmationModal('shared.save', 'shared.differentActionConfirm');
    }

    async updateGridControl() {
        if (!(await this.isSaveAccepted())) {
            return;
        }

        this.isButtonWaiting = true;
        const gridControl = angular.copy(this.gridControl);
        gridControl.data = angular.copy(this.gridControlList);

        try {
            await this._gridControlProvider.update(this.gridControlId, gridControl);
            this._$toasterService.success(this._translate('shared.savedGrid'));
            this._$state.go('app.gridControlList');
        } catch (error) {
            if (!error.data) {
                this._$toasterService.error(error);

                return;
            }

            if (error.data.code === 'NAME_DUPLICATE') {
                this._$toasterService.error({ body: error.data.message });

                return;
            }

            this._$gridService.displayDuplicatedError(error.data, 10);
        } finally {
            this.isButtonWaiting = false;
        }
    }

    toggleCondition(condition) {
        if (this.selectedCondition === condition) {
            this.selectedCondition = null;
        } else {
            this.selectedCondition = condition;
        }
    }

    setDataModel(value) {
        const { dataModel } = this.gridControl;

        if (dataModel.indexOf(value) > -1) {
            this.gridControl.dataModel = dataModel.filter((dataModel) => dataModel !== value);
        } else {
            this.gridControl.dataModel = [...dataModel, value];
        }
    }

    listDataModels(dataModels) {
        if (!dataModels || dataModels.length === 0) {
            return '';
        }

        return dataModels
            .map((dataModel) => this.dataModels.find((d) => d.id === dataModel))
            .filter((dataModel) => dataModel)
            .map((dataModel) => dataModel.name)
            .join(', ');
    }

    setCompany(value) {
        const { company } = this.gridControl;

        if (company.indexOf(value) > -1) {
            this.gridControl.company = company.filter((company) => company !== value);
        } else {
            this.gridControl.company = [...company, value];
        }
    }

    listCompanies(companies) {
        return (companies || [])
            .map((company) => this.companies.find(({ id }) => id === company))
            .filter((company) => company)
            .map(({ name }) => name)
            .join(', ');
    }

    setPhase(item, phase) {
        item.phases = item.phases.map((p) => ({
            ...p,
            isEnabled: p.phaseName === phase ? !p.isEnabled : p.isEnabled,
        }));
    }

    setSeverity(item, severity) {
        item.severity = severity;
    }

    getThematicList() {
        const thematicList = [];
        this.gridControl.data.forEach(({ thematicName }) => {
            if (!thematicList.includes(thematicName)) {
                thematicList.push(thematicName);
            }
        });

        return thematicList;
    }

    getObjectList() {
        const objectList = [];
        this.gridControl.data.forEach(({ object }) => {
            if (!objectList.includes(object)) {
                objectList.push(object);
            }
        });

        return objectList;
    }

    async openAddControlPointPopup() {
        if (this.filteredControlGridList.length === 0) {
            return;
        }

        const formData = await this._$modalService.triggerControlPointFormModal(
            this.getThematicList(),
            this.getObjectList(),
        );

        if (!formData) {
            return;
        }

        this.gridControl.data = [...this.gridControl.data, formData];
        this.refreshData();
    }

    async openEditControlPopup(item) {
        const formData = await this._$modalService.triggerControlPointFormModal(
            this.getThematicList(),
            this.getObjectList(),
            item.index,
            item,
        );

        if (!formData) {
            return;
        }

        this.gridControl.data[formData.index] = formData;
        this.refreshData();
    }

    async removeControl(item) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlPoint', {
                NAME: item.controlPoint,
            }),
        );
        if (!isAccepted) {
            return;
        }

        this.gridControl.data.splice(item.index, 1);
        this.refreshData();
    }

    cancelFilters() {
        this.initFilterIntoUrl({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('controlGridFormPage', {
    controller: ControlGridFormController,
    templateUrl: template,
});
