angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.sitesList', {
        url: '/sites',
        component: 'sitePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.siteDetail', {
        url: '/sites/{siteId:[a-f0-9]+}',
        component: 'siteDetailPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
});
