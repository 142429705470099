import template from './chartList.html';

angular.module('dotic').component('ccChartList', {
    templateUrl: template,
    bindings: {
        value: '<',
        text: '@',
        icon: '@',
        color: '@',
    },
});
