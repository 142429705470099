import template from './importExcelModal.html';

class ImportExcelModal {
    constructor($toasterService, $excelService, $filter) {
        this._$excelService = $excelService;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this.file = null;
    }

    $onInit() {
        this.isDeliveryZoneImport = this.resolve.isDeliveryZoneImport;
    }

    closePopup() {
        this.close();
    }

    cancelPopup() {
        this.dismiss();
    }

    async importFile() {
        if (!this.file) {
            return;
        }

        let query;
        if (this.isDeliveryZoneImport) {
            query = 'SELECT *, datetime(START_DATE) AS CONVERTED_DATE  FROM XLSX(?, {autoExt: false})';
        } else {
            query =
                'SELECT datetime(DATE_DE_DEBUT) as CONVERTED_START_DATE, datetime(DATE_DE_FIN) as CONVERTED_END_DATE,  *  FROM XLSX(?, {autoExt: false})';
        }

        const data = await this._$excelService.importXLSX(URL.createObjectURL(this.file), query);
        this.close({ $value: data });
    }
}

angular.module('dotic').component('importExcelModal', {
    templateUrl: template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    controller: ImportExcelModal,
});
