import template from './depositListModalFilling.html';

class DepositListModalFillingController {
    constructor($state) {
        this._$state = $state;
    }

    $onInit() {
        this.deposits = this.resolve.deposits;
    }

    viewReport(item) {
        this.dismiss();

        return this._$state.go('app.reportDetail', { reportId: item.report });
    }
}

angular.module('dotic').component('depositListModalFilling', {
    templateUrl: template,
    controller: DepositListModalFillingController,
    bindings: {
        resolve: '<',
        dismiss: '&',
    },
});
