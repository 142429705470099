angular.module('dotic').constant('URI_KEYS', {
    AGGREGATION: 'aggregations',
    AGGREGATOR: 'aggregators',
    COMPANY: 'companies',
    CONTROL: 'controls',
    CONTROL_CONFIGURATION: 'controlConfiguration',
    DASHBOARD_EXTENDED: 'dashboardExtended',
    DATA_MODEL: 'dataModel',
    DELIVERY_ZONE: 'deliveryZones',
    DEPOSIT: 'deposits',
    DEPOSITS: 'deposits',
    FILL_GRID: 'fillGrids',
    FME_SERVER: 'fmeServers',
    GRID_CONTROL: 'controlGrids',
    HEALTH: 'health',
    HISTORY: 'history',
    HISTORY_FILLING_STATISTICS: 'historyFillingStatistics',
    IMPORT: 'imports',
    INTERVENTION_GRID: 'interventionGrids',
    JOB: 'jobs',
    LANDING: 'landing',
    MVIEWER: 'mviewer',
    NAMESPACE: 'namespace',
    PHASE: 'phases',
    POST_PROCESSING: 'postProcessings',
    PROJECT: 'projects',
    READ: 'read',
    REPORT: 'reports',
    REPOSITORY: 'repositories',
    SERVER: 'servers',
    SETTING: 'setting',
    SITE: 'sites',
    SUPERVISION: 'supervision',
    SUPER_ADMIN: 'superAdmin',
    SYNOPTIC: 'synoptic',
    TEMPLATE: 'templates',
    TRANSFORMATION: 'transformations',
    USER: 'users',
    USER_METRIC: 'userMetrics',
});
