class FillGridUnsavedController {
    constructor($uibModalInstance) {
        this._$uibModalInstance = $uibModalInstance;
    }

    submit() {
        this._$uibModalInstance.close();
    }

    cancel() {
        this._$uibModalInstance.dismiss();
    }
}

angular.module('dotic').controller('FillGridUnsavedController', FillGridUnsavedController);
