angular.module('dotic').factory('mViewerProvider', mViewerProvider);

function mViewerProvider(RestProvider, URI_KEYS) {
    return {
        get,
    };

    function get(query = {}) {
        return RestProvider.get(URI_KEYS.MVIEWER, query);
    }
}
