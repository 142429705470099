import template from './attributesListModal.html';

class AttributesListModalComponent {
    constructor($scope) {
        this._$scope = $scope;

        this.controlPoint = {};
        this.attributes = [];
    }

    $onInit() {
        const controlPoint = this.resolve.controlPoint;

        this.controlPoint = controlPoint;
        this.attributes = this.controlPoint.attributes;
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('attributesListModal', {
    templateUrl: template,
    controller: AttributesListModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
