import template from './projectList.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.projectList', {
            url: '/projects?active&controlConfiguration&deliveryZone&subCompany&search&p',
            params: {
                active: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                controlConfiguration: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                deliveryZone: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                subCompany: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: template,
            controller: 'ProjectListController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.projectDetail', {
            url: '/projects/:projectId',
            component: 'projectDetailPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.projectCreate', {
            url: '/projects/create',
            component: 'projectFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.projectDuplicate', {
            url: '/projects/:projectId/duplicate',
            component: 'projectFormPage',
            params: {
                duplicate: true,
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.projectEdit', {
            url: '/projects/:projectId/edit',
            component: 'projectFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        });
});
