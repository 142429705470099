import template from './severity.html';

class SeverityController {
    constructor($filter) {
        this._humanizeSeverity = $filter('humanizeSeverity');

        this.severity = 'default';
        this.showLabel = false;
        this.customTooltip = null;

        this.label = '';
        this.tooltip = '';
    }

    $onInit() {
        if (!this.severity) {
            this.severity = 'default';
        }

        if (angular.isUndefined(this.showLabel)) {
            this.showLabel = true;
        }

        this.label = this._humanizeSeverity(this.severity);
        this.tooltip = this.label;

        if (this.customTooltip) {
            this.tooltip = `${this.tooltip} : ${this.customTooltip}`;
        }
    }
}

angular.module('dotic').component('severity', {
    templateUrl: template,
    controller: SeverityController,
    bindings: {
        severity: '<',
        showLabel: '<',
        customTooltip: '@',
    },
});
