import template from './notificationList.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.notificationList', {
        url: '/notifications',
        templateUrl: template,
        controller: 'NotificationListController as $ctrl',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin', 'admin', 'user', 'extendedUser'],
        },
    });
});
