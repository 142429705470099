import { formTypeEnum } from '../../../models/form.model';
import { HelpersService } from '../../../services/helpers.service';
import template from './server-form.component.html';

class ServerFormComponent {
    geometryList = [];
    isAdminPasswordVisible = false;
    isCreationForm = false;
    isReadonlyPasswordVisible = false;
    keyValueCompanies = [];
    loading = false;
    newPage = [];
    required = {
        companies: false,
        projection: false,
    };
    serverForm = {};
    serverFormValue = {
        name: '',
        companies: '',
        database: {
            host: '',
            name: '',
            password: '',
            passwordReadonly: '',
            port: '',
            directPort: '',
            projection: '',
            user: '',
            userReadonly: '',
        },
    };
    textButton = '';
    title = '';

    constructor(
        $filter,
        $geometryService,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        $timeout,
        companyProvider,
        serverProvider,
    ) {
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._companyProvider = companyProvider;
        this._serverProvider = serverProvider;
        this._translate = $filter('translate');

        this.geometryList = Array.from($geometryService.getAll(), ([key, label]) => ({
            key: `${key}`,
            value: label,
        }));
    }

    async $onInit() {
        await this.setPage(this._$stateParams.formType);

        this.keyValueCompanies = await this.fetchKeyValueCompanies();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
            // Previous line force a rerender of the page, which force a trigger of the company and geometry input ng-change.
            // This result in a trigger of both onChangeCompanyField and onChangeProjectionField.
            // Setting required properties to true.
            this.required.companies = false;
            this.required.projection = false;
        });

        this._$scope.$emit('keepPreviousNavigation', {
            allowedPreviousKeys: ['servers'],
            defaultPrevious: {
                href: this._$state.href('app.serverList'),
                key: 'servers',
                title: this._translate('shared.server'),
            },
            newPage: this.newPage,
        });
    }

    async createServer() {
        try {
            await this._serverProvider.createServer({
                ...this.serverFormValue,
                database: {
                    ...this.serverFormValue.database,
                    sgbd: 'postgresql',
                },
            });
            this._$toasterService.success({
                body: this._translate('serverForm.creationSuccess'),
            });
        } catch {
            this._$toasterService.error({
                body: this._translate('shared.errorCreation'),
            });
        }
    }

    async fetchKeyValueCompanies() {
        try {
            const companies = await this._companyProvider.getAll();

            return HelpersService.toKeyValue(companies);
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    async initEditFormValue() {
        try {
            if (this._$stateParams.formType !== formTypeEnum.EDITION) {
                return;
            }

            const server = await this._serverProvider.getDetail(this._$stateParams.serverId);

            this.serverFormValue = {
                ...server,
                companies: server.companies.map((company) => company.id) || null,
                database: {
                    ...server.database,
                    projection: `${server.database.projection}`,
                },
            };
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    onChangeCompanyField() {
        this.required.companies = !this.serverFormValue.companies.length;
    }

    onChangeProjectionField() {
        this.required.projection = !this.serverFormValue.database.projection;
    }

    async onSubmit() {
        this.onChangeCompanyField();
        this.onChangeProjectionField();

        if (!this.serverForm.$valid || this.required.companies || this.required.projection) {
            this.serverForm.name.$touched = true;
            this.serverForm.databaseName.$touched = true;
            this.serverForm.databaseHost.$touched = true;
            this.serverForm.databasePort.$touched = true;
            this.serverForm.databaseDirectPort.$touched = true;
            this.serverForm.databaseUser.$touched = true;
            this.serverForm.databasePassword.$touched = true;
            this.serverForm.databaseUserReadonly.$touched = true;
            this.serverForm.databasePasswordReadonly.$touched = true;

            return;
        }

        switch (this._$stateParams.formType) {
            case formTypeEnum.EDITION:
                await this.updateServer();
                break;
            case formTypeEnum.CREATION:
            case formTypeEnum.DUPLICATION:
            default:
                await this.createServer();
        }

        this._$state.go('app.serverList');
    }

    async setPage(formType) {
        switch (formType) {
            case formTypeEnum.EDITION:
                this.isCreationForm = false;
                await this.initEditFormValue();
                this.title = this._translate('shared.editServer');
                this.textButton = this._translate('shared.save');
                this.newPage = [
                    {
                        href: this._$state.href('app.serverEdit'),
                        key: 'serverEdit',
                        title: this.title,
                    },
                ];

                break;
            case formTypeEnum.CREATION:
            case formTypeEnum.DUPLICATION:
            default:
                this.isCreationForm = true;
                this.title = this._translate('shared.createStorageBase');
                this.textButton = this._translate('shared.create');
                this.newPage = [
                    {
                        href: this._$state.href('app.serverCreate'),
                        key: 'serverCreate',
                        title: this.title,
                    },
                ];
        }
    }

    async updateServer() {
        try {
            await this._serverProvider.updateServer(this.serverFormValue.id, this.serverFormValue);
            this._$toasterService.success({
                body: this._translate('serverForm.editionSuccess'),
            });
        } catch {
            this._$toasterService.error({
                body: this._translate('shared.errorUpdate'),
            });
        }
    }

    setAdminPassword(newPassword) {
        this.serverFormValue.database.password = newPassword;
    }

    setReadonlyPassword(newPassword) {
        this.serverFormValue.database.passwordReadonly = newPassword;
    }

    toggleAdminPasswordVisibility() {
        this.isAdminPasswordVisible = !this.isAdminPasswordVisible;
    }

    toggleReadonlyPasswordVisibility() {
        this.isReadonlyPasswordVisible = !this.isReadonlyPasswordVisible;
    }
}

angular.module('dotic').component('serverFormComponent', {
    controller: ServerFormComponent,
    templateUrl: template,
});
