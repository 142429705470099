import template from './deliveryZoneList.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.deliveryZoneList', {
            url: '/deliveryZone/list?server&project&projectId&subCompany&search&p',
            templateUrl: template,
            controller: 'DeliveryZoneListController as $ctrl',
            params: {
                server: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                project: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                subCompany: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                projectId: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.deliveryZoneDetail', {
            url: '/deliveryZone/:deliveryZoneId',
            component: 'deliveryZoneDetailPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.deliveryZoneCreate', {
            url: '/deliveryZone/create',
            component: 'deliveryZoneFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.deliveryZoneEdit', {
            url: '/deliveryZone/:deliveryZoneId/edit',
            component: 'deliveryZoneFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.deliveryZoneDuplicate', {
            url: '/deliveryZone/:deliveryZoneId/duplicate',
            component: 'deliveryZoneFormPage',
            params: {
                duplicate: true,
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        });
});
