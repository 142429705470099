angular.module('dotic').factory('healthProvider', healthProvider);

function healthProvider(ApiProvider, URI_KEYS) {
    return {
        getStatus,
    };

    /**
     * POST /status
     * @returns {*}
     */
    function getStatus() {
        return ApiProvider.one(`${URI_KEYS.HEALTH}/status`).get();
    }
}
