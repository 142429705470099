import * as radash from 'radash';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

import template from './multiDoughnutchart.html';

function parseMultiChartDataForLegend(chart) {
    if (!chart) {
        return [];
    }

    const data = [];
    const dataSet = chart.data.datasets[0];
    const data2Set = chart.data.datasets[1];

    if (!dataSet) {
        return [];
    }

    const total1 = radash.sum(dataSet.data);
    const total2 = radash.sum(data2Set.data);

    if (chart.data.datasets.length > 1) {
        for (let k = 0; k < dataSet.data.length; k++) {
            data.push({
                color: dataSet.backgroundColor[k],
                percentage1: Math.round((100 * 100 * dataSet.data[k]) / total1) / 100,
                value1: dataSet.data[k],
                label: chart.data.labels[k],
                total1: total1,
            });
        }
        for (let l = 0; l < data2Set.data.length; l++) {
            data.push({
                color: data2Set.backgroundColor[l],
                percentage2: Math.round((100 * 100 * data2Set.data[l]) / total2) / 100,
                value2: data2Set.data[l],
                label: chart.data.labels[l],
                total2: total2,
            });
        }
    }

    return data;
}

class CcMultiDoughnutChartController {
    constructor($element, $timeout) {
        this._$timeout = $timeout;
        this._$element = $element;

        if (angular.isUndefined(this.absolute)) {
            this.absolute = false;
        }

        this.options = {
            responsive: true,
            tooltips: { opacity: 1, position: 'nearest' },
            maintainAspectRatio: false,
            cutout: '67%',
            elements: {
                arc: {
                    borderWidth: 2,
                    borderColor: '#FFFFFF',
                },
            },
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: { display: false },
            },
        };

        this.chartId = `id${Math.random().toString(16).replace('.', '')}`;
    }

    $onChanges() {
        let size = 244;
        if (angular.isDefined(this.size)) {
            size = parseInt(this.size, 10);
        }

        if (this.notResponsive) {
            this.options.responsive = false;
        }

        this._$timeout(() => {
            const chartElement = this._$element.find(`#${this.chartId}`);
            // Avoid unexpected resizing
            const container = this._$element.find('.cc-chart-container')[0];
            container.style.minHeight = `${size}px`;

            if (chartElement.length > 0) {
                const doughnutChart = chartElement.get(0).getContext('2d');

                if (this.chart) {
                    this.chart.destroy();
                }

                this.chart = new Chart(doughnutChart, {
                    type: 'doughnut',
                    data: this.data,
                    size: size,
                    options: this.options,
                    plugins: [ChartDataLabels],
                });

                this.legends = parseMultiChartDataForLegend(this.chart);

                const checkData = this.data.datasets[0];
                const checkDataSize = checkData.data.length;
                this.legendsCompared = [];

                const legends1 = this.legends.slice(0, checkDataSize);
                const legends2 = this.legends.slice(checkDataSize);

                this.legendsCompared = this.getLegendCompare(legends1, legends2);
            }
        });
    }

    getLegendCompare(data1, data2) {
        this.legendeDifference = data1
            .filter((d1) => {
                return data2.find((d2) => {
                    return d1.label === d2.label;
                });
            })
            .map((el) => {
                let isSamePercentage = false;
                let isSameValue = false;
                let isSameTotal = false;
                let isAnomalyLabel = false;

                const compareData2 = data2.find((el2) => {
                    return el2.label === el.label;
                });

                data2.find((data) => {
                    if (data.label === el.label) {
                        if (el.percentage1 === data.percentage2) {
                            isSamePercentage = true;
                        }

                        if (el.value1 === data.value2) {
                            isSameValue = true;
                        }

                        if (el.total1 === data.total2) {
                            isSameTotal = true;
                        }

                        const anomalies = [
                            'Pts de contrôles en anomalie',
                            'Anomalies',
                            'Pts de contrôles inopérants',
                            'Pts de contrôles conditions non remplies',
                            'Contrôles en anomalie',
                        ];
                        isAnomalyLabel = anomalies.indexOf(el.label) > -1;
                    }
                });

                return {
                    ...el,
                    diff: {
                        compareData2,
                        isSamePercentage,
                        isSameValue,
                        isSameTotal,
                        isAnomalyLabel,
                    },
                };
            });

        return this.legendeDifference;
    }
}

angular.module('dotic').component('ccMultiDoughnutChart', {
    templateUrl: template,
    controller: CcMultiDoughnutChartController,
    controllerAs: '$ctrl',
    bindings: {
        data: '<',
        size: '@',
        absolute: '@',
        notResponsive: '<',
        hideData: '<',
        nameOrigin: '@',
        nameToCompare: '@',
    },
});
