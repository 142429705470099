angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.dashboardExtended', {
        url: '/dashboard-extended?phase&company&user&timeRange&groupByLastVersion',
        params: {
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            user: {
                value: '',
                squash: true,
                dynamic: true,
            },
            timeRange: {
                value: '',
                squash: true,
                dynamic: true,
            },
            groupByLastVersion: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'dashboardExtendedPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
