import EnvironmentSettingsService from '../../../services/environment-settings.service';
import fillGridUnsavedTemplate from '../unsavedModal/fillGridUnsaved.html';
import template from './fillGridAdminForm.html';

class FillGridAdminFormPage {
    constructor(
        $auth,
        $filter,
        $filterService,
        $modalService,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        $uibModal,
        dataModelProvider,
        fillGridProvider,
    ) {
        this._$auth = $auth;
        this._$filterService = $filterService;
        this._$rootScope = $rootScope;
        this._$scope = $scope;
        this._$timeout = $timeout;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$toasterService = $toasterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$uibModal = $uibModal;
        this._fillGridProvider = fillGridProvider;
        this._dataModelProvider = dataModelProvider;
        this.isOrange = EnvironmentSettingsService.isOrange();
        this.isBottomFilter = true;
        this._$modalService = $modalService;
        this.filterOptions = [
            {
                allowed: true,
                name: 'severityList',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseList',
                selected: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseActivatedList',
                value: 'phaseActivated',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.state',
            },
            {
                allowed: true,
                name: 'relationList',
                selected: 'relation',
                placeholder: 'filter.all.relation',
                hasSearch: true,
                translateKeyLabel: 'shared.relationship',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'requiredList',
                selected: 'required',
                placeholder: 'filter.all.status',
                translateKeyLabel: 'shared.status',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableList',
                selected: 'table',
                placeholder: 'filter.all.table',
                hasSearch: true,
                translateKeyLabel: 'shared.table',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableTypes',
                value: 'tableType',
                placeholder: 'filter.all.tableType',
                translateKeyLabel: 'shared.tableType',
            },
        ];

        this.filterValues = {
            severityList: [
                {
                    key: 'minor',
                    value: this._humanizeSeverity('minor'),
                },
                {
                    key: 'major',
                    value: this._humanizeSeverity('major'),
                },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            phaseList: [],
            phaseActivatedList: [
                {
                    key: 'activated',
                    value: this._translate('shared.activated', { GENDER: 'male' }),
                },
                {
                    key: 'disable',
                    value: this._translate('shared.disabled'),
                },
            ],
            relationList: [],
            requiredList: [
                {
                    key: 'required',
                    value: this._translate('status.required', { COUNT: 1 }),
                },
                {
                    key: 'optional',
                    value: this._translate('status.optional', { COUNT: 1 }),
                },
                {
                    key: 'noRequired',
                    value: this._translate('status.noRequired', { COUNT: 1 }),
                },
            ],
            tableList: [],
            tableTypes: [
                {
                    key: '^t_',
                    value: this._translate('fillGrid.linkedTable'),
                },
                {
                    key: '^l_',
                    value: this._translate('fillGrid.listedTable'),
                },
            ],
        };

        this.fillGrid = {};
        this.fillGridList = [];
        this.fillGridFiltered = [];
        this.fillGridDetailsShown = null;
        this.setDefaultFilter($stateParams);

        this.fillGridId = this._$stateParams.gridFillId;
        this.isButtonWaiting = false;
        this.loading = true;

        this._$scope.$watch('$ctrl.fillGrid.fullname', () => {
            this._$rootScope.pageTitle = [
                {
                    title: this._translate('shared.fillGrid'),
                    href: this._$state.href('app.gridFillAdminList'),
                },
                {
                    title: this.fillGrid.fullname,
                    href: this._$state.href('app.fillGridView', {
                        gridFillId: this.fillGridId,
                    }),
                },
            ];
        });

        this._$scope.$watch(
            '$ctrl.fillGridList',
            () => {
                if (
                    angular.isDefined(this.fillGridList) &&
                    angular.isArray(this.fillGridList) &&
                    this.fillGridList.length > 0
                ) {
                    this.phasesEnabledList = this.getPhasesListWithCountEnabled(this.fillGridList);
                }
            },
            true,
        );

        this._$scope.$watchGroup(
            [
                '$ctrl.fillGridList',
                '$ctrl.filter.severity',
                '$ctrl.filter.tableType',
                '$ctrl.filter.phase',
                '$ctrl.filter.table',
                '$ctrl.filter.search',
                '$ctrl.filter.relation',
                '$ctrl.filter.phaseActivated',
                '$ctrl.filter.required',
            ],
            () => this.filterGrid(),
        );
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setDefaultFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            tableType: params.tableType || '',
            severity: this._$filterService.getFilterValue(params.severity),
            phase: this._$filterService.getFilterValue(params.phase),
            relation: this._$filterService.getFilterValue(params.relation),
            search: params.search || '',
            table: this._$filterService.getFilterValue(params.table),
            phaseActivated: params.phaseActivated || '',
            required: this._$filterService.getFilterValue(params.required),
        };
    }

    $onInit() {
        Promise.all([this.getDataModels(), this.getFillGrid(this.fillGridId)]).then(() => {
            this.filterGrid();
            this._$timeout(() => {
                this.loading = false;
            });
        });
    }

    static filterByPhases(phaseActivated) {
        if (phaseActivated === 'activated') {
            return (control) => control.phases.some((phase) => phase.isEnabled);
        } else if (phaseActivated === 'disable') {
            return (control) => control.phases.every((phase) => !phase.isEnabled);
        }

        return () => true;
    }

    filterTableTypes(regexpTableType) {
        return (attr) => regexpTableType === null || regexpTableType.test(attr.relation);
    }

    filterGrid() {
        const { severity, phase, search, table, relation, phaseActivated, tableType, required } = this.filter;

        let regexpTableType = null;
        if (tableType && tableType !== '') {
            regexpTableType = new RegExp(tableType, 'i');
        }

        this.fillGridFiltered = this.fillGridList
            .filter(FillGridAdminFormPage.filterByPhases(phaseActivated))
            .filter(this._$filterService.genericMultiFilter(table, 'tableName'))
            .filter(this._$filterService.genericMultiFilter(relation, 'relation'))
            .filter(this._$filterService.genericMultiFilter(severity, 'severity'))
            .filter(
                (attr) =>
                    phase.length === 0 ||
                    phase.some((onePhase) => attr.phases.find((p) => p.phaseName === onePhase).isEnabled === true),
            )
            .filter(this._$filterService.genericMultiFilter(required, 'required'))
            .filter(this.filterTableTypes(regexpTableType))
            .filter(
                (fillGrid) =>
                    search === '' ||
                    (fillGrid.attributName || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    (fillGrid.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    (fillGrid.tableName || '').toLowerCase().indexOf(search.toLowerCase()) > -1,
            );
    }

    async getFillGrid(fillGridId) {
        try {
            const fillGrid = await this._fillGridProvider.get(fillGridId);
            this.fillGrid = angular.copy(fillGrid);
            this.loadedFillGrid = angular.copy(fillGrid);
            this.prepareData();

            const phaseList = new Map();
            const tableList = new Map();
            const relationList = new Map();

            fillGrid.data[0].phases.forEach(({ phaseName }) => {
                phaseList.set(phaseName, phaseName);
            });
            fillGrid.data.forEach(({ relation, tableName }) => {
                tableList.set(tableName, tableName);
                if (relation) {
                    relationList.set(relation, relation);
                }
            });

            this.filterValues.phaseList = Array.from(phaseList, ([key, value]) => ({ key, value }));
            this.filterValues.tableList = Array.from(tableList, ([key, value]) => ({ key, value }));
            this.filterValues.relationList = Array.from(relationList, ([key, value]) => ({ key, value }));
        } catch (e) {
            this._$toasterService.error(e);
        }
    }

    prepareData() {
        this.fillGridList = this.fillGrid.data.map((attribute) => ({
            ...attribute,
            required: attribute.required || 'noRequired',
        }));
    }

    async getDataModels() {
        try {
            this.dataModels = await this._dataModelProvider.getList();
        } catch (e) {
            this._$toasterService.error(e);
        }
    }

    updateFillGrid() {
        if (this.fillGridForm.$invalid) {
            this.fillGridForm.description.$touched = true;
            this.fillGridForm.name.$touched = true;
            this.fillGridForm.version.$touched = true;

            return;
        }

        this.isButtonWaiting = true;
        const fillGrid = angular.copy(this.fillGrid);
        fillGrid.data = angular.copy(this.fillGridList);

        this._fillGridProvider
            .update(fillGrid.id, fillGrid)
            .then(() => {
                this._$state.go('app.fillGridView', {
                    gridFillId: fillGrid.id,
                });
                this._$toasterService.success('La grille a bien été sauvegardée');
            })
            .catch((e) => {
                this.isButtonWaiting = false;
                if (e.data.code === 'NAME_DUPLICATE') {
                    this.fillGridForm.name.$setValidity('duplicate', false);
                    this.fillGridForm.name.$touched = true;
                } else {
                    this._$toasterService.error(e);
                }
            });
    }

    unsetError() {
        this.fillGridForm.name.$setValidity('duplicate', true);
    }

    goBack() {
        const newFillGrid = { ...this.fillGrid, data: this.fillGridList };
        const oldFillGrid = this.loadedFillGrid;

        if (angular.toJson(newFillGrid) !== angular.toJson(oldFillGrid)) {
            const modal = this._$uibModal.open({
                controller: 'FillGridUnsavedController as $ctrl',
                size: 'sm',
                templateUrl: fillGridUnsavedTemplate,
            });

            modal.result.then(
                () => {
                    this._$state.go('app.fillGridView', {
                        gridFillId: this.fillGridId,
                    });
                },
                () => {},
            );
        } else {
            this._$state.go('app.fillGridView', {
                gridFillId: this.fillGridId,
            });
        }
    }

    async removeFillGrid() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.fillGrid', {
                COUNT: 1,
                NAME: this.fillGrid.fullname,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._fillGridProvider.delete(this.fillGridId);
            this._$toasterService.info({
                body: this._translate('removeModal.successGrid', {
                    COUNT: 1,
                }),
            });
            await this._$state.go('app.gridFillAdminList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    onToggleSubLine(fillGrid) {
        if (this.fillGridDetailsShown === fillGrid.attributName) {
            this.fillGridDetailsShown = null;
        } else {
            this.fillGridDetailsShown = fillGrid.attributName;
        }
    }

    setPhaseStatus(isEnabled) {
        return function (phase) {
            return { ...phase, isEnabled: isEnabled };
        };
    }

    getDataModelName(value) {
        if (value && this.dataModels) {
            const dataModel = this.dataModels.find((dataModel) => dataModel.id === value || dataModel.id === value.id);

            if (dataModel) {
                return dataModel.name;
            }
        }

        return '';
    }

    togglePhases(item) {
        this.fillGridList.some((fillGrid, index, self) => {
            if (item === fillGrid) {
                self[index].phases = fillGrid.phases.map(this.setPhaseStatus(!this.hasPhasesEnabled(item.phases)));

                return true;
            }

            return false;
        });
    }

    setSeverity(item, severity) {
        item.severity = severity;
    }

    countActiveFills() {
        return this.fillGridList.filter((fill) => this.hasPhasesEnabled(fill.phases)).length;
    }

    hasPhasesEnabled(phases) {
        return phases.some((p) => p.isEnabled);
    }

    getAllPhasesEnabledList(fillGridData) {
        return fillGridData.reduce((acc, { phases }) => [...acc, ...phases], []).filter(({ isEnabled }) => isEnabled);
    }

    getPhasesListWithCountEnabled(fillGridData) {
        const allPhasesEnabledList = this.getAllPhasesEnabledList(fillGridData);
        const phasesEnabledAmount = allPhasesEnabledList.reduce(
            (acc, val) => ({
                ...acc,
                [val.phaseName]: (acc[val.phaseName] || 0) + 1,
            }),
            {},
        );

        return Object.keys(phasesEnabledAmount).map((e) => {
            return { name: e, count: phasesEnabledAmount[e] };
        });
    }

    cancelFilters() {
        this.setDefaultFilter({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
    }

    removeSearch() {
        this.filter.search = '';
    }
}

angular.module('dotic').component('fillGridAdminFormPage', {
    controller: FillGridAdminFormPage,
    templateUrl: template,
});
