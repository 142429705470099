import template from './postProcessingAdminList.html';

class PostProcessingAdminListPage {
    constructor($filter, $modalService, $scope, $state, $timeout, $toasterService, postProcessingProvider) {
        this._translate = $filter('translate');
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._postProcessingProvider = postProcessingProvider;

        this.files = null;
        this.loading = true;
        this.postProcessingList = [];
        this.uploadInProgress = false;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'postProcessingAdminList',
                    title: 'Liste des post-traitements',
                    href: this._$state.href('app.postProcessingAdminList'),
                },
            ],
        });

        $scope.$on('$locationChangeSuccess', () => {
            $scope.$emit('updateNavigationUrl');
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        this.files = null;

        try {
            const postProcessingList = await this._postProcessingProvider.getAll();
            this.postProcessingList = await this.getPostProcessesFiles(postProcessingList);
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async getPostProcessesFiles(postProcessingList) {
        return await Promise.all(
            postProcessingList.map((postProcessing) => {
                if (postProcessing.canHaveFiles) {
                    return this._postProcessingProvider.getFiles(postProcessing.id).then((files) => {
                        if (!files?.length) {
                            return postProcessing;
                        }

                        return { ...postProcessing, files: files.flat().filter((file) => file) };
                    });
                }

                return postProcessing;
            }),
        );
    }

    pushFiles(postProcessing) {
        const { maxNbOfFiles, fileExtension, fileNamePattern, id } = postProcessing;

        if (!this.files) {
            this._$toasterService.error({
                body: this._translate('shared.missingFiles'),
            });

            return;
        }

        // Handle both File and FileList instance
        let files = [];
        if (this.files instanceof File) {
            files.push(this.files);
        } else {
            files = Array.from(this.files);
        }

        if (maxNbOfFiles) {
            const alreadyUploaded = postProcessing?.files?.length;
            const currentLength = files.length;
            const totalLen = alreadyUploaded + currentLength;
            const nbFileRemaining = maxNbOfFiles - alreadyUploaded;

            if (alreadyUploaded === maxNbOfFiles) {
                this._$toasterService.error({
                    body: this._translate('shared.fileMaximumReached'),
                });

                return;
            } else if (totalLen > maxNbOfFiles) {
                this._$toasterService.error({
                    body: this._translate('shared.fileMaximumRemainingCount', { COUNT: nbFileRemaining }),
                });

                return;
            }
        }

        let isGoodExtension = true;
        let isFilePatternAlreadyPresent = false;

        if (fileExtension) {
            isGoodExtension = files.some((file) => this.isDifferentFileExtension(file, fileExtension));
        }

        if (fileNamePattern) {
            isFilePatternAlreadyPresent = files.some((file) => !this.isDifferentFilePattern(file, fileNamePattern));
        }

        if (!isGoodExtension || isFilePatternAlreadyPresent) {
            return;
        }

        this.uploadInProgress = true;

        return Promise.all(files.map((file) => this._postProcessingProvider.uploadFile(file, id)))
            .then(() => {
                this._$toasterService.info({
                    body: this._translate('postProcessing.importedFileDone'),
                });
            })
            .then(() => {
                this.uploadInProgress = false;

                return this.initData();
            })
            .catch((e) => {
                this.uploadInProgress = false;
                this._$toasterService.error(e.message);
            });
    }

    async downloadFile(filename, postProcessingId) {
        try {
            await this._postProcessingProvider.downloadFile(filename, postProcessingId);
            this._$toasterService.info({
                body: this._translate('shared.downloadingFile'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }

    async removeFile(filename, postProcessingId, postProcessingName) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.postProcessingFile', {
                FILE: filename,
                PROCESSING: postProcessingName,
            }),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._postProcessingProvider.deleteFile(filename, postProcessingId);
            this._$toasterService.info({
                body: this._translate('removeModal.successPostProcessingFile'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }

    isDifferentFileExtension = (currentExtension, expectedExtension) => {
        const fileExtensionArray = currentExtension.name.split('.');
        const lastIndex = fileExtensionArray.length - 1;
        const fileExtension = `.${fileExtensionArray[lastIndex]}`;
        const isSameExtension = fileExtension === expectedExtension;

        if (!isSameExtension) {
            this._$toasterService.error({
                body: this._translate('shared.wrongFileFormat'),
            });
        }

        return isSameExtension;
    };

    isDifferentFilePattern(file, expectedPattern) {
        const strArray = file.name.split('.');
        const beforeLastIndex = strArray.length - 1;
        const strWithoutExtension = strArray.slice(0, beforeLastIndex);
        const nameStr = strWithoutExtension.join('.');

        const nameRegex = new RegExp(expectedPattern);
        const isSamePattern = nameRegex.test(nameStr);

        if (!isSamePattern) {
            this._$toasterService.error({
                body: this._translate('shared.fileFollowPattern', {
                    PATTERN: expectedPattern,
                }),
            });
        }

        return isSamePattern;
    }
}

angular.module('dotic').component('postProcessingAdminListPage', {
    controller: PostProcessingAdminListPage,
    templateUrl: template,
});
