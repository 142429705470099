import template from './change-password-button.component.html';

class ChangePasswordButtonComponent {
    form = {};
    isPasswordVisible = false;
    name = '';
    onPasswordChange = null;
    password = '';

    constructor($modalService) {
        this._$modalService = $modalService;
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    async openChangePasswordModal() {
        const newPassword = await this._$modalService.triggerChangePasswordModal();

        if (!newPassword) {
            return;
        }

        this.onPasswordChange({ value: newPassword });
    }
}

angular.module('dotic').component('ccChangePasswordButton', {
    bindings: {
        form: '<',
        name: '<',
        onPasswordChange: '&',
        password: '<',
    },
    controller: ChangePasswordButtonComponent,
    templateUrl: template,
});
