class NotificationListController {
    constructor(
        $auth,
        $notificationService,
        $scope,
        $state,
        $timeout,
        $toasterService,
        controlConfigurationProvider,
        historyProvider,
        projectProvider,
        userProvider,
    ) {
        this._$notificationService = $notificationService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._projectProvider = projectProvider;
        this._historyProvider = historyProvider;
        this._userProvider = userProvider;

        this.page = 0;
        this.loading = true;
        this.hasViewMore = true;
        this.notificationList = [];
        this._userId = $auth.getPayload().userId;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'notifications',
                    title: 'Mes notifications',
                    href: $state.href('app.notificationList'),
                },
            ],
        });
    }

    async $onInit() {
        try {
            this.controlConfigurationList = await this._controlConfigurationProvider.getAll();
            this.projectList = await this._projectProvider.getAll();

            await this.viewMore();
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
            this._$timeout(() => (this.loading = false));
        }
    }

    async getNotificationList() {
        try {
            return await this._historyProvider.getAll({
                page: this.page,
                limit: 20,
            });
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    setHistoryHasRead(notificationIds) {
        return Promise.all(
            notificationIds.map((notificationId) => {
                return this._userProvider.setHistoryHasRead(this._userId, {
                    histories: [notificationId],
                });
            }),
        );
    }

    formatNotification(notification) {
        return this._$notificationService.format(notification, {
            projectList: this.projectList,
            controlConfigurationList: this.controlConfigurationList,
        });
    }

    async viewMore() {
        this.page = this.page + 1;

        const notificationList = await this.getNotificationList();
        if (notificationList.length < 20) {
            this.hasViewMore = false;
        }

        this.notificationList = this.notificationList.concat(
            notificationList.map((notification) => this.formatNotification(notification)),
        );

        await this.setHistoryHasRead(this.notificationList.filter(({ hasRead }) => !hasRead).map(({ id }) => id));
    }
}

angular.module('dotic').controller('NotificationListController', NotificationListController);
