import template from './correctiveActionModal.html';
class CorrectiveActionModalComponent {
    constructor($scope) {
        this._$scope = $scope;

        this.controls = {};
    }

    $onInit() {
        const { control } = this.resolve;
        this.controls = control;
    }

    onClickclosePopup() {
        this.close();
    }
}

angular.module('dotic').component('correctiveActionModal', {
    templateUrl: template,
    controller: CorrectiveActionModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
    },
});
