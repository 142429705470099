angular.module('dotic').factory('deliveryZoneProvider', deliveryZoneProvider);

function deliveryZoneProvider(ApiProvider, URI_KEYS) {
    return {
        get,
        getEdit,
        remove,
        update,
        list,
        resolve,
    };

    /**
     * DELETE /deliveryZones/:deliveryZoneId
     *
     * @param {object} deliveryZoneId
     *
     * @returns {*} Promise
     */
    function remove(deliveryZoneId) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, deliveryZoneId).remove();
    }

    /**
     * PUT /deliveryZones/:deliveryZoneId
     *
     * @param {string} deliveryZoneId
     * @param {object} deliveryZone
     *
     * @returns {*} Promise
     */
    function update(deliveryZoneId, deliveryZone) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, deliveryZoneId).customPUT(deliveryZone);
    }

    /**
     * GET /deliveryZones/
     * @param {object} queryParams
     * @param {string} queryParams.serverId
     *
     * @returns {*} Promise
     */
    function list(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE).customGET(null, queryParams);
    }

    /**
     * GET /deliveryZones/:deliveryZoneId/edit
     * @param {string} deliveryZoneId
     * @returns {*} Promise
     */
    function getEdit(deliveryZoneId) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, deliveryZoneId).one('edit').get();
    }

    /**
     * GET /deliveryZones/:deliveryZoneId/
     * @param {string} deliveryZoneId
     * @returns {*} Promise
     */
    function get(deliveryZoneId) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, deliveryZoneId).get();
    }

    /**
     * GET /deliveryZones/resolve-deposit/:project/:name
     * @param {string} projectName
     * @param {string} name
     * @returns {*} Promise
     */
    function resolve(projectName, name) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, 'resolve-deposit').one(projectName).one(name).get();
    }
}
