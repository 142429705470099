angular.module('dotic').factory('postProcessingProvider', postProcessingProvider);

function postProcessingProvider(ApiProvider, Upload, URI_KEYS, RestProvider) {
    return {
        create: createPostProcessing,
        delete: deletePostProcessing,
        deleteFile: deletePostProcessingFile,
        downloadFile: downloadPostProcessFile,
        get: getPostProcessing,
        getAll: getAllPostProcessing,
        getFiles: getPostProcessFiles,
        uploadFile: uploadPostProcessFile,
        update: updatePostProcessing,
    };

    /**
     * POST /postProcessings/
     *
     * @returns {*} Promise
     */
    function createPostProcessing(postProcessing) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING).customPOST(postProcessing);
    }

    /**
     * GET /postProcessings/
     *
     * @returns {*} Promise
     */
    function getAllPostProcessing() {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING).getList();
    }

    /**
     * DELETE /postProcessings/:postProcessingId
     *
     * @param {string} postProcessingId
     *
     * @returns {*} Promise
     */
    function deletePostProcessing(postProcessingId) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING, postProcessingId).remove();
    }

    /**
     * GET /postProcessings/:postProcessingId
     *
     * @param {string} postProcessingId
     *
     * @returns {*} Promise
     */
    function getPostProcessing(postProcessingId) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING, postProcessingId).get();
    }

    /**
     * PUT /postProcessings/:postProcessingId
     *
     * @param {string} postProcessingId
     * @param {object} postProcessing
     *
     * @returns {*} Promise
     */
    function updatePostProcessing(postProcessingId, postProcessing) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING, postProcessingId).customPUT(postProcessing);
    }

    /**
     * POST /postProcessings/files
     *
     * @param file
     * @param {string} postProcessingId
     * @return {*}
     */
    function uploadPostProcessFile(file, postProcessingId) {
        return Upload.upload({
            url: `${APP_SETTINGS.baseApiUrl}/${URI_KEYS.POST_PROCESSING}/files`,
            data: {
                file,
                postProcessingId,
            },
        });
    }

    /**
     * GET /postProcessings/files
     *
     * @param {string} postProcessingId
     * @returns {*} Promise
     */
    function getPostProcessFiles(postProcessingId) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING).one('files').customGET(null, {
            postProcessingId,
        });
    }

    /**
     * DELETE /postProcessings/files/:filename
     *
     * @param {string} filename
     * @param {string} postProcessingId
     * @return {*}
     */
    function deletePostProcessingFile(filename, postProcessingId) {
        return ApiProvider.one(URI_KEYS.POST_PROCESSING, 'files').one(filename).remove({ postProcessingId });
    }

    /**
     * GET /postProcessings/files/:filename
     *
     * @param {string} filename
     * @param {string} postProcessingId
     * @return {*}
     */
    function downloadPostProcessFile(filename, postProcessingId) {
        const path = [URI_KEYS.POST_PROCESSING, 'files', filename];

        return RestProvider.downloadFromPath(path, { postProcessingId });
    }
}
