angular.module('dotic').filter('prefixNumber', () => {
    return (value) => {
        const int = parseInt(value, 10);
        if (int > 9) {
            return value;
        }

        return `0${value}`;
    };
});
