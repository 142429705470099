function fillGridService($q, $toasterService, fillGridProvider, $phaseService) {
    const phaseList = $phaseService.getAll();

    return {
        excelToJson: excelToJson,
        jsonToExcel: jsonToExcel,
        duplicate: duplicate,
    };

    async function duplicate(companyId, fillGridId) {
        const fillGrid = await fillGridProvider.get(fillGridId);
        const newFillGrid = {
            company: [companyId],
            data: fillGrid.data,
            dataModel: fillGrid.dataModel,
            description: fillGrid.description,
            isClonable: fillGrid.isClonable,
            isEditable: fillGrid.isEditable,
            isTemplate: false,
            name: fillGrid.name,
        };
        newFillGrid.templateParent = fillGrid.isTemplate ? fillGrid.id : fillGrid.templateParent;
        const fillGridCreated = await fillGridProvider.create(newFillGrid);

        $toasterService.info('La grille de remplissage a bien été dupliquée');

        return fillGridCreated;
    }

    function excelToJson(objArray) {
        const tables = [];

        const newItems = objArray.map((item, index) => {
            const newObject = {};
            ['TABLE', 'CHAMP', 'severity', 'DESCRIPTION', 'REQUIRED'].forEach((field) => {
                //check if empty and not = 0
                if (!item[field]) {
                    throw new Error(`Le champ ${field} ne doit pas être vide. Ligne: ${index + 2}`);
                }
            });

            newObject.phases = [];
            newObject.tableName = item.TABLE.trim(); // Obligatoire
            newObject.attributName = item.CHAMP.trim(); // Obligatoire
            newObject.severity = item.severity.trim(); // Obligatoire
            newObject.regex = item.REGEX; // Optionnel

            if (item.MODE_PRODUCTION === 'null') {
                newObject.productionMode = null;
            } else {
                newObject.productionMode = item.MODE_PRODUCTION;
            }

            newObject.geometrie = item.GEOMETRIE; // Optionnel
            newObject.relation = null; // Optionnel
            newObject.objectSI = item.OBJECT_SI === 'null' ? null : item.OBJECT_SI; // Optionnel
            newObject.correspondenceSI = item.CORRESPONDENCE_SI === 'null' ? null : item.CORRESPONDENCE_SI; // Optionnel
            newObject.commentSI = item.COMMENT_SI === 'null' ? null : item.COMMENT_SI; // Optionnel

            const hasTable = tables.find((table) => {
                return table.name === newObject.tableName;
            });
            if (!hasTable) {
                tables.push({ name: newObject.tableName });
            }

            if (item.RELATION) {
                newObject.relation = item.RELATION;
            }

            if (item.DESCRIPTION) {
                newObject.description = item.DESCRIPTION.trim();
            }

            delete item.TABLE;
            delete item.CHAMP;
            delete item.REGEX;
            delete item.MODE_PRODUCTION;
            delete item.GEOMETRIE;
            delete item.severity;
            delete item.RELATION;
            delete item.DESCRIPTION;
            delete item.OBJECT_SI;
            delete item.CORRESPONDENCE_SI;
            delete item.COMMENT_SI;

            // On peut maintenant récupérer le nom des champ + la valeur
            const phases = [];
            Object.getOwnPropertyNames(item)
                .filter((item) => item.startsWith('P_'))
                .forEach((propertyName) => {
                    const string = propertyName.split('_');
                    const phaseName = string[1].trim();
                    phases.push({
                        phaseName: phaseName,
                        isEnabled: item[propertyName] === 1,
                    });
                });
            newObject.phases = phases;
            newObject.required =
                item.REQUIRED && !phases.every(({ isEnabled }) => !isEnabled) ? item.REQUIRED : 'noRequired';

            newObject.condition = item.REQUIRED === 'optional' ? item.CONDITION : null;
            newObject.testableCondition = item.REQUIRED === 'optional' ? item.CONDITION_TESTABLE || 0 : null;

            return newObject;
        });

        return { grid: newItems, tableList: tables };
    }

    function jsonToExcel(objArray, isArchive = false) {
        const gridFills = [];
        objArray.forEach((obj) => {
            const testableConditionValue = obj.testableCondition ? 1 : 0;

            const newItem = {};

            newItem.TABLE = obj.tableName;
            newItem.CHAMP = obj.attributName;
            newItem.REGEX = obj.regex;
            newItem.GEOMETRIE = obj.geometrie;
            newItem.RELATION = obj.relation ? obj.relation : '';
            newItem.severity = obj.severity;
            newItem.DESCRIPTION = obj.description;
            newItem.REQUIRED = obj.required;
            newItem.CONDITION = obj.condition && obj.required === 'optional' ? obj.condition : '';
            newItem.CONDITION_TESTABLE = obj.required !== 'optional' ? '' : testableConditionValue;
            newItem.MODE_PRODUCTION =
                angular.isUndefined(obj.productionMode) || obj.productionMode === null ? '' : obj.productionMode;
            newItem.OBJECT_SI = angular.isUndefined(obj.objectSI) || obj.objectSI === null ? '' : obj.objectSI;
            newItem.CORRESPONDENCE_SI =
                angular.isUndefined(obj.correspondenceSI) || obj.correspondenceSI === null ? '' : obj.correspondenceSI;
            newItem.COMMENT_SI = angular.isUndefined(obj.commentSI) || obj.commentSI === null ? '' : obj.commentSI;

            const phases = [];
            // Set backward compatibility for archive before the feature

            if (isArchive) {
                phases.push({
                    isEnabled: true,
                    phaseName: obj.phase,
                });
            }

            phaseList.forEach((phase) => {
                const currentPhase = isArchive
                    ? phases.find(({ phaseName }) => phaseName === phase)
                    : obj.phases.find(({ phaseName }) => phaseName === phase);

                newItem[`P_${phase}`] = angular.isDefined(currentPhase) && currentPhase.isEnabled ? 1 : 0;
            });

            gridFills.push(newItem);
        });

        return gridFills;
    }
}

angular.module('dotic').factory('$fillGridService', fillGridService);
