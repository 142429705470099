angular.module('dotic').factory('supervisionProvider', supervisionProvider);

function supervisionProvider(ApiProvider, RestProvider, URI_KEYS) {
    return {
        createAlert,
        updateAlert,
        getAlert,
        getList: getAllAlerts,
        deleteAlert,
    };

    /**
     * POST /supervision/
     *
     * @param {object} SUPERVISION
     *
     * @returns {*} Promise
     */

    function createAlert(indicatorAlert) {
        return ApiProvider.one(URI_KEYS.SUPERVISION).customPOST(indicatorAlert);
    }

    /**
     * PUT /supervision/:indicatorAlertId
     *
     * @param {string} indicatorAlertId
     * @param {object} indicatorAlertForm
     *
     * @returns {*} Promise
     */
    function updateAlert(indicatorAlertId, indicatorAlertForm) {
        return ApiProvider.one(URI_KEYS.SUPERVISION, indicatorAlertId).customPUT(indicatorAlertForm);
    }

    /**
     * GET /supervision/:indicatorAlertId
     *
     * @param {string} indicatorAlertId
     *
     * @returns {*} Promise
     */
    function getAlert(indicatorAlertId) {
        return RestProvider.get([URI_KEYS.SUPERVISION, indicatorAlertId]);
    }

    /**
     * GET /supervision/list
     *
     * @param {object} SUPERVISION
     *
     * @returns {*} Promise
     */

    function getAllAlerts() {
        return ApiProvider.one(URI_KEYS.SUPERVISION).getList();
    }

    /**
     * DELETE /supervision/:indicatorAlertId
     *
     * @param {string} indicatorAlertId
     *
     * @returns {*} Promise
     */
    function deleteAlert(indicatorAlertId) {
        return ApiProvider.one(URI_KEYS.SUPERVISION, indicatorAlertId).remove();
    }
}
