import userManualGestionDepotDeposerLivrableDeposerLivrableManyTemplate from './deposerLivrable/deposerLivrableMany.html';
import userManualGestionDepotDeposerLivrableDeposerLivrableTemplate from './deposerLivrable/deposerLivrable.html';
import userManualGestionDepotDeposerLivrableDepositNameTemplate from './deposerLivrable/depositName.html';
import userManualGestionDepotGestionDepotTemplate from './gestionDepot.html';
import userManualGestionDepotLivrableControleComparerLControleTemplate from './livrableControle/comparerLControle.html';
import userManualGestionDepotLivrableControleDownloadReportLControleTemplate from './livrableControle/downloadReportLControle.html';
import userManualGestionDepotLivrableControleDownloadReportLControleXlsTemplate from './livrableControle/downloadReportLControleXls.html';
import userManualGestionDepotLivrableControleErreurGlobaleLControleTemplate from './livrableControle/erreurGlobaleLControle.html';
import userManualGestionDepotLivrableControleFiltreLControleTemplate from './livrableControle/filtreLControle.html';
import userManualGestionDepotLivrableControleLivrableControleTemplate from './livrableControle/livrableControle.html';
import userManualGestionDepotLivrableControleLogFMELControleTemplate from './livrableControle/logFMELControle.html';
import userManualGestionDepotLivrableControleQGisLControleTemplate from './livrableControle/qGisLControle.html';
import userManualGestionDepotLivrableControleRapportLControleTemplate from './livrableControle/rapportLControle.html';
import userManualGestionDepotLivrableControleSeeReportLControleTemplate from './livrableControle/seeReportLControle.html';
import userManualGestionDepotLivrableControleSupprimerLControleTemplate from './livrableControle/supprimerLControle.html';
import userManualGestionDepotLivrableControleViewGridsArchiveTemplate from './livrableControle/viewGridsArchive.html';
import userManualGestionDepotLivrableControleVisualiserLControleTemplate from './livrableControle/visualiserLControle.html';
import userManualGestionDepotLivrableControleVoirLControleTemplate from './livrableControle/voirLControle.html';
import userManualGestionDepotLivrableStockeCartoLStockeTemplate from './livrableStocke/cartoLStocke.html';
import userManualGestionDepotLivrableStockeExportLStockeTemplate from './livrableStocke/exportLStocke.html';
import userManualGestionDepotLivrableStockeFiltreLStockeTemplate from './livrableStocke/filtreLStocke.html';
import userManualGestionDepotLivrableStockeLivrableStockeTemplate from './livrableStocke/livrableStocke.html';
import userManualGestionDepotLivrableStockePostTreatmentLStockeTemplate from './livrableStocke/postTreatmentLStocke.html';
import userManualGestionDepotLivrableStockeSeeLStockeTemplate from './livrableStocke/seeLStocke.html';
import userManualGestionDepotLivrableStockeSupprimerLStockeTemplate from './livrableStocke/supprimerLStocke.html';
import userManualGestionDepotLivrableStockeTelechargerLStockeTemplate from './livrableStocke/telechargerLStocke.html';
import userManualGestionDepotVueDepotStatusDepotTemplate from './deposerLivrable/statusDeposit.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.gestionDepot', {
            url: '/gestion-depot',
            templateUrl: userManualGestionDepotGestionDepotTemplate,
            data: {
                title: 'Gestion des dépots',
                navigation: [],
                previous: 'app.help.interventionList',
                next: 'app.help.deposerLivrable',
            },
        })
        /* Déposer des livrables */
        .state('app.help.deposerLivrable', {
            url: '/gestion-depot/deposer-livrable',
            templateUrl: userManualGestionDepotDeposerLivrableDeposerLivrableTemplate,
            data: {
                title: 'Déposer des livrables',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.gestionDepot',
                next: 'app.help.depositName',
            },
        })
        .state('app.help.depositName', {
            url: '/gestion-depot/depot-nomenclature',
            templateUrl: userManualGestionDepotDeposerLivrableDepositNameTemplate,
            data: {
                title: 'Nomenclature du livrable',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.deposerLivrable',
                next: 'app.help.deposerLivrableMany',
            },
        })
        .state('app.help.deposerLivrableMany', {
            url: '/gestion-depot/deposer-livrables',
            templateUrl: userManualGestionDepotDeposerLivrableDeposerLivrableManyTemplate,
            data: {
                title: 'Informations',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.depositName',
                next: 'app.help.statusDepot',
            },
        })
        .state('app.help.statusDepot', {
            url: '/gestion-depot/status',
            templateUrl: userManualGestionDepotVueDepotStatusDepotTemplate,
            data: {
                title: 'Statuts du dépôt',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.deposerLivrableMany',
                next: 'app.help.livrableControle',
            },
        })
        /* livrables controles */
        .state('app.help.livrableControle', {
            url: '/gestion-depot/livrable-controle',
            templateUrl: userManualGestionDepotLivrableControleLivrableControleTemplate,
            data: {
                title: 'Livrables contrôlés',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.supprimerDepot',
                next: 'app.help.voirLControle',
            },
        })
        .state('app.help.voirLControle', {
            url: '/gestion-depot/livrable-controle/voir',
            templateUrl: userManualGestionDepotLivrableControleVoirLControleTemplate,
            data: {
                title: 'Voir un livrable contrôlé',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.livrableControle',
                next: 'app.help.filtreLControle',
            },
        })
        .state('app.help.filtreLControle', {
            url: '/gestion-depot/livrable-controle/filtrer',
            templateUrl: userManualGestionDepotLivrableControleFiltreLControleTemplate,
            data: {
                title: 'Filtrer un livrable contrôlé',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.voirLControle',
                next: 'app.help.rapportLControle',
            },
        })
        .state('app.help.rapportLControle', {
            url: '/gestion-depot/livrable-controle/rapport',
            templateUrl: userManualGestionDepotLivrableControleRapportLControleTemplate,
            data: {
                title: 'Le rapport',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.filtreLControle',
                next: 'app.help.viewGridsArchive',
            },
        })
        .state('app.help.viewGridsArchive', {
            url: '/gestion-depot/livrable-controle/voir-grille-archive',
            templateUrl: userManualGestionDepotLivrableControleViewGridsArchiveTemplate,
            data: {
                title: 'Voir les grilles archives',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.rapportLControle',
                next: 'app.help.seeReportLControle',
            },
        })
        .state('app.help.seeReportLControle', {
            url: '/gestion-depot/livrable-controle/voir-rapport-pdf',
            templateUrl: userManualGestionDepotLivrableControleSeeReportLControleTemplate,
            data: {
                title: 'Voir un rapport',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.viewGridsArchive',
                next: 'app.help.downloadReportLControle',
            },
        })
        .state('app.help.downloadReportLControle', {
            url: '/gestion-depot/livrable-controle/telechargement-pdf',
            templateUrl: userManualGestionDepotLivrableControleDownloadReportLControleTemplate,
            data: {
                title: 'Télécharger version PDF',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.seeReportLControle',
                next: 'app.help.downloadReportLControleXls',
            },
        })
        .state('app.help.downloadReportLControleXls', {
            url: '/gestion-depot/livrable-controle/telechargement-xls',
            templateUrl: userManualGestionDepotLivrableControleDownloadReportLControleXlsTemplate,
            data: {
                title: 'Télécharger version Excel',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.downloadReportLControle',
                next: 'app.help.erreurGlobaleLControle',
            },
        })
        .state('app.help.erreurGlobaleLControle', {
            url: '/gestion-depot/livrable-controle/erreurs-globales',
            templateUrl: userManualGestionDepotLivrableControleErreurGlobaleLControleTemplate,
            data: {
                title: 'Télécharger les erreurs globales',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.downloadReportLControleXls',
                next: 'app.help.logFMELControle',
            },
        })
        .state('app.help.logFMELControle', {
            url: '/gestion-depot/livrable-controle/log-FME',
            templateUrl: userManualGestionDepotLivrableControleLogFMELControleTemplate,
            data: {
                title: 'Télécharger les logs FME',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.erreurGlobaleLControle',
                next: 'app.help.qGisLControle',
            },
        })
        .state('app.help.qGisLControle', {
            url: '/gestion-depot/livrable-controle/QGIS',
            templateUrl: userManualGestionDepotLivrableControleQGisLControleTemplate,
            data: {
                title: 'Mode QGIS',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.logFMELControle',
                next: 'app.help.visualiserLControle',
            },
        })
        .state('app.help.visualiserLControle', {
            url: '/gestion-depot/livrable-controle/visualiser',
            templateUrl: userManualGestionDepotLivrableControleVisualiserLControleTemplate,
            data: {
                title: 'Visualiser mode carto',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.qGisLControle',
                next: 'app.help.comparerLControle',
            },
        })
        .state('app.help.comparerLControle', {
            url: '/gestion-depot/livrable-controle/comparer',
            templateUrl: userManualGestionDepotLivrableControleComparerLControleTemplate,
            data: {
                title: 'Comparer des rapports',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.visualiserLControle',
                next: 'app.help.supprimerLControle',
            },
        })
        .state('app.help.supprimerLControle', {
            url: '/gestion-depot/livrable-controle/supprimer',
            templateUrl: userManualGestionDepotLivrableControleSupprimerLControleTemplate,
            data: {
                title: 'Supprimer un livrable contrôlé',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables contrôlés',
                        sref: 'app.help.livrableControle',
                    },
                ],
                previous: 'app.help.comparerLControle',
                next: 'app.help.livrableStocke',
            },
        })
        /* Livrables stockes */
        .state('app.help.livrableStocke', {
            url: '/gestion-depot/livrable-stocke',
            templateUrl: userManualGestionDepotLivrableStockeLivrableStockeTemplate,
            data: {
                title: 'Livrables stockés',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                ],
                previous: 'app.help.supprimerLControle',
                next: 'app.help.seeLStocke',
            },
        })
        .state('app.help.seeLStocke', {
            url: '/gestion-depot/livrable-stocke/voir',
            templateUrl: userManualGestionDepotLivrableStockeSeeLStockeTemplate,
            data: {
                title: 'Voir un livrable stocké',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.livrableStocke',
                next: 'app.help.filtreLStocke',
            },
        })
        .state('app.help.filtreLStocke', {
            url: '/gestion-depot/livrable-stocke/filtrer',
            templateUrl: userManualGestionDepotLivrableStockeFiltreLStockeTemplate,
            data: {
                title: 'Filtrer un livrable stocké',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.seeLStocke',
                next: 'app.help.telechargerLStocke',
            },
        })
        .state('app.help.telechargerLStocke', {
            url: '/gestion-depot/livrable-stocke/telecharger',
            templateUrl: userManualGestionDepotLivrableStockeTelechargerLStockeTemplate,
            data: {
                title: 'Télécharger un livrable stocké en mode Qgis',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.filtreLStocke',
                next: 'app.help.cartoLStocke',
            },
        })
        .state('app.help.cartoLStocke', {
            url: '/gestion-depot/livrable-stocke/mode-carto',
            templateUrl: userManualGestionDepotLivrableStockeCartoLStockeTemplate,
            data: {
                title: 'Mode cartographique',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.telechargerLStocke',
                next: 'app.help.exportLStocke',
            },
        })
        .state('app.help.exportLStocke', {
            url: '/gestion-depot/livrable-stocke/export',
            templateUrl: userManualGestionDepotLivrableStockeExportLStockeTemplate,
            data: {
                title: 'Exporter un livrable',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.cartoLStocke',
                next: 'app.help.supprimerLStocke',
            },
        })
        .state('app.help.postTreatmentLStocke', {
            url: '/gestion-depot/livrable-stocke/post-trraitement',
            templateUrl: userManualGestionDepotLivrableStockePostTreatmentLStockeTemplate,
            data: {
                title: 'Post-traiter un livrable',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.exportLStocke',
                next: 'app.help.supprimerLStocke',
            },
        })
        .state('app.help.supprimerLStocke', {
            url: '/gestion-depot/livrable-stocke/supprimer',
            templateUrl: userManualGestionDepotLivrableStockeSupprimerLStockeTemplate,
            data: {
                title: 'Supprimer un livrable stocké',
                navigation: [
                    {
                        title: 'Gestion des dépôts',
                        sref: 'app.help.gestionDepot',
                    },
                    {
                        title: 'Livrables stockés',
                        sref: 'app.help.livrableStocke',
                    },
                ],
                previous: 'app.help.exportLStocke',
                next: 'app.help.gestionStatistic',
            },
        });
});
