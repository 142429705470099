angular.module('dotic').factory('reportProvider', reportProvider);

function reportProvider(ApiProvider, URI_KEYS) {
    return {
        get,
        getStatistics,
        getProjectControlJson,
        getVersionControlJson,
        getFromDeposit,
        getDetails,
    };

    /**
     * GET /reports/:reportId
     *
     * @param {string} reportId
     * @returns {*} Promise
     */
    function get(reportId) {
        return ApiProvider.one(URI_KEYS.REPORT, reportId).get();
    }

    /**
     * GET /reports/:reportId/details
     *
     * @param {string} reportId
     * @returns {*} Promise
     */
    function getDetails(reportId) {
        return ApiProvider.one(URI_KEYS.REPORT, reportId).one('details').get();
    }

    /**
     * GET /reports/deposit/:depositId
     *
     * @param {string} depositId
     * @returns {*} Promise
     */
    function getFromDeposit(depositId) {
        return ApiProvider.one(URI_KEYS.REPORT).one('deposit', depositId).get();
    }

    /**
     * GET /reports/:reportId/statistics
     *
     * @param {string} reportId
     * @returns {*} Promise
     */
    function getStatistics(reportId) {
        return ApiProvider.one(URI_KEYS.REPORT, reportId).customGET('statistics');
    }

    /**
     * GET /reports/:reportId/controls/:controlPoint/json
     * @param {string} reportId
     * @param {string} controlPoint
     * @param {string|null} severity
     * @param {{column:string, page: number, limit: number, order:number}} queryParams
     * @returns {*}
     */
    function getProjectControlJson(reportId, controlPoint, severity = null, queryParams = {}) {
        return ApiProvider.one(URI_KEYS.REPORT, reportId)
            .one(URI_KEYS.CONTROL, controlPoint)
            .customGET('json', { ...queryParams, severity: severity });
    }

    /**
     * GET /reports/deposit/:depositId/controls/:controlPoint/json
     * @param {string} depositId
     * @param {string} controlPoint
     * @param {string|null} severity
     * @returns {*}
     */
    function getVersionControlJson(depositId, controlPoint, severity = null) {
        return ApiProvider.one(URI_KEYS.REPORT)
            .one('deposit', depositId)
            .one(URI_KEYS.CONTROL, controlPoint)
            .customGET('json', { severity });
    }
}
