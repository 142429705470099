const dateFormat = 'DD/MM/YYYY hh:mm';

class ProjectService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    excelToJson(projects) {
        const requiredFields = ['NOM', 'DESCRIPTION', 'GEOMETRIE', 'ETAPES'];

        const json = projects.map((project, index) => {
            // Ignore eventual blank lines
            if (Object.keys(project).length === 0) {
                return null;
            }

            requiredFields.forEach((field) => {
                if (!project[field]) {
                    throw new Error(
                        this._translate('shared.requiredFieldExcel', {
                            field,
                            line: index + 2,
                        }),
                    );
                }
            });

            const newItem = {};
            newItem.name = project.NOM;
            newItem.description = project.DESCRIPTION;
            newItem.phases = project.ETAPES.replace(/\s/g, '').split(',');

            newItem.isAutomaticNaming = project.NOMMAGE_RAPPORTS;

            newItem.startAt = moment(project.CONVERTED_START_DATE, dateFormat).toDate();
            newItem.endAt = moment(project.CONVERTED_END_DATE, dateFormat).toDate();

            newItem.controlConfigurations =
                project.CONFIGURATION_CONTROLE?.split(',').map((configuration) => configuration.trim()) ?? [];

            newItem.assignments =
                project.UTILISATEURS?.replace(/\s/g, '')
                    .split(',')
                    .filter((utilisateur) => utilisateur !== '')
                    .map((email) => ({ email, type: 'user' })) ?? [];

            // *
            // Valid and map sub contractors assignments
            const subContractorKeys = Object.keys(project)
                .filter((key) => key.startsWith('SOUSTRAITANT'))
                .map((key) => key.replace('SOUSTRAITANT', ''))
                .map((key) => parseInt(key, 10));

            const subContractors = subContractorKeys
                .map((key) => {
                    const userColumnName = `SOUSTRAITANT${key}`;
                    const phaseColumnName = `SS${key}_ETAPES`;

                    const valid =
                        angular.isDefined(project[userColumnName]) && angular.isDefined(project[phaseColumnName]);

                    if (!valid) {
                        throw new Error(
                            `Les colonnes "${userColumnName}" et "${phaseColumnName}" sont invalides ou manquantes`,
                        );
                    }

                    // Ignore blank cells
                    const filled = project[userColumnName] !== '' && project[phaseColumnName] !== '';
                    if (!filled) {
                        return null;
                    }

                    return {
                        phases: project[phaseColumnName].replace(/\s/g, '').split(','),
                        email: project[userColumnName].replace(/\s/g, ''),
                        type: 'subcontractor',
                    };
                })
                .filter(Boolean);

            newItem.assignments = newItem.assignments.concat(subContractors);

            newItem.projection = project.GEOMETRIE;

            return newItem;
        });

        return json.filter(Boolean);
    }

    getControlConfigurationFromSlugInProject(project, dataModelSlug) {
        const matchingConfigurations = project.controlConfigurations.filter(
            (controlConfiguration) => controlConfiguration.dataModel.regexSlug === dataModelSlug,
        );

        return matchingConfigurations.find((item) => item.isDefaultConfigurationDataModel) ?? matchingConfigurations[0];
    }

    jsonToExcel(project) {
        const users = project.assignments.filter((assignment) => assignment.type === 'user');

        const subContractors = project.assignments
            .filter((assignment) => assignment.type === 'subcontractor')
            .reduce((acc, assignment, key) => {
                const userColumnName = `SOUSTRAITANT${key + 1}`;
                const phaseColumnName = `SS${key + 1}_ETAPES`;
                acc[userColumnName] = assignment.user.email;
                acc[phaseColumnName] = assignment.phases.join(',');

                return acc;
            }, {});

        return {
            NOM: project.name,
            DESCRIPTION: project.description || '',
            ETAPES: project.phases,
            NOMMAGE_RAPPORTS: project.isAutomaticNaming ? 1 : 0,
            DATE_DE_DEBUT: project.startAt ? moment(project.startAt).format(dateFormat) : '',
            DATE_DE_FIN: project.endAt ? moment(project.endAt).format(dateFormat) : '',
            CONFIGURATION_CONTROLE: project.controlConfigurations.map((control) => control.name),
            UTILISATEURS: users.map((data) => data.user.email),
            GEOMETRIE: project.projection,
            ...subContractors,
        };
    }
}

angular.module('dotic').factory('$projectService', ($filter) => new ProjectService($filter));
