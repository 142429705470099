import { FMEJobEnum } from '../../models/deposit.model';
import template from './deposits-preview.html';

class DepositsPreviewController {
    loading = true;
    deposits = {
        results: [],
        page: 1,
        count: 0,
    };
    filters = {};
    job = FMEJobEnum.LOAD;
    pageSize = 5;

    constructor($scope, $timeout, depositProvider) {
        this._depositProvider = depositProvider;
        this._$timeout = $timeout;
    }

    async $onInit() {
        this.deposits = await this.getDeposits();
    }

    async $onChanges(changes) {
        if (this.loading || !changes.filters.previousValue || !changes.filters.currentValue) {
            return;
        }

        this.deposits = await this.getDeposits();
    }

    async getDeposits(page = 1) {
        this.loading = true;
        try {
            return await this._depositProvider.listAll({
                filters: {
                    job: this.job,
                    controlConfiguration: this.filters.controlConfigurations,
                    createdAtStart: this.filters.from,
                    createdAtEnd: this.filters.to,
                    createdBy: this.filters.users,
                    dataModel: this.filters.dataModel,
                    deliveryZone: this.filters.deliveryZones,
                    fillGrid: this.filters.fillGrid,
                    hasIdLivrable: this.filters.hasIdLivrable,
                    isSegmentFilterStrict: this.filters.isSegmentFilterStrict,
                    phase: this.filters.phases,
                    projectId: this.filters.projectIds,
                    project: this.filters.projects,
                    segment: this.filters.networkSegments,
                    subCompany: this.filters.subCompanies,
                    status: 'SUCCESS',
                    version: 'last',
                    zipFileName: this.filters.zipNames,
                },
                page: page,
                perPage: this.pageSize,
            });
        } catch {
            return {
                results: [],
                page: 1,
                count: 0,
            };
        } finally {
            this._$timeout(() => {
                this.loading = false;
            });
        }
    }

    async onPaginationChange(page, pageSize) {
        this.pageSize = pageSize;
        this.deposits = await this.getDeposits(page);
    }
}

angular.module('dotic').component('depositsPreview', {
    templateUrl: template,
    controller: DepositsPreviewController,
    bindings: {
        job: '@',
        filters: '<',
    },
});
