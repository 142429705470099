import template from './controlGridItemList.html';

class ControlGridItemListController {
    constructor(
        $excelService,
        $filter,
        $filterService,
        $gridControlService,
        $scope,
        $segmentsService,
        $state,
        $tableService,
        $toasterService,
    ) {
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._$gridControlService = $gridControlService;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$toasterService = $toasterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');

        this.controlGrid = [];
        this.filteredGrid = [];
        this.controlGridName = '';

        this.segmentsExist = false;

        this.filter = [];

        this.segmentsList = this._$segmentsService.getAll();
        this.segments = this.segmentsList.map((s) => {
            return { segmentName: s, isEnabled: false };
        });

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'internalIdsList',
                selected: 'internalId',
                placeholder: 'filter.all.internalId',
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematics',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'objects',
                selected: 'object',
                placeholder: 'filter.all.object',
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'severities',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'status',
                value: 'status',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.status',
            },
        ];

        this.filterValues = {
            internalIdsList: [],
            thematics: [],
            objects: [],
            severities: [],
            status: [],
        };

        // *
        // Set filters from urls
        const { params } = $state;
        this.setFilters(params);
        $scope.$on('$locationChangeSuccess', () => {
            this.setFilters(params);
        });

        // *
        // Watch view changes
        const watches = [
            '$ctrl.filter.thematic',
            '$ctrl.filter.internalId',
            '$ctrl.filter.object',
            '$ctrl.filter.severity',
            '$ctrl.filter.status',
            '$ctrl.filter.search',
            '$ctrl.controlGrid',
        ];

        $scope.$watchGroup(watches, () => {
            this.updateFilters();
        });
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setFilters(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            thematic: this._$filterService.getFilterValue(params.thematic),
            object: this._$filterService.getFilterValue(params.object),
            severity: this._$filterService.getFilterValue(params.severity),
            status: params.status || '',
            search: params.search || '',
            internalId: this._$filterService.getFilterValue(params.internalId),
        };
    }

    $onChanges() {
        if (!this.controlGrid || !angular.isArray(this.controlGrid)) {
            return;
        }

        this.tableDetail = this._$tableService.detail();
        this.updateTable(this.controlGrid);
    }

    onExportGridControlArchive() {
        const data = this._$gridControlService.jsonToExcel(this.controlGrid, true);

        return this._$excelService.downloadXLSX(
            { headers: true },
            data,
            this.controlGridName,
            'controlGridAdminView.downloadGrid',
        );
    }

    updateFilters() {
        if (!this.controlGrid) {
            return;
        }

        const { thematic, severity, object, search: rawSearch, status, internalId } = this.filter;
        const search = rawSearch;

        // Update url
        this._$state.go('.', {
            thematic: thematic.join(','),
            severity: severity.join(','),
            object: object.join(','),
            status,
            search,
            internalId: internalId.join(','),
        });

        // Filter list
        this.filteredGrid = this.controlGrid
            .filter(this.filterSearch(search))
            .filter(this._$filterService.genericMultiFilter(object, 'object'))
            .filter(this._$filterService.genericMultiFilter(thematic, 'thematic'))
            .filter(this._$filterService.genericMultiFilter(severity, 'severity'))
            .filter(this._$filterService.genericMultiFilter(internalId, 'controlPoint'))
            .filter(this.filterStatus(status));

        if (this.filteredGrid.find((el) => el.networkSegments)) {
            this.segmentsExist = true;
        }
    }

    updateTable(controlGrid = []) {
        this.filterValues = {
            objects: this.getObjectList(controlGrid),
            severities: this.getSeverityList(controlGrid),
            status: this.getStatusList(controlGrid),
            thematics: this.getThematicList(controlGrid),
            internalIdsList: this.getInternalIdsList(controlGrid),
        };

        const data = controlGrid.map((item) => ({
            ...item,
            variable1Type: item.variable1Type || 'input',
            variable2Type: item.variable2Type || 'input',
            thematic: item.thematic || item.thematicName,
            thematicName: item.thematicName || item.thematic,
        }));
        this.controlGrid = data;
        this.filteredGrid = data;
    }

    /**
     * On filter change
     */
    isDataPhaseEnabled(phase, item) {
        if (item.phases) {
            const currentPhase = item.phases.find((e) => e.phaseName === phase.key);

            return currentPhase ? currentPhase.isEnabled : false;
        } else if (item.phase) {
            return phase.key === item.phase;
        }
    }

    filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.defaultVariable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.variable2 || '').toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    filterStatus(status) {
        if (status === '') {
            return () => true;
        }

        const enable = status === 'true';

        return (item) => item.enable === enable;
    }

    /**
     * Get filter list
     */
    getObjectList(controlGrid = []) {
        return controlGrid.reduce((acc, { object }) => {
            if (!acc.find((item) => item.key === object)) {
                acc.push({
                    key: object,
                    value: object,
                });
            }

            return acc;
        }, []);
    }

    getSeverityList(controlGrid = []) {
        return controlGrid.reduce((acc, { severity }) => {
            if (!acc.find((item) => item.key === severity)) {
                acc.push({
                    key: severity,
                    value: this._humanizeSeverity(severity),
                });
            }

            return acc;
        }, []);
    }

    getStatusList(controlGrid = []) {
        return controlGrid.reduce((acc, { enable }) => {
            const enableKey = enable.toString();
            if (!acc.find((item) => item.key === enableKey)) {
                acc.push({
                    key: enableKey,
                    value: enable
                        ? this._translate('shared.activated', { GENDER: 'male' })
                        : this._translate('shared.disabled'),
                });
            }

            return acc;
        }, []);
    }

    getThematicList(controlGrid = []) {
        return controlGrid.reduce((acc, { thematic }) => {
            if (!acc.find((item) => item.key === thematic)) {
                acc.push({
                    key: thematic,
                    value: this._humanizeThematic(thematic),
                });
            }

            return acc;
        }, []);
    }

    getInternalIdsList(controlGrid = []) {
        return controlGrid.reduce((acc, { controlPoint }) => {
            if (!acc.find((item) => item.key === controlPoint)) {
                acc.push({
                    key: controlPoint,
                    value: controlPoint,
                });
            }

            return acc;
        }, []);
    }

    cancelFilters() {
        this.setFilters({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('controlGridItemList', {
    templateUrl: template,
    controller: ControlGridItemListController,
    bindings: {
        controlGrid: '<',
        controlGridName: '<',
    },
});
