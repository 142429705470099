import { GridActionEnum } from '../models/grid.model';

class GridService {
    constructor(
        $excelService,
        $filter,
        fillGridProvider,
        $fillGridService,
        gridControlProvider,
        $gridControlService,
        $toasterService,
    ) {
        this._$excelService = $excelService;
        this._$toasterService = $toasterService;
        this._fillGridProvider = fillGridProvider;
        this._$fillGridService = $fillGridService;
        this._gridControlProvider = gridControlProvider;
        this._$gridControlService = $gridControlService;
        this._translate = $filter('translate');
    }

    displayDuplicatedError(duplicatedInfo, maxNumberToDisplay = 5) {
        const numberOfDataToPresent = Math.min(duplicatedInfo.length, maxNumberToDisplay);

        const listToPresent = duplicatedInfo
            .slice(0, numberOfDataToPresent)
            .map((duplicatedData) => duplicatedData.virtualKey)
            .join(' | ');
        this._$toasterService.error(
            this._translate('toaster.duplicatedInfo', {
                COUNT: Math.max(duplicatedInfo.length - maxNumberToDisplay, 0),
                LIST: listToPresent,
            }),
        );
    }

    isActionPossible(grid, actionEnum) {
        switch (actionEnum) {
            case GridActionEnum.DUPLICATION:
                return grid.isClonable;
            case GridActionEnum.EDITION:
                return grid.isEditable;
            case GridActionEnum.ETL_SYNCHRONIZE:
                return grid.isUpdatedAutomatically;
            default:
                return false;
        }
    }

    getCompanyNames(grid) {
        if (!grid.company?.length) {
            return this._translate('shared.none', { GENDER: 'female' });
        }

        return grid.company
            .map((companyElement) => {
                return companyElement?.name ?? '-';
            })
            .join(' / ');
    }

    getPossibleActions(grid, actionEnums = []) {
        const actionTranslates = actionEnums.reduce((acc, actionEnum) => {
            if (!this.isActionPossible(grid, actionEnum)) {
                return acc;
            }

            return [...acc, this._translate(`shared.${actionEnum}`)];
        }, []);

        if (!actionTranslates.length) {
            return this._translate('shared.none', { GENDER: 'female' });
        }

        return actionTranslates.join(' / ');
    }

    getRemovedTemplateMessage(childrenNameList, selected, isFillGrid) {
        const typeGridMessage = isFillGrid ? this._translate('shared.fillGrid') : this._translate('shared.controlGrid');

        const theGridMessage = isFillGrid
            ? this._translate('fillGrid.theFillGrid')
            : this._translate('shared.theControlGrid');

        if (selected.length > 1) {
            return `${selected.length} ${typeGridMessage}. \n \n${this._translate('shared.linkedChildrenGrids')}`;
        }

        return `${theGridMessage} "${selected ? selected[0].fullname : ''}" ${
            childrenNameList.length
                ? `\n\n ${this._translate('shared.referenceGridsList')} : \n ${childrenNameList}`
                : `\n\n ${this._translate('shared.noChildrenGrids')}`
        } `;
    }

    getRemovedWorkingMessage(workingGrids, selected, isFillGrid) {
        const typeGridMessage = isFillGrid ? this._translate('shared.fillGrid') : this._translate('shared.controlGrid');

        if (selected.length > 1) {
            return `${selected.length} ${typeGridMessage}.
                ${this._translate('shared.warningWorkingGrids')} : \n
                ${workingGrids}`;
        }

        return `${this._translate('fillGrid.theWorkingGrid')} "${
            selected ? selected[0].fullname : ''
        }" ${this._translate('shared.linkedToCompany')} "${selected[0].company[0].name}".`;
    }

    async getTemplates(filters, sort, isFillGrid) {
        // check for template
        filters.template = true;

        const dataToFind = { ...sort, ...filters };

        try {
            const findListAdmin = isFillGrid
                ? await this._fillGridProvider.listAllFillGrids(dataToFind)
                : await this._gridControlProvider.listAllControlGrids(dataToFind);

            return findListAdmin.data;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    isAllowedToRemove(item, isAllowed) {
        return item && (item.countDeposits === 0 || isAllowed(['superAdmin']));
    }

    async onExportGrid(gridId, isFillGrid) {
        try {
            const grid = isFillGrid
                ? await this._fillGridProvider.get(gridId)
                : await this._gridControlProvider.get(gridId);

            const data = isFillGrid
                ? this._$fillGridService.jsonToExcel(grid.data)
                : this._$gridControlService.jsonToExcel(grid.data);

            return this._$excelService.downloadXLSX(
                { headers: true },
                data,
                grid.fullname,
                'controlGridAdminView.downloadGrid',
            );
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async removeMessage(selected, isTemplate, isFillGrid) {
        if (!isTemplate) {
            const workingGrids = Object.values(selected)
                .map((child) =>
                    this._translate('removeModal.gridFromCompany', {
                        COMPANY: child.company[0]?.name ?? this._translate('shared.noCompany', { COUNT: 1 }),
                        GRID: child.fullname,
                    }),
                )
                .join('\n');

            return this.getRemovedWorkingMessage(workingGrids, selected, isFillGrid);
        }

        try {
            const childrenGrids = isFillGrid
                ? await this._fillGridProvider.getChildren(selected[0].id)
                : await this._gridControlProvider.getChildren(selected[0].id);

            const childrenNameList = Object.values(childrenGrids)
                .map((child) =>
                    this._translate('removeModal.gridFromCompany', {
                        COMPANY: child.company[0]?.name ?? this._translate('shared.noCompany', { COUNT: 1 }),
                        GRID: child.fullname,
                    }),
                )
                .join('\n');

            return this.getRemovedTemplateMessage(childrenNameList, selected, isFillGrid);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async search(page, perPage, sort, filters, isFillGrid) {
        if (!angular.equals(sort, this.sort)) {
            this.sort = { ...sort };
        }

        try {
            return isFillGrid
                ? await this._fillGridProvider.listAllFillGrids({
                      page,
                      ...sort,
                      ...filters,
                      limit: perPage,
                  })
                : await this._gridControlProvider.listAllControlGrids({
                      page,
                      ...sort,
                      ...filters,
                      limit: perPage,
                  });
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    selectDataToRemove(grids, tableSelectActive, isAllowed) {
        return grids.filter(tableSelectActive).filter((item) => this.isAllowedToRemove(item, isAllowed));
    }
}

angular
    .module('dotic')
    .factory(
        '$gridService',
        (
            $filter,
            $excelService,
            fillGridProvider,
            $fillGridService,
            gridControlProvider,
            $gridControlService,
            $toasterService,
        ) =>
            new GridService(
                $excelService,
                $filter,
                fillGridProvider,
                $fillGridService,
                gridControlProvider,
                $gridControlService,
                $toasterService,
            ),
    );
