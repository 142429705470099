function LoaderService() {
    const loader = angular.element('#cc-loader');

    function open(text) {
        loader.removeClass('hide').addClass('show');
        loader.find('.cc-loader__text').html(text);
    }

    function dismiss() {
        loader.removeClass('show').addClass('hide');
        loader.find('.cc-loader__text').html('');
    }

    return {
        open: open,
        dismiss: dismiss,
    };
}

angular.module('dotic').factory('LoaderService', LoaderService);
