// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/synoptic/synoUse-selectWithReport.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/synoptic/synoUse-confirmationModal.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/synoptic/synoUse-showLinkedAnomalies.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../../assets/images/help/synoptic/synoUse-seeDetailsAnomalies.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../../assets/images/help/synoptic/synoUse-seeDetailsCopyData.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 9.5.1 / {{ 'helpSynoptic.linkWithControl' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpSynoptic.selectWithReport' | translate }} : <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span>! {{ 'helpSynoptic.warningReport' | translate }}</span> </p> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'helpSynoptic.selectWithReport' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.confirmationModal' | translate }} : <img class=\"help-Doc__img help-Doc__W40\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'shared.confirmationModal' | translate }} \"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpSynoptic.showLinkedAnomalies' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"{{ 'helpSynoptic.showLinkedAnomalies' | translate }} \"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpSynoptic.seeAnomalyDetails' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"{{ 'helpSynoptic.seeAnomalyDetails' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpSynoptic.copyData' | translate }} : <img class=\"help-Doc__img help-Doc__W60\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"{{ 'helpSynoptic.copyData' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/synoptic/synoLinked/synoLinkedReport.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;