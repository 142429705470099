angular.module('dotic').factory('ApiProvider', apiProvider).factory('AuthProvider', authProvider);

function apiProvider(Restangular) {
    return Restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(APP_SETTINGS.baseApiUrl);
        RestangularConfigurer.setPlainByDefault(true);
    });
}

function authProvider(Restangular) {
    return Restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(APP_SETTINGS.baseAuthUrl);
        RestangularConfigurer.setPlainByDefault(true);
    });
}
