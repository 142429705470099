import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

class CcChart {
    chart = null;

    originalData = null;
    data = null;
    options = null;
    height = null;
    maxHeight = null;
    xlabel = null;
    ylabel = null;

    offset = 0;
    paginate = null;

    interactivity = true;

    constructor($element) {
        this._$element = $element;
    }

    $onInit() {
        this.displayChart(this.type, this.data, this.options, this.height);
    }

    $onChanges(values) {
        const data = values?.data?.currentValue ?? this.data;
        const options = values?.options?.currentValue ?? this.options ?? { scales: {}, plugins: {} };
        this.originalData = { ...data };
        this.options = { ...options };
        this.displayChart(this.type, data, options, this.height);
    }

    $onDestroy() {
        this.destroyChart();
    }

    displayChart(type, data, options = { scales: {}, plugins: {} }, height = 100) {
        const container = this._$element.find('.cc-chart-container')[0];
        container.style.minHeight = `${height}px`;

        if (this.maxHeight) {
            container.style.maxHeight = `${this.maxHeight}px`;
        }

        const chartElement = this._$element.find('canvas')[0];
        const chartContext = chartElement.getContext('2d');

        if (!data) {
            return;
        }

        options.indexAxis = this.indexAxis ?? 'x';

        options.maintainAspectRatio = false;
        options.responsive = true;

        this.options.scales = this.options.scales ?? {};

        if (this.xlabel) {
            options.scales.x = { ...options.scales.x, title: { display: true, text: this.xlabel } };
        } else if (options.scales.x) {
            options.scales.x = { ...options.scales.x, title: { display: false } };
        }

        if (this.ylabel) {
            options.scales.y = { ...options.scales.y, title: { display: true, text: this.ylabel } };
        } else if (options.scales.y) {
            options.scales.y = { ...options.scales.y, title: { display: false } };
        }

        if (!this.hideData) {
            options.plugins = {
                datalabels: {
                    display: true,
                    color: '#7d7d7d',
                    font: {
                        size: 11,
                        lineHeight: 0.8,
                    },
                },
            };
        } else {
            options.plugins = {
                datalabels: {
                    display: false,
                },
            };
        }

        if (this.suggestedMax) {
            options.scales.x = {
                ...options.scales.x,
                ticks: {
                    beginAtZero: true,
                    value: this.suggestedMax,
                },
            };
        }

        if (this.chart) {
            this.destroyChart();
        }

        options.onClick = ($event, activeElements) => this.chartClickEvent($event, activeElements);

        options.onHover = ($event, element) => this.chartHover($event, element);

        this.type = type;

        if (this.paginate) {
            data.labels = this.originalData.labels.slice(this.offset, this.offset + this.paginate);
            data.datasets = this.originalData.datasets.map((dataset) => {
                const dataChunk = dataset.data.slice(this.offset, this.offset + this.paginate);

                return { ...dataset, data: dataChunk };
            });

            // Disable annoying animation when we change page
            options.animation = {
                duration: 0,
            };

            // Set max value based on dataset max
            const maxes = this.originalData.datasets.map(({ data }) => Math.max(...data));
            const maxValue = Math.max(...maxes);
            const max = maxValue + Math.round(maxValue / 2);

            if (options.scales.y) {
                options.scales.y.ticks = {
                    autoSkip: true,
                    max,
                };
            }
        }

        this.chart = new Chart(chartContext, {
            type,
            data,
            options,
            plugins: [ChartDataLabels],
        });
    }

    chartHover($event, element) {
        $event.native.target.style.cursor = element[0] ? 'pointer' : 'default';
    }

    chartClickEvent($event, activeElements) {
        if (!activeElements.length) {
            return;
        }
        const activePoint = activeElements[0];

        if (this.interactivity) {
            this.onClick({
                customClickEvent: {
                    label: $event.chart.data.labels[activePoint.index],
                    datasetIndexResult: activePoint.datasetIndex,
                    labelsIndexSeverity: activePoint.index,
                    isBar: true,
                },
            });
        }
    }

    nextPage() {
        this.offset += this.paginate;
        this.displayChart(this.type, this.data, this.options, this.height);
    }

    prevPage() {
        this.offset -= this.paginate;
        this.displayChart(this.type, this.data, this.options, this.height);
    }

    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = null;
    }
}

angular.module('dotic').component('ccChart', {
    template: `
        <div class="cc-chart-container">
            <canvas></canvas>
        </div>
        <div class="chartButton_paginate" ng-if="$ctrl.paginate">
            <cc-button color="flat" disabled="$ctrl.offset <= 0" on-click="$ctrl.prevPage()"><i class="icofont icofont-simple-left iconOpen-close"></i> {{'shared.previous' |translate}}</cc-button> <span> | </span>
            <cc-button color="flat" disabled="$ctrl.offset >= $ctrl.originalData.labels.length - $ctrl.paginate" on-click="$ctrl.nextPage()">{{'shared.next' |translate}} <i class="icofont icofont-simple-right iconOpen-close"></i></cc-button>
        </div>
    `,
    controller: CcChart,
    controllerAs: '$ctrl',
    bindings: {
        data: '<',
        indexAxis: '@',
        options: '<',
        type: '@',
        height: '@',
        maxHeight: '<',
        hideData: '<',
        xlabel: '@',
        ylabel: '@',
        paginate: '<',
        suggestedMax: '@',
        onClick: '&',
        interactivity: '<',
    },
});
