angular.module('dotic').filter('iconizeStatus', () => {
    return (value) => {
        switch (value) {
            case 'ABORTED':
                return '<i class="icofont icofont-ban"></i>';
            case 'DONE':
                return '<i class="icofont icofont-check"></i>';
            case 'DONE_WITH_ERROR':
                return '<i class="icofont icofont-close"></i>';
        }
    };
});
