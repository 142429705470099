import { omit as radashOmit } from 'radash';

import { defaultPagination } from '../../models/common.model';
import { DepositsGeoPackageApi } from '../../../sdk/connect-control-api-v1/src';
import geoPackageTemplate from './export-geopackage.html';

class ExportGeoPackagePage {
    exports = [];
    isAllowed = false;
    isDownloading = false;
    isGenerating = false;
    loading = true;
    page = 0;
    pageSize = 10;
    sort = { column: 'createdAt', order: -1 };
    tableDetail = {};
    tableSelect = {};

    constructor(
        $apiClientService,
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $scope,
        $tableService,
        $timeout,
        $toasterService,
        RestProvider,
    ) {
        this._exportGeoPackageApi = new DepositsGeoPackageApi($apiClientService.client);
        this._translate = $filter('translate');
        this._$deleteService = $deleteService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;

        this._RestProvider = RestProvider;

        this.isAllowed = $authorizationService.isAllowed;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'exportGeoPackage',
                    title: this._translate('shared.exportGeoPackage'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (event, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        this.tableDetail = this._$tableService.detail();
        this.tableSelect = this._$tableService.select();

        this.exports = await this.getExports();
        this.page = this.exports.page;
    }

    async download(exportItem) {
        this.isDownloading = true;
        try {
            this._$toasterService.info({ body: this._translate('shared.downloadingFile', { COUNT: 1 }) });
            await this._RestProvider.downloadFromPath(`deposits/geopackage/${exportItem.id}/download`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
        this.isDownloading = false;
    }

    async generateExport() {
        const exportData = await this._$modalService.triggerExportGeoPackageModal();
        if (!exportData) {
            return;
        }

        this.isGenerating = true;
        try {
            await this._exportGeoPackageApi.generateGeoPackageExportWithHttpInfo({
                ...radashOmit(exportData, ['from', 'to']),
                endDate: exportData.to,
                startDate: exportData.from,
            });
            this._$toasterService.info({ body: this._translate('shared.generating') });
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.exports = await this.getExports();
        this._$timeout(() => (this.isGenerating = false));
    }

    async getExports(paginationParameters = { page: 0, limit: this.pageSize }) {
        this.loading = true;
        try {
            return (
                await this._exportGeoPackageApi.geoPackageGetAllWithHttpInfo({
                    paginationParameters: paginationParameters,
                })
            ).response.body;
        } catch {
            return {
                ...defaultPagination,
                totalCount: 0,
            };
        } finally {
            this._$timeout(() => (this.loading = false));
        }
    }

    async onChange(page, perPage, sort) {
        this.pageSize = perPage;
        this.page = page;
        this.sort = sort;

        this.exports = await this.getExports({
            limit: perPage,
            page: page - 1,
            order: sort.order,
            orderBy: sort.column,
        });
    }

    async removeSelection() {
        const selectedExports = this.exports.data.filter(this.tableSelect.isActive);
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.export', {
                COUNT: selectedExports.length,
                NAME: selectedExports[0].name,
            }),
        );
        if (!isAccepted) {
            return;
        }

        await this._$deleteService.deleteArrayOfIds(
            selectedExports.map((selectedExport) => selectedExport._id),
            (exportId) => this._exportGeoPackageApi.removeGeoPackageExportWithHttpInfo(exportId),
            'removeModal.success',
        );
        this.tableSelect.empty();
        this.exports = await this.getExports();
    }

    selectAll() {
        this._$scope.$broadcast('getVisibleRows');
    }

    showDetails(exportItem) {
        this.tableDetail.toggle(exportItem);
    }
}

angular
    .module('dotic')
    .component('exportGeoPackagePage', { controller: ExportGeoPackagePage, templateUrl: geoPackageTemplate });
