// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/statistic-control/stat-list-1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/statistic-control/stat-list-2.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 6.1.5 / {{ 'shared.depositList' | translate }} </h3> <img class=\"help-Doc__marginTop help-Doc__img-double-up help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"{{ 'shared.depositList' | translate }}\"/> <img class=\"help-Doc__marginBottom help-Doc__img-double-down help-Doc__W95\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"{{ 'shared.depositList' | translate }}\"/> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionStatistic/controlStatistic/statList.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;