import { AuthApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './login.html';

class LoginPage {
    constructor($apiClientService, $auth, $document, $state, $timeout, $toasterService, $window) {
        this._$auth = $auth;
        this._authApi = new AuthApi($apiClientService.client);
        this._$state = $state;
        this._$window = $window;
        this._$toasterService = $toasterService;
        this._$timeout = $timeout;
        this.oauth2 = APP_SETTINGS.OAUTH2;
        this.ssoUrl = APP_SETTINGS.SSO_URL;

        this.alwaysRedirectToSso = false;

        this.user = {};
        this.passwordType = 'password';

        $document[0].title = 'Se connecter - ConnectControl';
    }

    $onInit() {
        if (this._$auth.isAuthenticated()) {
            return this._$state.go('app.depositJob-control');
        }

        // Deactivate login page for sso user
        if (this.ssoUrl && this.alwaysRedirectToSso) {
            this._$window.location.href = this.ssoUrl;
        }
    }

    async login() {
        const user = angular.copy(this.user);
        try {
            const token = (
                await this._authApi.login({
                    email: user.email,
                    password: user.password,
                })
            ).token;
            this._$auth.setToken(token);
            this.loginSuccess();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    loginSuccess() {
        const { userRole } = this._$auth.getPayload();
        let redirectState = 'app.depositJob-control';

        if (userRole === 'superAdmin') {
            redirectState = 'app.dashboardSuperAdmin';
        }

        // Force the reload by indicating the state in option.
        this._$timeout(() => {
            this._$state.go(redirectState, {}, { reload: redirectState });
        }, 0);
    }

    async authenticate(provider) {
        try {
            await this._$auth.authenticate(provider);
            this.loginSuccess();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular.module('dotic').component('loginPage', {
    controller: LoginPage,
    templateUrl: template,
});
