angular.module('dotic').factory('$sliderService', sliderService);

function sliderService() {
    return {
        optionPercent: optionPercent,
    };

    function optionPercent() {
        return {
            minRange: 1,
            pushRange: true,
            step: 1,
            ceil: 100,
            translate: (value) => `${value}%`,
        };
    }
}
