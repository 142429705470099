import { pick as radashPick } from 'radash';

import { defaultPagination } from '../../../models/common.model';
import { HelpersService } from '../../../services/helpers.service';

class ComparisonGridController {
    companies = [];
    grids = { ...defaultPagination };
    paginationParameters = {
        page: 0,
        pageSize: 5,
        sort: {},
    };
    selection = {
        company: null,
        date: '',
        template: false,
    };

    constructor(
        $auth,
        $comparisonService,
        $filter,
        $location,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $userService,
        fillGridProvider,
        gridControlProvider,
    ) {
        this._$auth = $auth;
        this._$comparisonService = $comparisonService;
        this._$location = $location;
        this._$scope = $scope;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$userService = $userService;
        this._fillGridProvider = fillGridProvider;
        this._gridControlProvider = gridControlProvider;
        this._translate = $filter('translate');

        this.selection.company = $auth.getPayload().company;

        this.tableDetail = $tableService.detail();
        this.templateList = [
            {
                key: true,
                value: this._translate('shared.templateGrids'),
            },
            {
                key: false,
                value: this._translate('shared.workingGrids'),
            },
        ];

        $scope.$watchGroup(
            ['$ctrl.selection.company', '$ctrl.selection.template', '$ctrl.selection.date'],
            async () => {
                this.paginationParameters.page = 0;
                this.loading = true;
                this.grids = await this.loadGrids();
                this._$timeout(() => (this.loading = false));
            },
        );
    }

    async $onInit() {
        this.loading = true;

        const userCompanies = this._$userService
            .getConnectedUser()
            .companies.filter(
                (company) =>
                    company._id === this.selection.company || company.settings.feature.hasGrantedAccessRightsToGrids,
            );
        this.companies = HelpersService.toKeyValue(userCompanies, { keyField: '_id', valueField: 'name' });

        this.selectedGrid = await this.getGrid();
        this.grids = await this.loadGrids();

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));

        this.setScopeNavigation();
    }

    async compareGrid(gridId) {
        const type = Object.entries(this._$state.params).find((param) => param[1])[0];

        await this._$comparisonService.goToComparePage(this.selectedGrid.id, gridId, type, this.selection.company);
    }

    formatQueryParams() {
        const params = {
            ...radashPick(this.selection, ['company', 'template']),
            ...this.paginationParameters.sort,
            limit: this.paginationParameters.pageSize,
            page: this.paginationParameters.page,
        };

        if (!this.selection.date) {
            return params;
        }

        return {
            ...params,
            updatedAtStart: moment(this.selection.date.startDate).utc().format(),
            updatedAtEnd: moment(this.selection.date.endDate).utc().format(),
        };
    }

    async getControlGrids() {
        try {
            const controlGrids = await this._gridControlProvider.listAllControlGrids(this.formatQueryParams());
            controlGrids.data = controlGrids.data.map((grid) => {
                grid.dataModel = grid.dataModel.map((dataModel) => dataModel.name);

                return grid;
            });

            return controlGrids;
        } catch {
            return defaultPagination;
        }
    }

    async getFillGrids() {
        try {
            return await this._fillGridProvider.listAllFillGrids(this.formatQueryParams());
        } catch {
            return defaultPagination;
        }
    }

    async getGrid() {
        try {
            let grid = {};
            if (this._$state.params.controlGrid) {
                grid = await this._gridControlProvider.get(this._$state.params.controlGrid);
                grid.dataModel = grid.dataModel.map((dataModel) => dataModel.name);
            } else if (this._$state.params.fillGrid) {
                grid = await this._fillGridProvider.get(this._$state.params.fillGrid);
            }

            grid.company = grid.company.find((company) => this.selection.company === company._id);

            return grid;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async loadGrids() {
        if (!this.selection.company) {
            this.selection.company = this._$auth.getPayload().company;
        }

        return this._$state.params.fillGrid ? await this.getFillGrids() : await this.getControlGrids();
    }

    async onPaginationChange(page, pageSize, sort) {
        this.paginationParameters = {
            ...this.paginationParameters,
            pageSize: pageSize,
            page: page,
            sort: sort,
        };

        this.loading = true;
        this.grids = await this.loadGrids();

        this._$timeout(() => (this.loading = false));
    }

    setScopeNavigation() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'compare',
                    title: this._translate('shared.comparisonInterface'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate(this._$state.params.fillGrid ? 'shared.fillGrid' : 'shared.controlGrid'),
                href: this._$state.href(
                    this._$state.params.fillGrid ? 'app.gridFillAdminList' : 'app.gridControlAdminList',
                ),
                key: this._$state.params.fillGrid ? 'fillGrids' : 'controlGrids',
            },
            allowedPreviousKeys: ['controlGrid', 'fillGrid'],
        });
    }
}

angular.module('dotic').controller('ComparisonGridController', ComparisonGridController);
