import template from './menuButton.html';

angular.module('dotic').component('menuButton', {
    templateUrl: template,
    bindings: {
        title: '@',
        isActive: '<',
        icon: '@',
        link: '@',
    },
});
